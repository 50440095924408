import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import IconFromPng from 'shared/components/IconFromPng';
import Spinner from 'shared/components/andtComponents/Spinner';
import Panel from 'shared/components/Panel';
import PageHeader from 'shared/components/PageHeader';
import ICONS from 'shared/constants/assetsConstants';
import { PageNames } from 'shared/constants/appConstants';
import DateFilter from 'shared/modules/dateFilter';
import { SuccessNotificationsLabels } from 'shared/constants/notificationsConstants';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { mapReportDatePeriodToType } from 'usage/constants/costAndUsageConstants';
import {
  mapCloudTypeToRecommendationTypes,
  mapRecommendationsTypeToDisplay,
  REC_REPORTS_COLUMN_WIDTHS,
  REC_REPORTS_DEFAULT_HIDDEN_COLUMN_NAMES,
  REC_REPORTS_DEFAULT_SORTING,
  REC_REPORTS_TABLE_COLUMN_EXTENSIONS,
  REC_REPORTS_TABLE_COLUMNS,
} from 'recommendations/constants/recommendationsConstants';
import toast from 'shared/components/andtComponents/Toast';
import ReportModal from 'shared/components/reportModal/ReportModal';
import {
  ALL_OPTIONS,
  RECOMMENDATIONS_MODAL_TYPE,
} from 'recommendations/containers/Reports/components/recommendationReportsConstants';
import RecommendationsReportContent from 'shared/components/reportModal/RecommendationsReportContent';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { sortByLabel } from 'shared/components/reportModal/reportsUtil';
import { prepareTableData } from 'recommendations/containers/Reports/helpers/recReportsHelpers';
import ReportsTable from 'recommendations/containers/Reports/components/ReportsDevExpTable';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import useRecommendationReports from 'recommendations/hooks/react-query/useRecommendationReports';
import uuidv1 from 'uuid';
import { dateToStr } from 'shared/utils/dateUtil';

const CreateReportButton = ({ handler }) => (
  <Row className="dotted_box">
    <div className="dotted_box-title">
      <span>Create Report</span>
    </div>
    <br />
    <div>
      <LinkIconButton>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="/" onClick={(e) => handler(e)} />
        <IconFromPng size="auto" icon={ICONS.plus} />
      </LinkIconButton>
    </div>
  </Row>
);

CreateReportButton.propTypes = {
  handler: PropTypes.func.isRequired,
};

const RecommendationReports = ({ usersStore, invoiceStore, getPageFilters, filtersValuesMap }) => {
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currDeleteReport, setCurrDeleteReport] = useState(null);
  const [editReport, setEditReport] = useState({});
  const [options, setOptions] = useState({});
  const { currDispUserCloudAccountType } = usersStore;

  const reportsProvider = useRecommendationReports();
  const { isLoading, data: reports } = reportsProvider.fetchRecommendationReports();

  const getReportParams = (reportId) => {
    const reportIndex = reports?.findIndex((report) => report.id === reportId);
    if (reportIndex > -1) {
      return reports[reportIndex];
    }
    return {};
  };

  const createOptions = () => {
    let tempLinkedAccounts = [ALL_OPTIONS];
    let tempDivisionOptions = [ALL_OPTIONS];
    const pageFilters = getPageFilters(PageNames.RECOMMENDATIONS_REPORTS, currDispUserCloudAccountType);
    if (pageFilters.has(AwsCommonFields.LINKED_ACCOUNT_NAME)) {
      tempLinkedAccounts = pageFilters
        .get(AwsCommonFields.LINKED_ACCOUNT_NAME)
        .map((field) => ({ value: field.linkedAccountId, label: field.displayLabel }));
    }
    if (pageFilters.has('division')) {
      tempDivisionOptions = pageFilters.get('division').map((field) => ({
        value: field.divisionName,
        label: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', field.divisionName),
      }));
    }
    const types = Object.values(mapCloudTypeToRecommendationTypes.get(currDispUserCloudAccountType)).map((value) => ({
      value,
      label: mapRecommendationsTypeToDisplay.get(value),
    }));
    const tempServicesOptions =
      filtersValuesMap.get(AwsCommonFields.SERVICE)?.map((value) => ({ value, label: value })) || [];
    return {
      linkedAccountsOptions: [ALL_OPTIONS, ...sortByLabel(tempLinkedAccounts)],
      recsTypesOptions: [ALL_OPTIONS, ...sortByLabel(types)],
      divisionsOptions: [ALL_OPTIONS, ...sortByLabel(tempDivisionOptions)],
      servicesOptions: [ALL_OPTIONS, ...sortByLabel(tempServicesOptions)],
    };
  };

  useEffect(() => {
    const options = createOptions();
    setOptions(options);
  }, [currDispUserCloudAccountType, filtersValuesMap]);

  const handleDeleteReportClicked = async (e, reportId) => {
    e.preventDefault();
    const { reportName } = getReportParams(reportId);
    setCurrDeleteReport({ id: reportId, name: reportName });
  };

  const deleteReportHandler = async (action) => {
    if (action === 'cancel') {
      setCurrDeleteReport(null);
    } else if (action === 'delete') {
      try {
        await reportsProvider.deleteRecommendationReport(currDeleteReport.id);
        setCurrDeleteReport(null);
      } catch (error) {
        setCurrDeleteReport(null);
      }
    }
  };

  const onEdit = (e, reportId) => {
    e.preventDefault();
    const reportParams = getReportParams(reportId);
    if (!reportParams) {
      toast.error('Currently edit is unavailable, please try again later');
      return;
    }

    const editReport = {
      id: reportParams.id,
      name: reportParams.reportName,
      email: reportParams.email,
      slackChannels: reportParams.slackChannels,
      reportFreq: reportParams.frequency,
      frequencyDeliveryTime: reportParams.deliveryTime,
      customFrequencyInDays: reportParams.customFrequencyInDays,
      firstDate: reportParams.firstDate,
      reportType: reportParams.reportType,
      reportGroupBy: reportParams.reportGroupBy,
      recsStatus: reportParams.recsStatus,
      linkedAccountIdsL: reportParams.linkedAccountIds,
      divisions: reportParams.divisions,
      services: reportParams.services,
      recsTypes: reportParams.recsTypes,
      tags: reportParams.tags,
      period: reportParams.period,
      recommendationAgeDays: reportParams.recommendationAgeDays,
      isEdit: true,
    };
    setIsSaveModalOpen(true);
    setEditReport(editReport);
  };

  const handleCreateReport = (e) => {
    e.preventDefault();
    setIsSaveModalOpen(true);
  };

  const formatDataForServer = (data) => {
    const {
      delivery,
      divisions,
      emails,
      linkedAccountIds,
      recommendationAgeDays,
      recsStatus,
      recsTypes,
      reportGroupBy,
      reportName,
      services,
      slackChannels,
      tags,
      reportType,
      period,
    } = data;
    const { customFrequency, customStartDate, frequency, time } = delivery;
    const { id } = editReport;
    return {
      id,
      customFrequencyInDays: customFrequency,
      firstDate: customStartDate,
      frequency,
      deliveryTime: time,
      divisions,
      email: emails.value,
      linkedAccountIds,
      recommendationAgeDays,
      recsStatus,
      recsTypes,
      reportGroupBy,
      reportName: reportName.value,
      services,
      slackChannels,
      tags,
      reportType,
      period,
      periodType: mapReportDatePeriodToType.get(period),
    };
  };

  const onClose = () => {
    setIsSaveModalOpen(false);
    setEditReport({});
  };

  const onSave = async (data) => {
    const { isEdit } = editReport;
    const reportParams = formatDataForServer(data);
    try {
      setIsSaving(true);
      reportParams.userKey = usersStore.currentDisplayedUserKey;
      const creationDate = dateToStr(new Date(), 'yyyy-mm-dd hh:mm:ss');
      reportParams.creationDate = creationDate;
      if (isEdit) {
        await reportsProvider.updateRecommendationReport(reportParams);
      } else {
        reportParams.id = uuidv1();
        await reportsProvider.createRecommendationReport(reportParams);
      }
      toast.success(SuccessNotificationsLabels.REPORT_CREATED);
    } catch (error) {
      toast.error(error?.message || 'General error occurred, please try again later');
    } finally {
      setIsSaveModalOpen(false);
      setIsSaving(false);
      setEditReport({});
    }
  };

  const onSend = async (data) => {
    let reportParams = data;
    if (data.reportName) {
      reportParams = formatDataForServer(data);
      reportParams.isTemp = true;
    }
    try {
      if (reportParams.isTemp) {
        reportParams.id = uuidv1();
      }
      reportParams.userKey = usersStore.getCurrUserKey;
      await reportsProvider.sendRecommendationReport(reportParams);
    } catch (error) {
      console.log(error);
    }
  };

  const tableData = prepareTableData(reports);
  const { currentUserReadOnly } = usersStore;

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Container>
      <PageHeader title={PageNames.RECOMMENDATIONS_REPORTS} showDate />
      <Row>
        <Panel divider title="Reports">
          <ReadOnlyDisplayWrapper isHide={false} userReadOnly={currentUserReadOnly}>
            <Row>
              <Col xs={12} md={12} lg={12} xl={12}>
                <CreateReportButton handler={handleCreateReport} />
              </Col>
            </Row>
          </ReadOnlyDisplayWrapper>
          <ReportsTable
            usersStore={usersStore}
            rows={tableData}
            columns={REC_REPORTS_TABLE_COLUMNS}
            columnWidths={REC_REPORTS_COLUMN_WIDTHS}
            tableColumnExtensions={REC_REPORTS_TABLE_COLUMN_EXTENSIONS}
            defaultHiddenColumnNames={REC_REPORTS_DEFAULT_HIDDEN_COLUMN_NAMES}
            defaultSorting={REC_REPORTS_DEFAULT_SORTING}
            csvTitle={`Reports_data_${DateFilter.month}_${DateFilter.year}`}
            deleteHandler={handleDeleteReportClicked}
            updateReportHandler={onEdit}
            sendReportHandler={onSend}
            currentUserReadOnly={currentUserReadOnly}
          />
        </Panel>
      </Row>
      <ReportModal
        onClose={onClose}
        modalType={RECOMMENDATIONS_MODAL_TYPE}
        isOpen={isSaveModalOpen}
        onSave={onSave}
        onSend={onSend}
        usersStore={usersStore}
        title={editReport.id ? 'Update report' : 'Create new report'}
        data={{ ...editReport, ...options }}
        isScroll
        isSaving={isSaving}
      >
        <RecommendationsReportContent invoiceStore={invoiceStore} />
      </ReportModal>
      <DeleteWarningModal
        deletedItemName={currDeleteReport && currDeleteReport.name}
        isOpen={currDeleteReport && currDeleteReport.id}
        handleDelete={deleteReportHandler}
        warningMessage="Be advise you are about to delete a"
        modalTitle="Delete Report"
      />
    </Container>
  );
};

RecommendationReports.propTypes = {
  usersStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  getPageFilters: PropTypes.func.isRequired,
  filtersValuesMap: PropTypes.object.isRequired,
};

const ObserverRecommendationReports = withInvoiceFiltersContextConsumer(observer(RecommendationReports));
export default ObserverRecommendationReports;
