/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import {
  AMORTIZE_COST_TYPES_ITEMS,
  calcRateValue,
  CostTypeModes,
  mapDisplayUsageQuantityTypeToMetricType,
  mapGranularityToDisplayName,
} from 'usage/constants/costAndUsageConstants';
import { ReactComponent as InfoiIcon } from 'shared/img/icons/info.svg';
import { ReactComponent as EditIcon } from 'shared/img/icons/edit.svg';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { ReactComponent as CheckIcon } from './assets/check.svg';
import { ReactComponent as ArrowDownIcon } from './assets/arrow-down.svg';
import Tooltip from '../../andtComponents/Tooltip';
import styles from './TotalChartValueLabel.module.scss';

const TITLE_BY_COST_TYPE = (costLabel, usageSelected, granularity) => ({
  [CostTypeModes.COST]: costLabel ? `Total Cost: ${costLabel}` : 'Total Cost',
  [CostTypeModes.USAGE]: usageSelected ? `Total Usage: ${usageSelected}` : 'Total Usage',
  [CostTypeModes.RATE]: 'Avg. Rate',
  [CostTypeModes.GOAL]: `${mapGranularityToDisplayName.get(granularity) || ''} Goal`,
});

const TotalChartValueLabel = ({
  dataForRate,
  totalCost,
  goalName,
  costTypeMode,
  costSelected,
  usageSelected,
  displayedMetric,
  goalReached,
  subTitle,
  granularity,
  editHandler,
}) => {
  const sum =
    costTypeMode === CostTypeModes.RATE
      ? calcRateValue(dataForRate.totalCostSum, dataForRate.totalUsageSum, usageSelected)
      : totalCost;
  const { numStrAbriviaionByDisplayMetric, getCurrencyNumber } = useUserSettingsContext();
  const sumLabel =
    costTypeMode === CostTypeModes.COST
      ? getCurrencyNumber(sum || 0, 2, {})
      : numStrAbriviaionByDisplayMetric(sum || 0, sum || 0, displayedMetric, null);
  const costLabel =
    costSelected && (Object.values(AMORTIZE_COST_TYPES_ITEMS).find((i) => i.value === costSelected) || {}).name;
  return (
    <div className={styles.container}>
      <h5>
        {TITLE_BY_COST_TYPE(costLabel, usageSelected, granularity)[costTypeMode]}
        {costTypeMode === CostTypeModes.GOAL && (
          <Tooltip
            title={
              <>
                {goalName}
                <br />
                {TITLE_BY_COST_TYPE(costLabel, usageSelected, granularity)[costTypeMode]}: {sumLabel}
                <br />
                {subTitle}
              </>
            }
            arrow
          >
            <span className={styles.icon}>
              <InfoiIcon />
            </span>
          </Tooltip>
        )}
        {costTypeMode !== CostTypeModes.COST && costTypeMode !== CostTypeModes.GOAL && (
          <Tooltip title={`Any data that is not measurable by ${usageSelected} has been filtered out.`} arrow>
            <span className={styles.icon}>
              <InfoiIcon />
            </span>
          </Tooltip>
        )}
        {editHandler && (
          <Tooltip title="Edit Goal">
            <span onClick={editHandler} className={styles.editGoalIcon}>
              <EditIcon />
            </span>
          </Tooltip>
        )}
      </h5>
      <span className={styles.number}>
        {sumLabel}
        {costTypeMode === CostTypeModes.GOAL && (goalReached ? <CheckIcon /> : <ArrowDownIcon />)}
      </span>
      {costTypeMode === CostTypeModes.RATE && (
        <p className={styles.subtitle}>
          {costLabel}: {/* eslint-disable-next-line max-len */}
          {getCurrencyNumber(dataForRate.totalCostSum, 2)} / {usageSelected}:{' '}
          {numStrAbriviaionByDisplayMetric(
            dataForRate.totalUsageSum,
            dataForRate.totalUsageSum,
            costTypeMode === CostTypeModes.RATE
              ? mapDisplayUsageQuantityTypeToMetricType.get(usageSelected)
              : displayedMetric,
            null,
          )}
        </p>
      )}
      {subTitle && <p className={styles.subtitle}>{subTitle}</p>}
    </div>
  );
};

TotalChartValueLabel.propTypes = {
  dataForRate: PropTypes.object,
  totalCost: PropTypes.number.isRequired,
  costTypeMode: PropTypes.string.isRequired,
  costSelected: PropTypes.string.isRequired,
  usageSelected: PropTypes.string.isRequired,
  displayedMetric: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  granularity: PropTypes.string,
  editHandler: PropTypes.func,
  goalName: PropTypes.string,
  goalReached: PropTypes.bool,
};

TotalChartValueLabel.defaultProps = {
  dataForRate: null,
  granularity: '',
  goalName: '',
  editHandler: null,
  goalReached: false,
  subTitle: null,
};

export default TotalChartValueLabel;
