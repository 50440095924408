/* eslint-disable max-len */
import {
  MAX_ASSUME_ROLE_SESSION_DURATION_IN_SECONDS,
  NEW_INVOICE_TOPIC_FILENAME,
  PILEUS_POLICY_FILENAME,
  PILEUS_ROLE_FILENAME,
} from '../constants/newUserConstants';

export const generateTopicNotificationCmd = (invoiceBucketName) =>
  `aws s3api put-bucket-notification-configuration --bucket ${invoiceBucketName} --notification-configuration file://${NEW_INVOICE_TOPIC_FILENAME}`;
export const generateCreateRoleCmd = () =>
  `aws iam create-role --role-name PileusRole --max-session-duration ${MAX_ASSUME_ROLE_SESSION_DURATION_IN_SECONDS} --assume-role-policy-document file://${PILEUS_ROLE_FILENAME}`;
export const generatePutRolePolicyCmd = () =>
  `aws iam put-role-policy --role-name PileusRole --policy-name PileusPolicy --policy-document file://${PILEUS_POLICY_FILENAME}`;
