import * as awsUtils from 'shared/utils/awsUtils';
import * as gcpUtils from 'shared/utils/gcpUtils';
import * as azureUtils from 'shared/utils/azureUtils';
import * as multiUtils from 'shared/utils/multiUtils';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';

export const mapCloudTypeToCloudUtil = new Map([
  [CLOUD_TYPE_IDS.AWS, awsUtils],
  [CLOUD_TYPE_IDS.AZURE, azureUtils],
  [CLOUD_TYPE_IDS.GCP, gcpUtils],
  [CLOUD_TYPE_IDS.MULTI, multiUtils],
]);

export const mapCloudTypeToDisplayLabelFunc = new Map([
  [CLOUD_TYPE_IDS.AWS, awsUtils.getFieldDisplayLabel],
  [CLOUD_TYPE_IDS.AZURE, azureUtils.getFieldDisplayLabel],
  [CLOUD_TYPE_IDS.GCP, gcpUtils.getFieldDisplayLabel],
  [CLOUD_TYPE_IDS.MULTI, multiUtils.getFieldDisplayLabel],
]);

export const isServiceHasFamilyType = (serviceName, cloudType) => {
  const cloudLib = mapCloudTypeToCloudUtil.get(cloudType);
  return cloudLib.isServiceHasFamilyType(serviceName);
};
export const isServiceHasTypes = (serviceName, cloudType) => {
  const cloudLib = mapCloudTypeToCloudUtil.get(cloudType);
  return cloudLib.isServiceHasTypes(serviceName);
};

export const isFamilyTypeHasTypes = (serviceName, cloudType) => {
  const cloudLib = mapCloudTypeToCloudUtil.get(cloudType);
  return cloudLib.isFamilyTypeHasTypes(serviceName);
};
