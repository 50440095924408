import { useMutation } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import { alignedStartEndDateByGranLevel, buildStartAndEndDate } from 'shared/utils/dateUtil';
import { buildFilterParams, getIncludeExcludeMaps, sanitizeParam } from 'shared/utils/apiUtil';
import { FilterTypes } from 'usage/constants/usageConstants';
import { AMORTIZE_COST_TYPES, CostTypes } from 'usage/constants/costAndUsageConstants';

const modifyFilterMapToMapWithValueArray = (baseFiltersMap) => {
  const entries = [...baseFiltersMap.entries()];
  const tempMap = new Map();
  entries.forEach(([k, v]) => {
    const arrOfVal = v.map((item) => item.value);
    tempMap.set(k, arrOfVal);
  });
  return tempMap;
};

const buildAssetsQuery = (
  rawStartDate,
  rawEndDate,
  granLevel,
  columnsArr,
  filtersMap,
  excludedFiltersStatusMap,
  measuresMap,
  costTypes,
  isFull,
  isK8S,
  panelId,
  dashboardId,
  k8SGranularity,
  isCount,
) => {
  const filtersMapWithValues = modifyFilterMapToMapWithValueArray(filtersMap);
  // prepare dates
  const { startDate, endDate } = buildStartAndEndDate(rawStartDate, rawEndDate);
  const { start, end } = alignedStartEndDateByGranLevel(startDate, endDate, granLevel);
  // prepare columns
  const columns = columnsArr.map((col) => `&columns=${sanitizeParam(col.value)}`).join('');
  // prepare measures
  const usageCount = measuresMap.get('usage') ? '&isUsage=true' : '';
  const showAmortize = measuresMap.get(AMORTIZE_COST_TYPES.AMORTIZED) ? '&isAmortized=true' : '';
  const showUnblended = measuresMap.get(AMORTIZE_COST_TYPES.UNBLENDED) ? '&isUnblended=true' : '';
  const netAmortize = measuresMap.get(AMORTIZE_COST_TYPES.NET_AMORTIZED) ? '&isNetAmortized=true' : '';
  const netUnblended = measuresMap.get(AMORTIZE_COST_TYPES.NET_UNBLENDED) ? '&isNetUnblended=true' : '';
  const arrCostType = costTypes.map((ct) => `&costType=${ct}`).join('');
  const panelIdQuery = panelId ? `&panelId=${panelId}` : '';
  const dashboardIdQuery = dashboardId ? `&dashboardId=${dashboardId}` : '';
  const k8SGranularityQuery = k8SGranularity ? `&k8SGranularity=${k8SGranularity}` : '';
  const isCountQuery = isCount ? `&isCount=1` : '';
  // prepare filter
  const { includeFiltersMap, excludeFiltersMap } = getIncludeExcludeMaps(
    filtersMapWithValues,
    excludedFiltersStatusMap,
  );
  const filterParams = buildFilterParams(includeFiltersMap);
  const excludeFilterParams = buildFilterParams(excludeFiltersMap, FilterTypes.EXCLUDE);
  const getUrl = () => {
    if (isCount) {
      return '/';
    }
    if (isFull) {
      return '/full';
    }
    return '/limited';
  };
  // prepare api query
  return `/api/v1/usage/assets${getUrl()}?startDate=${start || startDate}&endDate=${
    end || endDate
    // eslint-disable-next-line max-len
  }&isK8S=${+isK8S}&granLevel=${granLevel}${columns}${filterParams}${excludeFilterParams}${arrCostType}${usageCount}${showAmortize}${showUnblended}${netAmortize}${netUnblended}${panelIdQuery}${dashboardIdQuery}${k8SGranularityQuery}${isCountQuery}`;
};

export const getAssetsData = ({
  startDate,
  endDate,
  granLevel,
  columns,
  filtersMap,
  excludeFiltersMap,
  measuresMap,
  costTypes = [CostTypes.COST, CostTypes.DISCOUNT],
  isK8S,
  panelId,
  dashboardId,
  k8SGranularity,
  isFull,
  isCount,
}) =>
  API.get(
    'billings',
    buildAssetsQuery(
      startDate,
      endDate,
      granLevel,
      columns,
      filtersMap,
      excludeFiltersMap,
      measuresMap,
      costTypes,
      isFull,
      isK8S,
      panelId,
      dashboardId,
      k8SGranularity,
      isCount,
    ),
  );

export function useAssets() {
  const { data, isLoading, reset, mutate, mutateAsync } = useMutation({
    mutationFn: async (params) => {
      try {
        const response = await getAssetsData(params);
        if (Array.isArray(response)) {
          return { data: response };
        }
        return { data: response.data, isLimited: response.isLimited && !params.isK8S };
      } catch (error) {
        const { response } = error || {};
        if (response && response.status === 403 && response.data.errorCode === 'DATA_TOO_BIG') {
          return { data: [], tooBigError: response.data.clientMessage, isLimited: false };
        }
        return { data: [], isLimited: false };
      }
    },
  });
  return {
    clearAssetData: () => reset(),
    fetchAssetData: (params) => mutate(params),
    fetchAssetDataAsync: (params) => mutateAsync(params),
    assetData: data?.data,
    assetDataLimited: data?.isLimited,
    assetDataLoading: isLoading,
    assetDataTooBig: data?.tooBigError,
  };
}
