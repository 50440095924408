import { FILTERS } from 'recommendationsNew/consts';

// Transform data to match the format of react-select
export const transformSelectDataToClientFormat = (pageData, filterName) =>
  pageData?.map((item) => {
    const { id, name, ...rest } = item;
    return {
      ...rest,
      value: id,
      label: name,
    };
  });

export const transformSingleOptionToApiFormat = (option) => {
  const { value, label, ...rest } = option;
  return {
    ...rest,
    id: value,
    name: label,
  };
};

// Transform data to match the format of backend api
export const transformFiltersContextToApiFormat = (filters) => {
  let transformedFilters = {};
  if (!filters) {
    return transformedFilters;
  }
  Object.keys(filters)?.forEach((key) => {
    const filter = filters[key];
    let transformedFilter;
    if ([FILTERS.CATEGORIES.id].includes(key) && filter?.length > 0) {
      transformedFilter = filter?.map((item) => item.value);
    } else if (
      [
        FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id,
        FILTERS.OPEN_RECS_CREATION_DATE.id,
        FILTERS.CLOSED_AND_DONE_RECS_DATES.id,
      ].includes(key)
    ) {
      transformedFilter = filter;
    } else if (
      [
        FILTERS.TYPES.id,
        FILTERS.SERVICES.id,
        FILTERS.REGION.id,
        FILTERS.LINKED_ACCOUNT_ID.id,
        FILTERS.INSTANCE_TYPE.id,
        FILTERS.RESOURCE_ID.id,
        FILTERS.ASSIGNEE.id,
        FILTERS.COST_CENTER.id,
        FILTERS.CUSTOM_TAGS.id,
        FILTERS.BUSINESS_MAP.id,
        FILTERS.ENRICHMENT_TAGS.id,
        FILTERS.RECOMMENDED_INSTANCE_TYPE.id,
        FILTERS.VIRTUAL_TAGS.id,
      ].includes(key) &&
      filter?.length > 0
    ) {
      const eqValue = filter?.map((item) => item?.value);
      const negate = filter?.negate !== undefined;
      const likeValue = [];
      transformedFilter = {
        ...(negate ? { negate: true } : null), // Relevant for Include/Exclude
        ...(eqValue?.length ? { eq: eqValue } : null),
        ...(likeValue?.length ? { like: likeValue } : null),
      };
    } else if ([FILTERS.IS_OPEN.id, FILTERS.IS_STARRED.id].includes(key)) {
      transformedFilter = filter;
    } else if ([FILTERS.USER_STATUS.id].includes(key)) {
      transformedFilter = filter;
    }
    transformedFilters = {
      ...((Array.isArray(transformedFilter) && Object.keys(transformedFilter)?.length !== 0) ||
      typeof transformedFilter !== 'undefined'
        ? { ...transformedFilters, [key]: transformedFilter }
        : transformedFilters),
    };
  });
  return transformedFilters;
};
