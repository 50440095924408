/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-empty */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { action, makeObservable, observable, runInAction } from 'mobx';
import DateFilter from 'shared/modules/dateFilter';
import fileSaver from 'file-saver';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import { CostTrackingConstants } from 'usage/constants/costAndUsageConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { b64toBlob } from '../../shared/utils/strUtil';

export default class AwsCustomersModel {
  constructor(apiGateway) {
    this.apiGateway = apiGateway;
    this.customersData = [];
    this.awsCustomersTableData = [];
    this.awsResellerRatesData = [];
    this.awsResellerCreditData = [];
    this.awsResellerCreditInitialFetchDone = false;
    this.awsResellerCreditHistoryData = new Map();
    this.customerHistoryTableData = [];
    this.savedLogoUrl = null;
    this.isLoading = false;
    this.explainCustomerFlexibilityMarginData = {
      ri: [],
      sp: [],
      pp: [], // Private Pricing
      billingRules: [],
      creditMargin: [],
      resellerCredit: [],
    };
    makeObservable(this, {
      fetchData: action,
      updateCustomerDivisionTypeLocally: action,
      updateCustomerTableDataTypeLocally: action,
      awsCustomersTableData: observable,
      awsResellerRatesData: observable,
      awsResellerCreditData: observable,
      isLoading: observable,
      customerHistoryTableData: observable,
      customersData: observable,
    });
  }

  invalidateData = () => {
    this.awsCustomersTableData = [];
    this.customerHistoryTableData = [];
    this.awsResellerRatesData = [];
    this.awsResellerCreditData = [];
    this.awsResellerCreditInitialFetchDone = false;
    this.awsResellerCreditHistoryData = new Map();
    this.isLoading = false;
    this.explainCustomerFlexibilityMarginData = {
      ri: [],
      sp: [],
      pp: [], // Private Pricing
      billingRules: [],
      creditMargin: [],
      resellerCredit: [],
    };
    this.savedLogoUrl = null;
  };

  prepareCustomersDataByDivisionType = (data) => {
    const copiedData = JSON.parse(JSON.stringify(data));
    copiedData.map((item) => {
      item.groupBy = item.customerType || 'Unassigned';
      return item;
    });

    return copiedData;
  };

  fetchData = async (startDate, endDate) => {
    try {
      this.isLoading = true;
      const defaultStartDate = startDate || buildStartAndEndDate(null, null).startDate;
      const defaultEndDate = endDate || buildStartAndEndDate(null, null).endDate;
      // TODO: Move to customers model base class
      const customersData = await this.apiGateway.getCustomersData(
        defaultStartDate,
        defaultEndDate,
        CostTrackingConstants.GRAN_LEVEL_MONTHLY,
      );
      runInAction(() => {
        // TODO: Move to customers model base class
        this.customersData = customersData;
        this.customersDataByDivisionType = this.prepareCustomersDataByDivisionType(customersData);
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
        this.isLoading = false;
      });
    }
    return this.customersData;
  };
  fetchResellerCreditsData = async (startDate, endDate) => {
    try {
      const defaultStartDate = startDate || buildStartAndEndDate(null, null).startDate;
      const defaultEndDate = endDate || buildStartAndEndDate(null, null).endDate;
      const awsResellerCreditData = await this.apiGateway.getAwsCreditsData(defaultStartDate, defaultEndDate);
      runInAction(() => {
        this.awsResellerCreditData = awsResellerCreditData;
        this.awsResellerCreditInitialFetchDone = true;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
        this.awsResellerCreditInitialFetchDone = false;
      });
    }
  };
  getAwsCreditsData = (divId, divName) =>
    this.awsResellerCreditData.filter((resellerCreditData) => {
      if (!divName) {
        return true;
      }
      return resellerCreditData.divisionName === divName;
    });

  resellerIndexPPByEntryId = (id) => this.awsResellerRatesData.findIndex((resellerData) => resellerData.id === id);
  resellerCreditIndexByEntryId = (id) => this.awsResellerCreditData.findIndex((creditData) => creditData.id === id);
  resellerObjectById = (id) => {
    const indx = this.resellerIndexPPByEntryId(id);
    if (indx > -1) {
      return this.awsResellerRatesData[indx];
    }
    return {};
  };
  deleteOrReplacePrivPricingLocally = (id, ruleName, newPP) => {
    const indx = this.resellerIndexPPByEntryId(id);
    if (indx > -1) {
      if (newPP) {
        if (Array.isArray(newPP)) {
          const newPpLength = newPP.length;
          for (let index = 0; index < newPpLength; index++) {
            this.awsResellerRatesData.splice(indx + index, !index ? 1 : 0, newPP[index]);
          }
        } else {
          this.awsResellerRatesData.splice(indx, 1, newPP);
        }
      } else {
        this.awsResellerRatesData.splice(indx, 1);
      }
    }
  };
  deleteOrReplaceCreditsLocally = (id, divisionName, newCred) => {
    const indx = this.resellerCreditIndexByEntryId(id);
    if (indx > -1) {
      if (newCred) {
        if (Array.isArray(newCred)) {
          const newCreditLength = newCred.length;
          for (let index = 0; index < newCreditLength; index++) {
            this.awsResellerCreditData.splice(indx + index, !index ? 1 : 0, newCred[index]);
          }
        } else {
          this.awsResellerCreditData.splice(indx, 1, newCred);
        }
      } else {
        this.awsResellerCreditData.splice(indx, 1);
      }
    }
  };

  addCreditsData = async (creditsParamsWithShallowArrays) => {
    try {
      const result = await this.apiGateway.addCreditsData(creditsParamsWithShallowArrays);
      runInAction(() => {
        this.awsResellerCreditData = [...this.awsResellerCreditData, ...result];
      });
    } catch (error) {
      this.state = 'error';
      this.awsResellerCreditData = [...this.awsResellerCreditData];
    }
  };
  deleteCreditsData = async (id, divisionName) => {
    try {
      await this.apiGateway.deleteCreditsData(id, divisionName);
      this.deleteOrReplaceCreditsLocally(id, divisionName);
    } catch (error) {
      this.state = 'error';
    }
  };
  updateCreditsData = async (currEditedId, newCreditsParams) => {
    try {
      const { accountId, accountKey, divisionName } = this.resellerObjectById(currEditedId);
      const newCreditObject = { accountId, accountKey, ...newCreditsParams };
      const newCreditObjectResult = await this.apiGateway.updateCreditsData(currEditedId, newCreditObject);
      runInAction(() => {
        this.deleteOrReplaceCreditsLocally(currEditedId, divisionName, newCreditObjectResult);
      });
    } catch (error) {
      this.state = 'error';
    }
  };
  fetchExplainCreditHistoryData = async (creditId, startMonth, expirationMonth) => {
    let { endDate } = buildStartAndEndDate();
    const lastDayOfMonth = new Date(expirationMonth.split('-')[0], expirationMonth.split('-')[1], 0).getDate();
    endDate =
      new Date(`${expirationMonth}-${lastDayOfMonth}`) > new Date(endDate)
        ? endDate
        : `${expirationMonth}-${lastDayOfMonth}`;
    try {
      if (this.awsResellerCreditHistoryData.has(creditId)) {
        return;
      }
      const creditsHistory = await this.apiGateway.fetchExplainCreditHistoryData(creditId, `${startMonth}-01`, endDate);
      const updatedCreditsHistory = new Map(this.awsResellerCreditHistoryData);
      updatedCreditsHistory.set(creditId, creditsHistory);
      runInAction(() => {
        this.awsResellerCreditHistoryData = new Map(updatedCreditsHistory);
      });
    } catch (error) {
      this.state = 'error';
    }
  };
  updateCustomerDivisionTypeLocally = (accountKey, accountId, divisionId, newDivisionTypeId) => {
    const customerIndex = this.customersData.findIndex(
      (customer) =>
        customer.accountKey === accountKey && customer.accountId === accountId && customer.divisionId === divisionId,
    );
    if (customerIndex > -1) {
      this.customersData[customerIndex].divisionTypeId = newDivisionTypeId;
    }
    this.customersData.forEach((item, index) => {
      if (
        item.divisionId === parseInt(divisionId, 0) &&
        item.accountKey === parseInt(accountKey, 0) &&
        item.accountId === accountId
      ) {
        this.customersData[index].divisionTypeId = newDivisionTypeId;
      }
    });
    const updated = [...this.customersData];
    return updated;
  };

  updateCustomerTableDataTypeLocally = (accountKey, accountId, divisionId, newDivisionTypeId) => {
    const customerIndex = this.awsCustomersTableData.findIndex(
      (customer) =>
        customer.accountKey === accountKey && customer.accountId === accountId && customer.divisionId === divisionId,
    );
    if (customerIndex > -1) {
      this.awsCustomersTableData[customerIndex].divisionTypeId = newDivisionTypeId;
    }
    return [...this.awsCustomersTableData];
  };

  getAwsCustomersTable = async (startDate, endDate) => {
    let awsCustomersTableData = [];
    try {
      awsCustomersTableData = await this.apiGateway.getAwsCustomersTable(startDate, endDate);
      awsCustomersTableData = awsCustomersTableData.filter((data) => data.divisionId !== 0);
    } catch (error) {
      this.isLoading = false;
      this.state = 'error';
    }
    return awsCustomersTableData;
  };

  getCurrMonthAwsCustomersTableData = async () => {
    const startDate = DateFilter.getFirstDayOfCurrMonthDate();
    const endDate = DateFilter.getDateStr();
    this.isLoading = true;
    const result = await this.getAwsCustomersTable(startDate, endDate);
    runInAction(() => {
      this.awsCustomersTableData = result;
      this.isLoading = false;
    });
  };

  getCustomersHistoryData = async (startDate, endDate) => {
    const result = await this.getAwsCustomersTable(startDate, endDate);
    runInAction(() => {
      this.customerHistoryTableData = result;
      this.isLoading = false;
    });
  };
  fetchExplainCustomerFlexibilityMarginData = async (
    divisionId,
    divisionName,
    accountKey,
    flexibilityType,
    start = null,
    end = null,
  ) => {
    try {
      const { startDate, endDate } = buildStartAndEndDate(start, end);
      const data = await this.apiGateway.fetchExplainCustomerFlexibilityMarginData(
        divisionId,
        divisionName,
        accountKey,
        flexibilityType,
        startDate,
        endDate,
      );
      runInAction(() => {
        this.explainCustomerFlexibilityMarginData[flexibilityType] = [...data];
      });
    } catch (error) {
      this.state = 'error';
    }
    return this.customerHistoryTableData;
  };
  getExplainCustomerFlexibilityMarginData = (flexibilityType) =>
    this.explainCustomerFlexibilityMarginData[flexibilityType];
  deleteExplainCustomerFlexibilityMarginData = (flexibilityType) => {
    this.explainCustomerFlexibilityMarginData[flexibilityType] = [];
  };

  getResellerCustomerInvoiceData = async (rows, type) => {
    const results = await this.apiGateway.getResellerCustomerInvoiceData(rows, type);
    fileSaver.saveAs(b64toBlob(results), 'reseller-customers-invoices.zip');
  };
}
