import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import Button from 'shared/components/andtComponents/Button';
import { ReactComponent as CaretRightIcon } from 'shared/img/icons/caret-right.svg';

import InfoIconSVG from './assets/InfoIconSVG';
import styles from './ListWithHelp.module.scss';

const ListWithHelp = ({ list, title, help, styles: { listGap, helpWidth, hideControl, controlStyles = {} } }) => {
  const [activeHelp, setActiveHelp] = useState(list[0].key);
  const HelpComponent = help[activeHelp].component;
  const helpImage = help[activeHelp].image;
  const [imageOpen, setImageOpen] = useState(false);
  return (
    <div
      className={styles.container}
      data-wide={!!hideControl}
      style={{ gridTemplateColumns: `1fr ${helpWidth ? `${helpWidth}px` : '1fr'}` }}
    >
      {imageOpen &&
        createPortal(
          <>
            <div className={styles.imageModalBg} onClick={() => setImageOpen(false)} />
            <div className={styles.imageModal} onClick={() => setImageOpen(false)}>
              <img src={helpImage} alt="help" />
            </div>
          </>,
          document.getElementById('root'),
        )}
      <div className={styles.list} style={{ gap: listGap }}>
        {list
          .filter((item) => !item.disabled)
          .map((item) => (
            <div
              key={item.key}
              onClick={() => (!help[item.key] ? () => {} : setActiveHelp(item.key))}
              className={styles.listItem}
            >
              {item.render(() => setActiveHelp(item.key), activeHelp === item.key)}
              {!hideControl && help[item.key] && (
                <div className={styles.lineControl} style={{ top: item.topSpace }}>
                  <ListWithHelp.InfoIcon
                    style={controlStyles}
                    onClick={() => setActiveHelp(item.key)}
                    isActive={activeHelp === item.key}
                  />
                  {activeHelp === item.key && <CaretRightIcon />}
                </div>
              )}
            </div>
          ))}
      </div>
      <div className={styles.helpContent}>
        <div className={styles.helpTitle}>
          <InfoIconSVG /> {title}
        </div>
        {HelpComponent && <HelpComponent />}
        {helpImage && (
          <div className={styles.helpImage}>
            <Button
              className={{ button: styles.zoomButton }}
              text=""
              isTextButton
              onClick={() => setImageOpen(true)}
              icon={() => <GenerateIcon iconName={ICONS.magnifyingGlassPlus.name} />}
            />
            <div
              className={styles.image}
              style={{ backgroundImage: `url(${helpImage})`, height: help[activeHelp].imageHeight }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ListWithHelp.InfoIcon = ({ onClick, isActive, className, style }) => (
  <div className={`${styles.infoIcon} ${className || ''}`} style={style} onClick={onClick} data-active={isActive}>
    <InfoIconSVG />
  </div>
);

ListWithHelp.InfoIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
};

ListWithHelp.InfoIcon.defaultProps = {
  className: '',
  style: {},
  title: 'Help',
};

ListWithHelp.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      topSpace: PropTypes.number,
    }),
  ).isRequired,
  styles: PropTypes.shape({
    listGap: PropTypes.string,
    helpWidth: PropTypes.number,
    hideControl: PropTypes.bool,
    controlStyles: PropTypes.object,
  }).isRequired,
  help: PropTypes.objectOf(
    PropTypes.shape({
      component: PropTypes.elementType,
      image: PropTypes.string,
      imageHeight: PropTypes.number,
    }),
  ).isRequired,
};

export default ListWithHelp;
