/* eslint-disable max-len */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';

export class BaseRoutes {
  static Commitment = '/commitment';
  static CostAndUsage = '/cost-usage';
  static CostAllocation = '/cost-allocation';
  static Kubernetes = '/k8s';
  static Monitoring = '/monitoring';
  static NewUser = '/new-user';
  static Recommendations = '/recommendations';
  static RecommendationsNew = '/recommendations-new';
  static Services = '/services';
  static Partner = '/partner';
  static CostGpt = '/cost-gpt';
}

export const Routes = {
  //  Admin ****
  ADMIN_ACCOUNTS_STATUS: '/admin-accounts-status',
  //  General ****
  ACCOUNT: '/account',
  FEATURE_FLAGS: '/feature-flags',
  SETTINGS: '/settings',
  REGISTER: '/register',
  REGISTER_MSP: '/register-msp',
  REGISTER_SNOW: '/snow-register',
  REGISTER_SNOW_MSP: '/snow-register-msp',
  LOG_IN: '/log_in',
  RESET_PASSWORD: '/generate-password/:force?',
  ADMIN_CONTROL_PANEL: '/admin-control-panel',
  DASHBOARD: '/dashboard',
  ACCOUNTS_OVERVIEW: '/accounts-overview',
  NEW_USER: `${BaseRoutes.NewUser}`,
  NEW_AWS_USER: `${BaseRoutes.NewUser}/AWS`,
  NEW_AZURE_USER: `${BaseRoutes.NewUser}/AZURE`,
  NEW_GCP_USER: `${BaseRoutes.NewUser}/GCP`,
  ONBOARDING_AWS: '/onboarding/aws',
  ONBOARDING_AZURE: '/onboarding/azure',
  WELCOME: '/welcome',
  ALERTS: '/alerts',
  CUSTOMERS: '/customers',
  AZURE_BILLING_RULES: '/azure-billing-rules',
  SLACK_INTEGRATION: '/slack',
  TERMS_AND_CONDS: 'https://www.anodot.com/terms-of-use',
  PRIVACY_POLICY: 'https://www.anodot.com/privacy-policy',
  CONTACT_US: 'https://www.anodot.com/contact-us',
  CONNECT_AZURE_EA_ACCOUNT: '/guides/connect-ea-account',
  GCP_COST_SUMMARY: '/gcp-cost-summary',
  DESIGN_SYSTEM: '/design-system',
  // Partner ****
  AWS_BILLING_RULES: `${BaseRoutes.Partner}/aws-billing-rules`,
  AWS_CREDITS: `${BaseRoutes.Partner}/aws-credits`,
  AWS_CREDITS_NEW: `${BaseRoutes.Partner}/credits`,
  AWS_CUSTOMERS: `${BaseRoutes.Partner}/aws-customers`,
  CUSTOMERS_HISTORY: `${BaseRoutes.Partner}/customers-history`,
  AZURE_CUSTOMERS_HISTORY: `${BaseRoutes.Partner}/azure-customers-history`,
  CUSTOMERS_MANAGE: `${BaseRoutes.Partner}/customers-manage`,
  CUSTOMERS_REPORTS: `${BaseRoutes.Partner}/customers-reports`,
  AZURE_CUSTOMERS: `${BaseRoutes.Partner}/azure-customers`,
  REBILLING_PREFERENCES: `${BaseRoutes.Partner}/billing/preferences`,
  //  Commitment ****
  COMMITMENT_DASHBOARD: `${BaseRoutes.Commitment}/dashboard`,
  RI_UTILIZATION: `${BaseRoutes.Commitment}/utilization/ri`,
  SAVINGS_PLANS_UTILIZATION: `${BaseRoutes.Commitment}/utilization/sp`,
  SAVINGS_PLANS_ANALYZER: `${BaseRoutes.Commitment}/savings-plans-analyzer`,
  SAVINGS_PLANS_ANALYZER_REC: `${BaseRoutes.Commitment}/savings-plans-analyzer/:id`,
  // Cost & Usage ****
  REPORTS: `${BaseRoutes.CostAndUsage}/reports`,
  COST_USAGE_EXPLORER: `${BaseRoutes.CostAndUsage}/cost-usage-explorer`,
  METRICS_EXPLORER: `${BaseRoutes.CostAndUsage}/metrics-explorer`,
  RESOURCE_EXPLORER: `${BaseRoutes.CostAndUsage}/resource-explorer`,
  CUSTOM_DASHBOARD: `${BaseRoutes.CostAndUsage}/dashboards`,
  CUSTOM_DASHBOARD_INSTANCE: `${BaseRoutes.CostAndUsage}/dashboards/:id`,
  CUSTOM_DASHBOARD_TEMPLATE_INSTANCE: `${BaseRoutes.CostAndUsage}/dashboards-templates/:id`,
  CUSTOM_DASHBOARD_TEMPLATE: `${BaseRoutes.CostAndUsage}/dashboards-templates`,
  CUSTOM_DASHBOARDS_PANELS: `${BaseRoutes.CostAndUsage}/panels`,
  VIRTUAL_TAGS: `${BaseRoutes.CostAllocation}/virtual-tags`,
  FILTER_GROUP: `${BaseRoutes.CostAllocation}/filter-group`,
  BUSINESS_MAPPING: `${BaseRoutes.CostAllocation}/business-mapping`,
  BUSINESS_MAPPING_GLOBAL_GROUPS: `${BaseRoutes.CostAllocation}/business-mapping/global`,
  BUSINESS_MAPPING_GROUPS: `${BaseRoutes.CostAllocation}/business-mapping/:id`,
  ACCOUNT_TAGS: `${BaseRoutes.CostAllocation}/account-tags`,
  VIEWS: `${BaseRoutes.CostAllocation}/views`,
  ASSETS: `${BaseRoutes.CostAndUsage}/assets`,
  //  Monitoring ****
  CUE_ALERTS: `${BaseRoutes.Monitoring}/alerts`,
  ANOMALY_DETECTION: `${BaseRoutes.Monitoring}/anomaly-detection`,
  ANOMALY_DETECTION_NEW: `${BaseRoutes.Monitoring}/anomaly-detection-new`,
  BUDGET: `${BaseRoutes.Monitoring}/budget`,
  BUDGET_NEW: `${BaseRoutes.Monitoring}/budgetNew`,
  IDLE_INSTANCES: `${BaseRoutes.Monitoring}/idle-instances`,
  // Recommendations ****
  RECOMMENDATIONS: `${BaseRoutes.Recommendations}`,
  RECOMMENDATIONS_DASHBOARD: `${BaseRoutes.Recommendations}/recommendations-dashboard`,
  RECOMMENDATION_HISTORY: `${BaseRoutes.Recommendations}/recomm-history`,
  RECOMMENDATION_PREFERENCES: `${BaseRoutes.Recommendations}/recomm-preferences`,
  MANAGE_RECOMMENDATIONS: `${BaseRoutes.Recommendations}/recommendations-exclusions`, // renaming for now the original: '/manage-recommendations',
  DAILY_RECOMMENDATIONS: `${BaseRoutes.Recommendations}/daily-recommendations`,
  RECOMMENDATIONS_REPORTS: `${BaseRoutes.Recommendations}/recommendations-reports`,
  RECS_TRIAL: `${BaseRoutes.Recommendations}/recommendations-trial`,
  // Recommendations New ****
  RECOMMENDATIONS_NEW: `${BaseRoutes.RecommendationsNew}`,
  RECOMMENDATION_EXPLORER: `${BaseRoutes.RecommendationsNew}/recommendation-explorer`,
  RECOMMENDATION_360: `${BaseRoutes.RecommendationsNew}/recommendation-360`,
  // Services ****
  S3: `${BaseRoutes.Services}/s3`,
  EC2: `${BaseRoutes.Services}/ec2`,
  RDS: `${BaseRoutes.Services}/rds`,
  BIG_QUERY: `${BaseRoutes.Services}/big-query`,
  GcpAppEngine: `${BaseRoutes.Services}/app-engine`,
  GcpCloudSQL: `${BaseRoutes.Services}/cloud-sql`,
  GcpComputeEngine: `${BaseRoutes.Services}/compute-engine`,
  AZURE_VIRTUAL_MACHINES: `${BaseRoutes.Services}/azure-virtual-machines`,
  AZURE_STORAGE: `${BaseRoutes.Services}/azure-storage`,
  AZURE_DB_COSTS: `${BaseRoutes.Services}/azure-db-costs`,
  AzureActiveDirectory: `${BaseRoutes.Services}/active-directory`,
  AzureDataFactory: `${BaseRoutes.Services}/data-factory`,
  AzureSQL: `${BaseRoutes.Services}/azure-sql`,
  AZURE_RI_COVERAGE: `${BaseRoutes.Services}/azure-ri-coverage`,
  // Kubernetes ****
  K8S_PREVIEW: `${BaseRoutes.Kubernetes}/preview`,
  K8S_USAGE: `${BaseRoutes.Kubernetes}/usage`,
  K8S_PREFERENCES: `${BaseRoutes.Kubernetes}/preferences`,
  K8S_ASSETS: `${BaseRoutes.Kubernetes}/assets`,
  // CostGPT ****
  COST_GPT: `${BaseRoutes.CostGpt}`,
};

// eslint-disable-next-line wrap-iife
export const RecommsRoutes = (function () {
  const recommTypes = Object.values(RecommendationTypes);
  const recommRoutes = recommTypes.map((recommType) => `${Routes.RECOMMENDATIONS}/${recommType}-recs/:id`);
  return recommRoutes;
})();
