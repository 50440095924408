import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import PropTypes from 'prop-types';

export default class ConfigureAzureIntegration extends Component {
  static propTypes = {
    azureSelectedConfiguration: PropTypes.exact({
      ea: PropTypes.bool.isRequired,
      billingExport: PropTypes.bool.isRequired,
    }).isRequired,
    handleSelectAzureConfiguration: PropTypes.func.isRequired,
    usersStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.inAnim = false;
  }

  componentDidUpdate() {
    this.inAnim = true;
  }

  handleRadioButtonClicked = (e) => {
    const { handleSelectAzureConfiguration } = this.props;
    const selectedOption = e.target.name;
    handleSelectAzureConfiguration(selectedOption);
  };

  render() {
    const { azureSelectedConfiguration, usersStore } = this.props;
    const azureMCAEnabled = usersStore.getIsAzureAccountMCAOnboardingEnabled();
    return (
      <Container>
        <Row>
          <RadioGroup className="d-flex flex-column">
            {azureMCAEnabled && (
              <FormControlLabel
                control={
                  <Radio
                    name="mca"
                    checked={azureSelectedConfiguration === 'mca'}
                    onChange={(e) => this.handleRadioButtonClicked(e)}
                  />
                }
                label="MCA"
              />
            )}
            <FormControlLabel
              control={
                <Radio
                  name="ea"
                  checked={azureSelectedConfiguration === 'ea'}
                  onChange={(e) => this.handleRadioButtonClicked(e)}
                />
              }
              label="EA"
            />
            <FormControlLabel
              control={
                <Radio
                  name="payg"
                  checked={azureSelectedConfiguration === 'payg'}
                  onChange={(e) => this.handleRadioButtonClicked(e)}
                />
              }
              label="Pay as you Go"
            />
          </RadioGroup>
        </Row>
      </Container>
    );
  }
}
