import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import { BILLINGS_API_NAME, RECOMMENDATIONS_HEATMAP_ROOT, STATLE_TIME } from 'recommendationsNew/consts';
import {
  transformFiltersContextToApiFormat,
  transformSelectDataToClientFormat,
} from 'recommendationsNew/hooks/react-query/apiUtils';

async function fetchDynamicFilter(dynamicFilter, filters) {
  if (!dynamicFilter) {
    return null;
  }
  // const { [dynamicFilter]: currentFilter, ...filtersNotIncludingCurrent } = filters;
  const transformedFilters = transformFiltersContextToApiFormat(filters);
  const data = await API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_HEATMAP_ROOT}/dynamicFilter/${dynamicFilter}`, {
    body: {
      filters: transformedFilters || {},
      last_item_of_prev_page: null,
    },
  });
  // Transform data to match the format of react-select
  const pageData = transformSelectDataToClientFormat(data?.page, dynamicFilter);
  return data?.page ? { ...data, page: pageData } : data;
}

export default function useDynamicFilter(currentFilter = undefined) {
  const queryKey = [apiConstants.QUERY_KEYS.RECOMMENDATIONS_DYNAMIC_FILTER];

  return {
    invalidate: ({ currentFilter, filters }) =>
      queryClient.invalidateQueries([...queryKey, currentFilter, filters] || queryKey),
    reset: ({ currentFilter, filters }) => queryClient.resetQueries([...queryKey, currentFilter, filters] || queryKey),
    fetchDynamicFilter: (filters, customSelect) =>
      useQuery([...queryKey, currentFilter, filters], () => fetchDynamicFilter(currentFilter, filters), {
        retry: false,
        staleTime: STATLE_TIME,
        enabled: !!currentFilter,
        select: customSelect,
      }),
  };
}
