import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Container } from 'reactstrap';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import CustomerReportsPanel from './components/CustomerReportsPanel.jsx';

const CustomersReports = ({ usersStore, divisionsStore, invoiceStore, rootStore }) => (
  <Container>
    <PageHeader title={PageNames.CUSTOMERS_REPORTS} />
    <Card>
      <CardBody>
        <CustomerReportsPanel
          usersStore={usersStore}
          divisionsStore={divisionsStore}
          invoiceStore={invoiceStore}
          rootStore={rootStore}
        />
      </CardBody>
    </Card>
  </Container>
);

CustomersReports.propTypes = {
  usersStore: PropTypes.object.isRequired,
  divisionsStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
};

export default CustomersReports;
