import {
  AGGREGATION_FUNCTION,
  ElasticacheRiPlans,
  SpAnalyzerPaymentOptions,
  SpAnalyzerTerm,
} from 'usage/constants/usageConstants';

export const PREFERENCES_SELECT_VALUES = {
  paymentOption: Object.values(SpAnalyzerPaymentOptions),
  term: Object.values(SpAnalyzerTerm),
  preferredPlan: Object.values(ElasticacheRiPlans),
  cpuUtilizationAggregationFunction: Object.values(AGGREGATION_FUNCTION),
  iopsAggregationFunction: Object.values(AGGREGATION_FUNCTION),
};

export const PREFERENCES_SPECIAL_FIELDS = {
  coveragePercentage: {
    max: 100,
    isPercent: true,
  },
};
