/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars,arrow-parens,function-paren-newline */

import React, { PureComponent } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { FilterTypes, mapSpAnalyzerConstValueToDisplayValue } from 'usage/constants/usageConstants';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import AddBoxIcon from '@mui/icons-material/AddBox';
import green from '@mui/material/colors/green';
import { withStyles } from '@mui/styles';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import FieldFilter from 'shared/components/FieldFilter';
import { propComparator } from 'shared/utils/sortUtil';
import LabelCoordinator from 'shared/modules/labelCoordinator';

const styles = (theme) => ({
  root: {
    color: green[600],
  },
  checked: {},
});

class ImmediateFilterBar extends PureComponent {
  static propTypes = {
    selectedOptionsMap: PropTypes.object.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    fieldToFieldDistincValuesMap: PropTypes.object.isRequired,
    filterStatusTypeMap: PropTypes.object.isRequired,
    handleChangeFilterType: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    filterProps: PropTypes.object,
  };

  static defaultProps = {
    filterProps: {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getFilterLabel = (field) => {
    const label = LabelCoordinator.getFieldLabel(field);
    return label;
  };

  createOptions = (fieldValues, field) =>
    fieldValues.map((fieldValue) => {
      let value = '';
      let label = '';
      if (Object.prototype.toString.call(fieldValue) === '[object Object]' && 'linkedAccountId' in fieldValue) {
        value = fieldValue.linkedAccountName;
        label = fieldValue.displayLabel;
      } else if (field === 'division') {
        value = fieldValue.divisionName;
        label = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', fieldValue.divisionName);
      } else {
        value = fieldValue;
        label = fieldValue;
      }
      return { value, label };
    });

  tooltipActionDescription = (status) =>
    status === FilterTypes.INCLUDE
      ? mapSpAnalyzerConstValueToDisplayValue.get(FilterTypes.EXCLUDE)
      : mapSpAnalyzerConstValueToDisplayValue.get(FilterTypes.INCLUDE);

  renderFilters = (fieldToFieldDistincValuesMap, selectedOptionsMap) => {
    const { classes, filterProps } = this.props;
    const filters = [];
    if (!fieldToFieldDistincValuesMap) {
      return null;
    }
    if (fieldToFieldDistincValuesMap.size > 0) {
      fieldToFieldDistincValuesMap.forEach((fieldValues, field) => {
        const options = this.createOptions(fieldValues, field);
        if (AwsCommonFields.QUANTITY_TYPE !== field) {
          filters.push(
            <Col>
              <div>
                <div className="filter-bar-inner-col-wrapper full-width">
                  <div style={{ display: 'flex' }}>
                    <Tooltip
                      title={`Press here to ${this.tooltipActionDescription(
                        this.props.filterStatusTypeMap.get(field),
                      ).toLowerCase()} the selected values`}
                      placement="top"
                      arrow
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<AddBoxIcon fontSize="small" />}
                            checkedIcon={<IndeterminateCheckBoxIcon fontSize="small" />}
                            checked={this.props.filterStatusTypeMap.get(field)}
                            onChange={() => this.props.handleChangeFilterType(field)}
                            classes={{
                              root: classes.root,
                            }}
                          />
                        }
                      />
                    </Tooltip>
                    <h5 className="filter-bar-inner-col-title" style={{ marginTop: '15px', marginLeft: '-13px' }}>
                      {this.getFilterLabel(field)}
                    </h5>
                  </div>
                  <FieldFilter
                    {...(filterProps[field] || {})}
                    type={field}
                    value={selectedOptionsMap.get(field)}
                    options={options ? options.sort(propComparator('label')) : options}
                    handleChange={this.props.handleFilterChange}
                  />
                </div>
              </div>
            </Col>,
          );
        }
      });
    }

    return <Row>{filters}</Row>;
  };

  render() {
    const { fieldToFieldDistincValuesMap, selectedOptionsMap } = this.props;
    return <>{this.renderFilters(fieldToFieldDistincValuesMap, selectedOptionsMap)}</>;
  }
}

export default withStyles(styles)(ImmediateFilterBar);
