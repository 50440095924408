import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HeatMapContent from 'recommendationsNew/components/heatMap/heatMapContent';
import HeatMapSummary from 'recommendationsNew/components/heatMap/heatMapSummary';
import HeatMapCloseButton from 'recommendationsNew/components/heatMap/heatMapCloseButton';
import useGroupByOptions from 'recommendationsNew/hooks/react-query/useGroupByOptions';
import HeatMapContext from 'recommendationsNew/components/heatMap/contexts/heatMapContext';
import HeatMapFilters from 'recommendationsNew/components/heatMap/heatMapFilters/heatMapFilters';

import classes from './heatMap.module.scss';

const HeatMap = ({ usersStore }) => {
  const recommendationsGroupByOptionsHook = useGroupByOptions();
  const recommendationsGroupByOptions = recommendationsGroupByOptionsHook.fetchGroupByOptions();
  const { data: groupByOptionsData } = recommendationsGroupByOptions;

  const [isHeatMapClosed, setIsHeatMapClosed] = useState(false);

  const { setHeatMapGroupByOptions } = useContext(HeatMapContext);

  useEffect(() => {
    if (groupByOptionsData) {
      setHeatMapGroupByOptions(groupByOptionsData);
    }
  }, [groupByOptionsData]);

  const onCloseButtonClick = () => {
    setIsHeatMapClosed(!isHeatMapClosed);
  };

  return (
    <div className={classes.container}>
      <div className={classes.heatMapContainer}>
        <HeatMapFilters usersStore={usersStore} />
        {isHeatMapClosed ? <div /> : <HeatMapContent />}
        <HeatMapSummary />
      </div>
      <HeatMapCloseButton
        isClosed={isHeatMapClosed}
        onClick={onCloseButtonClick}
        automation-id="heatmap-close-open-button"
      />
    </div>
  );
};

HeatMap.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

export default HeatMap;
