/* eslint-disable no-unused-vars, max-len, react/jsx-curly-brace-presence, quotes */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

export default class WizardIntro extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.inAnim = false;
  }

  componentDidUpdate() {
    this.inAnim = true;
  }

  render() {
    return (
      <Row>
        <Col>
          <Col xs="auto" md="auto" lg="auto" xl="auto">
            <p style={{ padding: '10px 0px' }}>
              <span className="inner-wizard-input-legend">Link your account in several main steps</span>
            </p>
            <p style={{ padding: '10px 0px', margin: '0px' }}>
              <h5 className="inner-wizard-input-legend">
                <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
                <span style={{ marginLeft: '10px' }}>Account verification</span>
              </h5>
            </p>
            <p style={{ padding: '10px 0px', margin: '0px' }}>
              <h5 className="inner-wizard-input-legend">
                <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
                <span style={{ marginLeft: '10px' }}>Permission settings</span>
              </h5>
            </p>
            <p style={{ padding: '10px 0px', margin: '0px' }}>
              <h5 className="inner-wizard-input-legend">
                <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
                <span style={{ marginLeft: '10px' }}>Connect</span>
              </h5>
            </p>
          </Col>
        </Col>
      </Row>
    );
  }
}
