/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { Container, Row, UncontrolledTooltip } from 'reactstrap';
import If from 'shared/components/SimpleIf';
import DownloadAsPngLink from 'shared/components/DownloadAsPngLink';
import PropTypes from 'prop-types';
import { random5DigitNumber } from 'shared/utils/mathUtil';
import CustomTooltip from 'shared/components/CustomTooltip';
import {
  Area,
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  Pie,
  PieChart,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ChartKeysFilter from 'shared/components/ChartKeysFilter/ChartKeysFilter';
import { StringToColor } from 'shared/utils/graphicUtil';
import { updateDataIfOthersIsChecked } from 'shared/utils/dataPrepareUtil';
import CustomEventDot from 'shared/components/events/CustomEventDot';
import { CostTrackingConstants } from 'usage/constants/costAndUsageConstants';
import MagnifyMinusOutlineIcon from 'mdi-react/MagnifyMinusOutlineIcon';
import CustomizedDateXAxisTick from 'shared/components/CustomizedDateXAxisTick';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

class CostChart extends PureComponent {
  static propTypes = {
    chartId: PropTypes.string,
    verticalLinesData: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    data: PropTypes.object.isRequired,
    onEventCollapse: PropTypes.func,
    filteredKeys: PropTypes.object.isRequired,
    isInitialDataKeyFilterLoad: PropTypes.object.isRequired,
    favourites: PropTypes.object.isRequired,
    mainLegendKeysFilterHandler: PropTypes.object.isRequired,
    addKeysFilterHandler: PropTypes.func.isRequired,
    setKeysFilterHandler: PropTypes.func.isRequired,
    removeKeysFilterHandler: PropTypes.func.isRequired,
    isShowOthersChangeHandler: PropTypes.func.isRequired,
    isRenderKeysFilter: PropTypes.bool.isRequired,
    isNewKeyNeeded: PropTypes.bool.isRequired,
    yAxisLabelTitle: PropTypes.string,
    xAxisLabelTitle: PropTypes.string,
    isAreaChart: PropTypes.bool.isRequired,
    isPieChart: PropTypes.bool,
    isLineChart: PropTypes.bool,
    displayedMetric: PropTypes.object.isRequired,
    isRenderDownloadAsPng: PropTypes.bool,
    dataKeys: PropTypes.object.isRequired,
    isTrendLine: PropTypes.bool,
    isZoomAvailble: PropTypes.bool,
    toInt: PropTypes.bool,
    isOthersVisible: PropTypes.bool,
    isShowOthers: PropTypes.bool.isRequired,
    granLevel: PropTypes.string,
    groupBy: PropTypes.string,
    isFiltersOpen: PropTypes.bool,
    isFromReport: PropTypes.bool,
    isFromCustomDb: PropTypes.bool,
    visibleFilterKeysCount: PropTypes.number,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
  };

  static defaultProps = {
    chartId: null,
    yAxisLabelTitle: null,
    xAxisLabelTitle: null,
    isTrendLine: false,
    isZoomAvailble: false,
    isRenderDownloadAsPng: false,
    verticalLinesData: [],
    granLevel: null,
    onEventCollapse: () => {},
    groupBy: null,
    toInt: false,
    isOthersVisible: false,
    isFiltersOpen: false,
    isFromReport: false,
    isFromCustomDb: false,
    isPieChart: false,
    isLineChart: false,
    visibleFilterKeysCount: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      currKey: '',
      isZoom: false,
    };
    this.getAxisYDomain = this.getAxisYDomain.bind(this);
    this.zoom = this.zoom.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
  }

  componentDidMount() {
    const {
      isFromReport,
      dataKeys,
      filteredKeys,
      isInitialDataKeyFilterLoad,
      isShowOthersChangeHandler,
      mainLegendKeysFilterHandler,
      isShowOthers,
      isOthersVisible,
      isFromCustomDb,
    } = this.props;
    if (isFromReport) {
      isShowOthersChangeHandler(isShowOthers);
    } else {
      const filteredDataKeys = mainLegendKeysFilterHandler.filterDataKeys(
        dataKeys,
        filteredKeys,
        isInitialDataKeyFilterLoad,
      );
      if (!isFromCustomDb && isOthersVisible && isShowOthersChangeHandler) {
        if (filteredDataKeys.length < dataKeys.length) {
          isShowOthersChangeHandler(true);
        }
      }
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dataKeys } = this.props;
    if (dataKeys !== nextProps.dataKeys) {
      this.zoomOut();
    }
  }

  getAxisYDomain = (from, to, ref, offset) => {
    const { data } = this.props;
    const refData = data.filter((dailyData) => dailyData.usageDate >= from && dailyData.usageDate <= to);
    this.setState({ isZoom: true });
    let [bottom, top] = [refData[0][ref], refData[0][ref]];
    refData.forEach((d) => {
      if (d[ref] > top) {
        top = d[ref];
      }
      if (d[ref] < bottom) {
        bottom = d[ref];
      }
    });

    return [(bottom || 0) - offset, (top || 0) + offset];
  };

  calcChartYAxisTopPadding = (maxValue) => {
    let pad = 100;
    if (maxValue <= 1) {
      pad = 0.1;
    } else if (maxValue <= 50) {
      pad = 1;
    } else if (maxValue <= 300) {
      pad = 10;
    } else if (maxValue <= 1000) {
      pad = 50;
    }
    return pad;
  };

  prepareDataForPie = (data) =>
    Object.values(
      data.reduce((acc, currData) => {
        Object.entries(currData).forEach(([key, value]) => {
          if (key !== 'usageDate' && key !== 'totalSum') {
            if (!acc[key]) {
              acc[key] = {
                value: 0,
                name: key,
              };
            }
            acc[key].value += +value;
          }
        });
        return acc;
      }, {}),
    );
  prepareTotalSumWithOthersAndZoomForTrendLine = (data, filteredKeys, filteredDataKeys) => {
    const { data: dataFromProps } = this.props;
    const { isZoom, isShowOthers } = this.state;
    const dataKeysNamesArr = [];
    let maxValue = 0;
    let minValue = 0;
    let maxSingleValue = 0;
    let refData = data;
    if (isZoom) {
      const { left, right } = this.state;
      refData = dataFromProps.filter((dailyData) => dailyData.usageDate >= left && dailyData.usageDate <= right);
    }
    updateDataIfOthersIsChecked(refData, filteredKeys, filteredDataKeys, CostTrackingConstants.OTHERS, isShowOthers);
    filteredKeys.forEach((dataKey) => dataKeysNamesArr.push(dataKey.name));
    const dataWithTotalCost = refData.map((dataObj) => {
      const newDataObj = {};
      Object.keys(dataObj).forEach((e) => {
        if (filteredKeys.includes(e) || e === CostTrackingConstants.OTHERS) {
          newDataObj[e] = dataObj[e];
        }
      });
      const dateTotalSum = Object.values(newDataObj).reduce((accumulator, currValue) => {
        maxSingleValue = Math.max(maxSingleValue, parseFloat(currValue));
        return accumulator + parseFloat(currValue);
      }, 0);
      newDataObj.totalSum = dateTotalSum;
      newDataObj.usageDate = dataObj.usageDate;
      maxValue = Math.max(dateTotalSum, maxValue);
      minValue = Math.min(dateTotalSum, minValue);
      return newDataObj;
    });
    return { dataWithTotalCost, maxValue, minValue, maxSingleValue };
  };

  zoom = () => {
    let { refAreaLeft, refAreaRight } = this.state;

    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      this.setState(() => ({
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) {
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
    }

    // yAxis domain
    const [bottom, top] = this.getAxisYDomain(refAreaLeft, refAreaRight, 'cost', 1);
    const [bottom2, top2] = this.getAxisYDomain(refAreaLeft, refAreaRight, 'impression', 50);

    this.setState(() => ({
      refAreaLeft: '',
      refAreaRight: '',
      left: refAreaLeft,
      right: refAreaRight,
      bottom,
      top,
      bottom2,
      top2,
    }));
  };

  zoomOut = () => {
    const { data } = this.props;
    this.setState(() => ({
      data,
      refAreaLeft: '',
      refAreaRight: '',
      left: 'dataMin',
      right: 'dataMax',
      top: 'dataMax+1',
      bottom: 'dataMin',
      top2: 'dataMax+50',
      isZoom: false,
    }));
  };

  trendLineLabelFormatter = (value) => {
    const { displayedMetric, numStrAbriviaionByDisplayMetric } = this.props;
    const newVal = numStrAbriviaionByDisplayMetric(value, value, displayedMetric, false, { isTrunc: true });
    return newVal;
  };

  renderChartByType = (dataKeys) => {
    const { isAreaChart, isLineChart } = this.props;
    if (isAreaChart) {
      return this.renderArea(dataKeys);
    }
    if (isLineChart) {
      return this.renderLineChart(dataKeys);
    }
    return this.renderBar(dataKeys);
  };

  renderLineChart(dataKeys) {
    const { currKey } = this.state;
    return dataKeys.map((dataKey) => {
      const customMouseOver = () => {
        if (dataKey.name !== currKey) {
          this.setState({ currKey: dataKey.name });
        }
      };
      return (
        <Line
          type="monotone"
          dataKey={dataKey.name}
          stackId="a"
          stroke={
            dataKey.name === CostTrackingConstants.OTHERS
              ? CostTrackingConstants.OTHERS_NAME_COLOR
              : StringToColor.next(dataKey.name)
          }
          fill={
            dataKey.name === CostTrackingConstants.OTHERS
              ? CostTrackingConstants.OTHERS_NAME_COLOR
              : StringToColor.next(dataKey.name)
          }
          onMouseOver={customMouseOver}
          strokeWidth={1.5}
        />
      );
    });
  }

  renderArea(dataKeys) {
    const { currKey } = this.state;
    return dataKeys.map((dataKey) => {
      const customMouseOver = () => {
        if (dataKey.name !== currKey) {
          this.setState({ currKey: dataKey.name });
        }
      };
      return (
        <Area
          cursor="default"
          dataKey={dataKey.name}
          stackId="a"
          stroke={
            dataKey.name === CostTrackingConstants.OTHERS
              ? CostTrackingConstants.OTHERS_NAME_COLOR
              : StringToColor.next(dataKey.name)
          }
          fill={
            dataKey.name === CostTrackingConstants.OTHERS
              ? CostTrackingConstants.OTHERS_NAME_COLOR
              : StringToColor.next(dataKey.name)
          }
          onMouseOver={customMouseOver}
          type="monotone"
        />
      );
    });
  }

  renderBar(dataKeys) {
    const { currKey } = this.state;
    return dataKeys.map((dataKey) => {
      const customMouseOver = () => {
        if (dataKey.name !== currKey) {
          this.setState({ currKey: dataKey.name });
        }
      };
      return (
        <Bar
          cursor="default"
          barSize={28}
          dataKey={dataKey.name}
          stackId="a"
          fill={
            dataKey.name === CostTrackingConstants.OTHERS
              ? CostTrackingConstants.OTHERS_NAME_COLOR
              : StringToColor.next(dataKey.name)
          }
          onMouseOver={customMouseOver}
        />
      );
    });
  }

  renderLine = (dataKeys) =>
    dataKeys.map((dataKey) => <Line type="monotone" dataKey={dataKey.name} stroke="#ff7300" />);

  render() {
    const {
      data,
      favourites,
      displayedMetric,
      mainLegendKeysFilterHandler,
      isInitialDataKeyFilterLoad,
      filteredKeys,
      addKeysFilterHandler,
      setKeysFilterHandler,
      removeKeysFilterHandler,
      isShowOthersChangeHandler,
      isNewKeyNeeded,
      isRenderKeysFilter,
      isRenderDownloadAsPng,
      chartId,
      yAxisLabelTitle,
      dataKeys,
      xAxisLabelTitle,
      isTrendLine,
      isZoomAvailble,
      isShowOthers,
      verticalLinesData,
      toInt,
      isOthersVisible,
      groupBy,
      isFiltersOpen,
      isPieChart,
      isLineChart,
      onEventCollapse,
      visibleFilterKeysCount,
      granLevel,
      numStrAbriviaionByDisplayMetric,
    } = this.props;
    const { refAreaLeft, refAreaRight, currKey, isZoom } = this.state;
    const finalChartId = chartId || `cost-chart-${random5DigitNumber()}`;
    const filteredDataKeys = mainLegendKeysFilterHandler.filterDataKeys(
      dataKeys,
      filteredKeys,
      isInitialDataKeyFilterLoad,
    );
    const chartKeysData = mainLegendKeysFilterHandler.prepareFilterKeysFromDataKeys(dataKeys);
    const { dataWithTotalCost, maxValue, minValue, maxSingleValue } = isRenderKeysFilter
      ? this.prepareTotalSumWithOthersAndZoomForTrendLine(data, filteredKeys, filteredDataKeys)
      : this.prepareTotalSumWithOthersAndZoomForTrendLine(data, dataKeys);
    let dataForPieChart = [];
    let totalCostForPieChart = 0;
    if (isPieChart) {
      dataForPieChart = this.prepareDataForPie(dataWithTotalCost);
      totalCostForPieChart = dataForPieChart.reduce((acc, currData) => acc + +currData.value, 0);
    }
    const pieChartLabel = numStrAbriviaionByDisplayMetric(totalCostForPieChart, totalCostForPieChart, displayedMetric);
    return (
      <Container>
        <If cond={isRenderDownloadAsPng}>
          <DownloadAsPngLink chartId={finalChartId} />
        </If>
        <Row
          id={finalChartId}
          style={isFiltersOpen ? { boxShadow: 'none', border: 'none' } : {}}
          className="cost-chart-container"
        >
          <If cond={isZoomAvailble}>
            <button
              className="btn-no-style zoom-out-btn"
              onClick={this.zoomOut}
              style={{ display: isZoom ? null : 'none' }}
              type="button"
              id="TooltipTop"
            >
              <MagnifyMinusOutlineIcon />
            </button>
            <UncontrolledTooltip placement="top" target="TooltipTop">
              Zoom out
            </UncontrolledTooltip>{' '}
          </If>
          <If cond={isPieChart}>
            <p
              style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '400px',
                width: '100%',
                textAlign: 'center',
                margin: 0,
              }}
            >
              <span style={{ fontSize: '17px' }}>{`${displayedMetric}:`}</span>
              <b style={{ fontSize: '17px' }}>{pieChartLabel}</b>
            </p>
          </If>
          <ResponsiveContainer height={400}>
            {isPieChart ? (
              <PieChart>
                <Pie data={dataForPieChart} dataKey="value" nameKey="name" innerRadius="60%" outerRadius="85%">
                  {dataForPieChart.map((currData) => (
                    <Cell fill={currData.name === 'Others' ? '#afafaf' : StringToColor.next(currData.name)} />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value) => numStrAbriviaionByDisplayMetric(totalCostForPieChart, value, displayedMetric)}
                />
              </PieChart>
            ) : (
              <ComposedChart
                stackOffset="sign"
                width={1000}
                height={400}
                data={dataWithTotalCost}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 20,
                }}
                onMouseDown={
                  isZoomAvailble
                    ? (e) => (e && e.activeLabel ? this.setState({ refAreaLeft: e.activeLabel }) : null)
                    : null
                }
                onMouseMove={
                  isZoomAvailble
                    ? (e) => (e && e.activeLabel ? refAreaLeft && this.setState({ refAreaRight: e.activeLabel }) : null)
                    : null
                }
                onMouseUp={isZoomAvailble && refAreaLeft && refAreaRight ? this.zoom : null}
                cursor={isZoomAvailble ? 'Crosshair' : null}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  label={{ value: xAxisLabelTitle || '' }}
                  dataKey="usageDate"
                  tickCount={0}
                  tick={<CustomizedDateXAxisTick granLevel={granLevel} />}
                />
                <YAxis
                  tickLine
                  label={{
                    value: yAxisLabelTitle || displayedMetric,
                    offset: 0,
                    angle: -90,
                    position: 'left',
                  }}
                  type="number"
                  domain={['dataMin', (dataMax) => (isLineChart && !isTrendLine ? maxSingleValue : dataMax)]}
                  padding={{ top: isTrendLine ? 60 : 20 }} // add top margin for trendline labels
                  tickFormatter={(value) =>
                    numStrAbriviaionByDisplayMetric(
                      // eslint-disable-next-line max-len
                      // calc max shown value for formatting (-10 000 means we need to format like 10K as well as 10 000)
                      Math.max(maxValue, Math.abs(minValue)),
                      value,
                      displayedMetric,
                    )
                  }
                />
                <Tooltip
                  cursor={false}
                  content={
                    <CustomTooltip data={data} currKey={currKey} displayedMetric={displayedMetric} toInt={toInt} />
                  }
                />
                {isRenderKeysFilter ? null : <Legend />}
                <ReferenceLine y={0} stroke="#000" />
                {this.renderChartByType(filteredDataKeys)}
                {isTrendLine ? (
                  <Line type="linear" dataKey="totalSum" stroke="#ff7300" isAnimationActive={false}>
                    <LabelList
                      dataKey="totalSum"
                      position="top"
                      angle="35"
                      offset={20}
                      formatter={this.trendLineLabelFormatter}
                    />
                  </Line>
                ) : null}
                {refAreaLeft && refAreaRight ? (
                  <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                ) : null}
                {verticalLinesData.map((line) => (
                  <ReferenceLine
                    x={line.x}
                    isFront
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onEventCollapse(line.id);
                    }}
                    strokeWidth={1}
                    stroke="#000"
                    strokeDasharray="6 6"
                  />
                ))}
                {verticalLinesData.map((line) => (
                  <ReferenceDot
                    x={line.x}
                    y={maxSingleValue}
                    onClick={(e) => {
                      e.stopPropagation();
                      onEventCollapse(line.id);
                    }}
                    shape={<CustomEventDot title={`${line.date}: ${line.label}`} />}
                    r={6}
                    stroke="none"
                  />
                ))}
              </ComposedChart>
            )}
          </ResponsiveContainer>
        </Row>
        <div>
          <If cond={isRenderKeysFilter}>
            <Row style={{ marginTop: '20px', marginLeft: isFiltersOpen ? '50px' : '60px' }}>
              <ChartKeysFilter
                data={chartKeysData}
                favourites={favourites}
                filteredKeys={filteredKeys}
                addKeysFilterHandler={addKeysFilterHandler}
                setKeysFilterHandler={setKeysFilterHandler}
                removeKeysFilterHandler={removeKeysFilterHandler}
                isOthersVisible={isOthersVisible}
                isShowOthers={isShowOthers}
                isShowOthersChange={isShowOthersChangeHandler}
                isNewKeyNeeded={isNewKeyNeeded}
                filteredDataKeys={filteredDataKeys}
                groupBy={groupBy}
                isLineChart={isLineChart}
                visibleFilterKeysCount={visibleFilterKeysCount}
              />
            </Row>
          </If>
        </div>
      </Container>
    );
  }
}

const ObserverCostChart = withUserSettingsConsumer(CostChart);

export default ObserverCostChart;
