import download from '../img/icons/download.png';
import filter from '../img/icons/filter.png';
import table from '../img/icons/table.png';
import barChart from '../img/icons/bar_chart.png';
import save from '../img/icons/save.png';
import calendar from '../img/icons/calendar.png';
import upArrow from '../img/icons/up_arrow.png';
import downArrow from '../img/icons/down_arrow.png';
import cloud from '../img/icons/cloud.png';
import reportGlass from '../img/icons/report_glass.svg';
import notification from '../img/icons/notification.png';
import profileWhite from '../img/icons/profile_white.png';
import subAccount from '../img/icons/sub_account.png';
import subAccounts from '../img/icons/sub_accounts.svg';
import plus from '../img/icons/plus.svg';
import plusNoFill from '../img/icons/plus_no_fill.svg';
import dashboard from '../img/icons/dashboard.svg';
import costUsage from '../img/icons/cost_usage.svg';
import budget from '../img/icons/budget.svg';
import recommendations from '../img/icons/recommendations.svg';
import service from '../img/icons/service.svg';
import anomaly from '../img/icons/anomaly_detection.svg';
import tags from '../img/icons/tags.svg';
import cpu from '../img/icons/CPU.svg';
import logoDark from '../img/logo/logo_dark.svg';
// import logoWhite from '../img/logo/logo_white.png';
import ssl from '../img/icons/ssl.svg';
import pci from '../img/icons/pci.png';
import registerCostUsage from '../img/icons/register_cost_usage.svg';
import registerTags from '../img/icons/register_tags.svg';
import registerRecommendations from '../img/icons/register_recommendations.svg';
import users from '../img/icons/users.svg';
import pencilEdit from '../img/icons/PencilIcon';
import computeIcon from '../img/icons/Compute.svg';
import dataBaseIcon from '../img/icons/Database.svg';
import networkIcon from '../img/icons/Network.svg';
import storageIcon from '../img/icons/Storage.svg';
import commitmentIcon from '../img/icons/commitment.svg';
import noDataIcon from '../img/icons/database-search.svg';
// import monitoringIcon from '../img/icons/database-search-white.svg';
import monitoringIcon from '../img/icons/monitor-line.svg';
import buttonX from '../img/icons/button_x.svg';
import pencilOutline from '../img/icons/pencil-circle-outline.svg';
import pencilOutlinePng from '../img/icons/pencil-circle-outline-png.png';
import squareEdit from '../img/icons/square_edit.svg';
import k8sIcon from '../img/icons/kubernetes_icon.svg';
import costAllocationIcon from '../img/icons/cost-allocation.svg';
import DiskSaveIcon from '../img/icons/disk-save-icon.svg';
import refresh from '../img/icons/refresh.svg';
import remove from '../img/icons/remove.svg';
import resume from '../img/icons/resume.svg';
import eyeLine from '../img/icons/eye-line.svg';
import formLine from '../img/icons/form-line.svg';
import eventLine from '../img/icons/event-line.svg';
import storageLine from '../img/icons/storage-line.svg';
import eyeOff from '../img/icons/eye-off.png';
import eye from '../img/icons/eye.png';
import hamburger from '../img/icons/hamburger.svg';
import notifications from '../img/icons/topbar/notifications.svg';
import accounts from '../img/icons/topbar/accounts.svg';
import userAccount from '../img/icons/topbar/userAccount.svg';
import userProfile from '../img/icons/topbar/userProfile.svg';
import logout from '../img/icons/topbar/logout.svg';
import userImpersonate from '../img/icons/topbar/userImpersonate.svg';
import mainUser from '../img/icons/topbar/mainUser.svg';
import info from '../img/icons/info.svg';
import emptyOptions from '../img/icons/emptyOptions.svg';
import alert from '../img/icons/alert.svg';
import alertBlack from '../img/icons/alert-black.svg';
import emptyAlert from '../img/icons/emptyAlert.svg';
import collapse from '../img/icons/collapse.svg';
import exportIcon from '../img/icons/export.svg';
import andtCalendar from '../img/icons/andtCalendar.svg';
import clone from '../img/icons/clone.svg';
import deleteIcon from '../img/icons/delete.svg';
import circleCheckedSolid from '../img/icons/circle-check-solid.svg';
import circleXmark from '../img/icons/circle-xmark-solid.svg';

const ICONS = {
  exportIcon,
  collapse,
  download,
  filter,
  table,
  barChart,
  save,
  calendar,
  upArrow,
  downArrow,
  cloud,
  reportGlass,
  plus,
  // logoWhite,
  logoDark,
  dashboard,
  costUsage,
  budget,
  recommendations,
  service,
  anomaly,
  tags,
  cpu,
  notification,
  profileWhite,
  subAccounts,
  subAccount,
  ssl,
  pci,
  registerCostUsage,
  registerTags,
  registerRecommendations,
  users,
  pencilEdit,
  noDataIcon,
  monitoringIcon,
  commitmentIcon,
  buttonX,
  pencilOutline,
  pencilOutlinePng,
  plusNoFill,
  squareEdit,
  k8sIcon,
  costAllocationIcon,
  DiskSaveIcon,
  recDashboard: {
    computeIcon,
    dataBaseIcon,
    networkIcon,
    storageIcon,
  },
  refresh,
  remove,
  resume,
  eyeLine,
  formLine,
  eventLine,
  storageLine,
  eyeOff,
  eye,
  hamburger,
  notifications,
  accounts,
  userAccount,
  userProfile,
  logout,
  userImpersonate,
  mainUser,
  info,
  emptyOptions,
  alert,
  alertBlack,
  emptyAlert,
  andtCalendar,
  clone,
  deleteIcon,
  circleCheckedSolid,
  circleXmark,
};

export default ICONS;
