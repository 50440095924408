export const SPLIT_TYPES = {
  EQUAL: 'EQUAL',
  RATIO: 'RATIO',
  CUSTOM: 'CUSTOM',
};

export const SPLIT_TYPES_NAMES = {
  [SPLIT_TYPES.EQUAL]: 'Equal',
  [SPLIT_TYPES.RATIO]: 'Ratio',
  [SPLIT_TYPES.CUSTOM]: 'Custom',
};

export const SPLIT_TYPES_DESCRIPTIONS = {
  [SPLIT_TYPES.EQUAL]: 'Costs will be split evenly among these mappings.',
  [SPLIT_TYPES.RATIO]: "Costs will be split based on proportions of each mapping's current costs.",
};

export const ALL_TAGS_FIELD = 'All Tags';
export const SELECT_ALL_FIELD = 'select_all';

export const BUSINESS_MAPPING_COLUMNS = {
  NAME: 'Mapping',
  RANK: 'Rank',
  CREATED_BY: 'Created By',
  CREATED_DATE: 'Creation Date',
  TYPE: 'Type',
};

export const BUSINESS_MAPPING_GLOBAL_COLUMNS = {
  NAME: 'Mapping',
  CREATED_BY: 'Created By',
  CREATED_DATE: 'Creation Date',
  VIEWPOINTS: 'Viewpoint',
  TYPE: '',
};

export const BUSINESS_MAPPING_COLUMNS_WIDTH = {
  NAME: '250px',
  RANK: '100px',
  VIEWPOINTS: '300px',
  CREATED_BY: '220px',
  CREATED_DATE: '180px',
  TYPE: '90px',
};

export const sumPercentages = (customDivision) =>
  Object.values(customDivision).reduce((a, b) => +(a || 0) + +(b || 0), 0);

export const isCustomDivisionNotFinished = (customDivision) =>
  Object.values(customDivision).length > 0 && sumPercentages(customDivision) !== 100;
