import { Table, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table
    {...restProps}
    className={[
      classes.tableStriped,
      classes.tableTotalRow,
      classes.textAdjustment,
      classes.rowHover,
      classes.lastCellAlignRight,
      classes.columnWidth,
    ]
      .filter(Boolean)
      .join(' ')}
  />
);

const TableWrapper = ({ styles = {}, containerStyle = {}, horizontalScroll = true, virtual = false, ...props }) => {
  const ContainerComponent = (containerProps) => (
    <Table.Container
      style={{ ...containerStyle }}
      className={horizontalScroll ? 'show-scroll show-scroll-horizontal table-wrapper' : 'table-wrapper'}
      {...containerProps}
    />
  );

  const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

  return (
    <>
      {virtual ? (
        <VirtualTable {...props} tableComponent={TableComponent} />
      ) : (
        <Table {...props} tableComponent={TableComponent} containerComponent={ContainerComponent} />
      )}
    </>
  );
};

TableWrapper.propTypes = {
  styles: PropTypes.objectOf(PropTypes.any),
  containerStyle: PropTypes.objectOf(PropTypes.any),
  horizontalScroll: PropTypes.bool,
  virtual: PropTypes.bool,
  height: PropTypes.number,
};
TableWrapper.defaultProps = {
  styles: {},
  containerStyle: {},
  horizontalScroll: false,
  virtual: false,
  height: 530,
};
TableComponentBase.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
};
TableComponentBase.defaultProps = {
  classes: {},
};

export default TableWrapper;
