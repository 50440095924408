import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Alert } from 'shared/img/icons/alert.svg';
import { ReactComponent as EmptyAlert } from 'shared/img/icons/empty-alert.svg';
import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './budgets.module.scss';

const WhiteTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white !important',
    borderRadius: '6px',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    zIndex: 100,
  },
  arrow: {
    color: 'white !important',
  },
}))(Tooltip);

const AlertedColumn = ({ value: alerts, budgetSpend }) => {
  if (!alerts || !alerts.length) {
    return (
      <div className={styles.emptySvgWrapper}>
        <EmptyAlert />
      </div>
    );
  }

  const getAlertTrigger = (alert) => {
    const forecast = alert.whenToAlert?.find((val) => val === 'forecasted');
    const actual = alert.whenToAlert?.find((val) => val === 'actualUsage');
    if (forecast && actual) {
      return 'Actual/Forecast';
    }
    if (forecast) return 'Forecast cost';
    if (actual) return 'Actual cost';
    return '';
  };
  return (
    <WhiteTooltip
      placement="left"
      title={
        <div className={`${styles.tooltipWrap}`}>
          {alerts.map((alert) => (
            <div className={styles.tooltipRow}>
              <span className={budgetSpend > alert.budgetPercentToAlertFrom ? styles.exceeded : ''}>
                {`${getAlertTrigger(alert)} >  ${alert.budgetPercentToAlertFrom}%`}
              </span>
              <div className={styles.svgWrapper}>
                <Alert className={budgetSpend > alert.budgetPercentToAlertFrom ? styles.pink : styles.green} />
              </div>
              <br />
            </div>
          ))}
        </div>
      }
      arrow
    >
      <div className={styles.svgWrapper}>
        <Alert className={alerts.find((a) => a.budgetPercentToAlertFrom < budgetSpend) ? styles.pink : styles.green} />
      </div>
    </WhiteTooltip>
  );
};

AlertedColumn.propTypes = {
  value: PropTypes.array,
  budgetSpend: PropTypes.number.isRequired,
};
AlertedColumn.defaultProps = {
  value: [],
};
export default AlertedColumn;
