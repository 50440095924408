/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'react-smooth-scrollbar';
import VerticalWizardStepper from './VerticalWizardStepper';

const SideBarNavigationTimeline = memo((props) => (
  <div className="sidebar--newuser">
    <Scrollbar className="sidebar__scroll__new-user scroll">
      <div className="sidebar__wrapper sidebar__wrapper--desktop">
        <div className="inner__scroll__header">
          <p className="main-title">{`Connect Your ${props.cloudProviderName} Account`}</p>
          <p className="sub-title">This will enable analyzing your cloud data</p>
        </div>
      </div>
      <div>
        <VerticalWizardStepper activeIndex={props.activeIndex} length={props.length} steps={props.steps} />
      </div>
    </Scrollbar>
  </div>
));

SideBarNavigationTimeline.prototype = {
  activeIndex: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  cloudProviderName: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SideBarNavigationTimeline;
