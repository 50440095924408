/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { getMonthNameFromNumber } from 'shared/utils/dateUtil';
import InputCost from '../InputCost';

class BudgetPlannigForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  style = {
    border: '1px solid #dfdfdf',
    padding: '10px',
    margin: '10px',
    display: 'flex',
    'flex-wrap': 'wrap',
    'justify-content': 'flex-start',
    width: 'fit-content',
  };

  createInputLabel = (date) => {
    const dateMonth = date.getUTCMonth();
    const dateYear = date.getUTCFullYear();
    return `${getMonthNameFromNumber(dateMonth + 1)} ${dateYear}`;
  };

  renderInputs = () => {
    const inputsArr = [];
    if (this.props.tempBudget.budgetAmounts.length) {
      this.props.tempBudget.budgetAmounts.forEach((budgetAmount) => {
        inputsArr.push(
          <div className="budget-cost-input">
            <h5 className="filter-bar-inner-col-title">{this.createInputLabel(new Date(budgetAmount.date))}</h5>
            <InputCost
              // name="1"
              value={budgetAmount.amount}
              label=""
              onChange={(e) =>
                this.props.handleUpdateBudgetInBudgetAmounts(
                  this.props.tempBudgetType,
                  budgetAmount.date,
                  e.target.value,
                )
              }
              className="insert-budget-input"
            />
          </div>,
        );
      });
    }
    return inputsArr;
  };

  render() {
    // return <Row>{this.renderInputs()}</Row>;
    return <div style={this.style}>{this.renderInputs()}</div>;
  }
}

BudgetPlannigForm.propTypes = {
  tempBudget: PropTypes.object.isRequired,
  handleUpdateBudgetInBudgetAmounts: PropTypes.func.isRequired,
  tempBudgetType: PropTypes.string.isRequired,
  budgetAmounts: PropTypes.object.isRequired,
};

const ObserverBudgetPlannigForm = observer(BudgetPlannigForm);
export default ObserverBudgetPlannigForm;
