import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { Routes } from 'shared/constants/routes';
import { TABS_IDS } from 'users/constants/usersConstants';
import AzureMCAMigrationModal from './AzureMCAMigrationModal/AzureMCAMigrationModal';

const AzureMCAMigration = ({ usersStore, history }) => {
  const [migrationModalOpen, setMigrationModalOpen] = useState(false);
  useEffect(() => {
    if (usersStore.getIsAzureAccountMigrationRequired()) {
      setMigrationModalOpen(true);
    }
  }, [usersStore.getIsAzureAccountMigrationRequired()]);
  return (
    <AzureMCAMigrationModal
      open={migrationModalOpen}
      onContinue={() => {
        history.push(Routes.ACCOUNT, {
          defaultTabId: TABS_IDS.CLOUD_ACCOUNTS,
        });
        setMigrationModalOpen(false);
      }}
      onClose={() => setMigrationModalOpen(false)}
    />
  );
};

AzureMCAMigration.propTypes = {
  usersStore: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(observer(AzureMCAMigration));
