/* eslint-disable class-methods-use-this */
import React from 'react';
import { roundNumber } from 'shared/utils/mathUtil';
import ContentDetail from 'recommendations/shared/components/ContentDetail';
import { ContentPageTypes, RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { intersperse, isDecimalNeeded, numberWithCommas, percentStr, strNumToSize } from 'shared/utils/strUtil';
import { buildResourceIdAwsConsoleLink, buildResourceNameAwsConsoleLink } from 'shared/utils/awsUtils';
import NewTabLink from 'shared/components/NewTabLink';
import { CURRENCY_DETAILS } from 'shared/constants/appConstants';

const addResourceTagsToContent = (rec, detailArray) => {
  if (rec.resourceTags && rec.resourceTags.length) {
    detailArray.push(<ContentDetail display="Tags:" value={rec.resourceTags.join('\n')} />);
  }
};

const buildLink = (url, displayedText, className = '') => (
  <div className={className}>
    <a href={url} target="_blank" rel="noopener noreferrer">
      {displayedText}
    </a>
  </div>
);

class CostContent {
  constructor(rec, getCurrencyNumber, currencyCode, currentCostTP) {
    this.rec = rec;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyTooltip =
      currencyCode && currencyCode !== CURRENCY_DETAILS.USD ? CURRENCY_DETAILS.SAVING_CALCULATE_TOOLTIP : null;
    this.currentCostTP = currentCostTP;
  }

  createCostContent(rec) {
    const newAnnualCost =
      rec?.selectedOption >= 0 ? rec.alternatives[rec.selectedOption].annualCost : rec?.recommTotalCost;
    const contentDetailsArray = [];
    contentDetailsArray.push(
      <ContentDetail
        display="Current annual cost:"
        value={this.getCurrencyNumber(rec.currTotalCost, 0)}
        tooltip={this.currentCostTP}
      />,
    );
    contentDetailsArray.push(
      <ContentDetail
        display="New annual cost:"
        tooltip={this.currencyTooltip}
        value={this.getCurrencyNumber(newAnnualCost, 0)}
      />,
    );
    return contentDetailsArray;
  }
}

class RdsBaseContent {
  constructor(rec) {
    this.rec = rec;
  }

  createRdsBaseContent(rec) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="RDS Name:" value={rec.dbName} />);
    contentDetailsArray.push(<ContentDetail display="Type:" value={rec.dbType} />);
    if (rec.dbStorageSize?.toLowerCase() !== 'not available') {
      contentDetailsArray.push(<ContentDetail display="DB Size:" value={`${rec.dbStorageSize} GiB`} />);
    }
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Multi-AZ:" value={rec.isMultiAz ? 'Yes' : 'No'} />);
    return contentDetailsArray;
  }
}

class Ec2BaseContent {
  constructor(rec) {
    this.rec = rec;
  }

  createEc2BaseContent(rec) {
    const contentDetailsArray = [];
    if (rec.resourceName) {
      contentDetailsArray.push(
        <ContentDetail
          classes="daily-rec__content-detail-value"
          display="Resource Name:"
          value={
            <NewTabLink
              link={buildResourceNameAwsConsoleLink(rec.region, rec.resourceName)}
              displayText={rec.resourceName}
            />
          }
        />,
      );
    }
    if (rec.resourceId) {
      contentDetailsArray.push(
        <ContentDetail
          classes="daily-rec__content-detail-value"
          display="Resource Id:"
          value={
            <NewTabLink link={buildResourceIdAwsConsoleLink(rec.region, rec.resourceId)} displayText={rec.resourceId} />
          }
        />,
      );
    }
    if (rec.instance) {
      contentDetailsArray.push(
        <ContentDetail classes="daily-rec__content-detail-value" display="Model:" value={rec.instance} />,
      );
    }
    return contentDetailsArray;
  }
}

export default class BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode, tooltipText) {
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyTooltip =
      currencyCode && currencyCode !== CURRENCY_DETAILS.USD ? CURRENCY_DETAILS.SAVING_CALCULATE_TOOLTIP : null;
    this.tooltipText = tooltipText;
  }

  addBaseContent() {
    const { costPotentialSavings, potentialSavings } = this.rec;
    const contentDetailsArray = [];
    if (costPotentialSavings && potentialSavings && this.contentPageType !== ContentPageTypes.DAILY_RECS) {
      let { costPotentialSavings, potentialSavings } = this.rec;
      if (this.rec.selectedOption >= 0) {
        costPotentialSavings = this.rec.alternatives[this.rec.selectedOption].savingAmount;
        // eslint-disable-next-line prefer-destructuring
        potentialSavings = this.rec.alternatives[this.rec.selectedOption].potentialSavings;
      }

      contentDetailsArray.push(
        <ContentDetail
          classes="daily-rec__content-detail-value"
          display="Potential Savings:"
          tooltip={this.currencyTooltip || this.tooltipText}
          value={`${this.getCurrencyNumber(costPotentialSavings, 0, { roundNumber: true })}
           (${roundNumber(potentialSavings)}% lower Annual Costs)`}
        />,
      );
    }
    if (this.rec.linkedAccountId && this.rec.linkedAccountName) {
      contentDetailsArray.push(
        <ContentDetail
          classes="daily-rec__content-detail-value"
          display="Linked Account:"
          value={` ${this.rec.linkedAccountName} (${this.rec.linkedAccountId})`}
        />,
      );
    }
    if (this.rec.customer) {
      contentDetailsArray.push(
        <ContentDetail classes="daily-rec__content-detail-value" display="Customer:" value={this.rec.customer} />,
      );
    }
    if (this.rec.linkedAccountId && !this.rec.linkedAccountName) {
      contentDetailsArray.push(
        <ContentDetail
          classes="daily-rec__content-detail-value"
          display="Linked Account:"
          value={this.rec.linkedAccountId}
        />,
      );
    }
    if (this.rec.linkedAccountName && !this.rec.linkedAccountId) {
      contentDetailsArray.push(
        <ContentDetail
          classes="daily-rec__content-detail-value"
          display="Linked Account Name:"
          value={this.rec.linkedAccountName}
        />,
      );
    }
    if (this.rec.customTags) {
      contentDetailsArray.push(
        <ContentDetail classes="daily-rec__content-detail-value" display="Custom Tags:" value={this.rec.customTags} />,
      );
    }
    addResourceTagsToContent(this.rec, contentDetailsArray);
    return contentDetailsArray;
  }

  createContent() {
    const contentDetailsArray = this.addBaseContent();
    const addContentDetails = this.addContent(this.rec);
    contentDetailsArray.push(...addContentDetails);
    return [...contentDetailsArray];
  }
}

class VuContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    if (rec.separateResource) {
      contentDetailsArray.push(<ContentDetail display="Resource Id:" value={rec.resourceId} />);
      contentDetailsArray.push(<ContentDetail display="Resource Name:" value={rec.resourceName} />);
    }
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Current Model:" value={rec.instance} />);
    contentDetailsArray.push(<ContentDetail display="Recommended Model:" value={rec.recommEc2Instance.instance} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    contentDetailsArray.push(<ContentDetail display="Quantity:" value={rec.resourcesQuantity} />);
    contentDetailsArray.push(<ContentDetail display="Physical processor:" value={rec.physicalProcessor} />);
    return contentDetailsArray;
  }
}

class RdsVuContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    if (rec.separateResource) {
      contentDetailsArray.push(<ContentDetail display="Resource Id:" value={rec.resourceId} />);
      contentDetailsArray.push(<ContentDetail display="Resource Name:" value={rec.resourceName} />);
    }
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Current Model:" value={rec.currInstance} />);
    contentDetailsArray.push(<ContentDetail display="Recommended Model:" value={rec.recommendedInstance} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    contentDetailsArray.push(<ContentDetail display="Quantity:" value={rec.resourcesQuantity} />);
    return contentDetailsArray;
  }
}

class IdleLoadBalancerContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Load Balancer Name:" value={rec.loadBalancerName} />);
    contentDetailsArray.push(<ContentDetail display="Load Balancer Type:" value={rec.loadBalancerType} />);
    contentDetailsArray.push(<ContentDetail display="DNS Name:" value={rec.dnsName} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class DbIdleContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Table Name:" value={rec.tableName} />);
    contentDetailsArray.push(<ContentDetail display="Number of Idle Days:" value={rec.daysToCheck} />);
    contentDetailsArray.push(<ContentDetail display="Starting time:" value={rec.startingTime} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class CloudTrailDuplication extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Trails:" value={rec.trails.length} />);
    contentDetailsArray.push(<ContentDetail display="Trails info:" value={this.addTrailsTable(rec)} />);
    return contentDetailsArray;
  }

  addTrailsTable = (rec) => {
    const rows = [];
    rec.trails.forEach(({ trail_name: name, trail_id: id }) => {
      rows.push(
        <tr style={{ lineHeight: '16px' }}>
          <td style={{ padding: '5' }}>{id}</td>
          <td style={{ padding: '5' }}>{name}</td>
        </tr>,
      );
    });
    return (
      <table style={{ textAlign: 'center' }}>
        <tr>
          <th style={{ padding: '5' }}>Trail ID</th>
          <th style={{ padding: '5' }}>Trail Name </th>
        </tr>
        {rows}
      </table>
    );
  };
}

class RedshiftLowUtilization extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="DB Name:" value={rec.dbName} />);
    contentDetailsArray.push(<ContentDetail display="Cluster Status:" value={rec.clusterStatus} />);
    contentDetailsArray.push(<ContentDetail display="Number Of Nodes:" value={rec.numOfNodes} />);
    contentDetailsArray.push(<ContentDetail display="Nodes Type:" value={rec.nodeType} />);
    contentDetailsArray.push(<ContentDetail display="Max Number Of Connections:" value={rec.maxNumOfConnections} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class ElasticsearchLowUtilization extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Domain Id:" value={rec.domainId} />);
    contentDetailsArray.push(<ContentDetail display="Domain Name:" value={rec.domainName} />);
    contentDetailsArray.push(<ContentDetail display="Instance Type:" value={rec.instanceType} />);
    contentDetailsArray.push(<ContentDetail display="Instance Count:" value={rec.instanceCount} />);
    contentDetailsArray.push(<ContentDetail display="Max Indexing Rate:" value={rec.maxIndexingRate} />);
    contentDetailsArray.push(<ContentDetail display="Max Search Rate:" value={rec.maxSearchRate} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class ElasticacheLowUtilization extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Status:" value={rec.status} />);
    contentDetailsArray.push(<ContentDetail display="Engine:" value={rec.engine} />);
    contentDetailsArray.push(<ContentDetail display="Engine Version:" value={rec.engineVersion} />);
    contentDetailsArray.push(<ContentDetail display="Max Connections:" value={rec.maxConnections} />);
    contentDetailsArray.push(<ContentDetail display="Node Type:" value={rec.nodeType} />);
    contentDetailsArray.push(<ContentDetail display="Number of Nodes:" value={rec.numNodes} />);
    contentDetailsArray.push(<ContentDetail display="Replication Group Id:" value={rec.replicationGroupId} />);
    contentDetailsArray.push(<ContentDetail display="Resource Id:" value={rec.resourceId} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class NatGatewayLowUtilization extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Max Active Connections:" value={rec.maxActiveConnections} />);
    contentDetailsArray.push(<ContentDetail display="Resource Id:" value={rec.resourceId} />);
    contentDetailsArray.push(<ContentDetail display="State:" value={rec.state} />);
    contentDetailsArray.push(<ContentDetail display="Subnet Id:" value={rec.subnetId} />);
    contentDetailsArray.push(<ContentDetail display="Vpc Id:" value={rec.vpcId} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class DocumentDbLowUtilization extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Arn:" value={rec.arn} />);
    contentDetailsArray.push(<ContentDetail display="Engine:" value={rec.engine} />);
    contentDetailsArray.push(<ContentDetail display="Engine Version:" value={rec.engineVersion} />);
    contentDetailsArray.push(<ContentDetail display="Max Connections:" value={rec.maxConnections} />);
    contentDetailsArray.push(<ContentDetail display="Cluster Resource Id:" value={rec.dbClusterResourceId} />);
    contentDetailsArray.push(<ContentDetail display="Number of Members:" value={rec.numMembers} />);
    contentDetailsArray.push(<ContentDetail display="Multi AZ:" value={rec.multiAz ? 'Yes' : 'No'} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class NeptuneLowUtilization extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Arn:" value={rec.arn} />);
    contentDetailsArray.push(<ContentDetail display="Engine:" value={rec.engine} />);
    contentDetailsArray.push(<ContentDetail display="Engine Version:" value={rec.engineVersion} />);
    contentDetailsArray.push(
      <ContentDetail display="Max Gremlin Requests Per Sec:" value={rec.maxGremlinRequestsPerSec} />,
    );
    contentDetailsArray.push(
      <ContentDetail display="Max Sparql Requests Per Sec:" value={rec.maxSparqlRequestsPerSec} />,
    );
    contentDetailsArray.push(<ContentDetail display="DB Cluster ID:" value={rec.dbClusterId} />);
    contentDetailsArray.push(
      <ContentDetail display="DB Cluster Parameter Group:" value={rec.dbClusterParameterGroup} />,
    );
    contentDetailsArray.push(<ContentDetail display="DB Cluster Resource ID:" value={rec.dbClusterResourceId} />);
    contentDetailsArray.push(<ContentDetail display="Primary DB Instance ID:" value={rec.primaryDbInstanceId} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class KinesisLowUtilization extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Arn:" value={rec.arn} />);
    contentDetailsArray.push(<ContentDetail display="Status:" value={rec.status} />);
    contentDetailsArray.push(<ContentDetail display="Stream Name:" value={rec.streamName} />);
    contentDetailsArray.push(<ContentDetail display="Max Put Record Bytes:" value={rec.maxPutRecordBytes} />);
    contentDetailsArray.push(<ContentDetail display="Max Put Records Bytes:" value={rec.maxPutRecordsBytes} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class EbsTypeChangeContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];

    contentDetailsArray.push(
      <ContentDetail
        display="EBS Saving:"
        value={this.getCurrencyNumber(rec.sizePotentialSavings, 0, { roundNumber: true })}
      />,
    );
    contentDetailsArray.push(
      <ContentDetail
        display="IOPS Saving:"
        value={this.getCurrencyNumber(rec.iopsPotentialSavings, 0, { roundNumber: true })}
      />,
    );
    contentDetailsArray.push(
      <ContentDetail
        display="Throughput Saving:"
        value={this.getCurrencyNumber(rec.throughputPotentialSavings, 0, { roundNumber: true })}
      />,
    );

    contentDetailsArray.push(<ContentDetail display="Volume Name:" value={rec.ebsName} />);
    if (rec.ebsName !== rec.ebsId) {
      contentDetailsArray.push(<ContentDetail display="EBS ID:" value={rec.ebsId} />);
    }
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Type:" value={rec.ebsType} />);
    contentDetailsArray.push(<ContentDetail display="New Type:" value={rec.newEbsType} />);
    contentDetailsArray.push(<ContentDetail display="Starting Time:" value={rec.startingTime} />);
    contentDetailsArray.push(<ContentDetail display="Days to Check:" value={rec.daysToCheck} />);
    contentDetailsArray.push(<ContentDetail display="IOPS:" value={rec.iops} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    if (rec.resourcesQuantity) {
      contentDetailsArray.push(<ContentDetail display="Quantity:" value={rec.resourcesQuantity} />);
    }
    return contentDetailsArray;
  }
}

class EbsUpgradeContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    if (rec.ebsName !== rec.ebsId) {
      contentDetailsArray.push(<ContentDetail display="EBS ID:" value={rec.ebsId} />);
    }
    if (rec.separateResource) {
      contentDetailsArray.push(<ContentDetail display="Volume Id:" value={rec.resourceId} />);
      contentDetailsArray.push(<ContentDetail display="Volume Name:" value={rec.resourceName} />);
    }
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Type:" value={rec.ebsType} />);
    contentDetailsArray.push(<ContentDetail display="New Type:" value={rec.newEbsType} />);
    contentDetailsArray.push(<ContentDetail display="Starting Time:" value={rec.startingTime} />);
    contentDetailsArray.push(<ContentDetail display="Days to Check:" value={rec.daysToCheck} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    contentDetailsArray.push(<ContentDetail display="Quantity:" value={rec.quantity} />);
    return contentDetailsArray;
  }
}

class EbsTypeAndSizeChangeContent extends EbsTypeChangeContent {
  constructor(rec, contentPageType, getCurrencyNumber) {
    super(rec, contentPageType, getCurrencyNumber);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = super.addContent(rec, contentPageType);
    contentDetailsArray.splice(6, 0, <ContentDetail display="New Type Size:" value={`${rec.gp2Size} GiB`} />);
    return contentDetailsArray;
  }
}

class EbsUnattachedContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Type:" value={rec.ebsType} />);
    contentDetailsArray.push(<ContentDetail display="Volume Name:" value={rec.ebsName} />);
    contentDetailsArray.push(<ContentDetail display="Detached EBS Id:" value={rec.detachedEbsId} />);
    contentDetailsArray.push(<ContentDetail display="IOPS:" value={rec.iops} />);
    if (rec.tags) {
      contentDetailsArray.push(<ContentDetail display="Tags:" value={intersperse(rec.tags)} />);
    }
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    if (rec.lastUse) {
      contentDetailsArray.push(<ContentDetail display="Since:" value={rec.lastUse} />);
    }
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class Ec2IdleContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recEc2BaseContent = new Ec2BaseContent(rec);
    recEc2BaseContent.createEc2BaseContent(rec).forEach((baseContent) => contentDetailsArray.push(baseContent));
    contentDetailsArray.push(<ContentDetail display="Number of Idle Days:" value={rec.numOfDays} />);
    contentDetailsArray.push(<ContentDetail display="Last Inspection Time:" value={rec.startingTime} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    if (rec.cpuUtil) {
      contentDetailsArray.push(
        <ContentDetail
          display="Max CPU Utilization (%):"
          value={`${rec.cpuUtil.toFixed(isDecimalNeeded(rec.cpuUtil, 2) ? 2 : 0)} %`}
        />,
      );
    }
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class Ec2LowCpuContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recEc2BaseContent = new Ec2BaseContent(rec);
    recEc2BaseContent.createEc2BaseContent(rec).forEach((baseContent) => contentDetailsArray.push(baseContent));
    contentDetailsArray.push(<ContentDetail display="Recommended Model:" value={rec.recommEc2Instance.instance} />);
    contentDetailsArray.push(<ContentDetail display="Network In (Bytes):" value={rec.networkIn} />);
    contentDetailsArray.push(<ContentDetail display="Network Out (Bytes):" value={rec.networkOut} />);
    if (rec.maxMemory !== 'Not Available') {
      contentDetailsArray.push(<ContentDetail display="Max memory usage %:" value={`${rec.maxMemory} %`} />);
    }
    if (rec.cpuUtil) {
      contentDetailsArray.push(
        <ContentDetail
          display="Max CPU Utilization (%):"
          value={`${rec.cpuUtil.toFixed(isDecimalNeeded(rec.cpuUtil, 2) ? 2 : 0)} %`}
        />,
      );
    }
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class Ec2ScheduleContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recEc2BaceContent = new Ec2BaseContent(rec);
    recEc2BaceContent.createEc2BaseContent(rec).forEach((baseContent) => contentDetailsArray.push(baseContent));
    contentDetailsArray.push(<ContentDetail display="Start of Working Day:" value={rec.startOfWorkingDay} />);
    contentDetailsArray.push(<ContentDetail display="End of Working Day:" value={rec.endOfWorkingDay} />);
    contentDetailsArray.push(<ContentDetail display="Running Hours:" value={rec.runningHours} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class IpUnattachedContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const { publicIp, region } = rec;
    const ipUrl = `https://console.aws.amazon.com/ec2/v2/home?region=${region}#Addresses:search=${publicIp}`;
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Public IP:" value={buildLink(ipUrl, publicIp)} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class OperationSystemContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Operation System:" value={rec.os} />);
    contentDetailsArray.push(<ContentDetail display="Recommended Operation System:" value="Linux" />);
    const recEc2BaceContent = new Ec2BaseContent(rec);
    recEc2BaceContent.createEc2BaseContent(rec).forEach((baseContent) => contentDetailsArray.push(baseContent));
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class RdsRiContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recRdsBaceContent = new RdsBaseContent(rec);
    recRdsBaceContent.createRdsBaseContent(rec).forEach((baseContent) => contentDetailsArray.push(baseContent));
    contentDetailsArray.push(<ContentDetail display="Quantity:" value={rec.numOfRecommRIs} />);
    contentDetailsArray.push(<ContentDetail display="RI Type:" value={rec.riType} />);
    contentDetailsArray.push(<ContentDetail display="Model:" value={rec.instance} />);
    contentDetailsArray.push(<ContentDetail display="Expected ROI Period:" value={`${rec.breakEvenMonth} Months`} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class ElasticacheRiContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Quantity:" value={rec.numOfRecommRIs} />);
    contentDetailsArray.push(<ContentDetail display="RI Type:" value={rec.riType} />);
    contentDetailsArray.push(<ContentDetail display="Engine Type:" value={rec.engineType} />);
    contentDetailsArray.push(<ContentDetail display="Instance Size:" value={rec.size} />);
    contentDetailsArray.push(<ContentDetail display="Expected ROI Period:" value={`${rec.breakEvenMonth} Months`} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class OpenSearchRiContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Quantity:" value={rec.numOfRecommRIs} />);
    contentDetailsArray.push(<ContentDetail display="RI Type:" value={rec.riType} />);
    contentDetailsArray.push(<ContentDetail display="Instance Size:" value={rec.size} />);
    contentDetailsArray.push(<ContentDetail display="Recommended Instance Size:" value={rec.recommendedSize} />);
    contentDetailsArray.push(<ContentDetail display="Expected ROI Period:" value={`${rec.breakEvenMonth} Months`} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class RdsIdleContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recRdsBaceContent = new RdsBaseContent(rec);
    recRdsBaceContent.createRdsBaseContent(rec).forEach((baseContent) => contentDetailsArray.push(baseContent));
    contentDetailsArray.push(
      <ContentDetail display="Number of connections in checked period:" value={rec.numOfConnections} />,
    );
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class RdsIopsChangeContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber) {
    super(rec, contentPageType, getCurrencyNumber);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Resource Name:" value={rec.resourceArn} />);
    contentDetailsArray.push(<ContentDetail display="Resource ID:" value={rec.resourceId} />);
    contentDetailsArray.push(<ContentDetail display="Provisioned IOPS" value={rec.provisionedIops} />);
    contentDetailsArray.push(<ContentDetail display="Recommended IOPS:" value={rec.recommendedIops} />);
    contentDetailsArray.push(<ContentDetail display="Instance type:" value={rec.instanceType} />);
    contentDetailsArray.push(<ContentDetail display="Instance family:" value={rec.instanceFamily} />);
    contentDetailsArray.push(<ContentDetail display="Instance type family:" value={rec.instanceTypeFamily} />);
    contentDetailsArray.push(<ContentDetail display="Engine:" value={rec.engine} />);
    contentDetailsArray.push(<ContentDetail display="Multi-AZ:" value={rec.isMultiAz ? 'Yes' : 'No'} />);
    contentDetailsArray.push(<ContentDetail display="Storage:" value={rec.storage} />);
    contentDetailsArray.push(<ContentDetail display="Max CPU Utilization (%):" value={rec.cpuMax} />);
    contentDetailsArray.push(<ContentDetail display="Max IOPS:" value={rec.maxIops} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class RdsClassChangeContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber) {
    super(rec, contentPageType, getCurrencyNumber);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const { selectedOption } = rec;
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Resource Name:" value={rec.resourceArn} />);
    contentDetailsArray.push(<ContentDetail display="Resource ID:" value={rec.resourceId} />);
    contentDetailsArray.push(<ContentDetail display="Model:" value={rec.instanceType} />);

    contentDetailsArray.push(
      <ContentDetail
        display="Recommended Model:"
        value={selectedOption >= 0 ? rec.alternatives[selectedOption].instanceType : rec.recommendedInstanceType}
      />,
    );
    contentDetailsArray.push(
      <ContentDetail
        display="Instance family:"
        value={selectedOption >= 0 ? rec.alternatives[selectedOption].instanceFamily : rec.instanceFamily}
      />,
    );
    contentDetailsArray.push(
      <ContentDetail
        display="Instance type family:"
        value={selectedOption >= 0 ? rec.alternatives[selectedOption].instanceTypeFamily : rec.instanceTypeFamily}
      />,
    );
    contentDetailsArray.push(<ContentDetail display="Engine:" value={rec.engine} />);
    contentDetailsArray.push(<ContentDetail display="Multi-AZ:" value={rec.isMultiAz ? 'Yes' : 'No'} />);
    contentDetailsArray.push(
      <ContentDetail
        display="Storage:"
        value={selectedOption >= 0 ? rec.alternatives[selectedOption].instanceStorage : rec.storage}
      />,
    );
    contentDetailsArray.push(<ContentDetail display="Max CPU Utilization (%):" value={rec.cpuMax} />);
    contentDetailsArray.push(<ContentDetail display="95PCT CPU Utilization (%):" value={rec.cpuP95} />);
    contentDetailsArray.push(<ContentDetail display="95PCT Mem Utilization (%):" value={rec.memP95} />);
    contentDetailsArray.push(<ContentDetail display="Max IOPS:" value={rec.maxIops} />);
    contentDetailsArray.push(
      <ContentDetail
        display="Physical processor:"
        value={selectedOption >= 0 ? rec.alternatives[selectedOption].physicalProcessor : rec.physicalProcessor}
      />,
    );
    contentDetailsArray.push(
      <ContentDetail
        display="RI Coverage:"
        value={selectedOption >= 0 ? rec.alternatives[selectedOption].riCoverage : rec.riCoverage}
      />,
    );
    const recCostContent = new CostContent(rec, this.getCurrencyNumber);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class S3IdleContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    contentDetailsArray.push(<ContentDetail display="Bucket Name:" value={rec.bucketName} />);
    contentDetailsArray.push(<ContentDetail display="Num of Idle days:" value={rec.numOfDays} />);
    contentDetailsArray.push(<ContentDetail display="Idle Starting time:" value={rec.startingTime} />);
    return contentDetailsArray;
  }
}

class RdsTypeChangeContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recRdsBaceContent = new RdsBaseContent(rec);
    recRdsBaceContent.createRdsBaseContent(rec).forEach((baseContent) => contentDetailsArray.push(baseContent));
    contentDetailsArray.push(<ContentDetail display="Current Storage Type:" value={rec.currentStorageType} />);
    contentDetailsArray.push(<ContentDetail display="Recommended Storage Type:" value={rec.recommendedStorageType} />);
    contentDetailsArray.push(<ContentDetail display="Starting Time:" value={rec.startingTime} />);
    contentDetailsArray.push(<ContentDetail display="Days to Check:" value={rec.daysToCheck} />);
    contentDetailsArray.push(<ContentDetail display="IOPS:" value={rec.iops} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class RegionContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyTooltip = currencyCode !== CURRENCY_DETAILS.USD ? CURRENCY_DETAILS.SAVING_CALCULATE_TOOLTIP : null;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recEc2BaceContent = new Ec2BaseContent(rec);
    recEc2BaceContent
      .createEc2BaseContent(rec, contentPageType)
      .forEach((baseContent) => contentDetailsArray.push(baseContent));
    contentDetailsArray.push(<ContentDetail display="Current Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Suggested Region:" value={rec.recommRegion} />);
    contentDetailsArray.push(
      <ContentDetail
        display="Potential Savings:"
        value={percentStr(rec.reducePercent)}
        tooltip={this.currencyTooltip}
      />,
    );
    return contentDetailsArray;
  }
}

class RegionMigrationContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class RiContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Quantity:" value={rec.numOfRecommRIs} />);
    contentDetailsArray.push(<ContentDetail display="RI Type:" value={rec.riType} />);
    contentDetailsArray.push(<ContentDetail display="Expected ROI Period:" value={`${rec.breakEvenMonth} Months`} />);
    contentDetailsArray.push(<ContentDetail display="Total Usage Time:" value={`${rec.totalRunningHours} Hours`} />);
    contentDetailsArray.push(<ContentDetail display="Model:" value={rec.instance} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class S3MultipartUploadContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Bucket Name:" value={rec.bucketName} />);
    contentDetailsArray.push(<ContentDetail display="Total Upload Size:" value={strNumToSize(rec.totalUploadSize)} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class SavingsPlansContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const {
      recommendedUpfrontPayment,
      monthlyRecurringCharges,
      totalYearCommitment,
      daysToCheck,
      term,
      paymentOption,
      savingsPlanType,
      recommendedHourlyCommitment,
    } = rec;
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Savings Plan Type:" value={savingsPlanType} />);
    contentDetailsArray.push(
      <ContentDetail
        display="Recommended Hourly Commitment:"
        value={`${this.getCurrencyNumber(recommendedHourlyCommitment, 2)}`}
      />,
    );
    contentDetailsArray.push(<ContentDetail display="Payment Option:" value={paymentOption} />);
    contentDetailsArray.push(<ContentDetail display="Term:" value={term} />);

    contentDetailsArray.push(
      <ContentDetail
        display="Upfront Cost:"
        value={this.getCurrencyNumber(
          recommendedUpfrontPayment.toFixed(1)[recommendedUpfrontPayment.toFixed(1).length - 1] === '0'
            ? recommendedUpfrontPayment.toFixed(0)
            : recommendedUpfrontPayment,
          0,
        )}
      />,
    );
    contentDetailsArray.push(
      <ContentDetail
        display="Monthly Recurring Charges:"
        value={this.getCurrencyNumber(
          monthlyRecurringCharges.toFixed(1)[monthlyRecurringCharges.toFixed(1).length - 1] === '0'
            ? monthlyRecurringCharges.toFixed(0)
            : monthlyRecurringCharges,
          0,
        )}
      />,
    );
    contentDetailsArray.push(
      <ContentDetail
        display="Total Commitment:"
        value={this.getCurrencyNumber(
          totalYearCommitment.toFixed(1)[totalYearCommitment.toFixed(1).length - 1] === '0'
            ? totalYearCommitment.toFixed(0)
            : totalYearCommitment,
          0,
        )}
      />,
    );
    contentDetailsArray.push(<ContentDetail display="Days to Check:" value={daysToCheck} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class SpotContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recEc2BaceContent = new Ec2BaseContent(rec);
    recEc2BaceContent.createEc2BaseContent(rec).forEach((baseContent) => contentDetailsArray.push(baseContent));
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Interruption Frequency:" value={rec.interruptionFreq} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class EbsOutdatedSnapshotContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Name:" value={rec.resourceName} />);
    contentDetailsArray.push(<ContentDetail display="Size:" value={`${rec.snapshotSize} GiB`} />);
    contentDetailsArray.push(<ContentDetail display="Snapshot ID:" value={rec.resourceId} />);
    contentDetailsArray.push(<ContentDetail display="Volume ID:" value={`${rec.volumeId}`} />);
    contentDetailsArray.push(<ContentDetail display="Time from creation:" value={rec.elapsedTimeSinceCreation} />);
    contentDetailsArray.push(
      <ContentDetail display="Cost from creation:" value={`${this.getCurrencyNumber(rec.costSinceCreation, 2)}`} />,
    );
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class Ec2UnnecessaryDataTransferContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recEc2BaceContent = new Ec2BaseContent(rec);
    recEc2BaceContent.createEc2BaseContent(rec).forEach((baseContent) => contentDetailsArray.push(baseContent));
    contentDetailsArray.push(<ContentDetail display="Data Transfer Type:" value={rec.dtType} />);
    contentDetailsArray.push(<ContentDetail display="Availability Zone:" value={rec.availabilityZone} />);
    contentDetailsArray.push(<ContentDetail display="Resource Tags:" value={intersperse(rec.resourceTags, ', ')} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class Ec2StoppedInstanceContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode, currencySymbol) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencySymbol = currencySymbol;
    this.currencyCode = currencyCode;
  }

  addVolumeCostsTable = (rec) => {
    const rows = [];
    const entries = Object.entries(rec.attachedVolumesCosts);
    entries.forEach(([volId, volCost]) => {
      rows.push(
        <tr style={{ lineHeight: '16px' }}>
          <td style={{ padding: '5' }}>{`${volId}`}</td>
          <td style={{ padding: '5' }}>{`${this.getCurrencyNumber(volCost)}`}</td>
        </tr>,
      );
    });
    return (
      <table style={{ textAlign: 'center' }}>
        <tr>
          <th style={{ padding: '5' }}>Volume ID</th>
          <th style={{ padding: '5' }}>
            Cost
            {this.currencySymbol}
          </th>
        </tr>
        {rows}
      </table>
    );
  };

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recEc2BaceContent = new Ec2BaseContent(rec);
    recEc2BaceContent.createEc2BaseContent(rec).forEach((baseContent) => contentDetailsArray.push(baseContent));
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Attached Volumes:" value={rec.attachedVolumes} />);
    if (rec.attachedVolumesCosts) {
      contentDetailsArray.push(
        <ContentDetail display="Attached Volumes Monthly Cost:" value={this.addVolumeCostsTable(rec)} />,
      );
    }
    if (rec.publicIpAddress) {
      contentDetailsArray.push(<ContentDetail display="Public IP Address:" value={rec.publicIpAddress} />);
    }
    if (rec.publicIpAddress) {
      contentDetailsArray.push(
        <ContentDetail display="Public IP Address Monthly Cost:" value={rec.publicIpAddressCost} />,
      );
    }
    if (rec.stopReason) {
      contentDetailsArray.push(<ContentDetail display="Stop Reason:" value={rec.stopReason} />);
    }
    if (rec.stopTime) {
      contentDetailsArray.push(<ContentDetail display="Stop Time:" value={rec.stopTime} />);
    }
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

// AZURE ******

export class AzureBaseRecommendationCreator {
  constructor(
    rec,
    contentPageType,
    getCurrencyNumber,
    currencyCode,
    hidePotentialSavingPercent,
    psTooltip,
    tpBoldWords,
  ) {
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.hidePotentialSavingPercent = hidePotentialSavingPercent;
    this.currencyTooltip = currencyCode !== CURRENCY_DETAILS.USD ? CURRENCY_DETAILS.SAVING_CALCULATE_TOOLTIP : null;
    this.psTooltip = psTooltip;
    this.tpBoldWords = tpBoldWords;
  }

  addBaseContent() {
    const contentDetailsArray = [];
    const { costPotentialSavings, potentialSavings, savingAmount } = this.rec;
    if (costPotentialSavings && potentialSavings && this.contentPageType !== ContentPageTypes.DAILY_RECS) {
      contentDetailsArray.push(
        <ContentDetail
          classes="daily-rec__content-detail-value"
          display="Potential Savings:"
          tooltip={
            this.psTooltip
              ? this.psTooltip?.split('\n').map((line) => {
                  const words = line?.split(' ');
                  return (
                    <div>
                      {words.map((word) => {
                        if (this.tpBoldWords.includes(word)) {
                          return <b>{`${word} `}</b>;
                        }
                        return <span style={{ fontWeight: 400 }}>{`${word} `}</span>;
                      })}
                    </div>
                  );
                })
              : this.currencyTooltip
          }
          value={`${this.getCurrencyNumber(
            costPotentialSavings.toFixed(1)[costPotentialSavings.toFixed(1).length - 1] === '0'
              ? costPotentialSavings.toFixed(0)
              : costPotentialSavings,
            0,
          )} ${!this.hidePotentialSavingPercent ? `(${roundNumber(potentialSavings)}% lower Annual Costs)` : ''}`}
        />,
      );
    }
    if (savingAmount && this.hidePotentialSavingPercent) {
      contentDetailsArray.push(
        <ContentDetail
          classes="daily-rec__content-detail-value"
          display="Potential Savings:"
          value={` ${this.getCurrencyNumber(savingAmount)}`}
        />,
      );
    }
    if (this.rec.linkedAccountId && this.rec.linkedAccountName) {
      contentDetailsArray.push(
        <ContentDetail
          classes="daily-rec__content-detail-value"
          display="Subscription:"
          value={` ${this.rec.linkedAccountName} (${this.rec.linkedAccountId})`}
        />,
      );
    }
    if (this.rec.linkedAccountId && !this.rec.linkedAccountName) {
      contentDetailsArray.push(
        <ContentDetail
          classes="daily-rec__content-detail-value"
          display="Subscription ID:"
          value={this.rec.linkedAccountId}
        />,
      );
    }
    if (this.rec.linkedAccountName && !this.rec.linkedAccountId) {
      contentDetailsArray.push(
        <ContentDetail
          classes="daily-rec__content-detail-value"
          display="Subscription Name:"
          value={this.rec.linkedAccountName}
        />,
      );
    }

    if (this.rec.resourceGroup) {
      contentDetailsArray.push(
        <ContentDetail
          classes="daily-rec__content-detail-value"
          display="Resource Group:"
          value={this.rec.resourceGroup}
        />,
      );
    }

    if (this.rec.resourceId) {
      contentDetailsArray.push(<ContentDetail display="Resource Id:" value={this.rec.resourceId} />);
    }

    return contentDetailsArray;
  }

  createContent() {
    const contentDetailsArray = this.addBaseContent();
    const addContentDetails = this.addContent(this.rec);
    contentDetailsArray.push(...addContentDetails);
    return [...contentDetailsArray];
  }
}

class AzureDbIdleContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Database Name:" value={rec.dbName} />);
    contentDetailsArray.push(<ContentDetail display="Database Type:" value={rec.dbType} />);
    contentDetailsArray.push(<ContentDetail display="Number of Idle Days:" value={rec.daysToCheck} />);
    contentDetailsArray.push(<ContentDetail display="Starting time:" value={rec.startingTime} />);
    contentDetailsArray.push(<ContentDetail display="Number Of Connections:" value={rec.numOfConnections} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureVmStoppedContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode, currencySymbol) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencySymbol = currencySymbol;
    this.currencyCode = currencyCode;
  }

  addVolumeCostsTable = (rec) => {
    const rows = [];
    const attachedCosts =
      typeof rec.attachedVolumesCosts === 'object' ? rec.attachedVolumesCosts : JSON.parse(rec.attachedVolumesCosts);
    const entries = Object.entries(attachedCosts);
    entries.forEach(([volId, volCost]) => {
      rows.push(
        <tr style={{ lineHeight: '16px' }}>
          <td style={{ padding: '5' }}>{`${volId}`}</td>
          <td style={{ padding: '5' }}>{`${this.getCurrencyNumber(volCost)}`}</td>
        </tr>,
      );
    });
    return (
      <table style={{ textAlign: 'center' }}>
        <tr>
          <th style={{ padding: '5' }}>Volume ID</th>
          <th style={{ padding: '5' }}>
            Cost
            {this.currencySymbol}
          </th>
        </tr>
        {rows}
      </table>
    );
  };

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];

    contentDetailsArray.push(<ContentDetail display="Attached Volumes:" value={rec.attachedVolumes} />);
    contentDetailsArray.push(<ContentDetail display="Attached Volumes Cost:" value={this.addVolumeCostsTable(rec)} />);
    if (rec.publicIpAddress) {
      contentDetailsArray.push(<ContentDetail display="Public IP Address:" value={rec.publicIpAddress} />);
    }

    if (rec.publicIpAddress) {
      contentDetailsArray.push(
        <ContentDetail display="Public IP Address Monthly Cost:" value={rec.publicIpAddressCost} />,
      );
    }

    if (rec.stopReason) {
      contentDetailsArray.push(<ContentDetail display="Stop Reason:" value={rec.stopReason} />);
    }

    if (rec.stopTime) {
      contentDetailsArray.push(<ContentDetail display="Stop Time:" value={rec.stopTime} />);
    }
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureIpUnattachedContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Public IP:" value={rec.publicIp} />);
    contentDetailsArray.push(<ContentDetail display="IP Name:" value={rec.ipName} />);
    contentDetailsArray.push(<ContentDetail display="IP Version:" value={rec.ipVersion} />);
    contentDetailsArray.push(<ContentDetail display="Allocation:" value={rec.allocation} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureDiskUnattachedContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="SKU Name:" value={rec.diskSkuName} />);
    contentDetailsArray.push(<ContentDetail display="Detached Disk Id:" value={rec.unattachedDiskId} />);
    contentDetailsArray.push(<ContentDetail display="Resource Group:" value={rec.resourceGroup} />);
    contentDetailsArray.push(<ContentDetail display="Disk Size:" value={rec.diskSize} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureIdleLoadBalancerContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Load Balancer Name:" value={rec.loadBalancerName} />);
    contentDetailsArray.push(<ContentDetail display="Load Balancer Type:" value={rec.loadBalancerType} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureRiContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Quantity:" value={rec.quantity} />);
    contentDetailsArray.push(<ContentDetail display="RI Type:" value={rec.riType} />);
    contentDetailsArray.push(<ContentDetail display="Expected ROI Period:" value={`${rec.breakEvenMonth} Months`} />);
    contentDetailsArray.push(<ContentDetail display="Model:" value={rec.skuName} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureReservedCapacityContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode, true);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Quantity:" value={rec.quantity} />);
    contentDetailsArray.push(<ContentDetail display="Recommended Plan:" value={rec.recommPlan} />);
    contentDetailsArray.push(<ContentDetail display="Model:" value={rec.skuName} />);
    contentDetailsArray.push(<ContentDetail display="Service:" value={rec.service} />);
    return contentDetailsArray;
  }
}

class AzureSnapshotMigrationContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Snapshot Name:" value={rec.snapShotName} />);
    contentDetailsArray.push(<ContentDetail display="Service:" value={rec.service} />);
    contentDetailsArray.push(<ContentDetail display="Resource Group:" value={rec.resourceGroup} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureOutdatedSnapshotContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Snapshot Name:" value={rec.resourceName} />);
    contentDetailsArray.push(<ContentDetail display="Service:" value={rec.service} />);
    contentDetailsArray.push(<ContentDetail display="Resource Group:" value={rec.resourceGroup} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureVmIdleContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];

    contentDetailsArray.push(<ContentDetail display="Model:" value={rec.model} />);
    contentDetailsArray.push(<ContentDetail display="Number of Idle Days:" value={rec.numOfDays} />);
    contentDetailsArray.push(<ContentDetail display="Starting time:" value={rec.startingTime} />);
    if (rec.cpuUtil) {
      contentDetailsArray.push(
        <ContentDetail
          display="Max CPU Utilization (%):"
          value={`${rec.cpuUtil.toFixed(isDecimalNeeded(rec.cpuUtil, 2) ? 2 : 0)} %`}
        />,
      );
    }
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureAppRightSizingContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recEc2BaseContent = new Ec2BaseContent(rec);
    recEc2BaseContent.createEc2BaseContent(rec).forEach((baseContent) => contentDetailsArray.push(baseContent));
    contentDetailsArray.push(<ContentDetail display="Number of Idle Days:" value={rec.numOfDays} />);
    contentDetailsArray.push(<ContentDetail display="Starting time:" value={rec.startingTime} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureDbRiContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Table Name:" value={rec.tableName} />);
    contentDetailsArray.push(<ContentDetail display="Number of Idle Days:" value={rec.daysToCheck} />);
    contentDetailsArray.push(<ContentDetail display="Starting time:" value={rec.startingTime} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureVmRightSizing extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    const customPsTp =
      'The saving is calculated as follows:\n' +
      '1) In case the recommended target model has reservations, the savings calculation takes' +
      ' into account the target reservation rate and the current model usage \n' +
      ' (with demand/reservation ratio) for the overall calculation. \n' +
      '2) Currency exchange rate corresponding to the invoice date. \n';
    const boldWords = ['reservations,', 'target', 'current'];
    super(rec, contentPageType, getCurrencyNumber, currencyCode, false, customPsTp, boldWords);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Recommended Model:" value={rec.recommVersion} />);
    contentDetailsArray.push(<ContentDetail display="Current Model RI Coverage:" value={rec.isCurrentReserved} />);
    contentDetailsArray.push(<ContentDetail display="Target Model RI Coverage:" value={rec.isRecommendedReserved} />);
    contentDetailsArray.push(<ContentDetail display="OS:" value={rec.os} />);
    contentDetailsArray.push(<ContentDetail display="Days under utilization:" value={rec.numOfDays} />);
    contentDetailsArray.push(<ContentDetail display="Max Netowork (Bytes):" value={rec.maxNetwork} />);
    if (rec.cpuPercentile) {
      contentDetailsArray.push(
        <ContentDetail
          display="95P CPU Utilization"
          value={`${rec.cpuPercentile.toFixed(isDecimalNeeded(rec.cpuPercentile, 2) ? 2 : 0)}%`}
        />,
      );
    }
    const currentCostTP =
      'The saving is taken from the invoice and represents the effective cost after the reservation if exist';
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode, currentCostTP);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureStorageContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Table Name:" value={rec.tableName} />);
    contentDetailsArray.push(<ContentDetail display="Number of Idle Days:" value={rec.daysToCheck} />);
    contentDetailsArray.push(<ContentDetail display="Starting time:" value={rec.startingTime} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureCosmosRightSizing extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recEc2BaseContent = new Ec2BaseContent(rec);
    recEc2BaseContent.createEc2BaseContent(rec).forEach((baseContent) => contentDetailsArray.push(baseContent));
    contentDetailsArray.push(<ContentDetail display="Recommended Model:" value={rec.recommEc2Instance.instance} />);
    contentDetailsArray.push(<ContentDetail display="Cpu Utilization:" value={percentStr(rec.cpuUtil)} />);
    contentDetailsArray.push(<ContentDetail display="Days under utilization:" value={rec.numOfDays} />);
    contentDetailsArray.push(<ContentDetail display="Network In (Bytes):" value={rec.networkIn} />);
    contentDetailsArray.push(<ContentDetail display="Network Out (Bytes):" value={rec.networkOut} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureDiskTypeChangeContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Disk Name:" value={rec.diskName} />);
    if (rec.diskName !== rec.ebsId) {
      contentDetailsArray.push(<ContentDetail display="Disk ID:" value={rec.diskId} />);
    }
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Type:" value={rec.diskType} />);
    contentDetailsArray.push(<ContentDetail display="New Type:" value={rec.newDiskType} />);
    contentDetailsArray.push(<ContentDetail display="Disk Size:" value={`${rec.diskSize} GiB`} />);
    contentDetailsArray.push(<ContentDetail display="Starting Time:" value={rec.diskTimeCreated} />);
    contentDetailsArray.push(<ContentDetail display="IOPS:" value={rec.iops} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    contentDetailsArray.push(<ContentDetail display="Resource Group:" value={rec.resourceGroup} />);
    return contentDetailsArray;
  }
}

class AzureKustoUnusedDataContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Cluster Name:" value={rec.clusterName} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class AzureAppSerRsrvdCapacityContent extends AzureBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode, true);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Sku Name:" value={rec.skuName} />);
    contentDetailsArray.push(<ContentDetail display="Sku Display Name:" value={rec.skuDisplayName} />);
    contentDetailsArray.push(<ContentDetail display="Recommended Plan:" value={rec.recommendedPlan} />);
    contentDetailsArray.push(<ContentDetail display="Scope:" value={rec.scope} />);
    contentDetailsArray.push(<ContentDetail display="Quantity:" value={rec.quantity} />);
    return contentDetailsArray;
  }
}

// GCP ******

export class GcpBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyTooltip = currencyCode !== CURRENCY_DETAILS.USD ? CURRENCY_DETAILS.SAVING_CALCULATE_TOOLTIP : null;
  }

  addBaseContent() {
    const contentDetailsArray = [];
    const { costPotentialSavings, potentialSavings } = this.rec;
    if (costPotentialSavings && potentialSavings && this.contentPageType !== ContentPageTypes.DAILY_RECS) {
      contentDetailsArray.push(
        <ContentDetail
          classes="daily-rec__content-detail-value"
          display="Potential Savings:"
          tooltip={this.currencyTooltip}
          value={`${this.getCurrencyNumber(
            costPotentialSavings.toFixed(1)[costPotentialSavings.toFixed(1).length - 1] === '0'
              ? costPotentialSavings.toFixed(0)
              : costPotentialSavings,
            0,
          )} (${roundNumber(potentialSavings)}% lower Annual Costs)`}
        />,
      );
    }
    if (this.rec.linkedAccountId || this.rec.subscriptionId) {
      const value = this.rec.linkedAccountId ? this.rec.linkedAccountId : this.rec.subscriptionId;
      contentDetailsArray.push(
        <ContentDetail classes="daily-rec__content-detail-value" display="Project ID:" value={value} />,
      );
    }

    if (this.rec.projectName) {
      contentDetailsArray.push(
        <ContentDetail classes="daily-rec__content-detail-value" display="Project:" value={this.rec.projectName} />,
      );
    }

    if (this.rec.resourceId) {
      contentDetailsArray.push(<ContentDetail display="Resource Id:" value={this.rec.resourceId} />);
    }

    if (this.rec.environment && this.rec.environment !== 'Not Available') {
      contentDetailsArray.push(
        <ContentDetail classes="daily-rec__content-detail-value" display="Environment:" value={this.rec.environment} />,
      );
    }
    if (this.rec.project && this.rec.project !== 'Not Available') {
      contentDetailsArray.push(
        <ContentDetail classes="daily-rec__content-detail-value" display="Project:" value={this.rec.project} />,
      );
    }
    return contentDetailsArray;
  }

  createContent() {
    const contentDetailsArray = this.addBaseContent();
    const addContentDetails = this.addContent(this.rec);
    contentDetailsArray.push(...addContentDetails);
    return [...contentDetailsArray];
  }
}

class GcpVmIdleContent extends GcpBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];

    contentDetailsArray.push(<ContentDetail display="Model:" value={rec.model.concat('-', rec.size)} />);
    contentDetailsArray.push(<ContentDetail display="Number of Idle Days:" value={rec.numOfDays} />);
    contentDetailsArray.push(<ContentDetail display="Starting time:" value={rec.startingTime} />);
    if (rec.cpuUtil) {
      contentDetailsArray.push(
        <ContentDetail
          display="Max CPU Utilization (%):"
          value={`${rec.cpuUtil.toFixed(isDecimalNeeded(rec.cpuUtil, 2) ? 2 : 0)} %`}
        />,
      );
    }
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class GcpVmStoppedContent extends GcpBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode, currencySymbol) {
    super(rec, contentPageType, getCurrencyNumber, currencySymbol);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencySymbol = currencySymbol;
    this.currencyCode = currencyCode;
  }

  addVolumeCostsTable = (rec) => {
    const rows = [];
    const entries = Object.entries(rec.attachedVolumesCosts);
    entries.forEach(([volId, volCost]) => {
      rows.push(
        <tr style={{ lineHeight: '16px' }}>
          <td style={{ padding: '5' }}>{`${volId}`}</td>
          <td style={{ padding: '5' }}>{`${this.getCurrencyNumber(volCost)}`}</td>
        </tr>,
      );
    });
    return (
      <table style={{ textAlign: 'center' }}>
        <tr>
          <th style={{ padding: '5' }}>Volume ID</th>
          <th style={{ padding: '5' }}>
            Cost
            {this.currencySymbol}
          </th>
        </tr>
        {rows}
      </table>
    );
  };

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];

    contentDetailsArray.push(
      <ContentDetail display="Attached Volumes:" value={Object.keys(rec.attachedVolumesCosts || {}).length} />,
    );
    if (rec.attachedVolumesCosts) {
      contentDetailsArray.push(
        <ContentDetail display="Attached Volumes Monthly Cost:" value={this.addVolumeCostsTable(rec)} />,
      );
    }

    if (rec.publicIpAddress) {
      contentDetailsArray.push(<ContentDetail display="Public IP Address:" value={rec.publicIpAddress} />);
    }

    if (rec.publicIpAddress) {
      contentDetailsArray.push(
        <ContentDetail display="Public IP Address Monthly Cost:" value={rec.publicIpAddressCost} />,
      );
    }
    if (rec.stopReason) {
      contentDetailsArray.push(<ContentDetail display="Stop Reason:" value={rec.stopReason} />);
    }
    if (rec.stopTime) {
      contentDetailsArray.push(<ContentDetail display="Stop Time:" value={rec.stopTime} />);
    }
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class GcpIpIdleContent extends GcpBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];

    contentDetailsArray.push(<ContentDetail display="Public IP:" value={rec.publicIp} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class GcpRightSizingeContent extends GcpBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];

    contentDetailsArray.push(<ContentDetail display="Instance Type:" value={rec.instanceType} />);
    contentDetailsArray.push(
      <ContentDetail display="Recommended Instance Type:" value={rec.recommendedInstanceType} />,
    );
    if (rec.cpuUtil) {
      contentDetailsArray.push(
        <ContentDetail
          display="Max CPU Utilization (%):"
          value={`${rec.cpuUtil.toFixed(isDecimalNeeded(rec.cpuUtil, 2) ? 2 : 0)} %`}
        />,
      );
    }
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class GcpUsageCommitmentContent extends GcpBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Expected ROI Period:" value={`${rec.breakEvenMonth} Months`} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(
      <ContentDetail display="Recommended Commitment:" value={rec.recommendedCommitmentTitle} />,
    );
    contentDetailsArray.push(<ContentDetail display="Commitment Type:" value={rec.commitmentType} />);
    contentDetailsArray.push(<ContentDetail display="Commitment Plan:" value={rec.commitmentPlan} />);
    contentDetailsArray.push(<ContentDetail display="Instance Type Model:" value={rec.instanceTypeModel} />);
    contentDetailsArray.push(<ContentDetail display="RAM Size:" value={`${rec.ramSize} GB`} />);
    contentDetailsArray.push(<ContentDetail display="Number of CPUs:" value={rec.vCPUs} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class GcpDiskUnattachedContent extends GcpBaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Project Name:" value={rec.projectName} />);
    contentDetailsArray.push(<ContentDetail display="Disk Name:" value={rec.diskName} />);
    contentDetailsArray.push(<ContentDetail display="Disk Status:" value={rec.diskStatus} />);
    contentDetailsArray.push(<ContentDetail display="Disk Size:" value={`${rec.diskSize} GB`} />);
    contentDetailsArray.push(<ContentDetail display="Disk Type:" value={rec.diskType} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

// Ec2BaseContent
class KmsOldContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class KmsIdleContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

class S3StorageClassContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    contentDetailsArray.push(
      <ContentDetail display="Bucket size:" value={`${numberWithCommas(rec.totalSizeGB, 3)} GB`} />,
    );
    return contentDetailsArray;
  }
}

class S3VersioningContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType, getCurrencyNumber, currencyCode);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    contentDetailsArray.push(
      <ContentDetail display="Bucket size:" value={`${numberWithCommas(rec.totalSizeGB, 3)} GB`} />,
    );
    return contentDetailsArray;
  }
}

class EcsFargateContent extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    super(rec, contentPageType);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Resource (serviceARN):" value={rec.resourceId} />);
    contentDetailsArray.push(<ContentDetail display="Age (days):" value={rec.age} />);
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    contentDetailsArray.push(<ContentDetail display="Current Memory:" value={rec.currentMemory} />);
    contentDetailsArray.push(<ContentDetail display="Recommended Memory:" value={rec.recommendedMemory} />);
    contentDetailsArray.push(<ContentDetail display="Memory change (%):" value={rec.memoryChange} />);
    contentDetailsArray.push(<ContentDetail display="Current CPU:" value={rec.currentCpu} />);
    contentDetailsArray.push(<ContentDetail display="Recommended CPU:" value={rec.recommendedCpu} />);

    contentDetailsArray.push(<ContentDetail display="CPU change (%):" value={rec.cpuChange} />);
    contentDetailsArray.push(
      <ContentDetail display="Current annual cost:" value={this.getCurrencyNumber(rec.currTotalCost)} />,
    );
    contentDetailsArray.push(
      <ContentDetail display="New annual cost:" value={this.getCurrencyNumber(rec.recommTotalCost)} />,
    );
    contentDetailsArray.push(<ContentDetail display="Tags:" value={rec.resourceTags} />);
    return contentDetailsArray;
  }
}

class IpPublicIpv4Content extends BaseRecommendationCreator {
  constructor(rec, contentPageType, getCurrencyNumber, currencyCode) {
    const tooltipTxt = "Annual savings are calculated based on last month's usage, multiplied by the hourly rate.";
    super(rec, contentPageType, getCurrencyNumber, currencyCode, tooltipTxt);
    this.rec = rec;
    this.contentPageType = contentPageType;
    this.getCurrencyNumber = getCurrencyNumber;
    this.currencyCode = currencyCode;
  }

  addContent(rec, contentPageType) {
    const contentDetailsArray = [];
    contentDetailsArray.push(<ContentDetail display="Region:" value={rec.region} />);
    const recCostContent = new CostContent(rec, this.getCurrencyNumber, this.currencyCode);
    recCostContent
      .createCostContent(rec, contentPageType)
      .forEach((costContent) => contentDetailsArray.push(costContent));
    return contentDetailsArray;
  }
}

// eslint-disable-next-line no-undef
export const mapRecTypetoContentCreator = new Map([
  [RecommendationTypes.IP_PUBLIC_IPV4, IpPublicIpv4Content],
  [RecommendationTypes.ECS_FARGATE, EcsFargateContent],
  [RecommendationTypes.KMS_IDLE, KmsIdleContent],
  [RecommendationTypes.KMS_OLD, KmsOldContent],
  [RecommendationTypes.EC2_VERSION_UPGRADE, VuContent],
  [RecommendationTypes.RDS_VERSION_UPGRADE, RdsVuContent],
  [RecommendationTypes.EC2_IDLE_LOAD_BALANCER, IdleLoadBalancerContent],
  [RecommendationTypes.DDB_IDLE, DbIdleContent],
  [RecommendationTypes.EBS_TYPE_CHANGE, EbsTypeChangeContent],
  [RecommendationTypes.EBS_UPGRADE, EbsUpgradeContent],
  [RecommendationTypes.EBS_TYPE_SIZE_CHANGE, EbsTypeAndSizeChangeContent],
  [RecommendationTypes.EC2_EBS_UNATTACHED, EbsUnattachedContent],
  [RecommendationTypes.EC2_IDLE, Ec2IdleContent],
  [RecommendationTypes.EC2_LOW_CPU_USAGE, Ec2LowCpuContent],
  [RecommendationTypes.EC2_SCHEDULE, Ec2ScheduleContent],
  [RecommendationTypes.EC2_IP_UNATTACHED, IpUnattachedContent],
  [RecommendationTypes.EC2_OPERATION_SYS, OperationSystemContent],
  [RecommendationTypes.ELASTICACHE_RI, ElasticacheRiContent],
  [RecommendationTypes.OPEN_SEARCH_RI, OpenSearchRiContent],
  [RecommendationTypes.RDS_RI, RdsRiContent],
  [RecommendationTypes.RDS_IDLE, RdsIdleContent],
  [RecommendationTypes.RDS_CLASS_CHANGE, RdsClassChangeContent],
  [RecommendationTypes.RDS_IOPS_CHANGE, RdsIopsChangeContent],
  [RecommendationTypes.S3_IDLE, S3IdleContent],
  [RecommendationTypes.S3_VERSIONING, S3VersioningContent],
  [RecommendationTypes.S3_STORAGE_CLASS, S3StorageClassContent],
  [RecommendationTypes.RDS_TYPE_CHANGE, RdsTypeChangeContent],
  [RecommendationTypes.EC2_REGION, RegionContent],
  [RecommendationTypes.EC2_REGION_MIGRATION, RegionMigrationContent],
  [RecommendationTypes.EC2_RI, RiContent],
  [RecommendationTypes.S3_VERSIONING, S3VersioningContent],
  [RecommendationTypes.S3_MULTIPART_UPLOAD, S3MultipartUploadContent],
  [RecommendationTypes.EC2_SAVINGS_PLANS, SavingsPlansContent],
  [RecommendationTypes.EC2_SPOT, SpotContent],
  [RecommendationTypes.EBS_OUTDATED_SNAPSHOT, EbsOutdatedSnapshotContent],
  [RecommendationTypes.EC2_UNNECESSARY_DATA_TRANSFER, Ec2UnnecessaryDataTransferContent],
  [RecommendationTypes.EC2_STOPPED_INSTANCE, Ec2StoppedInstanceContent],
  [RecommendationTypes.REDSHIFT_LOW_UTILIZATION, RedshiftLowUtilization],
  [RecommendationTypes.CLOUDTRAIL_DUPLICATES_TRAILS, CloudTrailDuplication],
  [RecommendationTypes.ELASTICSEARCH_LOW_UTILIZATION, ElasticsearchLowUtilization],
  [RecommendationTypes.ELASTICACHE_LOW_UTILIZATION, ElasticacheLowUtilization],
  [RecommendationTypes.NAT_GATEWAY_LOW_UTILIZATION, NatGatewayLowUtilization],
  [RecommendationTypes.DOCUMENT_DB_LOW_UTILIZATION, DocumentDbLowUtilization],
  [RecommendationTypes.NEPTUNE_LOW_UTILIZATION, NeptuneLowUtilization],
  [RecommendationTypes.KINESIS_LOW_UTILIZATION, KinesisLowUtilization],
  // AZURE *****
  [RecommendationTypes.AZURE_IP_UNATTACHED, AzureIpUnattachedContent],
  [RecommendationTypes.AZURE_VM_STOPPED, AzureVmStoppedContent],
  [RecommendationTypes.AZURE_MYSQL_DB_IDLE, AzureDbIdleContent],
  [RecommendationTypes.AZURE_COSMOS_DB_IDLE, AzureDbIdleContent],
  [RecommendationTypes.AZURE_MARIA_DB_IDLE, AzureDbIdleContent],
  [RecommendationTypes.AZURE_POSTGRESQL_DB_IDLE, AzureDbIdleContent],
  [RecommendationTypes.AZURE_SQL_DB_IDLE, AzureDbIdleContent],
  [RecommendationTypes.AZURE_DISK_UNATTACHED, AzureDiskUnattachedContent],
  [RecommendationTypes.AZURE_DISK_TYPE_CHANGE, AzureDiskTypeChangeContent],
  [RecommendationTypes.AZURE_IDLE_LOAD_BALANCER, AzureIdleLoadBalancerContent],
  [RecommendationTypes.AZURE_VM_RI, AzureRiContent],
  [RecommendationTypes.AZURE_VM_IDLE, AzureVmIdleContent],
  [RecommendationTypes.AZURE_APP_RIGHT_SIZING, AzureAppRightSizingContent],
  [RecommendationTypes.AZURE_DB_RI, AzureDbRiContent],
  [RecommendationTypes.AZURE_VM_RIGHT_SIZING, AzureVmRightSizing],
  [RecommendationTypes.AZURE_STORAGE, AzureStorageContent],
  [RecommendationTypes.AZURE_COSMOS_RIGHT_SIZING, AzureCosmosRightSizing],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_COSMOS, AzureReservedCapacityContent],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_DATA_EXPLORER, AzureReservedCapacityContent],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_MY_SQL, AzureReservedCapacityContent],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_PG, AzureReservedCapacityContent],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_REDIS, AzureReservedCapacityContent],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL, AzureReservedCapacityContent],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL_DATA_WH, AzureReservedCapacityContent],
  [RecommendationTypes.AZURE_DB_RESERVED_CAPACITY, AzureReservedCapacityContent],
  [RecommendationTypes.AZURE_SNAPSHOT_MIGRATION, AzureSnapshotMigrationContent],
  [RecommendationTypes.AZURE_OUTDATED_SNAPSHOT, AzureOutdatedSnapshotContent],
  [RecommendationTypes.AZURE_KUSTO_UNUSED_DATA, AzureKustoUnusedDataContent],
  [RecommendationTypes.AZURE_APP_SERVICE_RESERVED_CAPACITY, AzureAppSerRsrvdCapacityContent],
  // GCP *****
  [RecommendationTypes.GCP_IP_IDLE, GcpIpIdleContent],
  [RecommendationTypes.GCP_VM_IDLE, GcpVmIdleContent],
  [RecommendationTypes.GCP_VM_STOPPED, GcpVmStoppedContent],
  [RecommendationTypes.GCP_VM_RIGHT_SIZING, GcpRightSizingeContent],
  [RecommendationTypes.GCP_USAGE_COMMITMENT, GcpUsageCommitmentContent],
  [RecommendationTypes.GCP_DISK_UNATTACHED, GcpDiskUnattachedContent],
]);

export const renderContentDetails = (rec, contentPageType, getCurrencyNumber, currencyCode, currencySymbol) => {
  const contentDetailsArray = [];
  const RecTypeContentDetailsClass = mapRecTypetoContentCreator.get(rec.type);
  const recTypeContentDetails = new RecTypeContentDetailsClass(
    rec,
    contentPageType,
    getCurrencyNumber,
    currencyCode,
    currencySymbol,
  );
  const content = recTypeContentDetails.createContent(rec, getCurrencyNumber);
  if (content) {
    contentDetailsArray.push(...content);
  }
  return [...contentDetailsArray];
};
