/* eslint-disable no-param-reassign,prefer-const */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { CostUsageStates, K8S_GROUP_BY_LOV } from 'usage/constants/costAndUsageConstants';
import Panel from 'shared/components/Panel';
import {
  buildStartAndEndDate,
  buildTimeDiffDateFromBaseDate,
  createTimeZoneAgnosticDateFromStr,
} from 'shared/utils/dateUtil';
import DevExpSearchAndVisibilityTable from 'shared/components/tables/DevExpSearchAndVisibilityTable';
import SingleGroupByButton from 'shared/components/SingleGroupByButton';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import ChartsPanel from 'kubernetes/containers/KubernetesDashboard/components/ChartsPanel/ChartsPanel';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import {
  COST_BREAKDOWN_TABLE_COLUMNS,
  COST_BREAKDOWN_TABLE_COLUMNS_EXTENSIONS,
  COST_BREAKDOWN_TABLE_COLUMNS_WIDTHS,
  COST_BREAKDOWN_TABLE_COST_COLUMNS,
  mapCostBreakDownGroupByDisplayToValue,
  mapCostBreakDownGroupByValueToDisplay,
} from '../../constants/k8sConstants';

class K8ScostAndUsagePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: this.defaultDates().agnosticStartDate,
      endDate: this.defaultDates().agnosticEndDate,
      costBreakdownTableGroupBy: CostUsageStates.CLUSTER,
    };
  }

  async componentDidMount() {
    const { kubernetesStore, location } = this.props;
    const { startDate, endDate } = this.defaultDates();
    if (!location.source) {
      await kubernetesStore.fetchK8sData(startDate, endDate);
    }
  }
  handleGroupByChange = (val) => {
    this.setState({ costBreakdownTableGroupBy: val });
    const { startDate, endDate } = buildStartAndEndDate(this.state.startDate, this.state.endDate);
    this.props.kubernetesStore.getCostBreakDownData(startDate, endDate, val);
  };

  handleDateChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;
    if (startDate > endDate && endDate !== null) {
      endDate = startDate;
    }

    this.setState({ startDate, endDate });
    const start = buildStartAndEndDate(startDate, endDate).startDate;
    const end = buildStartAndEndDate(startDate, endDate).endDate;
    this.props.kubernetesStore.getCostBreakDownData(start, end, this.state.costBreakdownTableGroupBy);
  };

  defaultDates = () => {
    let { startDate, endDate } = buildStartAndEndDate(null, null);
    const fourteenDaysDate = buildTimeDiffDateFromBaseDate(endDate, -14, 'd').startDate;
    startDate = new Date(fourteenDaysDate) > new Date(startDate) ? startDate : fourteenDaysDate;
    const agnosticStartDate = createTimeZoneAgnosticDateFromStr(startDate);
    const agnosticEndDate = createTimeZoneAgnosticDateFromStr(endDate);
    return { startDate, endDate, agnosticStartDate, agnosticEndDate };
  };

  renderCostBreakTable = (data) => (
    <>
      <Row style={{ marginBottom: '25px' }}>
        <Col xs="12" md="12" lg="12" xl="12">
          <SingleGroupByButton
            title="Group By: "
            items={[K8S_GROUP_BY_LOV.BY_CLUSTER, K8S_GROUP_BY_LOV.BY_NAMESPACE, K8S_GROUP_BY_LOV.BY_NODE_GROUP]}
            handler={this.handleGroupByChange}
            selectedItem={mapCostBreakDownGroupByValueToDisplay.get(this.state.costBreakdownTableGroupBy)}
            itemsValuesMap={mapCostBreakDownGroupByDisplayToValue}
            isIconButton
          />
        </Col>
        <Col xs="12" md="12" lg="12" xl="12">
          <DatePickerFilter
            onDateChange={this.handleDateChange}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            currPeriodGranLevel="day"
          />
        </Col>
      </Row>
      {data.length > 0 && (
        <Row>
          <Col xs="12" md="12" lg="12" xl="12">
            <DevExpSearchAndVisibilityTable
              data={data}
              columns={COST_BREAKDOWN_TABLE_COLUMNS}
              costColumnsArray={COST_BREAKDOWN_TABLE_COST_COLUMNS}
              currenciesColumns={COST_BREAKDOWN_TABLE_COST_COLUMNS}
              tableColumnExtensions={COST_BREAKDOWN_TABLE_COLUMNS_EXTENSIONS}
              csvTitle="k8s-waste"
              columnWidths={COST_BREAKDOWN_TABLE_COLUMNS_WIDTHS}
              defaultHiddenColumnNames={[]}
              defaultSorting={[]}
              isResize
              isPaging
              isVisibility={false}
            />
          </Col>
        </Row>
      )}
    </>
  );

  render() {
    const {
      invoiceStore,
      location = {},
      kubernetesStore,
      usersStore,
      history,
      appStore,
      usageStore,
      isPpApplied,
    } = this.props;
    const { costBreakDownData, isCostBreakLoading } = kubernetesStore.kubernetesModel;
    return (
      <Container>
        <PageHeader title={PageNames.K8S_USAGE} showDate />
        <ChartsPanel
          usageStore={usageStore}
          location={location}
          appStore={appStore}
          usersStore={usersStore}
          kubernetesStore={kubernetesStore}
          invoiceStore={invoiceStore}
          history={history}
          isPpApplied={isPpApplied}
        />
        {usersStore.currDispUserCloudAccountType !== CLOUD_TYPE_IDS.GCP ? (
          <Panel divider title="COST BREAKDOWN" isColPaddingZero>
            {isCostBreakLoading ? (
              <div style={{ height: 400 }}>
                <Spinner />
              </div>
            ) : (
              this.renderCostBreakTable(costBreakDownData)
            )}
          </Panel>
        ) : null}
      </Container>
    );
  }
}

K8ScostAndUsagePage.propTypes = {
  invoiceStore: PropTypes.object.isRequired,
  kubernetesStore: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
  isPpApplied: PropTypes.bool.isRequired,
};

const observerK8ScostAndUsagePage = observer(K8ScostAndUsagePage);
export default observerK8ScostAndUsagePage;
