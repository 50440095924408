import React from 'react';
import { ONBOARDING_FIELDS } from '../utils/OnboardingConstants';

export const AZURE_ONBOARDING_STEPS = {
  ACCOUNT_TYPE: 'ACCOUNT_TYPE',
  CREATE_APP: 'CREATE_APP',
  APP_REGISTRATION: 'APP_REGISTRATION',
  VALIDATE_ACCOUNT: 'VALIDATE_ACCOUNT',
  BILLING_EXPORT: 'BILLING_EXPORT',
  PROCESS_DATA: 'PROCESS_DATA',
};

export const AZURE_ACCOUNT_TYPES = {
  EA: 'EA',
  MCA_EXPORT: 'MCA_EXPORT',
  PAYG_EXPORT: 'PAYG_EXPORT',
  CSP_EXPORT: 'CSP_EXPORT',
  EA_EXPORT: 'EA_EXPORT',
};

export const AZURE_COST_TYPES = {
  AMORTIZED: 'amortized',
  UNBLENDED: 'unblended',
};

export const AZURE_MCA_ONBOARDING_STEPS_SHORT_TITLES = {
  CREATE_APP: (
    <>
      Create application
      <br /> and export
    </>
  ),
  APP_REGISTRATION: 'App Registration',
  VALIDATE_ACCOUNT: 'Data Fetch Simulation',
  BILLING_EXPORT: 'Billing Export',
  PROCESS_DATA: 'Process data',
};

export const AZURE_ONBOARDING_FIELDS = {
  ...ONBOARDING_FIELDS,
  AZURE_ACCOUNT_TYPE: 'azureAccountType',
  APPLICATION_ID: 'applicationId',
  DIRECTORY_ID: 'directoryId',
  AZURE_COST_TYPE: 'azureCostType',
  APPLICATION_DISPLAY_NAME: 'applicationDisplayName',
  CLIENT_SECRET: 'clientSecret',
  CURRENCY_TYPE: 'currencyType',
  ACCOUNT_NAME: 'accountName',
  ACCOUNT_STORAGE: 'accountStorage',
  CONTAINER_NAME: 'containerName',
  DIRECTORY_PREFIX: 'directoryPrefix',
  IS_MULTIPLE_SOURCES: 'isMultipleSources',
  IS_MULTIPLE_FILES: 'isMultipleFiles',
  SKIP_DATA_SIMULATION: 'skipDataSimulation',
};

export const AZURE_ONBOARDING_STEP_TO_FIELDS = {
  [AZURE_ONBOARDING_STEPS.ACCOUNT_TYPE]: [AZURE_ONBOARDING_FIELDS.RESELLER_CUSTOMER],
  [AZURE_ONBOARDING_STEPS.BILLING_EXPORT]: [
    AZURE_ONBOARDING_FIELDS.ACCOUNT_STORAGE,
    AZURE_ONBOARDING_FIELDS.CONTAINER_NAME,
    AZURE_ONBOARDING_FIELDS.DIRECTORY_PREFIX,
  ],
  [AZURE_ONBOARDING_STEPS.APP_REGISTRATION]: [
    AZURE_ONBOARDING_FIELDS.APPLICATION_ID,
    AZURE_ONBOARDING_FIELDS.ACCOUNT_NAME,
    AZURE_ONBOARDING_FIELDS.DIRECTORY_ID,
    AZURE_ONBOARDING_FIELDS.APPLICATION_DISPLAY_NAME,
    AZURE_ONBOARDING_FIELDS.CLIENT_SECRET,
  ],
};
