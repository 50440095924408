/* eslint-disable max-len */
import { API } from 'shared/utils/apiMiddleware';
// eslint-disable-next-line no-unused-vars
import { buildFilterParams, getIncludeExcludeMaps, sanitizeParam, sanitizeQuery } from 'shared/utils/apiUtil';

class UsageApi {
  updateUserBudget = (
    budgetId,
    budget,
    budgetAmounts,
    period,
    startDate,
    endDate,
    isFlexible,
    budgetAlerts,
    isRelativeAlerts,
    budgetName,
    includeFilters,
    excludeFilters,
    budgetType,
    budgetAmountType,
    userKey,
  ) => {
    const body = {
      budgetId,
      budgetAmount: budget,
      budgetAmounts,
      period,
      startDate,
      endDate,
      isFlexible,
      budgetAlerts,
      isRelativeAlerts,
      budgetName,
      includeFilters,
      excludeFilters,
      budgetType,
      budgetAmountType,
      userKey,
    };
    const result = API.put('billings', '/api/v1/budgets/i/', { body });

    return result;
  };

  deleteUserBudget = (budgetId) => API.del('billings', `/api/v1/budgets?id=${budgetId}`);
  createUserBudget = (
    budget,
    budgetAmounts,
    period,
    startDate,
    endDate,
    isFlexible,
    budgetName,
    budgetAlerts,
    isRelativeAlerts,
    includeFilterParams,
    excludeFilterParams,
    budgetType,
    budgetAmountType,
  ) => {
    const body = {
      budgetAmount: budget,
      budgetAmounts,
      period,
      startDate,
      endDate,
      isFlexible,
      budgetName,
      budgetAlerts,
      isRelativeAlerts,
      budgetType,
      budgetAmountType,
      includeFilters: includeFilterParams,
      excludeFilters: excludeFilterParams,
    };
    const result = API.post('billings', '/api/v1/budgets/i/', { body });
    return result;
  };
  getUserBudget = () => API.get('billings', '/api/v1/budgets/i/');
  getBudgetById = (budgetId) => API.get('billings', `/api/v1/budgets/i/${budgetId}`);
  removeBudget = (budgetId) => API.del('billings', `/api/v1/budgets?budgetId=${budgetId}`);
  // ////////////////////////////
  // ///////////////////////////

  getRdsInstanceCosts = (startDate, endDate) =>
    API.get('billings', `/api/v1/usage/rds/instance-costs?startDate=${startDate}&endDate=${endDate}`);
  getCsvReportsAdditionalData = async () => {
    const getQuery = '/api/v1/usage/add-data';
    const result = await API.get('billings', getQuery);
    return result;
  };
  getS3BucketCosts = (startDate, endDate) =>
    API.get('billings', `/api/v1/usage/s3/bucket-costs?startDate=${startDate}&endDate=${endDate}`);

  // ANOMALY DETECTION

  getAnomalyDetectionData = () => API.get('billings', '/api/v1/usage/anomaly-detection');

  updateIsDismissedAnomaly = (anomalyKey) =>
    API.put('billings', `/api/v1/usage/anomaly-detection?anomalyKey=${anomalyKey}`);

  getAnomalyDetectionRules = () => API.get('billings', '/api/v1/usage/anomaly-detections/rules');

  createAnomalyDetectionRule = (body) => API.post('billings', '/api/v1/usage/anomaly-detections/rules', { body });

  updateAnomalyDetectionRule = (body) => API.put('billings', '/api/v1/usage/anomaly-detections/rules', { body });

  removeAnomalyDetectionRule = (ruleId) =>
    API.del('billings', `/api/v1/usage/anomaly-detections/rules?ruleId=${ruleId}`);

  // ALERTS

  saveCauAlerts = (alerts) => {
    const query = '/api/v1/usage/alerts';
    // const { id, ...rest } = alerts;
    const result = API.post('billings', query, { body: alerts });
    return result;
  };
  deleteCauAlert = (alertId) => {
    const query = `/api/v1/usage/alerts/${alertId}`;
    const result = API.get('billings', query);
    return result;
  };
  fetchCauAlerts = () => {
    const query = '/api/v1/usage/alerts';
    const result = API.get('billings', query);
    return result;
  };

  // Savings Plans Analyzer
  getSavingsPlansAnalyzerData = async (
    includeFilterParams,
    excludeFilterParams,
    daysToCheck,
    selectedPaymentOption,
    selectedTerm,
    coverage,
  ) => {
    let apiQuery = `/api/v1/usage/sp-analyzer?daysToCheck=${daysToCheck}&paymentOption=${selectedPaymentOption}&term=${selectedTerm}&coverage=${coverage}${includeFilterParams}${excludeFilterParams}`;
    apiQuery = sanitizeQuery(apiQuery);
    const result = await API.get('billings', apiQuery);
    return result;
  };

  // COMMITMENTS *********

  getCommitmentTableData = async (date, commitmentService, linkedAccount, commitmentType) => {
    const apiQuery = `/api/v1/usage/commitment?date=${date}&commitmentType=${commitmentType}&commitmentService=${commitmentService}&linkedAccount=${linkedAccount}`;
    const result = await API.get('billings', apiQuery);
    return result;
  };
  getCommitmentChartsAndStatisticsData = async (date, commitmentType) => {
    const apiQuery = `/api/v1/usage/commitment/commitment-charts?date=${date}&commitmentType=${commitmentType}`;
    const result = await API.get('billings', apiQuery);
    return result;
  };
  getCommitmentRiFlexabilityData = async (arn, startDate, endDate) => {
    const apiQuery = `/api/v1/usage/commitment/ri-flexibility?arn=${arn}&startDate=${startDate}&endDate=${endDate}`;
    const result = await API.get('billings', apiQuery);
    return result;
  };
  getCommitmentgetChargeBackData = async (arn, startDate, endDate) => {
    const apiQuery = `/api/v1/usage/commitment/chargeback?arn=${arn}&startDate=${startDate}&endDate=${endDate}`;
    const result = await API.get('billings', apiQuery);
    return result;
  };
  getServicePlansChargeBackData = async (arn, startDate, endDate) => {
    const apiQuery = `/api/v1/usage/commitment/sp-chargeback?arn=${arn}&startDate=${startDate}&endDate=${endDate}`;
    const result = await API.get('billings', apiQuery);
    return result;
  };

  // AZURE *************

  getAzureStorageUsageData = async (startDate, endDate) => {
    const query = `/api/v1/azure/services/storage?startDate=${startDate}&endDate=${endDate}`;
    const result = await API.get('billings', query);
    return result;
  };
  getAzureDbCostData = async (startDate, endDate) => {
    const query = `/api/v1/azure/services/db-costs?startDate=${startDate}&endDate=${endDate}`;
    const result = await API.get('billings', query);
    return result;
  };

  getAzureRiCoverage = async (startDate, endDate) => {
    const query = `/api/v1/azure/services/ri-coverage?startDate=${startDate}&endDate=${endDate}`;
    const result = await API.get('billings', query);
    return result;
  };

  // GCP *************
  // big query
  getBigQueryData = async (startDate, endDate, whereParams) => {
    let apiQuery = `/api/v1/services/big-query?startDate=${startDate}&endDate=${endDate}${whereParams}`;
    apiQuery = sanitizeQuery(apiQuery);
    const result = await API.get('billings', apiQuery);
    return result;
  };
  getBigQueryTableData = async (lastPageKey, lastTotalCost, startDate, endDate, whereParams) => {
    let apiQuery = `/api/v1/services/big-query/table?lastPageKey=${lastPageKey}&lastTotalCost=${
      lastTotalCost || ''
    }&startDate=${startDate}&endDate=${endDate}${whereParams}`;
    apiQuery = sanitizeQuery(apiQuery);
    const result = await API.get('billings', apiQuery);
    return result;
  };

  getBigQueryDistinctValues = async () => {
    const apiQuery = '/api/v1/services/big-query/distinct-values';
    const result = await API.get('billings', apiQuery);
    return result;
  };

  // Idle Instances
  getIdleInstancesCriterias = () => API.get('billings', '/api/v1/usage/idle-instances/criteria');

  createIdleInstancesCriteria = (body) => API.post('billings', '/api/v1/usage/idle-instances/criteria', { body });
  deleteIdleCriteria = (id) => API.del('billings', `/api/v1/usage/idle-instances/criteria?criteriaId=${id}`);

  updateIdleCriteriaRule = (body) => API.put('billings', '/api/v1/usage/idle-instances/criteria', { body });

  // removeIdleInstancesCriteria = ruleId => API.del('billings', `/api/v1/usage/idle-instances/critaeria?critaeriaId=${ruleId}`);

  getIdleInstancesTableData = async (body) => {
    const linkedAccounts = body.linkedAccounts.map((la) => `&linkedAccounts=${la}`).join('');
    const criteriaIds = body.criteriaIds ? body.criteriaIds.map((cn) => `&criteriaIds=${cn}`).join('') : '';
    const apiQuery = `/api/v1/usage/idle-instances/data?startDate=${body.startDate}&endDate=${body.endDate}${linkedAccounts}${criteriaIds}`;
    const result = await API.get('billings', apiQuery);
    return result;
  };

  getIdleInstanceRowData = (criteriaId, instanceId, startDate, endDate) =>
    API.get(
      'billings',
      `/api/v1/usage/idle-instances/instanceData?criteriaId=${criteriaId}&instanceId=${instanceId}&startDate=${startDate}&endDate=${endDate}`,
    );

  // KPIS
  getKpiBusinessUnitCoverageData = (service, granularity, startDate, endDate) =>
    API.get(
      'billings',
      `/api/v1/usage/kpis/business-unit-coverage?service=${service}&granularity=${granularity}&startDate=${startDate}&endDate=${endDate}`,
    );

  getKpiCoverageByPricingMethod = (service, granularity, startDate, endDate) =>
    API.get(
      'billings',
      `/api/v1/usage/kpis/pricing-method-coverage?service=${service}&granularity=${granularity}&startDate=${startDate}&endDate=${endDate}`,
    );

  getKpieBusinessUnitReservationUtilization = async (service, granularity, startDate, endDate) =>
    API.get(
      'billings',
      `/api/v1/usage/kpis/business-unit-reservation-utilization?service=${service}&granularity=${granularity}&startDate=${startDate}&endDate=${endDate}`,
    );

  getKpiCashBasis = (granularity, startDate, endDate) =>
    API.get(
      'billings',
      `/api/v1/usage/kpis/cash-basis?granularity=${granularity}&startDate=${startDate}&endDate=${endDate}`,
    );
  getKpiCostPerHour = (service, granularity, startDate, endDate) =>
    API.get(
      'billings',
      `/api/v1/usage/kpis/cost-per-hour?service=${service}&granularity=${granularity}&startDate=${startDate}&endDate=${endDate}`,
    );
  getKpiMonthlyCostPerHour = (service, granularity, startDate, endDate) =>
    API.get(
      'billings',
      `/api/v1/usage/kpis/monthly-cost-per-hour?service=${service}&granularity=${granularity}&startDate=${startDate}&endDate=${endDate}`,
    );
  getKpiQtdCoverageByService = (service, granularity, startDate, endDate) =>
    API.get(
      'billings',
      `/api/v1/usage/kpis/qtd-service-coverage?service=${service}&startDate=${startDate}&endDate=${endDate}`,
    );
  getKpiQtdUtilizationByService = (service, year, startMonth, endMonth) =>
    API.get(
      'billings',
      `/api/v1/usage/kpis/qtd-service-utilization?service=${service}&year=${year}&startMonth=${startMonth}&endMonth=${endMonth}`,
    );
  getKpiSavingsOnDemand = (yearQuarter) =>
    API.get('billings', `/api/v1/usage/kpis/savings-on-demand?yearQuarter=${yearQuarter}`);
  qtdPileusSavings = () => API.get('billings', '/api/v1/usage/kpis/qtd-pileus-savings');
}

export default UsageApi;
