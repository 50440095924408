import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import styles from './suggestions.module.scss';

export const Suggestions = ({ userData, selectSuggestion }) => {
  const renderQuestions = (questions) =>
    questions.map((question) => (
      <li key={question}>
        <button onClick={() => selectSuggestion(question)} type="button" title={question}>
          <GenerateIcon iconName={ICONS.circleQuestion.name} />
          {question}
        </button>
      </li>
    ));

  return (
    <section className={styles.suggestions} automation-id="gptSuggestions">
      {!!userData?.frequent?.length && (
        <>
          <h4>Your recent questions</h4>
          <ul>{renderQuestions(userData.frequent)}</ul>
        </>
      )}
      {!!userData?.recommended?.length && (
        <>
          <h4>Recommended for you</h4>
          <ul>{renderQuestions(userData.recommended)}</ul>
        </>
      )}
    </section>
  );
};

Suggestions.propTypes = {
  userData: PropTypes.shape({
    frequent: PropTypes.array.isRequired,
    recommended: PropTypes.array.isRequired,
  }).isRequired,
  selectSuggestion: PropTypes.func.isRequired,
};
