import { useMutation, useQuery, useQueryClient } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import toast from 'shared/components/andtComponents/Toast';
import { createTimeZoneAgnosticDate } from 'shared/utils/dateUtil';

const root = '/api/v1/budgets/v2/i/';

export const mapBudgetParams = (body) => {
  const budgetAlerts = body.budgetAlerts?.map((alert) => ({
    ...alert,
    alertGranularity: alert.alertGranularity.map((a) => a.value),
  }));
  const budget = {
    budgetName: body.budgetName,
    budgetAmount: body.budgetAmount,
    budgetAmounts: body.budgetAmounts.map((amount) => ({
      amount: amount.amount,
      date: amount.date,
    })),
    endDate: typeof body.endDate === 'string' ? body.endDate : createTimeZoneAgnosticDate(body.endDate),
    startDate: typeof body.startDate === 'string' ? body.startDate : createTimeZoneAgnosticDate(body.startDate),
    isRelativeAlerts: body.isRelativeAlerts || true,
    budgetAlerts: budgetAlerts || [],
    costType: body.costType,
    budgetId: body.budgetId,
    description: body.description,
    isFlexible: body.isFlexible,
    excludeFilters: {},
    includeFilters: {},
    budgetType: body.budgetType?.value ? body.budgetType?.value : body.budgetType,
    budgetAmountType: body.budgetType?.value === 'expiring' || body.budgetType === 'expiring' ? 'planned' : 'fixed',
    period: body.period || 0,
    userKey: body.userKey,
  };
  if (body.budgetType === 'expiringFixed' || body.budgetType?.value === 'expiringFixed') {
    budget.budgetType = 'expiring';
  }
  Object.keys(body.includeFilters)?.forEach((key) => {
    const filterValues = body.includeFilters[key].map((filter) => filter.value);
    if (filterValues?.length > 0) {
      budget.includeFilters[key] = filterValues;
    }
  });
  Object.keys(body.excludeFilters)?.forEach((key) => {
    const filterValues = body.excludeFilters[key].map((filter) => filter.value);
    if (filterValues?.length > 0) {
      budget.excludeFilters[key] = filterValues;
    }
  });
  return budget;
};
const getUserBudget = () => API.get('billings', root);
export const getBudgetById = (budgetId) => API.get('billings', `${root}${budgetId}`);
const createUserBudgetApi = (body) => API.post('billings', '/api/v1/budgets/i', { body });
const editUserBudgetApi = (body) => API.put('billings', '/api/v1/budgets/i', { body });
const deleteUserBudgetApi = (budgetId) => API.del('billings', `/api/v1/budgets?budgetId=${budgetId}`);

export default function useBudgets() {
  const queryClient = useQueryClient();
  return {
    getUserBudget: (options = {}) =>
      useQuery(
        ['fetchBudgets', apiConstants.QUERY_KEYS.BUDGET],
        async () => {
          const budgets = await getUserBudget();
          return budgets?.map((d) => ({ ...d, isExpired: d.endDate && new Date(d.endDate) < new Date() })) || [];
        },
        {
          ...options,
          onError: (error) => {
            toast.error(error.response.data.clientMessage || 'Error get budgets');
          },
        },
      ),
    getBudgetById: (budgetId, options = {}) =>
      useQuery(['fetchBudget', apiConstants.QUERY_KEYS.BUDGET], () => getBudgetById(budgetId), {
        ...options,
        onError: (error) => {
          toast.error(error.response.data.clientMessage || `Error get budget: ${budgetId}`);
        },
      }),
    createUserBudget: (success) =>
      useMutation(createUserBudgetApi, {
        onSuccess: (res) => {
          success(res);
          return queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUDGET);
        },
        onError: (error) => {
          toast.error(error.response.data.clientMessage || error.response.data.message || 'Error when create budget');
        },
      }),
    editBudget: (success) =>
      useMutation(editUserBudgetApi, {
        onSuccess: (res) => {
          success(res);
          return queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUDGET);
        },
        onError: (error) => {
          toast.error(error.response.data.clientMessage || error.response.data.message || 'Error edit budget');
        },
      }),
    deleteBudget: (success) =>
      useMutation(deleteUserBudgetApi, {
        onSuccess: () => {
          success();
          return queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUDGET);
        },
      }),
  };
}
