/**
 *
 * @param row
 * @param customTagsFieldName - the name of customTags field in row
 * @param enrichmentTagsFieldName - the name of enrichmentTag field in row
 * @returns {data: {}, exportTagColumns: []} - data is object include  each key in customTags/enrichmentTag
 *                                             for display them in separate column in csv file.
 *                                             exportTagColumns - depend on the keys in customTags/enrichmentTag
 */

export const getTagsColumnsForCsv = (row, customTagsFieldName, enrichmentTagsFieldName) => {
  const customTags = row[customTagsFieldName];
  const enrichmentTags = row[enrichmentTagsFieldName];
  const exportTagColumns = [];
  const recTags = {};
  Object.keys(customTags || {})
    .concat(Object.keys(enrichmentTags || {}))
    .forEach((tag) => {
      if (customTags && customTags[tag] && enrichmentTags && enrichmentTags[tag]) {
        recTags[`${tag}`] = `${customTags[tag]}, ${enrichmentTags[tag]}`;
      } else {
        recTags[`${tag}`] = customTags && customTags[tag] ? customTags[tag] : enrichmentTags[tag];
      }
      if (!exportTagColumns.find((col) => col.label === tag)) {
        exportTagColumns.push({ label: tag, key: tag });
      }
    });
  return { data: recTags, exportTagColumns };
};
