/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const handleChange = (event, handler, mapValuesDescToValues) => {
  const getValue = event.target.value;
  const validatedValue = mapValuesDescToValues && mapValuesDescToValues.get(getValue) ? getValue : getValue;
  handler(validatedValue, event);
};

const renderList = (arrValues, mapRegionNamesToDescription, id) => (
  <datalist id={id}>
    {arrValues.map((val, indx) => (
      <option key={`${val + indx}`} value={val}>
        {mapRegionNamesToDescription.get(val)}
      </option>
    ))}
  </datalist>
);

const IconInputTextArea = memo(
  ({
    handler,
    label,
    name,
    value,
    type = 'text',
    placeHolder,
    icon,
    arrValues,
    mapRegionNamesToDescription,
    list,
    ...rest
  }) => (
    <div>
      <span className="inner-wizard-input-legend">{label}</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <span className={icon} />
        </div>
        <textarea
          name={`${name}`}
          value={value}
          onChange={(event) => handleChange(event, handler, mapRegionNamesToDescription)}
          // type={`${type}`}
          placeholder={placeHolder}
          list={list || null}
          // maxLength={100}
        />
        {/* {list && arrValues.length > 0 ? renderList(arrValues, mapRegionNamesToDescription, list) : null} */}
      </div>
    </div>
  ),
);

export default IconInputTextArea;

IconInputTextArea.propTypes = {
  handler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  list: PropTypes.string.isRequired,
  mapRegionNamesToDescription: PropTypes.object.isRequired,
  arrValues: PropTypes.array.isRequired,
};
