/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
import If from 'shared/components/SimpleIf';
import DownloadAsPngLink from 'shared/components/DownloadAsPngLink';
import PropTypes from 'prop-types';
import { random5DigitNumber } from 'shared/utils/mathUtil';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import ChartKeysFilter from 'shared/components/ChartKeysFilter/ChartKeysFilter';
import { useUserSettingsContext, withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

const modifyXAxisTickFormatter = (timeVal, granLevel) => {
  if (timeVal) {
    return createDateDisplayStr(timeVal, granLevel || undefined);
  }
  return '';
};

const liStyle = (nameColor) => ({
  position: 'relative',
  display: 'inline-block',
  padding: '0.4rem 0.4rem',
  'margin-bottom': '-2px',
  'border-radius': '3px',
  border: '0.5px solid #e0e0e0',
  background: nameColor,
  maxWidth: '10px',
  maxHeight: '10px',
});

const CustomTooltip = ({ active, payload, data, currKey, currCommitmentType, granLevel }) => {
  const { numStrAbriviaionByDisplayMetric } = useUserSettingsContext();
  if (active && payload && payload.length && currCommitmentType && currKey) {
    const { usageDate: uDate, date } = payload[0].payload && payload[0].payload;
    // Some data arrives with usageDate property, and some with date property.
    const currDate = uDate || date;
    const currDateData = data.filter(({ usageDate }) => usageDate === currDate);
    const { usageDate, ...chartData } = currDateData && currDateData.length && currDateData[0];
    const dateTotalSum = Object.keys(chartData).reduce((accumulator, key) => {
      if (key.toLowerCase().includes(currKey.toLowerCase())) {
        return accumulator + parseFloat(chartData[key]);
      }
      return accumulator;
    }, 0);
    const currPayloads = payload.filter((currPayloadData) =>
      currPayloadData.dataKey.toLowerCase().includes(currKey.toLowerCase()),
    );
    return (
      <div className="commitment-savings-chart-tooltip fs-exclude">
        <p className="label">{`Date : ${modifyXAxisTickFormatter(currDate, granLevel)}`}</p>
        <b>{`Total ${currKey}: ${numStrAbriviaionByDisplayMetric(+dateTotalSum, +dateTotalSum, 'Cost')}`}</b>
        <ul>
          {currPayloads.map((currPayload) => (
            <div>
              <li style={liStyle(currPayload.fill)} />
              <span style={{ 'font-weight': '400' }}>
                {`${currPayload.dataKey} :
                ${numStrAbriviaionByDisplayMetric(+currPayload.value, +currPayload.value, 'Cost')}
                `}
              </span>
            </div>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  currKey: PropTypes.string.isRequired,
  currCommitmentType: PropTypes.string.isRequired,
  granLevel: PropTypes.string.isRequired,
};
CustomTooltip.defaultProps = {};

class CostChart extends PureComponent {
  static propTypes = {
    chartId: PropTypes.string,
    data: PropTypes.object.isRequired,
    isRenderDownloadAsPng: PropTypes.bool,
    granLevel: PropTypes.string,
    service: PropTypes.string.isRequired,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
  };

  static defaultProps = {
    chartId: null,
    isRenderDownloadAsPng: false,
    granLevel: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      currKey: '',
      currIndex: null,
      currCommitmentType: null,
      favourites: [],
      filteredKeys: [],
      filteredDataKeys: [],
    };
  }

  componentDidMount() {
    this.initLegendKeys();
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    const { data: prevData } = prevProps;
    if (data.length !== prevData.length && data.length > 0) {
      this.initLegendKeys();
    }
  }

  initLegendKeys = () => {
    const { data } = this.props;
    if (!data || !data.length) {
      return;
    }
    const filteredKeys = Object.keys(data[0]).filter((key) => !['usageDate', 'date'].includes(key));
    const favourites = new Array(filteredKeys.length).fill(0).map((_, ind) => ind);
    this.setState({
      favourites,
      filteredKeys,
      filteredDataKeys: filteredKeys.map((key, ind) => ({
        id: favourites[ind],
        name: key,
      })),
    });
  };

  render() {
    const { data, isRenderDownloadAsPng, chartId, granLevel, service, numStrAbriviaionByDisplayMetric } = this.props;
    const { filteredDataKeys, favourites, filteredKeys, currCommitmentType, currKey, currIndex } = this.state;

    const customMouseOver = (e, index, dataKey, currCommitmentTypeTemp) => {
      const { currCommitmentType } = this.state;
      if (dataKey !== currKey || index !== currIndex || currCommitmentType !== currCommitmentTypeTemp) {
        this.setState({ currKey: dataKey, currIndex: index, currCommitmentType: currCommitmentTypeTemp });
      }
    };
    const finalChartId = chartId || `cost-chart-${random5DigitNumber()}`;
    return (
      <Container>
        <If cond={isRenderDownloadAsPng}>
          <DownloadAsPngLink chartId={finalChartId} />
        </If>
        <Row id={finalChartId}>
          <ResponsiveContainer height={400}>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="usageDate"
                tickCount={0}
                tickFormatter={(value) => modifyXAxisTickFormatter(value, granLevel)}
              />
              <YAxis
                tickLine
                label={{
                  value: 'Cost',
                  offset: 0,
                  angle: -90,
                  position: 'left',
                }}
                type="number"
                tickFormatter={(value) => numStrAbriviaionByDisplayMetric(value, value, 'Cost')}
              />
              <Tooltip
                cursor={false}
                content={
                  <CustomTooltip
                    currCommitmentType={currCommitmentType}
                    data={data}
                    currKey={currKey}
                    granLevel={granLevel}
                  />
                }
              />
              {filteredKeys.includes('RI Savings') && (
                <Bar
                  onMouseOver={(e, index) => customMouseOver(e, index, 'Savings', 'RI')}
                  dataKey="RI Savings"
                  stackId="a"
                  fill="#86b582"
                />
              )}
              {service === 'ec2' && filteredKeys.includes('SP Savings') && (
                <Bar
                  onMouseOver={(e, index) => customMouseOver(e, index, 'Savings', 'SP')}
                  dataKey="SP Savings"
                  stackId="a"
                  fill="#abd6c4"
                />
              )}
              {filteredKeys.includes('RI Waste') && (
                <Bar
                  onMouseOver={(e, index) => customMouseOver(e, index, 'Waste', 'RI')}
                  dataKey="RI Waste"
                  stackId="b"
                  fill="#ff6b64"
                />
              )}
              {service === 'ec2' && filteredKeys.includes('SP Waste') && (
                <Bar
                  onMouseOver={(e, index) => customMouseOver(e, index, 'Waste', 'SP')}
                  dataKey="SP Waste"
                  stackId="b"
                  fill="#f3a391"
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </Row>
        <Row className="m-auto" style={{ width: 'fit-content' }}>
          <ChartKeysFilter
            hidePanel
            data={filteredDataKeys}
            favourites={favourites}
            filteredKeys={filteredKeys}
            getLabelColor={(keyName) => {
              switch (keyName) {
                case 'SP Waste':
                  return '#f3a391';
                case 'RI Waste':
                  return '#ff6b64';
                case 'SP Savings':
                  return '#abd6c4';
                case 'RI Savings':
                  return '#86b582';
                default:
                  return null;
              }
            }}
            addKeysFilterHandler={([elem]) => {
              this.setState((prevState) => ({
                favourites: [...prevState.favourites, elem.id],
                filteredKeys: [...prevState.filteredKeys, elem.name],
              }));
            }}
            removeKeysFilterHandler={([elem]) => {
              this.setState((prevState) => ({
                favourites: prevState.favourites.filter((f) => f !== elem.id),
                filteredKeys: prevState.filteredKeys.filter((f) => f !== elem.name),
              }));
            }}
            isShowOthers={false}
            isShowOthersChange={null}
            filteredDataKeys={filteredDataKeys}
          />
        </Row>
      </Container>
    );
  }
}

const ObserverCostChart = withUserSettingsConsumer(CostChart);

export default ObserverCostChart;
