import React from 'react';
import { SWITCH_BUTTON_STATUSES } from 'recommendationsNew/consts';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';

import classes from './recommendationStatusSymbol.module.scss';

export const OpenStatus = () => (
  <div className={`${classes.openStatus} ${classes.statusContainer}`}>{SWITCH_BUTTON_STATUSES.OPEN}</div>
);

export const CompletedStatus = () => (
  <div className={`${classes.completedStatus} ${classes.statusContainer}`}>{SWITCH_BUTTON_STATUSES.COMPLETED}</div>
);

export const DoneStatus = () => <GenerateIcon iconName={ICONS.clipboardCheck.name} className={classes.doneStatus} />;
