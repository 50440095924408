import React from 'react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { copyStrToClipboard } from 'shared/utils/strUtil';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import Button from 'shared/components/andtComponents/Button';
import { prepareLinkAccountLabel } from 'recommendations/containers/Dashboard/helpers/dataPrepareHelpers';
import { useRootStore } from 'app/contexts/RootStoreContext';
import styles from './Notification.module.scss';

const Notification = ({ notification, excludeLoading, onExclude, checked, onChecked }) => {
  const { usersStore } = useRootStore();
  const { currDispUserCloudAccountType } = usersStore;
  const copyToClipboard = () => {
    copyStrToClipboard(notification.details.id);
  };
  const excludeLinkedAccount = () => {
    onExclude();
  };
  const { title, subTitle, icon, description } = notification;
  if (!title) {
    return null;
  }
  return (
    <div className={styles.container}>
      <p className={styles.title}>
        {notification.excludable && (
          <Checkbox primary isChecked={checked} onChange={() => onChecked(!checked)} text="" />
        )}
        {title}
      </p>
      {subTitle && (
        <p className={styles.subTitle}>
          {icon ? <GenerateIcon iconName={ICONS[icon].name} /> : null}
          <span>{subTitle}</span>
          {notification.details.id && (
            <Button
              isTextButton
              icon={() => <GenerateIcon iconName={ICONS.clone.name} />}
              text=""
              onClick={copyToClipboard}
            />
          )}
          {notification.excludable && (
            <Tooltip
              title={`Don't show notifications on this ${prepareLinkAccountLabel(
                currDispUserCloudAccountType,
                true,
              ).toLowerCase()} again.`}
            >
              <span>
                <Button
                  disabled={excludeLoading}
                  isTextButton
                  icon={() => <GenerateIcon iconName={ICONS.excluded.name} />}
                  text=""
                  onClick={excludeLinkedAccount}
                />
              </span>
            </Tooltip>
          )}
        </p>
      )}
      <p className={styles.description}>{description}</p>
    </div>
  );
};

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  excludeLoading: PropTypes.bool.isRequired,
  onChecked: PropTypes.func.isRequired,
  onExclude: PropTypes.func.isRequired,
};

export default Notification;
