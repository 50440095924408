import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getSidebarItemsByAccountTypeIdAndCloudTypeId } from 'shared/constants/routesMapping';
import { Sidebar } from '@pileus-cloud/anodot-frontend-common/dist';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';

// maintain this list to control which menu items should have the new indication
const newMenus = [
  {
    menu: 'anomalyDetection',
    ff: ACCOUNT_FEATURES.ANOMALY_DETECTION,
  },
  {
    menu: 'costGpt',
    ff: ACCOUNT_FEATURES.COST_GPT,
  },
  {
    menu: 'budgetNew',
  },
  {
    menu: 'recommendationsNew',
    ff: ACCOUNT_FEATURES.RECOMMENDATIONS,
  },
];
const SidebarContent = ({ cloudType, userType, accountFeatures, accountType, usersStore, history }) => {
  const [sidebarOptions, setSidebarOptions] = useState([]);
  const [location, setLocation] = useState('');

  // add new indications
  const enrichMenuOptionsData = useCallback((menuOptions) => {
    const currentRouteUrl = history.location.pathname;
    return (menuOptions || []).map((parentMenu) => ({
      ...parentMenu,
      isNew: newMenus.some((newMenu) => newMenu.menu === parentMenu.value && checkFeatureFlag(usersStore, newMenu.ff)),
      isActive:
        (parentMenu.route && parentMenu.route === currentRouteUrl) ||
        [parentMenu.children || []].some((child) => child.route === currentRouteUrl),
      children: (parentMenu.children || []).map((childMenu) => ({
        ...childMenu,
        isActive: childMenu.route === currentRouteUrl,
        isNew: newMenus.some(
          (newMenu) => newMenu.menu === childMenu.value && (!newMenu.ff || checkFeatureFlag(usersStore, newMenu.ff)),
        ),
      })),
    }));
  }, []);

  // update menu options when any of the affecting params changes
  useEffect(() => {
    const menuOptions = getSidebarItemsByAccountTypeIdAndCloudTypeId(
      +accountType,
      +cloudType,
      +userType,
      accountFeatures,
      usersStore,
    );

    const updatedMenu = enrichMenuOptionsData(menuOptions);
    setSidebarOptions(updatedMenu);
  }, [cloudType, userType, accountFeatures, accountType, usersStore, history.location.pathname, location]);

  // This effect is creating a listener to history changes when sidebar component is first mounted
  // this is needed for cases where navigation to a page is done with <Link> component
  // which does not re-render components that uses withRouter
  useEffect(() => {
    const unlisten = history.listen((location) => {
      setLocation(location.pathname);
    });
    return () => {
      unlisten();
    };
  }, []);

  const findMenuItem = useCallback(
    (item) => {
      if (item) {
        const index = item.indexOf('/');
        let parentItem = item;
        let childItem = null;
        let found;

        if (index > -1) {
          // The selected item is a child inside a parent.
          parentItem = item.slice(0, index);
          childItem = item.slice(index + 1, item.length);
          found = sidebarOptions.find((i) => i.value === parentItem).children.find((j) => j.value === childItem);
        } else {
          found = sidebarOptions.find((i) => i.value === parentItem);
        }
        return found;
      }
      return null;
    },
    [sidebarOptions],
  );

  const handleOnValueChange = useCallback(
    (item, isOpenNewWindow = false) => {
      const selectedMenuItem = findMenuItem(item);
      if (selectedMenuItem) {
        const url = selectedMenuItem.route;
        if (isOpenNewWindow) {
          // open menu link in new window
          window.open(url, '_blank');
          return;
        }
        history.push(url);
      }
    },
    [sidebarOptions],
  );

  return <Sidebar options={sidebarOptions} onValueChange={handleOnValueChange} />;
};

SidebarContent.propTypes = {
  userType: PropTypes.number.isRequired,
  usersStore: PropTypes.object.isRequired,
  accountFeatures: PropTypes.arrayOf(PropTypes.string),
  cloudType: PropTypes.number,
  accountType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  history: PropTypes.object.isRequired,
};
SidebarContent.defaultProps = {
  accountFeatures: [],
  cloudType: undefined,
  accountType: undefined,
};

export default withRouter(SidebarContent);
