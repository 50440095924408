/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase,arrow-parens */
/* eslint-disable  no-param-reassign */
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { createTimeZoneAgnosticDateFromStr } from 'shared/utils/dateUtil';
import moment from 'moment';
import DateFilter from 'shared/modules/dateFilter';
import { prepareBodyFiltersIncludeOrExclude } from 'shared/utils/dataPrepareUtil';
import {
  BudgetAlertGranularityTypes,
  BudgetAlertsTypes,
  BudgetAmountTypes,
  BudgetTypes,
  TempBudgetTypes,
} from 'usage/constants/usageConstants';
import { ErrorNotificationsLabels } from 'shared/constants/notificationsConstants';
import toast from 'shared/components/andtComponents/Toast';
import Budget from './helperClasses/budget';

export default class BudgetModel {
  errMsgs = [];
  budgets = [];
  tempBudgets = {
    [TempBudgetTypes.CREATE]: new Budget(
      'uuid', // uuid
      'user_key', // user_key
      3000, // budget_amount
      [], // budget_amounts
      0, // new_budget_amount
      BudgetTypes.RECURRING, // budget_type
      BudgetAmountTypes.FIXED, // budget_amount_type
      0, // period
      DateFilter.getFirstDayOfCurrMonthDate(), // start_date
      DateFilter.getFirstDayOfCurrMonthNextYearDate(), // end_date
      1, // flexible
      true, // is_alerts
      true, // is_relative_alerts
      {
        1: {
          alertPercent: '',
          alertEmail: '',
          whenToAlert: [BudgetAlertsTypes.FORECASTED],
          alertGranularity: [BudgetAlertGranularityTypes.DAILY],
        },
      }, // budget_alerts
      DateFilter.getFirstDayOfCurrMonthDate(), // db_creation_time
      null, // prepared_costs_data
      null, // prepared_accum_costs_data
      0, // is_active
      null, // total_cost
      '', // budget_name
      null, // total_forcasted
      [], // linked_accounts
      [], // services
      [], // tags
      [], // include_filters
      [], // exclude_filters
      [], // previous_budgets
    ),
    [TempBudgetTypes.UPDATED]: new Budget(
      'uuid', // uuid
      'user_key', // user_key
      3000, // budget_amount
      [], // budget_amounts
      0, // new_budget_amount
      BudgetTypes.RECURRING, // budget_type
      BudgetAmountTypes.FIXED, // budget_amount_type
      0, // period
      DateFilter.getFirstDayOfCurrMonthDate(), // start_date
      DateFilter.getFirstDayOfCurrMonthNextYearDate(), // end_date
      1, // flexible
      true, // is_alerts
      true, // is_relative_alerts
      {
        1: {
          alertPercent: '',
          alertEmail: '',
          whenToAlert: [BudgetAlertsTypes.FORECASTED],
          alertGranularity: [BudgetAlertGranularityTypes.DAILY],
        },
      }, // budget_alerts
      DateFilter.getFirstDayOfCurrMonthDate(), // db_creation_time
      null, // prepared_costs_data
      null, // prepared_accum_costs_data
      0, // is_active
      null, // total_cost
      '', // budget_name
      null, // total_forcasted
      [], // linked_accounts
      [], // services
      [], // tags
      [], // include_filters
      [], // exclude_filters
      [], // previous_budgets
    ),
  };
  // };
  isCreateBudgetTimeoutModal = false;

  constructor(apiGateway) {
    this.apiGateway = apiGateway;
    this.isLoading = false;
    this.isInitialFetchDone = false;
    this.createBudgetLoading = false;
    makeObservable(this, {
      tempBudgets: observable,
      budgets: observable,
      isLoading: observable,
      createBudgetLoading: observable,
      isCreateBudgetTimeoutModal: observable,
      errMsgs: observable,
      isInitialFetchDone: observable,
      updateBudget: action,
      // invalidate: action,
      deleteBudget: action,
      createBudget: action,
      monthlyBudget: computed,
    });
  }

  createBudgetInstance = (
    uuid,
    user_key,
    budget_amount,
    budget_amounts,
    new_budget_amount,
    budget_type,
    budget_amount_type,
    period,
    start_date,
    end_date,
    flexible,
    is_alerts,
    is_relative_alerts,
    alerts,
    db_creation_time,
    prepared_costs_data,
    prepared_accum_costs_data,
    is_active,
    total_cost,
    budget_name,
    total_forcasted,
    linked_accounts,
    services,
    tags,
    include_filters,
    exclude_filters,
    previous_budgets,
    monthly_start_date,
    // alerts,
  ) =>
    new Budget(
      uuid,
      user_key,
      budget_amount,
      budget_amounts,
      new_budget_amount,
      budget_type,
      budget_amount_type,
      period,
      createTimeZoneAgnosticDateFromStr(start_date),
      createTimeZoneAgnosticDateFromStr(end_date || DateFilter.getFirstDayOfCurrMonthNextYearDate()),
      flexible,
      is_alerts,
      is_relative_alerts,
      alerts,
      db_creation_time,
      prepared_costs_data,
      prepared_accum_costs_data,
      is_active,
      total_cost,
      budget_name,
      total_forcasted,
      linked_accounts,
      services,
      tags,
      include_filters,
      exclude_filters,
      previous_budgets,
      createTimeZoneAgnosticDateFromStr(monthly_start_date),
    );

  prepareBudget = (rawBudget) => {
    const {
      uuid,
      user_key,
      budget_amount,
      budget_amounts,
      new_budget_amount,
      budget_type,
      budget_amount_type,
      period,
      start_date,
      end_date,
      flexible,
      is_alerts,
      is_relative_alerts,
      alerts,
      db_creation_time,
      prepared_costs_data,
      prepared_accum_costs_data,
      is_dynamo_active,
      total_cost,
      budget_name,
      total_forcasted,
      linked_accounts,
      services,
      tags,
      filters,
      previous_budgets,
      monthly_start_date,
    } = rawBudget;
    return this.createBudgetInstance(
      uuid,
      user_key,
      budget_amount,
      budget_amounts,
      new_budget_amount,
      budget_type,
      budget_amount_type,
      period,
      start_date,
      end_date,
      flexible,
      is_alerts,
      is_relative_alerts,
      alerts,
      db_creation_time,
      prepared_costs_data,
      prepared_accum_costs_data,
      is_dynamo_active,
      total_cost,
      budget_name,
      total_forcasted,
      linked_accounts,
      services,
      tags,
      filters ? filters.include : null,
      filters ? filters.exclude : null,
      previous_budgets && previous_budgets.length
        ? previous_budgets.map((budget) => this.prepareBudget(budget))
        : previous_budgets,
      monthly_start_date || start_date,
    );
  };

  prepareBudgets = (rawData) => {
    const budgets = rawData.map((rawBudget) => this.prepareBudget(rawBudget));
    this.budgets.push(...budgets);
  };

  fetchData = async () => {
    try {
      const rawData = await this.apiGateway.getUserBudget();
      runInAction(() => {
        this.isInitialFetchDone = true;
        this.budgets = [];
        if (rawData) {
          this.prepareBudgets(rawData);
        } else {
          this.budgets.push(
            new Budget(
              '1', // uuid
              'user_key', // user_key
              0, // budget_amount
              [], // budget_amounts
              0, // new_budget_amount
              BudgetTypes.RECURRING, // budget_type
              BudgetAmountTypes.FIXED, // budget_amount_type
              0, // period
              DateFilter.getFirstDayOfCurrMonthDate(), // start_date
              DateFilter.getFirstDayOfCurrMonthNextYearDate(), // end_date
              1, // flexible
              true, // is_alerts
              true, // is_relative_alerts
              [
                {
                  alertPercent: '',
                  alertEmail: '',
                  whenToAlert: [BudgetAlertsTypes.FORECASTED],
                  alertGranularity: [BudgetAlertGranularityTypes.DAILY],
                },
              ], // budget_alerts
              DateFilter.getFirstDayOfCurrMonthDate(), // db_creation_time
              null, // prepared_costs_data
              null, // prepared_accum_costs_data
              0, // is_active
              null, // total_cost
              '', // budget_name
              null, // total_forcasted
              [], // linked_accounts
              [], // services
              [], // tags
              [], // include_filters
              [], // exclude_filters
              [], // previous_budgets
            ),
          );
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isInitialFetchDone = false;
        this.state = 'error';
      });
    }
    return this.budgets;
  };

  get monthlyBudget() {
    return this.budgets;
  }

  getBudgetById = async (id) => {
    const data = await this.apiGateway.getBudgetById(id);
    const preparedData = this.prepareBudget(data);
    return [preparedData];
  };

  // mobx action

  updateBudget = async (budget, selectedOptionsMap, filterStatusTypeMap) => {
    this.isLoading = true;
    const budgetIdx = this.budgets.findIndex((x) => x.budgetId === this.tempBudgets[TempBudgetTypes.UPDATED].budgetId);
    const currBudget = this.budgets[budgetIdx];
    const { includeFilterParams, excludeFilterParams } = prepareBodyFiltersIncludeOrExclude(
      selectedOptionsMap,
      filterStatusTypeMap,
    );
    // eslint-disable-next-line max-len
    const {
      budgetName,
      period,
      startDate,
      endDate,
      isFlexible,
      newBudgetAmount,
      budgetAmounts,
      budgetType,
      budgetAmountType,
      isActive,
      linkedAccounts,
      services,
      tags,
      budgetId,
      isRelativeAlerts,
      budgetAlerts,
    } = this.tempBudgets[TempBudgetTypes.UPDATED];
    currBudget.budgetName = budgetName;
    currBudget.period = period;
    currBudget.startDate = startDate;
    currBudget.endDate = endDate;
    currBudget.isFlexible = isFlexible;
    currBudget.budgetAlerts = budgetAlerts;
    currBudget.budgetAmount = newBudgetAmount;
    currBudget.budgetType = budgetType;
    currBudget.budgetAmountType = budgetAmountType;
    currBudget.isActive = isActive;
    currBudget.linkedAccounts = linkedAccounts;
    currBudget.services = services;
    currBudget.tags = tags;
    currBudget.includeFilters = includeFilterParams;
    currBudget.excludeFilters = excludeFilterParams;
    try {
      currBudget.budgetAlerts.forEach((budgetAlert) => {
        const { alertEmail } = budgetAlert;
        if (typeof budgetAlert.alertEmail === 'object') {
          const alertEmailAsString = alertEmail.join();
          budgetAlert.alertEmail = alertEmailAsString;
        }
      });
      const updatedBudget = await this.apiGateway.updateUserBudget(
        budgetId,
        budget,
        budgetAmounts,
        currBudget.period,
        moment(this.budgets[budgetIdx].startDate).format('YYYY-MM-DD'),
        moment(this.budgets[budgetIdx].endDate).format('YYYY-MM-DD'),
        currBudget.isFlexible,
        currBudget.budgetAlerts,
        isRelativeAlerts,
        currBudget.budgetName,
        currBudget.includeFilters,
        currBudget.excludeFilters,
        budgetType,
        budgetAmountType,
        currBudget.userKey,
      );
      runInAction(() => {
        if (updatedBudget) {
          const modifiedBudget = this.prepareBudget(updatedBudget);
          this.budgets = this.budgets.map((budg, index) => (index === budgetIdx ? modifiedBudget : budg));
          this.isLoading = false;
        } else {
          this.budgets.push(
            new Budget(
              'uuid', // uuid
              'user_key', // user_key
              3000, // budget_amount
              [], // budget_amounts
              0, // new_budget_amount
              BudgetTypes.RECURRING, // budget_type
              BudgetAmountTypes.FIXED, // budget_amount_type
              0, // period
              DateFilter.getFirstDayOfCurrMonthDate(), // start_date
              DateFilter.getFirstDayOfCurrMonthNextYearDate(), // end_date
              1, // flexible
              true, // is_alerts
              true, // is_relative_alerts
              [
                {
                  alertPercent: '',
                  alertEmail: '',
                  whenToAlert: [BudgetAlertsTypes.FORECASTED],
                  alertGranularity: [BudgetAlertGranularityTypes.DAILY],
                },
              ], // budget_alerts
              DateFilter.getFirstDayOfCurrMonthDate(), // db_creation_time
              null, // prepared_costs_data
              null, // prepared_accum_costs_data
              0, // is_active
              null, // total_cost
              '', // budget_name
              null, // total_forcasted
              [], // linked_accounts
              [], // services
              [], // tags
              [], // include_filters
              [], // exclude_filters
              [], // previous_budgets
            ),
          );
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        this.state = 'error';
      });
    }
  };

  removeBudget = (budgetId) => {
    this.apiGateway.removeBudget(budgetId);
  };

  // eslint-disable-next-line no-unused-vars
  createBudget = async (selectedOptionsMap, filterStatusTypeMap) => {
    this.createBudgetLoading = true;
    try {
      const { includeFilterParams, excludeFilterParams } = prepareBodyFiltersIncludeOrExclude(
        selectedOptionsMap,
        filterStatusTypeMap,
      );
      let newBudget = false;
      setTimeout(() => {
        this.createBudgetLoading = false;
        if (!newBudget) {
          this.tempBudgets[TempBudgetTypes.CREATE].budgetName = '';
          this.isCreateBudgetTimeoutModal = true;
        }
      }, 3000);
      newBudget = await this.apiGateway.createUserBudget(
        Number(this.tempBudgets[TempBudgetTypes.CREATE].newBudgetAmount),
        this.tempBudgets[TempBudgetTypes.CREATE].budgetAmounts,
        this.tempBudgets[TempBudgetTypes.CREATE].period,
        this.tempBudgets[TempBudgetTypes.CREATE].startDate,
        this.tempBudgets[TempBudgetTypes.CREATE].endDate,
        this.tempBudgets[TempBudgetTypes.CREATE].isFlexible,
        this.tempBudgets[TempBudgetTypes.CREATE].budgetName,
        this.tempBudgets[TempBudgetTypes.CREATE].budgetAlerts,
        this.tempBudgets[TempBudgetTypes.CREATE].isRelativeAlerts,
        includeFilterParams,
        excludeFilterParams,
        this.tempBudgets[TempBudgetTypes.CREATE].budgetType,
        this.tempBudgets[TempBudgetTypes.CREATE].budgetAmountType,
      );
      // eslint-disable-next-line no-alert
      runInAction(() => {
        if (newBudget) {
          newBudget.is_alerts = this.tempBudgets[TempBudgetTypes.CREATE].isAlerts;
          const modifiedBudget = this.prepareBudget(newBudget);
          this.budgets.push(modifiedBudget);
          this.resetTempBudgetCreat();
          this.createBudgetLoading = false;
        } else {
          this.budgets.push(
            new Budget(
              'uuid', // uuid
              'user_key', // user_key
              3000, // budget_amount
              [], // budget_amounts
              0, // new_budget_amount
              0, // period
              DateFilter.getFirstDayOfCurrMonthDate(), // start_date
              DateFilter.getFirstDayOfCurrMonthNextYearDate(), // end_date
              1, // flexible
              true, // is_alerts
              true, // is_relative_alerts
              [
                {
                  alertPercent: '',
                  alertEmail: '',
                  whenToAlert: [BudgetAlertsTypes.FORECASTED],
                  alertGranularity: [BudgetAlertGranularityTypes.DAILY],
                },
              ], // budget_alerts
              DateFilter.getFirstDayOfCurrMonthDate(), // db_creation_time
              null, // prepared_costs_data
              null, // prepared_accum_costs_data
              0, // is_active
              null, // total_cost
              '', // budget_name
              null, // total_forcasted
              [], // linked_accounts
              [], // services
              [], // tags
              [], // include_filters
              [], // exclude_filters
              [], // previous_budgets
            ),
          );
        }
      });
    } catch (error) {
      toast.error(ErrorNotificationsLabels.GENERAL);
      runInAction(() => {
        this.state = 'error';
      });
    }
  };

  resetTempBudgetCreat() {
    this.tempBudgets[TempBudgetTypes.CREATE] = new Budget(
      'uuid', // uuid
      'user_key', // user_key
      3000, // budget_amount
      [], // budget_amounts
      0, // new_budget_amount
      BudgetTypes.RECURRING, // budget_type
      BudgetAmountTypes.FIXED, // budget_amount_type
      0, // period
      DateFilter.getFirstDayOfCurrMonthDate(), // start_date
      DateFilter.getFirstDayOfCurrMonthNextYearDate(), // end_date
      1, // flexible
      true, // is_alerts
      true, // is_relative_alerts
      {
        1: {
          alertPercent: '',
          alertEmail: '',
          whenToAlert: [BudgetAlertsTypes.FORECASTED],
          alertGranularity: [BudgetAlertGranularityTypes.DAILY],
        },
      }, // budget_alerts
      DateFilter.getFirstDayOfCurrMonthDate(), // db_creation_time
      null, // prepared_costs_data
      null, // prepared_accum_costs_data
      0, // is_active
      null, // total_cost
      '', // budget_name
      null, // total_forcasted
      [], // linked_accounts
      [], // services
      [], // tags
      [], // include_filters
      [], // exclude_filters
      [], // previous_budgets
    );
  }

  // mobx action
  deleteBudget(id) {
    this.apiGateway.deleteUserBudget(id);
    const index = this.budgets.map((budgetObg) => budgetObg.budgetId).indexOf(id);
    this.budgets.splice(index, 1);
  }

  invalidateData = () => {
    this.budgets = [];
    this.isLoading = false;
    this.isInitialFetchDone = false;
    this.createBudgetLoading = false;
  };
}
