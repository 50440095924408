import { ReportPeriodTime } from 'usage/constants/costAndUsageConstants';

export const CUSTOMER_REPORT_MODAL_TYPE = 'customer';

export const CUSTOMER_REPORT_TYPES = {
  PDF: 'pdf',
  CSV: 'csv',
  BOTH: 'both',
};

export const ALL_CUSTOMERS = { value: 'all', label: 'All customers' };

export const PERIOD_OPTIONS = [
  { value: ReportPeriodTime.CURRENT_MONTH, label: 'Current month' },
  { value: ReportPeriodTime.PREVIOUS_MONTH, label: 'Previous month' },
];
export const mapCustomerReportTypeToDisplay = new Map([
  [CUSTOMER_REPORT_TYPES.PDF, 'PDF'],
  [CUSTOMER_REPORT_TYPES.CSV, 'CSV'],
  [CUSTOMER_REPORT_TYPES.BOTH, 'PDF & CSV'],
]);

export const CUSTOMER_REPORT_TYPES_OPTIONS = [
  { value: CUSTOMER_REPORT_TYPES.PDF, label: 'PDF' },
  { value: CUSTOMER_REPORT_TYPES.CSV, label: 'CSV' },
  { value: CUSTOMER_REPORT_TYPES.BOTH, label: 'PDF & CSV' },
];
export const CUSTOMER_REPORTS_TABLE_COLUMNS = [
  { name: 'name', title: 'Report Name', getCellValue: (row) => row.reportName },
  {
    name: 'recipients',
    title: 'Recipients',
    getCellValue: (row) => row.email,
  },
  {
    name: 'frequency',
    title: 'Frequency',
    getCellValue: (row) => row.frequency,
  },
  {
    name: 'period',
    title: 'Period',
    getCellValue: (row) => row.period,
  },
  {
    name: 'reportType',
    title: 'Type',
    getCellValue: (row) => row.reportType,
  },
  {
    name: 'creationDate',
    title: 'Date Created',
    getCellValue: (row) => row.creationDate,
  },
  {
    name: 'userAction',
    title: 'Actions',
    getCellValue: (row) => row.userAction,
  },
];
export const CUSTOMER_REPORTS_COLUMN_WIDTHS = [
  { columnName: 'name', width: 200 },
  { columnName: 'recipients', width: 150 },
  { columnName: 'frequency', width: 150 },
  { columnName: 'period', width: 150 },
  { columnName: 'reportType', width: 150 },
  { columnName: 'creationDate', width: 150 },
  { columnName: 'userAction', width: 230 },
];

export const CUSTOMER_REPORTS_TABLE_COLUMN_EXTENSIONS = [
  { columnName: 'userAction', align: 'center' },
  // { columnName: 'snapshot', align: 'center' },
];
export const CUSTOMER_REPORTS_DEFAULT_SORTING = [{ columnName: 'creationDate', direction: 'desc' }];
