/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style,arrow-parens,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Collapse, Button, Form } from 'reactstrap';
import { RowDetailState, DataTypeProvider } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@mui/styles';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import TableWrapper from 'shared/components/tables/TableWrapper';
import ActionButton from './ActionButton';

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      //   backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    '& tbody thead tr th': {
      fontFamily: 'rubik',
      color: '#b9b9ba',
      fontSize: '16px',
      'font-size': '16px',
    },
    fontSize: '25.5vw',
    fontFamily: 'rubik',
    color: '#4F4F4F',
  },
});

const COLUMN_EXTENSIONS = [
  { columnName: 'userActions', align: 'center' },
  { columnName: 'isVerified', align: 'center' },
];

class AppRegistrationTable extends React.PureComponent {
  static propTypes = {
    regAppsData: PropTypes.object.isRequired,
    tableColumns: PropTypes.object.isRequired,
    editApp: PropTypes.func.isRequired,
    deleteApp: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      updateSelectedAccs: null,
      currentDivision: null,
      resultStrings: [],
      appRegForDelete: null,
    };
  }

  getColor = (isVerified) => {
    if (!isVerified) {
      return '#d95350';
    }
    return '#449d44';
  };

  verificationStatus = (data) => {
    withStyles(data);
    return (
      <div
        style={{
          'background-color': this.getColor(data.row.isVerified),
          height: '15px',
          width: '15px',
          'border-radius': '50%',
          margin: '0 auto',
        }}
      />
    );
  };

  filterSelectOptions = (row, options) => {
    const filteredOptions = options;
    row.linkedAccounts.map((linkedAcc) => {
      const idx = filteredOptions.findIndex((element) => element.value === linkedAcc.linkedAccountId);
      if (idx > -1) filteredOptions.splice(idx, 1);
      return filteredOptions;
    });
    return filteredOptions;
  };

  handleDeleteAppReg = async (action) => {
    if (action === 'delete') {
      await this.props.deleteApp(this.state.appRegForDelete);
    }
    this.setState({ appRegForDelete: null });
  };
  handleDeleteClick = (appReg) => this.setState({ appRegForDelete: appReg });

  render() {
    const { regAppsData, tableColumns, editApp, deleteApp } = this.props;
    const userActionsFormatter = (data) => {
      withStyles(data);
      return (
        <i>
          <Row>
            <Col>
              <ActionButton regApp={data.row} editApp={editApp} deleteApp={this.handleDeleteClick} />
            </Col>
          </Row>
        </i>
      );
    };

    return (
      <div className="card">
        <Grid rows={regAppsData} columns={tableColumns}>
          <DataTypeProvider for={['isVerified']} formatterComponent={this.verificationStatus} />
          <DataTypeProvider for={['userActions']} formatterComponent={userActionsFormatter} />
          <TableWrapper columnExtensions={COLUMN_EXTENSIONS} styles={styles} />
          <TableHeaderRow />
        </Grid>
        <DeleteWarningModal
          deletedItemName={this.state.appRegForDelete && this.state.appRegForDelete.appName}
          isOpen={this.state.appRegForDelete}
          handleDelete={this.handleDeleteAppReg}
        />
      </div>
    );
  }
}

export default AppRegistrationTable;
