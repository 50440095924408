import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import { withRouter } from 'react-router';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { palette } from 'shared/constants/colorsConstants';
import { ACTION_STATUS } from 'recommendationsNew/consts';
import classes from './recommendationsList.module.scss';
import { useRootStore } from '../../../app/contexts/RootStoreContext';
import DoneAnimation from '../../img/done-animation.gif';

const UserStatusIcon = ({ row, click, showDoneId }) => {
  const { usersStore } = useRootStore();
  if (showDoneId && row.recId === showDoneId) {
    return (
      <div className={classes.animationWrapper}>
        <img src={DoneAnimation} alt="done" />
      </div>
    );
  }
  if (!row.userStatus) {
    return (
      <div onClick={click} className={classes.userStatusWrapper}>
        <Tooltip title="Add User Status" arrow placement="bottom">
          <span
            onClick={(e) => {
              if (usersStore.currentUserReadOnly) {
                return;
              }
              click(e);
            }}
          >
            <GenerateIcon
              className={`${classes.clipboardIcon} ${usersStore.currentUserReadOnly ? classes.disableIcon : ''}`}
              iconName={ICONS.clipboard.name}
              color={palette.gray[400]}
              size="lg"
              disabled={usersStore.currentUserReadOnly}
            />
          </span>
        </Tooltip>
      </div>
    );
  }
  return (
    <div onClick={click} className={classes.userStatusWrapper}>
      {row.userStatus.status === ACTION_STATUS.done ? (
        <Tooltip title="Done" arrow placement="top">
          <span>
            <GenerateIcon iconName={ICONS.clipboardCheck.name} color={palette.eucaliptus[500]} size="lg" />
          </span>
        </Tooltip>
      ) : (
        <Tooltip title="Excluded" arrow placement="top">
          <span className={classes.excludeWrapper}>
            <GenerateIcon iconName={ICONS.excluded.name} color={palette.red[500]} size="lg" />
          </span>
        </Tooltip>
      )}
    </div>
  );
};
const RecommendationUserStatus = ({ setDone, setExcluded, isDone, isExcluded, row, showDoneId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const toggleMenu = (e, closeMenu = false) => {
    e?.stopPropagation();
    setAnchorEl(closeMenu ? null : e?.currentTarget);
  };
  return (
    <div>
      <UserStatusIcon click={(e) => toggleMenu(e)} row={row} showDoneId={showDoneId} />
      <Menu
        classes={{ list: classes.menu, paper: classes.menuPaper }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => toggleMenu(e, true)}
      >
        <MenuItem classes={{ root: classes.menuItem }} onClick={setDone}>
          <div className={isDone && classes.doneMenu}>
            <GenerateIcon iconName={ICONS.clipboardCheck.name} className={classes.iconPadding} />
            <span className={classes.menuItem}>{`${!isDone ? 'Mark' : 'Unmark'} as Done`}</span>
          </div>
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItem }} onClick={setExcluded}>
          <div className={isExcluded && classes.excludedMenu}>
            <GenerateIcon iconName={ICONS.excluded.name} />
            <span className={classes.menuItem}>{`${!isExcluded ? 'Mark' : 'Unmark'} as Excluded`}</span>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default withRouter(RecommendationUserStatus);
