import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Col, Input } from 'reactstrap';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { green, red } from '@mui/material/colors';
import FieldFilter from 'shared/components/FieldFilter';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { propComparator } from 'shared/utils/sortUtil';
import { AwsCommonFields, serviceNameToDisplayNameWithShortName } from 'shared/constants/awsConstants';
import getDisplayNameByDivisionName from 'divisions/divisionsHelpers';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import MultiTypeFieldFilterSelector from 'shared/components/MultiTypeFieldFilterSelector';
import DiscountTypeRadioButtons from 'shared/components/resellerPrivatePricing/DiscountTypeRadioButtons';
import ControlledToggleButton from 'shared/components/buttons/ControlledToggleButton';
import { PRIVATE_PRICING_DISCOUNT_TYPES } from 'divisions/constants/customersConstants';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { percentageMargins, supportedCalcCostServices } from './resellerPrivatePricingConstants';
import { isOperatorInput, OPERATORS } from '../../constants/appConstants';
import { AzureCommonFields } from '../../constants/azureConstants';

const buttonGroupStyle = {
  margin: '5px 0px',
  display: 'flex',
  alignItems: 'center',
};
const labelStyle = {
  margin: '0 0 0 5px',
  fontSize: '13px',
};

const SelectInputsBar = (props) => {
  const defaultColProps = {
    xl: 12,
    lg: 12,
    md: 12,
    sm: 12,
    xs: 12,
    style: { margin: '10px 0px' },
  };
  const {
    payerAccListOfValues,
    scopeListOfValues,
    inputSelectedOption,
    handleOneChoiceFilterChange,
    handleNumericFilterChange,
    handleMultiChoiceFilterChange,
    handleSelectCloudFrontRegions,
    handleDisableMinimumFee,
    handleToggleScopeType,
    handleServiceFilterChange,
    handleDateChange,
    handleDiscountTypeChange,
    handleTextInputChange,
    handleChangeIncludeExcludeService,
    handleChangeIncludeExcludeMarketPlace,
    selectedInputsErrorMessage,
    isShowScope,
    modalState,
    cloudType,
    linkedAccountOptions,
  } = props;
  const isShowCustomerField =
    isShowScope &&
    !inputSelectedOption.isLinkedAccountsChecked &&
    (!payerAccListOfValues ||
      !payerAccListOfValues.length ||
      (payerAccListOfValues && payerAccListOfValues.length && inputSelectedOption.payerAccount));
  const isShowLinkedAccountField =
    isShowScope &&
    inputSelectedOption.isLinkedAccountsChecked &&
    (!payerAccListOfValues ||
      !payerAccListOfValues.length ||
      (payerAccListOfValues && payerAccListOfValues.length && inputSelectedOption.payerAccount));
  const { currencySymbol } = useUserSettingsContext();
  const removeServiceNameFromList = (servName, list) => {
    const index = list.findIndex((name) => name === servName);
    if (index > -1) {
      list.splice(index, 1);
    }
  };
  const checkForAdditionalNames = () => {
    const { marginType, customService } = inputSelectedOption;
    const addServiceValues =
      cloudType === CLOUD_TYPE_IDS.AWS && marginType !== 'remove-support'
        ? ['AWS Support [Business]', 'AWS Support [Developer]', 'AWS Premium Support']
        : [];
    if (['rate', 'percentage'].includes(marginType)) {
      addServiceValues.push('Data Transfer - Amazon CloudFront');
    } else {
      removeServiceNameFromList('Data Transfer - Amazon CloudFront', addServiceValues);
    }
    if (marginType === 'fix') {
      addServiceValues.push('Custom Service');
    } else {
      // if i'm not on margin type fix I need to remove custom service
      removeServiceNameFromList('Custom Service', addServiceValues);
    }
    if (customService && modalState === 'edit') {
      addServiceValues.push(customService.toUpperCase());
    } else if (customService && modalState !== 'edit') {
      removeServiceNameFromList(customService.toUpperCase(), addServiceValues);
    }
    return addServiceValues;
  };
  const createScopeOptions = (scopeValue) => {
    if (scopeValue) {
      return [
        {
          value: scopeValue,
          label: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', scopeValue),
        },
      ];
    }
    const values = scopeListOfValues.map((item) => ({
      value: item,
      label: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', item),
    }));
    if (values.length) {
      values.sort(propComparator('label'));
    }
    if (!payerAccListOfValues || !payerAccListOfValues.length) {
      values.unshift({ value: 'Internal_Pileus:Account_id:All_Customers', label: 'All Customers' });
    }
    return values;
  };
  const createLinkedAccountOptions = (linkedAccounts) => {
    if (!linkedAccounts) {
      return [];
    }
    const values = linkedAccounts.map((item) => ({ value: item.linkedAccountId, label: item.displayLabel }));
    if (values.length) {
      values.sort(propComparator('label'));
    }
    if (!payerAccListOfValues || !payerAccListOfValues.length) {
      values.unshift({ value: 'Internal_Pileus:Account_id:All_Customers', label: 'All Linked Accounts' });
    }
    return values;
  };
  const createClfRegionsOptions = () => {
    const regions = [
      'Asia',
      'Australia',
      'Canada',
      'Europe',
      'India',
      'Japan',
      'Middle East',
      'South Africa',
      'South America',
      'United States',
    ];
    const options = regions.map((region) => ({ value: region, label: region }));
    options.unshift({ value: [], label: 'All Regions' });
    return options;
  };
  const payerAccOptionsAndMap = (() => {
    const mapAccIdToPayerAccLabel = new Map();
    const options = payerAccListOfValues
      ? payerAccListOfValues.map((payerAccount) => {
          const value = payerAccount.includes('(') ? payerAccount.split('(')[1].replace(')', '') : payerAccount;
          mapAccIdToPayerAccLabel.set(value, payerAccount);
          return { label: payerAccount, value };
        })
      : [];
    return { options, mapAccIdToPayerAccLabel };
  })();
  const frequencyOptions = [
    { value: 'one time', label: 'One Time' },
    { value: 'recurring', label: 'Recurring' },
  ];

  const freQuencyLabel = {
    'one time': 'One Time',
    recurring: 'Recurring',
  };

  const checkIfSupportedCalcCostServices = (selectedServices) => {
    if (!selectedServices) {
      return false;
    }
    const arrSelectedServices = Array.isArray(selectedServices) ? [...selectedServices] : [selectedServices];
    return supportedCalcCostServices.some((suppService) =>
      arrSelectedServices.map(({ value }) => value).includes(suppService),
    );
  };

  const renderErrorMessage = (field) =>
    selectedInputsErrorMessage[field] ? <p style={{ color: 'red' }}>{selectedInputsErrorMessage[field]}</p> : null;
  const renderIncludeExcludeServicesButton = () => {
    if (inputSelectedOption.marginType !== 'custom-service-calc-cost') {
      return null;
    }
    const isDisabled =
      !inputSelectedOption.excludeService &&
      inputSelectedOption.services &&
      inputSelectedOption.services.some((service) => service.value === 'all');
    return (
      <Tooltip
        arrow
        title={
          isDisabled
            ? 'All services can not be excluded'
            : `Press here to ${inputSelectedOption.excludeService ? 'include' : 'exclude'} the selected values`
        }
        placement="top"
      >
        <FormControlLabel
          className="mb-0 mt-2 pt-1"
          disabled={isDisabled}
          control={
            <Checkbox
              icon={<AddBoxIcon fontSize="small" style={{ color: green[500] }} />}
              checkedIcon={<IndeterminateCheckBoxIcon fontSize="small" style={{ color: red[500] }} />}
              checked={inputSelectedOption.excludeService}
              onChange={() => handleChangeIncludeExcludeService()}
            />
          }
        />
      </Tooltip>
    );
  };
  const renderCustomServiceName = () => (
    <Col {...defaultColProps}>
      <div className="filter-bar-inner-col-wrapper full-width">
        <h5 className="filter-bar-inner-col-title">Custom Service Name</h5>
        <Input
          style={{ color: 'hsl(0,0%,20%)', fontSize: '13px' }}
          type="text"
          name="customService"
          value={inputSelectedOption.customService}
          onChange={handleTextInputChange}
          placeholder="Up to 30 characters"
        />
        {renderErrorMessage('customService')}
      </div>
    </Col>
  );

  const conditionalCustomServiceNameRender = () => {
    if (
      inputSelectedOption.marginType === 'fix' &&
      inputSelectedOption.services[0] &&
      inputSelectedOption.services[0].value === 'Custom Service'
    ) {
      return renderCustomServiceName();
    }
    if (inputSelectedOption.marginType === 'custom-service-calc-cost') {
      return renderCustomServiceName();
    }
    return null;
  };
  const isFixedCostMargin = inputSelectedOption.marginType === 'fix';
  const isCalcCost = inputSelectedOption.marginType === 'calc-cost';
  const isRemoveSupportMargin = inputSelectedOption.marginType === 'remove-support';
  const isExcludeAwsMarketplaceNeeded =
    cloudType === CLOUD_TYPE_IDS.AWS && !isFixedCostMargin && !isRemoveSupportMargin;
  const isPlan = ['calc-cost', 'remove-support'].includes(inputSelectedOption.marginType);
  const planOrService = isPlan ? 'Plan' : 'Service';
  const marginTypesForUsageTypeOption = [
    PRIVATE_PRICING_DISCOUNT_TYPES.PERCENTAGE,
    PRIVATE_PRICING_DISCOUNT_TYPES.FIXED_COST,
    PRIVATE_PRICING_DISCOUNT_TYPES.FIXED_RATE,
    PRIVATE_PRICING_DISCOUNT_TYPES.CUST_SER_CALC_COST,
  ];
  const percentageMode = percentageMargins.includes(inputSelectedOption.marginType);
  return (
    <div>
      <Col {...defaultColProps}>
        <div className="filter-bar-inner-col-wrapper full-width">
          <h5 className="filter-bar-inner-col-title">Name</h5>
          <Input
            style={{ color: 'hsl(0,0%,20%)', fontSize: '13px' }}
            type="text"
            name="name"
            value={inputSelectedOption.name}
            onChange={handleTextInputChange}
            placeholder="Up to 100 characters"
          />
          {renderErrorMessage('name')}
        </div>
      </Col>
      <Col {...defaultColProps}>
        <div className="filter-bar-inner-col-wrapper full-width">
          <h5 className="filter-bar-inner-col-title">Description</h5>
          <Input
            style={{ color: 'hsl(0,0%,20%)', fontSize: '13px' }}
            type="text"
            name="description"
            value={inputSelectedOption.description}
            onChange={handleTextInputChange}
            placeholder="Up to 100 characters"
          />
          {renderErrorMessage('description')}
        </div>
      </Col>
      {payerAccListOfValues && payerAccListOfValues.length ? (
        <Col {...defaultColProps}>
          <div className="filter-bar-inner-col-wrapper full-width">
            <h5 className="filter-bar-inner-col-title">Payer Account</h5>
            <OneChoiceFieldFilter
              type="payerAccount"
              value={[
                {
                  value: inputSelectedOption.payerAccount,
                  label: payerAccOptionsAndMap.mapAccIdToPayerAccLabel.get(inputSelectedOption.payerAccount),
                },
              ]}
              options={payerAccOptionsAndMap.options}
              handleChange={handleOneChoiceFilterChange}
              isDisabled={modalState === 'edit'}
            />
            {renderErrorMessage('payerAccount')}
          </div>
        </Col>
      ) : null}
      {(!payerAccListOfValues ||
        !payerAccListOfValues.length ||
        (payerAccListOfValues && payerAccListOfValues.length && inputSelectedOption.payerAccount)) && (
        <Col {...defaultColProps}>
          <div className="filter-bar-inner-col-wrapper full-width">
            <hr />
            <div style={{ margin: '0', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <p style={{ marginRight: '5px' }}>Customers</p>
              <ControlledToggleButton
                disabled={modalState === 'edit'}
                checked={inputSelectedOption.isLinkedAccountsChecked}
                selectedItem
                isFixColor={modalState !== 'edit'}
                handler={handleToggleScopeType}
              />
              <p
                style={{
                  marginLeft: '5px',
                  marginTop: '0px',
                }}
              >
                {LabelCoordinator.getFieldLabel('linkedaccid', 'plural')}
              </p>
            </div>
          </div>
        </Col>
      )}
      {isShowCustomerField && (
        <Col {...defaultColProps}>
          <div className="filter-bar-inner-col-wrapper full-width">
            <h5 className="filter-bar-inner-col-title">Customers</h5>
            <FieldFilter
              type="divNames"
              value={
                Array.isArray(inputSelectedOption.divNames)
                  ? inputSelectedOption.divNames.map(({ value, label }) => ({
                      value,
                      label: getDisplayNameByDivisionName(label) || label,
                    }))
                  : inputSelectedOption.divNames
              }
              options={createScopeOptions()}
              handleChange={handleMultiChoiceFilterChange}
              isDisabled={modalState === 'edit'}
            />
            {renderErrorMessage('divNames')}
          </div>
        </Col>
      )}
      {isShowLinkedAccountField && (
        <Col {...defaultColProps}>
          <div className="filter-bar-inner-col-wrapper full-width">
            <h5 className="filter-bar-inner-col-title">{LabelCoordinator.getFieldLabel('linkedaccid')}</h5>
            <FieldFilter
              type="linkedAccounts"
              value={inputSelectedOption.linkedAccounts}
              options={createLinkedAccountOptions(linkedAccountOptions)}
              handleChange={handleMultiChoiceFilterChange}
              isDisabled={modalState === 'edit'}
            />
            {renderErrorMessage('linkedAccounts')}
          </div>
        </Col>
      )}
      <Col {...defaultColProps}>
        <div className="filter-bar-inner-col-wrapper full-width">
          <h5 className="filter-bar-inner-col-title">Frequency</h5>
          <OneChoiceFieldFilter
            type="frequency"
            value={[{ value: inputSelectedOption.frequency, label: freQuencyLabel[inputSelectedOption.frequency] }]}
            options={frequencyOptions}
            handleChange={handleOneChoiceFilterChange}
          />
          {renderErrorMessage('frequency')}
        </div>
      </Col>
      <Col {...defaultColProps} style={{ display: 'flex' }}>
        <div className="filter-bar-inner-col-wrapper" style={{ marginInlineEnd: 10 }}>
          <h5 className="filter-bar-inner-col-title">
            {inputSelectedOption.frequency === 'one time' ? 'Effective month' : 'Start Month'}
          </h5>
          <input
            type="month"
            className="borderFormField"
            id={inputSelectedOption.frequency === 'one time' ? 'effectiveMonth' : 'startDate'}
            name={inputSelectedOption.frequency === 'one time' ? 'effectiveMonth' : 'startDate'}
            value={
              inputSelectedOption.frequency === 'one time'
                ? inputSelectedOption.effectiveMonth
                : inputSelectedOption.startDate
            }
            onChange={handleDateChange}
            max={inputSelectedOption.endDate}
          />
          {renderErrorMessage('date')}
        </div>
        {inputSelectedOption.frequency !== 'one time' && (
          <div className="filter-bar-inner-col-wrapper">
            <h5 className="filter-bar-inner-col-title">End Month</h5>
            <input
              className="borderFormField"
              type="month"
              id="endDate"
              name="endDate"
              value={inputSelectedOption.endDate}
              onChange={handleDateChange}
              min={inputSelectedOption.startDate}
            />
          </div>
        )}
      </Col>
      <Col {...defaultColProps}>
        <div className="filter-bar-inner-col-wrapper full-width">
          <h5 className="filter-bar-inner-col-title">Margin Type</h5>
          <DiscountTypeRadioButtons
            isFixedRateSupportedService
            isCalcCostSupportedService={checkIfSupportedCalcCostServices(inputSelectedOption.services)}
            handleDiscountTypeChange={handleDiscountTypeChange}
            inputSelectedOption={inputSelectedOption}
            cloudType={cloudType}
          />
        </div>
      </Col>
      <Col>
        {inputSelectedOption.marginType !== 'calc-cost' && inputSelectedOption.marginType !== 'remove-support' && (
          <div className="filter-bar-inner-col-wrapper">
            <h5 className="filter-bar-inner-col-title">Amount</h5>
            <h6 className="filter-bar-inner-col-title">(negative values for discount)</h6>
            <input
              type="number"
              min={0}
              max={percentageMode ? 100 : undefined}
              step={1}
              id="margin"
              value={inputSelectedOption.margin}
              onChange={handleNumericFilterChange}
              className="form-control"
              placeholder={percentageMode ? '%' : currencySymbol}
              style={{ fontSize: '12px' }}
            />
            {renderErrorMessage('margin')}
            {renderErrorMessage('marginType')}
          </div>
        )}
      </Col>
      {conditionalCustomServiceNameRender()}
      <Col
        {...defaultColProps}
        style={
          inputSelectedOption.marginType === 'custom-service-calc-cost'
            ? {
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '30px',
              }
            : {}
        }
      >
        {renderIncludeExcludeServicesButton()}
        <div className="filter-bar-inner-col-wrapper full-width">
          <h5 className="filter-bar-inner-col-title">{planOrService}</h5>
          <MultiTypeFieldFilterSelector
            selectedValues={inputSelectedOption.services}
            handler={handleServiceFilterChange}
            type={AwsCommonFields.SERVICE}
            name="services"
            placeHolder={`Select ${planOrService}`}
            singleSelect={isFixedCostMargin || isCalcCost}
            addAllOption={isFixedCostMargin ? null : `All ${planOrService}s`}
            addValues={checkForAdditionalNames()}
            fixedValues={
              isPlan
                ? [
                    'AWS Support [Business]',
                    'AWS Support [Developer]',
                    'AWS Support [Enterprise]',
                    'AWS Premium Support',
                  ]
                : null
            }
            labelFunc={(value) => serviceNameToDisplayNameWithShortName.get(value) || value}
          />
          {renderErrorMessage('services')}
        </div>
      </Col>
      {marginTypesForUsageTypeOption.includes(inputSelectedOption.marginType) && cloudType === CLOUD_TYPE_IDS.AWS ? (
        <Col>
          <div className="filter-bar-inner-col-wrapper full-width">
            <h5 className="filter-bar-inner-col-title">Usage Type</h5>
            <div className="d-flex">
              <div style={{ width: 200 }}>
                <MultiTypeFieldFilterSelector
                  type={null}
                  selectedValues={{
                    value: inputSelectedOption.usageTypeOperator,
                    label: OPERATORS[inputSelectedOption.usageTypeOperator],
                  }}
                  placeHolder="Operator"
                  singleSelect
                  name="usageTypeOperator"
                  handler={(_, value) => {
                    handleOneChoiceFilterChange('usageTypeOperator', value);
                  }}
                  fixedValues={Object.keys(OPERATORS)}
                  labelFunc={(val) => OPERATORS[val]}
                />
              </div>
              <div className="flex-grow-1 ms-3">
                {isOperatorInput(inputSelectedOption.usageTypeOperator) ? (
                  <Input
                    style={{ fontSize: '13px' }}
                    type="text"
                    placeholder="Enter match part"
                    name="usageTypes"
                    value={inputSelectedOption.usageTypes}
                    onChange={handleTextInputChange}
                  />
                ) : (
                  <MultiTypeFieldFilterSelector
                    selectedValues={inputSelectedOption.usageTypes}
                    handler={handleMultiChoiceFilterChange}
                    type={AwsCommonFields.USAGE_TYPE}
                    name="usageTypes"
                    placeHolder="Select Usage Type"
                    addAllOption="All Usage Types"
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
      ) : null}
      {isExcludeAwsMarketplaceNeeded ? (
        <Col {...defaultColProps} style={{ margin: '0px 0px' }}>
          <Col xl="auto" lg="auto" md="auto" sm="auto" xs="auto" style={buttonGroupStyle}>
            <input
              id="isExcludeAwsMarketplace"
              type="checkBox"
              name="isExcludeAwsMarketplace"
              checked={inputSelectedOption.isExcludeAwsMarketplace}
              onChange={() => handleChangeIncludeExcludeMarketPlace()}
            />
            <label style={labelStyle} htmlFor="isExcludeAwsMarketplace">
              Exclude Aws Marketplace
            </label>
          </Col>
        </Col>
      ) : null}
      {inputSelectedOption.marginType === 'calc-cost' && (
        <Col {...defaultColProps} style={{ margin: '0px 0px' }}>
          <Col xl="auto" lg="auto" md="auto" sm="auto" xs="auto" style={buttonGroupStyle}>
            <input
              id="disableMinimumFee"
              type="checkBox"
              name="disableMinimumFee"
              checked={inputSelectedOption.disableMinimumFee}
              onChange={(e) => handleDisableMinimumFee(e)}
            />
            <label style={labelStyle} htmlFor="disableMinimumFee">
              Disable minimum fee
            </label>
          </Col>
        </Col>
      )}
      {inputSelectedOption.services &&
        inputSelectedOption.services.map((srv) => srv.value).includes('Data Transfer - Amazon CloudFront') && (
          <Col {...defaultColProps}>
            <div className="filter-bar-inner-col-wrapper full-width">
              <h5 className="filter-bar-inner-col-title">Cloud Front Regions</h5>
              <FieldFilter
                type="cloudFrontRegions"
                value={inputSelectedOption.cloudFrontRegions}
                options={createClfRegionsOptions()}
                handleChange={handleSelectCloudFrontRegions}
              />
            </div>
            {renderErrorMessage('cloudFrontRegions')}
          </Col>
        )}
      {inputSelectedOption.services &&
        !isRemoveSupportMargin &&
        !inputSelectedOption.services.map((srv) => srv.value).includes('Data Transfer - Amazon CloudFront') && (
          <Col {...defaultColProps}>
            <div className="filter-bar-inner-col-wrapper full-width">
              <h5 className="filter-bar-inner-col-title">Regions</h5>
              <MultiTypeFieldFilterSelector
                selectedValues={inputSelectedOption.regions}
                handler={handleMultiChoiceFilterChange}
                type={AwsCommonFields.REGION}
                singleSelect={isFixedCostMargin}
                name="regions"
                placeHolder="Select region"
                addAllOption={isFixedCostMargin ? null : 'All'}
                labelFunc={(value) => serviceNameToDisplayNameWithShortName.get(value) || value}
              />
              {renderErrorMessage('regions')}
            </div>
          </Col>
        )}
      {cloudType === CLOUD_TYPE_IDS.AZURE && inputSelectedOption.marginType !== 'rate' && (
        <Col {...defaultColProps}>
          <div className="filter-bar-inner-col-wrapper full-width">
            <h5 className="filter-bar-inner-col-title">Line Types</h5>
            <MultiTypeFieldFilterSelector
              selectedValues={inputSelectedOption.lineTypes}
              handler={handleMultiChoiceFilterChange}
              type={AzureCommonFields.LINE_TYPE}
              placeHolder="Select line types"
              addAllOption="All"
            />
            {renderErrorMessage('lineTypes')}
          </div>
        </Col>
      )}
    </div>
  );
};

SelectInputsBar.propTypes = {
  inputListOfValues: PropTypes.object.isRequired,
  inputSelectedOption: PropTypes.object.isRequired,
  scopeListOfValues: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  selectedInputsErrorMessage: PropTypes.func.isRequired,
  handleOneChoiceFilterChange: PropTypes.func.isRequired,
  handleMultiChoiceFilterChange: PropTypes.func.isRequired,
  handleServiceFilterChange: PropTypes.func.isRequired,
  handleNumericFilterChange: PropTypes.func.isRequired,
  handleDisableMinimumFee: PropTypes.func.isRequired,
  handleSelectCloudFrontRegions: PropTypes.func.isRequired,
  handleChangeIncludeExcludeMarketPlace: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleTextInputChange: PropTypes.func.isRequired,
  handleDiscountTypeChange: PropTypes.func.isRequired,
  handleChangeIncludeExcludeService: PropTypes.func.isRequired,
  isShowScope: PropTypes.bool.isRequired,
  isCloudFront: PropTypes.bool.isRequired,
  modalState: PropTypes.string.isRequired,
  cloudType: PropTypes.number.isRequired,
  isLinkedAccountsChecked: PropTypes.bool.isRequired,
  handleToggleScopeType: PropTypes.func.isRequired,
  linkedAccountOptions: PropTypes.array.isRequired,
  payerAccListOfValues: PropTypes.object.isRequired,
};

const ObserverSelectInputsBar = observer(SelectInputsBar);
export default ObserverSelectInputsBar;
