/* eslint-disable class-methods-use-this */

import moment from 'moment';
import { action, makeObservable, observable } from 'mobx';
import { CLOUD_TYPE_IDS, UsersType } from 'users/constants/usersConstants';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import { isOperatorInput } from '../../shared/constants/appConstants';

class DivisionsStore {
  isLoading = false;
  customersModel = null;

  constructor(rootStore, awsCustomersModel, azureCustomersModel) {
    this.rootStore = rootStore;
    this.awsCustomersModel = awsCustomersModel;
    this.azureCustomersModel = azureCustomersModel;
    this.mapCloudTypeToFetchFuncArray = new Map([
      [CLOUD_TYPE_IDS.AWS, [this.rootStore.usersStore.fetchDivisionGroups]],
      [CLOUD_TYPE_IDS.AZURE, [this.rootStore.usersStore.fetchDivisionGroups]],
      [CLOUD_TYPE_IDS.GCP, [this.rootStore.usersStore.fetchDivisionGroups]],
    ]);
    makeObservable(this, {
      isLoading: observable,
      fetchData: action,
      awsCustomersModel: observable,
      azureCustomersModel: observable,
    });
  }

  // user general details form

  // Customers
  fetchData = async () => {
    this.isLoading = true;
    try {
      await this.rootStore.usersStore.fetchDivisionGroups();
    } catch (error) {
      this.isLoading = false;
      this.state = 'error';
    }
    this.isLoading = false;
  };

  fetchAwsCustomerData = async () => {
    const { currentDisplayedUserType } = this.rootStore.usersStore;
    if (currentDisplayedUserType === UsersType.RESELLER || currentDisplayedUserType === UsersType.BETA_USER) {
      this.awsCustomersModel.fetchData();
    }
  };
  // AWS Reseller **************************************

  getAwsCreditsData = (divId, divName) => {
    const rawData = this.awsCustomersModel.getAwsCreditsData(divId, divName);
    return rawData;
  };
  prepareCreditsObjectForDB = (creditParams) => {
    const shalowCreditParams = { ...creditParams };
    shalowCreditParams.divNames = Array.isArray(creditParams.scope)
      ? creditParams.scope.map(({ value }) => value)
      : [creditParams.scope].map(({ value }) => value);
    shalowCreditParams.service = Array.isArray(creditParams.service)
      ? creditParams.service.map(({ value }) => value)
      : [creditParams.service].map(({ value }) => value);
    shalowCreditParams.isExcludeService = creditParams.isExclude;
    shalowCreditParams.isExcludeAwsMarketplace = creditParams.isExcludeAwsMarketplace;
    shalowCreditParams.startDate = creditParams.startDate ? `${creditParams.startDate}-01` : '';
    shalowCreditParams.expirationDate = creditParams.expirationDate
      ? moment(creditParams.expirationDate).endOf('month').format('YYYY-MM-DD')
      : '';
    return shalowCreditParams;
  };
  addCreditsData = async (divId, creditsParams) => {
    const creditsWithShallowArrays = this.prepareCreditsObjectForDB(creditsParams);
    // eslint-disable-next-line no-param-reassign
    creditsParams.divNames = creditsWithShallowArrays.divNames;
    await this.awsCustomersModel.addCreditsData(creditsWithShallowArrays);
  };
  deleteCreditsData = async (id, divisionName) => {
    await this.awsCustomersModel.deleteCreditsData(id, divisionName);
  };
  updateCreditsData = async (currEditedId, newCreditsParams) => {
    const creditsWithShallowArrays = this.prepareCreditsObjectForDB(newCreditsParams);
    await this.awsCustomersModel.updateCreditsData(currEditedId, creditsWithShallowArrays);
  };

  fetchExplainCreditHistoryData = async (creditId, startMonth, expirationMonth) => {
    await this.awsCustomersModel.fetchExplainCreditHistoryData(creditId, startMonth, expirationMonth);
  };

  fetchExplainCustomerFlexibilityMarginData = async (
    divisionId,
    divisionName,
    accountKey,
    flexibilityType,
    start = null,
    end = null,
  ) => {
    await this.awsCustomersModel.fetchExplainCustomerFlexibilityMarginData(
      divisionId,
      divisionName,
      accountKey,
      flexibilityType,
      start,
      end,
    );
  };

  getExplainCustomerFlexibilityMarginData = (flexibilityType) =>
    this.awsCustomersModel.getExplainCustomerFlexibilityMarginData(flexibilityType);
  deleteExplainCustomerFlexibilityMarginData = (flexibilityType) =>
    this.awsCustomersModel.deleteExplainCustomerFlexibilityMarginData(flexibilityType);

  getResellerCustomerInvoiceData = async (rows, type) => {
    try {
      this.rootStore.appStore.updatePricingMode(true);
      await this.awsCustomersModel.getResellerCustomerInvoiceData(rows, type);
    } catch (e) {
      console.log(e);
    }
    this.rootStore.appStore.updatePricingMode(false);
  };

  getCustomerCostAndUsageData = async (
    customerDivisionId,
    customerDivisionName,
    customerAccountKey,
    selectedStartDate = null,
    selectedEndDate = null,
    // eslint-disable-next-line consistent-return
  ) => {
    const { currDispUserDivisionId, currDispUserDivisionName, currDispUserAccountKey } = this.rootStore.usersStore;
    const currDivisionId = currDispUserDivisionId;
    const currDivisionName = currDispUserDivisionName;
    const currAccountKey = currDispUserAccountKey;

    const { startDate, endDate } = buildStartAndEndDate(selectedStartDate, selectedEndDate);

    // change user params
    this.rootStore.usersStore.currDispUserDivisionId = customerDivisionId;
    this.rootStore.usersStore.currDispUserDivisionName = customerDivisionName;
    this.rootStore.usersStore.currDispUserAccountKey = customerAccountKey;
    this.rootStore.appStore.updatePricingMode(true); // change pricing mode to customer

    // fetch CUE services data, taxes, refunds & credits
    try {
      const {
        costData,
        refundData,
        totalCost,
        creditsData,
        costGroupbyLinkedaccData,
        creditsByLinkedaccData,
        costGroupbyCustomer,
        creditGroupbyCustomer,
        discountGroupbyCustomer,
        logoUrl,
      } = await this.getResellerCustomerInvoiceData(startDate, endDate);
      // preapre data
      const costDataRows = costData.map((rawData) => {
        const row = {
          service: rawData.group_by,
          totalCost: rawData.total_cost,
          usageDate: rawData.usage_date,
        };
        return row;
      });

      let refundSum = 0;
      refundData.forEach((rawData) => {
        refundSum += rawData.total_cost;
      });

      let creditsSum = 0;
      creditsData.forEach((rawData) => {
        creditsSum += rawData.total_cost;
      });
      let discountsSum = 0;
      discountGroupbyCustomer.forEach((rawData) => {
        discountsSum += rawData.total_cost;
      });
      let cost = 0;
      costGroupbyCustomer.forEach((customer) => {
        cost += customer.total_cost;
      });
      return {
        costData: costDataRows,
        refundSum,
        cost,
        totalCost,
        costGroupbyLinkedaccData,
        creditsSum,
        creditsByLinkedaccData,
        costGroupbyCustomer,
        creditGroupbyCustomer,
        discountGroupbyCustomer,
        discountsSum,
        logoUrl,
      };
    } catch (error) {
      console.log(error);
    } finally {
      // change back user params
      this.rootStore.usersStore.currDispUserDivisionId = currDivisionId;
      this.rootStore.usersStore.currDispUserDivisionName = currDivisionName;
      this.rootStore.usersStore.currDispUserAccountKey = currAccountKey;
      this.rootStore.appStore.updatePricingMode(false); // change pricing mode back to partner
    }
  };

  getBillingProfile = async (payerAccount) => this.awsCustomersModel.apiGateway.getBillingProfile(payerAccount);

  updateBillingProfile = async (data, payerAccount, isOnboarding) =>
    this.awsCustomersModel.apiGateway.updateBillingProfile(data, payerAccount, isOnboarding);

  invalidateStore = () => {
    this.awsCustomersModel.invalidateData();
    this.azureCustomersModel.invalidateData();
    this.rootStore.usersStore.invalidateDivisionGroups();
    this.rootStore.usersStore.invalidateSubUsers();
    this.rootStore.usersStore.invalidateTargetGoals();
    this.isLoading = false;
  };
}
export default DivisionsStore;
