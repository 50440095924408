import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Col, Collapse, Form, Input, Row } from 'reactstrap';
import { withStyles } from '@mui/styles';
import DateFilter from 'shared/modules/dateFilter';
import { getLstDayOfMonthDate, convertDateToUTC } from 'shared/utils/dateUtil';
import Panel from 'shared/components/Panel';
import DatePicker from 'shared/components/DatePicker';
import CheckBox from 'shared/components/CheckBox';
import ControlledToggleButton from 'shared/components/buttons/ControlledToggleButton';
import PlusCircleOutline from 'mdi-react/PlusCircleOutlineIcon';
import MinusCircleOutline from 'mdi-react/MinusCircleOutlineIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import {
  BudgetAlertChecboxTypes,
  BudgetAlertGranularityTypes,
  BudgetAlertsTypes,
  BudgetAmountTypes,
  BudgetTypes,
  TempBudgetTypes,
} from 'usage/constants/usageConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import BudgetPlannigForm from './budgetFormComponents/BudgetPlannigForm';
import InputCost from './InputCost';
import FilterBar from './FilterBar';

const styles = {
  rootRadio: {
    color: '#52a8ea',
    '&$checked': {
      color: '#52a8ea',
    },
  },
  checked: {},
};

class BudgetForm extends Component {
  static propTypes = {
    customTagsFetcher: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    tempBudget: PropTypes.object.isRequired,
    budgetName: PropTypes.string.isRequired,
    isUpdate: PropTypes.bool,
    currentUserReadOnly: PropTypes.bool.isRequired,
    selectedOptionsMap: PropTypes.object.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    fieldToFieldDistincValuesMap: PropTypes.object.isRequired,
    filterStatusTypeMap: PropTypes.object.isRequired,
    handleChangeFilterType: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    budgetAmount: PropTypes.number.isRequired,
    hasBudgets: PropTypes.bool,
    budgetFormErrorMsgs: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isUpdate: false,
    hasBudgets: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: props.isUpdate || !props.hasBudgets,
    };
  }

  toggleCollapse = () => {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  // eslint-disable-next-line consistent-return
  renderAddAlertRow = () => {
    let alertRowsArray = [];
    const currBudgetAlerts = Object.keys(this.props.tempBudget.budgetAlerts);
    const tempBudgetType = this.props.isUpdate ? TempBudgetTypes.UPDATED : TempBudgetTypes.CREATE;
    alertRowsArray = currBudgetAlerts;
    const maxId = Math.max(...currBudgetAlerts);
    return alertRowsArray.map((id) => (
      <div style={{ marginBottom: '10px' }}>
        <div className="budget-alert-row" style={{ display: 'flex', marginBottom: '5px' }}>
          {Number(id) === maxId && (
            <PlusCircleOutline
              size={20}
              onClick={() => this.props.handlers.handleAddNewBudgetAlertLine(tempBudgetType)}
              style={{
                cursor: 'pointer',
                mrginRight: '5px',
                opacity: `${this.props.tempBudget.isAlerts ? '0.6' : '0.3'}`,
              }}
            />
          )}
          {Number(id) !== maxId && (
            <MinusCircleOutline
              size={20}
              onClick={() => this.props.handlers.handleRemoveBudgetAlertLine(tempBudgetType, id)}
              style={{
                cursor: 'pointer',
                mrginRight: '5px',
                opacity: `${this.props.tempBudget.isAlerts ? '0.6' : '0.3'}`,
              }}
            />
          )}
          <h5
            className={this.props.tempBudget.isAlerts ? 'text-full-budget-alert active' : 'text-full-budget-alert'}
            style={{ maxWidth: '90px' }}
          >
            <input
              className={this.props.tempBudget.isAlerts ? 'input-full-budget-alert active' : 'input-full-budget-alert'}
              disabled={!this.props.tempBudget.isAlerts}
              name="content"
              type="number"
              min="0"
              max="100"
              onChange={(e) =>
                this.props.handlers.handleUpdateBudgetAlertPercentage(tempBudgetType, e.target.value, id)
              }
              value={this.props.tempBudget.budgetAlerts[id].alertPercent}
              autoComplete="off"
            />
            %{' '}
          </h5>
          <CheckBox
            label="Forecasted"
            onChangeFunc={() =>
              this.props.handlers.handleAlertsCheckboxChange(
                BudgetAlertChecboxTypes.WHEN_TO_ALERT,
                tempBudgetType,
                BudgetAlertsTypes.FORECASTED,
                id,
              )
            }
            value={
              this.props.tempBudget.budgetAlerts[id].whenToAlert
                ? this.props.tempBudget.budgetAlerts[id].whenToAlert.indexOf(BudgetAlertsTypes.FORECASTED) > -1
                : false
            }
            disabled={!this.props.tempBudget.isAlerts}
          />
          <CheckBox
            label="Actual Usage"
            onChangeFunc={() =>
              this.props.handlers.handleAlertsCheckboxChange(
                BudgetAlertChecboxTypes.WHEN_TO_ALERT,
                tempBudgetType,
                BudgetAlertsTypes.ACTUAL_USAGE,
                id,
              )
            }
            value={
              this.props.tempBudget.budgetAlerts[id].whenToAlert
                ? this.props.tempBudget.budgetAlerts[id].whenToAlert.indexOf(BudgetAlertsTypes.ACTUAL_USAGE) > -1
                : false
            }
            disabled={!this.props.tempBudget.isAlerts}
          />
          <h5
            className={this.props.tempBudget.isAlerts ? 'text-full-budget-alert active' : 'text-full-budget-alert'}
            style={{ marginLeft: '20px' }}
          >
            Recipients:{' '}
            <input
              style={{ minWidth: '300px' }}
              className={this.props.tempBudget.isAlerts ? 'input-full-budget-alert active' : 'input-full-budget-alert'}
              name="content"
              type="text"
              onChange={(e) => this.props.handlers.handleUpdateBudgetAlertMail(tempBudgetType, e.target.value, id)}
              placeholder="email@example.com, email@example.com"
              value={this.props.tempBudget.budgetAlerts[id].alertEmail}
              disabled={!this.props.tempBudget.isAlerts}
              autoComplete="off"
            />
          </h5>
        </div>
        <div className="budget-alert-row" style={{ display: 'flex', marginBottom: '5px', marginLeft: '13px' }}>
          <h5>Granularity:</h5>
          <CheckBox
            label="Daily"
            onChangeFunc={() =>
              this.props.handlers.handleAlertsCheckboxChange(
                BudgetAlertChecboxTypes.ALERT_GRANULARITY,
                tempBudgetType,
                BudgetAlertGranularityTypes.DAILY,
                id,
              )
            }
            value={
              this.props.tempBudget.budgetAlerts[id].alertGranularity
                ? this.props.tempBudget.budgetAlerts[id].alertGranularity.indexOf(BudgetAlertGranularityTypes.DAILY) >
                  -1
                : false
            }
            disabled={!this.props.tempBudget.isAlerts}
          />
          <CheckBox
            label="Monthly"
            onChangeFunc={() =>
              this.props.handlers.handleAlertsCheckboxChange(
                BudgetAlertChecboxTypes.ALERT_GRANULARITY,
                tempBudgetType,
                BudgetAlertGranularityTypes.MONTHLY,
                id,
              )
            }
            value={
              this.props.tempBudget.budgetAlerts[id].alertGranularity
                ? this.props.tempBudget.budgetAlerts[id].alertGranularity.indexOf(BudgetAlertGranularityTypes.MONTHLY) >
                  -1
                : false
            }
            disabled={!this.props.tempBudget.isAlerts}
          />
          {this.props.tempBudget.budgetType === BudgetTypes.EXPIRING && (
            <CheckBox
              label="Total"
              onChangeFunc={() =>
                this.props.handlers.handleAlertsCheckboxChange(
                  BudgetAlertChecboxTypes.ALERT_GRANULARITY,
                  tempBudgetType,
                  BudgetAlertGranularityTypes.TOTAL,
                  id,
                )
              }
              value={
                this.props.tempBudget.budgetAlerts[id].alertGranularity
                  ? this.props.tempBudget.budgetAlerts[id].alertGranularity.indexOf(BudgetAlertGranularityTypes.TOTAL) >
                    -1
                  : false
              }
              disabled={!this.props.tempBudget.isAlerts}
            />
          )}
        </div>
      </div>
    ));
  };

  render() {
    const {
      isUpdate,
      classes,
      selectedOptionsMap,
      handleFilterChange,
      fieldToFieldDistincValuesMap,
      filterStatusTypeMap,
      handleChangeFilterType,
      tempBudget,
      handlers,
      budgetFormErrorMsgs,
      budgetAmount,
      currentUserReadOnly,
      customTagsFetcher,
    } = this.props;
    const { collapse } = this.state;
    const tempBudgetType = isUpdate ? TempBudgetTypes.UPDATED : TempBudgetTypes.CREATE;
    return (
      <div>
        {!isUpdate ? (
          <div style={{ display: 'flex', margin: '10px' }}>
            {collapse ? (
              <MinusIcon onClick={this.toggleCollapse} style={{ cursor: 'pointer' }} />
            ) : (
              <PlusIcon onClick={this.toggleCollapse} style={{ cursor: 'pointer' }} />
            )}
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <h5
              onClick={this.toggleCollapse}
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', fontSize: '18px' }}
            >
              Create New Budget
            </h5>
          </div>
        ) : null}
        <Collapse isOpen={collapse}>
          <Panel className={isUpdate ? 'budget-update-modal-panel' : ''}>
            <Form style={{ 'margin-bottom': '20px', marginLeft: '20px' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ marginLeft: '0', 'margin-bottom': '18px' }} className="filter-bar-inner-col-wrapper w-50">
                  <h5 className="filter-bar-inner-col-title">Budget Name</h5>
                  <Input
                    value={tempBudget.budgetName}
                    onChange={(e) => handlers.handleChangeBudgetName(e, tempBudgetType)}
                    margin="normal"
                    variant="outlined"
                    placeholder="Name"
                    className="name-input"
                  />
                </div>
              </div>
              <div>
                <h5 className="filter-bar-inner-col-title">Budget Amount</h5>
                <InputCost
                  className="insert-budget-input"
                  name="content"
                  onChange={(e) => handlers.handleChangeBudgetAmount(e.target.value)}
                  value={Number(budgetAmount)}
                  label=""
                />
              </div>
              <div style={{ marginLeft: '-15px' }}>
                <FormControl component="fieldset" style={{ width: '500px' }}>
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={tempBudget.budgetType}
                    onChange={() => handlers.handleChangeBudgetType(tempBudgetType)}
                    row
                  >
                    <FormControlLabel
                      value={BudgetTypes.RECURRING}
                      control={
                        <Radio
                          classes={{
                            root: classes.rootRadio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Monthly Recurring"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value={BudgetTypes.EXPIRING}
                      control={
                        <Radio
                          classes={{
                            root: classes.rootRadio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Period"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div style={{ display: 'flex', width: '350px', marginLeft: '-15px' }}>
                <Col className="budget-update-modal-col start-date">
                  <DatePicker
                    startDate={convertDateToUTC(new Date(tempBudget.startDate))}
                    handleChangeDateStart={(selectedDate) =>
                      handlers.handleSelectStartDate(tempBudgetType, selectedDate || new Date(Date.now()))
                    }
                    periodGranLevel="Monthly"
                    title="Start Month"
                    minDate={new Date(DateFilter.getDateStr()).setUTCMonth(
                      new Date(DateFilter.getDateStr()).getUTCMonth() - 1,
                    )}
                  />
                </Col>
                <Col className="budget-update-modal-col start-date">
                  <DatePicker
                    startDate={convertDateToUTC(new Date(tempBudget.endDate))}
                    handleChangeDateStart={(selectedDate) =>
                      handlers.handleSelectEndDate(
                        tempBudgetType,
                        getLstDayOfMonthDate(selectedDate || new Date(Date.now())),
                      )
                    }
                    periodGranLevel="Monthly"
                    title="End Month"
                    maxDate={new Date(tempBudget.startDate).setUTCFullYear(
                      new Date(tempBudget.startDate).getUTCFullYear() + 1,
                    )}
                    minDate={new Date(tempBudget.startDate)}
                  />
                </Col>
              </div>
              {tempBudget.budgetType === BudgetTypes.EXPIRING ? (
                <div style={{ display: 'flex', marginLeft: '-15px' }}>
                  <FormControl component="fieldset" style={{ width: '300px' }}>
                    <RadioGroup
                      aria-label="position"
                      name="position"
                      value={tempBudget.budgetAmountType}
                      onChange={() => handlers.handleChangeBudgetAmountType(tempBudgetType)}
                      row
                    >
                      <FormControlLabel
                        value={BudgetAmountTypes.FIXED}
                        control={
                          <Radio
                            classes={{
                              root: classes.rootRadio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Fixed"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value={BudgetAmountTypes.PLANNED}
                        control={
                          <Radio
                            classes={{
                              root: classes.rootRadio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Monthly Budget Planning"
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div style={{ padding: '17px' }}>
                    <CheckBox
                      label="Flexible"
                      onChangeFunc={() => handlers.handleIsFlexibleToggle(tempBudgetType)}
                      value={tempBudget.isFlexible}
                    />
                  </div>
                </div>
              ) : null}
              {tempBudget.budgetAmountType === BudgetAmountTypes.PLANNED &&
              tempBudget.budgetType === BudgetTypes.EXPIRING ? (
                <div className={isUpdate ? 'budget-update-modal-col' : ''}>
                  <BudgetPlannigForm
                    tempBudget={tempBudget}
                    startDate={tempBudget.startDate}
                    endDate={tempBudget.endDate}
                    isRecurring={tempBudget.budgetType === BudgetTypes.RECURRING}
                    handleAddBudgetToBudgetAmounts={handlers.handleAddBudgetToBudgetAmounts}
                    handleUpdateBudgetInBudgetAmounts={handlers.handleUpdateBudgetInBudgetAmounts}
                    tempBudgetType={isUpdate ? TempBudgetTypes.UPDATED : TempBudgetTypes.CREATE}
                    budgetAmounts={tempBudget.budgetAmounts}
                  />
                </div>
              ) : null}
              <div className="filters-collapse-container" style={{ padding: '30px', marginTop: '30px' }}>
                <FilterBar
                  selectedOptionsMap={selectedOptionsMap}
                  handleFilterChange={handleFilterChange}
                  fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
                  filterStatusTypeMap={filterStatusTypeMap}
                  handleChangeFilterType={handleChangeFilterType}
                  classes={classes}
                  filterProps={{
                    [AwsCommonFields.CUSTOM_TAGS]: {
                      isLoading: customTagsFetcher.isLoading,
                      onLoad: customTagsFetcher.onLoad,
                      values: customTagsFetcher.values,
                      isAutoComplete: true,
                      placeHolder: 'Type to search',
                    },
                  }}
                />
              </div>
              <Row>
                <div className="line-6-copy" />
              </Row>
              <Row style={{ marginLeft: '5px', marginBottom: '10px', marginTop: '15px' }}>
                <ControlledToggleButton
                  checked={tempBudget.isAlerts}
                  handler={() => handlers.handleActivateAlert(tempBudgetType)}
                  selectedItem="1"
                />
                <h5 style={{ marginLeft: '5px' }}>Send Alerts</h5>
              </Row>
              <Row style={{ marginLeft: '5px', marginBottom: '10px', marginTop: '15px' }} />
              {this.renderAddAlertRow()}
              <Row>
                <div className="line-6-copy" />
              </Row>
              {!isUpdate && (
                <ReadOnlyDisplayWrapper isHide={false} userReadOnly={currentUserReadOnly}>
                  <Row className="budget-form-btns">
                    <Col sm={isUpdate ? 12 : ''} style={{ minWidth: '400px', marginTop: '20px' }}>
                      <Button
                        className="form__button-toolbar"
                        type="submit"
                        color="success"
                        size="sm"
                        onClick={handlers.handleCreateNewBudget}
                      >
                        Add Budget
                      </Button>
                    </Col>
                  </Row>
                </ReadOnlyDisplayWrapper>
              )}
              {budgetFormErrorMsgs.map((msg) => (
                <div>
                  <h5 style={{ color: '#db292c' }} className="budget-form-err-msg">
                    {msg.msg}
                  </h5>
                </div>
              ))}
            </Form>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

const ObserverBudgetForm = withInvoiceFiltersContextConsumer(observer(BudgetForm));
export default withStyles(styles)(ObserverBudgetForm);
