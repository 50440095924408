import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Alert, Button, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import { useDailyRecommendations } from 'recommendations/hooks/react-query/useDailyRecommendations';
import QuickRecommendationPanelBuilder from './components/QuickRecPanel';

const DailyRecommendationsPage = ({ usersStore }) => {
  const { currentDisplayedUserType, currentUserReadOnly, currDispUserCloudAccountType, usersModel } = usersStore;
  const { mapLinkedAccIdToDivisionName } = usersModel;

  const recommendationsHook = useDailyRecommendations({
    isEnabled: usersStore.currentDisplaydUser?.isPayingUser,
    currentDisplayedUserType,
    mapLinkedAccIdToDivisionName,
  });
  const dailyRecommendationDataFetch = recommendationsHook?.fetchDailyRecommendations();
  const isDailyRecommendationsLoading = dailyRecommendationDataFetch?.isLoading;
  const dailyRecommendationsData = dailyRecommendationDataFetch?.data;

  const { dailyRecommendations, isDailyRecsCompleted, isDailyRecsEmpty, getMoreDailyRecs } =
    dailyRecommendationsData || {};

  useEffect(
    // Cleanup
    () => () => recommendationsHook.reset(),
    [],
  );

  const renderAlerts = () => {
    let greeting = '';
    let message = '';
    if (isDailyRecsEmpty) {
      greeting = ' ';
      message = 'No recommendations left.';
    } else if (isDailyRecsCompleted) {
      greeting = 'Great!';
      message = 'You achieved all the daily recommendations';
    }

    if (isDailyRecsCompleted || isDailyRecsEmpty) {
      return (
        <div>
          <Alert color="success" className="alert--neutral" icon>
            <p>
              <span className="bold-text">{greeting}</span>
              {message}.
            </p>
          </Alert>
        </div>
      );
    }
    return <div />;
  };

  const renderGetMoreRecsButton = () => {
    if (isDailyRecsCompleted && !isDailyRecsEmpty) {
      return (
        <>
          <div>
            <Button color="primary" size="sm" onClick={getMoreDailyRecs}>
              Get More Recommendations
            </Button>
          </div>
        </>
      );
    }
    return <div />;
  };

  const renderDailyRecommendations = () =>
    dailyRecommendations?.map((dailyRec) => (
      <QuickRecommendationPanelBuilder
        rec={dailyRec}
        recCompletedHandler={(recs) => recommendationsHook.completeRecommendations(recs)}
        recExcludeHandler={(recs, excludeData, excludeMessageMap) =>
          recommendationsHook.excludeRecommendations(recs, excludeData, excludeMessageMap)
        }
        recHelpers={{
          vuFetchResourcesDataFunc: () => {},
        }}
        currentUserReadOnly={currentUserReadOnly}
        cloudTypeId={currDispUserCloudAccountType}
      />
    ));

  if (isDailyRecommendationsLoading) {
    return <Spinner />;
  }
  return (
    <Container>
      <PageHeader showDate title={PageNames.DAILY_RECOMMENDATION} />
      <Row>{renderAlerts(isDailyRecsCompleted, isDailyRecsEmpty)}</Row>
      <Row>{renderGetMoreRecsButton(isDailyRecsCompleted, isDailyRecsEmpty, getMoreDailyRecs)}</Row>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>{renderDailyRecommendations()}</div>
    </Container>
  );
};

DailyRecommendationsPage.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

const ObserverDailyRecommendationsPage = observer(DailyRecommendationsPage);
export default ObserverDailyRecommendationsPage;
