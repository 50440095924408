/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/system/colorManipulator';
import { withStyles } from '@mui/styles';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid,
  TableColumnVisibility,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Col, Row } from 'reactstrap';
import { RdsIsActiveValues } from 'usage/constants/usageConstants';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    fontSize: '25.5vw',
  },
});

class WeightsTable extends React.Component {
  static propTypes = {
    rows: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    columnWidths: PropTypes.object.isRequired,
    tableColumnExtensions: PropTypes.object.isRequired,
    defaultHiddenColumnNames: PropTypes.object.isRequired,
    defaultSorting: PropTypes.object.isRequired,
    currenciesColumns: PropTypes.object.isRequired,
    // csvTitle: PropTypes.object.isRequired,
    deleteHandler: PropTypes.object.isRequired,
    editHandler: PropTypes.object.isRequired,
    // explainHandler: PropTypes.object.isRequired,
    currentUserReadOnly: PropTypes.bool.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { columns, columnWidths, tableColumnExtensions, defaultHiddenColumnNames, currenciesColumns } = this.props;
    this.state = {
      columns,
      columnWidths,
      tableColumnExtensions,
      hiddenColumnNames: defaultHiddenColumnNames,
      // rows: this.props.rows,
      currenciesColumns,
      searchValue: '',
      filteringStateColumnExtensions: [{ columnName: 'userAction', filteringEnabled: false }],
    };
  }

  userActionsFormatter = (data) => {
    withStyles(data);
    return (
      <div style={{ display: 'flex' }}>
        <ReadOnlyDisplayWrapper isHide={false} userReadOnly={this.props.currentUserReadOnly}>
          <Tooltip title="Delete">
            <IconButton onClick={() => this.props.deleteHandler(data.row.id)} size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ReadOnlyDisplayWrapper>
        <Tooltip title="Edit">
          <IconButton onClick={() => this.props.editHandler(data.row)} size="large">
            <EditIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  currenciesFormatter = (value) => `${this.props.getCurrencyNumber(value.value, 1)}`;

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  changeSearchValue = (value) => this.setState({ searchValue: value });
  prepareCreditTableData = (data) => {
    if (!data || !data.length) {
      return [];
    }
    const preparedData = data.map((item) => {
      const { service, ...rest } = item;
      const prepService = typeof service === 'object' ? service.label : service;
      item.service = prepService;
      return item;
    });
    return preparedData;
  };

  render() {
    const {
      // rows,
      columns,
      columnWidths,
      tableColumnExtensions,
      hiddenColumnNames,
      searchValue,
      currenciesColumns,
      filteringStateColumnExtensions,
    } = this.state;
    const { rows, defaultSorting } = this.props;
    const csvModifiedRows = JSON.parse(JSON.stringify(rows));
    csvModifiedRows.map((row) => {
      if (row.isActive === RdsIsActiveValues.NOT_ACTIVE) {
        row.isActive = 'No';
        return row;
      }
      row.isActive = 'Yes';
      return row;
    });
    return (
      <div style={{ width: '100%' }} ref={this.refCallback}>
        <Col xs={12} md={12} lg={12} xl={12}>
          <Paper>
            <Grid rows={rows} columns={columns}>
              <FilteringState defaultFilters={[]} columnExtensions={filteringStateColumnExtensions} />
              <IntegratedFiltering />
              <SortingState defaultSorting={defaultSorting} />
              <IntegratedSorting />
              {currenciesColumns && (
                <DataTypeProvider for={currenciesColumns} formatterComponent={this.currenciesFormatter} />
              )}
              <DataTypeProvider for={['userAction']} formatterComponent={this.userActionsFormatter} />
              <TableWrapper columnExtensions={tableColumnExtensions} styles={styles} />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
              />
              <Toolbar />
              <ColumnChooser />
            </Grid>
          </Paper>
        </Col>
      </div>
    );
  }
}

const ObserverWeightsTable = withUserSettingsConsumer(WeightsTable);
export default ObserverWeightsTable;
