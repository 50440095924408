import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useSlack from 'shared/components/slackIntegration/hooks/useSlack';
import useTable from 'shared/hooks/customHooks/useTable';
import { userNotificationsProvider } from 'shared/hooks/react-query/useUserSettings';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import Spinner from 'shared/components/andtComponents/Spinner';
import { RowDetailState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import styles from './integrations.module.scss';
import SlackIntegration, { SlackIntegrationDetails } from './SlackIntegration';
import LegacySlackIntegration from './LegacySlackIntegration';

export default function Channels({ usersStore }) {
  const [isLegacySlack, setIsLegacySlack] = useState(false);
  const [integrationOptions, setIntegrationOptions] = useState([]);
  const [expandedRowIds, setExpandedRowIds] = useState([]);

  const userNotificationsHook = userNotificationsProvider();
  const { data: notifications, isLoading } = userNotificationsHook.useQuery();
  const { getSlackIntegrations } = useSlack();
  const { data: integrations, isLoading: isLoadingIntegration } = getSlackIntegrations();
  const { NewTableWrapper, NewTableRow, NewTableRowDetail } = useTable();

  const SlackExtendedDetails = ({ row }) => (
    <NewTableRowDetail row={row}>
      <SlackIntegrationDetails integrations={row.integrations} />
    </NewTableRowDetail>
  );
  SlackExtendedDetails.propTypes = {
    row: PropTypes.array.isRequired,
  };

  useEffect(() => {
    setIsLegacySlack(!!usersStore.usersModel.mainUser.slackWebhookUrl && notifications?.isSlackNotification);
  }, [notifications]);

  useEffect(() => {
    const slackComponent = isLegacySlack
      ? { component: <LegacySlackIntegration setIsLegacySlack={setIsLegacySlack} /> }
      : {
          component: <SlackIntegration integrations={integrations} />,
          integrations,
        };
    setIntegrationOptions([slackComponent]);
  }, [isLegacySlack, integrations]);

  const handleExpendChange = (expendedRows) => {
    // prevent expend when row does not have extendedInfo
    const expendRow = expendedRows.filter((row) => integrationOptions[row].integrations);
    setExpandedRowIds(expendRow);
  };

  if (isLoading || isLoadingIntegration) {
    return <Spinner />;
  }

  return (
    <div className={styles.integrations}>
      <div className={styles.title}>Channels</div>
      <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
        <NewTableWrapper>
          <Grid rows={integrationOptions} columns={[{ name: 'component', title: 'component' }]}>
            <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={handleExpendChange} />
            <Table rowComponent={(props) => <NewTableRow expandedRowIds={expandedRowIds} {...props} />} />
            <TableRowDetail contentComponent={SlackExtendedDetails} />
          </Grid>
        </NewTableWrapper>
      </ReadOnlyDisplayWrapper>
    </div>
  );
}

Channels.propTypes = {
  usersStore: PropTypes.object.isRequired,
};
