import PropTypes from 'prop-types';
import React from 'react';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import svgImage from '../../../img/hourlyCommitment.svg';

import classes from './recommendedHourlyCommitment.module.scss';

const RecommendedHourlyCommitment = ({ hourlyCommitment }) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(hourlyCommitment)) {
    return null;
  }
  const { currencySymbol } = useUserSettingsContext();

  return (
    <div className={classes.container}>
      <div className={classes.svgContainer}>
        <img src={svgImage} alt="hourlyCommitmentImage" />
      </div>
      <div className={classes.hourlyCommitmentContainer}>
        <div className={classes.text}>Recommended Hourly Commitments ({currencySymbol})</div>
        <div className={classes.value}>{hourlyCommitment}</div>
      </div>
      <div className={classes.spAnalyzerContainer}>
        <div className={classes.text}>Continue your investigation using SP Analyzer</div>
        <div className={classes.continueAnalysis}>
          <GenerateIcon iconName={ICONS.magnifyingGlassDollar.name} />
          <span>Continue Analysis</span>
        </div>
      </div>
    </div>
  );
};

RecommendedHourlyCommitment.propTypes = {
  hourlyCommitment: PropTypes.number.isRequired,
};

export default RecommendedHourlyCommitment;
