/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import domtoimage from 'dom-to-image';
import fileDownload from 'js-file-download';

const downloadChartAsImg = (chartId) => {
  domtoimage.toBlob(document.getElementById(chartId), { bgcolor: '#ffffff' }).then((blob) => {
    fileDownload(blob, 'chart-to-image.png');
  });
};

const DownloadAsPng = ({ chartId }) => (
  <a href="#" onClick={() => downloadChartAsImg(chartId)}>
    Download as PNG
    <span className="lnr lnr-download" style={{ fontSize: '18px', marginLeft: '8px', marginBottom: '8px' }} />
  </a>
);

export default DownloadAsPng;
DownloadAsPng.propTypes = {
  chartId: PropTypes.string.isRequired,
};
