import React from 'react';
import PropTypes from 'prop-types';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import { copyStrToClipboard } from '../helpers/newUserHelpers';

const copyToClipboard = (id) => {
  const copyText = document.getElementById(id).textContent;
  copyStrToClipboard(copyText);
};

const CopyFromCmdBoxButton = ({ boxId }) => (
  <button
    type="button"
    className="form__form-group-button"
    style={{ float: 'right' }}
    onClick={() => copyToClipboard(boxId)}
  >
    <ContentCopyIcon />
  </button>
);

CopyFromCmdBoxButton.propTypes = {
  boxId: PropTypes.string.isRequired,
};

const CliExecuteCmd = ({ cmdString, id }) => (
  <>
    <div className="account__code-window">
      <CopyFromCmdBoxButton boxId={id} />
      <pre id={id}>
        <code>{cmdString}</code>
      </pre>
    </div>
  </>
);
CliExecuteCmd.propTypes = {
  cmdString: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};
export default CliExecuteCmd;
