/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */

import React, { PureComponent } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { StringToColor } from 'shared/utils/graphicUtil';
import PropTypes from 'prop-types';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

class SimpleLineChart extends PureComponent {
  static propTypes = {
    dataKey: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    isPrecent: PropTypes.bool,
    displayMetric: PropTypes.string.isRequired,
    targetGoal: PropTypes.number,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
    overrideCurrency: PropTypes.string,
  };
  static defaultProps = {
    isPrecent: false,
    targetGoal: 0,
    overrideCurrency: null,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getMaxVal = (data) =>
    data.reduce((acc, currData) => {
      Object.keys(currData).forEach((currKey) => {
        if (currKey !== 'divName' && currKey !== 'usageDate' && currKey !== 'groupBy' && +currData[currKey] > acc) {
          acc = currData[currKey];
        }
      });
      return acc;
    }, -Infinity);

  getColorForLines = (name, keysRecorder) => {
    if (name.includes('(actual') && (!keysRecorder.actual || keysRecorder.actual.includes(name.split(' (')[0]))) {
      if (!keysRecorder.actual) {
        [keysRecorder.actual] = name.split(' (');
        [keysRecorder.requirments] = name.split(' (');
      }
      return '#4B1D3F';
    }
    if (
      name.includes('(requirements)') &&
      (!keysRecorder.requirments || keysRecorder.requirments.includes(name.split(' (')[0]))
    ) {
      if (!keysRecorder.requirments) {
        [keysRecorder.actual] = name.split(' (');
        [keysRecorder.requirments] = name.split(' (');
      }
      return '#D47A21';
    }
    return StringToColor.next(name);
  };

  toPrecent = (data) => `${data}%`;
  renderLines = (data) => {
    const requirementsActualRecorder = {
      requirments: '',
      actual: '',
    };
    const linesKeys = data.reduce((acc, currValue) => {
      Object.keys(currValue).forEach((currKey) => {
        if (!acc[currKey] && currKey !== 'divName' && currKey !== 'usageDate' && currKey !== 'groupBy') {
          acc[currKey] = null;
        }
      });
      return acc;
    }, {});
    return Object.keys(linesKeys).map((key) => (
      <Line strokeWidth={2} dataKey={key} stroke={this.getColorForLines(key, requirementsActualRecorder)} />
    ));
  };

  render() {
    const { renderLines, toPrecent } = this;
    const { data, overrideCurrency } = this.props;
    const { isPrecent, displayMetric, targetGoal, dataKey, numStrAbriviaionByDisplayMetric } = this.props;
    const maxValue = this.getMaxVal(data);
    return (
      <ResponsiveContainer height={400}>
        <LineChart height={400} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataKey} />
          {isPrecent && (
            <YAxis
              allowDataOverflow
              allowDecimals={false}
              domain={[0, 100]}
              tickFormatter={(value) => toPrecent(value)}
            />
          )}
          {!isPrecent && (
            <YAxis
              domain={[0, 'dataMax + 0.02']}
              tickFormatter={(value) =>
                numStrAbriviaionByDisplayMetric(maxValue, value, displayMetric, overrideCurrency)
              }
            />
          )}
          <Legend />
          <Tooltip
            formatter={(value) => numStrAbriviaionByDisplayMetric(maxValue, value, displayMetric, overrideCurrency)}
          />
          {renderLines(data)}
          {/* {targetGoal && <ReferenceLine y={targetGoal} label="Target" stroke="red" strokeDasharray="3 3" toFront />} */}
          {targetGoal && (
            <ReferenceLine
              y={targetGoal}
              label={{ position: 'bottom', value: `Target = ${targetGoal}%`, fill: 'black', fontSize: 20 }}
              stroke="black"
              strokeDasharray="5"
              strokeWidth={2.5}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

const ObserverSimpleLineChart = withUserSettingsConsumer(SimpleLineChart);

export default ObserverSimpleLineChart;
