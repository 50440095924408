import React, { useContext, useMemo } from 'react';
import HeatMapSummaryItem from 'recommendationsNew/components/heatMap/heatMapSummaryItem';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import useHeatMapSummary from 'recommendationsNew/hooks/react-query/useHeatMapSummary';
import RecommendationsNewContext from 'recommendationsNew/contexts/recommendationsNewContext';
import { SWITCH_BUTTON_STATUSES } from 'recommendationsNew/consts';
import { ICONS } from '@pileus-cloud/anodot-frontend-common/dist';

import classes from './heatMap.module.scss';

const HeatMapSummary = () => {
  const { currencySymbol } = useUserSettingsContext();
  const summaryInfoTooltip = 'All the numbers are corresponding to the selected filters';

  const { recommendationFilters: filtersContext } = useContext(RecommendationsNewContext);

  const heatmapSummaryHook = useHeatMapSummary();
  const { data: summary } = heatmapSummaryHook.fetchHeatMapSummary(filtersContext);

  // Annual potential savings amount divided by “Annual cost” * 100 (to get it as percent)
  const esr = (summary?.allOpenRecs?.annualSavings / summary?.annualCostOpenRecsUnimplemented) * 100 || 0;

  const additionalCostSavingsData = useMemo(() => {
    if (!summary) {
      return [];
    }
    return [
      {
        label: SWITCH_BUTTON_STATUSES.OPEN,
        value: summary?.openUndoneUnexcludedRecs?.annualSavings || 0,
        count: summary?.openUndoneUnexcludedRecs?.recsCount || 0,
      },
      {
        label: SWITCH_BUTTON_STATUSES.EXCLUDED,
        value: summary?.openExcludedRecs?.annualSavings || 0,
        count: summary?.openExcludedRecs?.recsCount || 0,
        icon: ICONS.excluded.name,
      },
      {
        label: SWITCH_BUTTON_STATUSES.DONE,
        value: summary?.openDoneRecs?.annualSavings || 0,
        count: summary?.openDoneRecs?.recsCount || 0,
        icon: ICONS.clipboardCheck.name,
      },
    ];
  }, [summary]);

  return (
    <div className={classes.summary}>
      {summary ? (
        <>
          <HeatMapSummaryItem
            summaryTitle="Annual Potential Savings"
            summaryNumber={summary?.allOpenRecs?.annualSavings || 0}
            isCostColored
            summaryUnit={currencySymbol}
            additionalCostSavingsData={additionalCostSavingsData}
            isBackgroundColored
          />
          <HeatMapSummaryItem
            summaryTitle="ESR (Effective Savings Rate):"
            summaryNumber={esr}
            summaryUnit="%"
            isCostColored
          />
          <Tooltip placement="top-start" title={summaryInfoTooltip} className="toolTip">
            <span>
              <InfoIcon />
            </span>
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

export default HeatMapSummary;
