/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Card, CardBody, Carousel, CarouselControl, CarouselItem, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BillingProfile from 'divisions/containers/Preferences/components/BillingProfile';
import { CLOUD_TYPE_IDS, UsersType } from 'users/constants/usersConstants';
import { hashText } from 'shared/utils/strUtil';
import ConnectLinkedAccountsContainer from 'users/containers/LinkedAccounts';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import AlertError from './components/Alerts/AlertError';
import { ActiveIndexToTitleText, pages, UserConnectionStages } from './constants/newUserConstants';
import WizardIntro from './components/Intro/WizardIntro';
import WizardPlayerForm from './components/WizardPlayerForm';
import WizardSubmitModal from './components/WizardSubmitModal';
import CarouselPageNavigator from './components/LayoutComponents/CarouselPageNavigator';
import InvoiceBucketNameForm from './components/InvoiceBucketNameForm';
import DownloadJsonFilesContainer from './components/DownloadJsonFilesContainer';
import ExecuteCliCommandsContainer from './components/ExecuteCliCommandsContainer';
import DownloadConnectFileContainer from './components/DownloadConnectFileContainer';
import SideBarNavigationTimeline from './components/LayoutComponents/SideBarNavigationTimeline';

const allItems = [
  {
    id: pages.page0,
    withProps: false,
    component: <WizardIntro />,
  },
  {
    id: pages.page1,
    withProps: false,
    component: <WizardPlayerForm />,
  },
  {
    id: pages.page2,
    withProps: true,
    component: InvoiceBucketNameForm,
  },
  {
    id: pages.page3,
    withProps: true,
    component: DownloadJsonFilesContainer,
  },
  {
    id: pages.page4_getUserInfo,
    withProps: true,
    component: ExecuteCliCommandsContainer,
  },
  {
    id: pages.page7,
    withProps: true,
    component: BillingProfile,
  },
  {
    id: pages.page5_lastPage_displayed,
    withProps: true,
    component: DownloadConnectFileContainer,
  },
  {
    id: pages.page6_lastPage,
    withProps: true,
    component: ConnectLinkedAccountsContainer,
  },
];

const disableEnterKeySub = (event) => {
  if (event.which === 13 /* Enter */) {
    event.preventDefault();
  }
};

const trimAccId = (awsAccountID) => awsAccountID.replace(/\b0+/g, '');

const WithProps =
  (C) =>
  ({ ...props }) =>
    <C {...props} />;

const NUM_OF_RETRIES = 25;

class NewAWSUserWizard extends Component {
  static propTypes = {
    usersStore: PropTypes.object.isRequired,
    divisionsStore: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { usersStore } = props;
    const isResellerMode = usersStore.getCurrDisplayedUserIsResellerMode();
    this.state = {
      items: !isResellerMode ? allItems.filter((item) => item.id !== pages.page7) : allItems,
      activeIndex: pages.page0,
      modal: false,
      invoiceBucketName: '',
      awsAccountID: '',
      awsAccountName: '',
      bucketRegionName: '',
      arnRoleNum: '',
      errorMessgae: '',
      showNoInputValueWarning: false,
      showGoToNextAlert: false,
      connectingUser: false,
      isConnectionSuccess: null,
      preConnectionDone: false,
      isLinkedAccounts: false,
      isAccountIdValid: false,
      accountIdErrorMsg: '',
      userConnectionStage: UserConnectionStages.DATA_FILLING,
      usersStore,
      externalId: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { preConnectionDone } = this.state;
    if (preConnectionDone && !prevState.preConnectionDone) {
      this.handleConnect();
    }
  }

  onSubmit = () => this.toggle();

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };
  // user pressed the modal button
  toggle = async () => {
    if (this.validateForm(this.state.activeIndex).isValid) {
      await this.handleCheckConnection(); // handle connect also check if user has linked accounts
    }
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  updateUserAccountInfoHandler = async (invoiceBucketName, arnRoleNum, awsAccountID, externalId) => {
    const { usersStore } = this.props;
    const connectData = {
      cloudAccountId: awsAccountID,
      clientPileusRoleArn: arnRoleNum,
      s3BucketName: invoiceBucketName,
      externalId,
    };
    const response = await usersStore.insertUserCloudAccountInterface(connectData);
    return response;
  };

  updateUserConnectionResult = (isConnectionSuccess, awsAccountID, accountKey) => {
    if (!isConnectionSuccess) {
      return;
    }
    const { usersStore } = this.props;
    const currDispUserKey = usersStore.currentDisplayedUserKey;
    const currDispUser = usersStore.getUserByKey(currDispUserKey);
    const awsAccountIdNum = typeof awsAccountID === 'number' ? awsAccountID : parseInt(awsAccountID, 10);
    if (currDispUser.userType === UsersType.NEW_USER) {
      const newType = UsersType.USER_ON_BOARDED;
      const cloudType = CLOUD_TYPE_IDS.AWS;
      usersStore.updateUserTypeAndAwsAccId(newType, awsAccountIdNum, isConnectionSuccess, cloudType, accountKey);
    }
  };

  handleConnect = async () => {
    const { usersStore } = this.props;
    const { preConnectionDone } = this.state;
    const connectResult = await usersStore.connectUserToCloudAccount(preConnectionDone);
    const { isLinkedAccounts } = this.state;
    this.setState({ connectingUser: false, isConnectionSuccess: connectResult.isConnectSuccess, isLinkedAccounts });
    this.updateUserConnectionStat(false, false, connectResult.isConnectSuccess, isLinkedAccounts);
    this.updateUserConnectionResult(
      connectResult.isConnectSuccess,
      preConnectionDone.cloudAccountId,
      connectResult.accountKey,
    );
  };

  handleCheckConnection = async () => {
    const { awsAccountID, awsAccountName, arnRoleNum, invoiceBucketName } = this.state;
    const { usersStore } = this.props;
    let connectingUser = true;
    let { isConnectionSuccess } = this.state;
    this.setState({
      modal: connectingUser,
      connectingUser,
    });
    this.updateUserConnectionStat(false, connectingUser, isConnectionSuccess, false);
    if (!awsAccountID || !arnRoleNum || !invoiceBucketName || !awsAccountName) {
      const missingData = `${awsAccountID || 'cloudAccountId'}, ${arnRoleNum || 'clientPileusRoleArn'}, ${
        invoiceBucketName || 's3BucketName'
      }, ${awsAccountName || 'awsAccountName'}`;
      connectingUser = false;
      isConnectionSuccess = false;
      this.setState({ connectingUser, userIsConnected: false });
      this.updateUserConnectionStat(true, connectingUser, isConnectionSuccess, false);
      return { isConnectionSuccess, missingData };
    }
    const connectData = {
      cloudAccountId: trimAccId(awsAccountID),
      accountName: awsAccountName,
      clientPileusRoleArn: arnRoleNum,
      s3BucketName: invoiceBucketName,
    };
    for (let i = 0; i < NUM_OF_RETRIES; i++) {
      // eslint-disable-next-line no-await-in-loop
      const accountCreated = await usersStore.usersModel.checkNewCloudAccount(connectData.cloudAccountId);
      if (accountCreated) {
        this.setState({
          preConnectionDone: connectData,
        });
        return {};
      }
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 2500));
    }
    this.setState({
      userConnectionStage: UserConnectionStages.CHECK_FAILED,
    });
    return null;
  };

  updateUserConnectionStat = (isError, connectingUser, isConnectionSuccess, isLinkedAccounts) => {
    if (isError) {
      this.setState({ userConnectionStage: UserConnectionStages.INTERNAL_ERROR });
    }
    if (connectingUser) {
      this.setState({ userConnectionStage: UserConnectionStages.CONNECTING });
    } else if (!connectingUser && isConnectionSuccess !== null && isConnectionSuccess) {
      const userStage = isLinkedAccounts
        ? UserConnectionStages.RESULT_SUCCESS_LINKED_ACC
        : UserConnectionStages.RESULT_SUCCESS;
      this.setState({ userConnectionStage: userStage });
    } else if (!connectingUser && isConnectionSuccess !== null && !isConnectionSuccess) {
      this.setState({ userConnectionStage: UserConnectionStages.RESULT_FAIL });
    }
  };

  handleInvoiceBucketNameChange = (newInvoiceBucketName) => {
    this.setState({ invoiceBucketName: newInvoiceBucketName });
    if (
      newInvoiceBucketName.length > 0 &&
      this.state.awsAccountID.length > 0 &&
      this.state.bucketRegionName.length > 0 &&
      this.state.awsAccountName.length > 0
    ) {
      this.setState({ showNoInputValueWarning: false, showGoToNextAlert: true });
    }
  };
  checkIfAccountAlreadyExistsForUser = (accId) => {
    try {
      const { usersStore } = this.props;
      const accounts = usersStore.currentDisplaydUser.getAllAccounts();
      const accountIndex = accounts.findIndex((acc) => acc.accountId === accId);
      return accountIndex > -1;
    } catch (error) {
      return false;
    }
  };
  checkIfAccountIdValid = (accId) => {
    let isAccountIdValid = true;
    let accountIdErrorMsg = '';
    if (this.checkIfAccountAlreadyExistsForUser(accId)) {
      isAccountIdValid = false;
      accountIdErrorMsg = 'This Account id already exists';
    } else if (!/^\d*$/.test(accId)) {
      isAccountIdValid = false;
      accountIdErrorMsg = 'Account Id must contain only numbers without spaces';
    }
    return { isAccountIdValid, accountIdErrorMsg };
  };
  handleAwsAccountIDChange = async (newAwsAccountID) => {
    const trim = trimAccId(newAwsAccountID);
    const { isAccountIdValid, accountIdErrorMsg } = this.checkIfAccountIdValid(trim);
    if (!isAccountIdValid) {
      this.setState({
        isAccountIdValid: false,
        accountIdErrorMsg,
        showNoInputValueWarning: false,
        showGoToNextAlert: false,
      });
      return;
    }
    const externalId = await hashText(trim, 8);
    this.setState({ awsAccountID: trim, isAccountIdValid: true, accountIdErrorMsg: '', externalId });
    if (
      newAwsAccountID.length > 0 &&
      this.state.invoiceBucketName.length > 0 &&
      this.state.bucketRegionName.length > 0 &&
      this.state.awsAccountName.length > 0
    ) {
      this.setState({ showNoInputValueWarning: false, showGoToNextAlert: true });
    }
  };
  handleAwsAccountNameChange = (newAwsAccountName) => {
    this.setState({ awsAccountName: newAwsAccountName });
    if (
      newAwsAccountName.length > 0 &&
      this.state.invoiceBucketName.length > 0 &&
      this.state.bucketRegionName.length > 0 &&
      this.state.awsAccountID.length > 0
    ) {
      this.setState({ showNoInputValueWarning: false, showGoToNextAlert: true });
    }
  };
  handleBucketRegionNameChange = (newRegionName) => {
    this.setState({ bucketRegionName: newRegionName });
    if (
      newRegionName.length > 0 &&
      this.state.awsAccountID.length > 0 &&
      this.state.invoiceBucketName.length > 0 &&
      this.state.awsAccountName.length > 0
    ) {
      this.setState({ showNoInputValueWarning: false, showGoToNextAlert: true });
    }
  };
  handleArnRoleNumChange = (newArnRoleNum) => {
    const validArnRoleNum = newArnRoleNum ? newArnRoleNum.replace(/["\s]+/g, '') : '';
    this.setState({ arnRoleNum: validArnRoleNum });
  };

  goToLinkedAccountsHandler = () => {
    this.setState({ activeIndex: pages.page6_lastPage });
  };

  updateAlertClosed = () => {
    this.setState({ showNoInputValueWarning: false, showGoToNextAlert: false, errorMessgae: '' });
  };

  validateForm = (index) => {
    let result = true;
    let errorMessgae = '';
    const { items } = this.state;
    const page = items[index].id;
    if (page === pages.page2) {
      if (
        this.state.invoiceBucketName.length === 0 ||
        this.state.awsAccountID.length === 0 ||
        this.state.awsAccountName.length === 0 ||
        this.state.bucketRegionName.length === 0 ||
        !this.state.isAccountIdValid
      ) {
        result = false;
      }
    }
    if (page === pages.page4_getUserInfo) {
      if (!this.state.arnRoleNum || this.state.arnRoleNum.length === 0) {
        result = false;
      } else if (!this.state.arnRoleNum.startsWith('arn:aws:iam::')) {
        result = false;
        errorMessgae = 'Arn number must start with - arn:aws:iam::';
      }
    }
    return { isValid: result, errorMessgae };
  };

  navigationDisabled = () => this.animating || document.activeElement.nodeName === 'INPUT';

  next = () => {
    if (this.navigationDisabled()) {
      return;
    }
    const { activeIndex, items, awsAccountID, invoiceBucketName, arnRoleNum, externalId } = this.state;
    const pageId = items[activeIndex].id;
    if (pageId === pages.page5_lastPage_displayed) {
      return;
    }
    const { isValid, errorMessgae } = this.validateForm(activeIndex);
    if (!isValid) {
      this.setState({ showNoInputValueWarning: true, showGoToNextAlert: false, errorMessgae });
      // this.setState({ showGoToNextAlert: false });
      return;
    }
    const nextIndex = pageId === pages.page6_lastPage ? 0 : activeIndex + 1;
    if (pageId === pages.page4_getUserInfo || pageId === pages.page2) {
      const trimmedAccId = awsAccountID && trimAccId(awsAccountID);
      this.updateUserAccountInfoHandler(invoiceBucketName, arnRoleNum, trimmedAccId, externalId);
    }
    this.setState({ showNoInputValueWarning: false, showGoToNextAlert: false, activeIndex: nextIndex });
  };

  previous = () => {
    const { items, activeIndex } = this.state;
    if (this.navigationDisabled()) {
      return;
    }
    if (activeIndex === 0) {
      return;
    }
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    this.setState({ showNoInputValueWarning: false, activeIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    if (this.animating) {
      return;
    }
    this.setState({ activeIndex: newIndex });
  };

  goOnCondition = () => {
    this.setState({ showGoToNextAlert: true });
    return null;
  };

  renderModal = (isOpen, closeModal, userConnectionStage, goToLinkedAccountsHandler) => (
    <WizardSubmitModal
      isOpen={isOpen}
      closeModal={closeModal}
      userConnectionStage={userConnectionStage}
      handleGoBack={() => {
        this.setState({
          modal: false,
          connectingUser: false,
          activeIndex: pages.page1,
        });
      }}
      handleTryConnect={() => this.handleCheckConnection()}
      goToLinkedAccountsHandler={goToLinkedAccountsHandler}
    />
  );

  render() {
    const { usersStore, divisionsStore } = this.props;
    const {
      activeIndex,
      userConnectionStage,
      modal,
      connectingUser,
      items,
      awsAccountID,
      accountIdErrorMsg,
      externalId,
      invoiceBucketName,
      awsAccountName,
      bucketRegionName,
      arnRoleNum,
      isAccountIdValid,
      errorMessgae,
      showNoInputValueWarning,
    } = this.state;
    const { userType } = usersStore.getUserByKey(usersStore.currentDisplayedUserKey) || {};
    const isModalOpen = modal || connectingUser;
    const slides = items.map((item) => (
      <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.id}>
        {item.withProps
          ? WithProps(item.component)({
              invoiceBucketName,
              handleInvoiceBucketNameChange: this.handleInvoiceBucketNameChange,
              awsAccountID,
              handleAwsAccountIDChange: this.handleAwsAccountIDChange,
              awsAccountName,
              handleAwsAccountNameChange: this.handleAwsAccountNameChange,
              bucketRegionName,
              handleBucketRegionNameChange: this.handleBucketRegionNameChange,
              arnRoleNum,
              handleArnRoleNumChange: this.handleArnRoleNumChange,
              disableEnterKeySub,
              onSubmit: this.onSubmit,
              goOnCondition: this.goOnCondition,
              userConnectionStage,
              usersStore,
              divisionsStore,
              billingProfileTitle: false,
              payerAccountOptions: awsAccountID ? [{ value: trimAccId(awsAccountID) }] : null,
              linkedAccounts: usersStore.usersModel.userLinkedAccounts,
              handleConnectLinkedAcct: this.handleConnectLinkedAcct,
              isAccountIdValid,
              accountIdErrorMsg,
              externalId,
              isOnBoarding: true,
            })
          : item.component}
      </CarouselItem>
    ));
    const index = items[activeIndex].id;
    return (
      <>
        {this.renderModal(isModalOpen, this.closeModal, userConnectionStage, this.goToLinkedAccountsHandler)}
        <Container className="new-user-container" style={{ width: 'calc(100% - 220px)' }}>
          <Row className="page-title-wrapper">
            <Col className="page-title" style={{ minWidth: '100%' }}>
              <h3>{ActiveIndexToTitleText.get(index)}</h3>
            </Col>
          </Row>
          <SideBarNavigationTimeline
            activeIndex={activeIndex}
            steps={items.map((i) => ActiveIndexToTitleText.get(i.id)).filter(Boolean)}
          />
          <Card className="wizrd-carousel-item-container">
            <CardBody>
              <Row>
                <Col xs={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }} xl={{ size: 12 }}>
                  <AlertError
                    text={errorMessgae || 'Please fill in the below missing fields to proceed'}
                    withCloseIcon
                    toShow={showNoInputValueWarning}
                    updateAlertClosed={this.updateAlertClosed}
                  />
                  <TableHeaderProvider>
                    <Carousel
                      interval={0}
                      autoPlay={false}
                      activeIndex={activeIndex}
                      next={this.next}
                      previous={this.previous}
                    >
                      {slides}
                      <CarouselControl className="control-hidden" direction="prev" directionText="Previous" />
                      <CarouselControl className="control-hidden" direction="next" directionText="Next" />
                    </Carousel>
                  </TableHeaderProvider>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <CarouselPageNavigator
            previous={this.previous}
            next={this.next}
            activeIndex={index}
            lastPageDisp={pages.page5_lastPage_displayed}
            lastPage={pages.page6_lastPage}
            closeModal={this.closeModal}
            linkedAccounts={usersStore.usersModel.userLinkedAccounts}
            userType={userType}
          />
        </Container>
      </>
    );
  }
}

const ObserverNewUserWizard = observer(NewAWSUserWizard);
export default ObserverNewUserWizard;
