/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
// import { UsersType } from 'users/constants/usersConstants';

const AllowedUsersDisplayWrapper = (props) => {
  const disabledStyle = { pointerEvents: 'none', opacity: '0.4' };
  const hideStyle = { display: 'none' };

  const getStyle = () => {
    let style = {};
    let isBlocked = true;
    try {
      isBlocked = !props.allowedUserTypes.includes(+props.viewingUserType);
    } catch (error) {
      isBlocked = true;
    }
    if (isBlocked) {
      style = props.isHide ? hideStyle : disabledStyle;
    }
    return style;
  };
  return <div style={getStyle()}>{props.children}</div>;
};

AllowedUsersDisplayWrapper.propTypes = {
  children: PropTypes.object.isRequired,
  viewingUserType: PropTypes.number.isRequired,
  allowedUserTypes: PropTypes.object.isRequired,
  isHide: PropTypes.bool,
};
AllowedUsersDisplayWrapper.defaultProps = {
  isHide: true,
};

export default AllowedUsersDisplayWrapper;
