export const PILEUS_ROLE_FILENAME = 'PileusRole.json';
export const PILEUS_POLICY_FILENAME = 'PileusPolicy.json';
export const NEW_INVOICE_TOPIC_FILENAME = 'NewInvoiceTopicConfiguration.json';
export const CONNECT_TO_FILE_NAME_COMPLETION = '_connect_to_pileus.txt';
export const MAX_ASSUME_ROLE_SESSION_DURATION_IN_SECONDS = 10800;

export class WIZARD_FLOW_TEXT {
  static INTRO_TITLE = 'Connect your AWS account';
  static INTRO_SUB_TITLE = 'This will enable analyzing your cloud data';
  static HOW_TO_TITLE = 'Create Cost & Usage Report';
  static GO_TO_NEXT_TITLE = 'Good Job!';
  static STEP3_TITLE = 'Update the Invoice Bucket Name & AWS Root Account ID';
  static STEP4_TITLE = 'Update Role and Policy';
  static STEP5_TITLE = 'Update the ARN Role';
  static STEP6_TITLE = 'Validate Permissions';
  static STEP7_TITLE = 'Billing Profile';
  static MODAL_SUCCESS_HEADER_TITLE = 'Great Job!';
  static MODAL_FAIL_HEADER_TITLE = 'Something went wrong..';
  static MODAL_SUCCESS_BODY = `In just a few hours, your AWS usage and cost will be processed on our system.
You’ll be able to view your dashboard, charts and many more features!`;
  static MODAL_SUCCESS_BODY_LINKED_ACC = `In just a few hours, your AWS usage and cost will be processed on our system.
You’ll be able to view your dashboard, charts and many more features!
  We see you have linked accounts, please proceed to connect those accounts`;
  static MODAL_FAIL_BODY = `Please review your input and re-try to connect. For any question contact Support.`;
  static MODAL_LOADING = 'We are trying to connect your account';
}

export class UserConnectionStages {
  static DATA_FILLING = 0;
  static CONNECTING = 1;
  static RESULT_SUCCESS = 2;
  static RESULT_FAIL = 3;
  static CONNECTED = 4;
  static INTERNAL_ERROR = 5;
  static RESULT_SUCCESS_LINKED_ACC = 6;
  static CHECK_FAILED = 7;
}

export const mapModalHeaderByCurrStage = new Map([
  [UserConnectionStages.CHECK_FAILED, 'We found some issues.'],
  [UserConnectionStages.CONNECTING, WIZARD_FLOW_TEXT.MODAL_LOADING],
  [UserConnectionStages.RESULT_SUCCESS, WIZARD_FLOW_TEXT.MODAL_SUCCESS_HEADER_TITLE],
  [UserConnectionStages.RESULT_SUCCESS_LINKED_ACC, WIZARD_FLOW_TEXT.MODAL_SUCCESS_HEADER_TITLE],
  [UserConnectionStages.RESULT_FAIL, WIZARD_FLOW_TEXT.MODAL_FAIL_HEADER_TITLE],
]);
export const mapModalBodyByCurrStage = new Map([
  [UserConnectionStages.RESULT_SUCCESS, WIZARD_FLOW_TEXT.MODAL_SUCCESS_BODY],
  [UserConnectionStages.RESULT_SUCCESS_LINKED_ACC, WIZARD_FLOW_TEXT.MODAL_SUCCESS_BODY_LINKED_ACC],
  [UserConnectionStages.RESULT_FAIL, WIZARD_FLOW_TEXT.MODAL_FAIL_BODY],
]);

export const pages = {
  page0: 0,
  page1: 1,
  page2: 2,
  page3: 3,
  page4_getUserInfo: 4,
  page5_lastPage_displayed: 5,
  page6_lastPage: 6,
  page7: 7,
};

export const ActiveIndexToTitleText = new Map([
  [pages.page0, WIZARD_FLOW_TEXT.INTRO_TITLE],
  [pages.page1, WIZARD_FLOW_TEXT.HOW_TO_TITLE],
  [pages.page2, WIZARD_FLOW_TEXT.STEP3_TITLE],
  [pages.page3, WIZARD_FLOW_TEXT.STEP4_TITLE],
  [pages.page4_getUserInfo, WIZARD_FLOW_TEXT.STEP5_TITLE],
  [pages.page5_lastPage_displayed, WIZARD_FLOW_TEXT.STEP6_TITLE],
  [pages.page7, WIZARD_FLOW_TEXT.STEP7_TITLE],
]);
