/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import {
  generatePileusPolicy,
  generatePileusRole,
  generateTopicConfiguration,
} from '../helpers/jsonTemplatesStringsGenerators';
import {
  NEW_INVOICE_TOPIC_FILENAME,
  PILEUS_POLICY_FILENAME,
  PILEUS_ROLE_FILENAME,
} from '../constants/newUserConstants';
import JsonDownloadBar from './JsonDownloadBar';
import { downloadFileSafari } from '../helpers/newUserHelpers';

const JsonFileObjects = [
  {
    id: 0,
    title: 'New Invoice Topic',
    fileName: NEW_INVOICE_TOPIC_FILENAME,
    jsonGen: generateTopicConfiguration,
    nodeId: 'NewInvoiceTopicConfiguration',
  },
  {
    id: 1,
    title: 'Anodot Cost Role',
    fileName: PILEUS_ROLE_FILENAME,
    jsonGen: generatePileusRole,
    nodeId: 'PileusRole',
  },
  {
    id: 2,
    title: 'Anodot Cost Policy',
    fileName: PILEUS_POLICY_FILENAME,
    jsonGen: generatePileusPolicy,
    nodeId: 'PileusPolicy',
  },
];

export default class DownloadJsonFilesContainer extends PureComponent {
  static propTypes = {
    goOnCondition: PropTypes.func.isRequired,
    invoiceBucketName: PropTypes.string.isRequired,
    bucketRegionName: PropTypes.string.isRequired,
    externalId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      filesDownloaded: [false, false, false],
    };
  }

  updateFilesDownloaded = (fileID) => {
    const currentStatus = [...this.state.filesDownloaded];
    currentStatus[fileID] = true;
    this.setState({ filesDownloaded: [...currentStatus] });
    if (this.numOfFilesDownloaded(currentStatus) === JsonFileObjects.length) {
      this.props.goOnCondition();
    }
  };

  numOfFilesDownloaded = (currentStatus) => {
    let count = 0;
    currentStatus.forEach((fileStat) => {
      count += fileStat ? 1 : 0;
    });
    return count;
  };

  downloadFileHandler = (wasClicked, fileName, id, invoiceBucketName, bucketRegionName, externalId, nodeId) => {
    downloadFileSafari(
      wasClicked,
      fileName,
      nodeId,
      JsonFileObjects[id].jsonGen({
        invoiceBucketName,
        bucketRegionName,
        externalId,
      }),
    );
  };

  downloadAllFiles = (e) => {
    e.preventDefault();
    const { invoiceBucketName, bucketRegionName, externalId } = this.props;
    JsonFileObjects.forEach((file) => {
      downloadFileSafari(
        false,
        file.fileName,
        file.nodeId,
        file.jsonGen({
          invoiceBucketName,
          bucketRegionName,
          externalId,
        }),
      );
      this.updateFilesDownloaded(file.id);
    });
  };

  render() {
    return (
      <Row>
        <Col xs="auto" md="auto" lg="auto" xl="auto">
          <h4 className="inner-wizard-items-header" style={{ 'padding-bottom': '20px' }}>
            <span>Download the following JSON Files and place them in the same directory:</span>
          </h4>
          <br />
          <h5 className="subhead">
            <a className="find-my-id" href="#" onClick={(e) => this.downloadAllFiles(e)}>
              Download all files
            </a>
          </h5>
        </Col>
        <Col xs={12} md={12} lg={12} xl={12}>
          {JsonFileObjects.map((obj) => (
            <JsonDownloadBar
              key={obj.id}
              id={obj.id}
              title={obj.title}
              fileName={obj.fileName}
              jsonGen={obj.jsonGen}
              nodeId={obj.nodeId}
              invoiceBucketName={this.props.invoiceBucketName}
              bucketRegionName={this.props.bucketRegionName}
              externalId={this.props.externalId}
              updateFilesDownloaded={this.updateFilesDownloaded}
              downloadFileHandler={this.downloadFileHandler}
            />
          ))}
        </Col>
      </Row>
    );
  }
}
