import React, { useEffect, useMemo, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import useUserRecAction from 'recommendationsNew/hooks/react-query/useUserRecAction';
import { palette } from 'shared/constants/colorsConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import classes from './recommendationsList.module.scss';
import RecommendationExcluded from './recommendationExcluded';
import RecommendationLabel from './recommendationLabel';

const BulkOperationsRow = ({ selectedRecs, reloadData, exportCsv }) => {
  const [showExcludeModal, setShowExcludeModal] = useState(false);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [recIds, setRecIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { usersStore } = useRootStore();
  const toggleMenu = (e, closeMenu = false) => {
    e.stopPropagation();
    setAnchorEl(closeMenu ? null : e.currentTarget);
  };
  const [actionType, setActionType] = useState(null);
  const [userBulkActionParams, setUserBulkActionParams] = useState({});
  const recActionHook = useUserRecAction();
  const { data: bulkUserActionStatus } = recActionHook.bulkUserAction(userBulkActionParams, {
    enabled: !!userBulkActionParams.recId,
  });

  const renderUserStatus = () => (
    <>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => {
          setAnchorEl(null);
          setUserBulkActionParams({ action: 'done', recIds });
          setActionType('done');
        }}
      >
        <div>
          <GenerateIcon iconName={ICONS.clipboardCheck.name} className={classes.iconPadding} />
          <span className={classes.menuItem}>Mark as Done</span>
        </div>
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => {
          setAnchorEl(null);
          setShowExcludeModal(true);
        }}
      >
        <div>
          <GenerateIcon iconName={ICONS.excluded.name} />
          <span className={classes.menuItem}>Mark as Excluded</span>
        </div>
      </MenuItem>
    </>
  );
  const renderStar = () => (
    <>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => {
          setAnchorEl(null);
          setUserBulkActionParams({ action: 'star', recIds });
          setActionType('star');
        }}
      >
        <div>
          <GenerateIcon iconName={ICONS.star.name} className={classes.iconPadding} />
          <span className={classes.menuItem}>Add Star</span>
        </div>
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => {
          setAnchorEl(null);
          setUserBulkActionParams({ action: 'unstar', recIds });
          setActionType('unstar');
        }}
      >
        <div>
          <GenerateIcon iconName={ICONS.starHollow.name} className={classes.iconPadding} />
          <span className={classes.menuItem}>Remove Star</span>
        </div>
      </MenuItem>
    </>
  );

  const renderExport = () => (
    <>
      <MenuItem classes={{ root: classes.menuItem }} onClick={() => setAnchorEl(null)}>
        <CustomCSVDownload
          fetchData={exportCsv}
          automationId="export-recommendations"
          showDownloadIcon
          isLoading={false}
          filesNumber={1}
          isSecondary
          hideText
          style={{
            background: 'none',
          }}
        >
          CSV
        </CustomCSVDownload>
      </MenuItem>
      {/* <MenuItem classes={{ root: classes.menuItem }} onClick={() => {}}> */}
      {/*  <div> */}
      {/*    <GenerateIcon iconName={ICONS.download.name} className={classes.iconPadding} /> */}
      {/*    <span className={classes.menuItem}>Terraform Format</span> */}
      {/*  </div> */}
      {/* </MenuItem> */}
    </>
  );

  const getLabels = () => {
    const labels = {};
    selectedRecs.forEach((rec) => {
      if (!rec.labels) {
        return;
      }
      rec.labels.split(',').forEach((label) => {
        labels[label] = [...(labels[label] || []), rec.recId];
      });
    });
    return Object.keys(labels).map((key) => ({ label: key, recIds: labels[key] }));
  };
  const labelsOptions = useMemo(() => getLabels(), [selectedRecs]);

  useEffect(() => {
    if (bulkUserActionStatus) {
      reloadData(actionType);
    }
  }, [bulkUserActionStatus]);

  useEffect(() => {
    setRecIds([...(selectedRecs || [])].map((r) => r.recId));
  }, [selectedRecs]);
  return (
    <div className={classes.bulkOperationsWrapper}>
      <Button
        isTextButton
        overrideStyles={{ color: palette.gray[500] }}
        text="User Status"
        disabled={usersStore.currentUserReadOnly}
        icon={() => <GenerateIcon iconName={ICONS.clipboardQuestion.name} />}
        onClick={(e) => {
          toggleMenu(e);
          setActionType('status');
        }}
      />
      <Button
        isTextButton
        overrideStyles={{ color: palette.gray[500] }}
        text="Star"
        icon={() => <GenerateIcon iconName={ICONS.star.name} />}
        disabled={usersStore.currentUserReadOnly}
        onClick={(e) => {
          toggleMenu(e);
          setActionType('star');
        }}
      />
      {/* <Button */}
      {/*  isTextButton */}
      {/*  text="Assignee" */}
      {/*  icon={() => <GenerateIcon iconName={ICONS.clipboardUser.name} />} */}
      {/*  onClick={(e) => { */}
      {/*    toggleMenu(e); */}
      {/*    setActionType('assignee'); */}
      {/*  }} */}
      {/* /> */}
      {/* <Button */}
      {/*  isTextButton */}
      {/*  text="Read/Unread" */}
      {/*  icon={() => <GenerateIcon iconName={ICONS.eye.name} />} */}
      {/*  onClick={(e) => { */}
      {/*    toggleMenu(e); */}
      {/*    setActionType('read'); */}
      {/*  }} */}
      {/* /> */}
      <Button
        isTextButton
        text="Export"
        overrideStyles={{ color: palette.gray[500] }}
        icon={() => <GenerateIcon iconName={ICONS.download.name} />}
        onClick={(e) => {
          toggleMenu(e);
          setActionType('export');
        }}
      />
      <Button
        isTextButton
        text="Add Label"
        overrideStyles={{ color: palette.gray[500] }}
        disabled={usersStore.currentUserReadOnly}
        icon={() => <GenerateIcon iconName={ICONS.hashtag.name} />}
        onClick={() => {
          setActionType('label');
          setShowLabelModal(true);
        }}
      />
      <Menu
        classes={{ list: classes.menu }}
        style={{ marginTop: '-20px' }}
        id="simple-menu"
        anchorEl={anchorEl}
        disabled={usersStore.currentUserReadOnly}
        open={Boolean(anchorEl)}
        onClose={(e) => toggleMenu(e, true)}
      >
        {actionType === 'status' && renderUserStatus()}
        {actionType === 'star' && renderStar()}
        {actionType === 'export' && renderExport()}
      </Menu>
      {showExcludeModal && (
        <RecommendationExcluded
          recs={selectedRecs}
          closeModal={(obj) => {
            if (obj) {
              setUserBulkActionParams({
                action: 'exclude',
                recIds,
                actionParams: obj,
              });
              setActionType('exclude');
            }
            setShowExcludeModal(false);
          }}
        />
      )}
      {showLabelModal && (
        <RecommendationLabel
          closeModal={() => setShowLabelModal(false)}
          recLabels={labelsOptions}
          recIds={recIds}
          isFromBulk
          saveLabels={(addLabels, removeLabels) => {
            if (!addLabels.length && !removeLabels.length) {
              return;
            }
            setUserBulkActionParams({
              action: 'label',
              recIds,
              actionParams: { data: { label_add: addLabels.join(','), label_delete: removeLabels.join(',') } },
            });
            setActionType('label');
          }}
        />
      )}
    </div>
  );
};

export default BulkOperationsRow;
