import React, { useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { GROUP_BY_OPTIONS, SORT_BY_OPTIONS } from 'recommendationsNew/consts';
import HeatMapCardGroupedByItem from 'recommendationsNew/components/heatMap/heatMapCards/heatMapCardGroupedByItem';
import { ReactComponent as EmptyGroupByCardData } from 'recommendationsNew/img/noData.svg';
import { HeatMapCardMoreList } from 'recommendationsNew/components/heatMap/heatMapCards/heatMapCardMoreList';
import RecommendationsNewContext from 'recommendationsNew/contexts/recommendationsNewContext';

import classes from './heatMapCard.module.scss';

const HeatMapCard = ({ groupByOption, optionData, onCardClose, sortBy, allOpenRecsData, isLastPage }) => {
  const progressPart = (item) =>
    100 *
    (sortBy === SORT_BY_OPTIONS.items[0].property
      ? item.savings / allOpenRecsData?.annualSavings
      : item.recsCount / allOpenRecsData?.recsCount);

  const { recommendationFilters: filtersContext, setRecommendationFilters: setFiltersContext } =
    useContext(RecommendationsNewContext);

  const getGroupByOptionIcon = useMemo(() => {
    const option = Object.values(GROUP_BY_OPTIONS.items).find((option) => option.id === groupByOption?.id);
    if (option) {
      return option.icon;
    }
    return undefined;
  }, [groupByOption?.id]);

  const [addedFromMoreOptions, setAddedFromMoreOptions] = useState([]);
  const cardContentRef = useRef(null);

  useLayoutEffect(() => {
    if (addedFromMoreOptions.length && cardContentRef.current) {
      cardContentRef.current.scrollTop = cardContentRef.current.scrollHeight;
    }
  }, [addedFromMoreOptions]);

  const handleOptionAddedFromMore = (addedOption) => {
    setAddedFromMoreOptions([...addedFromMoreOptions, addedOption]);
  };

  const cardGroupOptions = useMemo(
    () => (optionData ? [...optionData, ...addedFromMoreOptions] : []),
    [optionData, addedFromMoreOptions],
  );

  const onGroupedByItemClick = (item) => {
    const filterValue = filtersContext[groupByOption.id];
    const filterItemObj = { value: item.value, label: item.label };

    let newFilterValue;
    if (Array.isArray(filterValue)) {
      newFilterValue = [...filterValue];
      if (!filterValue.find((i) => i.value === item.value)) {
        newFilterValue.push(filterItemObj);
      }
    } else {
      newFilterValue = [filterItemObj];
    }
    const newContext = {
      ...filtersContext,
      [groupByOption.id]: newFilterValue,
    };
    setFiltersContext(newContext);
  };

  return (
    <div className={classes.heatMapCardContainer}>
      <div className={classes.cardContentContainer}>
        <div className={classes.cardTitleContainer}>
          <div className={classes.cardTitle}>
            {getGroupByOptionIcon ? (
              <GenerateIcon iconName={getGroupByOptionIcon} className={classes.cardIcon} />
            ) : (
              <div />
            )}
            {groupByOption.name}
          </div>
          {groupByOption.isPinned ? null : (
            <GenerateIcon
              automation-id={`heatmap-card-${groupByOption.id}-close-button`}
              iconName={ICONS.xMark.name}
              className={classes.cardClose}
              onClick={() => onCardClose(groupByOption)}
            />
          )}
        </div>
        <div
          className={`${classes.cardContent} ${cardGroupOptions?.length ? '' : `${classes.emptyCard}`}`}
          ref={cardContentRef}
        >
          {cardGroupOptions?.length ? (
            cardGroupOptions?.map((item, index) => (
              <HeatMapCardGroupedByItem
                automation-id={`heatmap-card-${groupByOption.id}-grouped-by-item-${item.label}`}
                item={item}
                itemIndex={index}
                onGroupedByItemClick={onGroupedByItemClick}
                progressPart={progressPart(item)}
              />
            ))
          ) : (
            <EmptyGroupByCardData className="m-xl-auto" />
          )}
        </div>
      </div>
      {!isLastPage ? (
        <div className={classes.cardFooterContainer}>
          <HeatMapCardMoreList
            automation-id={`heatmap-card-${groupByOption.id}-more-button`}
            groupByOptionId={groupByOption.id}
            lastOption={optionData?.[optionData?.length - 1]}
            onChange={(addedOption) => handleOptionAddedFromMore(addedOption)}
            optionsToHide={addedFromMoreOptions}
          />
        </div>
      ) : null}
    </div>
  );
};

HeatMapCard.propTypes = {
  allOpenRecsData: PropTypes.object.isRequired,
  groupByOption: PropTypes.object.isRequired,
  isLastPage: PropTypes.bool.isRequired,
  onCardClose: PropTypes.func.isRequired,
  optionData: PropTypes.array.isRequired,
  sortBy: PropTypes.string.isRequired,
};

export default HeatMapCard;
