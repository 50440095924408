/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Row } from 'reactstrap';
import ArrowLeftDropCircleOutline from 'mdi-react/ArrowLeftDropCircleOutlineIcon';
import ArrowRightDropCircleOutline from 'mdi-react/ArrowRightDropCircleOutlineIcon';
import { BudgetMonthChange } from 'usage/constants/usageConstants';
import { getMonthNameFromNumber } from 'shared/utils/dateUtil';
import NoDataFoundComponent from 'shared/components/NoDataFoundComponent';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import BudgetChart from './BudgetChart';
import NewBudgetChart from './NewBudgetChart';

class BudgetDisplay extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    usersStore: PropTypes.object.isRequired,
    overrideCurrency: PropTypes.string,
  };
  static defaultProps = {
    overrideCurrency: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      currDisplayedBudgetDate:
        props.data && props.data[0] && typeof props.data[0].monthlyStartDate === 'string'
          ? new Date(props.data[0].monthlyStartDate)
          : new Date(
              props.data && props.data[0] && props.data[0].monthlyStartDate?.getFullYear(),
              props.data && props.data[0] && props.data[0].monthlyStartDate?.getMonth(),
              1,
            ),
    };
  }

  isNewBudget = () => {
    const { usersStore } = this.props;
    return checkFeatureFlag(usersStore, ACCOUNT_FEATURES.BUDGET);
  };
  dateFormat = (date) => `${date?.getFullYear()}-${date?.getMonth()}`;

  handleChangeMonth = (direction) => {
    const budget = this.props.data[0];
    const prevDisaplyedBudgetDate = new Date(this.state.currDisplayedBudgetDate);
    if (direction === BudgetMonthChange.PREVIOUS) {
      const updatedDisaplyedBudgetDate = new Date(
        prevDisaplyedBudgetDate.setMonth(prevDisaplyedBudgetDate.getMonth() - 1),
      );
      const index = budget.previousBudgets
        ? budget.previousBudgets.findIndex(
            (currBudget) =>
              this.dateFormat(currBudget && currBudget.monthlyStartDate) ===
              this.dateFormat(updatedDisaplyedBudgetDate),
          )
        : -1;
      if (index > -1) {
        this.setState({ currDisplayedBudgetDate: updatedDisaplyedBudgetDate });
      }
    } else if (direction === BudgetMonthChange.NEXT) {
      const updatedDisaplyedBudgetDate = new Date(
        prevDisaplyedBudgetDate.setMonth(prevDisaplyedBudgetDate.getMonth() + 1),
      );
      const index = budget.previousBudgets
        ? budget.previousBudgets.findIndex(
            (currBudget) =>
              this.dateFormat(currBudget && currBudget.monthlyStartDate) ===
              this.dateFormat(updatedDisaplyedBudgetDate),
          )
        : -1;
      if (index > -1 || this.dateFormat(budget.monthlyStartDate) === this.dateFormat(updatedDisaplyedBudgetDate)) {
        this.setState({ currDisplayedBudgetDate: updatedDisaplyedBudgetDate });
      }
    }
  };
  handleIsDiaplayArrow = (budget) => {
    if (!budget.previousBudgets) {
      return false;
    }
    return true;
  };

  handlePresentedBudget = () => {
    const { data } = this.props;
    const { currDisplayedBudgetDate } = this.state;
    const budget = data[0];

    if (this.dateFormat(currDisplayedBudgetDate) === this.dateFormat(budget.monthlyStartDate)) {
      return budget;
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < budget.previousBudgets?.length; i++) {
      if (this.dateFormat(budget.previousBudgets[i].monthlyStartDate) === this.dateFormat(currDisplayedBudgetDate)) {
        return budget.previousBudgets[i];
      }
    }
    return null;
  };

  render() {
    const { data, overrideCurrency } = this.props;
    const { currDisplayedBudgetDate } = this.state;
    if (!data || !data[0]) {
      return <NoDataFoundComponent bodyText=" " />;
    }
    return (
      <div>
        {this.isNewBudget() ? (
          <NewBudgetChart budget={data[0]} />
        ) : (
          <>
            <Row className="budgets-nevigate-row">
              <ArrowLeftDropCircleOutline
                color="#646777"
                className="arrow-btn"
                onClick={() => this.handleChangeMonth(BudgetMonthChange.PREVIOUS)}
              />
              <h5 className="month-name">
                {getMonthNameFromNumber(currDisplayedBudgetDate.getMonth() + 1)},{' '}
                {currDisplayedBudgetDate.getFullYear()}
              </h5>
              <ArrowRightDropCircleOutline
                color="#646777"
                className="arrow-btn"
                onClick={() => this.handleChangeMonth(BudgetMonthChange.NEXT)}
              />
            </Row>
            <BudgetChart
              budget={this.handlePresentedBudget()}
              currMonthlyUsage={this.handlePresentedBudget()?.currTotalCost}
              currDisplayedBudgetDate={currDisplayedBudgetDate}
              overrideCurrency={overrideCurrency}
            />
          </>
        )}
      </div>
    );
  }
}

const ObserverBudgetDisplay = observer(BudgetDisplay);
export default ObserverBudgetDisplay;
