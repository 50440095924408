import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';

export default class WizardIntro extends Component {
  constructor(props) {
    super(props);
    this.inAnim = false;
  }

  componentDidUpdate() {
    this.inAnim = true;
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <Col xs="auto" md="auto" lg="auto" xl="auto">
              <p className="py-2">
                <span className="inner-wizard-input-legend h-auto">Link your account in several main steps</span>
              </p>
              <p className="py-2 m-0">
                <h5 className="inner-wizard-input-legend h-auto">
                  <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
                  <span style={{ marginLeft: '10px' }}>Authorization to read the account billing data</span>
                </h5>
              </p>
              <p className="py-2 m-0">
                <h5 className="inner-wizard-input-legend h-auto">
                  <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
                  <span style={{ marginLeft: '10px' }}>
                    Authorization to read resources utilization data inorder to provide savings recommendations
                    (optional)
                  </span>
                </h5>
              </p>
              <p className="py-2 m-0">
                <h5 className="inner-wizard-input-legend h-auto">
                  <span style={{ marginLeft: '10px' }}>
                    You will have full visibility 24 hours after you completed the onboarding process
                  </span>
                </h5>
              </p>
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}
