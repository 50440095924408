/* eslint-disable no-await-in-loop */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Spinner from 'shared/components/andtComponents/Spinner';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import {
  AZURE_BILLING_HISTORY_TABLE_COLUMNS,
  AZURE_BILLING_HISTORY_TABLE_COLUMNS_WIDTHS,
} from 'divisions/constants/customersConstants';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import DateFilter from 'shared/modules/dateFilter';
import {
  buildStartAndEndDate,
  buildTimeDiffDateFromBaseDate,
  createTimeZoneAgnosticDateFromStr,
} from 'shared/utils/dateUtil';
import PileusLogo from 'shared/img/login_logo_plain.png';
import AwsLogo from 'shared/img/cloud-providers/aws_logo.jpeg';
import CustomersHistoryTable from './components/CustomersHistoryTable';
import DownloadDropdown from './components/DownloadDropdown';
import ExplainFlexibilityModal from '../Customers/awsCustomers/components/ExplainFlexibilityModal';
import {
  FLEXIBILITY_TABLE_COLUMNS,
  FLEXIBILITY_TABLE_COLUMNS_WIDTHS,
  FLEXIBILITY_TABLE_COST_COLUMNS,
} from '../../constants/customersConstants';

export const BILLING_HISTORY_TABLE_COLUMNS = [
  {
    name: 'customerName',
    title: 'Customer Name',
    getCellValue: (row) => LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', row.customerName),
  },
  {
    name: 'month',
    title: 'Month',
    getCellValue: (row) => {
      const date = new Date(row.year, row.month - 1, 1);
      return date.toLocaleDateString(undefined, { month: 'long' });
    },
  },
  {
    name: 'year',
    title: 'Year',
  },
  { name: 'mtdResellerCost', title: 'Reseller Cost' },
  { name: 'mtdCustomerCost', title: 'Customer Cost' },
  { name: 'totalMarginCost', title: 'Total Margin' },
  { name: 'riFlexibilityMargin', title: 'RI Margin' },
  { name: 'spFlexibilityMargin', title: 'SP Margin' },
  { name: 'publicPriceMargin', title: 'Tiers Margin' },
  { name: 'billingRuleMargin', title: 'Billing Rule Margin' },
  { name: 'creditMargin', title: 'AWS Credits' },
  { name: 'resellerCredit', title: 'Reseller Credit' },
];

class CustomersHistoryPage extends Component {
  static propTypes = {
    divisionsStore: PropTypes.object.isRequired,
    usersStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isProcessing: false,
      startDate: this.getInitialDate().startDate,
      endDate: this.getInitialDate().endDate,
      resultStrings: [],
      currCustomerCreatedData: {},
      password: '',
      email: '',
      isCustomerCreateSuccess: false,
      isFetchingExplainData: false,
      explainMeModalIsOpen: false,
      explainMeModaForType: '',
      selectedRows: [],
      isLoadingPdf: false,
      isLoadingCsv: false,
      // eslint-disable-next-line max-len
      currDataModelByCloudType:
        props.usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS
          ? 'awsCustomersModel'
          : 'azureCustomersModel',
    };
  }

  async componentDidMount() {
    try {
      const { divisionsStore } = this.props;
      const { startDate, endDate, currDataModelByCloudType } = this.state;
      this.setState({ dataIsLoading: true });
      await divisionsStore[currDataModelByCloudType].getCustomersHistoryData(
        buildStartAndEndDate(startDate, null).startDate,
        buildStartAndEndDate(null, endDate).endDate,
      );
      this.setState({ dataIsLoading: false });
    } catch (error) {
      this.setState({ dataIsLoading: false });
    }
  }

  getInitialDate = () => {
    let { startDate, endDate } = buildStartAndEndDate(null, null);
    const month = new Date(startDate).getUTCMonth();
    const year = new Date(startDate).getUTCFullYear();
    const rawStartDate = new Date(year, month - 1, 1);
    const rawEndDate = new Date(year, month, 0);
    startDate = createTimeZoneAgnosticDateFromStr(buildStartAndEndDate(rawStartDate, null).startDate);
    endDate = createTimeZoneAgnosticDateFromStr(buildStartAndEndDate(null, rawEndDate).endDate);
    return { startDate, endDate };
  };

  getTableColumnsAndWidths = () => {
    const cloutTypeId = this.props.usersStore.currDispUserCloudAccountType;
    if (cloutTypeId === CLOUD_TYPE_IDS.AZURE) {
      return {
        tableColumns: AZURE_BILLING_HISTORY_TABLE_COLUMNS,
        columnWidths: AZURE_BILLING_HISTORY_TABLE_COLUMNS_WIDTHS,
      };
    }
    return {
      tableColumns: BILLING_HISTORY_TABLE_COLUMNS,
      columnWidths: FLEXIBILITY_TABLE_COLUMNS_WIDTHS(this.state.explainMeModaForType),
    };
  };

  initiateCsvsDownload = () => {
    this.setState({ isLoadingCsv: true });
  };

  datePickerSyntexDates = (start, end) => {
    let { startDate, endDate } = buildStartAndEndDate(start || '', end || '');
    startDate = createTimeZoneAgnosticDateFromStr(startDate);
    endDate = createTimeZoneAgnosticDateFromStr(endDate);
    return { startDate, endDate };
  };

  handleDateChange = async ({ startDate, endDate }) => {
    const start = startDate || this.state.startDate;
    let end = endDate || this.state.endDate;
    if (start > end && end !== null) {
      end = start;
    }
    const modStartDate = buildStartAndEndDate(start, end).startDate;
    const modEndDate = buildStartAndEndDate(start, end).endDate;
    this.setState({
      startDate: createTimeZoneAgnosticDateFromStr(modStartDate),
      endDate: createTimeZoneAgnosticDateFromStr(modEndDate),
    });
    this.setState({ dataIsLoading: true });
    await this.props.divisionsStore[this.state.currDataModelByCloudType].getCustomersHistoryData(
      modStartDate,
      modEndDate,
    );
    this.setState({ dataIsLoading: false });
  };
  toggleExplainMeModal = () => {
    this.setState((prevState) => ({
      explainMeModalIsOpen: !prevState.explainMeModalIsOpen,
    }));
  };
  handleDownloadPdf = async (divisionId, divisionName, accountKey, startDate, endDate) => {
    const data = await this.props.divisionsStore.getCustomerCostAndUsageData(
      divisionId,
      divisionName,
      accountKey,
      startDate,
      endDate,
    );
    return data;
  };
  handleExplainCustomerFlexibilityMargin = async ({
    e,
    divisionId,
    divisionName,
    accountKey,
    flexibilityType,
    month,
    year,
  }) => {
    e.preventDefault();
    const rowStartDate = new Date(year, month - 1, 1); // date of first day for row's month
    const rowEndtDate = new Date(year, month, 0); // date of last day for row's month
    const { startDate, endDate } = buildStartAndEndDate(rowStartDate, rowEndtDate); // format dates for API call
    try {
      this.setState({ isFetchingExplainData: true });
      await this.props.divisionsStore.fetchExplainCustomerFlexibilityMarginData(
        divisionId,
        divisionName,
        accountKey,
        flexibilityType,
        startDate,
        endDate,
      );
      this.setState({
        isFetchingExplainData: false,
        explainMeModalIsOpen: true,
        explainMeModaForType: flexibilityType,
      });
    } catch (error) {
      this.setState({ isFetchingExplainData: false, explainMeModalIsOpen: false });
    }
  };
  prepareCsvModifiedRows = (data) => {
    let csvModifiedRows = JSON.parse(JSON.stringify(data));
    csvModifiedRows = csvModifiedRows.map((row) => {
      const currRow = row;
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(currRow)) {
        if (['accountKey', 'divisionId', 'divisionTypeId'].includes(key)) {
          delete currRow[key];
        }
        if (key === 'customerName') {
          currRow[key] = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', value) || value;
        }
      }
      return currRow;
    });
    return csvModifiedRows;
  };

  selectedRowsUpdate = (selectedRows) => {
    this.setState({ selectedRows });
  };

  downloadSelectedPdfs = async () => {
    const { divisionsStore } = this.props;
    const { currDataModelByCloudType, selectedRows } = this.state;
    this.setState({ isLoadingPdf: true });
    const rows = selectedRows.map((r) => {
      const { divisionId, customerName, accountKey, month, year } =
        divisionsStore[currDataModelByCloudType].customerHistoryTableData[r];
      const customerDisplayName = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', customerName);
      return {
        divisionId,
        customerName,
        customerDisplayName,
        accountKey,
        month,
        year,
      };
    });
    await divisionsStore.getResellerCustomerInvoiceData(rows, 'pdf');
    this.setState({ isLoadingPdf: false });
  };

  prepareSelectedCsvs = async () => {
    const { divisionsStore } = this.props;
    const { currDataModelByCloudType, selectedRows } = this.state;
    this.setState({ isLoadingCsv: true });
    const rows = selectedRows.map((r) => {
      const { divisionId, customerName, accountKey, month, year } =
        divisionsStore[currDataModelByCloudType].customerHistoryTableData[r];
      return {
        divisionId,
        customerName,
        accountKey,
        month,
        year,
      };
    });
    await divisionsStore.getResellerCustomerInvoiceData(rows, 'csv');
    this.setState({ isLoadingCsv: false });
  };

  render() {
    const { divisionsStore } = this.props;
    const {
      startDate,
      endDate,
      selectedRows,
      isLoadingPdf,
      isLoadingCsv,
      explainMeModaForType,
      currDataModelByCloudType,
      dataIsLoading,
      explainMeModalIsOpen,
      isFetchingExplainData,
    } = this.state;
    if (divisionsStore.isLoading) {
      return <Spinner />;
    }
    const lastProcessTime = DateFilter.getDate();
    const maxDate = this.datePickerSyntexDates(
      null,
      buildTimeDiffDateFromBaseDate(lastProcessTime, -30, 'd').startDate,
    ).endDate;

    const tableData = divisionsStore[currDataModelByCloudType].customerHistoryTableData;
    const { tableColumns } = this.getTableColumnsAndWidths();
    const explainFlexibilityTableData = divisionsStore.getExplainCustomerFlexibilityMarginData(explainMeModaForType);

    return (
      <Container>
        <PageHeader title={PageNames.CUSTOMERS_HISTORY} />
        <Card>
          <CardBody>
            <DatePickerFilter
              onDateChange={this.handleDateChange}
              startDate={startDate}
              endDate={endDate}
              currPeriodGranLevel="month"
              maxDate={maxDate}
            />
            {dataIsLoading ? (
              <Spinner />
            ) : tableData && tableData.length ? (
              <div style={{ marginTop: '10px' }}>
                <Row style={{ width: '100%' }}>
                  {selectedRows.length ? (
                    <Col style={{ display: 'flex', justifyContent: 'flex-start', minWidth: '300px' }}>
                      <DownloadDropdown
                        downloadCsv={this.prepareSelectedCsvs}
                        downloadPdf={this.downloadSelectedPdfs}
                        isLoadingPdf={isLoadingPdf}
                        isLoadingCsv={isLoadingCsv}
                        filesNumber={(selectedRows || []).length}
                      />
                    </Col>
                  ) : (
                    <div style={{ height: '45px' }} />
                  )}
                  <Col style={{ display: 'flex', justifyContent: 'flex-end', minWidth: '300px' }}>
                    <CSVLink
                      data={this.prepareCsvModifiedRows(tableData)}
                      headers={BILLING_HISTORY_TABLE_COLUMNS.name}
                      filename="billing-history.csv"
                      className="biling-history-csv-btn"
                    >
                      Export as CSV
                      <span
                        className="lnr lnr-download"
                        style={{ fontSize: '18px', marginLeft: '8px', marginBottom: '8px' }}
                      />
                    </CSVLink>
                  </Col>
                </Row>
                <CustomersHistoryTable
                  tableData={tableData}
                  handleDownloadPdf={this.handleDownloadPdf}
                  handleExplainCustomerFlexibilityMargin={this.handleExplainCustomerFlexibilityMargin}
                  pdfRightLogo={AwsLogo}
                  pdfLeftLogo={PileusLogo}
                  selectedRowsUpdate={this.selectedRowsUpdate}
                  tableColumns={tableColumns}
                />
                {isFetchingExplainData && <Spinner />}
                {explainMeModalIsOpen && (
                  <ExplainFlexibilityModal
                    isOpen={explainMeModalIsOpen}
                    toggle={this.toggleExplainMeModal}
                    modalType={explainMeModaForType}
                    tableData={explainFlexibilityTableData}
                    tableColumns={FLEXIBILITY_TABLE_COLUMNS(explainMeModaForType)}
                    columnWidths={FLEXIBILITY_TABLE_COLUMNS_WIDTHS(explainMeModaForType)}
                    costColumnsArray={FLEXIBILITY_TABLE_COST_COLUMNS}
                  />
                )}
              </div>
            ) : null}
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const ObserverCustomersHistoryPage = observer(CustomersHistoryPage);
export default ObserverCustomersHistoryPage;
