import React from 'react';
import PropTypes from 'prop-types';
import { BASE_PROPERTIES, COST_PROPERTIES } from '../../recommendationPropertyUtils';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import RecommendationCommand from '../../recommendationGenericComponents/recommendationCommand';
import { Y_AXIS_UNIT } from '../../recommendationGenericComponents/recommendationChart/chartConsts';
import RecommendationChartData, {
  CHART_TYPES,
} from '../../recommendationGenericComponents/recommendationChart/recommendationChartData';

const EBS_TYPE_CHANGE_PROPERTIES = {
  CUSTOMER: {
    label: 'Customer',
    getterFunction: (recommendation) => recommendation?.recData?.customer,
  },
  EBS_SAVING: {
    label: 'EBS Saving',
    isCurrency: true,
    getterFunction: (recommendation) => recommendation?.recData?.size_potential_savings,
  },
  IOPS_SAVING: {
    label: 'IOPS Saving',
    isCurrency: true,
    getterFunction: (recommendation) => recommendation?.recData?.iops_potential_savings,
  },
  THROUGHPUT_SAVING: {
    label: 'Stop Time',
    isCurrency: true,
    getterFunction: (recommendation) => recommendation?.recData?.throughput_potential_savings,
  },
  VOLUME_NAME: {
    label: 'Volume Name',
    getterFunction: (recommendation) => recommendation?.recData?.ebs_name,
  },
  EBS_ID: {
    label: 'EBS ID',
    getterFunction: (recommendation) => recommendation?.recData?.ebs_id,
  },
  EBS_TYPE: {
    label: 'EBS Type',
    getterFunction: (recommendation) => recommendation?.recData?.ebs_type,
  },
  NEW_EBS_TYPE: {
    label: 'New EBS Type',
    getterFunction: (recommendation) => recommendation?.recData?.new_ebs_type,
  },
  NEW_TYPE_SIZE: {
    label: 'New Type Size',
    isGBSize: true,
    getterFunction: (recommendation) =>
      recommendation.typeId === 'ebs-type-and-size-change' &&
      recommendation?.recData?.[`${recommendation?.recData?.new_ebs_type}_size`],
  },
  MAX_IOPS: {
    label: 'Max IOPS',
    getterFunction: (recommendation) => recommendation?.recData?.max_iops,
  },
};

const EbsTypeChange = ({ recommendation }) => {
  const properties = {
    ...EBS_TYPE_CHANGE_PROPERTIES,
    ...COST_PROPERTIES,
    ...BASE_PROPERTIES,
  };

  const description = `We recommend you to change this EBS type ${
    recommendation?.recData?.subtype === 'change_size' ? 'and size ' : ''
  }${recommendation?.recData?.attention_comment ? 'and IOPS ' : ''}to reduce costs and to suit your performance needs.`;

  const insideLookComponent = <RecommendationInsideLook description={description} />;

  const otherResourceIdRecComponent = recommendation.resourceId ? (
    <OtherResourceIdRecommendations resourceId={recommendation.resourceId} />
  ) : null;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const actionCommand = recommendation?.recData?.attention_comment
    ? recommendation?.recData?.command
    : `aws ec2 modify-volume --volume-type ${recommendation?.recData?.new_ebs_type} ${
        recommendation?.typeId === 'ebs-type-and-size-change'
          ? `--size  ${recommendation?.recData?.[`${recommendation?.recData?.new_ebs_type}_size`]}`
          : ''
      } --volume-id  ${recommendation?.recData?.ebs_id}`;

  const commandComponent = <RecommendationCommand cliInstructions={[{ actionText: '', actionCommand }]} />;

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  const chartsData = [
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'IOPS Performance',
      yAxisLabel: 'IOPS',
      maxDataProperty: recommendation.recData?.max_iops_data,
    },
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'Throughput Performance',
      yAxisLabel: 'Throughput',
      yAxisUnit: Y_AXIS_UNIT.BYTE,
      maxDataProperty: recommendation.recData?.max_throughput_data,
    },
  ];

  const chartsComponent = <RecommendationChartData chartsData={chartsData} />;

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      otherResourceIdRecommendations={otherResourceIdRecComponent}
      details={detailsComponent}
      command={commandComponent}
      preferences={preferencesComponent}
      charts={chartsComponent}
    />
  );
};

EbsTypeChange.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default EbsTypeChange;
