/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import ICONS from 'shared/constants/assetsConstants';
import IconFromPng from 'shared/components/IconFromPng';
import Collapse from 'shared/components/Collapse';
import JsonDownlad from './JsonDownlad';
import { downloadFile } from '../helpers/newUserHelpers';

const JsonDownloadBar = memo(
  ({
    id,
    fileName,
    jsonGen,
    title,
    updateFilesDownloaded,
    invoiceBucketName,
    bucketRegionName,
    downloadFileHandler,
    externalId,
    nodeId,
  }) => (
    <Row style={{ padding: '5px 0px', margin: '10px 0px' }}>
      <Col className="filter-button-inner-button" xs="auto" md="auto" lg="auto" xl="auto">
        <LinkIconButton>
          <a
            id={nodeId}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              // downloadFileHandler(true, fileName, `${id}`, invoiceBucketName, bucketRegionName, externalId, nodeId);
              downloadFile(fileName, jsonGen({ invoiceBucketName, bucketRegionName, externalId }));
              updateFilesDownloaded(id);
              return null;
            }}
          />
          <IconFromPng size={15} icon={ICONS.download} />
        </LinkIconButton>
        {/* <div
        style={{
          display: 'table',
          width: '100%',
          height: '80%',
        }}
      >
        <h4 style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'end' }}>
          <a
            href="#"
            onClick={() => {
              downloadFile(fileName, jsonGen({ invoiceBucketName, bucketRegionName }));
              updateFilesDownloaded(id);
              return null;
            }}
          >
            <span className="lnr lnr-download" />
          </a>
        </h4>
      </div> */}
      </Col>
      <Col xs={10} md={10} lg={10} xl={10}>
        <Collapse title={title}>
          <JsonDownlad id={1} jsonString={jsonGen({ invoiceBucketName, bucketRegionName, externalId })} />
        </Collapse>
      </Col>
    </Row>
  ),
);

JsonDownloadBar.propTypes = {
  fileName: PropTypes.string.isRequired,
  jsonGen: PropTypes.func.isRequired,
  updateFilesDownloaded: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  invoiceBucketName: PropTypes.string.isRequired,
  bucketRegionName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default JsonDownloadBar;
