import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';
import { ICONS, GenerateIcon, UserSettingsContext } from '@pileus-cloud/anodot-frontend-common/dist';
import ListItem from '@mui/material/ListItem';
import { mapUserTypeToRoute } from 'shared/constants/routesMapping';
import MenuWrapper from 'app/containers/Layout/topNavbar/components/MenuWrapper';
import TopBarSearch from 'shared/components/TopBarSearch';
import AdminAccountItem from 'users/containers/Admin/components/AdminAccountItem';
import AdminParentAccountItem from 'users/containers/Admin/components/AdminParentAccountItem';
import classes from '../TopNavBar.module.scss';

class UserPicker extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  static contextType = UserSettingsContext;
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      searchInput: '',
      filteredList: [], // once moving to parent-children users this can be removed
    };
  }

  componentDidMount() {
    const { store } = this.props;
    const usersProp = this.prepareIntitialUsersList(store.usersModel.users); // first time setup
    const filteredList = usersProp;
    this.setState({ filteredList });

    window.selectUser = this.handleUserClicked;
    window.afterSelectUser = () => {
      // make sure dropdown is closed
      const userSettingsContext = this.context;
      userSettingsContext.toggleOpen();
    };
  }

  // HANDLERS //

  toggle = () => {
    const { store } = this.props;
    const usersProp = this.prepareIntitialUsersList(store.usersModel.users); // clear all like first time setup
    const filteredList = usersProp;
    this.setState((prevState) => ({ collapse: !prevState.collapse, filteredList, searchInput: '' }));
  };
  handleUserClicked = async (key) => {
    try {
      const userSettingsContext = this.context;
      await this.props.store.handleDisplayedUserChange(key);
      this.toggle();
      userSettingsContext.toggleOpen();
      const userType = this.props.store.currentDisplayedUserType;
      // const nextRoutePath = Routes.DASHBOARD;
      const nextRoutePath = mapUserTypeToRoute.get(userType);
      this.props.history.push(nextRoutePath);
    } catch (error) {
      console.log({ error });
    }
  };

  handleSearchChange = (e) => {
    const searchValue = e.target.value;
    const filteredList = this.filterUserList(this.props.store.usersModel.users, searchValue);
    this.setState({ searchInput: searchValue, filteredList });
  };

  updateCtgOpenStatus = (userKey, isOpen) => {
    if (userKey === undefined) {
      return;
    }
    const { filteredList } = this.state;
    const userKeyIndex = this.findUserIndexByUserKey(filteredList, userKey);
    filteredList[userKeyIndex].isCtgOpen = isOpen;
    this.setState({ filteredList });
  };

  // PREPARES //

  prepareIntitialUsersList = (users) => {
    // check on list without admin user - parent of all users
    // check all non childs that have parents if they are in parent
    if (!users || users.length === 0) {
      return [];
    }
    const arrUsersWithoutAdmin = users.slice(1, users.length);
    const arrParentUsers = arrUsersWithoutAdmin.filter((usr) => usr.isParent);
    // check only childs that are not parents and not a direct child of Admin
    const childsWithoutParent = arrUsersWithoutAdmin.filter(
      (usr) => !usr.isParent && usr.parentUserKey === users[0].userKey,
    );
    const consolidatedArr = [users[0], ...arrParentUsers, ...childsWithoutParent];
    const finalList = this.prepareChildUsersList(consolidatedArr);
    return finalList;
  };
  prepareChildUsersList = (users, asSecondary = false) => {
    if (!users || users.length === 0) {
      return [];
    }
    const finalList = users.map((user) => ({
      isParent: user.isParent,
      userDisplayName: user.userDisplayName,
      userKey: user.userKey,
      parentUserKey: user.parentUserKey,
      childs: user.childs || [],
      isCtgOpen: false,
      isSecondary: asSecondary,
      size: user.isParent ? 30 : 30,
      checkIfMatchFilterCriteria: user.checkIfMatchFilterCriteria,
      getUserDisplayName: user.getUserDisplayName,
    }));
    return finalList;
  };

  findUserIndexByUserKey = (users, userKey) => {
    const indx = users.map((usr) => usr.userKey).indexOf(userKey);
    return indx;
  };

  prepareFilteredListWithChild = (users) => {
    const setOfOpenUserKeys = new Set(users.filter((usr) => usr.isCtgOpen).map((usr) => usr.userKey));
    if (setOfOpenUserKeys.size > 0) {
      setOfOpenUserKeys.forEach((currUserKey) => {
        const currIndex = this.findUserIndexByUserKey(users, currUserKey);
        if (currIndex > -1 && users[currIndex].childs.length > 0) {
          const asSecondary = true;
          const prepChildsArr = this.prepareChildUsersList(users[currIndex].childs, asSecondary);
          const firstPart = users.slice(0, currIndex + 1);
          const lastPart = users.slice(currIndex + 1, users.length);
          // eslint-disable-next-line no-param-reassign
          users = [...firstPart, ...prepChildsArr, ...lastPart];
        }
      });
    }
    return users;
  };

  filterUserList = (users, searchValue) => {
    if (!users || users.length === 0) {
      return [];
    }
    if (searchValue.length === 0) {
      return this.prepareIntitialUsersList(users);
    }
    const result = users.filter((usr) => usr.checkIfMatchFilterCriteria(searchValue));
    return result;
  };

  // RENDERS //

  renderRow = (props) => {
    const { index, style, data } = props;
    const item = data[index];
    const accItemOrParentItem =
      item.childs.length === 0 ? (
        <AdminAccountItem id={index} key={index} style={style} user={item} handleUserClicked={this.handleUserClicked} />
      ) : (
        <AdminParentAccountItem
          index={index}
          style={style}
          updateCtgOpenStatus={this.updateCtgOpenStatus}
          user={item}
          handleUserClicked={this.handleUserClicked}
        />
      );
    const returnItem = (
      <ListItem key={index} role={undefined} dense button style={props.style}>
        {accItemOrParentItem}
      </ListItem>
    );
    return returnItem;
  };

  renderUsersList = () => {
    const { filteredList } = this.state;
    this.prepareFilteredListWithChild(filteredList);
    const users = this.prepareFilteredListWithChild(filteredList);
    if (!this.props.store.usersModel.users || this.props.store.usersModel.users.length < 2) {
      return <></>;
    }
    return (
      <div className="userAccounts" id="accountUsersEnv">
        <div className="topbar__menu">
          <MenuWrapper
            isTopbar
            id="topbar_users_list_toggler"
            title="Users"
            iconSize="12px"
            matchHeight
            icon={<GenerateIcon iconName={ICONS.userSecret.name} className={classes.icon} />}
          >
            <TopBarSearch
              currentValue={this.state.searchInput}
              handler={this.handleSearchChange}
              id="topbar_user_search_panel"
              placeholder="Search users"
            />
            <FixedSizeList itemData={users} height={200} width="auto" itemSize={30} itemCount={users.length}>
              {this.renderRow}
            </FixedSizeList>
          </MenuWrapper>
        </div>
      </div>
    );
  };

  render() {
    return this.renderUsersList();
  }
}

export default UserPicker;
