import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { ClickAwayListener } from '@mui/material';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import styles from './InfoPopover.module.scss';
import Tooltip from '../Tooltip';

const InfoPopover = ({ title, children, isSimple, width, height, placement }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  if (isSimple) {
    return (
      <Tooltip title={children}>
        <span className={styles.infoIcon} onClick={() => setOpen(!open)} data-simple data-info-popover="true">
          <InfoIcon />
        </span>
      </Tooltip>
    );
  }
  const getPlacement = () => {
    const pos = ref.current?.getBoundingClientRect();
    switch (placement) {
      case 'left':
        return {
          top: height ? pos.top - height / 2 : pos.top / 2,
          left: ref.current?.getBoundingClientRect().left - 20 - width,
        };
      case 'right':
        return {
          top: height ? pos.top - height / 2 : pos.top / 2,
          left: ref.current?.getBoundingClientRect().left + 60,
        };
      default:
        return {};
    }
  };
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={styles.container}>
        <span className={styles.infoIcon} onClick={() => setOpen(true)} data-active={open} ref={ref}>
          <InfoIcon />
        </span>
        {open &&
          createPortal(
            <div className={styles.popover} style={{ ...getPlacement(), width }}>
              <div className={styles.popoverHeader}>
                {title}
                <div className={styles.closeIcon} onClick={() => setOpen(false)}>
                  <GenerateIcon iconName={ICONS.xMark.name} />
                </div>
              </div>
              <div>{children}</div>
            </div>,
            document.getElementById('root'),
          )}
      </div>
    </ClickAwayListener>
  );
};

InfoPopover.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  isSimple: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.number,
  placement: PropTypes.string,
};

InfoPopover.defaultProps = {
  height: undefined,
  width: '300px',
  title: '',
  isSimple: true,
  placement: '',
};

export default InfoPopover;
