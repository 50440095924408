/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

const ReadOnlyDisplayWrapper = ({ userReadOnly, isHide, children }) => {
  const disabledStyle = { pointerEvents: 'none', opacity: '0.4' };
  const hideStyle = { display: 'none' };
  const getStyle = () => {
    let style = {};
    if (userReadOnly) {
      style = isHide ? hideStyle : disabledStyle;
    }
    return style;
  };
  return <div style={getStyle()}>{children}</div>;
};

ReadOnlyDisplayWrapper.propTypes = {
  children: PropTypes.object.isRequired,
  userReadOnly: PropTypes.bool.isRequired,
  isHide: PropTypes.bool,
};
ReadOnlyDisplayWrapper.defaultProps = {
  isHide: true,
};

export default ReadOnlyDisplayWrapper;
