import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { adaptV4Theme, createTheme } from '@mui/material/styles';
import { BrowserRouter, withRouter } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'bootstrap/dist/css/bootstrap.css';
import RootStore from 'app/store/rootStore';
import * as fsHandler from 'shared/modules/fullStoryHandler';
import { ModelNames } from 'shared/constants/appConstants';
import ApiGateway from 'shared/modules/apiGateway';
import CommonApi from 'shared/api/commonApi';
import InvoicesApi from 'invoices/api/invoicesApi';
import UsageApi from 'usage/api/usageApi';
import CommitmentApi from 'commitment/api/commitmentApi';
import DivisionsApi from 'divisions/api/divisionsApi';
import KubernetesApi from 'kubernetes/api/kubernetesApi';
import CustomDashboardApi from 'usage/containers/CustomDashboard/api/customDashboardApi';
import { withRootStoreContextProvider } from 'app/contexts/RootStoreContext';
import { withInvoiceFiltersContextProvider } from 'invoices/contexts/InvoiceFiltersContext';
import { signOut, UsersApi } from 'users/api/usersApi';
import { createCustomHeaders } from 'shared/utils/apiUtil';
import { checkIfTokenRefreshNeeded } from 'shared/utils/tokenUtil';
import Spinner from 'shared/components/andtComponents/Spinner';

// models
import UsersModel from 'users/models/usersModel';
import BudgetModel from 'usage/models/budgetModel';
import AnomalyDetectionModel from 'usage/models/anomalyDetectionModel';
import BigQueryModel from 'usage/models/bigQueryModel';
import CostAndUsageAlertsModel from 'usage/models/costAndUsageAlertsModel';
import CustomDashboardModel from 'usage/store/subStores/customDashBoard/customDashboardModel';
import CommitmentModel from 'commitment/models/commitmentModel';
import IdleInstancesModel from 'usage/models/idleInstancesModel';
import KubernetesModel from 'kubernetes/models/kubernetesModel';
import AwsCustomersModel from 'divisions/models/awsCustomersModel';
import AzureCustomersModel from 'divisions/models/azureCustomersModel';
import { UserSettingsProvider } from 'users/utils/contexts/UserSettingsContext';
import config from 'config';

// Layout & Scss
import '../../../scss/app.scss';
import ScrollToTop from './ScrollToTop';
// Routing
import Router from './Router';
import CustomThemeApplier from './components/CustomThemeApplier';

fsHandler.init();

const muiTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#2671FF',
      },
      secondary: {
        main: 'rgba(0, 0, 0, 0.87)',
      },
    },
    overrides: {
      MuiTableCell: {
        root: {
          padding: 4,
        },
      },
      MuiTableSortLabel: {
        root: {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
      Table: {
        stickyTable: {
          zIndex: 0,
        },
      },
      MuiTableRow: {
        root: {
          height: 48,
        },
      },
    },
  }),
);

const createApiGateway = () => {
  const commonApi = new CommonApi();
  const invoicesApi = new InvoicesApi();
  const usersAPI = new UsersApi();
  const usageApi = new UsageApi();
  const divisionsApi = new DivisionsApi();
  const kubernetesApi = new KubernetesApi();
  const commitmentApi = new CommitmentApi();
  const customDashboardApi = new CustomDashboardApi();
  const apiGateway = new ApiGateway(
    commonApi,
    invoicesApi,
    usersAPI,
    usageApi,
    divisionsApi,
    kubernetesApi,
    commitmentApi,
    customDashboardApi,
  );
  return apiGateway;
};

const createModels = (apiGateway) => {
  const usersModel = new UsersModel(apiGateway);
  const budgetModel = new BudgetModel(apiGateway);
  const costAndUsageAlertsModel = new CostAndUsageAlertsModel(apiGateway);
  const anomalyDetectionModel = new AnomalyDetectionModel(apiGateway);
  const bigQueryModel = new BigQueryModel(apiGateway);
  const kubernetesModel = new KubernetesModel(apiGateway);
  const azureCustomersModel = new AzureCustomersModel(apiGateway);
  const awsCustomersModel = new AwsCustomersModel(apiGateway);
  const commitmentModel = new CommitmentModel(apiGateway);
  const idleInstancesModel = new IdleInstancesModel(apiGateway);
  const customDashboardModel = new CustomDashboardModel(apiGateway);
  const modelsMap = new Map();
  modelsMap.set(ModelNames.BUDGET_MODEL, budgetModel);
  modelsMap.set(ModelNames.USERS_MODEL, usersModel);
  modelsMap.set(ModelNames.ANOMALY_DETECTION_MODEL, anomalyDetectionModel);
  modelsMap.set(ModelNames.CUE_ALERTS_MODEL, costAndUsageAlertsModel);
  modelsMap.set(ModelNames.BIG_QUERY_MODEL, bigQueryModel);
  modelsMap.set(ModelNames.K8S_USAGE_MODEL, kubernetesModel);
  modelsMap.set(ModelNames.AWS_CUSTOMERS_MODEL, awsCustomersModel);
  modelsMap.set(ModelNames.AZURE_CUSTOMERS_MODEL, azureCustomersModel);
  modelsMap.set(ModelNames.COMMITMENT_MODEL, commitmentModel);
  modelsMap.set(ModelNames.IDLE_INSTANCES_MODEL, idleInstancesModel);
  modelsMap.set(ModelNames.CUSTOM_DASHBOARD_MODEL, customDashboardModel);
  return modelsMap;
};

const createRootStore = () => {
  const apiGateway = createApiGateway();
  const map = createModels(apiGateway);
  const rootStore = new RootStore(map);
  return rootStore;
};

const rootStore = createRootStore();

config.apiReqHeaders.setCreateHeadersFunc(createCustomHeaders(rootStore));

const handleVisibilityChange = () => {
  if (document.visibilityState === 'visible') {
    // do not await here
    checkIfTokenRefreshNeeded();
  }
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      newTabLoading: true,
      isAuthenticated: false,
      username: '',
    };
  }

  async componentDidMount() {
    const { queryClient } = this.props;
    this.handleNewTab();
    rootStore.queryClient = queryClient;
    document.addEventListener('visibilitychange', handleVisibilityChange);
    // bind handleLogout to window in order to be able to call it from apiMiddleware
    window.handleLogout = this.handleLogout.bind(this);
  }
  componentWillUnmount() {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  }
  setUsername = (username) => {
    this.setState({ username });
  };

  // eslint-disable-next-line class-methods-use-this
  getDispUserKeyFromStorage = () => {
    // For refresh, get the last user key of the current tab.
    const sessionDispUserKey = window.sessionStorage.getItem('dispUserKey');
    if (sessionDispUserKey) {
      return sessionDispUserKey;
    }
    return window.localStorage.getItem('dispUserKey');
  };

  handleNewTab = async () => {
    if (rootStore) {
      if (this.isSessionStorageAuth()) {
        const dispUserKey = this.getDispUserKeyFromStorage();
        const authUserKey = window.localStorage.getItem('authUserKey');
        if (authUserKey) {
          rootStore.usersStore.updateCurrentAuthUser(authUserKey);
          rootStore.usersStore.updateCurrentDisplayedUserKey(authUserKey);
          await rootStore.usersStore.initMainUser();
          this.userHasAuthenticated();
          if (authUserKey !== dispUserKey) {
            await rootStore.usersStore.handleDisplayedUserChange(dispUserKey);
          }
          rootStore.fetchData(dispUserKey);
        }
      }
    }
    this.setState(() => ({ newTabLoading: false }));
  };

  userHasAuthenticated = () => {
    this.setState({ isAuthenticated: true });
    const userKey = rootStore.usersStore.currentDisplayedUserKey;
    const email = rootStore.usersStore.currentDisplayedUserName;
    const displayName = email;
    try {
      fsHandler.identify(userKey, displayName, email);
    } catch (error) {
      console.log('warn identify');
    }
  };

  // eslint-disable-next-line class-methods-use-this
  isSessionStorageAuth = () => {
    const authToken = window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken');
    const refreshToken = window.sessionStorage.getItem('refreshToken') || window.localStorage.getItem('refreshToken');
    if (!authToken) {
      return false;
    }
    const authTokenData = authToken.split('.')[1];
    const validBase64TokenData = authTokenData.replace(/-/g, '+').replace(/_/g, '/');
    const userData = JSON.parse(window.atob(validBase64TokenData));
    const exp = userData.exp * 1000; // seconds to milliseconds
    const now = Date.now();
    return exp > now || refreshToken;
  };

  async handleLogout(redirect = '') {
    try {
      await signOut(); // Need to await for sign out before clearing the session storage
    } catch (e) {
      // if we get here, it means that user token is already expired
      console.error(e);
    }
    this.setState({ isAuthenticated: false });
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.setUsername('');
    rootStore.invalidateStoresLogOut();
    if (redirect) {
      window.location.href = redirect;
    } else {
      window.location.reload();
    }
  }

  render() {
    const { newTabLoading, isAuthenticated, username } = this.state;
    const { usersStore, invoiceStore, usageStore, appStore, divisionsStore, kubernetesStore, commitmentStore } =
      rootStore;
    const childProps = {
      isAuthenticated,
      newTabLoading,
      userHasAuthenticated: this.userHasAuthenticated,
      isSessionStorageAuth: this.isSessionStorageAuth,
      handleLogout: this.handleLogout.bind(this),
      username,
      setUsername: this.setUsername.bind(this),
      rootStore,
      usersStore,
      invoiceStore,
      usageStore,
      divisionsStore,
      appStore,
      kubernetesStore,
      commitmentStore,
    };
    const customTheme = usersStore.getCurrDisplayedUserTheme();
    if (newTabLoading) {
      return <Spinner />;
    }
    return (
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={muiTheme}>
            <UserSettingsProvider>
              <ScrollToTop>
                <div>
                  <ToastContainer hideProgressBar position="bottom-left" />
                  <CustomThemeApplier customThemeName={customTheme} />
                  <Router childProps={childProps} />
                  <ReactQueryDevtools initialIsOpen={false} />
                </div>
              </ScrollToTop>
            </UserSettingsProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  queryClient: PropTypes.object.isRequired,
};

export default withInvoiceFiltersContextProvider(withRootStoreContextProvider(withRouter(App), rootStore), rootStore);
