import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { ReactComponent as SwitchDown } from 'shared/img/icons/switch-down.svg';
import { ReactComponent as SwitchUp } from 'shared/img/icons/switch-up.svg';
import classes from './FieldSidebarFilter.module.scss';

export const IncludeExcludeButton = ({ excludeMode, field, label, subField, changeFilterType, isDisabled }) => {
  const [isAnimate, setIsAnimate] = useState(false);
  const [startFinishAnimate, setStartFinishAnimate] = useState(false);
  const [buttonText, setButtonText] = useState(excludeMode ? 'Exclude' : 'Include');
  const inputEl = useRef();

  return (
    <Tooltip
      title={`Press here to ${buttonText === 'exclude' ? 'include' : 'exclude'} the selected values of
                   ${label || LabelCoordinator.getFieldLabel(field)} field`}
      placement="top"
      arrow
    >
      <div className={classes.toggleContainer}>
        <button
          className={`${classes.includeButton} ${buttonText === 'Exclude' ? classes.exclude : ''} `}
          disabled={isDisabled}
          onClick={(e) => {
            changeFilterType(e, field, subField);
            setIsAnimate(true);
            setTimeout(() => {
              setStartFinishAnimate(true);
              setButtonText(buttonText === 'Include' ? 'Exclude' : 'Include');

              setTimeout(() => {
                setStartFinishAnimate(false);
                setIsAnimate(false);
              }, 200);
            }, 300);
          }}
          type="button"
        >
          <span
            ref={inputEl}
            className={`${isAnimate === true ? classes[`${buttonText.toLowerCase()}Animation`] : classes.noAnimate} ${
              startFinishAnimate ? classes.endAnimate : ''
            }}`}
          >
            {buttonText}
          </span>
          {excludeMode ? (
            <SwitchUp style={{ height: '12px', width: '12px', paddingLeft: '2px' }} />
          ) : (
            <SwitchDown style={{ height: '12px', width: '12px', paddingLeft: '2px' }} />
          )}
        </button>
      </div>
    </Tooltip>
  );
};

IncludeExcludeButton.propTypes = {
  excludeMode: PropTypes.bool.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  subField: PropTypes.object,
  changeFilterType: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

IncludeExcludeButton.defaultProps = {
  label: '',
  subField: null,
  isDisabled: false,
};
