import { getFullAwsServiceName } from 'shared/utils/awsUtils';
import { mapCostUsageStateToAttributes } from 'usage/constants/costAndUsageConstants';
import { K8S_QUANTITY_TYPE_SELECT } from 'shared/constants/k8sConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { AWS_QUANTITY_TYPE_SELECT, AwsCommonFields, AwsQuantityTypes } from 'shared/constants/awsConstants';

export default class BaseCostUsageDataState {
  constructor(context) {
    this.data = null;
    this.context = context;
  }

  getData = () => {
    const { kubernetesStore } = this.context.props;
    const stateType = this.getDataStateType();
    this.data = kubernetesStore.kubernetesModel.dataStates[stateType];
    return this.data;
  };
  getSupportedQuantityTypes = () => [
    AWS_QUANTITY_TYPE_SELECT,
    AwsQuantityTypes.HOURS,
    AwsQuantityTypes.REQUESTS,
    AwsQuantityTypes.BYTES,
    AwsQuantityTypes.OTHER,
  ];

  modifyDataKey = (dataKey, currGroupBy) => {
    if (currGroupBy === AwsCommonFields.PAYER_ACCOUNT) {
      return dataKey.split(' (')[1].replace(')', '');
    }
    return dataKey;
  };

  getBreadCrumbLabel = (value) => LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', value);

  handleDive = (date, dataKey) => {
    const { currentGroupBy, dataKeyToWhereParamsMap } = this.context.getUrlState();
    const modifiedDataKey = this.modifyDataKey(dataKey, currentGroupBy);
    const newDataState = this.getNewDataStateType(modifiedDataKey);
    const newGroupBy = mapCostUsageStateToAttributes.get(newDataState);
    this.context.setUrlState({
      diveDate: date,
      isApplyFiltersButtonDisabled: true,
      currDataState: newDataState,
      currentGroupBy: newGroupBy,
      dataKeyToWhereParamsMap: {
        ...dataKeyToWhereParamsMap,
        [currentGroupBy]: getFullAwsServiceName(modifiedDataKey),
      },
    });
  };

  getCurrentGroupByFromWhereParams = (dataStateAttr) => {
    if ((dataStateAttr || '').includes('customtags')) {
      const { dataKeyToWhereParamsMap } = this.context.state;
      const wheresKeys = Object.keys(dataKeyToWhereParamsMap);
      const fulldataStateAttr = wheresKeys.find((whereKey) => whereKey.split(':')[0] === dataStateAttr);
      return fulldataStateAttr || dataStateAttr;
    }
    return dataStateAttr;
  };

  handleBackTo = () => {
    const { dataKeyToWhereParamsMap } = this.context.getUrlState();
    const dataStateType = this.getDataStateType();
    const newGroupBy = this.getCurrentGroupByFromWhereParams(mapCostUsageStateToAttributes.get(dataStateType));
    const keys = [...Object.keys(dataKeyToWhereParamsMap)];
    const newDataKeyToWhereParamsMap = {};
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === newGroupBy) {
        break;
      }
      newDataKeyToWhereParamsMap[keys[i]] = dataKeyToWhereParamsMap[keys[i]];
    }
    this.context.setUrlState({
      currDataState: dataStateType,
      currentGroupBy: newGroupBy,
      dataKeyToWhereParamsMap: newDataKeyToWhereParamsMap,
    });
  };

  handleFilterChange = async (
    groupByFilter,
    dataKeyToWhereParamsMap,
    startDate,
    endDate,
    currPeriodGranLevel,
    fieldToFilterdValuesMap,
    currCostType,
    selectedUsageType = K8S_QUANTITY_TYPE_SELECT,
    selectedUsageTypeMetric = null,
    excludedFiltersStatusMap = new Map(),
    likeFiltersStatus = {},
    isNetUnblended,
    isShowAmortizeCost,
    isNetAmortize,
    wasteCostAllocationChecked,
    diveDate,
    k8SGranularity,
  ) => {
    const { kubernetesStore } = this.context.props;
    const { filterBarGroupBySecondary } = this.context.getUrlState();
    this.context.updateDataDates();
    kubernetesStore.fetchK8sDataByState(
      this.getDataStateType(),
      filterBarGroupBySecondary,
      groupByFilter,
      dataKeyToWhereParamsMap,
      startDate,
      endDate,
      currPeriodGranLevel,
      fieldToFilterdValuesMap,
      currCostType,
      selectedUsageType,
      selectedUsageTypeMetric,
      excludedFiltersStatusMap,
      likeFiltersStatus,
      isNetUnblended,
      isShowAmortizeCost,
      isNetAmortize,
      undefined,
      undefined,
      undefined,
      wasteCostAllocationChecked,
      diveDate,
      k8SGranularity,
    );
  };

  getPreparedNewGroupBy = (newGroupBy) => {
    let preparedNewGroupBy = '';
    switch (true) {
      case newGroupBy.split(':')[0] === 'customtags':
        preparedNewGroupBy = 'customtags';
        break;
      case newGroupBy.split(':')[0] === 'accounttags':
        preparedNewGroupBy = 'accounttags';
        break;
      case newGroupBy.split(':')[0] === 'labels':
        preparedNewGroupBy = 'labels';
        break;
      case newGroupBy.split(':')[0] === 'virtualcustomtags':
        preparedNewGroupBy = 'virtualcustomtags';
        break;
      case newGroupBy.split(':')[0] === 'viewscustomtags':
        preparedNewGroupBy = 'viewscustomtags';
        break;
      case newGroupBy.split(':')[0] === AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS:
        preparedNewGroupBy = AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS;
        break;
      default:
        preparedNewGroupBy = newGroupBy;
        break;
    }
    return preparedNewGroupBy;
  };

  fetchDataByState = (dataState, newGroupBy, newSecondaryGroupBy = '') => {
    const { kubernetesStore } = this.context.props;
    const {
      endDate,
      diveDate,
      startDate,
      isNetAmortize,
      wasteCostAllocationChecked,
      isNetUnblended,
      dataKeyToWhereParamsMap,
      SelectedUsageType,
      isShowAmortizeCost,
      excludedFiltersStatusMap,
      selectedUsageTypeMetric,
      fieldToFilterdValuesMap,
      likeFiltersStatus,
      currCostType,
      currPeriodGranLevel,
      isUsageChecked,
      selectedGranLevel,
      k8SGranularity,
    } = this.context.getUrlState();
    const stateAttribute = mapCostUsageStateToAttributes.get(dataState);
    this.context.updateDataDates();
    kubernetesStore.fetchK8sDataByState(
      dataState,
      newSecondaryGroupBy,
      newGroupBy || stateAttribute,
      dataKeyToWhereParamsMap,
      startDate,
      endDate,
      selectedGranLevel || currPeriodGranLevel,
      fieldToFilterdValuesMap,
      currCostType,
      isUsageChecked && SelectedUsageType ? SelectedUsageType : K8S_QUANTITY_TYPE_SELECT,
      isUsageChecked && selectedUsageTypeMetric ? selectedUsageTypeMetric : null,
      excludedFiltersStatusMap,
      likeFiltersStatus,
      isNetUnblended,
      isShowAmortizeCost,
      isNetAmortize,
      undefined,
      undefined,
      undefined,
      wasteCostAllocationChecked,
      diveDate,
      k8SGranularity,
    );
  };
}
