import React, { useContext, useEffect, useState } from 'react';
import Select from 'shared/components/Select';
import MultiSelect from 'shared/components/andtComponents/MultiSelect';
import { ReportContext } from 'shared/components/reportModal/ReportModal';
import {
  CUSTOMER_REPORT_TYPES_OPTIONS,
  CUSTOMER_REPORT_TYPES,
  PERIOD_OPTIONS,
  ALL_CUSTOMERS,
} from 'divisions/containers/Customers/reports/constants/customerReportsConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { ReportPeriodTime } from 'usage/constants/costAndUsageConstants';
import { isIncludesAll } from 'shared/components/reportModal/reportsUtil';
import styles from './ReportModal.module.scss';

const CustomerReportContent = () => {
  const { reportData, setReportData, data, errors, setErrors } = useContext(ReportContext);
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  useEffect(() => {
    setReportData((prev) => ({
      ...prev,
      isOnlyEmail: true,
    }));
  }, []);

  const formatCustomers = (any) => {
    if (!any) {
      return [];
    }
    // data type is not consistent! can be a string if a single value is selected.
    if (Array.isArray(any)) {
      return any.map((value) => ({
        value,
        label: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', value),
      }));
    }
    return [ALL_CUSTOMERS];
  };

  useEffect(() => {
    let error = null;
    if (!reportData.customers || reportData.customers.length === 0) {
      error = 'Report must have at least one Customer';
    }
    setErrors((prev) => ({
      ...prev,
      customers: error,
    }));
  }, [reportData.customers]);

  useEffect(() => {
    setReportData((prev) => ({
      ...prev,
      type: data.type || CUSTOMER_REPORT_TYPES.CSV,
      period: data.period || ReportPeriodTime.CURRENT_MONTH,
      customers: data.customers,
    }));
    setSelectedCustomers(formatCustomers(data.customers));
  }, [JSON.stringify(data)]);

  const onCustomerSelect = (values) => {
    let selected = values;
    setReportData((prev) => {
      if (isIncludesAll(prev.customers)) {
        const valuesNoAll = values?.filter((obj) => obj.value !== 'all');
        selected = valuesNoAll;
      } else if (isIncludesAll(values)) {
        selected = [ALL_CUSTOMERS];
      }
      return { ...prev, customers: selected ? selected.map(({ value }) => value) : selected };
    });
    setSelectedCustomers(selected);
  };

  return (
    <>
      <div className={`${styles.field} ${styles.halfField}`}>
        <div className={styles.fieldTitle}>Period</div>
        <Select
          className={styles.select}
          value={`${reportData.period}`}
          options={PERIOD_OPTIONS}
          onChange={(event) => {
            setReportData((prev) => ({ ...prev, period: event.target.value }));
          }}
        />
      </div>
      <div className={`${styles.field} ${styles.halfField}`}>
        <div className={styles.fieldTitle}>Report Type</div>
        <Select
          className={styles.select}
          value={`${reportData.type}`}
          options={CUSTOMER_REPORT_TYPES_OPTIONS}
          onChange={(event) => {
            setReportData((prev) => ({ ...prev, type: event.target.value }));
          }}
        />
      </div>
      <div className={styles.field}>
        <div className={styles.fieldTitle}>Customers *</div>
        <MultiSelect options={data.customersOptions} value={selectedCustomers} onChange={onCustomerSelect} />
        {errors.customers && reportData.reportName.value ? (
          <div className={styles.errorLabel}>Report must have at least one Customer</div>
        ) : null}
      </div>
    </>
  );
};

export default CustomerReportContent;
