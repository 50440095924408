import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Spinner from 'shared/components/andtComponents/Spinner';
import { Card, CardBody, Container, Row } from 'reactstrap';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import PreferencesTable from 'shared/components/PreferencesTable';
import { PreferencesTypes } from 'users/constants/usersConstants';
import { usePreferences } from 'users/hooks/react-query/usePreferences';

const RecommendationsPreferences = ({ usersStore }) => {
  const { fetchPreferences } = usePreferences();
  const { data: preferences, isLoading } = fetchPreferences(usersStore.currDispUserAccountKey);
  if (isLoading) {
    return <Spinner />;
  }
  const preferencesFiltered = preferences?.filter((p) => p.preferenceType === PreferencesTypes.RECOMMENDATION);

  return (
    <Container>
      <PageHeader title={PageNames.RECOMMENDATIONS_PREFERENCES} />
      <Card>
        <CardBody>
          <Row>
            <PreferencesTable preferences={preferencesFiltered} usersStore={usersStore} />
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

RecommendationsPreferences.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

const ObserverRecommendationsPreferences = observer(RecommendationsPreferences);
export default ObserverRecommendationsPreferences;
