/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { AnomalyRulesModalTypes } from 'usage/constants/usageConstants';
import CustomModal from 'shared/components/andtComponents/Modal';
import MultiTypeFieldFilterSelector from 'shared/components/MultiTypeFieldFilterSelector';
import NumberInput from './NumberInput';
import TextInput from './TextInput';

class CreateOrEditRuleModal extends React.PureComponent {
  static propTypes = {
    invoiceStore: PropTypes.object.isRequired,
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    modalState: PropTypes.string.isRequired,
    ruleHandlers: PropTypes.object.isRequired,
    // ******
    maxNetwork: PropTypes.string.isRequired,
    maxCpu: PropTypes.string.isRequired,
    criteriaName: PropTypes.string.isRequired,
    linkedAccSelectedOptionsMap: PropTypes.object.isRequired,
    existingCriteriasNames: PropTypes.object.isRequired,
    // ******
    className: PropTypes.bool,
  };
  static defaultProps = {
    className: '',
  };
  state = {
    wasFormSubmit: false,
    isNoEmptyFields: false,
    isNameValid: true,
  };
  getModalTitles = (state) => {
    let modalTitle = '';
    let buttonText = '';
    if (state === AnomalyRulesModalTypes.CREATE) {
      modalTitle = 'Create Idle Criteria Rule';
      buttonText = 'Create';
    }
    if (state === AnomalyRulesModalTypes.EDIT) {
      modalTitle = 'Edit Idle Criteria Rule';
      buttonText = 'Edit';
    }
    return { modalTitle, buttonText };
  };
  isNameValid = (enteredName) => {
    const isNameExists = !!this.props.existingCriteriasNames.find(
      (crtName) => crtName.value.toLowerCase() === enteredName.toLowerCase(),
    );
    this.setState({ isNameValid: !isNameExists });
    return !isNameExists;
  };
  checkIfNoEmptyFields = () => {
    const { maxNetwork, maxCpu, criteriaName, linkedAccSelectedOptionsMap } = this.props;
    const isMaxNetValid = !!maxNetwork;
    const isMaxCpuValid = !!maxCpu;
    const iscriteriaNameValid = !!criteriaName;
    const linkedAccValid = !!linkedAccSelectedOptionsMap.get('linkedaccid').length;
    const isNoEmptyFields = isMaxNetValid && isMaxCpuValid && iscriteriaNameValid && linkedAccValid;
    this.setState({ isNoEmptyFields });
    return isNoEmptyFields;
  };
  isFormValid = () => {
    const isNoEmptyFields = this.checkIfNoEmptyFields();
    const isValid = isNoEmptyFields && this.state.isNameValid;
    return isValid;
  };
  handleSaveOrEditClicked = (modalState, ruleHandlers) => {
    const isValid = this.isFormValid();
    this.setState({ wasFormSubmit: true });
    if (isValid) {
      if (modalState === AnomalyRulesModalTypes.CREATE) {
        ruleHandlers.handleCreateNewRule();
      } else {
        ruleHandlers.handleUpdateRule();
      }
    }
  };
  handleNameChanged = (e, ruleHandlers) => {
    this.isNameValid(e.target.value);
    ruleHandlers.handleInputFieldChange(e);
  };
  renderValidationMessage = (validationMessage) => (
    <Row className="idle-instances-body" style={{ marginBottom: '10px' }}>
      <h5 className="filter-msg">{validationMessage}</h5>
    </Row>
  );

  render() {
    const {
      toggle,
      criteriaName,
      isOpen,
      className,
      modalState,
      ruleHandlers,
      linkedAccSelectedOptionsMap,
      invoiceStore,
      maxCpu,
      maxNetwork,
    } = this.props;
    const { wasFormSubmit, isNoEmptyFields, isNameValid } = this.state;
    return (
      <CustomModal
        overrideStyles={{ width: 800 }}
        open={isOpen}
        onClose={toggle}
        className={className}
        title={this.getModalTitles(modalState).modalTitle}
        closeOnSave={false}
        onSave={() => this.handleSaveOrEditClicked(modalState, ruleHandlers)}
        saveTitle={modalState === AnomalyRulesModalTypes.CREATE ? 'Create' : 'Save'}
      >
        <Row style={{ marginBottom: '10px' }}>
          <Col>
            <h5 style={{ width: '100%' }} className="filter-bar-inner-col-title">
              Criteria Name
            </h5>
            <TextInput
              className="insert-budget-input"
              name="criteriaName"
              onChange={(e) => this.handleNameChanged(e, ruleHandlers)}
              value={criteriaName}
              label=""
            />
            {!isNameValid && this.renderValidationMessage('Criteria name already exists')}
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col>
            <h5 style={{ width: '100%' }} className="filter-bar-inner-col-title">
              Max CPU Utilization Limit Percent
            </h5>
            <NumberInput
              className="insert-budget-input"
              name="maxCpu"
              onChange={(e) => ruleHandlers.handleInputFieldChange(e)}
              value={maxCpu}
              label=""
              isPercent
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col>
            <h5 style={{ width: '100%' }} className="filter-bar-inner-col-title">
              {' '}
              Max Network In & Out Limit (MB)
            </h5>
            <NumberInput
              className="insert-budget-input"
              name="maxNetwork"
              onChange={(e) => ruleHandlers.handleInputFieldChange(e)}
              value={maxNetwork}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col>
            <h5 style={{ width: '100%' }} className="filter-bar-inner-col-title">
              Linked Account
            </h5>
            <MultiTypeFieldFilterSelector
              selectedValues={linkedAccSelectedOptionsMap.get('linkedaccid')}
              handler={ruleHandlers.handleLinkedAccChange}
              type="linkedaccid"
              name="linkedaccid"
              placeHolder="Select linked accounts"
              labelFunc={(value) => value.displayLabel}
              valueFunc={(value) => value.linkedAccountId}
            />
            <span>
              *Only{' '}
              <a
                href="https://cloudcost.zendesk.com/hc/en-us/articles/6041352942492"
                target="_blank"
                rel="noopener noreferrer"
              >
                connected linked accounts
              </a>{' '}
              will be inspected
            </span>
          </Col>
        </Row>
        {wasFormSubmit && !isNoEmptyFields ? this.renderValidationMessage('Please fill all fileds to continue') : null}
      </CustomModal>
    );
  }
}

export default CreateOrEditRuleModal;
