/* eslint-disable quotes,class-methods-use-this */
/* eslint-disable no-unused-vars,arrow-parens */
import { API } from 'shared/utils/apiMiddleware';
import { sanitizeParam } from 'shared/utils/apiUtil';
import { CLOUD_TYPE_IDS } from '../constants/usersConstants';

export const signIn = async (username, password) =>
  API.post('billings', `/api/v1/users/signin`, { body: { username, password } });
export const renewToken = async (username, refreshToken) =>
  API.post('billings', `/api/v1/users/renewToken`, { body: { username, refreshToken } });

export const getSsoClientId = async (username) => {
  try {
    const clientId = username.toLowerCase().includes('pileuscloud.com')
      ? null
      : await API.get('billings', `/api/v1/users/sso?username=${username}`);
    return clientId;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error in getSsoClientId: ${error}`);
    return null;
  }
};

export const signOut = async () => {
  await API.post('billings', `/api/v1/users/signout`);
};

export const getAnodotUserToken = async () => {
  const apiQuery = `/api/v1/users/token`;
  return API.get('billings', apiQuery);
};

export const resendSignUp = async (username) => {
  // make another API call instead of direct AWS call
  // const result = await Auth.resendSignUp(username);
  // return result;
};

export const forgotPassword = async (username) => {
  await API.post('billings', `/api/v1/users/forgotPassword`, { body: { username } });
};
export const forgotPasswordSubmit = async (username, code, password) => {
  await API.post('billings', `/api/v1/users/forgotPasswordSubmit`, { body: { username, code, newPassword: password } });
};

export class UsersApi {
  registerUser = async (registerData) => {
    const { username, password } = registerData;
    await API.post('billings', `/api/v1/users/signup`, { body: { username, password } });
  };

  signIn = async (username, password) => {
    const userID = await API.post('billings', `/api/v1/users/signin`, { body: { username, password } });
    return userID;
  };

  processUserPayment = async (userPaymentData) => {
    const body = userPaymentData;
    let response = null;
    try {
      response = await API.post('billings', `/api/v1/users/payments`, { body });
      if (response) {
        return response;
      }
    } catch (error) {
      response = error;
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return response;
    }
  };

  updateAutoAssignAccountProperty = async (autoAssignLinkedAccounts) => {
    const url = '/api/v1/users/account-info/auto-assign-linked-accounts';
    const data = await API.post('billings', url, { body: { autoAssignLinkedAccounts } });
    return data;
  };
  getCloudAccountData = async (accountId, cloudTypeId) => {
    const getUrl = `/api/v1/users/account-info?accountId=${accountId}&cloudTypeId=${cloudTypeId}`;
    const data = await API.get('billings', getUrl);
    return data;
  };
  deleteAccount = async (accountId, cloudTypeId) => {
    const putUrl = `/api/v1/users/account-delete?accountId=${accountId}&cloudTypeId=${cloudTypeId}`;
    const data = await API.put('billings', putUrl);
    return data;
  };
  createPileusUser = async (newPileusUserData, registerUserInfoParams) => {
    const body = { newPileusUserData, registerUserInfoParams };
    let response = null;
    try {
      response = await API.post('billings', `/api/v1/users`, { body });
      if (response) {
        return response;
      }
    } catch (error) {
      response = error;
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return response;
    }
  };

  getUserWithPlainSubUsers = () => {
    const query = `/api/v1/users/plain-sub-users`;
    return API.get('billings', query);
  };

  getUser = () => {
    const query = `/api/v1/users`;
    return API.get('billings', query);
  };

  getSubUserInfo = (userKey) => {
    const query = `/api/v1/users/sub-user/${userKey}`;
    return API.get('billings', query);
  };

  insertUserCloudAccountInterface = async (connectData) => {
    const body = connectData;
    let response = null;
    try {
      response = await API.put('billings', `/api/v1/users/on-boarding`, { body });
      if (response) {
        return response;
      }
    } catch (error) {
      response = error;
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return response;
    }
  };
  connectUserToCloudAccount = async (connectData) => {
    const body = connectData;
    let response = null;
    try {
      response = await API.post('billings', `/api/v1/users/connect-cloud-account`, { body });
      if (response) {
        return response;
      }
    } catch (error) {
      response = error;
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return response;
    }
  };
  checkNewCloudAccount = async (accountId) => {
    try {
      const data = await API.post('billings', `/api/v1/users/check-cloud-account?accountId=${accountId}`);
      return data.isConnectSuccess;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return false;
    }
  };
  updateUserTypeAndAwsAccId = (newType, awsAccountID, isConnectionSuccess) => {
    const body = {
      newType,
      awsAccountID,
      isConnectionSuccess,
    };
    return API.put('billings', '/api/v1/users/update-user-type-id', { body });
  };

  // User key is passed in the body params, should not be removed
  updateUserData = async (setParams, setValues, whereParams, whereValues) => {
    const body = {
      setParams,
      setValues,
      whereParams,
      whereValues,
    };
    return API.put('billings', '/api/v1/users/update-user-data', { body });
  };
  updateUserGeneralData = async (params) => {
    const body = params;
    return API.put('billings', '/api/v1/users/user-general-data', { body });
  };
  updateUserRole = async (body) => {
    const creareUrl = `/api/v1/users/update-user-role`;
    return API.put('billings', creareUrl, { body });
  };
  updateUserAccount = async (accountId, accountKey, newAccountName, newRoleARN) => {
    const body = { editedAccountId: accountId, editedAccountKey: accountKey, newAccountName, newRoleARN };
    return API.put('billings', '/api/v1/users/update-account', { body });
  };

  getUserAccounts = async () => {
    const getUrl = '/api/v1/users/user-accounts';
    return API.get('billings', getUrl);
  };

  // notifications

  getUserNotificationSettings = async () => {
    const getUrl = `/api/v1/users/user-settings/notifications`;
    return API.get('billings', getUrl);
  };

  updateUserNotificationSettings = async (body) => {
    const updateUrl = `/api/v1/users/user-settings/notifications`;
    return API.put('billings', updateUrl, { body });
  };

  // linked accounts

  fetchUserLinkedAccounts = async (cloudId) => {
    if (cloudId === CLOUD_TYPE_IDS.MULTI) {
      return [];
    }
    const cloudIdToUrl = {
      0: '/aws',
      1: '/azure',
      2: '/gcp',
    };
    const getUrl = `/api/v1/users/linked-accounts${cloudIdToUrl[cloudId]}`;
    return API.get('billings', getUrl);
  };

  // External keys

  signinWithToken = async () => {
    const apiQuery = `/api/v1/users/signin-with-token`;
    return API.post('billings', apiQuery);
  };

  setExternalUserNameToUserKey = async (externalUserName, userKey) => {
    const putUrl = `/api/v1/users/user-key-from-external`;
    const body = {
      externalUserName,
      userKey,
    };
    return API.put('billings', putUrl, { body });
  };

  getUserKeyFromUserName = async (userName) => {
    const sanName = sanitizeParam(userName);
    const apiQuery = `/api/v1/users/user-key-from-name?user_name=${sanName}`;
    return API.get('billings', apiQuery);
  };

  updateLinkedAccounts = async (linkedAccounts) => {
    const body = { linkedAccounts };
    const getUrl = `/api/v1/users/linked-accounts`;
    return API.put('billings', getUrl, { body });
  };

  // Alerts

  getUserAlerts = async () => {
    const getUrl = `/api/v1/users/alerts`;
    return API.get('billings', getUrl);
  };

  updateAlertDismiss = async (dueDate, alertId) => {
    const updateUrl = `/api/v1/users/alerts/${alertId}/${dueDate || '0'}`;
    return API.put('billings', updateUrl);
  };

  // Sub Users

  getSubUsers = async () => {
    const getUrl = `/api/v1/users/subUsers`;
    return API.get('billings', getUrl);
  };

  deleteSubUser = async (userKey, accountKey) => {
    const body = { userKey, accountKey };
    const deleteUrl = `/api/v1/users/subUsers`;
    return API.del('billings', deleteUrl, { body });
  };
  deleteSubUsersBulk = async (data) => {
    const body = { users: data };
    const deleteUrl = `/api/v1/users/subUsers/bulk`;
    return API.del('billings', deleteUrl, { body });
  };
  createNewUsersInRole = async (body) => {
    const creareUrl = `/api/v1/users/new-user-with-role`;
    return API.post('billings', creareUrl, { body });
  };
  createNewCustomerSubUser = async (body) => {
    const creareUrl = `/api/v1/users/customer-sub-users`;
    return API.post('billings', creareUrl, { body });
  };
  createSubUserDashbaord = async (body) => {
    const postUrl = `/api/v1/users/create-dashbaord`;
    return API.post('billings', postUrl, { body });
  };

  getAvailableLinkedAccounts = async () => {
    const getUrl = `/api/v1/users/subUsers/availble-accounts`;
    return API.get('billings', getUrl);
  };
  getAvailableDivisionLinkedAccounts = async () => {
    const getUrl = `/api/v1/users/subUsers/availble-accounts-division`;
    return API.get('billings', getUrl);
  };
  updateUserMarginPercentage = async (marginPercentageNumber) => {
    const putUrl = `/api/v1/users/user-margin?marginPercentageNumber=${marginPercentageNumber}`;
    return API.put('billings', putUrl);
  };
  getUserMarginPercent = async () => {
    const getUrl = `/api/v1/users/user-margin`;
    return API.get('billings', getUrl);
  };
  connectAzureBillingExportAccount = async (billingExportConfiguration) => {
    const url = `/api/v1/users/on-boarding/connect-azure-billing-export-account`;
    return API.post('billings', url, { body: billingExportConfiguration });
  };
  migrateAzureEAToMCAAccount = async (billingExportConfiguration) => {
    const url = `/api/v1/users/on-boarding/migrate-azure-ea-to-mca-account`;
    return API.post('billings', url, { body: billingExportConfiguration });
  };
  connectAzureEaAccount = async (eaDetailsConfiguration) => {
    const body = { ...eaDetailsConfiguration };
    const getUrl = `/api/v1/users/on-boarding/connect-azure-ea-account`;
    return API.put('billings', getUrl, { body });
  };
  testAppRegistraionConfiguration = async (appConfiguration) => {
    const { directoryId, applicationId, clientSecret, appName } = appConfiguration;
    // eslint-disable-next-line max-len
    const queryParams = `directoryId=${directoryId}&applicationId=${applicationId}&clientSecret=${clientSecret}&${appName}`;
    const url = `/api/v1/users/azure/test-app-registration?${queryParams}`;
    return API.get('billings', url);
  };
  sendOnBoardingEvent = async (cloudTypeId) => {
    const body = { cloudTypeId };
    const url = '/api/v1/users/on-boarding-event';
    await API.post('billings', url, { body });
  };
  saveAzureOnBoardingAppRegistrationConfiguration = async (appConfiguration) => {
    const body = { ...appConfiguration };
    const putUrl = `/api/v1/users/azure/reg-app`;
    return API.post('billings', putUrl, { body });
  };
  deleteAppRegistrationConfiguration = async (data) => {
    const body = data;
    const delUrl = `/api/v1/users/azure/del/reg-app?accountId=${data.accountId}&id=${data.id}`;
    return API.del('billings', delUrl, { body });
  };
  updateAppName = async (data) => {
    const body = data;
    const putUrl = `/api/v1/users/azure/update/reg-app`;
    return API.put('billings', putUrl, { body });
  };
  getAzureRegisteredApps = async () => {
    const getUrl = `/api/v1/users/azure/reg-app`;
    return API.get('billings', getUrl);
  };
  getAzureEaApiAccessKeyData = async () => {
    const getUrl = `/api/v1/users/azure/ea-api-key`;
    return API.get('billings', getUrl);
  };

  updateAzureEaApiAccessKeyData = async (apiAccessKey, effectiveDate) => {
    const body = { apiAccessKey, effectiveDate };
    const putUrl = `/api/v1/users/azure/ea-api-key`;
    return API.put('billings', putUrl, { body });
  };
  connectGcpAccount = async (gcpDetailsConfiguration) => {
    const body = { ...gcpDetailsConfiguration };
    const putUrl = `/api/v1/users/on-boarding/connect-gcp-account`;
    return API.put('billings', putUrl, { body });
  };
  uploadAccountTagsFile = async (fileData) => {
    const body = fileData;
    const putUrl = `/api/v1/users/files/account-complement-data/upload`;
    return API.put('billings', putUrl, { body });
  };
  deleteAccountTagsFile = async (fileId) => {
    const delUrl = `/api/v1/users/files/account-complement-data/${fileId}`;
    return API.del('billings', delUrl);
  };
  getAccountTagsMetaData = async () => {
    const getUrl = `/api/v1/users/files/account-complement-data/all`;
    return API.get('billings', getUrl);
  };
  updateAccountTagsInfo = async (linkedAccountId, newData, uuid) => {
    const body = newData;
    const putUrl = `/api/v1/users/files/account-complement-data/${linkedAccountId}`;
    return API.put('billings', putUrl, { body });
  };
  getAccountTagsData = async () => {
    const getQuery = '/api/v1/users/files/account-complement-data/add-data';
    const result = await API.get('billings', getQuery);
    return result;
  };
  // Target Goals **********************************
  fetchAllTargetGoals = async () => {
    const getQuery = '/api/v1/users/target-goals';
    const result = await API.get('billings', getQuery);
    return result;
  };
  createNewTargetGoal = async (data) => {
    const postQuery = '/api/v1/users/target-goals';
    const result = await API.post('billings', postQuery, { body: data });
    return result;
  };
  deleteTargetGoal = async (uuid) => {
    const delQuery = `/api/v1/users/target-goals?uuid=${uuid}`;
    const result = await API.del('billings', delQuery);
    return result;
  };
  updateTargetGoal = async (data) => {
    const putQuery = '/api/v1/users/target-goals';
    const result = await API.put('billings', putQuery, { body: data });
    return result;
  };

  updateDefaultAccountId = async (accountId) => {
    const query = `/api/v1/users/default-account?accountId=${accountId}`;
    const result = await API.post('billings', query);
    return result;
  };

  getRoutesPermissions = async () => {
    const apiQuery = '/api/v1/users/permissions/routes';
    const result = await API.get('billings', apiQuery);
    return result;
  };

  getUserDataByAccount = async (accountId) => {
    const result = await API.get('billings', `/api/v1/users/byAccount?accountId=${accountId}`);
    return result;
  };
  getIsUserSubscribedToMondayWebhook = async () => {
    const result = await API.get('billings', '/api/v1/integrations/monday/is-role-id-subscribed');
    return result;
  };

  getCompanySlackChannels = async () => {
    const result = await API.get('billings', '/api/v1/integrations/slack/channels');
    return result;
  };

  updateUserSlackData = async (slackUserData) => {
    const body = slackUserData;
    let response = null;
    try {
      response = await API.put('billings', `/api/v1/users/slack`, { body });
      if (response) {
        return response;
      }
    } catch (error) {
      response = error;
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return response;
    }
  };
}
