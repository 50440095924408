import Report from '../models/helperClasses/report';

export const createReportObject = (userKey, rawReportObj) => {
  const {
    uuid: id,
    type,
    name,
    update_time: updateTime,
    creation_time: creationDate,
    report_saved_params: reportSavedParams,
    mail_params: mailParams,
    is_pp_applied: isPpApplied,
    report_type: reportType,
    source_key: sourceKey,
  } = rawReportObj;
  if (!mailParams) {
    return null;
  }
  const valMail = parseInt(mailParams.email, 0) === 0 ? '' : mailParams.email;
  mailParams.email = mailParams && valMail;
  mailParams.frequency = mailParams && parseInt(mailParams.frequency, 0);
  const newReport = new Report(
    id,
    userKey,
    type,
    name,
    creationDate,
    isPpApplied,
    reportSavedParams,
    mailParams,
    reportType,
    sourceKey,
    updateTime,
  );
  return newReport;
};
