import { GranularityLevelsTypes } from 'shared/constants/appConstants';
import { percentStr } from 'shared/utils/strUtil';
import { CLOUD_TYPE_IDS, UsersType } from 'users/constants/usersConstants';
import { mapReportTypeToDisplay } from './costAndUsageConstants';

export class REPORT_TYPES {
  static REGION = 0;
  static CURR_VS_PREV_MONTH = 1;
  static TOP_5_SERVICES = 2;
  static YESTERDAY_TOP_5_SERVICES = 3;
  static MONTHLY_COST_YTD = 4;
}

export class CAU_ALERTS_TYPES {
  static COST_CHANGES = 0;
  static RI_EXPIRATION = 1;
  static SP_EXPIRATION = 2;
}

export class CAU_ALERTS_STATUS {
  static SAVE = 0;
  static EDIT = 1;
  static ADD = 2;
  static REMOVE = 3;
  static DELETE = 4;
}

export const CAU_ALERTS_TYPES_TEXT = {
  [CAU_ALERTS_TYPES.COST_CHANGES]: 'changeCostService',
  [CAU_ALERTS_TYPES.RI_EXPIRATION]: 'commitementExpirationRi',
  [CAU_ALERTS_TYPES.SP_EXPIRATION]: 'commitementExpirationSP',
};
export const CUE_ALERTS_TYPES_VALUES_FROM_TEXT = {
  changeCostService: [CAU_ALERTS_TYPES.COST_CHANGES],
  commitementExpirationRi: [CAU_ALERTS_TYPES.RI_EXPIRATION],
  commitementExpirationSP: [CAU_ALERTS_TYPES.SP_EXPIRATION],
};
export const CAU_ALERTS_STATUS_TEXT = {
  [CAU_ALERTS_STATUS.SAVE]: 'save',
  [CAU_ALERTS_STATUS.EDIT]: 'edit',
  [CAU_ALERTS_STATUS.ADD]: 'add',
  [CAU_ALERTS_STATUS.REMOVE]: 'remove',
  [CAU_ALERTS_STATUS.DELETE]: 'delete',
};
export const CAU_ALERTS_STATUS_VALUES_FROM_TEXT = {
  save: [CAU_ALERTS_STATUS.SAVE],
  edit: [CAU_ALERTS_STATUS.EDIT],
  add: [CAU_ALERTS_STATUS.ADD],
  remove: [CAU_ALERTS_STATUS.REMOVE],
  delete: [CAU_ALERTS_STATUS.DELETE],
};

export class CAU_COST_CHANGE_ALERTS_CHANGE_TYPE {
  static CHANGE = 'change';
  static INCREASE = 'increase';
  static DECREASE = 'decrease';
}

export class CAU_ALERTS_GRAN_LEVELS {
  static DAILY = 0;
  static WEEKLY = 1;
  static MONTHLY = 2;
  static DAY_IN_WEEK = 3;
}
export class CAU_ALERTS_WEEK_DAYS {
  static MONDAY = 0;
  static TUESDAY = 1;
  static WEDNESDAY = 2;
  static THURSDAY = 3;
  static FRIDAY = 4;
  static SATURDAY = 5;
  static SUNDAY = 6;
}

export const costChangeAlertChangeTypesToDisplayLabels = {
  [CAU_COST_CHANGE_ALERTS_CHANGE_TYPE.CHANGE]: 'Change',
  [CAU_COST_CHANGE_ALERTS_CHANGE_TYPE.INCREASE]: 'Increase',
  [CAU_COST_CHANGE_ALERTS_CHANGE_TYPE.DECREASE]: 'Decrease',
};

export const alertsGranLevelDisplayedLabels = {
  [CAU_ALERTS_GRAN_LEVELS.DAILY]: 'Today vs. Yesterday',
  [CAU_ALERTS_GRAN_LEVELS.WEEKLY]: 'This week vs. Last week',
  [CAU_ALERTS_GRAN_LEVELS.MONTHLY]: 'This month vs. Last month',
  [CAU_ALERTS_GRAN_LEVELS.DAY_IN_WEEK]: 'Day this week vs. Day last week',
};
export const weekDaysDisplayedLabels = {
  [CAU_ALERTS_WEEK_DAYS.MONDAY]: 'Monday',
  [CAU_ALERTS_WEEK_DAYS.TUESDAY]: 'Tuesday',
  [CAU_ALERTS_WEEK_DAYS.WEDNESDAY]: 'Wednesday',
  [CAU_ALERTS_WEEK_DAYS.THURSDAY]: 'Thursday',
  [CAU_ALERTS_WEEK_DAYS.FRIDAY]: 'Friday',
  [CAU_ALERTS_WEEK_DAYS.SATURDAY]: 'Saturday',
  [CAU_ALERTS_WEEK_DAYS.SUNDAY]: 'Sunday',
};

export class CAU_ALERTS_OPERATOR_TYPE_VALUES {
  static AND = 'and';
  static OR = 'or';
}

export const cueAlertAndOrToDisplayLabels = {
  [CAU_ALERTS_OPERATOR_TYPE_VALUES.AND]: 'And',
  [CAU_ALERTS_OPERATOR_TYPE_VALUES.OR]: 'Or',
};
export const cuaAlertsTypeToNotificationParam = {
  [CAU_ALERTS_TYPES.COST_CHANGES]: 'isShowAlertCostChange',
  [CAU_ALERTS_TYPES.RI_EXPIRATION]: 'isShowAlertRiExpiration',
  [CAU_ALERTS_TYPES.SP_EXPIRATION]: 'isShowAlertSpExpiration',
};
export const alertStatusToActionButtonsArray = {
  [CAU_ALERTS_STATUS.ADD]: [],
  [CAU_ALERTS_STATUS.REMOVE]: [],
  [CAU_ALERTS_STATUS.EDIT]: ['cancel'],
  [CAU_ALERTS_STATUS.SAVE]: ['edit', 'delete'],
};
export const DAYS_LIMIT_BY_GRAN_LEVEL = {
  [GranularityLevelsTypes.GRAN_LEVEL_MONTHLY]: 365,
  [GranularityLevelsTypes.GRAN_LEVEL_QUARTERLY]: 365,
  [GranularityLevelsTypes.GRAN_LEVEL_YEARLY]: 365 * 3,
  [GranularityLevelsTypes.GRAN_LEVEL_WEEKLY]: 24 * 7,
  [GranularityLevelsTypes.GRAN_LEVEL_DAILY]: 150,
};

// *************** AWS ***********

const createCol = (colName, colTitle, getCellValueFunc = (row) => row[colName]) => ({
  name: colName,
  title: colTitle,
  getCellValue: getCellValueFunc,
});

export const BUDGETS_TABLE_COLUMNS = [
  { columnName: 'budgetName', columnTitle: 'Budget Name' },
  { columnName: 'current', columnTitle: 'Current', columnField: 'currTotalCost' },
  { columnName: 'budgeted', columnTitle: 'Budget', columnField: 'budgetAmount' },
  { columnName: 'budgetForcast', columnTitle: 'Forecasted', columnField: 'totalForcasted' },
  { columnName: 'budgetStatus', columnTitle: 'Current vs. Budgeted' },
  { columnName: 'forcastedStatus', columnTitle: 'Forecasted vs. Budgeted' },
  { columnName: 'userAction', columnTitle: ' ' },
];
export const BUDGETS_TABLE_COLUMNS_WIDTH = [
  { columnName: 'budgetName', width: 200 },
  { columnName: 'current', width: 200 },
  { columnName: 'budgeted', width: 200 },
  { columnName: 'budgetForcast', width: 200 },
  { columnName: 'budgetStatus', width: 200 },
  { columnName: 'forcastedStatus', width: 200 },
  { columnName: 'userAction', width: 50 },
];

export const REPORTS_TABLE_COLUMNS = [
  { name: 'id', title: 'Id', getCellValue: (row) => row.id },
  { name: 'name', title: 'Report Name', getCellValue: (row) => row.name },
  {
    name: 'email',
    title: 'Email',
    getCellValue: (row) => row.email,
  },
  {
    name: 'frequency',
    title: 'Frequency',
    getCellValue: (row) => row.frequency,
  },
  {
    name: 'period',
    title: 'Period',
    getCellValue: (row) => row.period,
  },
  {
    name: 'pricingMode',
    title: 'Pricing Mode',
    getCellValue: ({ isPpApplied }) => (isPpApplied ? 'Customer' : 'Partner'),
  },
  {
    name: 'updateTime',
    title: 'Date Updated',
    getCellValue: (row) => row.updateTime,
  },
  {
    name: 'creationDate',
    title: 'Date Created',
    getCellValue: (row) => row.creationDate,
  },
  {
    name: 'reportType',
    title: 'Type',
    getCellValue: (row) => (row.snapshot ? 'Snapshot' : mapReportTypeToDisplay.get(row.reportType)),
  },
  {
    name: 'userAction',
    title: 'Actions',
    getCellValue: (row) => row.userAction,
  },
];
export const REPORTS_COLUMN_WIDTHS = [
  { columnName: 'id', width: 100 },
  { columnName: 'name', width: 200 },
  { columnName: 'email', width: 150 },
  { columnName: 'frequency', width: 150 },
  { columnName: 'period', width: 150 },
  { columnName: 'updateTime', width: 150 },
  { columnName: 'creationDate', width: 150 },
  { columnName: 'userAction', width: 230 },
  { columnName: 'reportType', width: 150 },
  { columnName: 'pricingMode', width: 150 },
];

export const REPORTS_TABLE_COLUMN_EXTENSIONS = [
  { columnName: 'id', align: 'center' },
  { columnName: 'userAction', align: 'center' },
];
export const REPORTS_DEFAULT_HIDDEN_COLUMN_NAMES = ['id'];
export const REPORTS_DEFAULT_SORTING = [{ columnName: 'updateTime', direction: 'desc' }];

// RDS ************************

export const RDS_TABLE_COLUMNS = [
  {
    name: 'linkedAccountName',
    title: 'Linked Account',
    getCellValue: (row) => {
      const id = row.linkedAccountName !== row.linkedAccountId ? ` (${row.linkedAccountId})` : '';
      return `${row.linkedAccountName}${id}`;
    },
  },
  { name: 'dbName', title: 'DB Name', getCellValue: (row) => row.dbName },
  { name: 'dbType', title: 'DB Type', getCellValue: (row) => row.dbType },
  {
    name: 'instanceType',
    title: 'Instance Type',
    getCellValue: (row) => row.instanceType,
  },
  { name: 'regionTag', title: 'Region', getCellValue: (row) => row.regionTag },
  { name: 'cost', title: 'On-demand Cost', getCellValue: (row) => row.cost },
  {
    name: 'riCoveragePercent',
    title: 'RI Coverage',
    getCellValue: (row) => `${(row.riCoveragePercent * 100).toFixed(1)} %`,
  },
  { name: 'isActive', title: 'Live', getCellValue: (row) => row.isActive },
  { name: 'lastUpdate', title: 'Last Active Date', getCellValue: (row) => `${row.usageDate.slice(0, 10)}` },
];
export const RDS_TABLE_CSV_RI_DATA_COL = 'RI Data (RI (ARN) / On-Demand, Quantity, Percent)';
export const RDS_TABLE_CSV_COLUMNS = [
  { name: 'linkedAccountName', title: 'Linked Account Name' },
  { name: 'linkedAccountId', title: 'Linked Account Id' },
  { name: 'dbName', title: 'DB Name' },
  { name: 'dbType', title: 'DB Type' },
  { name: 'instanceType', title: 'Instance Type' },
  { name: 'regionTag', title: 'Region' },
  { name: 'cost', title: 'On-demand Cost' },
  { name: 'riCoveragePercent', title: 'RI Coverage' },
  { name: 'isActive', title: 'Live' },
  { name: RDS_TABLE_CSV_RI_DATA_COL, title: RDS_TABLE_CSV_RI_DATA_COL },
  { name: 'usageDate', title: 'Usage Date' },
];

export const RDS_COLUMN_WIDTHS = [
  { columnName: 'linkedAccountName', width: 200 },
  { columnName: 'linkedAccountId', width: 200 },
  { columnName: 'dbName', width: 200 },
  { columnName: 'dbType', width: 150 },
  { columnName: 'instanceType', width: 150 },
  { columnName: 'regionTag', width: 150 },
  { columnName: 'cost', width: 150 },
  { columnName: 'riCoveragePercent', width: 150 },
  { columnName: 'isActive', width: 150 },
  { columnName: 'lastUpdate', width: 150 },
];

export const RDS_TABLE_COLUMN_EXTENSIONS = [
  { columnName: 'cost', align: 'center' },
  {
    columnName: 'isActive',
    align: 'center',
  },
];
export const RDS_DEFAULT_HIDDEN_COLUMN_NAMES = ['linkedAccountId', 'year', 'month'];
export const RDS_DEFAULT_SORTING = [{ columnName: 'cost', direction: 'desc' }];
export const RDS_CURRENCIES_COLUMNS = ['cost'];

// EBS
export const EBS_TABLE_COLUMNS = [
  {
    name: 'linkedAccountName',
    title: 'Linked Account Name',
    getCellValue: (row) => row.linkedAccountName,
  },
  {
    name: 'linkedAccountId',
    title: 'Linked Account',
    getCellValue: (row) => row.linkedAccountId,
  },
  { name: 'volumeId', title: 'Volume Id', getCellValue: (row) => row.volumeId },
  { name: 'ebsName', title: 'EBS Name', getCellValue: (row) => row.ebsName },
  { name: 'regionTag', title: 'Region', getCellValue: (row) => row.regionTagName },
  { name: 'cost', title: 'Cost', getCellValue: (row) => row.cost },
  { name: 'quantity', title: 'Quantity', getCellValue: (row) => row.quantity.toFixed(3) },
  { name: 'month', title: 'Month', getCellValue: (row) => row.usageMonth },
  { name: 'year', title: 'Year', getCellValue: (row) => row.usageYear },
];

export const EBS_COLUMN_WIDTHS = [
  { columnName: 'linkedAccountName', width: 200 },
  { columnName: 'linkedAccountId', width: 200 },
  { columnName: 'volumeId', width: 200 },
  { columnName: 'ebsName', width: 200 },
  { columnName: 'regionTag', width: 150 },
  { columnName: 'cost', width: 150 },
  { columnName: 'quantity', width: 150 },
  { columnName: 'month', width: 150 },
  { columnName: 'year', width: 150 },
];

export const EBS_DEFAULT_SORTING = [{ columnName: 'cost', direction: 'desc' }];
export const EBS_CURRENCIES_COLUMNS = ['cost'];
export const EBS_TABLE_COLUMN_EXTENSIONS = [
  { columnName: 'month', align: 'center' },
  {
    columnName: 'year',
    align: 'center',
  },
];
export const EBS_DEFAULT_HIDDEN_COLUMN_NAMES = ['linkedAccountName', 'linkedAccountId'];
export const S3_TABLE_COLUMNS = [
  {
    name: 'linkedAccountName',
    title: 'Linked Account',
    getCellValue: (row) => {
      const id = row.linkedAccountName !== row.linkedAccountId ? ` (${row.linkedAccountId})` : '';
      return `${row.linkedAccountName}${id}`;
    },
  },
  {
    name: 'bucketName',
    title: 'Bucket Name',
    getCellValue: (row) => row.bucketName,
  },
  { name: 'cost', title: 'Cost', getCellValue: (row) => row.cost },
  {
    name: 'size',
    title: 'Size',
    getCellValue: (row) => {
      if (row.size === -1) {
        return '-';
      }
      return row.size;
    },
  },
  {
    name: 'region',
    title: 'Region',
    getCellValue: (row) => {
      if (row.region === 'Not Available') {
        return '-';
      }
      return row.region;
    },
  },
  {
    name: 'versioning',
    title: 'Versioning',
    getCellValue: (row) => {
      if (row.versioning === -1) {
        return '-';
      }
      return row.versioning;
    },
  },
  { name: 'lifecycle', title: 'Life cycle', getCellValue: (row) => row.lifecycle },
  { name: 'month', title: 'Month', getCellValue: (row) => row.month },
  { name: 'year', title: 'Year', getCellValue: (row) => row.year },
];

export const S3_TABLE_CSV_COLUMNS = [
  { name: 'linkedAccountName', title: 'Linked Account Name' },
  { name: 'linkedAccountId', title: 'Linked Account Id' },
  { name: 'bucketName', title: 'Bucket Name' },
  { name: 'cost', title: 'Cost' },
  { name: 'size', title: 'Size' },
  { name: 'region', title: 'Region' },
  { name: 'versioning', title: 'Versioning' },
  { name: 'lifecycle', title: 'Life cycle' },
  { name: 'month', title: 'Month' },
  { name: 'year', title: 'Year' },
];

export const S3_COLUMN_WIDTHS = [
  { columnName: 'linkedAccountName', width: 300 },
  { columnName: 'linkedAccountId', width: 150 },
  { columnName: 'bucketName', width: 300 },
  { columnName: 'cost', width: 150 },
  { columnName: 'size', width: 150 },
  { columnName: 'region', width: 200 },
  { columnName: 'versioning', width: 150 },
  { columnName: 'lifecycle', width: 150 },
  { columnName: 'month', width: 150 },
  { columnName: 'year', width: 150 },
];
export const S3_TABLE_COLUMN_EXTENSIONS = [
  { columnName: 'cost', align: 'center' },
  { columnName: 'size', align: 'center' },
  { columnName: 'lifecycle', align: 'center' },
  { columnName: 'versioning', align: 'center' },
  { columnName: 'region', align: 'center' },
];
export const S3_DEFAULT_HIDDEN_COLUMN_NAMES = ['linkedAccountId', 'year', 'month'];
export const S3_DEFAULT_SORTING = [
  { columnName: 'cost', direction: 'desc' },
  {
    columnName: 'size',
    direction: 'desc',
  },
];
export const S3_CURRENCIES_COLUMNS = ['cost'];

export class S3IsVersioningValues {
  static NO = 0;
  static YES = 1;
}

export class RdsIsActiveValues {
  static NOT_ACTIVE = 0;
  static ACTIVE = 1;
}

export class BudgetPeriodTypes {
  static MONTHLY = 0;
  static QUATERLY = 1;
  static ANNUALY = 2;
}

export class DisplayBudgetPeriodTypes {
  static MONTHLY = 'Monthly';
  static QUATERLY = 'Quaterly';
  static ANNUALY = 'Annualy';
}

export const TempBudgetTypes = {
  CREATE: 'create_budget',
  UPDATED: 'updated_budget',
};

export const BudgetAlertsTypes = {
  ACTUAL_USAGE: 'actualUsage',
  FORECASTED: 'forecasted',
};

export const BudgetTypes = {
  RECURRING: 'recurring',
  EXPIRING: 'expiring',
};

export const BudgetAmountTypes = {
  FIXED: 'fixed',
  PLANNED: 'planned',
};

export const BudgetMonthChange = {
  PREVIOUS: 'prev',
  NEXT: 'next',
};

export const BudgetAlertGranularityTypes = {
  DAILY: 'daily',
  MONTHLY: 'monthly',
  TOTAL: 'total',
};

export const BudgetAlertChecboxTypes = {
  WHEN_TO_ALERT: 'whenToAlert',
  ALERT_GRANULARITY: 'alertGranularity',
};

export class FilterTypes {
  static INCLUDE = 0;
  static EXCLUDE = 1;
  static LIKE = 3;
}

export class DisplaySpAnalyzerFilterTypes {
  static INCLUDE = 'Include';
  static EXCLUDE = 'Exclude';
}

export const mapBudgetConstValueToDisplayValue = new Map([
  [BudgetPeriodTypes.MONTHLY, DisplayBudgetPeriodTypes.MONTHLY],
  [BudgetPeriodTypes.QUATERLY, DisplayBudgetPeriodTypes.QUATERLY],
  [BudgetPeriodTypes.ANNUALY, DisplayBudgetPeriodTypes.ANNUALY],
]);

export const mapSpAnalyzerConstValueToDisplayValue = new Map([
  [FilterTypes.INCLUDE, DisplaySpAnalyzerFilterTypes.INCLUDE],
  [FilterTypes.EXCLUDE, DisplaySpAnalyzerFilterTypes.EXCLUDE],
]);

export class SpAnalyzerPaymentOptions {
  static ALL_UPFRONT = 'All Upfront';
  static PARTIAL_UPFRONT = 'Partial Upfront';
  static NO_UPFRONT = 'No Upfront';
}

export const SpObligatoryFilters = [];

export const SpAnalyzerTerm = {
  ONE_YEAR: '1-Year',
  THREE_YEAR: '3-Year',
};

export const ElasticacheRiPlans = {
  NO_UPFRONT_1_YEAR: 'standard 1 year no upfront',
  PARTIAL_UPFRONT_1_YEAR: 'standard 1 year partial upfront',
  FULL_UPFRONT_1_YEAR: 'standard 1 year full upfront',
  NO_UPFRONT_3_YEAR: 'standard 3 year no upfront',
  PARTIAL_UPFRONT_3_YEAR: 'standard 3 year partial upfront',
  FULL_UPFRONT_3_YEAR: 'standard 3 year full upfront',
};

export const AGGREGATION_FUNCTION = {
  PERCENTILE_95: '95th Percentile',
  PERCENTILE_99: '99th Percentile',
  MAX: 'Max',
};

export const SpAnalyzerOs = [
  'Linux',
  'Red Hat Enterprise Linux',
  'Windows',
  'SUSE Linux',
  'Windows with SQL Web',
  'Linux with SQL Web',
  'Windows with SQL Server Standard',
  'Linux with SQL Server Standard',
  'Windows with SQL Server Enterprise',
  'Linux with SQL Server Enterprise',
  'BYOL',
];

export const SP_DISCOUNT_TABLE_COLUMNS = [
  { name: 'instanceType', title: 'Instance Type', getCellValue: (row) => row.instanceType },
  { name: 'region', title: 'Region', getCellValue: (row) => row.region },
  { name: 'os', title: 'Operating System', getCellValue: (row) => row.os },
  { name: 'discount', title: 'Savings over On-Demand', getCellValue: (row) => Number(row.discount) },
  // eslint-disable-next-line radix
  { name: 'totalUsage', title: 'On-Demand Hours', getCellValue: (row) => parseInt(row.totalUsage, 0) },
];

export const SP_DISCOUNT_DETAIL_NAMES = [
  'startTime',
  'offeringClass',
  'paymentOption',
  'recurringCharges',
  'scope',
  'term',
];

export const SP_DISCOUNT_COLUMN_EXTENSIONS = [];
export const SP_DISCOUNT_DEFAULT_SORTING = [
  { columnName: 'discount', direction: 'desc' },
  { columnName: 'total_usage', direction: 'desc' },
];

// Big query constants
export const bigQueryColumnsArray = [
  { name: 'usageDate', title: 'Usage Date' },
  { name: 'projectId', title: 'Project Id' },
  { name: 'accountId', title: 'Account Id' },
  { name: 'jobId', title: 'Job Id' },
  { name: 'jobQuery', title: 'Query' },
  { name: 'statusCode', title: 'Status Code' },
  { name: 'totalCost', title: 'Total Cost' },
];

export const bigQueryColumnWidths = [
  { columnName: 'usageDate', width: 150 },
  { columnName: 'projectId', width: 250 },
  { columnName: 'accountId', width: 150 },
  { columnName: 'jobId', width: 200 },
  { columnName: 'statusCode', width: 150 },
  { columnName: 'jobQuery', width: 450 },
  { columnName: 'totalCost', width: 250 },
];

export const BigQueryFilters = {
  PROJECTS: 'projects',
  STATUS_CODE: 'statusCode',
};

export const StatusCodeOptions = {
  ALL: 'All',
  SUCCESS: 'Success',
  FAILED: 'Failed',
};

export const anomalyDetectionColumnsArray = (cloudType = CLOUD_TYPE_IDS.AWS, userType, showDivisionCol) => [
  { name: 'usageDate', title: 'Date' },
  ...(showDivisionCol
    ? [
        {
          name: 'divisionName',
          title: userType === UsersType.RESELLER ? 'Customer' : 'Cost Center',
        },
      ]
    : []),
  { name: 'linkedAccountName', title: `${cloudType === CLOUD_TYPE_IDS.GCP ? 'Project Name' : 'Account Name'}` },
  { name: 'serviceName', title: 'Service' },
  { name: 'beforeCost', title: 'Previous Cost' },
  { name: 'currentCost', title: 'Current Cost' },
  { name: 'percentageChange', title: '% Change' },
  { name: 'usageType', title: cloudType === CLOUD_TYPE_IDS.GCP ? 'Operation' : 'Usage Type' },
  { name: 'details', title: 'Details' },
  { name: 'userAction', title: ' ' },
];

export const anomalyDetectionColumnWidths = [
  { columnName: 'divisionName', width: 150 },
  { columnName: 'usageType', width: 150 },
  { columnName: 'serviceName', width: 200 },
  { columnName: 'linkedAccountName', width: 150 },
  { columnName: 'usageDate', width: 150 },
  { columnName: 'beforeCost', width: 150 },
  { columnName: 'currentCost', width: 150 },
  { columnName: 'difference', width: 150 },
  { columnName: 'percentageChange', width: 150 },
  { columnName: 'details', width: 150 },
  { columnName: 'userAction', width: 100 },
];

export const anomalyDetectionRulesColumnsArray = [
  { name: 'ruleName', title: 'Rule Name' },
  { name: 'minAmountChange', title: 'Minimum Amount Change' },
  { name: 'minPercentChange', title: 'Minimum Percent Change' },
  { name: 'userAction', title: ' ' },
];

export const anomalyDetectionRulesColumnWidths = [
  { columnName: 'ruleName', width: 250 },
  { columnName: 'minAmountChange', width: 250 },
  { columnName: 'minPercentChange', width: 250 },
  { columnName: 'userAction', width: 100 },
];

export const AnomalyRulesModalTypes = {
  EDIT: 'edit',
  CREATE: 'create',
};

// Idle instances

export const idleCriteriaRulesColumnsArray = [
  { name: 'criteriaName', title: 'Criteria Name' },
  createCol('maxCpu', 'Max Cpu Util', (row) => percentStr(row.maxCpu)),
  createCol('maxNetwork', 'Max Network Limit', (row) => `${row.maxNetwork} MB`),
  { name: 'userAction', title: ' ' },
];

export const idleCriteriaRulesColumnWidths = [
  { columnName: 'criteriaName', width: 250 },
  { columnName: 'maxCpu', width: 250 },
  { columnName: 'maxNetwork', width: 250 },
  { columnName: 'userAction', width: 100 },
];

export const IdleCriteriaModalTypes = {
  EDIT: 'edit',
  CREATE: 'create',
};

export const k8sColumnNames = {
  USAGE_DATE: 'usageDate',
  SERVICE_NAME: 'serviceName',
  CLUSTER_NAME: 'name',
  MEMORY_UTIL: 'memoryUtilization',
  CPU_UTIL: 'avgCpu',
  NODES: 'numOfNodes',
  PODS: 'numOfPods',
  TOTAL_COST: 'totalCost',
};

export const k8sDisplayNames = {
  USAGE_DATE: 'Usage Date',
  SERVICE_NAME: 'Service Name',
  CLUSTER_NAME: 'Cluster Name',
  MEMORY_UTIL: 'Memory Utilization',
  CPU_UTIL: 'Avg CPU Utilization',
  NODES: 'Nodes',
  PODS: 'Pods',
  TOTAL_COST: 'Total Cost',
};

// AZURE_STORAGE ************************

export const AZURE_STORAGE_TABLE_COLUMNS = [
  {
    name: 'accountName',
    title: 'Subscription Name',
    getCellValue: (row) => row.linkedAccountName || row.linkedAccountId,
  },
  { name: 'storageName', title: 'Storage Name', getCellValue: (row) => row.storageName },
  { name: 'storageType', title: 'Storage Type', getCellValue: (row) => row.storageType },
  { name: 'resourceGroup', title: 'Resource Group', getCellValue: (row) => row.resourceGroup },
  { name: 'regionTagName', title: 'Region Name', getCellValue: (row) => row.regionTagName },
  { name: 'totalCost', title: 'Cost', getCellValue: (row) => row.totalCost },
  { name: 'quantity', title: 'Quantity', getCellValue: (row) => row.quantity },
];
export const AZURE_STORAGE_COLUMN_WIDTHS = [
  { columnName: 'accountName', width: 250 },
  { columnName: 'storageType', width: 250 },
  { columnName: 'storageName', width: 250 },
  { columnName: 'resourceGroup', width: 250 },
  { columnName: 'regionTagName', width: 250 },
  { columnName: 'quantity', width: 250 },
  { columnName: 'totalCost', width: 250 },
];

export const AZURE_STORAGE_TABLE_COLUMN_EXTENSIONS = [];
export const AZURE_STORAGE_DEFAULT_HIDDEN_COLUMN_NAMES = ['accountName'];
export const AZURE_STORAGE_DEFAULT_SORTING = [{ columnName: 'totalCost', direction: 'desc' }];
export const AZURE_STORAGE_CURRENCIES_COLUMNS = ['totalCost'];

// AZURE_DB_COSTS_USAGE ************************

// eslint-disable-next-line max-len
// id, aws_account_id, account_id, linked_account_id, usage_date, db_name, db_type, region_tag, total_cost, is_active, total_hours, db_creation_time;
// db name,*Type,*Instance model, refion, cost, link acc id....

export const AZURE_DB_COSTS_TABLE_COLUMNS = [
  { name: 'dbName', title: 'DB Name', getCellValue: (row) => row.dbName },
  {
    name: 'subscription',
    title: 'Subscription',
    getCellValue: (row) =>
      `${
        row.subscriptionName && row.subscriptionName !== row.subscriptionId
          ? `${row.subscriptionName} (${row.subscriptionId})`
          : row.subscriptionId
      }`,
  },
  { name: 'dbType', title: 'Type', getCellValue: (row) => row.dbType },
  { name: 'regionTag', title: 'Region', getCellValue: (row) => row.regionTag },
  { name: 'totalCost', title: 'Cost', getCellValue: (row) => row.totalCost },
  { name: 'isActive', title: 'Live', getCellValue: (row) => row.isActive },
  { name: 'lastUpdate', title: 'Last Active Date', getCellValue: (row) => `${row.usageDate.slice(0, 10)}` },
];
export const AZURE_DB_COSTS_COLUMN_WIDTHS = [
  { columnName: 'dbName', width: 350 },
  { columnName: 'subscription', width: 350 },
  { columnName: 'dbType', width: 350 },
  { columnName: 'regionTag', width: 250 },
  { columnName: 'totalCost', width: 250 },
  { columnName: 'isActive', width: 150 },
  { columnName: 'lastUpdate', width: 200 },
];

export const IDLE_INSTANCES_TABLE_COLUMNS = [
  { name: 'criteriaName', title: 'Criteria Name' },
  {
    name: 'linkedAccount',
    title: 'Linked Account',
  },
  { name: 'instanceName', title: 'Instance' },
  { name: 'instanceType', title: 'Instance Type' },
  { name: 'region', title: 'Region' },
  { name: 'totalWaste', title: 'Waste' },
  { name: 'numOfDays', title: '# Days' },
  { name: 'dailyAvg', title: 'Daily Avg Idle Time' },
  { name: 'lastIdleDate', title: 'Last Idle Date' },
];

export const IDLE_INSTANCES_COLUMN_WIDTHS = [
  { columnName: 'criteriaName', width: 150 },
  { columnName: 'linkedAccount', width: 250 },
  { columnName: 'instanceName', width: 375 },
  { columnName: 'instanceType', width: 250 },
  { columnName: 'region', width: 150 },
  { columnName: 'totalWaste', width: 150 },
  { columnName: 'numOfDays', width: 150 },
  { columnName: 'dailyAvg', width: 200 },
  { columnName: 'lastIdleDate', width: 200 },
];

export const IDLE_INSTANCES_DEFAULT_SORTING = [{ columnName: 'dailyAvg', direction: 'desc' }];
export const AZURE_DB_COSTS_TABLE_COLUMN_EXTENSIONS = [];
export const AZURE_DB_COSTS_DEFAULT_HIDDEN_COLUMN_NAMES = [];
export const AZURE_DB_COSTS_DEFAULT_SORTING = [{ columnName: 'totalCost', direction: 'desc' }];
export const AZURE_DB_COSTS_CURRENCIES_COLUMNS = ['totalCost'];
