import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { cloudTypeToIcon } from 'shared/constants/appConstants';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { mapCloudTypeIdToDescription } from 'users/constants/usersConstants';
import { Chip } from '@mui/material';
import styles from './SelectedAccounts.module.scss';
import { linkedAccountsSelectedCounter, totalLinkedAccountsCounter } from './utils';

const SelectedAccount = ({ accList, selectedAccounts, setSelectedAccounts, linkedAccounts, acc }) => {
  const [open, setOpen] = useState(false);
  const fullAccount = accList.find((ac) => ac.accountId === acc.accountId);
  const selectedLns =
    (acc.linkedAccountIds === 'all' ? linkedAccounts.get(acc.accountId)?.length : acc.linkedAccountIds?.length) || 0;
  const handleClickDelete = () => setSelectedAccounts(selectedAccounts.filter((sa) => sa.accountId !== acc.accountId));
  return (
    <div className={styles.account}>
      <Chip
        className={styles.accountChip}
        onDelete={handleClickDelete}
        deleteIcon={<GenerateIcon iconName={ICONS.xMark.name} className="chip-delete-icon chip-delete-icon-white" />}
        label={
          <p>
            {fullAccount.accountName}{' '}
            <span>{`(${selectedLns}/${linkedAccounts.get(acc.accountId)?.length || 0})`}</span>
          </p>
        }
      />
      {acc.linkedAccountIds === 'all' ? (
        <Chip
          label="All"
          icon={<GenerateIcon iconName={ICONS.link.name} />}
          deleteIcon={<GenerateIcon iconName={ICONS.xMark.name} className="chip-delete-icon" />}
          onDelete={handleClickDelete}
        />
      ) : (
        acc.linkedAccountIds?.slice(0, open ? Infinity : 3).map((ln) => {
          const linkedAccount = linkedAccounts.get(acc.accountId)?.find((la) => la.linkedAccountId === ln);
          return (
            <Chip
              label={linkedAccount?.linkedAccountName ? `${linkedAccount.linkedAccountName} (${ln})` : ln}
              key={ln}
              onDelete={() =>
                setSelectedAccounts(
                  selectedAccounts.map((sa) =>
                    sa.accountId !== acc.accountId
                      ? sa
                      : {
                          ...sa,
                          linkedAccountIds: sa.linkedAccountIds.filter((linkedAcc) => linkedAcc !== ln),
                        },
                  ),
                )
              }
              deleteIcon={<GenerateIcon iconName={ICONS.xMark.name} className="chip-delete-icon" />}
              icon={<GenerateIcon iconName={ICONS.link.name} />}
            />
          );
        })
      )}
      {!open && selectedLns > 3 && acc.linkedAccountIds !== 'all' ? (
        <Chip
          label={selectedLns - 3}
          icon={<GenerateIcon iconName={ICONS.plus.name} />}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
          className={styles.showMoreChip}
        />
      ) : null}
    </div>
  );
};

SelectedAccount.propTypes = {
  accList: PropTypes.array.isRequired,
  selectedAccounts: PropTypes.array.isRequired,
  setSelectedAccounts: PropTypes.func.isRequired,
  linkedAccounts: PropTypes.object.isRequired,
  acc: PropTypes.object.isRequired,
};

const SelectedAccounts = ({ accounts, selectedAccounts, setSelectedAccounts, linkedAccounts }) => {
  const renderCloudTitle = (cloud, accLength, accTotalLength) => {
    const CloudIcon = cloudTypeToIcon[+cloud];
    const selected = linkedAccountsSelectedCounter(accounts, selectedAccounts, linkedAccounts, { cloud });
    const total = totalLinkedAccountsCounter({ [cloud]: accounts[cloud] }, linkedAccounts);
    return (
      <div className={styles.cloudHeader}>
        <div>
          <CloudIcon />
          <p>
            {mapCloudTypeIdToDescription.get(+cloud)}{' '}
            <Tooltip title="Accounts">
              <span>
                ({accLength}/{accTotalLength})
              </span>
            </Tooltip>
          </p>
        </div>
        <p className={styles.linkedAccountsHeader}>
          Total Linked Accounts{' '}
          <span>
            ({selected}/{total})
          </span>
        </p>
      </div>
    );
  };
  return (
    <div className={styles.container}>
      {Object.entries(accounts).map(([cloud, accList]) => {
        const selected = selectedAccounts.filter((sa) => accList.some((acc) => acc.accountId === sa.accountId));
        if (selected.length === 0) {
          return null;
        }
        return (
          <div key={cloud}>
            {renderCloudTitle(cloud, selected.length, accList.length)}
            <div className={styles.accountsList}>
              {selected.map((acc) => (
                <SelectedAccount
                  accList={accList}
                  key={acc.accountId}
                  acc={acc}
                  setSelectedAccounts={setSelectedAccounts}
                  selectedAccounts={selectedAccounts}
                  linkedAccounts={linkedAccounts}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

SelectedAccounts.propTypes = {
  accounts: PropTypes.object.isRequired,
  selectedAccounts: PropTypes.array.isRequired,
  setSelectedAccounts: PropTypes.func.isRequired,
  linkedAccounts: PropTypes.object.isRequired,
};

export default SelectedAccounts;
