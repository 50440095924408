import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { CLOUD_TYPE_IDS, mapCloudTypeIdToDescription } from 'users/constants/usersConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { useQuery, useQueryClient } from 'react-query';
import { isNumber } from 'lodash';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { sanitizeQuery } from 'shared/utils/apiUtil';
import { createFiltersDistinctValuesDisplayMap, getPageFiltersRaw } from 'invoices/utils/filtersUtils';

const SEARCH_TAG_MIN_LENGTH = 3;

export function useFiltersAccountTagsBySearchStr() {
  const fetchValues = async ({ searchStr, tagKey }) => {
    const searchStrEmpty = !searchStr || searchStr.length < SEARCH_TAG_MIN_LENGTH;
    if (searchStrEmpty && !tagKey) {
      return [];
    }
    const tagKeyQuery = tagKey ? `&tagKey=${tagKey}` : '';
    let apiQuery = `/api/v1/invoices/service-costs/distinct-tags/account/search?searchStr=${searchStr}${tagKeyQuery}`;
    apiQuery = sanitizeQuery(apiQuery);
    const response = await API.get('billings', apiQuery);
    return response.accounttags || [];
  };
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery([apiConstants.QUERY_KEYS.FILTERS_ACCOUNT_TAGS_SEARCH], fetchValues, {
    enabled: false,
  });
  return {
    accountTagsFetcher: {
      onLoad: (searchStr, tagKey) =>
        queryClient.fetchQuery([apiConstants.QUERY_KEYS.FILTERS_ACCOUNT_TAGS_SEARCH], () =>
          fetchValues({ searchStr, tagKey }),
        ),
      fetchData: (searchStr, tagKey) =>
        useQuery([apiConstants.QUERY_KEYS.FILTERS_ACCOUNT_TAGS_SEARCH, tagKey, searchStr], () =>
          fetchValues({ searchStr, tagKey }),
        ),
      isLoading: isLoading || isFetching,
      values: data,
    },
  };
}

export function useFiltersVirtualTagsBySearchStr() {
  const fetchValues = async ({ searchStr, tagKey }) => {
    const searchStrEmpty = !searchStr || searchStr.length < SEARCH_TAG_MIN_LENGTH;
    if (searchStrEmpty && !tagKey) {
      return [];
    }
    const tagKeyQuery = tagKey ? `&tagKey=${tagKey}` : '';
    let apiQuery = `/api/v1/invoices/service-costs/distinct-tags/virtual/search?searchStr=${searchStr}${tagKeyQuery}`;
    apiQuery = sanitizeQuery(apiQuery);
    const response = await API.get('billings', apiQuery);
    return response.customtags || [];
  };
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery([apiConstants.QUERY_KEYS.FILTERS_VIRTUAL_TAGS_SEARCH], fetchValues, {
    enabled: false,
  });
  return {
    virtualTagsFetcher: {
      onLoad: (searchStr, tagKey) =>
        queryClient.fetchQuery([apiConstants.QUERY_KEYS.FILTERS_VIRTUAL_TAGS_SEARCH], () =>
          fetchValues({ searchStr, tagKey }),
        ),
      fetchData: (searchStr, tagKey) =>
        useQuery([apiConstants.QUERY_KEYS.FILTERS_VIRTUAL_TAGS_SEARCH, tagKey], () =>
          fetchValues({ searchStr, tagKey }),
        ),
      isLoading: isLoading || isFetching,
      values: data,
    },
  };
}

export function useFiltersCustomTagsBySearchStr() {
  const fetchValues = async ({ searchStr, tagKey }) => {
    const searchStrEmpty = !searchStr || searchStr.length < SEARCH_TAG_MIN_LENGTH;
    if (searchStrEmpty && !tagKey) {
      return [];
    }
    const tagKeyQuery = tagKey ? `&tagKey=${tagKey}` : '';
    let apiQuery = `/api/v1/invoices/service-costs/distinct-tags/custom/search?searchStr=${searchStr}${tagKeyQuery}`;
    apiQuery = sanitizeQuery(apiQuery);
    const response = await API.get('billings', apiQuery);
    return response.customtags || [];
  };
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery([apiConstants.QUERY_KEYS.FILTERS_CUSTOM_TAGS_SEARCH], fetchValues, {
    enabled: false,
  });
  return {
    customTagsFetcher: {
      onLoad: (searchStr, tagKey) =>
        queryClient.fetchQuery([apiConstants.QUERY_KEYS.FILTERS_CUSTOM_TAGS_SEARCH], () =>
          fetchValues({ searchStr, tagKey }),
        ),
      fetchData: (searchStr, tagKey) =>
        useQuery([apiConstants.QUERY_KEYS.FILTERS_CUSTOM_TAGS_SEARCH, tagKey, searchStr], () =>
          fetchValues({ searchStr, tagKey }),
        ),
      isLoading: isLoading || isFetching,
      values: data,
    },
  };
}

export function useFiltersK8SLabelsBySearchStr() {
  const fetchValues = async ({ searchStr, tagKey }) => {
    const searchStrEmpty = !searchStr || searchStr.length < SEARCH_TAG_MIN_LENGTH;
    if (searchStrEmpty && !tagKey) {
      return [];
    }
    const tagKeyQuery = tagKey ? `&tagKey=${tagKey}` : '';
    let apiQuery = `/api/v1/invoices/service-costs/distinct-tags/k8scustom/search?searchStr=${searchStr}${tagKeyQuery}`;
    apiQuery = sanitizeQuery(apiQuery);
    const response = await API.get('billings', apiQuery);
    return response.k8scustomtags || [];
  };
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery([apiConstants.QUERY_KEYS.FILTERS_K8S_TAGS_SEARCH], fetchValues, {
    enabled: false,
  });
  return {
    k8sLabelsFetcher: {
      onLoad: (searchStr, tagKey) =>
        queryClient.fetchQuery([apiConstants.QUERY_KEYS.FILTERS_K8S_TAGS_SEARCH], () =>
          fetchValues({ searchStr, tagKey }),
        ),
      fetchData: (searchStr, tagKey) =>
        useQuery([apiConstants.QUERY_KEYS.FILTERS_K8S_TAGS_SEARCH, tagKey], () => fetchValues({ searchStr, tagKey })),
      isLoading: isLoading || isFetching,
      values: data,
    },
  };
}

export function useFilterRecDBCustomTagsBySearchStr() {
  const isValid = (searchStr) => !!searchStr && searchStr.length >= SEARCH_TAG_MIN_LENGTH;
  const fetchValues = async ({ searchStr }) => {
    if (!isValid(searchStr)) {
      return [];
    }
    let apiQuery = `/api/v1/invoices/service-costs/distinct-tags/recdbcustom/search?searchStr=${searchStr}`;
    apiQuery = sanitizeQuery(apiQuery);
    const response = await API.get('billings', apiQuery);
    return response.recdbcustomtags || [];
  };
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery([apiConstants.QUERY_KEYS.FILTERS_RECDB_TAGS_SEARCH], fetchValues, {
    enabled: false,
  });
  return {
    recDbCustomTagsFetcher: {
      onLoad: (searchStr) =>
        queryClient.fetchQuery([apiConstants.QUERY_KEYS.FILTERS_RECDB_TAGS_SEARCH], () => fetchValues({ searchStr })),
      fetchData: (searchStr) =>
        useQuery([apiConstants.QUERY_KEYS.FILTERS_RECDB_TAGS_SEARCH, searchStr], () => fetchValues({ searchStr }), {
          enabled: isValid(searchStr),
        }),
      isLoading: isLoading || isFetching,
      values: data,
    },
  };
}

export function useFiltersViewpointsMappings() {
  const fetchValues = async ({ searchStr, viewpointName, isK8S }) => {
    if (!viewpointName) {
      return [];
    }
    const searchLower = (searchStr || '').toLowerCase();
    const response = await API.get(
      'billings',
      `/api/v1/usage/business-mapping/viewpoints/${viewpointName}/mappings/distinct`,
    );
    return response
      .filter((item) => !!item.k8s === !!isK8S)
      .filter(
        (item) => item.id.includes(searchLower) || (item.name.split(': ')[1] || '').toLowerCase().includes(searchLower),
      );
  };
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery(
    [apiConstants.QUERY_KEYS.FILTERS_VIEWPOINT_MAPPINGS_SEARCH],
    fetchValues,
    {
      enabled: false,
    },
  );
  return {
    viewpointMappingsFetcher: {
      onLoad: (isK8S, searchStr, viewpointName) =>
        queryClient.fetchQuery([apiConstants.QUERY_KEYS.FILTERS_VIEWPOINT_MAPPINGS_SEARCH], () =>
          fetchValues({ searchStr, viewpointName, isK8S }),
        ),
      fetchData: (isK8S, searchStr, viewpointName) =>
        useQuery([apiConstants.QUERY_KEYS.FILTERS_VIEWPOINT_MAPPINGS_SEARCH, viewpointName], () =>
          fetchValues({ searchStr, viewpointName, isK8S }),
        ),
      isLoading: isLoading || isFetching,
      values: data,
    },
  };
}

export function useFiltersUsageTypes() {
  const fetchValues = async () => {
    const response = await API.get('billings', `/api/v1/invoices/service-costs/distinct-usage-types`);
    return response || [];
  };
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery([apiConstants.QUERY_KEYS.FILTERS_USAGE_TYPES], fetchValues, {
    enabled: false,
  });
  return {
    usageTypesFetcher: {
      onLoad: () =>
        queryClient.fetchQuery([apiConstants.QUERY_KEYS.FILTERS_USAGE_TYPES], fetchValues, {
          staleTime: Infinity,
        }),
      fetchData: (_, __, enabled) => useQuery([apiConstants.QUERY_KEYS.FILTERS_USAGE_TYPES], fetchValues, { enabled }),
      isLoading: isLoading || isFetching,
      values: data,
    },
  };
}

export function useFiltersNamespaces() {
  const fetchValues = async () => {
    const response = await API.get('billings', `/api/v1/invoices/service-costs/distinct-namespaces`);
    return response || [];
  };
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery([apiConstants.QUERY_KEYS.FILTERS_NAMESPACES], fetchValues, {
    enabled: false,
  });
  return {
    namespacesFetcher: {
      onLoad: () =>
        queryClient.fetchQuery([apiConstants.QUERY_KEYS.FILTERS_NAMESPACES], fetchValues, {
          staleTime: Infinity,
        }),
      fetchData: (_, __, enabled) => useQuery([apiConstants.QUERY_KEYS.FILTERS_NAMESPACES], fetchValues, { enabled }),
      isLoading: isLoading || isFetching,
      values: data,
    },
  };
}

export function useFiltersPods() {
  const isValid = (searchStr) => !!searchStr && searchStr.length >= SEARCH_TAG_MIN_LENGTH;
  const fetchValues = async ({ searchStr }) => {
    if (!isValid(searchStr)) {
      return [];
    }
    const response = await API.get('billings', `/api/v1/invoices/service-costs/distinct-pods?value=${searchStr}`);
    return response || [];
  };
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery([apiConstants.QUERY_KEYS.FILTERS_PODS], fetchValues, {
    enabled: false,
  });

  return {
    podsFetcher: {
      onLoad: (searchStr) =>
        queryClient.fetchQuery([apiConstants.QUERY_KEYS.FILTERS_PODS], () => fetchValues({ searchStr })),
      fetchData: (searchStr) =>
        useQuery([apiConstants.QUERY_KEYS.FILTERS_PODS, searchStr], () => fetchValues({ searchStr }), {
          enabled: isValid(searchStr),
        }),
      isLoading: isLoading || isFetching,
      values: data,
    },
  };
}

export function useFiltersResources() {
  const isValid = (searchStr) => !!searchStr && searchStr.length >= SEARCH_TAG_MIN_LENGTH;
  const fetchValues = async ({ searchStr }) => {
    if (!isValid(searchStr)) {
      return [];
    }
    const response = await API.get('billings', `/api/v1/usage/resource-explorer/distinct?value=${searchStr}`);
    const result = (response || []).map((item) => item.label);
    return result || [];
  };
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery([apiConstants.QUERY_KEYS.FILTERS_RESOURCES_SEARCH], fetchValues, {
    enabled: false,
  });
  return {
    resourcesFetcher: {
      onLoad: (searchStr) =>
        queryClient.fetchQuery([apiConstants.QUERY_KEYS.FILTERS_RESOURCES_SEARCH], () => fetchValues({ searchStr })),
      fetchData: (searchStr) =>
        useQuery([apiConstants.QUERY_KEYS.FILTERS_RESOURCES_SEARCH, searchStr], () => fetchValues({ searchStr }), {
          enabled: isValid(searchStr),
        }),
      isLoading: isLoading || isFetching,
      values: data,
    },
  };
}

function usePlainFiltersDistinctValues({ accountType, accountKey, enabled }) {
  const { data, isLoading } = useQuery(
    [apiConstants.QUERY_KEYS.FILTERS_DISTINCT_VALUES, { accountType }],
    async () => {
      if (!isNumber(accountType) || !isNumber(accountKey)) {
        return new Map();
      }
      const { startDate, endDate } = buildStartAndEndDate(null, null);
      let apiQuery = `/api/v1/invoices/service-costs/distinct?startDate=${startDate}&endDate=${endDate}`;
      apiQuery = sanitizeQuery(apiQuery);
      const distinctValues = await API.get('billings', apiQuery);
      const mapFiltersDistinctValues = new Map();
      mapFiltersDistinctValues.set(AwsCommonFields.CLOUD_PROVIDER, [
        mapCloudTypeIdToDescription.get(CLOUD_TYPE_IDS.AWS),
        mapCloudTypeIdToDescription.get(CLOUD_TYPE_IDS.AZURE),
        mapCloudTypeIdToDescription.get(CLOUD_TYPE_IDS.GCP),
      ]);
      const selectedValuesKeys = [
        'division',
        'businessmappingviewpoints',
        'businessmapping',
        AwsCommonFields.PURCHASE_OPTION,
      ];
      selectedValuesKeys.forEach((valKey) => {
        const values = distinctValues[valKey];
        if (values) {
          const newMap = createFiltersDistinctValuesDisplayMap(valKey, values);
          LabelCoordinator.addNewKeyToDisplayMapValues('cueDisplayCoordinator', newMap);
        }
      });
      if (distinctValues) {
        Object.entries(distinctValues).forEach(([key, value]) => {
          if (key === AwsCommonFields.LINKED_ACCOUNT_NAME) {
            mapFiltersDistinctValues.set(AwsCommonFields.LINKED_ACCOUNT_ID, value);
          }
          if (key === AwsCommonFields.INSTANCE_TYPE) {
            const prepValues = [...new Set(value.map((val) => val.split('.')[0]))];
            mapFiltersDistinctValues.set(AwsCommonFields.INSTANCE_FAMILY, prepValues);
          }
          mapFiltersDistinctValues.set(key, value);
        });
      }
      return mapFiltersDistinctValues;
    },
    { enabled },
  );
  return { filtersValuesMap: data, filtersLoading: isLoading };
}

function useFiltersDistinctTagsValues({ accountType, accountKey, enabled }) {
  const { data, isLoading } = useQuery(
    [apiConstants.QUERY_KEYS.FILTERS_DISTINCT_TAGS_VALUES, { accountType }],
    async () => {
      if (!isNumber(accountType) || !isNumber(accountKey)) {
        return new Map();
      }
      const { startDate, endDate } = buildStartAndEndDate(null, null);
      let apiQuery = `/api/v1/invoices/service-costs/distinct-tags?startDate=${startDate}&endDate=${endDate}`;
      apiQuery = sanitizeQuery(apiQuery);
      const distinctValues = await API.get('billings', apiQuery);
      const mapFiltersDistinctValues = new Map();
      if (distinctValues) {
        Object.entries(distinctValues).forEach(([key, value]) => {
          mapFiltersDistinctValues.set(key, value);
        });
      }
      return mapFiltersDistinctValues;
    },
    { enabled },
  );
  return { filtersTagsValuesMap: data, filtersTagsLoading: isLoading };
}

function useFiltersDistinctK8SValues({ accountType, accountKey, enabled }) {
  const { data, isLoading } = useQuery(
    [apiConstants.QUERY_KEYS.FILTERS_DISTINCT_K8S_VALUES, { accountType }],
    async () => {
      if (!isNumber(accountType) || !isNumber(accountKey)) {
        return new Map();
      }
      const { startDate, endDate } = buildStartAndEndDate(null, null);
      let apiQuery = `/api/v1/invoices/service-costs/distinct-k8s?startDate=${startDate}&endDate=${endDate}`;
      apiQuery = sanitizeQuery(apiQuery);
      const distinctValues = await API.get('billings', apiQuery);
      const mapFiltersDistinctValues = new Map();
      if (distinctValues) {
        Object.entries(distinctValues).forEach(([key, value]) => {
          if (key === AwsCommonFields.CLUSTER) {
            mapFiltersDistinctValues.set(AwsCommonFields.K8S_CLUSTER, value);
          }
          if (key === AwsCommonFields.NODE_GROUP) {
            mapFiltersDistinctValues.set(AwsCommonFields.K8S_NODE_GROUP, value);
          }
          if (key === AwsCommonFields.K8S_INSTANCE_TYPE) {
            mapFiltersDistinctValues.set(AwsCommonFields.K8S_INSTANCE_TYPE, value);
          }
          if (key === AwsCommonFields.K8S_REGION) {
            mapFiltersDistinctValues.set(AwsCommonFields.K8S_REGION, value);
          }
          mapFiltersDistinctValues.set(key, value);
        });
      }
      return mapFiltersDistinctValues;
    },
    { enabled },
  );
  return { filtersK8SValuesMap: data, filtersK8SLoading: isLoading };
}

export function useInvalidateFiltersDistinctValues() {
  const queryClient = useQueryClient();
  const invalidatePlainFilters = () => {
    queryClient.invalidateQueries([apiConstants.QUERY_KEYS.FILTERS_DISTINCT_VALUES]);
  };
  const invalidatePlainK8SFilters = () => {
    queryClient.invalidateQueries([apiConstants.QUERY_KEYS.FILTERS_DISTINCT_K8S_VALUES]);
  };
  const invalidateTagsFilters = () =>
    queryClient.invalidateQueries([apiConstants.QUERY_KEYS.FILTERS_DISTINCT_TAGS_VALUES]);
  return { invalidatePlainFilters, invalidatePlainK8SFilters, invalidateTagsFilters };
}
export function useFiltersDistinctValues({ accountType, accountKey, fetchK8S, enabled = false }) {
  const queryClient = useQueryClient();
  const invalidatePlainFilters = () => {
    queryClient.invalidateQueries([apiConstants.QUERY_KEYS.FILTERS_DISTINCT_VALUES]);
    queryClient.invalidateQueries([apiConstants.QUERY_KEYS.FILTERS_DISTINCT_K8S_VALUES]);
  };
  const invalidateTagsFilters = () =>
    queryClient.invalidateQueries([apiConstants.QUERY_KEYS.FILTERS_DISTINCT_TAGS_VALUES]);
  const { filtersLoading, filtersValuesMap } = usePlainFiltersDistinctValues({
    accountType,
    accountKey,
    enabled,
  });
  const { filtersK8SLoading, filtersK8SValuesMap } = useFiltersDistinctK8SValues({
    accountType,
    accountKey,
    enabled: fetchK8S && enabled,
  });
  const { filtersTagsLoading, filtersTagsValuesMap } = useFiltersDistinctTagsValues({
    accountType,
    accountKey,
    enabled,
  });
  const mergedFilters = new Map([
    ...(filtersValuesMap || []),
    ...(filtersK8SValuesMap || []),
    ...(filtersTagsValuesMap || []),
  ]);
  const getBusinessMappingViewpoints = () => {
    const viewpoints = mergedFilters.get(AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS) || [];
    return viewpoints.filter((v) => !v.k8s);
  };
  const getK8SBusinessMappingViewpoints = (podsMode) => {
    const viewpoints = mergedFilters.get(AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS) || [];
    return viewpoints
      .filter((v) => !!v.k8s)
      .filter((v) => {
        const { podsView } = v.k8sConfig || {};
        return podsMode ? podsView : !podsView;
      });
  };
  const getCustomTagsKeys = () => mergedFilters.get(AwsCommonFields.CUSTOM_TAGS_KEYS) || null;
  const getVirtualTagsKeys = () => mergedFilters.get(AwsCommonFields.VIRTUAL_CUSTOM_TAGS)?.map((vt) => vt.name) || null;
  const getAccountTagsKeys = () => mergedFilters.get(AwsCommonFields.ACCOUNT_TAGS_KEYS) || [];
  const getK8sCustomTagsKeys = () => mergedFilters.get(AwsCommonFields.K8S_CUSTOM_TAGS_KEYS) || [];
  return {
    filtersLoading: filtersLoading || filtersK8SLoading || filtersTagsLoading,
    filtersValuesMap: mergedFilters,
    invalidatePlainFilters,
    invalidateTagsFilters,
    getPageFilters: (pageName, cloudType, filterOptions) =>
      getPageFiltersRaw(mergedFilters, pageName, cloudType, filterOptions, fetchK8S),
    getAccountTagsKeys,
    getCustomTagsKeys,
    getVirtualTagsKeys,
    getK8sCustomTagsKeys,
    getBusinessMappingViewpoints,
    getK8SBusinessMappingViewpoints,
  };
}
