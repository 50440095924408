import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import { groupBy } from 'lodash';
import toast from 'shared/components/andtComponents/Toast';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Spinner from 'shared/components/andtComponents/Spinner';
import AddOrEditUserModal from 'users/containers/RolesAndUsers/components/AddOrEditUserModal';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { withRolesProvider } from 'users/contexts/RolesProvider';
import { getPayerLinkedAccount } from 'shared/utils/sharedUtils';
import CreateOrUpdateCustomerModal from 'divisions/containers/ManageCustomers/components/CreateOrUpdateCustomerModal';
import CustomersTable from 'divisions/containers/ManageCustomers//components/CustomersTable';
import {
  withLinkedAccountsTableContextProvider,
  withUsersTableContextProvider,
} from 'divisions/containers/ManageCustomers//contexts/customersContext';
import Filters from 'divisions/containers/ManageCustomers//components/Filters/Filters';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import { withLinkedAccountsProvider } from 'users/contexts/LinkedAccountsProvider';
import { useTableHeaderContext, withTableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import SwitchButton from 'shared/components/andtComponents/Switch';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import styles from './ManageCustomers.module.scss';

const ManageCustomersPage = ({ customersLoading }) => {
  const { usersStore, divisionsStore } = useRootStore();
  const customers = usersStore.usersModel.divisionGroups;
  const [createCustomerModalOpen, setCreateCustomerModalOpen] = useState(false);
  const { expandedRowIds, setExpandedRowIds } = useTableHeaderContext();
  const [isSingleCustomerAccount, setIsSingleCustomerAccount] = useState(false);
  const [filters, setFilters] = useState({
    customerName: [],
    userName: [],
    linkedAccount: [],
  });
  const [editedCustomer, setEditedCustomer] = useState(null);
  const [editedLinkedAccountsCustomer, setEditedLinkedAccountsCustomer] = useState(null);
  const [addUserModal, setAddUserModal] = useState(null);
  useEffect(() => {
    usersStore.fetchAvailableDivisionLinkedAccounts();
    usersStore.fetchSubUsers();
    usersStore.fetchUserLinkedAccounts();
    (async () => {
      const accountBillingProfile = await divisionsStore.getBillingProfile(usersStore.getCurrDisplayedAccountId());
      setIsSingleCustomerAccount(!accountBillingProfile.serviceCosts);
    })();
  }, []);
  const currentAccount = usersStore.getCurrDisplayedAccount;
  const { autoAssignLinkedAccounts, resellerAccountType } = currentAccount;
  const handleUpdateAutoAssignLinkedAccounts = async (autoAssign) => {
    await usersStore.updateAutoAssignAccountProperty(autoAssign);
  };
  // sorts customers by name asc and filters by customer name / linked account ids / user name
  const customersFiltered = useMemo(() => {
    const users =
      filters.userName.length > 0
        ? usersStore
            .getSubUsersListByDisplayedType()
            .filter((user) => filters.userName.some((u) => u.value === user.userName))
        : [];
    const usersByCustomer = groupBy(users, 'divisionName');
    return [...customers]
      .sort((a, b) => a.divisionName?.localeCompare(b.divisionName || ''))
      .filter((customer) => {
        if (filters.customerName.length > 0) {
          if (!filters.customerName.some((r) => r.value === customer.divisionName)) {
            return false;
          }
        }
        if (filters.linkedAccount.length > 0) {
          if (
            !customer.linkedAccounts?.some((ln) => filters.linkedAccount.some((l) => l.value === ln.linkedAccountId))
          ) {
            return false;
          }
        }
        if (filters.userName.length > 0) {
          return !!usersByCustomer[customer.divisionName];
        }
        return true;
      });
  }, [customers, filters]);
  useEffect(() => {
    setExpandedRowIds([]);
  }, [customersFiltered.map((customer) => customer.divisionId).join()]);
  const handleUpdateLinkedAccounts = async (customer, linkedAccounts) => {
    try {
      await usersStore.updateDivisionGroupLinkAccounts(
        customer.divisionId,
        linkedAccounts,
        customer.divisionName,
        customer.divisionCode,
        customer.divisionTypeId,
        customer.roleId,
      );
    } catch (error) {
      toast.error(error.response.data.clientMessage, { position: toast.POSITION.BOTTOM_RIGHT });
    }
  };

  const fetchCSVData = () => {
    const customersCSVData = customersFiltered
      .map((customer) =>
        customer.linkedAccounts
          .map((ln) => ({
            customerId: customer.divisionId,
            customerName: customer.divisionName,
            customerCode: customer.divisionCode,
            linkedAccountId: ln.linkedAccountId,
            linkedAccountName: ln.linkedAccountName,
          }))
          .filter(Boolean),
      )
      .flat(3);
    const usersCSVData = usersStore
      .getSubUsersListByDisplayedType()
      .filter((row) => customersFiltered.some((c) => c.divisionName === row.divisionName))
      .map((row) => {
        const { divisionName, userName, creationDate } = row;
        const customer = customersFiltered.find((c) => c.divisionName === divisionName);
        return {
          customerId: customer.divisionId,
          customerName: divisionName,
          customerCode: customer.divisionCode,
          userName,
          creationDate,
        };
      });
    return [
      { data: customersCSVData, filename: 'customers.csv' },
      { data: usersCSVData, filename: 'users.csv' },
    ];
  };

  const createNewUserWithCustomer = async (userEmail) => {
    try {
      const result = await usersStore.createNewCustomerSubUser(
        (Array.isArray(userEmail) ? userEmail.join(',') : userEmail).toLowerCase(),
        addUserModal.divisionName,
        addUserModal.divisionId,
        addUserModal.parentAccountKey,
        addUserModal.roleId,
      );
      if (!addUserModal.roleId) {
        await usersStore.fetchDivisionGroups();
      }
      if (result.result) {
        await usersStore.fetchSubUsers();
        setEditedCustomer(null);
        toast.success(result?.message || 'An invitation mail was sent to the user(s)', {
          autoClose: !result?.message,
          onClose: () => {},
        });
        return true;
      }
      toast.error('Something went wrong, please try again later');
    } catch {
      toast.error('Something went wrong, please try again later');
    }
    return false;
  };

  const expanded = expandedRowIds.length > 0;
  return (
    <Container>
      <PageHeader title={PageNames.CUSTOMERS_MANAGE} />
      <TableHeader
        includeDetails
        tableName="Customer"
        hideSearch
        rightMenuClassName={resellerAccountType === 'dedicated' ? styles.rightMenuFloat : undefined}
        titleComponent={
          resellerAccountType === 'dedicated' ? (
            <>
              Dedicated Account
              <div className={styles.autoAssignRow}>
                <SwitchButton
                  label={`Auto-assignment of ${LabelCoordinator.getFieldLabel('linkedaccid').toLowerCase()}s`}
                  isChecked={autoAssignLinkedAccounts}
                  onChange={() => handleUpdateAutoAssignLinkedAccounts(!autoAssignLinkedAccounts)}
                />
                <InfoPopover isSimple>
                  {usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP ? (
                    <>
                      All the projects that exist under the billing dataset will be associated automatically with the
                      customer.
                    </>
                  ) : (
                    <>
                      The customer will be able to see the Commitments section including ALL SP and RI purchases in the
                      AWS Organization. In addition, the customer will get visibility to all cost types including
                      Amortize cost.
                    </>
                  )}
                </InfoPopover>
              </div>
            </>
          ) : undefined
        }
        fetchCsvData={fetchCSVData}
        csvTooltip="List of all the customers and their Accounts"
        expanded={expandedRowIds.length > 0}
        onCreate={() => setCreateCustomerModalOpen(true)}
        isCreateLast
        totalRows={customersFiltered.length}
        setExpanded={() =>
          setExpandedRowIds(expanded ? [] : new Array(customersFiltered.length).fill(0).map((_, i) => i))
        }
        filters={<Filters filters={filters} customers={customers} setFilters={setFilters} />}
      />
      <div className="pt-4 pb-2" />
      {customersLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <CustomersTable
          handleUpdateLinkedAccounts={handleUpdateLinkedAccounts}
          customers={customersFiltered}
          editCustomerHandler={(customer) => setEditedCustomer(customer)}
          editedLinkedAccountsCustomerHandler={(customer) => setEditedLinkedAccountsCustomer(customer)}
          addUserHandler={(customer) => {
            const payerAccount = getPayerLinkedAccount({
              account: usersStore.getCurrDisplayedAccount,
              currDispUserCloudAccountType: usersStore.currDispUserCloudAccountType,
            });
            const isOnlyPayerAccountSelected =
              !isSingleCustomerAccount && payerAccount && customer.linkedAccounts.length === 1
                ? customer.linkedAccounts[0].linkedAccountId === payerAccount.linkedAccountId
                : false;
            if (isOnlyPayerAccountSelected) {
              toast.error("You can't create a user with only the payer account connected");
              return;
            }
            setAddUserModal(customer);
          }}
          expandedRowIds={expandedRowIds}
          setExpandedRowIds={setExpandedRowIds}
          filters={filters}
          renderCSVDownload={(customer) => (
            <Tooltip placement="top" title="List of all the users that are associated with the customer" arrow>
              <span>
                <CustomCSVDownload
                  fetchData={() => {
                    const [customersCSVData, usersCSVData] = fetchCSVData();
                    return [
                      {
                        data: customersCSVData.data.filter((r) => r.customerId === customer.divisionId),
                        filename: `${customer.divisionName}_linked_accounts.csv`,
                      },
                      {
                        data: usersCSVData.data.filter((user) => user.customerId === customer.divisionId),
                        filename: `${customer.divisionName}_users.csv`,
                      },
                    ];
                  }}
                  showDownloadIcon
                  isLoading={false}
                  filesNumber={2}
                  hideText
                  style={{
                    height: 36,
                  }}
                >
                  CSV
                </CustomCSVDownload>
              </span>
            </Tooltip>
          )}
        />
      )}
      {addUserModal ? (
        <AddOrEditUserModal
          roles={[]}
          open={!!addUserModal}
          customerMode
          onClose={() => {
            setAddUserModal(false);
          }}
          email={addUserModal?.email}
          onSave={async ({ userEmail }) => {
            try {
              await createNewUserWithCustomer(userEmail);
              return true;
            } catch (e) {
              toast.error('Something went wrong please try again later');
            }
            return false;
          }}
        />
      ) : null}
      {createCustomerModalOpen && (
        <CreateOrUpdateCustomerModal
          linkedAccountsMode={false}
          onClose={() => setCreateCustomerModalOpen(false)}
          usersStore={usersStore}
        />
      )}
      {!!editedCustomer && (
        <CreateOrUpdateCustomerModal
          linkedAccountsMode={false}
          editedCustomer={editedCustomer}
          onClose={() => {
            setEditedCustomer(null);
          }}
          usersStore={usersStore}
        />
      )}
      {!!editedLinkedAccountsCustomer && (
        <CreateOrUpdateCustomerModal
          linkedAccountsMode
          handleUpdateLinkedAccounts={handleUpdateLinkedAccounts}
          editedCustomer={editedLinkedAccountsCustomer}
          onClose={() => {
            setEditedLinkedAccountsCustomer(null);
          }}
          usersStore={usersStore}
        />
      )}
    </Container>
  );
};

ManageCustomersPage.propTypes = {
  customersLoading: PropTypes.bool.isRequired,
};

export default withTableHeaderProvider(
  observer(
    withLinkedAccountsProvider(
      withRolesProvider(withUsersTableContextProvider(withLinkedAccountsTableContextProvider(ManageCustomersPage))),
    ),
  ),
);
