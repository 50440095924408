import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Button from 'shared/components/andtComponents/Button';
import Input from 'shared/components/andtComponents/Input';
import styles from './GeneralDetails.module.scss';

const GeneralDetails = ({ usersStore }) => {
  const [isLocked, setIsLocked] = React.useState(true);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [jobTitle, setJobTitle] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const userData = usersStore.currentDisplaydUser;
    if (userData) {
      const { firstName, lastName, jobTitle, companyName } = userData;
      setFirstName(firstName);
      setLastName(lastName);
      setJobTitle(jobTitle);
      setCompanyName(companyName);
    }
  }, []);

  const handleCancelEdit = () => {
    const userData = usersStore.currentDisplaydUser;
    const { firstName, lastName, jobTitle, companyName } = userData;
    setFirstName(firstName);
    setLastName(lastName);
    setJobTitle(jobTitle);
    setCompanyName(companyName);
    setIsLocked(true);
  };

  const handleUpdateUserGeneralData = async () => {
    setIsLoading(true);
    await usersStore.updateUserGeneralData(firstName, lastName, jobTitle);
    setIsLocked(true);
    setIsLoading(false);
    toast.success('Info Saved.');
  };
  const renderInput = (name, value, setter, enabled = true) => {
    if (isLocked || !enabled) {
      return <p>{value}</p>;
    }
    return <Input onChange={(e) => setter(e.target.value)} name={name} value={value} />;
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>General Information</div>
      </div>
      <div className={styles.form}>
        <div>
          <label htmlFor="first-name">First Name</label>
          <div id="first-name">{renderInput('firstName', firstName, setFirstName)}</div>
        </div>
        <div>
          <label htmlFor="last-name">Last Name</label>
          <div id="last-name">{renderInput('lastName', lastName, setLastName)}</div>
        </div>
        <div>
          <label htmlFor="full-name">Company Name</label>
          <div id="company-name">{renderInput('companyName', companyName, setCompanyName, false)}</div>
        </div>
        <div>
          <label htmlFor="job-title">Job Title</label>
          <div id="job-title">{renderInput('jobTitle', jobTitle, setJobTitle)}</div>
        </div>
      </div>
      {isLocked ? (
        <Button
          text="Edit"
          isTextButton
          icon={() => <GenerateIcon iconName={ICONS.edit.name} />}
          onClick={() => setIsLocked(false)}
        />
      ) : (
        <div className={styles.buttons}>
          <Button onClick={handleCancelEdit} text="Cancel" isSecondary disabled={isLoading} />
          <Button onClick={handleUpdateUserGeneralData} text="Save" isLoading={isLoading} />
        </div>
      )}
    </div>
  );
};

GeneralDetails.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

const ObserverGeneralDetails = observer(GeneralDetails);
export default ObserverGeneralDetails;
