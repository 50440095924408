import { API } from 'shared/utils/apiMiddleware';
import moment from 'moment';
import uuidv1 from 'uuid';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import apiConstants from 'shared/api/apiConstants';

const root = '/api/v1/usage/virtual-tags/virtual-tags';

const fetchVirtualTagsApi = () => {
  const result = API.get('billings', root);
  return result;
};
const addVirtualTagApi = async (virtualTagParams) => {
  const currDate = new Date();
  const creationDate = `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`;
  const copyVirtualTagParams = { ...virtualTagParams, creationDate, uuid: uuidv1(6) };
  copyVirtualTagParams.customTags = copyVirtualTagParams.customTags.map((ct) => ({
    name: ct,
    assignDate: moment().format('YYYY-MM-DD'),
    assignedBy: virtualTagParams.createdBy,
  }));
  return API.post('billings', root, { body: { virtualTagParams: copyVirtualTagParams } });
};
const updateVirtualTagApi = async (virtualTagParams) => {
  const copyVirtualTagParams = { ...virtualTagParams };
  copyVirtualTagParams.customTags = copyVirtualTagParams.customTags.map((ct) => {
    const oldTag = virtualTagParams.oldCustomTags?.find((oldCt) => oldCt.name === ct);
    return {
      name: ct,
      assignDate: oldTag?.assignDate || moment().format('YYYY-MM-DD'),
      assignedBy: oldTag?.assignedBy || virtualTagParams.createdBy,
    };
  });
  const body = { virtualTagParams: copyVirtualTagParams };
  const result = API.put('billings', root, { body });
  return result;
};
const deleteVirtualTagApi = async (uuid) => {
  const body = { uuid };
  const result = API.del('billings', root, { body });
  return result;
};

export const useVirtualTags = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.VIRTUAL_TAGS];
  const onSuccess = () => {
    queryClient.invalidateQueries(queryKey);
    queryClient.invalidateQueries([apiConstants.QUERY_KEYS.FILTERS_DISTINCT_TAGS_VALUES]);
  };
  return {
    fetchVirtualTags: () => useQuery(queryKey, fetchVirtualTagsApi),
    useAddVirtualTag: () => useMutation(queryKey, addVirtualTagApi, { onSuccess }),
    useUpdateVirtualTag: () => useMutation(queryKey, updateVirtualTagApi, { onSuccess }),
    useDeleteVirtualTag: () => useMutation(queryKey, deleteVirtualTagApi, { onSuccess }),
  };
};
