import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { copyStrToClipboard } from 'shared/utils/strUtil';
import Button from '../Button';
import styles from './CodeBlock.module.scss';

const CodeBlock = ({ code, icon }) => {
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    copyStrToClipboard(code);
    setCopied(true);
  };
  return (
    <div className={styles.container} data-active={copied}>
      <span>
        {icon || <GenerateIcon iconName={ICONS.code.name} className={styles.codeIcon} />}
        {code}
      </span>
      <Button
        onClick={copyToClipboard}
        text="Copy"
        icon={() => <GenerateIcon iconName={ICONS.clone.name} />}
        isTextButton
      />
    </div>
  );
};

CodeBlock.propTypes = {
  icon: PropTypes.string,
  code: PropTypes.string.isRequired,
};

CodeBlock.defaultProps = {
  icon: '',
};

export default CodeBlock;
