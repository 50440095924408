import { action, makeObservable, observable } from 'mobx';

export default class Alert {
  constructor(rawAlert) {
    this.id = rawAlert.uuid;
    this.userKey = rawAlert.userKey;
    this.accountId = rawAlert.accountId;
    this.linkedAccountId = rawAlert.linkedAccountId;
    this.linkedAccountName = rawAlert.linkedAccountName;
    this.type = this.capitalize(rawAlert.type);
    this.description = rawAlert.description;
    this.showAlert = rawAlert.showAlert;
    this.hideDueDate = rawAlert.hideDueDate;
    this.date = rawAlert.time;
    makeObservable(this, {
      id: observable,
      userKey: observable,
      accountId: observable,
      linkedAccountId: observable,
      linkedAccountName: observable,
      type: observable,
      description: observable,
      date: observable,
      showAlert: observable,
      hideDueDate: observable,
      setHideDueDate: action,
      setShowAlert: action,
    });
  }

  capitalize = (string) => {
    const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalized;
  };

  setHideDueDate(dueDate) {
    this.hideDueDate = dueDate || undefined;
  }

  setShowAlert(showAlert) {
    this.showAlert = showAlert;
  }
}
