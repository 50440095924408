import React from 'react';
import PropTypes from 'prop-types';
import { palette } from 'shared/constants/colorsConstants';
import './Spinner.module.scss';

export const SIZES = {
  XX_BIG_150: 150,
  XX_BIG_100: 100,
  X_BIG_90: 90,
  BIG_60: 60,
  MEDIUM_50: 50,
  SMALL_30: 30,
};

const getRectUnit = (rotate, color, begin, key) => (
  <g key={key} transform={`rotate(${rotate} 50 50)`}>
    <rect x="46.5" y="24" rx="9.3" ry="4.8" width="7" height="12" fill={color}>
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin={begin} repeatCount="indefinite" />
    </rect>
  </g>
);

const getAllRect = (color) => {
  const ret = [];
  const total = 10;
  for (let i = 0; i < total; i += 1) {
    // eslint-disable-next-line no-mixed-operators
    const unit = getRectUnit((360 / total) * i, color, `-${0.9 - i / total}s`, `key-${i}`);
    ret.push(unit);
  }
  return ret;
};

const Spinner = ({ color, size, position, top }) => {
  const innerSvg = getAllRect(color);

  return (
    <div
      style={{
        height: `${size}px`,
        width: `${size}px`,
        position,
        left: '50%',
        top: top || '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,
      }}
    >
      <svg
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style={{ background: 'none' }}
      >
        {innerSvg}
      </svg>
    </div>
  );
};

Spinner.propTypes = {
  color: PropTypes.string,
  position: PropTypes.string,
  size: PropTypes.number,
  top: PropTypes.number,
};

Spinner.defaultProps = {
  color: palette.gray[500],
  position: 'absolute',
  size: SIZES.X_BIG_90,
  top: '50%',
};
export default Spinner;
