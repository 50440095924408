/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { AwsServiceNames } from 'shared/constants/awsConstants';
import { AzureServiceNames } from 'shared/constants/azureConstants';
import { GcpServiceNames } from 'shared/constants/gcpConstants';
import { getDisplayAwsLabel } from 'shared/utils/awsUtils';
import { getDisplayAzureLabel } from 'shared/utils/azureUtils';
import { getDisplayGcpLabel } from 'shared/utils/gcpUtils';
import ICONS from 'shared/constants/assetsConstants';

// *** IMPORTANT ***
// RecommendationTypes and RecommendationTypesDisplay should
// have the SAME order of types at later on we create a map that is based on
// the order of those objects.

// export const RecommendationTypes = {
//   EC2_RI: 'ri',
//   // EC2_REGION: 'region',
//   EC2_SPOT: 'spot',
//   EC2_VERSION_UPGRADE: 'version-upgrade',
//   EC2_OPERATION_SYS: 'operation-system',
//   EC2_IP_UNATTACHED: 'ip-unattached',
//   EC2_IDLE: 'ec2-idle',
//   EC2_EBS_UNATTACHED: 'ebs-unattached',
//   EC2_IDLE_LOAD_BALANCER: 'idle-load-balancer',
//   EC2_REGION_MIGRATION: 'region-migration',
//   EC2_LOW_CPU_USAGE: 'ec2-low-cpu-usage',
//   EC2_SCHEDULE: 'ec2-schedule',
//   EC2_SAVINGS_PLANS: 'ec2-savings-plans',
//   DDB_IDLE: 'dynamodb-idle',
//   RDS_RI: 'rds-ri',
//   RDS_VERSION_UPGRADE: 'rds-version-upgrade',
//   RDS_IDLE: 'rds-idle',
//   S3_IDLE: 's3-idle',
//   S3_VERSIONING: 's3-versioning',
//   S3_MULTIPART_UPLOAD: 's3-multipart-upload',
//   EBS_TYPE_CHANGE: 'ebs-type-change',
//   EBS_TYPE_SIZE_CHANGE: 'ebs-type-and-size-change',
//   RDS_TYPE_CHANGE: 'rds-type-change',
//   EBS_OUTDATED_SNAPSHOT: 'ebs-outdated-snapshot',
//   EC2_UNNECESSARY_DATA_TRANSFER: 'ec2-udt',
//   EC2_STOPPED_INSTANCE: 'ec2-stopped-instance',
//   // ***** Azure RecommendationTypes
//   AZURE_VM_RI: 'azure-vm-ri',
//   AZURE_DISK_UNATTACHED: 'azure-disk-unattached',
//   AZURE_DISK_TYPE_CHANGE: 'azure-disk-type-change',
//   AZURE_IP_UNATTACHED: 'azure-ip-unattached',
//   AZURE_VM_IDLE: 'azure-vm-idle',
//   AZURE_IDLE_LOAD_BALANCER: 'azure-idle-load-balancer',
//   AZURE_DB_RI: 'azure-db-ri',
//   AZURE_VM_RIGHT_SIZING: 'azure-vm-right-sizing',
//   AZURE_COSMOS_RIGHT_SIZING: 'azure-cosmos-right-sizing',
//   AZURE_APP_RIGHT_SIZING: 'azure-app-right-sizing',
//   AZURE_STORAGE: 'azure-storage',
//   // ***** GCP RecommendationTypes
//   GCP_VM_IDLE: 'gcp-vm-idle',
//   GCP_VM_RIGHT_SIZING: 'gcp-vm-rightsizing',
// };
export const MAX_LABELS = 15;
export const MAX_LABEL_LENGTH_CHARS = 50;

const AZURE_SQL_TEXT =
  'Reserved instance recommendations are based on your historical usage. These recommendations are calculated by analyzing all incurred usage eligible to be covered by the Azure RI.';

export const LabelActionTypes = {
  ADD: 'add',
  EDIT: 'edit',
  REMOVE: 'remove',
};

const RecommendationTypesAws = {
  // EC2_RI: 'ri',
  IP_PUBLIC_IPV4: 'ip-public-ipv4',
  ECS_FARGATE: 'ecs-fargate',
  KMS_IDLE: 'kms-idle',
  KMS_OLD: 'kms-old',
  // EC2_REGION: 'region',
  // EC2_SPOT: 'spot',
  EC2_VERSION_UPGRADE: 'version-upgrade',
  EC2_OPERATION_SYS: 'operation-system',
  EC2_IP_UNATTACHED: 'ip-unattached',
  EC2_IDLE: 'ec2-idle',
  EC2_EBS_UNATTACHED: 'ebs-unattached',
  EC2_IDLE_LOAD_BALANCER: 'idle-load-balancer',
  EC2_REGION_MIGRATION: 'region-migration',
  EC2_LOW_CPU_USAGE: 'ec2-low-cpu-usage',
  EC2_SCHEDULE: 'ec2-schedule',
  EC2_SAVINGS_PLANS: 'ec2-savings-plans',
  DDB_IDLE: 'dynamodb-idle',
  RDS_RI: 'rds-ri',
  RDS_VERSION_UPGRADE: 'rds-version-upgrade',
  RDS_IDLE: 'rds-idle',
  RDS_CLASS_CHANGE: 'rds-class-change',
  RDS_IOPS_CHANGE: 'rds-iops-change',
  S3_IDLE: 's3-idle',
  S3_VERSIONING: 's3-versioning',
  S3_STORAGE_CLASS: 's3-storage-class',
  S3_MULTIPART_UPLOAD: 's3-multipart-upload',
  EBS_TYPE_CHANGE: 'ebs-type-change',
  EBS_TYPE_SIZE_CHANGE: 'ebs-type-and-size-change',
  RDS_TYPE_CHANGE: 'rds-type-change',
  EBS_OUTDATED_SNAPSHOT: 'ebs-outdated-snapshot',
  EBS_UPGRADE: 'ebs-upgrade',
  EC2_UNNECESSARY_DATA_TRANSFER: 'ec2-udt',
  EC2_STOPPED_INSTANCE: 'ec2-stopped-instance',
  ELASTICACHE_RI: 'elasticache-ri',
  OPEN_SEARCH_RI: 'opensearch-ri',
  REDSHIFT_LOW_UTILIZATION: 'redshift-util-low',
  ELASTICSEARCH_LOW_UTILIZATION: 'es-util-low',
  NAT_GATEWAY_LOW_UTILIZATION: 'nat-gateway-util-low',
  ELASTICACHE_LOW_UTILIZATION: 'elasticache-util-low',
  DOCUMENT_DB_LOW_UTILIZATION: 'documentdb-util-low',
  NEPTUNE_LOW_UTILIZATION: 'neptune-util-low',
  KINESIS_LOW_UTILIZATION: 'kinesis-util-low',
  CLOUDTRAIL_DUPLICATES_TRAILS: 'cloudtrail-duplicate-trails',
};
const RecommendationTypesAzure = {
  // ***** Azure RecommendationTypes
  AZURE_VM_RI: 'azure-vm-ri',
  AZURE_DISK_UNATTACHED: 'azure-disk-unattached',
  AZURE_DISK_TYPE_CHANGE: 'azure-disk-type-change',
  AZURE_IP_UNATTACHED: 'azure-ip-unattached',
  AZURE_MARIA_DB_IDLE: 'azure-maria-idle',
  AZURE_MYSQL_DB_IDLE: 'azure-mysql-idle',
  AZURE_POSTGRESQL_DB_IDLE: 'azure-postgres-idle',
  AZURE_COSMOS_DB_IDLE: 'azure-cosmos-idle',
  AZURE_SQL_DB_IDLE: 'azure-sql-idle',
  AZURE_VM_STOPPED: 'azure-vm-stopped',
  AZURE_VM_IDLE: 'azure-vm-idle',
  AZURE_IDLE_LOAD_BALANCER: 'azure-idle-load-balancer',
  AZURE_DB_RI: 'azure-db-ri',
  AZURE_VM_RIGHT_SIZING: 'azure-vm-rightsizing',
  AZURE_COSMOS_RIGHT_SIZING: 'azure-cosmos-db-right-sizing',
  AZURE_APP_RIGHT_SIZING: 'azure-app-right-sizing',
  AZURE_STORAGE: 'azure-storage',
  AZURE_RESERVED_CAPACITY_COSMOS: 'azure-cosmos-db-reserved-capacity',
  AZURE_RESERVED_CAPACITY_DATA_EXPLORER: 'azure-data-explorer-reserved-capacity',
  AZURE_RESERVED_CAPACITY_MY_SQL: 'azure-mysql-reserved-capacity',
  AZURE_RESERVED_CAPACITY_PG: 'azure-postgresql-reserved-capacity',
  AZURE_RESERVED_CAPACITY_REDIS: 'azure-redis-reserved-capacity',
  AZURE_RESERVED_CAPACITY_SQL_DATA_WH: 'azure-sql-data-warehouse-reserved-capacity',
  AZURE_RESERVED_CAPACITY_SQL: 'azure-sql-reserved-capacity',
  AZURE_SNAPSHOT_MIGRATION: 'azure-snapshot-migration',
  AZURE_OUTDATED_SNAPSHOT: 'azure-outdated-snapshot',
  AZURE_KUSTO_UNUSED_DATA: 'azure-kusto-unused-data',
  AZURE_APP_SERVICE_RESERVED_CAPACITY: 'azure-app-service-reserved-capacity',
  AZURE_DB_RESERVED_CAPACITY: 'azure-db-reserved-capacity',

  // ***** GCP RecommendationTypes
};
const RecommendationTypesGcp = {
  // ***** GCP RecommendationTypes
  GCP_IP_IDLE: 'gcp-ip-idle',
  GCP_VM_STOPPED: 'gcp-vm-stopped',
  GCP_VM_IDLE: 'gcp-vm-idle',
  GCP_VM_RIGHT_SIZING: 'gcp-vm-rightsizing',
  GCP_USAGE_COMMITMENT: 'gcp-usage-commitment',
  GCP_DISK_UNATTACHED: 'gcp-disk-unattached',
};

export const RecommendationTypes = {
  ...RecommendationTypesAws,
  ...RecommendationTypesAzure,
  ...RecommendationTypesGcp,
};

export const RecommendationTypesDisplay = {
  IP_PUBLIC_IPV4: 'IP migration v4 to v6',
  ECS_FARGATE: 'ECS Fargate Right Sizing',
  KMS_IDLE: 'Disabled KMS',
  KMS_OLD: 'Old KMS',
  // EC2_RI: 'EC2 Reserved Instances',
  //  EC2_REGION : 'EC2 Region Migration',
  // EC2_SPOT: 'EC2 Spot Instances',
  EC2_VERSION_UPGRADE: 'EC2 Generation Upgrade',
  EC2_OPERATION_SYS: 'Operation system',
  EC2_IP_UNATTACHED: 'Ip Unattached',
  EC2_IDLE: 'EC2 Idle',
  EC2_EBS_UNATTACHED: 'EBS Unattached',
  EC2_IDLE_LOAD_BALANCER: 'Idle Load Balancer',
  EC2_REGION_MIGRATION: 'Region Migration',
  EC2_LOW_CPU_USAGE: 'EC2 Right Sizing',
  EC2_SCHEDULE: 'EC2 Schedule',
  EC2_SAVINGS_PLANS: 'Savings Plans',
  DDB_IDLE: 'DynamoDB Idle',
  CLOUDTRAIL_DUPLICATES_TRAILS: 'Duplicate CloudTrail Trails',
  RDS_RI: 'RDS Reserved Instances',
  RDS_VERSION_UPGRADE: 'RDS Generation Upgrade',
  RDS_IDLE: 'RDS Idle',
  RDS_CLASS_CHANGE: 'RDS Right Sizing',
  RDS_IOPS_CHANGE: 'RDS IOPS Change',
  S3_IDLE: 'S3 Inactive',
  S3_VERSIONING: 'S3 Versioning',
  S3_STORAGE_CLASS: 'S3 Storage Class',
  S3_MULTIPART_UPLOAD: 'S3 Multipart Upload',
  EBS_TYPE_CHANGE: 'EBS Type Change',
  EBS_TYPE_SIZE_CHANGE: 'EBS Type And Size Change',
  RDS_TYPE_CHANGE: 'RDS Type Change',
  EBS_OUTDATED_SNAPSHOT: 'EBS Outdated Snapshot',
  EBS_UPGRADE: 'EBS Upgrade',
  EC2_UNNECESSARY_DATA_TRANSFER: 'EC2 Unnecessary Data Transfer',
  EC2_STOPPED_INSTANCE: 'EC2 Stopped Instance',
  ELASTICACHE_RI: 'Elastic Cache Reserved Instances',
  OPEN_SEARCH_RI: 'Open Search Reserved Instances',
  REDSHIFT_LOW_UTILIZATION: 'Redshift Idle',
  ELASTICSEARCH_LOW_UTILIZATION: 'Elasticsearch Idle',
  NAT_GATEWAY_LOW_UTILIZATION: 'NAT Gateway Idle',
  ELASTICACHE_LOW_UTILIZATION: 'ElastiCache Idle',
  DOCUMENT_DB_LOW_UTILIZATION: 'DocumentDB Idle',
  NEPTUNE_LOW_UTILIZATION: 'Neptune Idle',
  KINESIS_LOW_UTILIZATION: 'Kinesis Idle',
  // ***** Azure RecommendationTypes
  AZURE_VM_RI: 'Virtual Machines RI',
  AZURE_DISK_UNATTACHED: 'Disk Unattached',
  AZURE_DISK_TYPE_CHANGE: 'Disk Type Change',
  AZURE_IP_UNATTACHED: 'IP Unattached',
  AZURE_MYSQL_DB_IDLE: 'MySQL DB Idle',
  AZURE_MARIA_DB_IDLE: 'Maria DB Idle',
  AZURE_SQL_DB_IDLE: 'SQL DB Idle',
  AZURE_POSTGRESQL_DB_IDLE: 'PostgreSQL DB Idle',
  AZURE_COSMOS_DB_IDLE: 'Cosmos DB Idle',
  AZURE_VM_STOPPED: 'Virtual Machine Stopped',
  AZURE_VM_IDLE: 'Virtual Machine Idle',
  AZURE_IDLE_LOAD_BALANCER: 'Idle Load Balancer',
  AZURE_DB_RI: 'DB RI',
  AZURE_VM_RIGHT_SIZING: 'Virtual Machine Right Sizing',
  AZURE_COSMOS_RIGHT_SIZING: 'Cosmos Right Sizing',
  AZURE_APP_RIGHT_SIZING: 'APP Right Sizing',
  AZURE_STORAGE: 'Storage',
  AZURE_RESERVED_CAPACITY_COSMOS: 'Cosmos DB Reserved Instance',
  AZURE_RESERVED_CAPACITY_DATA_EXPLORER: 'Data Explorer Reserved Instance',
  AZURE_RESERVED_CAPACITY_MY_SQL: 'MySQL RReserved Instance',
  AZURE_RESERVED_CAPACITY_PG: 'PostgreSQL Reserved Instance',
  AZURE_RESERVED_CAPACITY_REDIS: 'Redis Reserved Instance',
  AZURE_RESERVED_CAPACITY_SQL_DATA_WH: 'SQL Data Warehouse Reserved Instance',
  AZURE_RESERVED_CAPACITY_SQL: 'SQL Reserved Instance',
  AZURE_SNAPSHOT_MIGRATION: 'Snapshot Migration',
  AZURE_OUTDATED_SNAPSHOT: 'Outdated Snapshot',
  AZURE_KUSTO_UNUSED_DATA: 'Kusto Unused Data',
  AZURE_APP_SERVICE_RESERVED_CAPACITY: 'Azure App Service Reserved Instance',
  AZURE_DB_RESERVED_CAPACITY: 'Azure DB Reserved Instance',
  // ***** GCP RecommendationTypes
  GCP_IP_IDLE: 'IP Idle',
  GCP_VM_STOPPED: 'Virtual Machine Stopped',
  GCP_VM_IDLE: 'Virtual Machine Idle',
  GCP_USAGE_COMMITMENT: 'Committed Use Discount',
  GCP_VM_RIGHT_SIZING: 'Virtual Machine Right Sizing',
  GCP_DISK_UNATTACHED: 'Disk Unattached',
};

export const mapCloudTypeToRecommendationTypes = new Map([
  [0, RecommendationTypesAws],
  [1, RecommendationTypesAzure],
  [2, RecommendationTypesGcp],
]);
const types = Object.keys(RecommendationTypes);

export const mapRecommendationsTypeToDisplay = new Map(
  types.map((key) => [RecommendationTypes[key], RecommendationTypesDisplay[key]]),
);
export const mapDisplayRecommendationsTypeToType = new Map(
  types.map((key) => [RecommendationTypesDisplay[key], RecommendationTypes[key]]),
);
export const mapRecTypeToDisplayText = new Map([
  [RecommendationTypes.IP_PUBLIC_IPV4, 'In case the format of the in-use public IP is v4, change it to v6'],
  [
    RecommendationTypes.ECS_FARGATE,
    'Recommendations to tune your ECS Fargate containers, providing improved utilization and cost',
  ],
  [RecommendationTypes.KMS_IDLE, "It's recommended to delete disabled KMS that are no longer in use."],
  [RecommendationTypes.KMS_OLD, "It's recommended to rotate KMS after one year of use and schedule to delete them."],
  [
    RecommendationTypes.CLOUDTRAIL_DUPLICATES_TRAILS,
    'Identifying excessive trails for member accounts and suggesting for their termination',
  ],
  [
    RecommendationTypes.EC2_RI,
    `RI purchase recommendations are based on your historical usage.
These recommendations are calculated by analyzing all incurred usage eligible to be covered by an RI (e.g. On-Demand usage)
and using our unique algorithms to simulate possible combinations of RI’s that would cover that usage`,
  ],
  [
    RecommendationTypes.EC2_SPOT,
    `A Spot Instance is an unused EC2 instance that is available for less than the corresponding On-Demand price.
Spot Instances are a cost-effective choice if you can be flexible about when your applications run and if your applications can be interrupted.
We recommend Spot Instances for data analysis, batch jobs, background processing, and optional tasks`,
  ],
  [
    RecommendationTypes.EC2_REGION,
    "In case where the current Region has high cost, unless specifically required, it's recommend to move to a lower cost region",
  ],
  [
    RecommendationTypes.EC2_VERSION_UPGRADE,
    `AWS introduces different types of instances according to application loads, memory, storage and networking capacity to offer better performance.
We recommend you to use the latest generation of instances to get the best performance and pricing`,
  ],
  [
    RecommendationTypes.EC2_OPERATION_SYS,
    'In case where the runnig OS is not Linux, and unless specific requirements are needed for other OS, its recommend to use Linux as its cheaper',
  ],
  [RecommendationTypes.EC2_IP_UNATTACHED, 'In case where IP is not attached to any instance'],
  [
    RecommendationTypes.EC2_IDLE,
    'In case where daily average CPU, of EC2 instance, is less than 1% and network in/out is lower than 10MB',
  ],
  [RecommendationTypes.EC2_EBS_UNATTACHED, 'In case where EBS is active but not attached, EBS should be turned off'],
  [RecommendationTypes.EC2_IDLE_LOAD_BALANCER, 'In case where a Load balancer is not attached to any instance'],
  [
    RecommendationTypes.EC2_REGION_MIGRATION,
    `Building applications that will reliably grow starts with choosing the right AWS region.
There are substantial differences in price, performance, number of Availability Zones and product selection among AWS regions.
We recommend on region migration based on usage and the cost of the current and suggested regions`,
  ],
  [
    RecommendationTypes.EC2_LOW_CPU_USAGE,
    'In case where EC2 instance has a low CPU utilization, its recommend to switch to a more suitable instance type which will cost less',
  ],
  [
    RecommendationTypes.EC2_SCHEDULE,
    'In case where EC2 instance could be schedule to run only on working hours, based on his current running environment',
  ],
  [
    RecommendationTypes.EC2_SAVINGS_PLANS,
    'In case that the daily hourly usage is above the stated commitment, we recommend to commit to Savings plan',
  ],
  [
    RecommendationTypes.DDB_IDLE,
    "In case where a DynamoDB table has not been reached in the last 30 days, it's recommend to delete it or cancel the IOPS commitment",
  ],
  [
    RecommendationTypes.RDS_RI,
    `RI purchase recommendations are based on your historical usage.
These recommendations are calculated by analyzing all incurred usage eligible to be covered by an RI (e.g. On-Demand usage)
and using our unique algorithms to simulate possible combinations of RI’s that would cover that usage`,
  ],
  [
    RecommendationTypes.RDS_VERSION_UPGRADE,
    `AWS introduces different types of instances according to application loads, memory, storage and networking
capacity to offer better performance. it's recommend to use the latest generation of instances to get the best performance and pricing`,
  ],
  [
    RecommendationTypes.RDS_IDLE,
    'In case where RDS instance was with no more than 1 connection for long period, its recommended to terminate it',
  ],
  [
    RecommendationTypes.RDS_CLASS_CHANGE,
    'In case the RDS instance is underutilized, consider switching it to a different instance type to reduce cost',
  ],
  [
    RecommendationTypes.RDS_IOPS_CHANGE,
    'In case the RDS IOPS is underutilized, consider decreasing the provisioned IOPS',
  ],
  [
    RecommendationTypes.REDSHIFT_LOW_UTILIZATION,
    'In case where Redshift instance was in idle for a long period of time, its recommended to terminate it',
  ],
  [
    RecommendationTypes.ELASTICSEARCH_LOW_UTILIZATION,
    'In case where Elasticsearch instance was in idle for a long period of time, its recommended to terminate it',
  ],
  [
    RecommendationTypes.ELASTICACHE_LOW_UTILIZATION,
    'In case where Elasticache instance was in idle for a long period of time, its recommended to terminate it',
  ],
  [
    RecommendationTypes.NAT_GATEWAY_LOW_UTILIZATION,
    'In case where NAT Gateway instance was in idle for a long period of time, its recommended to terminate it',
  ],
  [
    RecommendationTypes.DOCUMENT_DB_LOW_UTILIZATION,
    'In case where DocumentDb instance was in idle for a long period of time, its recommended to terminate it',
  ],
  [
    RecommendationTypes.NEPTUNE_LOW_UTILIZATION,
    'In case where Neptune instance was in idle for a long period of time, its recommended to terminate it',
  ],
  [
    RecommendationTypes.KINESIS_LOW_UTILIZATION,
    'In case where Kinesis instance was in idle for a long period of time, its recommended to terminate it',
  ],
  [
    RecommendationTypes.S3_IDLE,
    'In case where S3 Bucket was inactive for a long period of time, its recommended to terminate it',
  ],
  [
    RecommendationTypes.S3_VERSIONING,
    'This S3 Bucket has versioning turned on, you can reduce cost by deleting some of the oldest versions.',
  ],
  [
    RecommendationTypes.S3_STORAGE_CLASS,
    'Buckets are created by default at the standard storage class which is good when the objects in them are accessed very often.',
  ],
  [
    RecommendationTypes.S3_MULTIPART_UPLOAD,
    'In case where S3 Bucket has multipart uploaded parts, its recommended to create a lifecycle rule that' +
      ' will automatically delete incomplete multipart objects',
  ],
  [
    RecommendationTypes.EBS_TYPE_CHANGE,
    'In case where EBS type exceeds performance needs, reduce costs by choosing the right type',
  ],
  [
    RecommendationTypes.EBS_TYPE_SIZE_CHANGE,
    'In case where EBS type exceeds performance needs, reduce costs by choosing the right type',
  ],
  [
    RecommendationTypes.RDS_TYPE_CHANGE,
    "In case where RDS type doesn't suit performance needs, changing RDS type can reduce costs",
  ],
  [
    RecommendationTypes.EBS_OUTDATED_SNAPSHOT,
    'In case where the snapshot exists more than 21 days, it’s recommended to delete the snapshot',
  ],
  [RecommendationTypes.EBS_UPGRADE, 'In case EBS type can be upgraded to achieve better pricing and flexibility'],
  [
    RecommendationTypes.EC2_UNNECESSARY_DATA_TRANSFER,
    `In case where EC2 instances, within the same region, have inter-Availability Zones Data Transfer
  consider to co-located it within the same Availability Zone, to avoid unnecessary data transfer charges`,
  ],
  [
    RecommendationTypes.EC2_STOPPED_INSTANCE,
    'In case where EC2 instance is stopped but carries additional EBS and/or Public IP charges, should be consider to terminating it',
  ],

  [RecommendationTypes.OPEN_SEARCH_RI, getDisplayAwsLabel(AwsServiceNames.ELASTICSEARCH)],
  [RecommendationTypes.ELASTICACHE_RI, getDisplayAwsLabel(AwsServiceNames.ELASTICACHE)],
  // AZURE ******
  [RecommendationTypes.AZURE_VM_RI, getDisplayAzureLabel(AzureServiceNames.VM)],
  [RecommendationTypes.AZURE_IP_UNATTACHED, getDisplayAzureLabel(AzureServiceNames.VM)],
  [RecommendationTypes.AZURE_DISK_UNATTACHED, getDisplayAzureLabel(AzureServiceNames.AZURE_MANAGED_DISKS)],
  [RecommendationTypes.AZURE_DISK_TYPE_CHANGE, getDisplayAzureLabel(AzureServiceNames.AZURE_MANAGED_DISKS)],
  [RecommendationTypes.AZURE_IDLE_LOAD_BALANCER, getDisplayAzureLabel(AzureServiceNames.VM)],
  [RecommendationTypes.AZURE_VM_IDLE, getDisplayAzureLabel(AzureServiceNames.VM)],
  [RecommendationTypes.AZURE_VM_STOPPED, getDisplayAzureLabel(AzureServiceNames.VM)],
  [RecommendationTypes.AZURE_MARIA_DB_IDLE, getDisplayAzureLabel(AzureServiceNames.DB)],
  [RecommendationTypes.AZURE_MYSQL_DB_IDLE, getDisplayAzureLabel(AzureServiceNames.DB)],
  [RecommendationTypes.AZURE_POSTGRESQL_DB_IDLE, getDisplayAzureLabel(AzureServiceNames.DB)],
  [RecommendationTypes.AZURE_COSMOS_DB_IDLE, getDisplayAzureLabel(AzureServiceNames.DB)],
  [RecommendationTypes.AZURE_SQL_DB_IDLE, getDisplayAzureLabel(AzureServiceNames.DB)],
  [RecommendationTypes.AZURE_VM_RIGHT_SIZING, getDisplayAzureLabel(AzureServiceNames.VM)],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_COSMOS, AZURE_SQL_TEXT],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_DATA_EXPLORER, AZURE_SQL_TEXT],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_MY_SQL, AZURE_SQL_TEXT],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_PG, AZURE_SQL_TEXT],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_REDIS, AZURE_SQL_TEXT],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL, AZURE_SQL_TEXT],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL_DATA_WH, AZURE_SQL_TEXT],
  [RecommendationTypes.AZURE_APP_SERVICE_RESERVED_CAPACITY, AZURE_SQL_TEXT],
  // GCP ******
  [RecommendationTypes.GCP_IP_IDLE, getDisplayGcpLabel(GcpServiceNames.IP)],
  [RecommendationTypes.GCP_VM_STOPPED, getDisplayGcpLabel(GcpServiceNames.VM)],
  [RecommendationTypes.GCP_VM_IDLE, getDisplayGcpLabel(GcpServiceNames.VM)],
  [RecommendationTypes.GCP_USAGE_COMMITMENT, 'Commitment Use Discount'],
]);

export class DATE_FILTERS_VALUES {
  static CURRENT_MONTH = 'Current month';

  static PREVIOUS_MONTH = 'Previous month';

  static LAST_Q = 'Last 3 months';

  static YTD = 'Year to date';
}

// Recommendation table fields
export class RecommTableFieldTypes {
  static ID = 0;

  static KEY = 1;

  static TITLE = 2;

  static REC_STATUS = 3;

  static CURR_INSTANCE = 4;

  static REGION = 5;

  static TYPE = 6;

  static CURR_TOTAL_COST = 7;

  static SUGG_TOTAL_COST = 8;

  static REDUCE_PERCENT = 9;

  static SUGG_CREATION_DATE = 10;

  static SUGG_UPDATE_DATE = 11;

  static EXCLUDE_REASON = 12;
}

export class RecommendationStatus {
  static OPEN = 0;

  static COMPLETED = 1;

  static DISMISSED_BY_SYSTEM = 2;

  static DISMISSED_BY_USER = 3;

  static EXCLUDED = 4;

  static INVALID = 5;

  static COMPLETED_BY_SYSTEM = 6;

  static NUM_OF_STATUSES = 7;

  static NOT_APPLICABLE = 8;

  static EXCLUDE_EXPIRED = 9;
}

export const AzureRecommendationRejectReason = {
  IRRELEVANT_FOR_SUBSCRIPTION: 4,
};

export const AzureRecommendationRejectReasonDisplay = {
  IRRELEVANT_FOR_SUBSCRIPTION: "It's irrelevant for this subscription",
};
export const AwsRecommendationRejectReason = {
  IRRELEVANT_FOR_RESOURCE_GROUP: 3,
  IRRELEVANT_FOR_LINKED_ACCOUNT: 4,
};

export const AwsRecommendationRejectReasonDisplay = {
  IRRELEVANT_FOR_RESOURCE_GROUP: "It's irrelevant for this resource group",
  IRRELEVANT_FOR_LINKED_ACCOUNT: "It's irrelevant for this linked account",
};
export const RecommendationRejectReasonIntro = {
  NO_REASON_SELECTED: 0,
  IRRELEVANT_FOR_NOW: 1,
  IRRELEVANT_FOREVER: 2,
};
export const RecommendationRejectReason = {
  // IRRELEVANT_FOR_PROJECT_AND_ENV: 5,
  IRRELEVANT_FOR_RESOURCE: 6,
  IRRELEVANT_FOR_REGION: 7,
  DID_NOT_UNDERSTAND: 8,
  DONT_AGREE_WITH_RECOMM: 9,
  OTHER: 10,
};

export const RecommendationExcludeScope = {
  REC_ONLY: 'rec_only',
  RESOURCE_ID: 'resource_id',
  LINKED_ACCOUNT_ID: 'linked_account_id',
  ALL: 'all',
};

export const RecommendationExcludePeriod = {
  OneMonth: 'one_month',
  ThreeMonths: 'three_months',
  SixMonths: 'six_months',
  TwelveMonths: 'twelve_months',
  Permanently: 'permanently',
};

export const RecommendationExcludePeriodInDays = new Map([
  [RecommendationExcludePeriod.OneMonth, 30],
  [RecommendationExcludePeriod.ThreeMonths, 90],
  [RecommendationExcludePeriod.SixMonths, 180],
  [RecommendationExcludePeriod.TwelveMonths, 365],
  [RecommendationExcludePeriod.Permanently, -1],
]);

export const RecommendationRejectReasonDisplayIntro = {
  NO_REASON_SELECTED: 'No reason',
  IRRELEVANT_FOR_NOW: "It's irrelevant for now",
  IRRELEVANT_FOREVER: "It's irrelevant forever",
};
export const RecommendationRejectReasonDisplay = {
  // IRRELEVANT_FOR_PROJECT_AND_ENV: "It's irrelevant for this project and environment",
  IRRELEVANT_FOR_RESOURCE: "It's irrelevant for this resource",
  IRRELEVANT_FOR_REGION: "It's irrelevant for this region",
  DID_NOT_UNDERSTAND: "I didn't understand it",
  DONT_AGREE_WITH_RECOMM: "I don't agree with it",
  OTHER: 'Other',
};

const mapCloudTypeToRejectReason = new Map([
  [0, AwsRecommendationRejectReason],
  [1, AzureRecommendationRejectReason],
  [2, AwsRecommendationRejectReason],
]);
const mapCloudTypeToRejectReasonDisplay = new Map([
  [0, AwsRecommendationRejectReasonDisplay],
  [1, AzureRecommendationRejectReasonDisplay],
  [2, AwsRecommendationRejectReasonDisplay],
]);

// The following maps creation is under the assumption that RecommendationRejectReason & RecommendationRejectReasonDisplay
// are ordered in the same way
const rejectReasons = Object.values(RecommendationRejectReason);
const displayRejectReasons = Object.values(RecommendationRejectReasonDisplay);

export const mapRecommsRejectReasonToDisplay = new Map(
  rejectReasons.map((type, idx) => [type, displayRejectReasons[idx]]),
);
export const mapDisplayToRejectReason = new Map(
  displayRejectReasons.map((displayType, idx) => [displayType, rejectReasons[idx]]),
);
export const getMapRecommsRejectReasonToDisplay = (cloudType) => {
  const rejReasonObjValues = [
    ...Object.values(RecommendationRejectReasonIntro),
    ...Object.values(mapCloudTypeToRejectReason.get(cloudType)),
    ...Object.values(RecommendationRejectReason),
  ];
  const dispRejReasonObjValues = [
    ...Object.values(RecommendationRejectReasonDisplayIntro),
    ...Object.values(mapCloudTypeToRejectReasonDisplay.get(cloudType)),
    ...Object.values(RecommendationRejectReasonDisplay),
  ];
  return new Map(rejReasonObjValues.map((type, idx) => [type, dispRejReasonObjValues[idx]]));
};
export const getRecommendationRejectReason = (cloudType) => ({
  ...RecommendationRejectReasonIntro,
  ...mapCloudTypeToRejectReason.get(cloudType),
  ...RecommendationRejectReason,
});
// assert(mapDisplayRecommendationsTypeToType.length === mapDisplayRecommendationsTypeToType.length);

// actual values from the raw data
export class RecommFieldsAndFiltersValues {
  static OPEN = RecommendationStatus.OPEN;

  static COMPLETED = RecommendationStatus.COMPLETED;

  static COMPLETED_BY_SYSTEM = RecommendationStatus.COMPLETED_BY_SYSTEM;

  static DISMISSED_BY_SYSTEM = RecommendationStatus.DISMISSED_BY_SYSTEM;

  static DISMISSED_BY_USER = RecommendationStatus.DISMISSED_BY_USER;

  static EXCLUDED = RecommendationStatus.EXCLUDED;

  static INVALID = RecommendationStatus.INVALID;

  static ALL = RecommendationStatus.NUM_OF_STATUSES;

  static CURRENT_MONTH = 'Current month';

  static PREVIOUS_MONTH = 'Previous month';

  static LAST_Q = 'Last 3 months';

  static YTD = 'Year to date';

  static EC2_RI = 'ri';

  static EC2_REGION = 'region';

  static EC2_SPOT = 'spot';

  static EC2_VERSION_UPGRADE = 'version-upgrade';
}

export const mapRecommendationsTypeToServiceName = new Map([
  [RecommendationTypes.EC2_RI, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_SPOT, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_REGION, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_VERSION_UPGRADE, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_OPERATION_SYS, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_IP_UNATTACHED, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_IDLE, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_EBS_UNATTACHED, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_IDLE_LOAD_BALANCER, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_REGION_MIGRATION, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_LOW_CPU_USAGE, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_SCHEDULE, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_SAVINGS_PLANS, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.DDB_IDLE, getDisplayAwsLabel(AwsServiceNames.DDB)],
  [RecommendationTypes.RDS_RI, getDisplayAwsLabel(AwsServiceNames.RDS)],
  [RecommendationTypes.RDS_VERSION_UPGRADE, getDisplayAwsLabel(AwsServiceNames.RDS)],
  [RecommendationTypes.RDS_IDLE, getDisplayAwsLabel(AwsServiceNames.RDS)],
  [RecommendationTypes.RDS_TYPE_CHANGE, getDisplayAwsLabel(AwsServiceNames.RDS)],
  [RecommendationTypes.RDS_IOPS_CHANGE, getDisplayAwsLabel(AwsServiceNames.RDS)],
  [RecommendationTypes.RDS_CLASS_CHANGE, getDisplayAwsLabel(AwsServiceNames.RDS)],
  [RecommendationTypes.RDS_IDLE, getDisplayAwsLabel(AwsServiceNames.RDS)],
  [RecommendationTypes.S3_IDLE, getDisplayAwsLabel(AwsServiceNames.S3)],
  [RecommendationTypes.S3_VERSIONING, getDisplayAwsLabel(AwsServiceNames.S3)],
  [RecommendationTypes.S3_STORAGE_CLASS, getDisplayAwsLabel(AwsServiceNames.S3)],
  [RecommendationTypes.S3_MULTIPART_UPLOAD, getDisplayAwsLabel(AwsServiceNames.S3)],
  [RecommendationTypes.EBS_TYPE_CHANGE, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EBS_TYPE_SIZE_CHANGE, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EBS_OUTDATED_SNAPSHOT, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EBS_UPGRADE, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_UNNECESSARY_DATA_TRANSFER, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.EC2_STOPPED_INSTANCE, getDisplayAwsLabel(AwsServiceNames.EC2)],
  [RecommendationTypes.ELASTICACHE_RI, getDisplayAwsLabel(AwsServiceNames.ELASTICACHE)],
  [RecommendationTypes.OPEN_SEARCH_RI, getDisplayAwsLabel(AwsServiceNames.OPENSEARCH)],
  [RecommendationTypes.REDSHIFT_LOW_UTILIZATION, getDisplayAwsLabel(AwsServiceNames.REDSHIFT)],
  [RecommendationTypes.ELASTICSEARCH_LOW_UTILIZATION, getDisplayAwsLabel(AwsServiceNames.ELASTICSEARCH)],
  [RecommendationTypes.ELASTICACHE_LOW_UTILIZATION, getDisplayAwsLabel(AwsServiceNames.ELASTICACHE)],
  [RecommendationTypes.NAT_GATEWAY_LOW_UTILIZATION, getDisplayAwsLabel(AwsServiceNames.NAT_GATEWAY)],
  [RecommendationTypes.DOCUMENT_DB_LOW_UTILIZATION, getDisplayAwsLabel(AwsServiceNames.DOCUMENT_DB)],
  [RecommendationTypes.NEPTUNE_LOW_UTILIZATION, getDisplayAwsLabel(AwsServiceNames.NEPTUNE)],
  [RecommendationTypes.KINESIS_LOW_UTILIZATION, getDisplayAwsLabel(AwsServiceNames.KINESIS)],
  // AZURE ******
  [RecommendationTypes.AZURE_VM_RI, getDisplayAzureLabel(AzureServiceNames.VM)],
  [RecommendationTypes.AZURE_IP_UNATTACHED, getDisplayAzureLabel(AzureServiceNames.VM)],
  [RecommendationTypes.AZURE_DISK_UNATTACHED, getDisplayAzureLabel(AzureServiceNames.AZURE_MANAGED_DISKS)],
  [RecommendationTypes.AZURE_DISK_TYPE_CHANGE, getDisplayAzureLabel(AzureServiceNames.AZURE_MANAGED_DISKS)],
  [RecommendationTypes.AZURE_IDLE_LOAD_BALANCER, getDisplayAzureLabel(AzureServiceNames.VM)],
  [RecommendationTypes.AZURE_VM_IDLE, getDisplayAzureLabel(AzureServiceNames.VM)],
  [RecommendationTypes.AZURE_VM_STOPPED, getDisplayAzureLabel(AzureServiceNames.VM)],
  [RecommendationTypes.AZURE_MARIA_DB_IDLE, getDisplayAzureLabel(AzureServiceNames.DB)],
  [RecommendationTypes.AZURE_MYSQL_DB_IDLE, getDisplayAzureLabel(AzureServiceNames.DB)],
  [RecommendationTypes.AZURE_SQL_DB_IDLE, getDisplayAzureLabel(AzureServiceNames.DB)],
  [RecommendationTypes.AZURE_POSTGRESQL_DB_IDLE, getDisplayAzureLabel(AzureServiceNames.DB)],
  [RecommendationTypes.AZURE_COSMOS_DB_IDLE, getDisplayAzureLabel(AzureServiceNames.DB)],
  [RecommendationTypes.AZURE_VM_RIGHT_SIZING, getDisplayAzureLabel(AzureServiceNames.VM)],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_COSMOS, getDisplayAzureLabel(AzureServiceNames.AZURE_RESERVED_CAPACITY)],
  [
    RecommendationTypes.AZURE_RESERVED_CAPACITY_DATA_EXPLORER,
    getDisplayAzureLabel(AzureServiceNames.AZURE_RESERVED_CAPACITY),
  ],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_MY_SQL, getDisplayAzureLabel(AzureServiceNames.AZURE_RESERVED_CAPACITY)],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_PG, getDisplayAzureLabel(AzureServiceNames.AZURE_RESERVED_CAPACITY)],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_REDIS, getDisplayAzureLabel(AzureServiceNames.AZURE_RESERVED_CAPACITY)],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL, getDisplayAzureLabel(AzureServiceNames.AZURE_RESERVED_CAPACITY)],
  [
    RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL_DATA_WH,
    getDisplayAzureLabel(AzureServiceNames.AZURE_RESERVED_CAPACITY),
  ],
  [RecommendationTypes.AZURE_SNAPSHOT_MIGRATION, getDisplayAzureLabel(AzureServiceNames.AZURE_MANAGED_DISKS)],
  [RecommendationTypes.AZURE_OUTDATED_SNAPSHOT, getDisplayAzureLabel(AzureServiceNames.AZURE_MANAGED_DISKS)],
  [RecommendationTypes.AZURE_KUSTO_UNUSED_DATA, getDisplayAzureLabel(AzureServiceNames.AZURE_DATA_EXPLORER)],
  [RecommendationTypes.AZURE_APP_SERVICE_RESERVED_CAPACITY, getDisplayAzureLabel(AzureServiceNames.AZURE_APP_SERVICE)],
  [RecommendationTypes.AZURE_DB_RESERVED_CAPACITY, getDisplayAzureLabel(AzureServiceNames.AZURE_RESERVED_CAPACITY)],
  // GCP ******
  [RecommendationTypes.GCP_IP_IDLE, getDisplayGcpLabel(GcpServiceNames.IP)],
  [RecommendationTypes.GCP_VM_STOPPED, getDisplayGcpLabel(GcpServiceNames.VM)],
  [RecommendationTypes.GCP_VM_IDLE, getDisplayGcpLabel(GcpServiceNames.VM)],
  [RecommendationTypes.GCP_VM_RIGHT_SIZING, getDisplayGcpLabel(GcpServiceNames.VM)],
  [RecommendationTypes.GCP_USAGE_COMMITMENT, 'Commitment Use Discount'],
]);
export const mapRecommendationsTypeToIconSrc = new Map([
  [RecommendationTypes.IP_PUBLIC_IPV4, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.ECS_FARGATE, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.RDS_IOPS_CHANGE, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.KMS_IDLE, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.KMS_OLD, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.EC2_RI, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.EC2_SPOT, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.EC2_REGION, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.EC2_VERSION_UPGRADE, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.EC2_OPERATION_SYS, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.EC2_IP_UNATTACHED, ICONS.recDashboard.networkIcon],
  [RecommendationTypes.EC2_IDLE, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.EC2_EBS_UNATTACHED, ICONS.recDashboard.storageIcon],
  [RecommendationTypes.EC2_IDLE_LOAD_BALANCER, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.EC2_REGION_MIGRATION, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.EC2_LOW_CPU_USAGE, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.EC2_SCHEDULE, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.EC2_SAVINGS_PLANS, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.DDB_IDLE, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.RDS_RI, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.ELASTICACHE_RI, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.OPEN_SEARCH_RI, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.RDS_VERSION_UPGRADE, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.RDS_IDLE, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.RDS_TYPE_CHANGE, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.RDS_CLASS_CHANGE, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.RDS_IOPS_CHANGE, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.S3_IDLE, ICONS.recDashboard.storageIcon],
  [RecommendationTypes.S3_VERSIONING, ICONS.recDashboard.storageIcon],
  [RecommendationTypes.S3_STORAGE_CLASS, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.S3_MULTIPART_UPLOAD, ICONS.recDashboard.storageIcon],
  [RecommendationTypes.EBS_TYPE_CHANGE, ICONS.recDashboard.storageIcon],
  [RecommendationTypes.EBS_TYPE_SIZE_CHANGE, ICONS.recDashboard.storageIcon],
  [RecommendationTypes.EBS_OUTDATED_SNAPSHOT, ICONS.recDashboard.storageIcon],
  [RecommendationTypes.EBS_UPGRADE, ICONS.recDashboard.storageIcon],
  [RecommendationTypes.EC2_UNNECESSARY_DATA_TRANSFER, ICONS.recDashboard.networkIcon],
  [RecommendationTypes.EC2_STOPPED_INSTANCE, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.REDSHIFT_LOW_UTILIZATION, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.ELASTICSEARCH_LOW_UTILIZATION, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.ELASTICACHE_LOW_UTILIZATION, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.NAT_GATEWAY_LOW_UTILIZATION, ICONS.recDashboard.networkIcon],
  [RecommendationTypes.DOCUMENT_DB_LOW_UTILIZATION, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.NEPTUNE_LOW_UTILIZATION, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.KINESIS_LOW_UTILIZATION, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.CLOUDTRAIL_DUPLICATES_TRAILS, ICONS.recDashboard.computeIcon],
  // AZURE ******
  [RecommendationTypes.AZURE_VM_RI, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.AZURE_IP_UNATTACHED, ICONS.recDashboard.networkIcon],
  [RecommendationTypes.AZURE_DISK_UNATTACHED, ICONS.recDashboard.storageIcon],
  [RecommendationTypes.AZURE_DISK_TYPE_CHANGE, ICONS.recDashboard.storageIcon],
  [RecommendationTypes.AZURE_IDLE_LOAD_BALANCER, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.AZURE_VM_IDLE, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.AZURE_VM_STOPPED, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.AZURE_MARIA_DB_IDLE, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.AZURE_MYSQL_DB_IDLE, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.AZURE_POSTGRESQL_DB_IDLE, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.AZURE_SQL_DB_IDLE, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.AZURE_COSMOS_DB_IDLE, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.AZURE_VM_RIGHT_SIZING, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.AZURE_APP_RIGHT_SIZING, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.AZURE_APP_SERVICE_RESERVED_CAPACITY, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_COSMOS, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.AZURE_COSMOS_RIGHT_SIZING, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.AZURE_DB_RI, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.AZURE_KUSTO_UNUSED_DATA, ICONS.recDashboard.networkIcon],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_DATA_EXPLORER, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_MY_SQL, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_PG, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_REDIS, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL_DATA_WH, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.AZURE_SNAPSHOT_MIGRATION, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.AZURE_OUTDATED_SNAPSHOT, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.AZURE_STORAGE, ICONS.recDashboard.storageIcon],
  [RecommendationTypes.AZURE_APP_SERVICE, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.AZURE_DATA_EXPLORER, ICONS.recDashboard.networkIcon],
  [RecommendationTypes.AZURE_MANAGED_DISKS, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY, ICONS.recDashboard.dataBaseIcon],
  [RecommendationTypes.AZURE_DB_RESERVED_CAPACITY, ICONS.recDashboard.computeIcon],
  // GCP ******
  [RecommendationTypes.GCP_IP_IDLE, ICONS.recDashboard.networkIcon],
  [RecommendationTypes.GCP_VM_STOPPED, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.GCP_VM_IDLE, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.GCP_USAGE_COMMITMENT, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.GCP_DISK_UNATTACHED, ICONS.recDashboard.computeIcon],
  [RecommendationTypes.GCP_VM_RIGHT_SIZING, ICONS.recDashboard.computeIcon],
]);

export class RecommendationCommonConstants {
  static NUM_OF_DAILY_RECS = 3;

  static NUM_OF_DAILY_RECS_ROWS = 6;
}

export class ContentPageTypes {
  static DAILY_RECS = 0;

  static RECS_GENERAL = 1;

  static RECS_PAGE = 2;

  static EXCLUDE_RULES_PAGE = 3;
}

export class ContentDetailsDisplay {
  static DAILY_RECS_CLOSE = 0;

  static DAILY_RECS_OPEN = 1;

  static RECS_PAGE = 2;

  static EXCLUDE_RULES_PAGE = 3;
}

export const mapRecpageTypeToDefaultDetailsDiaply = new Map([
  [ContentPageTypes.DAILY_RECS, ContentDetailsDisplay.DAILY_RECS_CLOSE],
  [ContentPageTypes.RECS_PAGE, ContentDetailsDisplay.RECS_PAGE],
  [ContentPageTypes.EXCLUDE_RULES_PAGE, ContentDetailsDisplay.EXCLUDE_RULES_PAGE],
]);

export const REC_REPORTS_TABLE_COLUMNS = [
  { name: 'id', title: 'Id', getCellValue: (row) => row.id },
  { name: 'reportName', title: 'Report Name', getCellValue: (row) => row.reportName },
  {
    name: 'email',
    title: 'Recipients',
    getCellValue: (row) => row.email,
  },
  {
    name: 'frequency',
    title: 'Frequency',
    getCellValue: (row) => row.frequency,
  },
  {
    name: 'period',
    title: 'Period',
    getCellValue: (row) => row.period,
  },
  {
    name: 'creationDate',
    title: 'Updated',
    getCellValue: (row) => row.creationDate,
  },
  {
    name: 'userAction',
    title: 'Actions',
    getCellValue: (row) => row.userAction,
  },
];
export const REC_REPORTS_COLUMN_WIDTHS = [
  { columnName: 'id', width: 200 },
  { columnName: 'reportName', width: 200 },
  { columnName: 'email', width: 200 },
  { columnName: 'frequency', width: 200 },
  { columnName: 'period', width: 200 },
  { columnName: 'creationDate', width: 200 },
  { columnName: 'userAction', width: 200 },
];

export const REC_REPORTS_TABLE_COLUMN_EXTENSIONS = [
  { columnName: 'id', align: 'center' },
  {
    columnName: 'userAction',
    align: 'center',
  },
];
export const REC_REPORTS_DEFAULT_HIDDEN_COLUMN_NAMES = ['id'];
export const REC_REPORTS_DEFAULT_SORTING = [{ columnName: 'creationDate', direction: 'desc' }];

export const REC_DASHB_SUMMARY_TABLE_COLUMNS = [
  { name: 'typeDisplay', title: 'Type', getCellValue: (row) => row.type },
  { name: 'savings', title: 'Annual Savings', getCellValue: (row) => row.savings },
  { name: 'percentOfTotal', title: '% of Total', getCellValue: (row) => row.percentOfTotal },
  { name: 'quantity', title: '#' },
  { name: 'info', title: ' ' },
];
export const REC_DASHB_SUMMARY_COLUMN_WIDTHS = [
  { columnName: 'typeDisplay', width: 250 },
  { columnName: 'savings', width: 150 },
  { columnName: 'percentOfTotal', width: 100 },
  { columnName: 'quantity', width: 100 },
  { columnName: 'info', width: 100 },
];

export const REC_DASHB_SUMMARY_TABLE_COLUMN_EXTENSIONS = [
  { columnName: 'typeDisplay', align: 'left' },
  { columnName: 'savings', align: 'center' },
  { columnName: 'percentOfTotal', align: 'center' },
  { columnName: 'quantity', align: 'center' },
  { columnName: 'info', align: 'left' },
];
export const REC_DASHB_SUMMARY_DEFAULT_SORTING = [];
// export const REC_DASHB_SUMMARY_DEFAULT_SORTING = [{ columnName: 'savings', direction: 'desc' }];

export const keysToRemoveFromCsv = [
  'project',
  'environment',
  'resourcesQuantity',
  'action',
  'status',
  'isActive',
  'creationtime',
  'alertId',
  'rejReason',
  'type',
  'currTotalCost',
  'recommTotalCost',
  'isBeta',
  'potentialSavings',
  'creationTime',
  'updateTime',
  'startingTime',
  'daysToCheck',
  'totalCostRecommended',
  'totalCostCurrent',
  'signature',
  'dbCreationTime',
  'multiResourceId',
];

export const MapSpanToWidth = {
  1: 50,
  2: 100,
  3: 33,
  4: 25,
  5: 66,
  6: 75,
};

export const MapWidthToSpan = {
  33: 3,
  50: 1,
  100: 2,
  25: 4,
  66: 5,
  75: 6,
};
