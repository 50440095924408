import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import ToggleAllertButton from 'shared/components/buttons/ControlledToggleButton';

function SendAlertsWrapper(props) {
  return (
    <div>
      <div>
        <ReadOnlyDisplayWrapper isHide={false} userReadOnly={props.currentUserReadOnly}>
          <div>
            {/* <Col xs={12} md={12} lg={12} xl={12}> */}
            <div className="toggler-with-text-wrapper-no-margin">
              <div className="toggler-inner-item">
                <ToggleAllertButton
                  checked={props.isOpen}
                  selectedItem={props.alertType}
                  handler={props.onToggleBtnClick}
                />
              </div>
              <div className="toggler-inner-item">
                <p>{props.toggleButtonText}</p>
              </div>
            </div>
            {/* </Col> */}
          </div>
        </ReadOnlyDisplayWrapper>
      </div>
      <div>
        <Collapse isOpen={props.isOpen}>{props.children}</Collapse>
      </div>
    </div>
  );
}

SendAlertsWrapper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  currentUserReadOnly: PropTypes.bool.isRequired,
  toggleButtonText: PropTypes.string.isRequired,
  alertType: PropTypes.string.isRequired,
  onToggleBtnClick: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
};

export default SendAlertsWrapper;
