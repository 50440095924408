import config from '../config';

export const generateTopicConfiguration = ({ bucketRegionName }) => `{
    "TopicConfigurations": [
        {
            "TopicArn": "arn:aws:sns:${bucketRegionName}:932213950603:${config.connect.TOPIC}",
            "Events": [
                "s3:ObjectCreated:*"
            ]
        }
    ]
}
`;

export const generatePileusRole = ({ externalId }) => `{
    "Version": "2012-10-17",
    "Statement": {
      "Effect": "Allow",
      "Principal": { "AWS": "arn:aws:iam::932213950603:root" },
      "Action": "sts:AssumeRole",
      "Condition": {
        "StringEquals": {
          "sts:ExternalId": "${externalId}"
        }
      }
    }
}
`;

export const generatePileusPolicy = ({ invoiceBucketName }) => {
  // When generating this policy on the on-boarding phase we need to generate it with the S3
  // read/write permissions for the billing report bucket so we could copy it to our bucket
  // when the user is not on-boarding this policy is generated without them.
  const isRootAccountOnBoarding = invoiceBucketName
    ? `{
            "Effect": "Allow",
            "Action": [
                "s3:ListBucket",
                "s3:Get*"
            ],
            "Resource": [
                "arn:aws:s3:::${invoiceBucketName}/*",
                "arn:aws:s3:::${invoiceBucketName}"
            ]
        },
        {
            "Effect": "Allow",
            "Action": [
                "s3:Put*"
            ],
            "Resource": [
                "arn:aws:s3:::${config.connect.PILEUS_BUCKET}/*",
                "arn:aws:s3:::${config.connect.PILEUS_BUCKET}"
            ]
        },
      `
    : '';
  return `{
    "Version": "2012-10-17",
    "Statement": [
        ${isRootAccountOnBoarding}
        {
            "Effect": "Allow",
            "Action": ["ec2:Describe*"],
            "Resource": "*"
        },
        {
            "Action": [
                "organizations:ListAccounts"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "elasticloadbalancing:Describe*"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "s3:ListBucket",
                "s3:GetBucketLocation",
                "s3:ListBucketVersions",
                "s3:GetBucketVersioning",
                "s3:GetLifecycleConfiguration",
                "s3:GetEncryptionConfiguration",
                "s3:ListAllMyBuckets",
                "s3:ListBucketMultipartUploads",
                "s3:ListMultipartUploadParts"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "cloudwatch:ListMetrics",
                "cloudwatch:GetMetricStatistics",
                "cloudwatch:GetMetricData",
                "logs:DescribeLogGroups",
                "logs:GetQueryResults"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "logs:CreateExportTask",
                "logs:StartQuery"
            ],
            "Resource": [
                "arn:aws:logs:*:*:log-group:/aws/containerinsights/*/performance",
                "arn:aws:logs:*:*:log-group:/aws/containerinsights/*/performance:*",
                "arn:aws:logs:*:*:log-group:/aws/containerinsights/*/performance:*:*"
            ],
            "Effect": "Allow"
        },
        {
            "Action": [
                "autoscaling:Describe*"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "eks:ListFargateProfiles",
                "eks:DescribeNodegroup",
                "eks:ListNodegroups",
                "eks:DescribeFargateProfile",
                "eks:ListTagsForResource",
                "eks:ListUpdates",
                "eks:DescribeUpdate",
                "eks:DescribeCluster",
                "eks:ListClusters"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "dynamodb:Describe*",
                "dynamodb:List*",
                "tag:GetResources",
                "rds:DescribeDBInstances",
                "rds:DescribeDBClusters",
                "rds:ListTagsForResource",
                "ecs:DescribeClusters",
                "redshift:DescribeClusters",
                "es:ListDomainNames",
                "es:DescribeElasticsearchDomains",
                "elasticache:DescribeCacheClusters",
                "kinesis:ListStreams",
                "kinesis:DescribeStream",
                "kms:ListKeys",
                "kms:DescribeKey",
                "kms:ListResourceTags",
                "cloudTrail:DescribeTrails"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "account:GetAccountInformation",
                "billing:GetBillingData",
                "billing:GetBillingDetails",
                "billing:GetBillingNotifications",
                "billing:GetBillingPreferences",
                "billing:GetContractInformation",
                "billing:GetCredits",
                "billing:GetIAMAccessPreference",
                "billing:GetSellerOfRecord",
                "billing:ListBillingViews",
                "ce:DescribeNotificationSubscription",
                "ce:DescribeReport",
                "ce:GetAnomalies",
                "ce:GetAnomalyMonitors",
                "ce:GetAnomalySubscriptions",
                "ce:GetCostAndUsage",
                "ce:GetCostAndUsageWithResources",
                "ce:GetCostCategories",
                "ce:GetCostForecast",
                "ce:GetDimensionValues",
                "ce:GetPreferences",
                "ce:GetReservationCoverage",
                "ce:GetReservationPurchaseRecommendation",
                "ce:GetReservationUtilization",
                "ce:GetRightsizingRecommendation",
                "ce:GetSavingsPlansCoverage",
                "ce:GetSavingsPlansPurchaseRecommendation",
                "ce:GetSavingsPlansUtilization",
                "ce:GetSavingsPlansUtilizationDetails",
                "ce:GetTags",
                "ce:GetUsageForecast",
                "ce:ListCostAllocationTags",
                "ce:ListSavingsPlansPurchaseRecommendationGeneration",
                "consolidatedbilling:GetAccountBillingRole",
                "consolidatedbilling:ListLinkedAccounts",
                "cur:DescribeReportDefinitions",
                "cur:GetClassicReport",
                "cur:GetClassicReportPreferences",
                "cur:GetUsageReport",
                "cur:ValidateReportDestination",
                "freetier:GetFreeTierAlertPreference",
                "freetier:GetFreeTierUsage",
                "invoicing:GetInvoiceEmailDeliveryPreferences",
                "invoicing:GetInvoicePDF",
                "invoicing:ListInvoiceSummaries",
                "payments:GetPaymentInstrument",
                "payments:GetPaymentStatus",
                "payments:ListPaymentPreferences",
                "tax:GetTaxInheritance",
                "tax:GetTaxRegistrationDocument",
                "tax:ListTaxRegistrations"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "logs:DescribeExportTasks"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "s3:Put*"
            ],
            "Resource": "arn:aws:s3:::prod-k8s-cloudwatch-logs-*",
            "Effect": "Allow"
        }
    ]
}
  `;
};
