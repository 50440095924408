import React from 'react';
import PropTypes from 'prop-types';
import svgImage from '../../../img/lightbulb-gear.svg';

import classes from './recommendationInsideLook.module.scss';

const RecommendationInsideLook = ({ description, descriptionUrl, recData }) => {
  const formatDescription = () => {
    if (descriptionUrl?.fullSentence) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {description}
          <a href={descriptionUrl.url} target="_blank" rel="noopener noreferrer" className={classes.descriptionUrl}>
            {descriptionUrl.fullSentence}
          </a>
        </div>
      );
    }
    const words = description.split(' ');
    const formattedText = words.map((word) => {
      const wordUrl = word.includes(descriptionUrl.text) ? descriptionUrl.url : null;
      if (wordUrl) {
        return (
          <a href={wordUrl} target="_blank" rel="noopener noreferrer" className={classes.descriptionUrl}>
            {word}
          </a>
        );
      }
      return <span>{`${word} `}</span>;
    });
    return formattedText;
  };

  return (
    <div className={classes.insideLookContainer}>
      <img src={svgImage} alt="lightbulb-gear" className={classes.lightBulbGear} />
      <div className={classes.content}>
        <div className={classes.description}>
          <div className={classes.title}>Inside Look</div>
          <div className={classes.text}>{descriptionUrl ? formatDescription(description) : description}</div>
        </div>
        {recData && (recData.sp_savings || recData.ri_savings) ? (
          <div className={classes.coverageContainer}>
            <div className={classes.coverage}>
              {recData.sp_savings ? (
                <>
                  <div className={classes.coverageLabel}>
                    Currently this instance is using SP, moving to a new model will reduce SP usage
                  </div>
                  <div className={classes.coverageText}>
                    SP coverage: <span className={classes.coverageNumber}>{recData?.sp_coverage}%</span>
                  </div>
                </>
              ) : null}
            </div>
            <div className={classes.coverage}>
              <div className={classes.coverage}>
                {recData.ri_savings ? (
                  <>
                    <div className={classes.coverageLabel}>
                      Currently this instance is using RI, moving to a new model will reduce RI usage
                    </div>
                    <div className={classes.coverageText}>
                      RI coverage: <span className={classes.coverageNumber}>{recData?.ri_coverage}%</span>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

RecommendationInsideLook.propTypes = {
  description: PropTypes.string.isRequired,
  descriptionUrl: PropTypes.arrayOf(PropTypes.object),
  recData: PropTypes.object,
};

RecommendationInsideLook.defaultProps = {
  descriptionUrl: [],
  recData: {},
};

export default RecommendationInsideLook;
