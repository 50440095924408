import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  calculateStartAndEndDates,
  CLOSED_AND_DONE_RECS_DATES_FILTERS,
  DATE_RANGE_OPTIONS,
  FILTERS,
} from 'recommendationsNew/consts';

const RecommendationsNewContext = createContext();

export const RecommendationsNewProvider = ({ children }) => {
  const initialFiltersState = {
    [FILTERS.IS_OPEN.id]: true,
    [FILTERS.USER_STATUS.id]: { done: false, excluded: false },
    [FILTERS.IS_STARRED.id]: null,
    [FILTERS.OPEN_RECS_CREATION_DATE.id]: {
      from: calculateStartAndEndDates(DATE_RANGE_OPTIONS.LAST_YEAR).from,
      to: null,
    },
    [FILTERS.CLOSED_AND_DONE_RECS_DATES.id]: {
      [CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE]: {
        from: calculateStartAndEndDates(DATE_RANGE_OPTIONS.LAST_YEAR).from,
        to: null,
      },
    },
  };

  const [recommendationFilters, setRecommendationFilters] = useState(initialFiltersState);

  return (
    <RecommendationsNewContext.Provider
      value={{
        recommendationFilters,
        setRecommendationFilters,
        initialFiltersState,
      }}
    >
      {children}
    </RecommendationsNewContext.Provider>
  );
};

RecommendationsNewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RecommendationsNewContext;
