import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';

import classes from './heatMap.module.scss';

const HeatMapSummaryItem = ({
  isBackgroundColored,
  isCostColored,
  summaryTitle,
  summaryUnit,
  summaryNumber,
  additionalCostSavingsData,
}) =>
  summaryTitle ? (
    <div className={classes.summaryItem}>
      <div className={`${classes.data} ${isBackgroundColored ? classes.isMain : ''}`}>
        <div className={classes.summaryItemTitle}>{summaryTitle}</div>
        <div className={`${classes.summaryItemNumber} ${isCostColored ? classes.colored : ''}`}>
          <div>{summaryUnit}</div>
          <div>{summaryNumber?.toLocaleString()}</div>
        </div>
      </div>
      {additionalCostSavingsData ? (
        <>
          {additionalCostSavingsData.map((item) => (
            <div className={classes.additionalData}>
              {item.icon ? (
                <div className={classes.statusIcon}>
                  <GenerateIcon iconName={item.icon} />
                </div>
              ) : null}
              <div>{item.label}: </div>
              {summaryUnit && <div className={classes.value}>{summaryUnit}</div>}
              <div className={classes.value}>{item.value?.toLocaleString()}</div>
              <div className={classes.count}>({item.count})</div>
            </div>
          ))}
        </>
      ) : null}
    </div>
  ) : null;

HeatMapSummaryItem.propTypes = {
  isBackgroundColored: PropTypes.bool,
  isCostColored: PropTypes.bool,
  summaryTitle: PropTypes.string.isRequired,
  summaryUnit: PropTypes.string,
  summaryNumber: PropTypes.number.isRequired,
  additionalCostSavingsData: PropTypes.arrayOf(PropTypes.object),
};

HeatMapSummaryItem.defaultProps = {
  isBackgroundColored: false,
  isCostColored: false,
  summaryUnit: undefined,
  additionalCostSavingsData: undefined,
};

export default HeatMapSummaryItem;
