/* eslint-disable max-len */
import { action, makeObservable, observable, runInAction } from 'mobx';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { prepareBodyFiltersIncludeOrExclude } from 'shared/utils/dataPrepareUtil';
import moment from 'moment';

export default class AnomalyDetectionModel {
  constructor(apiGateway) {
    this.apiGateway = apiGateway;
    this.anomalyDetectionData = [];
    this.anomalyDetectionRules = [];
    this.isLoading = true;
    this.isLoadingRulesData = false;
    makeObservable(this, {
      fetchAnomalyDetectionData: action,
      updateIsDismissedAnomaly: action,
      anomalyDetectionData: observable,
      anomalyDetectionRules: observable,
      isLoading: observable,
      isLoadingRulesData: observable,
      createAnomalyDetectionRule: action,
      updateAnomalyDetectionRule: action,
      removeAnomalyDetectionRule: action,
    });
  }

  fetchAnomalyDetectionData = async () => {
    this.isLoading = true;
    this.isLoadingRulesData = true;
    try {
      const anomalyDetectionRawData = await this.apiGateway.getAnomalyDetectionData();
      const anomalyDetectionRules = await this.apiGateway.getAnomalyDetectionRules();
      const anomalyDetectionData = [];
      anomalyDetectionRawData.forEach((row) => {
        if (!row.isDismissed) {
          anomalyDetectionData.push({
            serviceName: LabelCoordinator.getServiceNameDisplayValue(row.serviceName),
            // serviceName: row.serviceName,
            linkedAccount: row.linkedAccountId,
            linkedAccountName: row.linkedAccountName,
            usageDate: moment(row.currUsageDate).format('YYYY-MM-DD'),
            lagUsageDate: moment(row.lagUsageDate).format('YYYY-MM-DD'),
            beforeCost: row.lagCost,
            currentCost: row.currCost,
            difference: row.diff,
            percentageChange: row.percentageChange,
            anomalyKey: row.uuid,
            divisionName: row.divisionName,
            usageType: row.usageType,
          });
        }
      });

      runInAction(() => {
        this.anomalyDetectionData = anomalyDetectionData;
        this.anomalyDetectionRules = anomalyDetectionRules;
        this.isLoading = false;
        this.isLoadingRulesData = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        this.isLoadingRulesData = false;
        this.state = 'error';
      });
    }
  };

  updateIsDismissedAnomaly = async (anomalyKey) => {
    try {
      await this.apiGateway.updateIsDismissedAnomaly(anomalyKey);
      runInAction(() => {});
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
      });
    }
  };

  createAnomalyDetectionRule = async (bodyParams, selectedOptionsMap, filterStatusTypeMap) => {
    this.isLoadingRulesData = true;
    const { includeFilterParams, excludeFilterParams } = prepareBodyFiltersIncludeOrExclude(
      selectedOptionsMap,
      filterStatusTypeMap,
    );
    const body = bodyParams;
    body.includeFilters = includeFilterParams;
    body.excludeFilters = excludeFilterParams;
    const res = await this.apiGateway.createAnomalyDetectionRule(body);
    runInAction(() => {
      this.isLoadingRulesData = false;
      if (res.is_valid) {
        body.ruleId = res.rule_id;
        this.anomalyDetectionRules.push(body);
      }
    });
  };

  updateAnomalyDetectionRule = async (bodyParams, selectedOptionsMap, filterStatusTypeMap) => {
    this.isLoadingRulesData = true;
    const { includeFilterParams, excludeFilterParams } = prepareBodyFiltersIncludeOrExclude(
      selectedOptionsMap,
      filterStatusTypeMap,
    );
    const body = bodyParams;
    body.includeFilters = includeFilterParams;
    body.excludeFilters = excludeFilterParams;
    await this.apiGateway.updateAnomalyDetectionRule(body);
    runInAction(() => {
      this.isLoadingRulesData = false;
      const index = this.anomalyDetectionRules.map((rule) => rule.ruleId).indexOf(body.ruleId);
      this.anomalyDetectionRules[index] = bodyParams;
    });
  };

  removeAnomalyDetectionRule = async (ruleId) => {
    this.isLoadingRulesData = true;
    try {
      await this.apiGateway.removeAnomalyDetectionRule(ruleId);
      runInAction(() => {
        this.isLoadingRulesData = false;
        const index = this.anomalyDetectionRules.map((rule) => rule.ruleId).indexOf(ruleId);
        this.anomalyDetectionRules.splice(index, 1);
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
      });
    }
    return this.anomalyDetectionRules;
  };

  invalidateData = () => {
    this.anomalyDetectionData = [];
    this.anomalyDetectionRules = [];
  };
}
