/* eslint-disable no-unused-vars */

export class AwsServiceNames {
  static S3 = 'Amazon Simple Storage Service';
  static EC2 = 'Amazon Elastic Compute Cloud';
  static EDP = 'EDP';
  static DDB = 'Amazon DynamoDB';
  static RDS = 'Amazon Relational Database Service';
  static EBS = 'Amazon Elastic Block Store';
  static VPC = 'Amazon Virtual Private Cloud';
  static GUARD_DUTY = 'Amazon GuardDuty';
  static REDSHIFT = 'Amazon Redshift';
  static ELASTICSEARCH = 'Amazon Elasticsearch Service';
  static OPENSEARCH = 'Amazon OpenSearch Service';
  static ELASTICACHE = 'Amazon ElastiCache';
  static NAT_GATEWAY = 'Amazon NAT Gateway';
  static DOCUMENT_DB = 'Amazon DocumentDB [with MongoDB compatibility]';
  static NEPTUNE = 'Amazon Neptune';
  static KINESIS = 'Amazon Kinesis';
}

export class AwsOperations {
  static RUN_INSTANCES = 'RunInstances';
}

export class AwsCommonFields {
  static NONE = 'none';
  static REGION = 'region';
  static AVAILABILITY_ZONE = 'zonetag';
  static LINKED_ACCOUNT_NAME = 'linkedaccname';
  static LINKED_ACCOUNT_ID = 'linkedaccid';
  static ENVIRONMENT = 'environment';
  static CLOUD_PROVIDER = 'cloudprovider';
  static CUSTOM_TAGS = 'customtags';
  static CUSTOM_TAGS_KEYS = 'customtags_keys';
  static ACCOUNT_TAGS = 'accounttags';
  static ACCOUNT_TAGS_KEYS = 'accounttags_keys';
  static CHARGE_TYPE = 'chargetype';
  static CATEGORIES = 'categories';
  static BUSINESS_MAPPING = 'businessmapping';
  static BUSINESS_MAPPING_VIEWPOINTS = 'businessmappingviewpoints';
  static CATEGORY = 'category';
  static K8S_CUSTOM_TAGS = 'k8scustomtags';
  static K8S_TAGS = 'k8stags';
  static K8S_CUSTOM_TAGS_KEYS = 'k8scustomtags_keys';
  static K8S_LABEL_TAGS = 'labels';
  static CUE_K8S_LABEL_TAGS = 'k8slabels';
  static VIRTUAL_CUSTOM_TAGS = 'virtualcustomtags';
  static VIEWS_CUSTOM_TAGS = 'viewscustomtags';
  static SUB_VIEWS_CUSTOM_TAGS = 'subviewscustomtags';
  static METRIC_TYPE = 'metrictype';
  static REC_DB_CUSTOM_TAGS = 'recdbcustomtags';
  static PROJECT = 'project';
  static DEVELOPMENT = 'development';
  static PRODUCTION = 'production';
  static STAGING = 'staging';
  static TESTING = 'testing';
  static SERVICE = 'service';
  static OPERATION = 'operation';
  static TYPE = 'instancetype';
  static RESOURCE = 'resourceid';
  static RESOURCE_GROUP = 'resourcegroup';
  static INSTANCE_TYPE = 'instancetype';
  static INSTANCE_FAMILY = 'instancefamily';
  static USAGE_DATE = 'usagedate';
  static GROUP_BY = 'groupby';
  static TOTAL_COST = 'totalcost';
  static TOTAL_USAGE = 'totalhours';
  static FAMILY_TYPE = 'familytype';
  static QUANTITY_TYPE = 'quantitytype';
  static OS = 'os';
  static TAG = 'tag';
  static TAGS = 'tags';
  static DIVISION = 'division';
  static PURCHASE_OPTION = 'purchaseoption';
  static NODE = 'node';
  static NODE_GROUP = 'nodegroup';
  static CLUSTER = 'cluster';
  static POD = 'pod';
  static NAMESPACE = 'namespace';
  static K8S_INSTANCE_TYPE = 'k8sinstancetype';
  static K8S_NAMESPACE = 'k8snamespace';
  static K8S_CLUSTER = 'k8scluster';
  static K8S_NODE_GROUP = 'k8snodegroup';
  static PAYER_ACCOUNT = 'payeraccount';
  static PAYER_ACCOUNT_NAME = 'accountName';
  static ITEM_DESCRIPTION = 'itemdescription';
  static COST_TYPE = 'costtype';
  static USAGE_TYPE = 'usagetype';
  static SUB_SERVICE = 'subservice'; // data transfer services
  static DEPLOYMENT = 'deployment';
  static K8S_REGION = 'k8sregion';
}

export const K8S_CUE_FIELDS = [
  AwsCommonFields.K8S_NAMESPACE,
  AwsCommonFields.CUE_K8S_LABEL_TAGS,
  AwsCommonFields.K8S_CLUSTER,
  AwsCommonFields.K8S_NODE_GROUP,
];

export const objAwsRegionNames = {
  // Pay atention that if this object changed the change the same object in the app server!
  af_south_1: 'af-south-1',
  eu_south_1: 'eu-south-1',
  us_east_1: 'us-east-1',
  us_east_2: 'us-east-2',
  us_west_1: 'us-west-1',
  us_west_2: 'us-west-2',
  us_gov_west_1: 'us-gov-west-1',
  us_gov_east_1: 'us-gov-east-1',
  ca_central_1: 'ca-central-1',
  cn_north_1: 'cn-north-1',
  cn_northwest_1: 'cn-northwest-1',
  eu_central_1: 'eu-central-1',
  eu_west_1: 'eu-west-1',
  eu_west_2: 'eu-west-2',
  eu_west_3: 'eu-west-3',
  eu_north_1: 'eu-north-1',
  ap_east_1: 'ap-east-1',
  ap_northeast_1: 'ap-northeast-1',
  ap_northeast_2: 'ap-northeast-2',
  ap_northeast_3: 'ap-northeast-3',
  ap_southeast_1: 'ap-southeast-1',
  ap_southeast_2: 'ap-southeast-2',
  ap_southeast_3: 'ap-southeast-3',
  ap_southeast_4: 'ap-southeast-4',
  ap_south_1: 'ap-south-1',
  me_south_1: 'me-south-1',
  sa_east_1: 'sa-east-1',
  il_central_1: 'il-central-1',
};

export const mapRegionNamesToDescription = new Map([
  [objAwsRegionNames.af_south_1, 'Africa (Cape Town)'],
  [objAwsRegionNames.eu_south_1, 'Europe (Milan)'],
  [objAwsRegionNames.us_east_1, 'US East (N. Virginia)'],
  [objAwsRegionNames.us_east_2, 'US East (Ohio)'],
  [objAwsRegionNames.us_west_1, 'US West (N. California)'],
  [objAwsRegionNames.us_west_2, 'US West (Oregon)'],
  [objAwsRegionNames.us_gov_west_1, 'AWS GovCloud (US)'],
  [objAwsRegionNames.us_gov_east_1, 'AWS GovCloud (US-East)'],
  [objAwsRegionNames.ca_central_1, 'Canada (Central)'],
  [objAwsRegionNames.cn_north_1, 'China (Beijing)'],
  [objAwsRegionNames.cn_northwest_1, 'China (Ningxia)'],
  [objAwsRegionNames.eu_central_1, 'EU (Frankfurt)'],
  [objAwsRegionNames.eu_west_1, 'EU (Ireland)'],
  [objAwsRegionNames.eu_west_2, 'EU (London)'],
  [objAwsRegionNames.eu_west_3, 'EU (Paris)'],
  [objAwsRegionNames.eu_north_1, 'EU (Stockholm)'],
  [objAwsRegionNames.ap_east_1, 'Asia Pacific (Hong Kong)'],
  [objAwsRegionNames.ap_northeast_1, 'Asia Pacific (Tokyo)'],
  [objAwsRegionNames.ap_northeast_2, 'Asia Pacific (Seoul)'],
  [objAwsRegionNames.ap_northeast_3, 'Asia Pacific (Osaka-Local)'],
  [objAwsRegionNames.ap_southeast_1, 'Asia Pacific (Singapore)'],
  [objAwsRegionNames.ap_southeast_2, 'Asia Pacific (Sydney)'],
  [objAwsRegionNames.ap_southeast_3, 'Asia Pacific (Jakarta)'],
  [objAwsRegionNames.ap_southeast_4, 'Asia Pacific (Melbourne)'],
  [objAwsRegionNames.ap_south_1, 'Asia Pacific (Mumbai)'],
  [objAwsRegionNames.me_south_1, 'Middle East (Bahrain)'],
  [objAwsRegionNames.sa_east_1, 'South America (Sao Paulo)'],
  [objAwsRegionNames.il_central_1, 'Israel (Tel Aviv)'],
]);

export const AwsAutoCompleteFields = [
  AwsCommonFields.K8S_CUSTOM_TAGS,
  AwsCommonFields.BUSINESS_MAPPING,
  AwsCommonFields.K8S_LABEL_TAGS,
  AwsCommonFields.CUE_K8S_LABEL_TAGS,
  AwsCommonFields.CUSTOM_TAGS,
  AwsCommonFields.POD,
  AwsCommonFields.ACCOUNT_TAGS,
  AwsCommonFields.USAGE_TYPE,
  AwsCommonFields.RESOURCE,
  AwsCommonFields.K8S_NAMESPACE,
  AwsCommonFields.NAMESPACE,
];

// Pay atention that if this Map changed the change the same Map in the app server!
export const mapRegionDescriptionToNames = new Map([
  ['Africa (Cape Town)', objAwsRegionNames.af_south_1],
  ['Europe (Milan)', objAwsRegionNames.eu_south_1],
  ['US East (N. Virginia)', objAwsRegionNames.us_east_1],
  ['US East (Ohio)', objAwsRegionNames.us_east_2],
  ['US West (N. California)', objAwsRegionNames.us_west_1],
  ['US West (Oregon)', objAwsRegionNames.us_west_2],
  ['AWS GovCloud (US)', objAwsRegionNames.us_gov_west_1],
  ['AWS GovCloud (US-East)', objAwsRegionNames.us_gov_east_1],
  ['Canada (Central)', objAwsRegionNames.ca_central_1],
  ['China (Beijing)', objAwsRegionNames.cn_north_1],
  ['China (Ningxia)', objAwsRegionNames.cn_northwest_1],
  ['EU (Frankfurt)', objAwsRegionNames.eu_central_1],
  ['EU (Ireland)', objAwsRegionNames.eu_west_1],
  ['EU (London)', objAwsRegionNames.eu_west_2],
  ['EU (Paris)', objAwsRegionNames.eu_west_3],
  ['EU (Stockholm)', objAwsRegionNames.eu_north_1],
  ['Asia Pacific (Hong Kong)', objAwsRegionNames.ap_east_1],
  ['Asia Pacific (Tokyo)', objAwsRegionNames.ap_northeast_1],
  ['Asia Pacific (Seoul)', objAwsRegionNames.ap_northeast_2],
  ['ap-northeast-3 ()', objAwsRegionNames.ap_northeast_3],
  ['Asia Pacific (Singapore)', objAwsRegionNames.ap_southeast_1],
  ['Asia Pacific (Sydney)', objAwsRegionNames.ap_southeast_2],
  ['Asia Pacific (Jakarta)', objAwsRegionNames.ap_southeast_3],
  ['Asia Pacific (Melbourne)', objAwsRegionNames.ap_southeast_4],
  ['Asia Pacific (Mumbai)', objAwsRegionNames.ap_south_1],
  ['Middle East (Bahrain)', objAwsRegionNames.me_south_1],
  ['South America (Sao Paulo)', objAwsRegionNames.sa_east_1],
  ['Israel (Tel Aviv)', objAwsRegionNames.il_central_1],
]);

export const awsCommonFieldToDisplayField = new Map([
  [AwsCommonFields.NONE, '- None -'],
  [AwsCommonFields.USAGE_DATE, 'Date'],
  [AwsCommonFields.REGION, 'Region'],
  [AwsCommonFields.K8S_REGION, 'Region'],
  [AwsCommonFields.AVAILABILITY_ZONE, 'Availability Zone'],
  [AwsCommonFields.LINKED_ACCOUNT_NAME, 'Linked Account'],
  [AwsCommonFields.LINKED_ACCOUNT_ID, 'Linked Account'],
  [AwsCommonFields.TYPE, 'Type'],
  [AwsCommonFields.ENVIRONMENT, 'Environments'],
  [AwsCommonFields.PROJECT, 'Projects'],
  [AwsCommonFields.DEVELOPMENT, 'Development'],
  [AwsCommonFields.PRODUCTION, 'Production'],
  [AwsCommonFields.STAGING, 'Staging'],
  [AwsCommonFields.TESTING, 'Testing'],
  [AwsCommonFields.SERVICE, 'Service'],
  [AwsCommonFields.OPERATION, 'Operation'],
  [AwsCommonFields.RESOURCE, 'Resource'],
  [AwsCommonFields.INSTANCE_TYPE, 'Instance Type'],
  [AwsCommonFields.INSTANCE_FAMILY, 'Instance Family'],
  [AwsCommonFields.FAMILY_TYPE, 'Family Type'],
  [AwsCommonFields.SUB_SERVICE, 'Sub Service'],
  [AwsCommonFields.QUANTITY_TYPE, 'Quantity Type'],
  [AwsCommonFields.OS, 'Operation System'],
  [AwsCommonFields.TAG, 'Tag'],
  [AwsCommonFields.TAGS, 'Tags'],
  [AwsCommonFields.CUSTOM_TAGS, 'Tags'],
  [AwsCommonFields.CATEGORIES, 'Categories'],
  [AwsCommonFields.BUSINESS_MAPPING, 'Business Mapping'],
  [AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS, 'Business Mapping Viewpoints'],
  [AwsCommonFields.CATEGORY, 'Category'],
  [AwsCommonFields.USAGE_TYPE, 'Usage Type'],
  [AwsCommonFields.CUSTOM_TAGS_KEYS, 'Tags'],
  [AwsCommonFields.VIRTUAL_CUSTOM_TAGS, 'Virtual Tags'],
  [AwsCommonFields.K8S_TAGS, 'Labels'],
  ['cloudtags', 'Tags'],
  ['k8scustomtags', 'Labels'],
  [AwsCommonFields.ACCOUNT_TAGS, 'Enrichment Tags'],
  [AwsCommonFields.ACCOUNT_TAGS_KEYS, 'Enrichment Tags'],
  [AwsCommonFields.K8S_CUSTOM_TAGS_KEYS, 'Tags'],
  [AwsCommonFields.K8S_LABEL_TAGS, 'Labels'],
  [AwsCommonFields.CUE_K8S_LABEL_TAGS, 'K8S Labels'],
  [AwsCommonFields.VIEWS_CUSTOM_TAGS, 'Views Tags'],
  [AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS, 'Sub Views'],
  [AwsCommonFields.DIVISION, 'Cost Center'],
  [AwsCommonFields.CHARGE_TYPE, 'Charge Type'],
  [AwsCommonFields.PURCHASE_OPTION, 'Purchase Option'],
  [AwsCommonFields.POD, 'Pod'],
  [AwsCommonFields.NODE, 'Node'],
  [AwsCommonFields.NODE_GROUP, 'Node Group'],
  [AwsCommonFields.DEPLOYMENT, 'Deployment'],
  [AwsCommonFields.CLUSTER, 'Cluster'],
  [AwsCommonFields.NAMESPACE, 'Namespace'],
  [AwsCommonFields.K8S_NAMESPACE, 'K8S Namespace'],
  [AwsCommonFields.K8S_INSTANCE_TYPE, 'Instance Type'],
  [AwsCommonFields.K8S_CLUSTER, 'K8S Cluster'],
  [AwsCommonFields.K8S_NODE_GROUP, 'K8S Node Group'],
  [AwsCommonFields.PAYER_ACCOUNT, 'Payer Account'],
  [AwsCommonFields.PAYER_ACCOUNT_NAME, 'Payer Account Name'],
  [AwsCommonFields.CLOUD_PROVIDER, 'Cloud Provider'],
  [AwsCommonFields.ITEM_DESCRIPTION, 'Item Description'],
  [AwsCommonFields.COST_TYPE, 'Cost Type'],
  ['on demand', 'On Demand'],
  ['ri', 'RI'],
  ['savings_plan', 'Savings Plans'],
  ['spot', 'Spot'],
  ['SavingsPlanCoveredUsage', 'Savings Plan Covered Usage'],
  ['RIFee', 'RI Fee'],
  ['Other_out-of-cycle_charges', 'Other Out of Cycle Charges'],
  ['SavingsPlanRecurringFee', 'Savings Plan Recurring Fee'],
  ['RiVolumeDiscount', 'Ri Volume Discount'],
  ['EdpDiscount', 'EDP Discount'],
  ['SavingsPlanNegation', 'Savings Plan Negation'],
  ['AlertsAllAccounts', 'Any Linked Account'],
]);

export const AwsQuantityTypes = {
  HOURS: 'Hours',
  BYTES: 'Bytes',
  REQUESTS: 'Requests',
  OTHER: 'Other',
  QUANTITY: 'Quantity',
  MEMORY: 'Memory',
  CPU: 'CPU',
};

export const AWS_QUANTITY_TYPE_SELECT = 'Usage';
export const CUSTOM_TAGS_NOT_TAGGED = 'not_tagged';

export const AwsFamilyTypes = {
  LOAD_BALANCER: 'Load Balancer',
  STORAGE: 'Storage',
  NAT: 'NAT',
  COMPUTE: 'Compute',
};

export const serviceNameToDisplayNameWithShortName = new Map([
  [AwsServiceNames.EC2, 'Amazon Elastic Compute Cloud (EC2)'],
  [AwsServiceNames.EDP, 'EDP'],
  ['Amazon Elastic MapReduce', 'Amazon Elastic MapReduce (EMR)'],
  ['Amazon EC2 Container Registry [ECR]', 'Amazon EC2 Container Registry (ECR)'],
  ['Amazon Elastic Container Service for Kubernetes', 'Amazon Elastic Container Service for Kubernetes (EKS)'],
  ['Amazon Elastic File System', 'Amazon Elastic File System (EFS)'],
  ['Amazon NAT Gateway', 'Amazon NAT Gateway'],
  ['Amazon Elasticsearch Service', 'Amazon Elasticsearch Service'],
  ['Amazon Relational Database Service', 'Amazon Relational Database Service (RDS)'],
  ['AWS Support [Business]', 'Support [Business]'],
  ['Data Transfer', 'Data Transfer'],
  ['Amazon CloudFront', 'Amazon CloudFront'],
  ['Amazon ElastiCache', 'Amazon ElastiCache'],
  ['Amazon Elastic Container Service', 'Amazon Elastic Container Service (ECS)'],
  ['Amazon Redshift', 'Amazon Redshift'],
  ['Amazon DocumentDB', 'Amazon DocumentDB'],
  ['Amazon Neptune', 'Amazon Neptune'],
  ['Amazon Kinesis', 'Amazon Kinesis'],
  ['AWS Support [Developer]', 'Support [Dev]'],
  [AwsServiceNames.S3, `${AwsServiceNames.S3} (S3)`],
  ['AmazonCloudWatch', 'AmazonCloudWatch'],
  [AwsServiceNames.DDB, AwsServiceNames.DDB],
  [AwsServiceNames.EBS, `${AwsServiceNames.EBS} (EBS)`],
  [AwsServiceNames.VPC, AwsServiceNames.VPC],
  [AwsServiceNames.GUARD_DUTY, AwsServiceNames.GUARD_DUTY],
  ['Amazon Route 53', 'Amazon Route 53 R53'],
  ['ami', 'AMI'],
  ['asg', 'ASG'],
  ['ecs_clusters', 'ECS'],
  ['ecsClusters', 'ECS'],
  ['elastic_ip', 'Elastic-IP'],
  ['elasticIp', 'Elastic-IP'],
  ['elb', 'ELB'],
  ['elbv2', 'ELBv2'],
  ['instances', 'EC2-instance'],
  ['rds', 'RDS'],
  ['s3', 'S3'],
  ['snapshots', 'snapshots'],
  ['volumes', 'volumes'],
  ['vpc', 'VPC'],
  ['sp', 'SP'],
  ['ri', 'RI'],
]);

export const mapPurchaseOptionDbNamesToDisplay = new Map([
  ['on demand', 'On-Demand'],
  ['ri', 'Reserved'],
  ['spot', 'Spot'],
  ['savings_plan', 'Savings Plans'],
]);

export const mapServiceNameToColor = new Map([
  [AwsServiceNames.EC2, '#ff9900'],
  [AwsServiceNames.REDSHIFT, '#52a8ea'],
]);
