import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Container } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { observer } from 'mobx-react';
import { CSVLink } from 'react-csv';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import toast from 'shared/components/andtComponents/Toast';
import FilesTabel from './components/FilesTable';
import AccountTagsUploadModal from './components/AccountTagsUploadModal';

const csvExampleData = [
  ['linked_account_id', 'company', 'division', 'department', 'region'],
  ['1369484921', 'Company A', 'Technology', 'R&D', 'US'],
  ['1369484922', 'Company A', 'Sales', 'Marketing', 'EU'],
  ['1369484923', 'Company A', 'Finance', 'Budget', 'EU'],
];

class AccountTagsPage extends Component {
  static propTypes = {
    usersStore: PropTypes.object.isRequired,
    invalidateTagsFilters: PropTypes.func.isRequired,
  };
  state = {
    loading: false,
    isFileDataModalIsOpen: false,
    viewedFileName: '',
    viewedFileData: [],
    modalTableColumns: [],
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const { usersStore } = this.props;
      await usersStore.getAccountTagsMetaData();
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  onDrop = async (acceptedFiles, rejectedFiles) => {
    const { usersStore } = this.props;
    if (rejectedFiles && rejectedFiles.length) {
      toast.error('Wrong file format, please use CSV files only');
      return;
    }
    this.readFile(acceptedFiles[0], usersStore.uploadAccountTagsFile);
  };

  handleFileDelete = async (file) => {
    try {
      const { usersStore, invalidateTagsFilters } = this.props;
      this.setState({ loading: true });
      await usersStore.deleteAccountTagsFile(file.id);
      invalidateTagsFilters();
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };
  handleUpdateFile = (newData) => {
    const { usersStore } = this.props;
    usersStore.updateAccountTagsInfo(newData);
  };
  prepareDataForTable = (viewedFileData) => {
    const preparedFileDataToModalTable = [...viewedFileData];
    const columnTitle = viewedFileData.reduce((acc, curr) => {
      const { uuid, upload_date: uploadDate, uuid_linked_account_id: linkedAccountId, ...rest } = curr;
      Object.keys(rest).forEach((key) => acc.add(key));
      return acc;
    }, new Set());
    const modalTableColumns = Array.from(columnTitle).map((input) => ({ name: input, title: input }));
    return { preparedFileDataToModalTable, modalTableColumns };
  };
  viewFileDataHandler = () => {
    const { usersStore } = this.props;
    const viewedFileName = usersStore.usersModel.accountTagsFileName;
    const viewedFileData = Array.from(usersStore.usersModel.accountTagsData.values());
    if (isEmptyArray(viewedFileData)) {
      toast.error("There was an error retrieving file's data");
      return;
    }
    const { preparedFileDataToModalTable, modalTableColumns } = this.prepareDataForTable(viewedFileData);
    this.setState({
      isFileDataModalIsOpen: true,
      viewedFileName,
      viewedFileData: preparedFileDataToModalTable,
      modalTableColumns,
    });
  };
  toggleViewFileModal = () => {
    this.setState((prevProps) => ({ isFileDataModalIsOpen: !prevProps.isFileDataModalIsOpen }));
  };
  readFile = (file, handleFileUploaded) => {
    const { invalidateTagsFilters } = this.props;
    const { type, name } = file;
    const reader = new FileReader();
    try {
      reader.onabort = () => toast.error('Error reading the file');
      reader.onerror = () => toast.error('Error reading the file');
      reader.onload = async () => {
        const content = reader.result;
        this.setState({ loading: true });
        const isSuccess = await handleFileUploaded({ metaData: { type, name }, content });
        if (!isSuccess) {
          toast.error(
            'Error loading the file:\n' +
              ' verify that Linked Account Id header is in the right syntax - linked_account_id',
          );
        }
        invalidateTagsFilters();
        this.setState({ loading: false });
      };
      reader.readAsText(file);
    } catch (error) {
      toast.error('Error reading the file');
      this.setState({ loading: false });
    }
  };

  renderDragZoneOrFileTable = (files, currentUserReadOnly) => {
    if (files && files.length) {
      return (
        <FilesTabel
          uploadedFiles={files}
          currentUserReadOnly={currentUserReadOnly}
          deleteHandler={this.handleFileDelete}
          viewFileDataHandler={this.viewFileDataHandler}
        />
      );
    }
    return (
      <div>
        <CSVLink data={csvExampleData} filename="enrichment_tags_data_example.csv">
          CSV example file
          <span
            className="lnr lnr-download"
            style={{
              fontSize: '18px',
              marginLeft: '8px',
              marginBottom: '8px',
            }}
          />
        </CSVLink>
        {/* </Col> */}
        <Dropzone accept=".csv" onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div className="drop_zone" {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag & Drop a CSV file, or click to select a file</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  };

  render() {
    const { usersStore } = this.props;
    const { loading, isFileDataModalIsOpen, viewedFileData, viewedFileName, modalTableColumns } = this.state;
    return (
      <Container>
        <PageHeader title={PageNames.ACCOUNT_TAGS} showDate />
        <Card>
          <p>
            Upload a CSV file for enriched info on linked accounts in reports and Cost & Usage explorer (including
            Kubernetes).
          </p>
          <CardBody style={{ minHeight: '300px' }}>
            {loading ? (
              <Spinner />
            ) : (
              this.renderDragZoneOrFileTable(usersStore.usersModel.accountTagsFiles, usersStore.currentUserReadOnly)
            )}
            <AccountTagsUploadModal
              toggle={this.toggleViewFileModal}
              isOpen={isFileDataModalIsOpen}
              title={viewedFileName}
              data={viewedFileData}
              columns={modalTableColumns}
              handleUpdateFile={this.handleUpdateFile}
            />
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default withInvoiceFiltersContextConsumer(observer(AccountTagsPage));
