import { useQuery } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { sanitizeQuery } from 'shared/utils/apiUtil';
import { SpAnalyzerPaymentOptions } from 'usage/constants/usageConstants';
import { prepareSPAnalyzerFilters } from 'commitment/utils/savingsPlansAnalyzerUtils';

const root = '/api/v1/commitment/sp-analyzer';

const getSavingsPlansAnalyzerData = async (
  selectedOptions,
  daysToCheck,
  filterStatusTypeMap,
  selectedPaymentOption,
  selectedTerm,
  coverage,
  hourlyCommitment,
) => {
  const { includeFilterParams, excludeFilterParams } = prepareSPAnalyzerFilters(selectedOptions, filterStatusTypeMap);
  // eslint-disable-next-line max-len
  let apiQuery = `${root}?daysToCheck=${daysToCheck}&paymentOption=${selectedPaymentOption}&term=${selectedTerm}&coverage=${
    coverage / 100
  }${includeFilterParams}${excludeFilterParams}${hourlyCommitment ? `&hourlyCommitment=${hourlyCommitment}` : ''}`;
  apiQuery = sanitizeQuery(apiQuery);
  return API.get('billings', apiQuery);
};

export default function useSavingsPlansAnalyzer() {
  const queryKey = apiConstants.QUERY_KEYS.SP_ANALYZER_DATA;

  return {
    fetchSavingsPlansAnalyzerData: (
      selectedOptions,
      daysToCheck = 30,
      filterStatusTypeMap,
      selectedPaymentOption = SpAnalyzerPaymentOptions.PARTIAL_UPFRONT.tSoLowerCase(),
      selectedTerm,
      coverage,
      hourlyCommitment = '',
    ) =>
      useQuery(
        [
          queryKey,
          selectedOptions,
          daysToCheck,
          filterStatusTypeMap,
          selectedPaymentOption,
          selectedTerm,
          coverage,
          hourlyCommitment,
        ],
        () =>
          getSavingsPlansAnalyzerData(
            selectedOptions,
            daysToCheck,
            filterStatusTypeMap,
            selectedPaymentOption,
            selectedTerm,
            coverage,
            hourlyCommitment,
          ),
        { retry: false, enabled: false },
      ),
  };
}
