/* eslint-disable react/no-unused-state */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len, no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Badge, Container, Card, CardBody, Row, Col } from 'reactstrap';
import {
  Grid,
  TableHeaderRow,
  PagingPanel,
  TableColumnResizing,
  TableRowDetail,
  Toolbar,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  IntegratedFiltering,
  DataTypeProvider,
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  RowDetailState,
  SearchState,
} from '@devexpress/dx-react-grid';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import PencilIcon from 'shared/img/icons/PencilIcon';
import Delete from 'mdi-react/TrashCanOutlineIcon';
import {
  idleCriteriaRulesColumnsArray,
  idleCriteriaRulesColumnWidths,
  IdleCriteriaModalTypes,
} from 'usage/constants/usageConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import { withStyles } from '@mui/styles';
import { alpha } from '@mui/system/colorManipulator';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import TableWrapper from 'shared/components/tables/TableWrapper';
import CreateOrEditRuleModal from './CreateOrEditRuleModal';

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
});

const linkStyle = {
  display: 'inline-block',
  position: 'relative',
  zIndex: 1,
  padding: '1em',
  margin: '-1em',
};
const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

export const AddRuleButton = (props) => {
  const { handler } = props;
  return (
    <div className="d-flex justify-content-start mb-3 align-items-center">
      <div style={{ padding: '5px', marginRight: '10px' }}>
        <LinkIconButton>
          <a href="#" onClick={handler} />
          <IconFromPng size={30} icon={ICONS.plus} />
        </LinkIconButton>
      </div>
      <div style={{ fontSize: '1.2vw', padding: '5px' }}>
        <span>Create Idle Criteria Rule</span>
      </div>
    </div>
  );
};
AddRuleButton.propTypes = {
  handler: PropTypes.func.isRequired,
};

class IdleCriteriaRulesPanel extends Component {
  static propTypes = {
    usageStore: PropTypes.object.isRequired,
    invoiceStore: PropTypes.object.isRequired,
    filtersValuesMap: PropTypes.object.isRequired,
    idleInstancesModel: PropTypes.object.isRequired,
    existingCriteriasNames: PropTypes.object.isRequired,
    currentUserReadOnly: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      filteringStateColumnExtensions: [], // { columnName: 'details', filteringEnabled: false }
      isUpdatingCriteria: false,
      pageSizes: [10, 15, 20, 0],
      columnWidths: idleCriteriaRulesColumnWidths,
      isModalOpen: false,
      modalState: IdleCriteriaModalTypes.CREATE,
      criteriaIdSelectedToDeleteOrEdit: null,
      editedCriteriaId: null,
      criteriaName: '',
      maxCpu: null,
      maxNetwork: null,
      linkedAccSelectedOptionsMap: new Map([['linkedaccid', []]]),
      isDeleteAlertWarning: false,
      criteriaNameSelectedToDelete: null,
    };
    this.handleEditCriteriaClicked = this.handleEditCriteriaClicked.bind(this);
    this.baseState = {
      isUpdatingCriteria: false,
      pageSizes: [10, 15, 20, 0],
      columnWidths: idleCriteriaRulesColumnWidths,
      isModalOpen: false,
      modalState: IdleCriteriaModalTypes.CREATE,
      editedCriteriaId: null,
      criteriaName: '',
      maxCpu: null,
      maxNetwork: null,
      linkedAccSelectedOptionsMap: new Map([['linkedaccid', []]]),
      isDeleteAlertWarning: false,
      criteriaIdSelectedToDeleteOrEdit: null,
      criteriaNameSelectedToDelete: null,
    };
  }

  userActionsFormatter = (data) => {
    const { currentUserReadOnly } = this.props;

    withStyles(data);
    return (
      <i>
        <Row>
          <Col>
            <ReadOnlyDisplayWrapper isHide={false} userReadOnly={currentUserReadOnly}>
              <LinkIconButton containerStyle={buttonContainerStyle}>
                <a
                  href="#"
                  // eslint-disable-next-line react/no-unknown-property
                  linkStyle={linkStyle}
                  onClick={(e) => this.handleDeleteCrietriaClicked(data.row.criteriaId, data.row.criteriaName)}
                />
                <Delete />
              </LinkIconButton>
            </ReadOnlyDisplayWrapper>
          </Col>
          <Col>
            <LinkIconButton containerStyle={buttonContainerStyle}>
              <a
                href="#"
                // eslint-disable-next-line react/no-unknown-property
                linkStyle={linkStyle}
                onClick={(e) => this.handleEditCriteriaClicked(data.row)}
              />
              <PencilIcon className="anomaly-rule-svg-pencil-icon svg-pencil-icon" />
            </LinkIconButton>
          </Col>
        </Row>
      </i>
    );
  };

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };
  toggleModal = () => {
    const { isModalOpen } = this.state;
    this.setState({ isModalOpen: !isModalOpen });
  };
  prepareCurrentEditedCriteriaParamsToState = (criteriaData) => {
    const { filtersValuesMap, usageStore } = this.props;
    const { criteriaName, maxCpu, maxNetwork, linkedAccounts } = criteriaData;
    const preparedLinkedAccountsOptions = usageStore.getPreparedLinkedAccountsOptions(
      linkedAccounts,
      filtersValuesMap.get('linkedaccid'),
    );
    const linkedAccSelectedOptionsMap = new Map();
    linkedAccSelectedOptionsMap.set('linkedaccid', preparedLinkedAccountsOptions);
    return {
      criteriaName,
      maxCpu,
      maxNetwork,
      linkedAccSelectedOptionsMap,
    };
  };
  handleEditCriteriaClicked = (criteriaData) => {
    const currentEditedCriteriaParams = this.prepareCurrentEditedCriteriaParamsToState(criteriaData);
    this.setState({
      criteriaIdSelectedToDeleteOrEdit: criteriaData.criteriaId,
      isModalOpen: true,
      modalState: IdleCriteriaModalTypes.EDIT,
      ...currentEditedCriteriaParams,
    });
  };

  handleOpenAddRuleModalClick = () => {
    this.setState({ isModalOpen: true, modalState: IdleCriteriaModalTypes.CREATE });
  };

  handleCreateNewCriteriaRule = async () => {
    const { criteriaName, maxCpu, maxNetwork, linkedAccSelectedOptionsMap } = this.state;
    try {
      this.setState({ isUpdatingCriteria: true, isModalOpen: false });
      const params = {
        criteriaName,
        maxCpu: +maxCpu,
        maxNetwork: +maxNetwork,
        linkedAccounts: linkedAccSelectedOptionsMap.get('linkedaccid').map(({ value }) => value),
      };
      await this.props.usageStore.createIdleCriteriaRule(params);
      this.setState({ ...this.baseState });
    } catch (error) {
      this.setState({ ...this.baseState });
    }
  };
  handleDeleteCrietriaClicked = (id, name) => {
    this.setState({
      isDeleteAlertWarning: true,
      criteriaIdSelectedToDeleteOrEdit: id,
      criteriaNameSelectedToDelete: name,
    });
  };
  deleteAlertHandler = async (action) => {
    const { criteriaIdSelectedToDeleteOrEdit } = this.state;
    if (action === 'cancel') {
      this.setState({
        isDeleteAlertWarning: false,
        criteriaIdSelectedToDeleteOrEdit: null,
        criteriaNameSelectedToDelete: null,
      });
    } else if (action === 'delete') {
      try {
        const id = criteriaIdSelectedToDeleteOrEdit;
        this.setState({ isUpdatingCriteria: true, isDeleteAlertWarning: false });
        await this.props.usageStore.deleteIdleCriteria(id);
        this.setState({ ...this.baseState });
      } catch (error) {
        this.setState({ ...this.baseState });
      }
    }
  };
  handleUpdateCriteriaRule = async () => {
    const { criteriaName, maxCpu, maxNetwork, linkedAccSelectedOptionsMap, criteriaIdSelectedToDeleteOrEdit } =
      this.state;
    try {
      this.setState({ isUpdatingCriteria: true, isModalOpen: false });
      const params = {
        criteriaName,
        maxCpu: +maxCpu,
        maxNetwork: +maxNetwork,
        linkedAccounts: linkedAccSelectedOptionsMap.get('linkedaccid').map(({ value }) => value),
        criteriaId: criteriaIdSelectedToDeleteOrEdit,
      };
      await this.props.usageStore.updateIdleCriteriaRule(params);
      this.setState({ ...this.baseState });
    } catch (error) {
      this.setState({ ...this.baseState });
    }
  };

  handleInputFieldChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  selectValueHandler = (filterType, selectedOptions) => {
    const { linkedAccSelectedOptionsMap } = this.state;
    const selectedOptionsMap = new Map(linkedAccSelectedOptionsMap);
    selectedOptionsMap.set(filterType, selectedOptions);
    this.setState({ linkedAccSelectedOptionsMap: selectedOptionsMap });
  };
  ruleHandlers = {
    handleInputFieldChange: this.handleInputFieldChange,
    handleLinkedAccChange: this.selectValueHandler,
    handleCreateNewRule: this.handleCreateNewCriteriaRule,
    handleUpdateRule: this.handleUpdateCriteriaRule,
  };
  rowDetail = ({ row }) => {
    const { filtersValuesMap, usageStore } = this.props;
    const preparedLinkedAccountsOptions = usageStore.getPreparedLinkedAccountsOptions(
      row.linkedAccounts,
      filtersValuesMap.get('linkedaccid'),
    );
    return (
      <div style={{ padding: '10px' }}>
        <ul style={{ display: 'flex', flexDirection: 'row', 'list-style-type': 'none' }}>
          {preparedLinkedAccountsOptions.map((la) => (
            <li style={{ padding: '2px' }}>
              <Badge color="light" pill style={{ border: 'solid 1px #70bbfd', background: '#fff' }}>
                <div style={{ padding: '2px', color: '#70bbfd' }}>
                  <span>{la.label}</span>
                </div>
              </Badge>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  renderTableOrNoCriteriaMessgae = (idleInstancesCriterias) => {
    if (!idleInstancesCriterias || !idleInstancesCriterias.length) {
      return (
        <div className="criteria-form-card description-container">
          <h5 className="idle-instances-description">
            In order to present the Idle Instances, please set a criteria first. The criteria contains three parameters
            - Max CPU Utilization Limit Percent, Max Network In & Out Limit and Linked Accounts. Once all parameters are
            selected and submitted, Instance monitoring will be available from the next data processing.
          </h5>
        </div>
      );
    }
    return this.renderTable(idleInstancesCriterias);
  };

  renderTable = (idleInstancesCriterias) => {
    const { columnWidths, pageSizes } = this.state;
    return (
      <Grid rows={idleInstancesCriterias} columns={idleCriteriaRulesColumnsArray}>
        {/* <FilteringState defaultFilters={[]} columnExtensions={this.state.filteringStateColumnExtensions} /> */}
        <SortingState defaultSorting={[{ columnName: 'usageDate', direction: 'desc' }]} />
        <PagingState defaultCurrentPage={0} />
        <SearchState />
        <RowDetailState />
        <IntegratedFiltering />
        <IntegratedSorting />
        <IntegratedPaging />

        <DataTypeProvider for={['userAction']} formatterComponent={this.userActionsFormatter} />

        <TableWrapper styles={styles} />
        <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
        <TableHeaderRow showSortingControls />
        <TableRowDetail contentComponent={this.rowDetail} />
        <PagingPanel pageSizes={pageSizes} />
        {/* <TableFilterRow /> */}
        <Toolbar />
        <SearchPanel />
      </Grid>
    );
  };
  render() {
    const {
      isUpdatingCriteria,
      modalState,
      criteriaName,
      maxCpu,
      maxNetwork,
      linkedAccSelectedOptionsMap,
      isModalOpen,
      criteriaNameSelectedToDelete,
      isDeleteAlertWarning,
    } = this.state;
    const { existingCriteriasNames, usageStore, invoiceStore } = this.props;
    if (isUpdatingCriteria) {
      return (
        <Container>
          <Card>
            <CardBody>
              <Spinner />
            </CardBody>
          </Card>
        </Container>
      );
    }
    const { idleInstancesCriterias } = usageStore.idleInstancesModel;
    return (
      <Container>
        <Card>
          <CardBody>
            <Row>
              <AddRuleButton handler={this.handleOpenAddRuleModalClick} />
            </Row>
            {this.renderTableOrNoCriteriaMessgae(idleInstancesCriterias)}
          </CardBody>
        </Card>
        <CreateOrEditRuleModal
          invoiceStore={invoiceStore}
          isOpen={isModalOpen}
          toggle={this.toggleModal}
          modalState={modalState}
          ruleHandlers={this.ruleHandlers}
          criteriaName={criteriaName}
          maxCpu={maxCpu}
          maxNetwork={maxNetwork}
          linkedAccSelectedOptionsMap={linkedAccSelectedOptionsMap}
          existingCriteriasNames={existingCriteriasNames}
        />
        <DeleteWarningModal
          deletedItemName={criteriaNameSelectedToDelete || ''}
          isOpen={isDeleteAlertWarning}
          handleDelete={this.deleteAlertHandler}
          warningMessage="Be advise you are about to delete a"
          modalTitle="Delete Criteria"
        />
      </Container>
    );
  }
}
const ObserverIdleCriteriaRulesPanel = withInvoiceFiltersContextConsumer(observer(IdleCriteriaRulesPanel));
export default ObserverIdleCriteriaRulesPanel;
