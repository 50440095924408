import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'reactstrap';
import IconInputField from 'shared/components/IconInputField';
import IconSelectField from 'shared/components/IconSelectField';
import { mapRegionNamesToDescription, objAwsRegionNames } from 'shared/constants/awsConstants';

const InputFiledWithSideActionButton = (props) => {
  const { containerClassName, children, externalId = 'asd' } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 20px)', marginBottom: '20px' }}>
      <div className={containerClassName}>
        {children}
        <a
          style={{ whiteSpace: 'nowrap', marginLeft: '10px' }}
          className="find-my-id"
          rel="noopener noreferrer"
          target="_blank"
          href="https://console.aws.amazon.com/billing/home?#/account"
        >
          Find My ID
        </a>
      </div>
      {externalId && (
        <div>
          <span>Please note that the account external id is: </span>
          <span onClick={() => navigator.clipboard.writeText(externalId)}>{externalId}</span>
        </div>
      )}
    </div>
  );
};

InputFiledWithSideActionButton.propTypes = {
  children: PropTypes.object.isRequired,
  containerClassName: PropTypes.object,
  externalId: PropTypes.string.isRequired,
};
InputFiledWithSideActionButton.defaultProps = {
  containerClassName: '',
};

const nameValidation = {
  marginTop: '5px',
  color: 'red',
  fontSize: '10px',
};
const accValidation = {
  color: 'red',
  fontSize: '10px',
};

class InsertInvoiceBucketName extends PureComponent {
  static propTypes = {
    invoiceBucketName: PropTypes.string.isRequired,
    handleInvoiceBucketNameChange: PropTypes.func.isRequired,
    handleAwsAccountIDChange: PropTypes.func.isRequired,
    awsAccountName: PropTypes.string.isRequired,
    handleAwsAccountNameChange: PropTypes.func.isRequired,
    bucketRegionName: PropTypes.string.isRequired,
    handleBucketRegionNameChange: PropTypes.func.isRequired,
    disableEnterKeySub: PropTypes.func.isRequired,
    isAccountIdValid: PropTypes.bool.isRequired,
    accountIdErrorMsg: PropTypes.string.isRequired,
    externalId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      nameIsValid: true,
    };
  }

  accountNameValidation = (name) => {
    const reg = /[^A-Za-z0-9 ]/;
    if (reg.test(name)) {
      this.setState({ nameIsValid: false });
    } else {
      this.setState({ nameIsValid: true });
      this.props.handleAwsAccountNameChange(name || '');
    }
  };

  render() {
    const {
      invoiceBucketName,
      handleInvoiceBucketNameChange,
      disableEnterKeySub,
      handleAwsAccountIDChange,
      handleBucketRegionNameChange,
      bucketRegionName,
      awsAccountName,
      accountIdErrorMsg,
      isAccountIdValid,
      externalId,
    } = this.props;
    const { nameIsValid } = this.state;
    const arrValues = Object.values(objAwsRegionNames);
    return (
      <Row>
        <Col xs={6} md={6} lg={6} xl={6}>
          <h4 className="inner-wizard-items-header" style={{ 'padding-bottom': '20px' }}>
            <span>Complete the following fields:</span>
          </h4>
          <br />
          <Form onKeyPress={disableEnterKeySub} className="form">
            <div className="form__form-group-trim">
              <IconInputField
                icon="lnr lnr-database"
                handler={handleInvoiceBucketNameChange}
                label="Invoice Bucket Name"
                name="invoiceBucketName"
                value={invoiceBucketName}
                type="text"
                placeHolder="Enter your Invoice Bucket Name"
              />
            </div>
            <div className="form__form-group-trim">
              <IconSelectField
                icon="lnr lnr-earth"
                handler={handleBucketRegionNameChange}
                label="Bucket Region name"
                name="regionName"
                value={bucketRegionName}
                placeHolder="Select your Bucket region name"
                arrValues={arrValues}
                mapRegionNamesToDescription={mapRegionNamesToDescription}
              />
            </div>
            <InputFiledWithSideActionButton containerClassName="with-Action-button-wrapper" externalId={externalId}>
              <div className="form__form-group-trim">
                <IconInputField
                  icon="lnr lnr-cloud"
                  handler={handleAwsAccountIDChange}
                  label="AWS Root Account ID"
                  name="awsAccountID"
                  type="text"
                  placeHolder="Enter your AWS Root account ID"
                />
              </div>
            </InputFiledWithSideActionButton>
            {!isAccountIdValid && <div style={accValidation}>{accountIdErrorMsg}</div>}
            <div className="form__form-group-trim">
              <IconInputField
                icon="lnr lnr-cloud"
                handler={this.accountNameValidation}
                label="Display Account Name"
                name="awsAccountName"
                value={awsAccountName}
                type="text"
                placeHolder="Enter a display name for your account"
                // pattern="[a-zA-Z0-9 ]+"
              />
              {!nameIsValid && <div style={nameValidation}>Please use alphanumeric values only</div>}
            </div>
          </Form>
        </Col>
      </Row>
    );
  }
}

export default InsertInvoiceBucketName;
