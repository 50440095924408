import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line max-len
import SelectedFilterDisplay from 'recommendationsNew/components/heatMap/heatMapFilters/displayedSelectedFilters/selectedFilterDisplay';
import { FILTERS } from 'recommendationsNew/consts';
import RecommendationsNewContext from 'recommendationsNew/contexts/recommendationsNewContext';

import classes from './heatMapSelectedFiltersDisplay.module.scss';

const HeatMapSelectedFiltersDisplay = ({ cloudType, unsetCurrentFilter }) => {
  const { recommendationFilters: filtersContext, setRecommendationFilters: setFiltersContext } =
    useContext(RecommendationsNewContext);

  const selectedFiltersKeys = Object.keys(filtersContext);

  const removeSelectedFilter = ({ filter, userStatusFilter = null, isDropDown = false }) => {
    const newFilters = { ...filtersContext };
    if (filter === FILTERS.USER_STATUS.id) {
      newFilters[filter][userStatusFilter] = null;
    } else if (isDropDown) {
      delete newFilters[filter];
    } else {
      newFilters[filter] = null;
    }
    setFiltersContext(newFilters);
    unsetCurrentFilter();
  };

  return selectedFiltersKeys?.length ? (
    <div className={classes.selectedFiltersDisplay}>
      {selectedFiltersKeys?.map((filter) => (
        <SelectedFilterDisplay
          selectedFilter={filter}
          selectedFilterValue={filtersContext[filter]}
          cloudType={cloudType}
          removeSelectedFilter={removeSelectedFilter}
        />
      ))}
    </div>
  ) : (
    <div />
  );
};

HeatMapSelectedFiltersDisplay.propTypes = {
  cloudType: PropTypes.number.isRequired,
  unsetCurrentFilter: PropTypes.func.isRequired,
};

export default HeatMapSelectedFiltersDisplay;
