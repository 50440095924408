import HistoryTableCellClass from './historyTableCellClass';

export default class HistoryTableIDCell extends HistoryTableCellClass {
  constructor(cell, id) {
    super(cell);
    this.parsedValue = this.parseValue(id);
    this.parsedCompareValue = this.parseValue(id);
  }

  get getParsedValue() {
    return this.parsedValue;
  }

  get getParsedCompareValue() {
    return this.parsedCompareValue;
  }

  parseValue = (v) => {
    const val = parseInt(v, 0);
    return val || 0;
  };
}
