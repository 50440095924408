import React from 'react';
import Button from 'shared/components/andtComponents/Button';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import useSlack from 'shared/components/slackIntegration/hooks/useSlack';

// eslint-disable-next-line react/prop-types
const AddSlackButton = ({ isDisabled }) => {
  const { getSlackInstallUrl } = useSlack();
  const { data: slack } = getSlackInstallUrl();

  const runGoToSlack = async () => {
    window.location.href = slack.INSTALL_URL;
  };

  return (
    <Button
      text="Add Workspace"
      onClick={runGoToSlack}
      icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
      iconSize={24}
      disabled={isDisabled}
    />
  );
};

export default AddSlackButton;
