import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import {
  transformFiltersContextToApiFormat,
  transformSelectDataToClientFormat,
  transformSingleOptionToApiFormat,
} from 'recommendationsNew/hooks/react-query/apiUtils';
import {
  BILLINGS_API_NAME,
  RECOMMENDATIONS_HEATMAP_ROOT,
  SORT_BY_OPTIONS,
  STATLE_TIME,
} from 'recommendationsNew/consts';

async function fetchHeatMapGroup({ groupBy, filters, sortBy, lastItemOfPrevPage, pageSize = 50 }) {
  const transformedFilters = transformFiltersContextToApiFormat(filters);
  const data = await API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_HEATMAP_ROOT}/group`, {
    body: {
      group_by: groupBy,
      filters: transformedFilters || {},
      sort_by: sortBy || SORT_BY_OPTIONS.items[0].property,
      tag_name: null,
      last_item_of_prev_page: lastItemOfPrevPage ? transformSingleOptionToApiFormat(lastItemOfPrevPage) : null,
      page_size: pageSize,
    },
  });
  // Transform data to match the format of react-select
  const pageData = transformSelectDataToClientFormat(data?.page);
  return data?.page ? { ...data, page: pageData } : data;
}

export default function useGroupByOption(isEnabled) {
  const queryKey = [apiConstants.QUERY_KEYS.RECOMMENDATIONS_GROUP_BY_OPTION];

  return {
    invalidate: ({ groupBy, filters, sortBy }) =>
      queryClient.invalidateQueries([...queryKey, groupBy, filters, sortBy] || queryKey),
    reset: ({ groupBy, filters, sortBy }) =>
      queryClient.resetQueries([...queryKey, groupBy, filters, sortBy] || queryKey),
    fetchGroupByOption: ({ groupBy, filters, sortBy, lastItemOfPrevPage, pageSize }) =>
      useQuery(
        [...queryKey, groupBy, filters, sortBy, lastItemOfPrevPage, pageSize],
        () => fetchHeatMapGroup({ groupBy, filters, sortBy, lastItemOfPrevPage, pageSize }),
        {
          retry: false,
          staleTime: STATLE_TIME,
          enabled: isEnabled,
        },
      ),
  };
}
