/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import FieldFilter from 'shared/components/FieldFilter';
import { propComparator } from 'shared/utils/sortUtil';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';

const MultiTypeFieldFilterSelector = (props) => {
  const {
    fixedValues,
    filtersValuesMap,
    addValues,
    type,
    valueFunc,
    labelFunc,
    addAllOption,
    name,
    handler,
    placeHolder,
    singleSelect,
    selectedValues,
  } = props;
  const getPreparedValue = (value, func) => {
    if (func && value) {
      return func(value);
    }
    return value;
  };

  const createOptions = () => {
    const values = fixedValues || [...filtersValuesMap.get(type)].filter(Boolean);
    if (addValues && values) {
      addValues.forEach((value) => {
        if (values && !values.includes(value)) {
          values.push(value);
        }
      });
    }
    const options = !values
      ? []
      : values.map((fieldValue) => {
          const value = getPreparedValue(fieldValue, valueFunc); // fieldValue[props.valueParam] || fieldValue;
          const label = getPreparedValue(fieldValue, labelFunc); // fieldValue[props.labelParam] in case values are objects
          return { value, label };
        });
    if (options.length) {
      options.sort(propComparator('label'));
    }
    if (addAllOption && !fixedValues) {
      options.unshift({ value: 'all', label: addAllOption });
    }
    return options;
  };
  return (
    <div>
      <FieldFilter
        type={type}
        name={name}
        singleSelect={singleSelect}
        value={selectedValues}
        options={createOptions()}
        handleChange={handler}
        placeHolder={placeHolder}
      />
    </div>
  );
};

MultiTypeFieldFilterSelector.propTypes = {
  selectedValues: PropTypes.object.isRequired,
  singleSelect: PropTypes.bool,
  filtersValuesMap: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  labelFunc: PropTypes.func,
  valueFunc: PropTypes.func,
  addValues: PropTypes.object,
  fixedValues: PropTypes.object,
  addAllOption: PropTypes.bool,
};
MultiTypeFieldFilterSelector.defaultProps = {
  singleSelect: false,
  addAllOption: false,
  labelFunc: null,
  valueFunc: null,
  addValues: [],
  fixedValues: null,
};

export default withInvoiceFiltersContextConsumer(MultiTypeFieldFilterSelector);
