import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  Table,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  RowDetailState,
  SortingState,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid';
import { Progress, GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import useTable from 'shared/hooks/customHooks/useTable';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import styles from 'shared/components/slackIntegration/slackIntegration.module.scss';
import Modal from 'shared/components/andtComponents/Modal';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import PropTypes from 'prop-types';
import { useTableHeaderContext } from 'shared/components/tableHeader/TableHeaderContext';
import {
  getBudgetAmount,
  getBudgetColor,
  getCloudProviderForBudget,
  granularityMethodOptions,
  methodOptions,
} from 'usage/containers/BudgetNew/budgetUtil';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { ReactComponent as NoBudget } from 'shared/img/icons/no-budget.svg';
import { ReactComponent as NoResultData } from 'shared/img/icons/no_result_data.svg';
import { cloudTypeToIcon } from 'shared/constants/appConstants';
import AlertedColumn from './AlertColumn';
import classes from './budgets.module.scss';
import BudgetActions from './BudgetActions';
import BudgetDetails from './BudgetDetails';
import BudgetModal from './budgetModal/BudgetModal';

const budgetColumnsWidths = [
  { columnName: 'budgetName', width: '180' },
  { columnName: 'cloudProvider', width: '120' },
  { columnName: 'costType', width: '110' },
  { columnName: 'current', width: '195' },
  { columnName: 'budgeted', width: '125' },
  { columnName: 'budgetForecast', width: '130' },
  { columnName: 'budgetStatus', width: '170' },
  { columnName: 'forecastedStatus', width: '170' },
  { columnName: 'totalCost', width: '160' },
  { columnName: 'exceeded', width: '135' },
  { columnName: 'budgetLeft', width: '140' },
  { columnName: 'budgetType', width: '125' },
  { columnName: 'alert', width: '65' },
  { columnName: 'userActions', width: '80' },
];

const getBudgetTableColumn = (isMultiCloud) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  return [
    { name: 'budgetName', title: 'Budget Name' },
    { name: 'cloudProvider', title: 'Cloud Provider' },
    {
      name: 'costType',
      title: 'AWS Cost',
      getCellValue: (row) => {
        if (isMultiCloud && !getCloudProviderForBudget(row).includes('AWS')) {
          return '';
        }
        // add space before capital letters to separate word for example : 'costType' to 'Cost Type'
        const str = row.costType ? row.costType.replace(/([A-Z])/g, ' $1').trim() : '';
        // separate cost type that come with '_' to 2 words that start with capital letter
        // for example : 'Cost_Type' to 'Cost Type'
        let str1 = str.split('_');
        str1 = str1.map((s) => s.charAt(0).toUpperCase() + s.slice(1));
        return str1.join(' ');
      },
    },
    {
      name: 'current',
      title: 'Accumulated Current Spend',
      getCellValue: (row) => getCurrencyNumber(Math.round(row.totalCost)),
    },
    {
      name: 'budgeted',
      title: 'Budget Amount',
      getCellValue: (row) => getCurrencyNumber(getBudgetAmount(row)),
    },
    {
      name: 'budgetForecast',
      title: 'Forecasted Spend',
      getCellValue: (row) => getCurrencyNumber(Math.round(row.totalForcasted)),
    },
    {
      name: 'budgetStatus',
      title: 'Current vs. Budgeted',
      getCellValue: (row) => `${Math.round((row.totalCost / getBudgetAmount(row)) * 100)}%`,
    },
    {
      name: 'forecastedStatus',
      title: 'Forecasted vs. Budgeted',
      getCellValue: (row) => `${Math.round((row.totalForcasted / getBudgetAmount(row)) * 100)}%`,
    },
    {
      name: 'exceeded',
      title: 'Exceeded Budget',
      getCellValue: (row) => {
        const budgetAmount = getBudgetAmount(row);
        return budgetAmount - row.totalCost < 0 ? getCurrencyNumber(Math.abs(budgetAmount - row.totalCost)) : '-';
      },
    },
    {
      name: 'budgetLeft',
      title: 'Remaining Budget',
      getCellValue: (row) => {
        const budgetAmount = getBudgetAmount(row);
        return budgetAmount - row.totalCost >= 0 ? getCurrencyNumber(budgetAmount - row.totalCost) : '-';
      },
    },
    {
      name: 'budgetType',
      title: 'Budget Type',
      getCellValue: (row) => {
        switch (row.budgetType) {
          case 'recurring':
            return 'Fixed Monthly';
          case 'expiring':
            return 'Planned Monthly';
          case 'expiringFixed':
            return 'Fixed Period';
          default:
            return row.budgetType;
        }
      },
    },
    { name: 'alert', title: 'Alert' },
    { name: 'userActions', title: 'Actions' },
  ];
};

const budgetStatusFormatter = (row, expandedRowIds, rowIndex) => {
  const value = Math.round((row.totalCost / getBudgetAmount(row)) * 100);
  const isExpand = expandedRowIds?.includes(rowIndex);
  const overrideClasses = {
    root: isExpand ? classes.expandProgressRoot : classes.root,
    indicator: classes.indicator,
  };
  return (
    <div className={classes.tdWrapper}>
      <div className="text-center">{`${value}%`}</div>
      <Progress classes={overrideClasses} color={getBudgetColor(value)} value={value} />
    </div>
  );
};

const budgetForecastedFormatter = (row, expandedRowIds, rowIndex) => {
  const value = Math.round((row.totalForcasted / getBudgetAmount(row)) * 100);
  const isExpand = expandedRowIds?.includes(rowIndex);
  const overrideClasses = {
    root: isExpand ? classes.expandProgressRoot : classes.root,
    indicator: classes.indicator,
  };

  return (
    <div className={`${classes.tdWrapper}`}>
      <div className="text-center">{`${value}%`}</div>
      <Progress classes={overrideClasses} color={getBudgetColor(value)} value={value} />
    </div>
  );
};

const budgetNameColumn = ({ row }) => {
  if (row.isExpired) {
    return (
      <div className={classes.expiredColumn}>
        {' '}
        <GenerateIcon iconName={ICONS.calendarExclamation.name} />
        <span>{row?.budgetName}</span>
      </div>
    );
  }
  return <strong>{row?.budgetName}</strong>;
};

const getSelectedOption = (options, type) => {
  const typeSelected = options.find((o) => o.value === type);
  return typeSelected || type;
};

const mapAlertForEdit = (alerts) => {
  const budgetAlerts = [];

  const getAlertGranularity = (alertGranularity) => {
    const granularity = alertGranularity?.map((g) => getSelectedOption(granularityMethodOptions, g));
    return granularity?.length ? granularity : [];
  };
  alerts?.forEach((b) => {
    const bAlert = {
      alertGranularity: getAlertGranularity(b.alertGranularity),
      alertPercent: b.budgetPercentToAlertFrom,
      alertEmail: b.recipients?.join(','),
      whenToAlert: b.whenToAlert,
    };
    budgetAlerts.push(bAlert);
  });
  return budgetAlerts;
};

const cloudIcon = (cloudType) => {
  const CloudTypeIcon = cloudTypeToIcon[CLOUD_TYPE_IDS[cloudType]];
  if (CloudTypeIcon) {
    return (
      <Tooltip title={`${cloudType}`}>
        <span>
          <CloudTypeIcon />
        </span>
      </Tooltip>
    );
  }
  return '';
};
const cloudTypesFormatter = (data) => getCloudProviderForBudget(data.row).map((cp) => cloudIcon(cp));
const BudgetTable = ({
  rows,
  deleteRow,
  totalRows,
  editRow,
  duplicateRow,
  fieldToFieldDistincValuesMap,
  isEditMode,
  setIsEditMode,
  isLoading,
  allRowCount,
  budgetsNames,
}) => {
  const { NewTableWrapper, NewTableRow, NewTableRowDetail } = useTable();
  const { isExpanded } = useTableHeaderContext();
  const { usersStore } = useRootStore();
  const [showDeleteMsg, setShowDeleteMsg] = useState(false);
  const [currentRow, setCurrentRow] = useState(false);
  const [editBudget, setEditBudget] = useState({});
  const [duplicateBudget, setDuplicateBudget] = useState({});
  const [sorting, setSorting] = useState([{ columnName: 'budgeName', direction: 'asc' }]);

  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const csvData = rows.map((row) => {
    const formattedRow = {};
    const csvColumns = getBudgetTableColumn(usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.MULTI).filter(
      (c) => !['alert', 'userActions'].includes(c.name),
    );
    const filtersKeys = [...Object.keys(row.filters.include), ...Object.keys(row.filters.exclude)];
    filtersKeys.forEach((fk) => {
      csvColumns.push({
        name: fk,
        title: fk,
        getCellValue: (row, col) => {
          if (row.filters.include[col]) {
            return `include: ${row.filters.include[col].join(', ')}`;
          }
          return `exclude: ${row.filters.exclude[col].join(', ')}`;
        },
      });
    });
    const providerColumn = csvColumns.find((c) => c.name === 'cloudProvider');
    if (providerColumn) {
      providerColumn.getCellValue = (row) => getCloudProviderForBudget(row).join(' | ');
    }
    csvColumns.forEach((col) => {
      formattedRow[col.title] = col.getCellValue ? col.getCellValue(row, col.name) : row[col.name];
    });
    return formattedRow;
  });

  useEffect(() => {
    if (!isExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(rows.map((row, index) => index));
    }
  }, [isExpanded]);

  const editRowHandler = (row) => {
    const editBudget = {
      ...row,
      budgetAlerts: mapAlertForEdit(row?.budgetAlerts),
      budgetType: getSelectedOption(methodOptions, row?.budgetType),
    };
    setEditBudget(editBudget);
    setDuplicateBudget(null);
    setIsEditMode(true);
  };
  const duplicateRowHandler = (row) => {
    const duplicateBudget = {
      ...row,
      budgetName: `Copy of ${row.budgetName}`,
      startDate: new Date(row.startDate) < new Date() ? new Date() : row.startDate,
      budgetAlerts: mapAlertForEdit(row?.budgetAlerts),
      budgetType: getSelectedOption(methodOptions, row?.budgetType),
    };
    setDuplicateBudget(duplicateBudget);
    setEditBudget(null);
    setIsEditMode(true);
  };
  const RowDetail = ({ row }) => (
    <NewTableRowDetail row={row}>
      <div className={classes.rowDetailsWrapper}>
        <BudgetDetails budget={row} />
      </div>
    </NewTableRowDetail>
  );
  const renderTableHeaderRow = ({ row, ...restProps }) => (
    <>
      <Table.Row {...restProps} />
      <tr className={classes.lineSpacer} />
    </>
  );
  const comparePercentNumber = (firstNum, secondNum) => {
    const numA = Number(firstNum.split('%')[0]);
    const numB = Number(secondNum.split('%')[0]);
    if (numA === numB) {
      return 0;
    }
    return numA < numB ? -1 : 1;
  };
  const compareFormatterNumber = (firstNum, secondNum) => {
    const valA = firstNum.split(' ').length > 1 ? firstNum.split(' ')[1] : firstNum.split(' ')[0];
    const valB = secondNum.split(' ').length > 1 ? secondNum.split(' ')[1] : secondNum.split(' ')[0];
    const numA = valA === '-' ? 0 : Number(valA.replace(/,/g, ''));
    const numB = valB === '-' ? 0 : Number(valB.replace(/,/g, ''));
    if (numA === numB) {
      return 0;
    }
    return numA < numB ? -1 : 1;
  };

  const sortedColumnExtensions = [
    { columnName: 'current', compare: compareFormatterNumber },
    { columnName: 'budgeted', compare: compareFormatterNumber },
    { columnName: 'budgetForecast', compare: compareFormatterNumber },
    { columnName: 'budgetStatus', compare: comparePercentNumber },
    { columnName: 'forecastedStatus', compare: comparePercentNumber },
    { columnName: 'exceeded', compare: compareFormatterNumber },
    { columnName: 'budgetLeft', compare: compareFormatterNumber },
  ];

  const hiddenColumn = useMemo(() => {
    if (usersStore.currDispUserCloudAccountType !== CLOUD_TYPE_IDS.MULTI) {
      return usersStore.currDispUserCloudAccountType !== CLOUD_TYPE_IDS.AWS
        ? ['cloudProvider', 'costType']
        : ['cloudProvider'];
    }
    return ['alert'];
  }, [usersStore.currDispUserCloudAccountType]);
  return (
    <div>
      <TableHeader
        tableName="Budget"
        cloudType={usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.MULTI ? 'Multi-Cloud' : ''}
        includeDetails
        filteredRows={rows.length}
        totalRows={totalRows}
        showActiveFilter
        disableCreateOption={usersStore.currentUserReadOnly}
        csvData={{ data: csvData, filename: 'budgets.csv' }}
      />
      {!rows?.length ? (
        <div className={classes.noDataWrapper}>
          {!allRowCount ? <NoBudget /> : <NoResultData />}
          <span className={classes.mainText}>
            {' '}
            {!allRowCount ? 'There are currently no budgets defined' : 'There are no budgets found'}
          </span>
          <span className={classes.subText}>
            {!allRowCount
              ? 'Create your first budget to start improving your cost monitoring'
              : 'Change your search filter to present data'}
          </span>
        </div>
      ) : (
        <NewTableWrapper>
          <Grid
            rows={rows}
            columns={getBudgetTableColumn(usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.MULTI)}
          >
            <SortingState
              sorting={sorting}
              onSortingChange={(sortingColumAndDirection) => setSorting(sortingColumAndDirection)}
            />
            <IntegratedSorting columnExtensions={sortedColumnExtensions} />
            <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
            <DataTypeProvider for={['budgetName']} formatterComponent={budgetNameColumn} />
            <DataTypeProvider for={['cloudProvider']} formatterComponent={cloudTypesFormatter} />
            <DataTypeProvider
              for={['budgetStatus']}
              formatterComponent={(data) => budgetStatusFormatter(data.row, expandedRowIds, rows.indexOf(data.row))}
            />
            <DataTypeProvider
              for={['forecastedStatus']}
              formatterComponent={(data) => budgetForecastedFormatter(data.row, expandedRowIds, rows.indexOf(data.row))}
            />
            <DataTypeProvider
              for={['alert']}
              formatterComponent={(data) => (
                <AlertedColumn
                  value={data.row.alerts}
                  budgetSpend={(data.row.totalCost / getBudgetAmount(data.row)) * 100}
                />
              )}
            />
            <DataTypeProvider
              for={['userActions']}
              formatterComponent={(props) => (
                <BudgetActions
                  {...props}
                  onEdit={(row) => editRowHandler(row)}
                  onDelete={(row) => {
                    setCurrentRow(row);
                    setShowDeleteMsg(true);
                  }}
                  fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
                  onDuplicate={(row) => {
                    duplicateRowHandler(row);
                  }}
                />
              )}
            />
            <PagingState defaultCurrentPage={0} pageSize={200} />
            <IntegratedPaging />
            <TableWrapper
              virtual
              rowComponent={(props) => (
                <NewTableRow expandedRowIds={expandedRowIds} lastRowId={rows.length - 1} {...props} />
              )}
              height="750px"
            />
            <TableColumnResizing defaultColumnWidths={budgetColumnsWidths} resizingMode="nextColumn" />
            <TableRowDetail contentComponent={RowDetail} />
            <TableColumnVisibility hiddenColumnNames={hiddenColumn} />
            <TableHeaderRow
              showSortingControls
              rowComponent={renderTableHeaderRow}
              cellComponent={({ column, children, ...rest }) => {
                if (column.name === 'budgetForecast' || column.name === 'forecastedStatus') {
                  return (
                    <TableHeaderRow.Cell {...rest}>
                      <div className={classes.customCellHeader}>
                        {children}
                        <Tooltip
                          title={
                            column.name === 'budgetForecast'
                              ? 'The forecasted spend at the end of the month/period' +
                                ' (corresponding to the chosen budget type)'
                              : 'The forecasted spend at the end of the month/period vs. ' +
                                'monthly/period budget  (corresponding to the chosen budget type)'
                          }
                          arrow
                          className={`toolTip ${classes.leftMargin}`}
                        >
                          <span>
                            <InfoIcon />
                          </span>
                        </Tooltip>
                      </div>
                    </TableHeaderRow.Cell>
                  );
                }
                return (
                  <TableHeaderRow.Cell column={column} {...rest}>
                    {children}
                  </TableHeaderRow.Cell>
                );
              }}
            />
            <PagingPanel />
          </Grid>
        </NewTableWrapper>
      )}
      <Modal
        title={`Are you sure you want to delete ${currentRow?.budgetName} budget?`}
        saveTitle="Delete"
        onClose={() => setShowDeleteMsg(false)}
        open={showDeleteMsg}
        onSave={() => deleteRow(currentRow.uuid)}
        saveWarning
      >
        <div className={styles.uninstallText}>
          Deleting this budget will also delete any related alerts and dashboards.
        </div>
      </Modal>
      {isEditMode && (
        <BudgetModal
          fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
          saveBudget={editBudget ? editRow : duplicateRow}
          editBudget={editBudget}
          isOpen={isEditMode}
          setIsOpen={setIsEditMode}
          isLoading={isLoading}
          newBudget={duplicateBudget}
          budgetsNames={budgetsNames?.filter((b) => b !== editBudget?.budgetName)}
        />
      )}
    </div>
  );
};

export default BudgetTable;

BudgetTable.propTypes = {
  rows: PropTypes.array.isRequired,
  deleteRow: PropTypes.func.isRequired,
  totalRows: PropTypes.number.isRequired,
  editRow: PropTypes.func.isRequired,
  duplicateRow: PropTypes.func.isRequired,
  fieldToFieldDistincValuesMap: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  allRowCount: PropTypes.number.isRequired,
  setIsEditMode: PropTypes.func.isRequired,
  budgetsNames: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

BudgetTable.defaultProps = {
  isLoading: false,
};
