/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable no-empty */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style,arrow-parens,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */
/* eslint-disable  jsx-a11y/click-events-have-key-events */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid,
  PagingPanel,
  TableColumnVisibility,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { alpha } from '@mui/system/colorManipulator';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import Spinner from 'shared/components/andtComponents/Spinner';
import Panel from 'shared/components/Panel';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import NoDataFoundComponent from 'shared/components/NoDataFoundComponent';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

// eslint-disable-next-line react/prop-types
const FilterIcon = ({ type, ...restProps }) => <TableFilterRow.Icon type={type} {...restProps} />;

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    fontSize: '25.5vw',
  },
});

class DevExpBigQueryTable extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    bigQueryModel: PropTypes.object.isRequired,
    selectedValuesMap: PropTypes.object.isRequired,
    csvTitle: PropTypes.string,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    setPageSize: PropTypes.func.isRequired,
    changeColumnWidths: PropTypes.func.isRequired,
    columnWidths: PropTypes.object.isRequired,
    costColumnsArray: PropTypes.object,
    isLoading: PropTypes.bool,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  static defaultProps = {
    csvTitle: 'big-query',
    costColumnsArray: [],
    isLoading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      pageSizes: [5, 10, 15, 20],
      // modifiedDataForCsv: [],
      currentPage: 0,
      loadingMoreRows: false,
      hiddenColumnNames: [],
    };
  }

  setCurrentPage = async (newPage) => {
    const lastPage = Math.floor(this.props.data.length / this.props.pageSize);
    this.setState({ currentPage: newPage });
    if (newPage + 1 >= lastPage && this.props.bigQueryModel.bigQueryTableDataLastId) {
      try {
        const { bigQueryModel } = this.props;
        this.setState({ loadingMoreRows: true });
        const { selectedValuesMap, startDate, endDate } = this.props;
        if (startDate && endDate) {
          const start = buildStartAndEndDate(startDate, endDate).startDate;
          const end = buildStartAndEndDate(startDate, endDate).endDate;
          await bigQueryModel.fetchBigQueryTableData(start, end, selectedValuesMap);
        } else {
          await bigQueryModel.fetchBigQueryTableData(null, null, selectedValuesMap);
        }
        this.setState({ loadingMoreRows: false });
      } catch (error) {
        this.setState({ loadingMoreRows: false });
      }
    }
  };
  prepareRowsForCsv = (row) => {
    return Object.entries(row).reduce((acc, [key, value]) => {
      const currRelevantColumn = this.props.columns.find((col) => col.name === key);
      if (currRelevantColumn) {
        const { name, title } = currRelevantColumn;
        if (name === 'usageDate') {
          [acc.Date] = value.split(' ');
        } else if (name === 'jobQuery') {
          acc[title] = `${value}`;
        } else {
          acc[title] = value;
        }
      }
      return acc;
    }, {});
  };

  prepareCsvModifiedRows = (data) => {
    const copyData = JSON.parse(JSON.stringify(data));
    const csvModifiedRows = copyData.map((row) => {
      return this.prepareRowsForCsv(row);
    });
    return csvModifiedRows;
  };
  exportAsCsv = async () => {
    const { bigQueryModel, csvTitle } = this.props;
    const preparedData = this.prepareCsvModifiedRows(bigQueryModel.bigQueryTableData);
    return [{ data: preparedData, filename: `${csvTitle}.csv` }];
  };
  currenciesFormatter = (value) => this.props.getCurrencyNumber(value.value, 3, { roundNumber: true });
  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  render() {
    const {
      pageSize,
      setPageSize,
      data,
      columns,
      startDate,
      endDate,
      columnWidths,
      changeColumnWidths,
      costColumnsArray,
      isLoading,
      csvTitle,
    } = this.props;
    const { hiddenColumnNames, loadingMoreRows, currentPage, pageSizes } = this.state;
    if (isLoading) {
      return (
        <Panel>
          <div style={{ height: '55px' }}>
            <Spinner />
          </div>
        </Panel>
      );
    }
    if (!data?.length) {
      return <NoDataFoundComponent />;
    }
    return (
      <div className="card">
        {startDate && endDate ? (
          <Row
            style={{
              marginLeft: '0px',
              marginTop: '10px',
              marginRight: '25px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <CustomCSVDownload filesNumber={1} fetchData={this.exportAsCsv} classNames="btn link" />
          </Row>
        ) : null}
        {loadingMoreRows ? (
          <Spinner />
        ) : (
          <Grid rows={data} columns={columns}>
            <SortingState />
            <FilteringState />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.setCurrentPage}
              defaultCurrentPage={0}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedPaging />
            {costColumnsArray && (
              <DataTypeProvider for={costColumnsArray} formatterComponent={this.currenciesFormatter} />
            )}
            <TableWrapper styles={styles} />
            <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={changeColumnWidths} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow showFilterSelector iconComponent={FilterIcon} />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
            />
            <Toolbar />
            <ColumnChooser />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        )}
      </div>
    );
  }
}

const ObserverDevExpRecommendationHistoryTable = withUserSettingsConsumer(DevExpBigQueryTable);
export default ObserverDevExpRecommendationHistoryTable;
