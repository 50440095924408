/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SearchState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  SearchPanel,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Undo';
import moment from 'moment';
import Spinner from 'shared/components/andtComponents/Spinner';
import AlertCircleOutline from 'mdi-react/AlertCircleOutlineIcon';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import EditPencil from 'shared/img/icons/PencilIcon';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

const EditButton = ({ onExecute }) => <EditPencil onClick={onExecute} />;
EditButton.propTypes = {
  onExecute: PropTypes.func.isRequired,
};

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes" size="large">
    <SaveIcon />
  </IconButton>
);
CommitButton.propTypes = {
  onExecute: PropTypes.func.isRequired,
};
const CancelButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Cancel changes" size="large">
    <CancelIcon />
  </IconButton>
);
CancelButton.propTypes = {
  onExecute: PropTypes.func.isRequired,
};

const commandComponents = {
  edit: EditButton,
  commit: CommitButton,
  cancel: CancelButton,
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return <CommandButton onExecute={onExecute} />;
};
Command.propTypes = {
  id: PropTypes.number.isRequired,
  onExecute: PropTypes.func.isRequired,
};

const CSVButton = ({ fetchCSVData }) => (
  <span onClick={fetchCSVData} className="csv-download" style={{ cursor: 'pointer', color: '#80bbf8' }}>
    CSV
  </span>
);

CSVButton.propTypes = {
  fetchCSVData: PropTypes.func.isRequired,
};

const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '80%',
  height: '100%',
};
const textStyle = {
  width: '50%',
};

const colStyle = {
  position: 'relative',
  width: '100%',
  minHeight: '1px',
  paddingRight: '15px',
  flexBasis: 0,
  flexGrow: 1,
  maxWidth: '100%',
};

class CustomersTable extends Component {
  static propTypes = {
    tableData: PropTypes.object.isRequired,
    divisionsStore: PropTypes.object.isRequired,
    handleDownloadPdf: PropTypes.func.isRequired,
    handleExplainCustomerFlexibilityMargin: PropTypes.func.isRequired,
    selectedRowsUpdate: PropTypes.func.isRequired,
    tableColumns: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { tableData } = this.props;
    this.state = {
      rows: tableData,
      rowChanges: {},
      pageSizes: [15, 20, 25, 0],
      selectedRows: [],
      tableIsUpdated: false,
      download: {},
    };
    this.csvLink = React.createRef();
  }

  setRowChanges = (rowChanges) => {
    const oldRowChanges = this.state.rowChanges;
    const newRowChanges = rowChanges;
    Object.keys(rowChanges).forEach((objKey) => {
      // eslint-disable-next-line no-constant-condition
      if (Number.isNaN(rowChanges[objKey].divisionMarginPercent)) {
        if (!rowChanges[objKey].divisionMarginPercent) {
          this.setState({ rowChanges });
        } else if (!rowChanges[objKey].customerType) {
          this.setState({ rowChanges: oldRowChanges });
        } else if (rowChanges[objKey].customerType) {
          newRowChanges[objKey].divisionMarginPercent = oldRowChanges[objKey].divisionMarginPercent;
          this.setState({ rowChanges: newRowChanges });
        }
      } else {
        this.setState({ rowChanges });
      }
    });
  };

  setSelectedRows = (selectedRows) => {
    this.setState({ selectedRows });
    this.props.selectedRowsUpdate(selectedRows);
  };

  currenciesFormatter = (value) => {
    const { getCurrencyNumber } = this.props;
    if (value && _.isNumber(value.value)) {
      const cost = getCurrencyNumber(value.value || 0, 1);
      return `${cost}`;
    }
    return '';
  };

  percentagesFormatter = (value) => (value.value ? `${value.value}%` : null);

  handleDownloadPdf = async (divisionId, divisionName, accountKey) => {
    const data = await this.props.divisionsStore.getCustomerCostAndUsageData(divisionId, divisionName, accountKey);
    return data;
  };
  updatedDownloadState = (clickedId, clickedDivisionName, clickedAccountKey, status, month, year) => {
    const clickedKey = `${clickedId}-${clickedDivisionName}-${clickedAccountKey}-${month}-${year}`;
    const currDownloadState = {};
    currDownloadState[clickedKey] = status;
    return currDownloadState;
  };
  downloadPdfHandler = async (id, divisionName, accountKey, month, year) => {
    let downloadStatus = 'downloading';
    let currDownloadState = this.updatedDownloadState(id, divisionName, accountKey, downloadStatus, month, year);
    let creditsSum = [];
    this.setState({
      download: currDownloadState,
      creditsSum,
    });
    const rawStartDate = new Date(year, month - 1, 1);
    const rawEndDate = new Date(year, month, 0);
    const { startDate, endDate } = buildStartAndEndDate(rawStartDate, rawEndDate);
    try {
      ({ creditsSum } = await this.props.handleDownloadPdf(id, divisionName, accountKey, startDate, endDate));
      downloadStatus = 'downloaded';
      currDownloadState = this.updatedDownloadState(id, divisionName, accountKey, downloadStatus, month, year);
    } catch (error) {
      downloadStatus = 'error';
      currDownloadState = this.updatedDownloadState(id, divisionName, accountKey, downloadStatus, month, year);
    }
    this.setState({
      creditsSum,
      download: currDownloadState,
    });
    return {
      creditsSum,
    };
  };
  prepareCsvData = (data, costData, cost, refundSum, creditSum) => {
    const csvData = [[`${data.row.customerName} ${data.row.year}-${data.row.month}`, '']];
    costData.forEach((service) => csvData.push([service.service, service.totalCost]));
    csvData.push(['', '']);
    csvData.push(
      ['Cost', +cost.toFixed(4)],
      ['Refund', +refundSum.toFixed(4)],
      ['Credit', +creditSum.toFixed(4)],
      ['Total Cost', +cost.toFixed(4) + +refundSum.toFixed(4) + +creditSum.toFixed(4)],
    );
    const date = new Date(data.row.year, data.row.month - 1, 1);
    const monthName = date.toLocaleDateString(undefined, { month: 'long' });
    const currCustomerName = data.row.customerName;
    const currYear = data.row.year;
    return { csvData, currMonthName: monthName, currCustomerName, currYear };
  };
  handleGetCsvData = async (data) => {
    // eslint-disable-next-line max-len
    const clickedKey = `${data.row.divisionId}-${data.row.customerName}-${data.row.accountKey}-${data.row.month}-${data.row.year}`;
    const currentStatus = this.state.download[clickedKey];
    if (currentStatus === 'downloading') {
      return;
    }
    let { creditsSum } = this.state;
    if (currentStatus !== 'downloaded') {
      try {
        ({ creditsSum } = await this.downloadPdfHandler(
          data.row.divisionId,
          data.row.customerName,
          data.row.accountKey,
          data.row.month,
          data.row.year,
        ));
      } catch (error) {
        console.log(error);
      }
    }
    this.setState(
      {
        creditsSum,
      },
      () =>
        this.csvLink.current
          ? this.csvLink.current.link.click()
          : setTimeout(() => {
              this.csvLink.current.link.click();
            }),
    );
  };
  publicPriceFlexibilityFormatter = (data) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={textStyle}>
        <span>{this.props.getCurrencyNumber(data.row.publicPriceMargin || 0, 1)}</span>
      </div>
      <div style={colStyle}>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="#"
            onClick={(e) =>
              this.props.handleExplainCustomerFlexibilityMargin({
                e,
                divisionId: data.row.divisionId,
                divisionName: data.row.customerName,
                accountKey: data.row.accountKey,
                flexibilityType: 'pp',
                month: data.row.month,
                year: data.row.year,
              })
            }
          />
          <AlertCircleOutline size={16} />
        </LinkIconButton>
      </div>
    </div>
  );
  billingRuleMarginFormatter = (data) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={textStyle}>
        <span>{this.props.getCurrencyNumber(data.row.billingRuleMargin || 0, 1)}</span>
      </div>
      <div style={colStyle}>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="#"
            onClick={(e) =>
              this.props.handleExplainCustomerFlexibilityMargin({
                e,
                divisionId: data.row.divisionId,
                divisionName: data.row.customerName,
                accountKey: data.row.accountKey,
                flexibilityType: 'billingRules',
                month: data.row.month,
                year: data.row.year,
              })
            }
          />
          <AlertCircleOutline size={16} />
        </LinkIconButton>
      </div>
    </div>
  );
  spFlexibilityFormatter = (data) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={textStyle}>
        <span>{this.props.getCurrencyNumber(data.row.spFlexibilityMargin || 0, 1)}</span>
      </div>
      <div style={colStyle}>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="#"
            onClick={(e) =>
              this.props.handleExplainCustomerFlexibilityMargin({
                e,
                divisionId: data.row.divisionId,
                divisionName: data.row.customerName,
                accountKey: data.row.accountKey,
                flexibilityType: 'sp',
                month: data.row.month,
                year: data.row.year,
              })
            }
          />
          <AlertCircleOutline size={16} />
        </LinkIconButton>
      </div>
    </div>
  );
  riFlexibilityFormatter = (data) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={textStyle}>
        <span>{this.props.getCurrencyNumber(data.row.riFlexibilityMargin || 0, 1)}</span>
      </div>
      <div style={colStyle}>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="#"
            onClick={(e) =>
              this.props.handleExplainCustomerFlexibilityMargin({
                e,
                divisionId: data.row.divisionId,
                divisionName: data.row.customerName,
                accountKey: data.row.accountKey,
                flexibilityType: 'ri',
                month: data.row.month,
                year: data.row.year,
              })
            }
          />
          <AlertCircleOutline size={16} />
        </LinkIconButton>
      </div>
    </div>
  );
  creditMarginFormatter = (data) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={textStyle}>
        <span>{this.props.getCurrencyNumber(data.row.creditMargin || 0, 1)}</span>
      </div>
      <div style={colStyle}>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="#"
            onClick={(e) =>
              this.props.handleExplainCustomerFlexibilityMargin({
                e,
                divisionId: data.row.divisionId,
                divisionName: data.row.customerName,
                accountKey: data.row.accountKey,
                flexibilityType: 'creditMargin',
                month: data.row.month,
                year: data.row.year,
              })
            }
          />
          <AlertCircleOutline size={16} />
        </LinkIconButton>
      </div>
    </div>
  );
  resellerCreditFormatter = (data) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={textStyle}>
        <span>{this.props.getCurrencyNumber(data.row.resellerCredit || 0, 1)}</span>
      </div>
      <div style={colStyle}>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="#"
            onClick={(e) =>
              this.props.handleExplainCustomerFlexibilityMargin({
                e,
                divisionId: data.row.divisionId,
                divisionName: data.row.customerName,
                accountKey: data.row.accountKey,
                flexibilityType: 'resellerCredit',
                month: data.row.month,
                year: data.row.year,
              })
            }
          />
          <AlertCircleOutline size={16} />
        </LinkIconButton>
      </div>
    </div>
  );

  render() {
    const { tableColumns } = this.props;
    const { selectedRows, pageSizes, tableIsUpdated, rows } = this.state;
    if (tableIsUpdated) {
      return <Spinner />;
    }

    const monthSort = (a, b) => moment().month(a).format('M') - moment().month(b).format('M');

    const sortingExtensions = [
      {
        columnName: 'month',
        compare: monthSort,
      },
    ];

    return (
      <div className="card history-table-page">
        <Paper>
          <Grid rows={rows} columns={tableColumns}>
            <SortingState
              defaultSorting={[
                { columnName: 'year', direction: 'desc' },
                { columnName: 'month', direction: 'desc' },
                { columnName: 'mtdResellerCost', direction: 'desc' },
                { columnName: 'mtdCustomerCost', direction: 'desc' },
                { columnName: 'partnerMargin', direction: 'desc' },
              ]}
            />
            <IntegratedSorting columnExtensions={sortingExtensions} />
            <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[0]} />
            <SelectionState selection={selectedRows} onSelectionChange={this.setSelectedRows} />
            <SearchState />
            <IntegratedFiltering />

            <IntegratedPaging />
            <IntegratedSelection />
            <DataTypeProvider
              for={['mtdResellerCost', 'mtdCustomerCost', 'partnerMargin', 'riMargin', 'usageMargin']}
              formatterComponent={this.currenciesFormatter}
            />
            <DataTypeProvider for={['riFlexibilityMargin']} formatterComponent={this.riFlexibilityFormatter} />
            <DataTypeProvider for={['spFlexibilityMargin']} formatterComponent={this.spFlexibilityFormatter} />
            <DataTypeProvider for={['publicPriceMargin']} formatterComponent={this.publicPriceFlexibilityFormatter} />
            <DataTypeProvider for={['billingRuleMargin']} formatterComponent={this.billingRuleMarginFormatter} />
            <DataTypeProvider for={['creditMargin']} formatterComponent={this.creditMarginFormatter} />
            <DataTypeProvider for={['resellerCredit']} formatterComponent={this.resellerCreditFormatter} />
            <TableWrapper columnExtensions={this.tableColumnExtensions} />
            <TableHeaderRow showSortingControls />
            <Toolbar />
            <SearchPanel />
            <TableSelection showSelectAll />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        </Paper>
      </div>
    );
  }
}

const ObserverCustomersTable = withUserSettingsConsumer(CustomersTable);
export default ObserverCustomersTable;
