import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Spinner from 'shared/components/andtComponents/Spinner';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import DateFilter from 'shared/modules/dateFilter';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import {
  RDS_COLUMN_WIDTHS,
  RDS_CURRENCIES_COLUMNS,
  RDS_DEFAULT_HIDDEN_COLUMN_NAMES,
  RDS_DEFAULT_SORTING,
  RDS_TABLE_COLUMN_EXTENSIONS,
  RDS_TABLE_COLUMNS,
  RDS_TABLE_CSV_COLUMNS,
} from 'usage/constants/usageConstants';
import { buildStartAndEndDate, createTimeZoneAgnosticDateFromStr } from 'shared/utils/dateUtil';
import DevExpSearchAndVisibilityTable from './components/RdsDevExpTable';
import prepareTableColumns from './helpers/prepareTableColumns';

class RdsUsagePage extends Component {
  static propTypes = {
    usageStore: PropTypes.object.isRequired,
    appStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { appStore } = this.props;
    this.state = {
      dataIsLoading: false,
      startDate: this.datePickerSyntexDates(null, null).startDate,
      endDate: this.datePickerSyntexDates(null, null).endDate,
      isCustomerPricingMode: appStore.isCustomerPricingMode,
    };
    this.months = [];
  }

  UNSAFE_componentWillMount() {
    const { month } = DateFilter;
    this.setState({ dateFilter: month });
  }

  async componentDidMount() {
    const { usageStore } = this.props;
    try {
      this.setState({ dataIsLoading: true });
      await usageStore.getRdsData();
      this.setState({ dataIsLoading: false });
    } catch (error) {
      this.setState({ dataIsLoading: false, data: [] });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { appStore } = this.props;
    const prevStateIsCustomerPricingMode = prevState.isCustomerPricingMode;
    const currPropsIsCustomerPricingMode = appStore.isCustomerPricingMode;
    if (currPropsIsCustomerPricingMode !== prevStateIsCustomerPricingMode) {
      this.setState({ isCustomerPricingMode: currPropsIsCustomerPricingMode, dataIsLoading: true }, () => {
        this.handleDateChange(this.state);
      });
    }
  }

  datePickerSyntexDates = (start, end) => {
    let { startDate, endDate } = buildStartAndEndDate(start || '', end || '');
    startDate = createTimeZoneAgnosticDateFromStr(startDate);
    endDate = createTimeZoneAgnosticDateFromStr(endDate);
    return { startDate, endDate };
  };

  handleDateChange = async ({ startDate, endDate }) => {
    const start = startDate || this.state.startDate;
    let end = endDate || this.state.endDate;
    if (start > end && end !== null) {
      end = start;
    }
    const modStartDate = buildStartAndEndDate(start, end).startDate;
    const modEndDate = buildStartAndEndDate(start, end).endDate;
    this.setState({
      startDate: modStartDate,
      endDate: modEndDate,
    });
    this.setState({ dataIsLoading: true });
    const data = await this.props.usageStore.fetchRdsData(modStartDate, modEndDate);
    this.setState({ dataIsLoading: false, data });
  };

  render() {
    const { appStore, usageStore } = this.props;
    const { startDate, endDate, dataIsLoading } = this.state;
    // eslint-disable-next-line max-len,no-unused-vars
    const { isCustomerPricingMode } = appStore; // We need it to trigger componentDidUpdate when pricing mode change. keep it.
    const preparedData = usageStore.rdsData;
    return (
      <Container>
        <PageHeader title={PageNames.RDS_USAGE} showDate />
        <Card>
          <CardBody style={{ minHeight: '300px' }}>
            <Row style={{ marginLeft: '1px' }}>
              <DatePickerFilter
                onDateChange={this.handleDateChange}
                startDate={this.datePickerSyntexDates(startDate, null).startDate}
                endDate={this.datePickerSyntexDates(null, endDate).endDate}
                currPeriodGranLevel="month"
              />
            </Row>
            {dataIsLoading ? (
              <Spinner />
            ) : (
              <Row>
                <DevExpSearchAndVisibilityTable
                  data={preparedData}
                  columns={prepareTableColumns(RDS_TABLE_COLUMNS, preparedData)}
                  columnWidths={RDS_COLUMN_WIDTHS}
                  tableColumnExtensions={RDS_TABLE_COLUMN_EXTENSIONS}
                  defaultHiddenColumnNames={RDS_DEFAULT_HIDDEN_COLUMN_NAMES}
                  defaultSorting={RDS_DEFAULT_SORTING}
                  currenciesColumns={RDS_CURRENCIES_COLUMNS}
                  csvColumns={RDS_TABLE_CSV_COLUMNS}
                  csvTitle={`RDS_data_${DateFilter.month}_${DateFilter.year}`}
                  isPaging
                />
              </Row>
            )}
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const ObserverRdsUsagePage = observer(RdsUsagePage);
export default ObserverRdsUsagePage;
