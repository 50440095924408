import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { CSVLink } from 'react-csv';
import { mapCsvHeadersKeyToLabel } from 'commitment/containers/Commitments/constants/commitmentConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import styles from './CustomCSVDownload.module.scss';
import Button from '../andtComponents/Button';

const getFilteredFormattedHeaders = (rows, headers) => {
  if (!headers) {
    return rows;
  }
  const filteredHeaders = headers.filter((header) => rows.some((row) => row[header]));
  const keysMissedOnHeaders = [
    ...new Set(
      rows.reduce((acc, row) => {
        Object.keys(row).forEach((key) => {
          if (!headers.includes(key) && key !== 'UpfrontFee') {
            acc.push(key);
          }
        });
        return acc;
      }, []),
    ),
  ];
  filteredHeaders.push(...keysMissedOnHeaders);
  return rows.map((r) =>
    filteredHeaders.reduce(
      (acc, key) => ({
        ...acc,
        [mapCsvHeadersKeyToLabel.get(key) || key]: r[key],
      }),
      {},
    ),
  );
};

export const getRowsNamesWithoutPoints = (data) =>
  data.map((currentData) => {
    const res = {};
    Object.keys(currentData).forEach((currKey) => {
      const newKey = currKey.replace(/\./g, '').replace(/\[/g, '(').replace(/]/g, ')');
      res[newKey] = currentData[currKey];
    });
    return res;
  });

// https://github.com/react-csv/react-csv/issues/72#issuecomment-421160842
export default function CustomCSVDownload({
  filesNumber,
  fetchData,
  data,
  headers,
  automationId,
  isLoading,
  style = {},
  formatData,
  className,
  isSecondary,
  isPrimary,
  disabled,
  hideText,
  showDownloadIcon,
  children,
  separator,
}) {
  const { usersStore } = useRootStore();
  const [info, setInfo] = useState(data);
  const [fetchDataCounter, setFetchDataCounter] = useState(0);
  const initialRender = useRef(true);
  const [csvLinks, setCsvLinks] = useState(
    Array(filesNumber)
      .fill(0)
      .map(() => React.createRef()),
  );
  useEffect(() => {
    setInfo(data || []);
  }, [data]);
  useEffect(() => {
    setCsvLinks(
      Array(filesNumber)
        .fill(0)
        .map(() => React.createRef()),
    );
  }, [filesNumber]);

  const startDownload = () => {
    csvLinks.forEach((link) => link.current && link.current.link.click());
  };

  const convertData = (infoFile) => {
    if (!infoFile) {
      return infoFile;
    }
    const rows = getFilteredFormattedHeaders(formatData(infoFile.data), headers);
    const hasLinkAccIds =
      rows && rows.length ? rows.some((row) => !!row.linkedAccountId || !!row['Linked Account Id']) : false;
    const rowsWithAdditionalData = hasLinkAccIds && usersStore ? usersStore.attachAccountTagsToCSVReport(rows) : rows;
    const rowsNamesWithoutPoints = getRowsNamesWithoutPoints(rowsWithAdditionalData);
    return { ...infoFile, data: rowsNamesWithoutPoints };
  };

  useEffect(() => {
    // Ignore the initial render
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      startDownload();
    }
  }, [fetchDataCounter]);
  const fetchAllData = async () => {
    const payload = await fetchData();
    // update info with fetched data
    setInfo(payload || []);
    // increase the counter to trigger the effect which will start the download
    setFetchDataCounter(fetchDataCounter + 1);
  };

  const renderIcon = () => <GenerateIcon iconName={ICONS.download.name} />;

  return (
    <div className={className}>
      <Button
        automationId={automationId || 'CustomCSVDownload'}
        isTextButton={!isSecondary && !isPrimary}
        isSecondary={isSecondary}
        onClick={(e) => {
          e.stopPropagation();
          if (isLoading) {
            return;
          }
          if (fetchData) {
            fetchAllData();
            return;
          }
          startDownload();
        }}
        disabled={disabled}
        text={hideText ? children || null : 'Export as CSV'}
        isLoading={isLoading}
        overrideStyles={{ height: 25, fontWeight: 400, ...style }}
        icon={!showDownloadIcon ? undefined : renderIcon}
      />
      {csvLinks.map((link, index) => (
        <CSVLink
          {...(convertData(info[index]) || { data: [], filename: '' })}
          className={styles.hiddenLinks}
          ref={link}
          separator={separator}
          target="_blank"
        />
      ))}
    </div>
  );
}

CustomCSVDownload.propTypes = {
  fetchData: PropTypes.func,
  formatData: PropTypes.func,
  data: PropTypes.array,
  headers: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  showDownloadIcon: PropTypes.bool,
  children: PropTypes.array,
  hideText: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  filesNumber: PropTypes.number.isRequired,
  className: PropTypes.string,
  automationId: PropTypes.string,
  separator: PropTypes.string,
};
CustomCSVDownload.defaultProps = {
  className: '',
  fetchData: undefined,
  hideText: false,
  data: [],
  headers: null,
  children: null,
  disabled: false,
  isPrimary: false,
  isSecondary: false,
  separator: undefined,
  showDownloadIcon: false,
  automationId: '',
  style: {},
  formatData: (data) => data,
};
