/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Card, CardBody, Carousel, CarouselControl, CarouselItem, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { PageNames } from 'shared/constants/appConstants';
import { Routes } from 'shared/constants/routes';
import { CLOUD_TYPE_IDS, UsersType } from 'users/constants/usersConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import AlertError from '../shared/components/Alerts/AlertError';
import AlertGoToNext from '../shared/components/Alerts/AlertGoToNext';
import { AzureActiveIndexToTitleText, pages } from './constants/newUserConstants';
import WizardIntro from './components/Intro/WizardIntro';
import ConfigureAzureIntegration from './components/Intro/ConfigureAzureIntegration';
import CarouselPageNavigator from '../shared/components/LayoutComponents/CarouselPageNavigator';
import EaDetailsConfiguration from './components/EaDetailsConfiguration';
import BillingExportIntegrationConfiguration from './components/BillingExportIntegrationConfiguration';
import AzureAppRegistration from './components/AzureAppRegistration';
import SideBarNavigationTimeline from '../shared/components/LayoutComponents/SideBarNavigationTimeline';

const items = [
  {
    id: pages.page0,
    withProps: false,
    component: <WizardIntro />,
  },
  {
    id: pages.page1,
    withProps: true,
    component: ConfigureAzureIntegration,
  },
];

const isBillingExportConfiguration = (configurationType) =>
  ['csp', 'payg', 'mca', 'EA_EXPORT'].includes(configurationType);

const mapComponentBySelectedConfiguration = new Map([
  [
    'ea',
    [
      {
        id: pages.page2,
        withProps: true,
        component: EaDetailsConfiguration,
      },
    ],
  ],
  [
    'billingExport',
    [
      {
        id: pages.page2,
        withProps: true,
        component: AzureAppRegistration,
      },
      {
        id: pages.page3,
        withProps: true,
        component: BillingExportIntegrationConfiguration,
      },
    ],
  ],
]);

const WithProps =
  (C) =>
  ({ ...props }) =>
    <C {...props} />;

class NewAzureEaUserWizard extends Component {
  static propTypes = {
    usersStore: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { usersStore } = this.props;
    const migrationAccountId = new URLSearchParams(props.location.search).get('migrationAccountId');
    this.state = {
      activeIndex: migrationAccountId ? pages.page2 : pages.page0,
      modal: false,
      enrollmentId: '',
      apiAccessKey: '',
      apiAccessKeyEffectiveDate: '',
      accountName: '',
      accountId: migrationAccountId || '',
      directoryId: '',
      applicationId: '',
      clientSecret: '',
      isSavingData: false,
      isEaDataSaved: false,
      isSaveDataError: false,
      showNoInputValueWarning: false,
      showGoToNextAlert: false,
      isAppRegButtonDisabled: true,
      azureSelectedConfiguration: migrationAccountId ? 'EA_EXPORT' : '',
      isCSP: false,
      applicationName: '',
      accountStorage: '',
      containerName: '',
      isAppCredentialsTestError: false,
      isTestAppCreds: false,
      isMultipleSources: false,
      isMultipleFiles: false,
      directoryPrefix: '',
      showNoSelectedIntegrationConfigWarning: false,
      showNoSelectedBEPaymentTypeWarning: false,
      eaToMCAMigration: !!migrationAccountId,
    };
    this.dynamicItems = [];
  }

  componentDidUpdate() {}

  onSubmit = () => this.toggle();

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  getSteps = (dynamicItems) => {
    const { azureSelectedConfiguration, mca } = this.state;
    return dynamicItems.map(({ id }) => {
      const azurActiveIndexTitle = AzureActiveIndexToTitleText.get(id);
      if (typeof azurActiveIndexTitle === 'function') {
        return azurActiveIndexTitle(azureSelectedConfiguration);
      }
      return azurActiveIndexTitle;
    });
  };

  // user pressed the modal button
  toggle = async () => {
    const { activeIndex } = this.state;
    if (this.validateForm(activeIndex)) {
      const connectMeSent = true;
      this.setState({ connectMeSent });
      const { isConnectionSuccess, apiAccessKey } = await this.handleConnect(connectMeSent); // handle connect also check if user has linked accounts
      this.updateUserConnectionResult(isConnectionSuccess, apiAccessKey);
    }
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  handleEaConfigInputsChange = (newInput, e) => {
    if (!e) {
      return;
    }
    const currState = { ...this.state };
    this.setState({ [e.target.name]: newInput, isSaveDataError: false });
    currState[e.target.name] = newInput;
    if (
      currState.enrollmentId.length > 0 &&
      currState.apiAccessKey.length > 0 &&
      currState.accountName.length > 0 &&
      currState.apiAccessKeyEffectiveDate.length > 0
    ) {
      this.setState({ showNoInputValueWarning: false, showGoToNextAlert: true });
    }
  };

  handleAppRegistrationInputsChange = (newInput, e) => {
    if (!e) {
      return;
    }
    const currState = { ...this.state };
    this.setState({ [e.target.name]: newInput, isSaveDataError: false });
    currState[e.target.name] = newInput;
    if (currState.directoryId.length > 0 && currState.applicationId.length > 0 && currState.clientSecret.length > 0) {
      this.setState({ isAppRegButtonDisabled: false });
    }
  };

  disableEnterKeySub = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  updateAlertClosed = () => {
    this.setState({
      showNoInputValueWarning: false,
      showGoToNextAlert: false,
      isAppCredentialsTestError: false,
      showNoSelectedIntegrationConfigWarning: false,
    });
  };
  updateDataErrorClosed = () => {
    this.setState({ isSaveDataError: false });
  };

  validateForm = (page) => {
    let result = true;
    const {
      azureSelectedConfiguration,
      enrollmentId,
      apiAccessKey,
      accountName,
      apiAccessKeyEffectiveDate,
      applicationId,
      applicationName,
      clientSecret,
      directoryId,
      containerName,
      accountStorage,
      eaToMCAMigration,
    } = this.state;
    if (page === pages.page1 && !azureSelectedConfiguration) {
      result = false;
      this.setState({ showNoSelectedIntegrationConfigWarning: true });
    }
    if (page === pages.page2) {
      if (
        // EA integration validation
        azureSelectedConfiguration === 'ea' &&
        (enrollmentId.length === 0 ||
          apiAccessKey.length === 0 ||
          accountName.length === 0 ||
          apiAccessKeyEffectiveDate.length === 0)
      ) {
        result = false;
      }
      // Billing Export integration validation
      if (
        isBillingExportConfiguration(azureSelectedConfiguration) &&
        (applicationId.length === 0 ||
          applicationName.length === 0 ||
          clientSecret.length === 0 ||
          directoryId.length === 0)
      ) {
        result = false;
        this.setState({ showNoInputValueWarning: true });
      }
    }
    if (page === pages.page3) {
      if (
        (!isBillingExportConfiguration(azureSelectedConfiguration) &&
          (directoryId.length === 0 || applicationId.length === 0 || clientSecret.length === 0)) ||
        (isBillingExportConfiguration(azureSelectedConfiguration) &&
          (containerName.length === 0 ||
            (accountName.length === 0 && !eaToMCAMigration) ||
            accountName.length > 15 ||
            accountStorage.length === 0))
      ) {
        result = false;
      }
    }
    return result;
  };
  updateUserConnectionResult = async (isConnectionSuccess, accountID, accountKey) => {
    const { usersStore } = this.props;
    if (!isConnectionSuccess) {
      return;
    }
    const currDispUserKey = usersStore.currentDisplayedUserKey;
    const currDispUser = usersStore.getUserByKey(currDispUserKey);
    if (currDispUser.userType === UsersType.NEW_USER) {
      const newType = UsersType.USER_ON_BOARDED;
      const cloudType = CLOUD_TYPE_IDS.AZURE;
      await usersStore.updateUserTypeAndAwsAccId(newType, accountID, isConnectionSuccess, cloudType, accountKey);
    }
  };

  saveEaDetailsConfiguration = async () => {
    const { usersStore, history } = this.props;
    const { enrollmentId, apiAccessKey, accountName, apiAccessKeyEffectiveDate, currencySymbol } = this.state;
    const eaDetailsConfiguration = {
      enrollmentId,
      apiAccessKey,
      accountName,
      apiAccessKeyEffectiveDate,
      azureAccountType: 'EA',
      accountProperties: { currencyCode: currencySymbol?.key },
    };
    try {
      this.setState({ isSavingData: true });
      const connectResult = await usersStore.connectAzureEaAccount(eaDetailsConfiguration);
      const { accountId, isConnectSuccess } = connectResult || {};
      if (isConnectSuccess) {
        await this.updateUserConnectionResult(isConnectSuccess, accountId, connectResult.accountKey);
        this.setState({ isSavingData: false, isEaDataSaved: isConnectSuccess, activeIndex: pages.page1, accountId });
        const { userType } = usersStore.getUserByKey(usersStore.currentDisplayedUserKey);
        history.push(userType === UsersType.NEW_USER ? Routes.WELCOME : Routes.DASHBOARD);
      } else {
        this.setState({ isSavingData: false, isSaveDataError: true });
      }
    } catch (error) {
      this.setState({ isSavingData: false, isSaveDataError: true });
    }
  };
  saveBillingExportConfiguration = async () => {
    const { usersStore, history } = this.props;
    const {
      applicationId,
      clientSecret,
      accountStorage,
      applicationName,
      isMultipleSources,
      directoryId,
      accountName,
      containerName,
      azureSelectedConfiguration,
      eaToMCAMigration,
      accountId,
      isCSP,
      currencySymbol,
      isMultipleFiles,
      directoryPrefix,
    } = this.state;
    const billingExportConfiguration = {
      applicationId,
      clientSecret,
      accountStorage,
      applicationName,
      isMultipleSources,
      isMultipleFiles,
      directoryPrefix,
      directoryId,
      azureAccountType: isCSP ? 'csp' : azureSelectedConfiguration,
      accountName,
      containerName,
      accountProperties: { currencyCode: currencySymbol?.key },
    };
    try {
      this.setState({ isSavingData: true });
      if (eaToMCAMigration) {
        await usersStore.usersModel.apiGateway.migrateAzureEAToMCAAccount({ accountId, ...billingExportConfiguration });
        history.push(Routes.ACCOUNT);
        usersStore.initMainUser();
        return;
      }
      const connectResult = await usersStore.connectAzureBillingExportAccount(billingExportConfiguration);
      if (connectResult.isConnectSuccess) {
        const { accountId } = connectResult;
        await this.updateUserConnectionResult(connectResult.isConnectSuccess, accountId, connectResult.accountKey);
        this.setState({
          isSavingData: false,
          isEaDataSaved: connectResult.isConnectSuccess,
          activeIndex: pages.page1,
          accountId,
        });
        const { userType } = usersStore.getUserByKey(usersStore.currentDisplayedUserKey);
        history.push(userType === UsersType.NEW_USER ? Routes.WELCOME : Routes.DASHBOARD);
      } else {
        this.setState({ isSavingData: false, isSaveDataError: true });
      }
    } catch (error) {
      this.setState({ isSavingData: false, isSaveDataError: true });
    }
  };
  saveAppRegistrationConfiguration = async () => {
    const { usersStore, history } = this.props;
    const { directoryId, applicationId, clientSecret, accountId } = this.state;
    const appConfiguration = { directoryId, applicationId, clientSecret, accountId };
    const { userType } = usersStore.getUserByKey(usersStore.currentDisplayedUserKey);
    try {
      this.setState({ isSavingData: true });
      await usersStore.saveAzureOnBoardingAppRegistrationConfiguration(appConfiguration);
      this.setState({ isSavingData: false });
      history.push(userType === UsersType.NEW_USER ? Routes.WELCOME : Routes.DASHBOARD);
    } catch (error) {
      this.setState({ isSavingData: false, isSaveDataError: true });
    }
  };

  navigationDisabled = () => this.animating || document.activeElement.nodeName === 'INPUT';

  next = async () => {
    const { history, usersStore } = this.props;
    const {
      azureSelectedConfiguration,
      activeIndex,
      directoryId,
      applicationId,
      clientSecret,
      accountId,
      isSavingData,
    } = this.state;
    if (this.navigationDisabled()) {
      return;
    }
    if (activeIndex === pages.page0) {
      usersStore.usersModel.sendOnBoardingEvent(CLOUD_TYPE_IDS.AZURE);
    }
    if (activeIndex === pages.page4) {
      return;
    }
    if (isSavingData) {
      return;
    }
    if (!this.validateForm(activeIndex)) {
      this.setState({ showNoInputValueWarning: true, showGoToNextAlert: false });
      return;
    }
    const isTestButton = activeIndex === pages.page2 && isBillingExportConfiguration(azureSelectedConfiguration);
    if (isTestButton) {
      const appConfiguration = { directoryId, applicationId, clientSecret, accountId };
      this.setState({ isTestAppCreds: true });
      const isRegAppConfiguration = await usersStore.testAppRegistraionConfiguration(appConfiguration);
      if (!isRegAppConfiguration) {
        this.setState({ isAppCredentialsTestError: !isRegAppConfiguration, isTestAppCreds: false });
        return;
      }
    }
    const nextIndex = activeIndex === this.dynamicItems.length - 1 ? activeIndex : activeIndex + 1;
    if (activeIndex === pages.page2 && azureSelectedConfiguration === 'ea') {
      this.saveEaDetailsConfiguration();
      return;
    }
    if (activeIndex === pages.page3 && isBillingExportConfiguration(azureSelectedConfiguration)) {
      this.saveBillingExportConfiguration();
      return;
    }
    if (activeIndex === pages.page4) {
      history.push(Routes.WELCOME);
    }
    this.setState({ showNoInputValueWarning: false, showGoToNextAlert: false, activeIndex: nextIndex });
  };

  previous = () => {
    const { activeIndex } = this.state;
    if (this.navigationDisabled()) {
      return;
    }
    if (activeIndex === 0) {
      return;
    }
    const nextIndex = activeIndex === 0 ? this.dynamicItems.length - 1 : activeIndex - 1;
    this.setState({ showNoInputValueWarning: false, activeIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    if (this.animating) {
      return;
    }
    this.setState({ activeIndex: newIndex });
  };

  goOnCondition = () => {
    this.setState({ showGoToNextAlert: true });
    return null;
  };

  handleConnectLinkedAcct = async () => {
    const { usersStore } = this.props;
    this.setState({ linkedAccounts: usersStore.usersModel.userLinkedAccounts });
  };

  handleSelectAzureConfiguration = (azureSelectedConfiguration) => this.setState({ azureSelectedConfiguration });

  handleChangeStateValuesByName = (name, value) => this.setState({ [name]: value });
  handleSelectCurrency = (value) => this.setState({ currencySymbol: value });

  render() {
    const { usersStore } = this.props;
    const {
      activeIndex,
      userConnectionStage,
      linkedAccounts,
      directoryId,
      applicationId,
      applicationName,
      accountName,
      currencySymbol,
      apiAccessKey,
      enrollmentId,
      isAppRegButtonDisabled,
      azureSelectedConfiguration,
      showNoInputValueWarning,
      containerName,
      accountStorage,
      isAppCredentialsTestError,
      isTestAppCreds,
      clientSecret,
      isMultipleSources,
      isMultipleFiles,
      directoryPrefix,
      showNoSelectedIntegrationConfigWarning,
      isSaveDataError,
      showGoToNextAlert,
      isSavingData,
      eaToMCAMigration,
      isCSP,
    } = this.state;
    const { userType } = usersStore.getUserByKey(usersStore.currentDisplayedUserKey) || {};
    this.dynamicItems = [
      ...items.slice(0, 2),
      ...(mapComponentBySelectedConfiguration.get(
        isBillingExportConfiguration(azureSelectedConfiguration) ? 'billingExport' : azureSelectedConfiguration,
      ) || []),
    ];
    const steps = this.getSteps(this.dynamicItems);
    const subTitle =
      typeof AzureActiveIndexToTitleText.get(activeIndex) === 'function'
        ? AzureActiveIndexToTitleText.get(activeIndex)(azureSelectedConfiguration)
        : AzureActiveIndexToTitleText.get(activeIndex);
    const slides = this.dynamicItems.map((item) => (
      <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.id}>
        {item.withProps
          ? WithProps(item.component)({
              enrollmentId,
              handleInvoiceBucketNameChange: this.handleInvoiceBucketNameChange,
              apiAccessKey,
              handleAwsAccountIDChange: this.handleAwsAccountIDChange,
              accountName,
              handleAwsAccountNameChange: this.handleAwsAccountNameChange,
              directoryId,
              applicationName,
              handleBucketRegionNameChange: this.handleBucketRegionNameChange,
              applicationId,
              handleArnRoleNumChange: this.handleArnRoleNumChange,
              disableEnterKeySub: this.disableEnterKeySub,
              onSubmit: this.onSubmit,
              goOnCondition: this.goOnCondition,
              userConnectionStage,
              usersStore,
              linkedAccounts,
              currencySymbol,
              handleConnectLinkedAcct: this.handleConnectLinkedAcct,
              handleEaConfigInputsChange: this.handleEaConfigInputsChange,
              handleAppRegistrationInputsChange: this.handleAppRegistrationInputsChange,
              validateForm: this.validateForm,
              isAppRegButtonDisabled,
              saveEaDetailsConfiguration: this.saveEaDetailsConfiguration,
              saveAppRegistrationConfiguration: this.saveAppRegistrationConfiguration,
              azureSelectedConfiguration,
              handleSelectAzureConfiguration: this.handleSelectAzureConfiguration,
              handleChangeStateValuesByName: this.handleChangeStateValuesByName,
              handleSelectCurrency: this.handleSelectCurrency,
              showNoInputValueWarning,
              isTestAppCreds,
              clientSecret,
              eaToMCAMigration,
              // Billing export integration configuration
              accountStorage,
              containerName,
              isMultipleSources,
              isMultipleFiles,
              directoryPrefix,
              isCSP,
              handleSetIsCSP: (isCSP) => this.setState({ isCSP }),
              handleSelectPaymentType: this.handleSelectPaymentType,
            })
          : item.component}
      </CarouselItem>
    ));
    const isTestButton = activeIndex === pages.page2 && isBillingExportConfiguration(azureSelectedConfiguration);
    return (
      <>
        <Container className="new-user-container" style={{ width: 'calc(100% - 220px)' }}>
          <Row className="page-title-wrapper">
            <Col className="page-title">
              <h3>{eaToMCAMigration ? 'Migrate your EA account to EA Billing Export' : PageNames.AZURE_ON_BOARDING}</h3>
            </Col>
          </Row>
          <SideBarNavigationTimeline
            activeIndex={activeIndex}
            length={this.dynamicItems.length}
            cloudProviderName="AZURE"
            steps={steps}
          />
          <Card className="wizrd-carousel-item-container">
            <CardBody>
              <Row>
                <Col xs={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }} xl={{ size: 12 }}>
                  <AlertError
                    text="Select Azure integration"
                    withCloseIcon
                    toShow={showNoSelectedIntegrationConfigWarning}
                    updateAlertClosed={this.updateAlertClosed}
                  />
                  <AlertError
                    text="Check your app credentials and try again"
                    withCloseIcon
                    toShow={isAppCredentialsTestError}
                    updateAlertClosed={this.updateAlertClosed}
                  />
                  <AlertError
                    text="Please fill in the below fields to proceed"
                    withCloseIcon
                    toShow={showNoInputValueWarning}
                    updateAlertClosed={this.updateAlertClosed}
                  />
                  <AlertError
                    text="There was an error, please try again later"
                    withCloseIcon
                    toShow={isSaveDataError}
                    updateAlertClosed={this.updateDataErrorClosed}
                  />
                  <AlertGoToNext
                    toShow={
                      showGoToNextAlert && (activeIndex === pages.page2 || activeIndex === pages.page4_getUserInfo)
                    }
                    steps={this.dynamicItems.length - 2 - activeIndex}
                    updateAlertClosed={this.updateAlertClosed}
                  />
                  {isSavingData && <Spinner />}
                  <Carousel
                    interval={0}
                    autoPlay={false}
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                  >
                    {slides}
                    <CarouselControl className="control-hidden" direction="prev" directionText="Previous" />
                    <CarouselControl className="control-hidden" direction="next" directionText="Next" />
                  </Carousel>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {activeIndex === pages.page1 && !azureSelectedConfiguration ? null : (
            <CarouselPageNavigator
              previous={this.previous}
              next={this.next}
              hideBackBtn={activeIndex === pages.page2 && eaToMCAMigration}
              activeIndex={activeIndex}
              lastPage={this.dynamicItems.length - 1}
              closeModal={this.closeModal}
              linkedAccounts={linkedAccounts}
              userType={userType}
              isTestButton={isTestButton}
            />
          )}
        </Container>
      </>
    );
  }
}

const ObserverNewUserWizard = withRouter(observer(NewAzureEaUserWizard));
export default ObserverNewUserWizard;
