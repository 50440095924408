/* eslint-disable no-tabs */
/* eslint-disable indent */
/* eslint-disable no-unused-vars,arrow-body-style */

const apiReqHeaders = {
  createHeaders: () => {},
  setCreateHeadersFunc: (func) => {
    apiReqHeaders.createHeaders = func;
  },
};

const local = {
  env: 'localDev',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'http://127.0.0.1:8080',
  },
};

const dev = {
  env: 'dev',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api.dev.mypileus.io',
  },
};

const staging = {
  env: 'staging',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api.staging.mypileus.io',
  },
};

const devBigquery = {
  env: 'dev',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api.bigquery.mypileus.io',
  },
};

const redshiftClusters = {
  env: 'dev',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api-uicluster.dev.mypileus.io',
  },
};

const prod = {
  env: 'prod',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api-front.mypileus.io',
    LEGACY_URL: 'https://api.mypileus.io',
  },
};

let config;
switch (process.env.REACT_APP_STAGE) {
  case 'local':
    config = local;
    break;
  case 'dev':
    config = dev;
    break;
  case 'staging':
    config = staging;
    break;
  case 'bigquery':
    config = devBigquery;
    break;
  case 'prod':
    config = prod;
    break;
  case 'redshift-clusters':
    config = redshiftClusters;
    break;
  default:
    config = dev; // ##default##
}

export default {
  // common config values here
  apiReqHeaders,
  mock: local,
  ...config,
};
