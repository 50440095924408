import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { Chip, Collapse, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { CUSTOM_TAGS_NOT_TAGGED } from 'shared/constants/awsConstants';
import FieldSelect from './FieldSelect';
import ValueSelect from './ValueSelect';
import OperatorSelect from './OperatorSelect';
import styles from './FilterSelector.module.scss';

const FiltersSelector = ({
  currDispUserCloudAccountType,
  filtersList,
  filterProps,
  filterDisabledText,
  handleChange,
  handleRemove,
  handleCollapse,
  collapsed,
  handleDuplicate,
  filter,
  index,
}) => {
  const [moreExpanded, setMoreExpanded] = useState(false);
  const { values, operator, field, prefix, ruleId, index: filterIndex } = filter || {};
  const { isAutoComplete, subMenu, showNotAllocated } = filterProps[field] || {};
  const blockValuesSelect = showNotAllocated && prefix === CUSTOM_TAGS_NOT_TAGGED;
  return (
    <div>
      {filterDisabledText && (
        <>
          <div className={styles.filterDisabled}>
            <Typography className="d-flex align-items-center disabled-tooltip">{filterDisabledText}</Typography>
          </div>
          <div className={styles.filterDisabledBlock} />
        </>
      )}
      <div className={styles.filterTitle}>
        <div className={styles.filterName}>Condition #{index + 1}</div>
        <div className={styles.filterTitleMenu}>
          <IconButton onClick={handleCollapse} size="large">
            <GenerateIcon iconName={!collapsed ? ICONS.arrowsFromLine.name : ICONS.arrowsToLine.name} />
          </IconButton>
          <IconButton onClick={handleDuplicate} size="large">
            <GenerateIcon iconName={ICONS.clone.name} />
          </IconButton>
          <IconButton onClick={handleRemove} size="large">
            <GenerateIcon iconName={ICONS.delete.name} />
          </IconButton>
        </div>
      </div>
      <Collapse in={!collapsed}>
        <div className="mt-2 pb-3">
          <div className="d-flex mt-2">
            <FieldSelect
              filtersList={filtersList}
              filterProps={filterProps}
              value={filter}
              handleChange={handleChange}
              currDispUserCloudAccountType={currDispUserCloudAccountType}
            />
          </div>
          {field && !blockValuesSelect && (!isAutoComplete || prefix || !subMenu) ? (
            <div className={styles.operatorAndValueLine}>
              <div style={{ minWidth: 140 }} className={styles.operatorSelect}>
                <OperatorSelect filterProps={filterProps} value={filter} handleChange={handleChange} />
              </div>
              <ValueSelect
                value={filter}
                filterProps={filterProps}
                filtersList={filtersList}
                handleChange={handleChange}
                currDispUserCloudAccountType={currDispUserCloudAccountType}
              />
            </div>
          ) : null}
          {!isEmptyArray(values) && (
            <div className={styles.valuesContainer}>
              {(moreExpanded ? values : values.slice(0, 7)).map(({ label, value }, ind1) => (
                <Chip
                  key={value}
                  label={label}
                  className={styles.chipComponent}
                  onDelete={() => {
                    handleChange({
                      values: values.filter((v, ind2) => ind1 !== ind2),
                      operator,
                      field,
                      prefix,
                      ruleId,
                      index: filterIndex,
                    });
                  }}
                />
              ))}
              {values.length > 7 ? (
                <>
                  {!moreExpanded && (
                    <Chip
                      label={`And ${values.length - 7} more`}
                      className={styles.chipComponent}
                      onClick={() => setMoreExpanded(true)}
                    />
                  )}
                  {moreExpanded && (
                    <Chip label="collapse" className={styles.chipComponent} onClick={() => setMoreExpanded(false)} />
                  )}
                </>
              ) : null}
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

FiltersSelector.propTypes = {
  currDispUserCloudAccountType: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  filterDisabledText: PropTypes.string.isRequired,
  filtersList: PropTypes.object.isRequired,
  filterProps: PropTypes.object.isRequired,
  collapsed: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleCollapse: PropTypes.func.isRequired,
  handleDuplicate: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    values: PropTypes.array.isRequired,
    field: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    ruleId: PropTypes.number,
    index: PropTypes.number,
    prefix: PropTypes.string,
  }).isRequired,
};

export default FiltersSelector;
