/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
// eslint-disable-next-line no-unused-vars
// import { decorate, observable, runInAction } from 'mobx';;
import User from './user';
import Account from './account';

export default class SubUser extends User {
  constructor(args) {
    super(args);
    this.childs = [];
    this.accounts = this.setUserAccounts(args.accounts);
  }

  setUserAccounts = (accounts) => {
    if (accounts) {
      const arrOfAccounst = accounts.map((acc) => new Account(acc));
      return arrOfAccounst;
    }
    return [];
  };
}
