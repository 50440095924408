/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import { action, makeObservable, observable, runInAction } from 'mobx';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import { buildWhereParams } from 'shared/utils/apiUtil';
import DateFilter from 'shared/modules/dateFilter';

import moment from 'moment';

export default class BigQueryModel {
  constructor(apiGateway) {
    this.apiGateway = apiGateway;
    this.bigQueryData = null;
    this.bigQueryTableData = null;
    this.bigQueryTableDataLastId = 'start';
    this.bigQueryTableDataLastTotalCost = null;
    this.bigQueryDistinctValues = [];
    this.isLoading = false;
    makeObservable(this, {
      fetchBigQueryData: action,
      fetchBigQueryTableData: action,
      fetchData: action,
      fetchBigQueryDistinctValues: action,
      isLoading: observable,
      bigQueryData: observable,
      bigQueryDistinctValues: observable,
      bigQueryTableData: observable,
    });
  }

  invalidateData = () => {
    this.bigQueryData = null;
    this.bigQueryDistinctValues = [];
  };

  fetchData = async () => {
    const { startDate, endDate } = buildStartAndEndDate(null, null);
    try {
      this.isLoading = true;
      const bigQueryData = await this.apiGateway.getBigQueryData(startDate, endDate);
      runInAction(() => {
        this.bigQueryData = bigQueryData;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
      });
    }

    return this.bigQueryData;
  };

  fetchBigQueryDistinctValues = async () => {
    try {
      this.isLoading = true;
      const bigQueryDistinctValues = await this.apiGateway.getBigQueryDistinctValues();
      runInAction(() => {
        this.bigQueryDistinctValues = bigQueryDistinctValues;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
      });
    }

    return this.bigQueryDistinctValues;
  };

  fetchBigQueryData = async (selectedStartDate, selectedEndDate, fieldToFilteredValuesMap, pageSize = 5) => {
    let startDate;
    let endDate;
    selectedStartDate
      ? (startDate = moment(selectedStartDate).format('YYYY-MM-DD'))
      : ({ startDate } = buildStartAndEndDate(selectedStartDate, selectedEndDate));
    selectedEndDate
      ? (endDate = moment(selectedEndDate).format('YYYY-MM-DD'))
      : ({ endDate } = buildStartAndEndDate(selectedStartDate, selectedEndDate));
    const whereParams = buildWhereParams(fieldToFilteredValuesMap);
    try {
      this.isLoading = true;
      this.fetchBigQueryTableData(startDate, endDate, fieldToFilteredValuesMap);
      const bigQueryData = await this.apiGateway.getBigQueryData(startDate, endDate, whereParams);
      runInAction(() => {
        this.bigQueryData = bigQueryData;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
      });
    }
    return this.bigQueryData;
  };
  fetchBigQueryTableData = async (startDate = null, endDate = null, fieldToFilteredValuesMap = null) => {
    const lastProcessTime = DateFilter.getDate();
    const startDateForQuery = startDate
      ? moment(startDate).format('YYYY-MM-DD 00:00:00')
      : moment(lastProcessTime).subtract(30, 'days').format('YYYY-MM-DD 00:00:00');
    const endDateForQuery = endDate
      ? moment(endDate).format('YYYY-MM-DD 23:59:59')
      : moment(lastProcessTime).format('YYYY-MM-DD 23:59:59');
    let rawBigQueryTableData = [];
    const whereParams = buildWhereParams(fieldToFilteredValuesMap);
    const bigQueryTableDataLastId = this.bigQueryTableDataLastId === 'start' ? 0 : this.bigQueryTableDataLastId;
    try {
      const data = await this.apiGateway.getBigQueryTableData(
        bigQueryTableDataLastId,
        this.bigQueryTableDataLastTotalCost,
        startDateForQuery,
        endDateForQuery,
        whereParams,
      );
      rawBigQueryTableData = data;
      runInAction(() => {
        this.addBigQueryTableData(rawBigQueryTableData);
        if (this.bigQueryTableDataLastId === 'start' || !this.bigQueryTableDataLastId) {
          this.setBigQueryTableDataLastPageKey(rawBigQueryTableData[rawBigQueryTableData.length - 1].id);
        }
        this.bigQueryTableDataLastId = this.setBigQueryTableDataLastId(data);
        this.bigQueryTableDataLastTotalCost = this.setBigQueryTableDataLastTotalCost(data);
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
        if (rawBigQueryTableData) {
          this.addBigQueryTableData(rawBigQueryTableData);
        }
      });
    }
  };

  invalidateBigQueryTableData = () => {
    this.bigQueryTableDataLastId = 'start';
    this.bigQueryTableDataLastTotalCost = null;
    this.bigQueryTableData = null;
  };

  setBigQueryTableDataLastId = (data) => {
    if (data.length < 4000) {
      // The limit of query in the App server setted up as 4000
      return null;
    }
    const lastDataIdx = data.length - 1;
    return data[lastDataIdx].id;
  };
  setBigQueryTableDataLastTotalCost = (data) => {
    const lastDataIdx = data.length - 1;
    return data[lastDataIdx].totalCost;
  };

  setBigQueryTableDataLastPageKey = (val) => {
    this.bigQueryTableDataLastId = val;
  };

  addBigQueryTableData = (data) => {
    if (this.bigQueryTableDataLastId === 'start') {
      this.bigQueryTableData = [...data];
    } else {
      this.bigQueryTableData = [...this.bigQueryTableData, ...data];
    }
  };
}
