/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { withStyles } from '@mui/styles';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import ViewsActionButton from './ViewsActionButton';

const SubViewPanel = (props) => {
  const userActionsFormatter = (data, usersStore, handlers) => {
    withStyles(data);
    return (
      <ReadOnlyDisplayWrapper userReadOnly={usersStore.currentUserReadOnly}>
        <ViewsActionButton row={data} handlers={handlers} />
      </ReadOnlyDisplayWrapper>
    );
  };

  const { subView } = props;
  const { name, uuid, ...rest } = subView;
  const valuesKeys = Object.keys(rest);
  return (
    <div className="view-sub-view-panel">
      <Col xl={props.isActionsButton ? 3 : 2} lg={props.isActionsButton ? 3 : 2}>
        <h5>{name}</h5>
      </Col>
      <Row className="inner-panel-values">
        {/* <Row style={{ width: '100%', alignItems: 'center' }}> */}
        {valuesKeys.map((key) => (
          <Col style={{ display: 'flex' }} xl={props.isActionsButton ? 10 : 12} lg={props.isActionsButton ? 10 : 12}>
            <b style={{ marginInlineEnd: '5px' }}>{`${props.keysToDisplayMap.get(key)}: `}</b>
            <span style={{ display: props.isBlock ? 'block' : '' }}>
              {`${typeof subView[key] === 'object' ? subView[key].join(', ') : subView[key]}`}
            </span>
          </Col>
        ))}
        {props.isActionsButton && (
          <Col xl={2} lg={2}>
            {userActionsFormatter(subView, props.usersStore, props.handlers)}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default SubViewPanel;
SubViewPanel.propTypes = {
  usersStore: PropTypes.object.isRequired,
  subView: PropTypes.object.isRequired,
  keysToDisplayMap: PropTypes.object.isRequired,
  handlers: PropTypes.object.isRequired,
  isBlock: PropTypes.bool.isRequired,
  isActionsButton: PropTypes.bool.isRequired,
};
