import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import { BASE_PROPERTIES, COST_PROPERTIES, ENGINE_PROPERTIES, RDS_PROPERTIES } from '../../recommendationPropertyUtils';
import RecommendationOptionsPanel from '../../recommendationGenericComponents/recommendationOptionsPanel';
import RecommendationCommand, { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationOptionsContext from '../../recommendationGenericComponents/recommendationOptionsContext';
import RecommendationChartData, {
  CHART_TYPES,
} from '../../recommendationGenericComponents/recommendationChart/recommendationChartData';
import { Y_AXIS_UNIT } from '../../recommendationGenericComponents/recommendationChart/chartConsts';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';

const RDS_CLASS_CHANGE_PROPERTIES = {
  MAX_CPU: {
    label: 'Max CPU Utilization (%)',
    isPercent: true,
    getterFunction: (recommendation) => recommendation.recData?.cpu_max,
  },
  CPU_95: {
    label: '95PCT CPU Utilization (%)',
    isPercent: true,
    getterFunction: (recommendation) => recommendation.recData?.cpu_p95,
  },
  MAX_MEM: {
    label: 'Max Mem Utilization (%)',
    isPercent: true,
    getterFunction: (recommendation) => recommendation.recData?.mem_max,
  },
  MEM_95: {
    label: '95PCT Mem Utilization (%)',
    isPercent: true,
    getterFunction: (recommendation) => recommendation.recData?.mem_p95,
  },
  MAX_IOPS: {
    label: 'Max IOPS',
    getterFunction: (recommendation) => recommendation.recData?.max_iops,
  },
};

const RdsClassChange = ({ recommendation }) => {
  const properties = {
    ...RDS_PROPERTIES,
    ...RDS_CLASS_CHANGE_PROPERTIES,
    ...COST_PROPERTIES,
    ...ENGINE_PROPERTIES,
    ...BASE_PROPERTIES,
  };

  const RECOMMENDATION_OPTION_DATA = [
    properties.V_CPU,
    properties.MEMORY,
    properties.STORAGE,
    properties.PHYSICAL_PROCESSOR,
    properties.RI_COVERAGE,
    properties.MULTI_AZ,
  ];

  const CURRENT_PROPERTIES = [
    properties.CURRENT_INSTANCE_TYPE,
    properties.CURRENT_INSTANCE_FAMILY,
    properties.CURRENT_INSTANCE_TYPE_FAMILY,
    ...RECOMMENDATION_OPTION_DATA,
    properties.CURRENT_ANNUAL_COST,
  ];

  const ALTERNATIVE_PROPERTIES = [
    properties.RECOMMENDED_INSTANCE_TYPE,
    properties.RECOMMENDED_INSTANCE_FAMILY,
    properties.RECOMMENDED_INSTANCE_TYPE_FAMILY,
    ...RECOMMENDATION_OPTION_DATA,
    properties.RECOMMENDED_ANNUAL_COST,
    properties.POTENTIAL_SAVINGS,
  ];

  const topThreeAlternatives = recommendation?.recData?.alternatives?.slice(0, 3);

  const { selectedOptionIndex } = useContext(RecommendationOptionsContext);

  const chartsData = [
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'IOPS Performance',
      yAxisLabel: 'IOPS',
      maxDataProperty: recommendation.recData?.iops_max_data,
      avgDataProperty: recommendation.recData?.iops_avg_data,
      percentileDataProperty: recommendation.recData?.iops_p95_data,
    },
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'CPU Performance',
      yAxisLabel: 'CPU',
      yAxisUnit: Y_AXIS_UNIT.PERCENT,
      maxDataProperty: recommendation.recData?.cpu_max_data,
      avgDataProperty: recommendation.recData?.cpu_avg_data,
      estimatedDataProperty:
        selectedOptionIndex >= 0
          ? recommendation.recData?.alternatives[selectedOptionIndex]?.cpu_max_estimated
          : recommendation.recData?.cpu_max_estimated,
      percentileDataProperty: recommendation.recData?.cpu_p95_data,
      percentile99DataProperty: recommendation.recData?.cpu_p99_data,
    },
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'Memory Performance',
      yAxisLabel: 'Memory',
      yAxisUnit: Y_AXIS_UNIT.PERCENT,
      maxDataProperty: recommendation.recData?.mem_max_data,
      avgDataProperty: recommendation.recData?.mem_avg_data,
      estimatedDataProperty:
        selectedOptionIndex >= 0
          ? recommendation.recData?.alternatives[selectedOptionIndex]?.mem_max_utilization_estimated
          : recommendation.recData?.mem_max_utilization_estimated,
      percentileDataProperty: recommendation.recData?.mem_p95_data,
    },
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'Network Performance',
      yAxisLabel: 'Network I/O (MB)',
      yAxisUnit: Y_AXIS_UNIT.BYTE,
      maxDataProperty: recommendation.recData?.network_max_data,
      avgDataProperty: recommendation.recData?.network_avg_data,
      percentileDataProperty: recommendation.recData?.network_p95_data,
    },
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'Throughput Performance',
      yAxisLabel: 'Throughput',
      yAxisUnit: Y_AXIS_UNIT.BYTE,
      maxDataProperty: recommendation.recData?.throughput_max_data,
      avgDataProperty: recommendation.recData?.throughput_avg_data,
      percentileDataProperty: recommendation.recData?.throughput_p95_data,
    },
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'DB Connection Performance',
      yAxisLabel: 'DB Connections',
      maxDataProperty: recommendation.recData?.db_connections_max_data,
      avgDataProperty: recommendation.recData?.db_connections_avg_data,
      percentileDataProperty: recommendation.recData?.db_connections_p95_data,
    },
  ];

  const description = 'We recommend you to change this RDS type to reduce costs and to suit your performance needs.';

  const insideLookComponent = <RecommendationInsideLook description={description} />;

  const otherResourceIdRecComponent = recommendation.resourceId ? (
    <OtherResourceIdRecommendations resourceId={recommendation.resourceId} />
  ) : null;

  const optionsPanelComponent =
    Array.isArray(topThreeAlternatives) && topThreeAlternatives.length ? (
      <RecommendationOptionsPanel
        alternatives={topThreeAlternatives}
        currentProperties={CURRENT_PROPERTIES}
        alternativeProperties={ALTERNATIVE_PROPERTIES}
        recommendation={recommendation}
      />
    ) : null;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const actionCommand =
    selectedOptionIndex >= 0 && recommendation?.recData?.alternatives[selectedOptionIndex]?.command
      ? recommendation?.recData.alternatives[selectedOptionIndex].command
      : recommendation?.recData.command;

  const actionCommandComment =
    selectedOptionIndex >= 0 && recommendation?.recData?.alternatives[selectedOptionIndex]?.command_comment
      ? recommendation?.recData.alternatives[selectedOptionIndex].command_comment
      : recommendation?.recData.command_comment;

  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      comment: actionCommandComment,
      instructions: [
        {
          actionText: '',
          actionCommand,
        },
      ],
    },
  ];

  const commandComponent = <RecommendationCommand commandsList={commandsList} />;

  const chartsComponent = <RecommendationChartData chartsData={chartsData} />;

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      otherResourceIdRecommendations={otherResourceIdRecComponent}
      optionsPanel={optionsPanelComponent}
      details={detailsComponent}
      command={commandComponent}
      charts={chartsComponent}
      preferences={preferencesComponent}
    />
  );
};

RdsClassChange.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default RdsClassChange;
