import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import HeatMapCard from 'recommendationsNew/components/heatMap/heatMapCards/heatMapCard';
import useHeatMap from 'recommendationsNew/hooks/react-query/useHeatMap';
import Spinner from 'shared/components/andtComponents/Spinner';
import HeatMapContext from 'recommendationsNew/components/heatMap/contexts/heatMapContext';
import RecommendationsNewContext from 'recommendationsNew/contexts/recommendationsNewContext';
import { GROUP_BY_OPTIONS } from 'recommendationsNew/consts';
import useGroupByOption from 'recommendationsNew/hooks/react-query/useGroupByOption';
import useHeatMapSummary from 'recommendationsNew/hooks/react-query/useHeatMapSummary';

import classes from '../heatMap.module.scss';

const HeatMapCardList = ({ onCardClose }) => {
  const { heatMapSortByOption: sortByContext, heatMapGroupByOptions } = useContext(HeatMapContext);
  const { recommendationFilters: filtersContext } = useContext(RecommendationsNewContext);

  const cardListGroupByOptions = useMemo(() => {
    if (!heatMapGroupByOptions?.length) {
      return null;
    }
    const tmpOptions = heatMapGroupByOptions?.filter((property) => property.default === true);
    const index = tmpOptions.findIndex((o) => o.id === GROUP_BY_OPTIONS.items.TYPE_ID.id);
    if (index !== -1) {
      const option = tmpOptions[index];
      tmpOptions.splice(index, 1);
      tmpOptions.unshift({ ...option, isPinned: true });
    }
    return tmpOptions;
  }, [heatMapGroupByOptions]);

  const heatMapHook = useHeatMap();
  const { data: heatMapData } = heatMapHook.fetchRecommendationsHeatMap({
    filters: filtersContext,
    groupBy: cardListGroupByOptions?.map((i) => i.id),
    sortBy: sortByContext,
  });

  const recommendationsGroupTypeHook = useGroupByOption();
  const { data: recommendationsGroupTypeData } = recommendationsGroupTypeHook.fetchGroupByOption({
    filters: filtersContext,
    groupBy: GROUP_BY_OPTIONS.items.TYPE_ID.id,
    pageSize: 100,
    sortBy: sortByContext,
  });

  const heatmapSummaryHook = useHeatMapSummary();
  const { data: summary } = heatmapSummaryHook.fetchHeatMapSummary(filtersContext);

  const finalHeatMapData = useMemo(() => {
    if (heatMapData && recommendationsGroupTypeData) {
      return { ...heatMapData, [GROUP_BY_OPTIONS.items.TYPE_ID.id]: recommendationsGroupTypeData };
    }
    return null;
  }, [heatMapData, recommendationsGroupTypeData]);

  return summary && finalHeatMapData ? (
    <div className={classes.heatMapCardsContainer}>
      <div className={classes.cardsList}>
        {cardListGroupByOptions.map((option) => (
          <HeatMapCard
            allOpenRecsData={summary?.allOpenRecs}
            groupByOption={option}
            isLastPage={finalHeatMapData[option.id].isLastPage}
            onCardClose={onCardClose}
            optionData={finalHeatMapData[option.id].page}
            sortBy={sortByContext}
          />
        ))}
      </div>
    </div>
  ) : (
    <div className="position-relative">
      <Spinner />
    </div>
  );
};

HeatMapCardList.propTypes = {
  onCardClose: PropTypes.func,
};

HeatMapCardList.defaultProps = {
  onCardClose: () => {},
};

export default HeatMapCardList;
