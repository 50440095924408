import { renewToken } from 'users/api/usersApi';

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const setLocalStorage = (key, value, isUseSessionStorage = false) => {
  if (isUseSessionStorage) {
    window.sessionStorage.setItem(key, value);
  } else {
    window.localStorage.setItem(key, value);
  }
};

const getSessionTimeLeft = () => {
  try {
    const authToken = window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken');
    const authTokenData = authToken.split('.')[1];
    const userData = JSON.parse(window.atob(authTokenData));
    const exp = userData.exp * 1000; // seconds to milliseconds
    const now = Date.now();
    return exp - now;
  } catch {
    return 0;
  }
};

export const checkIfTokenRefreshNeeded = async () => {
  const refreshToken = window.sessionStorage.getItem('refreshToken') || window.localStorage.getItem('refreshToken');
  const username = window.localStorage.getItem('username');
  const timeLeft = getSessionTimeLeft();
  if (username && refreshToken && timeLeft <= 0) {
    // call refresh token api
    try {
      const newToken = await renewToken(username, refreshToken);
      const { jwtToken: authToken } = newToken || {};
      const decoded = parseJwt(authToken);
      setLocalStorage('authToken', authToken, decoded['custom:useSessionStorage'] === '1');
    } catch {
      // if refresh token fails, we need to logout
      window.handleLogout();
    }
  }
  return false;
};
