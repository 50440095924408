const OnboardingLinks = {
  AWS: {
    customerType: 'https://cloudcost.anodot.com/hc/en-us/articles/10273697984668',
    onboardingType: 'https://cloudcost.anodot.com/hc/en-us/articles/10273724354076',
    onboardingTypeEventNotification: 'https://cloudcost.anodot.com/hc/en-us/articles/10273724354076-AWS-Automatic-and-Manual-Onboarding#01HQEFVPQ5E3C4BACM3Y1YR3TS',
  },
  AZURE: {
    application: 'https://cloudcost.anodot.com/hc/en-us/articles/9425408352540',
    onboardingType: 'https://cloudcost.anodot.com/hc/en-us/articles/12759538700572-Azure-Onboarding-CSP-MCA-Pay-as-you-go',
    customerType: 'https://cloudcost.anodot.com/hc/en-us/articles/12865472672668-Azure-Onboarding-for-MSP',
    eaGuide: 'https://cloudcost.anodot.com/hc/en-us/articles/9425634478748',
  },
}

export default OnboardingLinks;
