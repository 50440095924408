export const pages = {
  page0: 0,
  page1: 1,
  page2: 2,
  page3: 3,
  page4_getUserInfo: 4,
  page5_lastPage_displayed: 5,
  page6_lastPage: 6,
};

export class GCP_WIZARD_FLOW_TEXT {
  static INTRO_TITLE = 'Connect your GCP account';
  static HOW_TO_TITLE = 'Configure Account Details';
  static STEP3_TITLE = 'Configure Account APP Registration';
}

export const GcpActiveIndexToTitleText = new Map([
  [pages.page0, GCP_WIZARD_FLOW_TEXT.INTRO_TITLE],
  [pages.page1, GCP_WIZARD_FLOW_TEXT.HOW_TO_TITLE],
  [pages.page2, GCP_WIZARD_FLOW_TEXT.STEP3_TITLE],
]);
