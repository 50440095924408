import React from 'react';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import { Container } from 'reactstrap';
import HeatMap from 'recommendationsNew/components/heatMap/heatMap';
import PropTypes from 'prop-types';
import { HeatMapFiltersProvider } from 'recommendationsNew/components/heatMap/contexts/heatMapContext';
import RecommendationsList from 'recommendationsNew/components/list/recommendationsList';
import { RecommendationDetailsWidthProvider } from '../../contexts/recommendationDetailsWidthContext';

const RecommendationExplorer = (props) => {
  const { usersStore } = props;

  return (
    <TableHeaderProvider>
      <Container>
        <PageHeader showDate title={PageNames.RECOMMENDATION_EXPLORER} />
        <div className="d-flex flex-column gap-4">
          <HeatMapFiltersProvider>
            <HeatMap usersStore={usersStore} />
          </HeatMapFiltersProvider>
          <RecommendationDetailsWidthProvider>
            <RecommendationsList />
          </RecommendationDetailsWidthProvider>
        </div>
      </Container>
    </TableHeaderProvider>
  );
};

RecommendationExplorer.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

export default RecommendationExplorer;
