/* eslint-disable no-unused-vars, react/jsx-no-undef, arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@mui/styles';
import { FixedSizeList } from 'react-window';
import CheckBoxItem from 'shared/components/CheckBoxItem';
import { modifyRegionNameToDisplay } from 'shared/utils/awsUtils';

const useStyles = createStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
  },
}));

const renderRow = (props) => {
  const { index, style, data } = props;
  const item = data.preparedList[index];
  const { groupBy } = data;
  item.name = modifyRegionNameToDisplay(groupBy, item.name);
  return <CheckBoxItem style={style} ContainerProps={item} />;
};

renderRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const InfiniteList = (props) => {
  const classes = useStyles();
  const { preparedList, groupBy } = props;
  const itemData = { preparedList, groupBy };

  return (
    <div className={classes.root}>
      {/* <div > */}
      <FixedSizeList itemData={itemData} height={300} width="auto" itemSize={30} itemCount={preparedList.length}>
        {renderRow}
      </FixedSizeList>
    </div>
  );
};

InfiniteList.propTypes = {
  // handler: PropTypes.func.isRequired,
  // selectedFilters: PropTypes.object.isRequired,
  preparedList: PropTypes.array.isRequired,
  groupBy: PropTypes.string,
};

InfiniteList.defaultProps = {
  groupBy: null,
};

export default withStyles(useStyles)(InfiniteList);
