import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SORT_BY_OPTIONS } from 'recommendationsNew/consts';
import OptionsSwitchButton from 'recommendationsNew/components/heatMap/heatMapFilters/optionsSwitchButton';
import HeatMapContext from 'recommendationsNew/components/heatMap/contexts/heatMapContext';

import classes from './heatMap.module.scss';

const HeatMapLeftPanel = ({ onGroupByOptionClick }) => {
  const leftPanelLabel = 'Add Property';

  const {
    heatMapSortByOption: sortByOptionContext,
    setHeatMapSortByOption,
    heatMapGroupByOptions: groupByOptionsContext,
  } = useContext(HeatMapContext);

  const leftPanelOptions = useMemo(() => {
    if (!groupByOptionsContext?.length) {
      return null;
    }
    return groupByOptionsContext?.filter((property) => property.default === false);
  }, [groupByOptionsContext]);

  return (
    <div className={classes.leftPanel}>
      <div className={classes.groupByOptions}>
        <div className={`${classes.addPropertyLabel} ${classes.addPropertyLabel}`}>{leftPanelLabel}</div>
        <div className={classes.addPropertiesContainer}>
          {leftPanelOptions?.map((option) => (
            <div
              key={option.id}
              className={classes.addPropertyName}
              onClick={() => onGroupByOptionClick(option)}
              automation-id={`heatmap-${option.name}-left-panel`}
            >
              {option.name}
            </div>
          ))}
        </div>
      </div>
      <OptionsSwitchButton
        automation-id="heatmap-sort-by-left-panel"
        label={SORT_BY_OPTIONS.title}
        optionOne={SORT_BY_OPTIONS.items[0]}
        optionTwo={SORT_BY_OPTIONS.items[1]}
        selectedOption={sortByOptionContext}
        toggleOption={(newSortByOption) => {
          setHeatMapSortByOption(newSortByOption);
        }}
      />
    </div>
  );
};

HeatMapLeftPanel.propTypes = {
  onGroupByOptionClick: PropTypes.func.isRequired,
};

export default HeatMapLeftPanel;
