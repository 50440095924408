import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Button from 'shared/components/andtComponents/Button';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import RecommBuilderFactory from 'recommendations/models/recommBuilderFactory';
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { FilterTypes, SpAnalyzerOs, SpAnalyzerPaymentOptions, SpAnalyzerTerm } from 'usage/constants/usageConstants';
import NumericInput from 'react-numeric-input';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import AvgHourlyCostChart from 'recommendations/containers/SavingsPlans/components/AvgHourlyCostChart';
import useSavingsPlansAnalyzer from 'commitment/hooks/react-query/useSavingsPlansAnalyzer';
import SpHeatMapChart from 'recommendations/containers/SavingsPlans/components/SpHeatMapChart';
import SpDiscountTable from 'recommendations/containers/SavingsPlans/components/SpDiscountTable';
import {
  createAvgHourlyCostChartData,
  modifySpTableData,
} from 'recommendations/containers/SavingsPlans/helpers/helpers';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { useRecommendationByType } from 'recommendations/hooks/react-query/useRecommendationByType';
import useRecommendationsHistory from 'recommendations/hooks/react-query/useRecommendationsHistory';
import SavingsChart from './components/SavingsChart';
import FilterBar from './components/FilterBar';
import ContentInfo from './components/ContentInfo';
import { calcCharges, calcCoverage, calcDiscount } from './helpers/spCalculator';
import HamburgerToolbar from './components/HamburgerToolbar';
import Empty from './components/Empty';

const SavingsPlansAnalyzerPage = ({ usageStore, getPageFilters, invoiceStore, rootStore }) => {
  const [selectedOptionsMap, setSelectedOptionsMap] = useState(
    new Map([
      [AwsCommonFields.ENVIRONMENT, []],
      [AwsCommonFields.PROJECT, []],
      [AwsCommonFields.OS, []],
      [AwsCommonFields.SERVICE, []],
    ]),
  );
  const [daysToCheck, setDaysToCheck] = useState(14);
  const [coveragePercent, setCoveragePercent] = useState(90);
  const [filterStatusTypeMap, setFilterStatusTypeMap] = useState(
    new Map([
      [AwsCommonFields.ENVIRONMENT, FilterTypes.INCLUDE],
      [AwsCommonFields.PROJECT, FilterTypes.INCLUDE],
      [AwsCommonFields.SERVICE, FilterTypes.INCLUDE],
      [AwsCommonFields.OS, FilterTypes.INCLUDE],
      [AwsCommonFields.INSTANCE_TYPE, FilterTypes.INCLUDE],
      [AwsCommonFields.LINKED_ACCOUNT_ID, FilterTypes.INCLUDE],
    ]),
  );
  const [selectedPaymentOption, setSelectedPaymentOption] = useState({
    label: SpAnalyzerPaymentOptions.PARTIAL_UPFRONT,
    value: SpAnalyzerPaymentOptions.PARTIAL_UPFRONT,
  });
  const [selectedTerm, setSelectedTerm] = useState({
    label: SpAnalyzerTerm.ONE_YEAR,
    value: SpAnalyzerTerm.ONE_YEAR,
  });
  // eslint-disable-next-line no-unused-vars
  const [selectedOs, setSelectedOs] = useState({
    label: SpAnalyzerOs[0],
    value: SpAnalyzerOs[0],
  });
  const [presentedPaymentOption, setPresentedPaymentOption] = useState(SpAnalyzerPaymentOptions.PARTIAL_UPFRONT);
  const [presentedTerm, setPresentedTerm] = useState(SpAnalyzerTerm.ONE_YEAR);
  const [presentedCoveragePercent, setPresentedCoveragePercent] = useState(90);
  const [hourlyCommitmentManualOverride, setHourlyCommitmentManualOverride] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [rec, setRec] = useState(null);
  const { fetchSavingsPlansAnalyzerData } = useSavingsPlansAnalyzer();
  const {
    data: savingsPlansData,
    isLoading,
    isError,
    refetch,
  } = fetchSavingsPlansAnalyzerData(
    selectedOptionsMap,
    daysToCheck,
    filterStatusTypeMap,
    selectedPaymentOption.value.toLowerCase(),
    selectedTerm.value.toLowerCase(),
    coveragePercent,
    hourlyCommitmentManualOverride,
  );
  const { getCurrencyNumber } = useUserSettingsContext();
  const getAnalyzedSavingsPlanData = async () => {
    setHourlyCommitmentManualOverride(null);
    await refetch();
    setPresentedPaymentOption(selectedPaymentOption.value);
    setPresentedTerm(selectedTerm.value);
    setPresentedCoveragePercent(coveragePercent);
  };

  const selectValueHandler = (filterType, selectedOptions) => {
    const selectedOptionsMapNew = new Map(selectedOptionsMap);
    selectedOptionsMapNew.set(filterType, selectedOptions);
    setSelectedOptionsMap(selectedOptionsMapNew);
  };

  const handleChangeFilterType = (field) => {
    const filterStatusTypeMapNew = new Map(filterStatusTypeMap);
    const newFilterType =
      filterStatusTypeMapNew.get(field) === FilterTypes.INCLUDE ? FilterTypes.EXCLUDE : FilterTypes.INCLUDE;
    filterStatusTypeMapNew.set(field, newFilterType);
    setFilterStatusTypeMap(filterStatusTypeMapNew);
  };

  const handleSelectPaymentOption = (type, selectedOption) => {
    setSelectedPaymentOption(selectedOption);
  };

  const handleSelectTerm = (type, selectedTerm) => {
    setSelectedTerm(selectedTerm);
  };

  useEffect(() => {
    const recalPotentialSaving = async () => {
      await refetch();
    };
    recalPotentialSaving();
  }, [hourlyCommitmentManualOverride]);
  const changeHourlyCommitment = (newVal) => {
    if (newVal && newVal >= 0.001) {
      setHourlyCommitmentManualOverride(newVal);
    }
  };

  const prepareRec = (rawRecData) => {
    const recommBuilder = RecommBuilderFactory.create(rawRecData, RecommendationTypes.EC2_SAVINGS_PLANS);
    if (recommBuilder) {
      recommBuilder.build();
      const { recommendation } = recommBuilder;
      return recommendation;
    }
    return {};
  };

  const { currentDisplayedUserType, usersModel, currentDisplaydUser } = rootStore.usersStore;
  const { mapLinkedAccIdToDivisionName } = usersModel;

  const recommendationsHook = useRecommendationByType({
    isEnabled: currentDisplaydUser?.isPayingUser,
    recommendationType: null,
    currentDisplayedUserType,
    mapLinkedAccIdToDivisionName,
  });
  const recommendationDataHook = recommendationsHook.fetchRecommendationsByTypeData();
  const { data: recommendations } = recommendationDataHook;

  const historyHook = useRecommendationsHistory({
    limit: 'all',
  });
  const recommendationsHistoryDataHook = historyHook.fetchRecommendationsHistory();
  const recommendationsHistory = recommendationsHistoryDataHook?.data?.recommendationsHistory;

  const getRecommendationByKey = (recommendationKey) => {
    let recommendation = recommendations?.find((rec) => recommendationKey === rec.key);
    if (!recommendation) {
      recommendation = recommendationsHistory?.find((rec) => recommendationKey === rec.key);
    }
    return recommendation;
  };

  useEffect(() => {
    if (!savingsPlansData) {
      return;
    }
    const preparedRec = savingsPlansData ? prepareRec(savingsPlansData) : false;
    setRec(preparedRec);
  }, [savingsPlansData]);

  const getPreparedSavingsPlansData = () => {
    const path = window.location.pathname;
    const pathParts = path.split('/');
    const recommKey = pathParts ? pathParts[pathParts.length - 1] : null;
    if (recommKey && recommKey !== 'savings-plans-analyzer') {
      return getRecommendationByKey(recommKey);
    }
    if (savingsPlansData || toJS(savingsPlansData)) {
      return prepareRec(toJS(savingsPlansData));
    }
    return false;
  };

  const renderSavingsPlansAnalyzeData = (preparedSavingsPlansData) => {
    const discount = calcDiscount(preparedSavingsPlansData.discountTable);
    let coverage = 0.0;
    if (preparedSavingsPlansData.recommendedHourlyCommitment) {
      coverage = calcCoverage(preparedSavingsPlansData.recommendedHourlyCommitment, discount);
    } else if (preparedSavingsPlansData) {
      coverage = preparedSavingsPlansData.avgHourlyCost * (presentedCoveragePercent / 100);
    }
    const avgHourlyCostData = createAvgHourlyCostChartData(
      preparedSavingsPlansData,
      preparedSavingsPlansData.recommendedHourlyCommitment,
      coverage,
    );

    const { dataArr, maxValue } = modifySpTableData(preparedSavingsPlansData);
    if (!savingsPlansData || !preparedSavingsPlansData) {
      return <Empty />;
    }
    if (
      preparedSavingsPlansData.discountTable &&
      preparedSavingsPlansData.type === RecommendationTypes.EC2_SAVINGS_PLANS
    ) {
      return (
        <div>
          <hr />
          <div>
            <Row style={{ justifyContent: 'center' }}>
              <Col xs={12} md={12} lg={6} xl="auto" className="sp-analyzer__content-col">
                <ContentInfo title="Savings Plan Type" value={preparedSavingsPlansData.savingsPlanType} />
              </Col>
              <Col xs={12} md={12} lg={6} xl="auto" className="sp-analyzer__content-col">
                <ContentInfo
                  title="Hourly Commitment"
                  value={preparedSavingsPlansData.recommendedHourlyCommitment}
                  isEditable
                  editFunc={changeHourlyCommitment}
                  isCost
                />
              </Col>
              <Col xs={12} md={12} lg={12} xl="auto" className="sp-analyzer__content-col chart">
                <SavingsChart
                  percentages={preparedSavingsPlansData.potentialSavings}
                  currCost={preparedSavingsPlansData.currTotalCost}
                  potentialCost={preparedSavingsPlansData.recommTotalCost}
                />
              </Col>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              <Col xs={12} md={12} lg={6} xl="auto" className="sp-analyzer__content-col">
                <ContentInfo title="Term" value={preparedSavingsPlansData.term} size={16} small />
              </Col>
              <Col xs={12} md={12} lg={6} xl="auto" className="sp-analyzer__content-col">
                <ContentInfo
                  title="Upfront Cost"
                  value={getCurrencyNumber(
                    calcCharges(
                      preparedSavingsPlansData.recommendedHourlyCommitment,
                      presentedPaymentOption,
                      presentedTerm,
                    ).recommendedUpfrontPayment,
                    0,
                    { roundNumber: true },
                  )}
                  small
                />
              </Col>
              <Col xs={12} md={12} lg={6} xl="auto" className="sp-analyzer__content-col">
                <ContentInfo
                  title="Monthly Recurring Charges"
                  value={getCurrencyNumber(
                    calcCharges(
                      preparedSavingsPlansData.recommendedHourlyCommitment,
                      presentedPaymentOption,
                      presentedTerm,
                    ).monthlyRecurringCharges,
                    0,
                    { roundNumber: true },
                  )}
                  small
                />
              </Col>
              <Col xs={12} md={12} lg={6} xl="auto" className="sp-analyzer__content-col">
                <ContentInfo
                  title="Total Commitment"
                  value={getCurrencyNumber(
                    calcCharges(
                      preparedSavingsPlansData.recommendedHourlyCommitment,
                      presentedPaymentOption,
                      presentedTerm,
                    ).totalYearCommitment,
                    0,
                    { roundNumber: true },
                  )}
                  small
                />
              </Col>
            </Row>
          </div>
          <hr />
          <div className="sp-analyzer__charts-container">
            <div style={{ minWidth: '500px' }}>
              <br />
              <div style={{ width: '100%', marginRight: '30px' }}>
                <p className="project-summary__statistic-title">Average Compute Cost Hourly</p>
                <div className="d-flex flex-row justify-content-end w-95">
                  <HamburgerToolbar data={avgHourlyCostData} />
                </div>
                <div id="avg-compute-costy-hourly">
                  <AvgHourlyCostChart
                    data={avgHourlyCostData}
                    selectedHourlyCost={preparedSavingsPlansData.recommendedHourlyCommitment}
                  />
                </div>
                <br />
              </div>
            </div>
            <div className="project-summary__statistic_heatmap" style={{ marginTop: '30px' }}>
              <p className="project-summary__statistic-title" />
              <br />
              <SpHeatMapChart
                series={dataArr}
                maxValue={maxValue}
                selectedHourlyCost={preparedSavingsPlansData.recommendedHourlyCommitment}
              />
            </div>
          </div>
          <Row>
            <SpDiscountTable data={preparedSavingsPlansData.discountTable} />
          </Row>
        </div>
      );
    }
    return <Empty />;
  };

  const preparedSavingsPlansData = getPreparedSavingsPlansData();
  const spDistincValuesMap = getPageFilters(
    PageNames.SAVINGS_PLANS_ANALYZER,
    rootStore.usersStore.currDispUserCloudAccountType,
  );

  if (usageStore.isLoading || invoiceStore.isLoading) {
    return (
      <>
        <div className="spLoadingAnimation" style={{ paddingBottom: '40px' }}>
          <Spinner />
        </div>
      </>
    );
  }

  return (
    <Container>
      <PageHeader title={PageNames.SAVINGS_PLANS_ANALYZER} />
      <Card>
        <CardBody>
          <div className="filters-collapse-container" style={{ padding: '30px' }}>
            <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FilterBar
                fieldToFieldDistincValuesMap={spDistincValuesMap}
                selectedOptionsMap={selectedOptionsMap}
                handleFilterChange={selectValueHandler}
                filterStatusTypeMap={filterStatusTypeMap}
                handleChangeFilterType={handleChangeFilterType}
              />
            </Col>
            <Col style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ marginLeft: '0px' }} className="filter-bar-inner-col-wrapper full-width">
                <h5 className="filter-bar-inner-col-title">Days to Check</h5>
                <NumericInput
                  min={1}
                  size={8}
                  value={daysToCheck}
                  onChange={(num) => {
                    if (num === 0) {
                      setDaysToCheck(1);
                    } else if (num >= 45) {
                      setDaysToCheck(45);
                    } else {
                      setDaysToCheck(num);
                    }
                  }}
                  className="form-control"
                />
              </div>
              <div style={{ marginLeft: '0px' }} className="filter-bar-inner-col-wrapper full-width">
                <h5 className="filter-bar-inner-col-title">Coverage Percentage</h5>
                <NumericInput
                  min={1}
                  max={100}
                  size={8}
                  value={coveragePercent}
                  onChange={(num) => {
                    if (num === 0) {
                      setCoveragePercent(90);
                    } else {
                      setCoveragePercent(num);
                    }
                  }}
                  className="form-control"
                />
              </div>
              <div style={{ marginLeft: '0px' }} className="filter-bar-inner-col-wrapper full-width">
                <h5 className="filter-bar-inner-col-title">Payment Option</h5>
                <OneChoiceFieldFilter
                  type="Payment Option"
                  value={selectedPaymentOption}
                  options={[
                    SpAnalyzerPaymentOptions.ALL_UPFRONT,
                    SpAnalyzerPaymentOptions.PARTIAL_UPFRONT,
                    SpAnalyzerPaymentOptions.NO_UPFRONT,
                  ]}
                  handleChange={handleSelectPaymentOption}
                  isClearable={false}
                />
              </div>
              <div style={{ marginLeft: '0px' }} className="filter-bar-inner-col-wrapper full-width">
                <h5 className="filter-bar-inner-col-title">Term</h5>
                <OneChoiceFieldFilter
                  type="Term"
                  value={selectedTerm}
                  options={Object.values(SpAnalyzerTerm)}
                  handleChange={handleSelectTerm}
                  isClearable={false}
                />
              </div>
            </Col>
            <Col>
              <Button onClick={getAnalyzedSavingsPlanData} text="Analyze" automationId="SpAnalyzer-analyze" />
            </Col>
          </div>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <div style={{ marginTop: '350px' }}>
              <Spinner />
            </div>
          ) : preparedSavingsPlansData ? (
            renderSavingsPlansAnalyzeData(preparedSavingsPlansData)
          ) : null}
          {isError && <Empty />}
        </CardBody>
      </Card>
    </Container>
  );
};

SavingsPlansAnalyzerPage.propTypes = {
  usageStore: PropTypes.object.isRequired,
  getPageFilters: PropTypes.func.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
};

const ObserverSavingsPlansAnalyzer = withInvoiceFiltersContextConsumer(observer(SavingsPlansAnalyzerPage));
export default ObserverSavingsPlansAnalyzer;
