/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const useStyles = createStyles((theme) => ({
  root: {
    background: '#4A4A4A',
    backgroundColor: '#4A4A4A',
  }, // a style rule
  MuiPaper: {
    background: '#4A4A4A',
    backgroundColor: '#4A4A4A',
  }, // a nested style rule
  MuiStepper: {
    background: '#4A4A4A',
    backgroundColor: '#4A4A4A',
  },
}));

const VerticalWizardStepper = (props) => {
  const classes = useStyles();
  const { steps } = props;

  return (
    <div className="ps-4 pt-3">
      <Stepper className={classes.MuiStepper} activeStep={props.activeIndex} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

VerticalWizardStepper.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withStyles(useStyles)(VerticalWizardStepper);
