/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import SettingsIcon from '@mui/icons-material/Settings';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import RefreshIcon from 'mdi-react/RefreshIcon';
import {
  buildStartAndEndDate,
  createDateDisplayStr,
  createTimeZoneAgnosticDateFromStr,
  dateToStr,
  parseDateFormatFromDate,
} from 'shared/utils/dateUtil';
import DateFilter from 'shared/modules/dateFilter';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import {
  countFilters,
  groupDataByUsageDate,
  prepareDataForDisplayChart,
  prepareDataForDisplayTable,
  prepareDataKeys,
} from 'shared/utils/dataPrepareUtil';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import IconTextButton from 'shared/components/buttons/IconTextButton';
import { AppCommonFields, PageNames } from 'shared/constants/appConstants';
import { AWS_QUANTITY_TYPE_SELECT, AwsCommonFields } from 'shared/constants/awsConstants';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import CostUsageSimpleSelectDropdown from 'shared/components/SimpleSelectDropdown';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import NoDataFoundComponent from 'shared/components/NoDataFoundComponent';
import IconButton from '@mui/material/IconButton';
import {
  ChartTypeDisplayTypes,
  CostTrackingConstants,
  CostTypeModes,
  CostUsageStates,
  Dimensions,
  DisplayMetricTypes,
  GROUP_BY_LOV,
  GroupByLovToAttributes,
} from 'usage/constants/costAndUsageConstants';
import LegendKeysFilterHandler from 'shared/components/ChartKeysFilter/legendKeysFilterHandler';
import FiltersSidebar from 'shared/components/FilterSidebar/FiltersSidebar';
import { formatExcludeFilterMap } from 'shared/utils/filtersUtils';
import MarginAppliedReloader from 'shared/components/MarginAppliedReloader';
import { PreferencesKeys, PreferencesTypes } from 'users/constants/usersConstants';
import FiltersSidebarContainer from 'shared/components/FilterSidebar/FiltersSidebarContainer';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { withPreferencesContextProvider } from 'users/contexts/PreferencesContext';
import { updatePreferences } from 'users/hooks/react-query/usePreferences';
import CostChart from './components/CostChart';
import CostTable from './components/CostTable';
import MetricsDataState from './helpers/stateMachine/metricsDataState';
import PrimaryFilterBar from './components/FilterBar';
import ProcessedLinkedAccountsModal from './components/ProcessedLinkedAccountsModal';

const TABLE_ID = 'cue-full-cost-table-id';

class MetricsPage extends Component {
  static propTypes = {
    preferences: PropTypes.array.isRequired,
    handleSavePreferences: PropTypes.func.isRequired,
    getPageFilters: PropTypes.func.isRequired,
    getCustomTagsKeys: PropTypes.func.isRequired,
    filtersValuesMap: PropTypes.object.isRequired,
    invoiceStore: PropTypes.object.isRequired,
    usersStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.makeBaseState = () => ({
      isAreaChart: false,
      isDisplayTable: false,
      isPieChart: false,
      isLineChart: true,
      filteredKeys: [],
      favourites: [],
      changedTableColumnWidth: [],
      isInitialDataKeyFilterLoad: true,
      currDataState: CostUsageStates.INSTANCE_TYPE,
      filterBarGroupBy: GroupByLovToAttributes.get(GROUP_BY_LOV.BY_INSTANCE_TYPE),
      filterBarGroupBySecondary: GroupByLovToAttributes.get('Date'),
      selectedFilterBarGroupBy: GroupByLovToAttributes.get(GROUP_BY_LOV.BY_INSTANCE_TYPE),
      excludedFiltersStatusMap: new Map(),
      isGroupByFiltersError: false,
      currentGroupBy: GroupByLovToAttributes.get(GROUP_BY_LOV.BY_INSTANCE_TYPE),
      selectedMetric: null,
      currPeriodGranLevel: CostTrackingConstants.GRAN_LEVEL_DAILY,
      selectedGranLevel: CostTrackingConstants.GRAN_LEVEL_DAILY,
      isDateRangeError: false,
      startDate: moment(dateToStr(DateFilter.getDate(), 'yyyy-mm-dd')).add(-6, 'day').toDate(),
      endDate: DateFilter.getDate(),
      selectedDimensions: [Dimensions.AVERAGE],
      selectedOptionsMap: new Map(),
      isApplyFiltersButtonDisabled: true,
      isFiltersOpen: false,
      periodType: 'relativeDates',
      isShowOthers: false,
      selectedPageSize: 15,
      linkedAccountsModalOpen: false,
    });
    this.state = {
      ...this.makeBaseState(),
    };
    this.mainLegendKeysFilterHandler = new LegendKeysFilterHandler(this);
    this.setKeysFilterHandler = this.mainLegendKeysFilterHandler.setKeysFilterHandler.bind(this);
    this.addKeysFilterHandler = this.mainLegendKeysFilterHandler.addKeysFilterHandler.bind(this);
    this.removeKeysFilterHandler = this.mainLegendKeysFilterHandler.removeKeysFilterHandler.bind(this);
    this.isShowOthersChangeHandler = this.mainLegendKeysFilterHandler.isShowOthersChangeHandler.bind(this);
    this.dataStates = {
      [CostUsageStates.RESOURCE]: new MetricsDataState(this, CostUsageStates.RESOURCE),
      [CostUsageStates.LINKED_ACC_ID]: new MetricsDataState(this, CostUsageStates.LINKED_ACC_ID),
      [CostUsageStates.INSTANCE_TYPE]: new MetricsDataState(this, CostUsageStates.INSTANCE_TYPE),
      [CostUsageStates.CUSTOM_TAGS]: new MetricsDataState(this, CostUsageStates.CUSTOM_TAGS),
    };
    this.dataKeyToWhereParamsMap = new Map();
    this.fieldToFilterdValuesMap = new Map();
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleApplyFiltersButtonClick = this.handleApplyFiltersButtonClick.bind(this);
    this.handleDimensionsChange = this.handleDimensionsChange.bind(this);
    this.handleTableColumnWidthChange = this.handleTableColumnWidthChange.bind(this);
    this.handleChangeGranLevel = this.handleChangeGranLevel.bind(this);
  }

  componentDidMount() {
    this.loadInitialData();
  }

  setSelectedPageSize = (size) => {
    this.setState({ selectedPageSize: size });
  };

  getFilterLabel = (field) => {
    const label = LabelCoordinator.getFieldLabel(field);
    return label;
  };

  getIsLinkedAccountsSelected = () => {
    const preference = this.getPreference() || {};
    const { linkedAccountIds = [] } = preference.preferences || {};
    return linkedAccountIds.length > 0;
  };

  getLinkedAccountsForSelect = () => {
    const { filtersValuesMap } = this.props;
    const preference = this.getPreference() || {};
    const { linkedAccountIds = [] } = preference.preferences || {};
    const allLinkedAccounts = filtersValuesMap.get('linkedaccid');
    return (allLinkedAccounts || [])
      .filter((acc) => linkedAccountIds.includes(acc.linkedAccountId))
      .map((acc) => ({
        label: acc.displayLabel,
        value: acc.linkedAccountId,
      }));
  };
  getPreference = () => {
    const { preferences } = this.props;
    return preferences.find(
      (p) => p.preferenceKey === PreferencesKeys.METRICS_FILTERS && p.preferenceType === PreferencesTypes.METRICS,
    );
  };

  loadInitialData = async () => {
    this.loadMetrics();
  };

  loadMetrics = async () => {
    const { invoiceStore } = this.props;
    const metrics = await invoiceStore.fetchMetricsTypes();
    const metricTypes = metrics.map((v) => v.name);
    if (!metricTypes.length) {
      return;
    }
    this.setState(
      () => ({
        selectedMetric: metricTypes[0],
        metrics,
      }),
      this.handleFetchData,
    );
  };

  prepareMonthlyData(baseData) {
    if (!baseData || baseData.length === 0) {
      return { modifiedDailyBalances: [], tableModifiedDailyBalance: [] };
    }
    const data = groupDataByUsageDate(baseData);
    data.sort((a, b) => a[0].usageDate.localeCompare(b[0].usageDate));
    const { modifiedDailyBalances, tableModifiedDailyBalance } = this.prepareData(data);
    return { modifiedDailyBalances, tableModifiedDailyBalance };
  }

  prepareData(data, isShowTrendRow) {
    const { isCumulative } = this.state;
    const modifiedDailyBalances = prepareDataForDisplayChart(
      data,
      AppCommonFields.USAGE_DATE,
      AppCommonFields.GROUP_BY,
      AppCommonFields.TOTAL_COST,
      LabelCoordinator.getServiceNameDisplayValue,
    );
    const tableModifiedDailyBalance = prepareDataForDisplayTable({
      data,
      entryAnchorFieldName: AppCommonFields.USAGE_DATE,
      keyFieldName: AppCommonFields.GROUP_BY,
      valueFieldName: AppCommonFields.TOTAL_COST,
      isShowTrendRow,
      isCumulative,
    });

    return { modifiedDailyBalances, tableModifiedDailyBalance };
  }

  prepareInitialDataKeys = () => {
    const baseData = this.dataStates[this.state.currDataState].getData();
    const { modifiedDailyBalances } = this.prepareMonthlyData(baseData, this.state.isTableTrendRow);
    const dataKeys = prepareDataKeys(modifiedDailyBalances, 'name', 'cost', this.state.isCumulative);
    return dataKeys;
  };

  prepareDataKeys(data) {
    if (!data) {
      return [];
    }
    const result = Object.values(
      data.reduce((a, { usageDate, ...rest }) => {
        Object.entries(rest).forEach(([k, v]) => {
          a[k] = { name: k, cost: 0 };
          a[k].cost += Number(v);
        });
        return a;
      }, {}),
    );

    result.sort((a, b) => parseFloat(a.cost) - parseFloat(b.cost)).reverse();
    return result;
  }

  formatColumnTitleDate = (column) => {
    const format = parseDateFormatFromDate(column);
    if (format) {
      return {
        name: column,
        title: createDateDisplayStr(column, format),
      };
    }
    return { name: column, title: column };
  };

  prepareTableHeaders = (data, newWidths, currGroupBy) => {
    const dataKeys = data.map((row) => Object.keys(row));
    const result = [...new Set([].concat(...dataKeys))].filter((item) => item !== 'linkedAccountId');

    const columns = this.sortColumnsByDate(result.map(this.formatColumnTitleDate));

    const totalSummaryItems = result.map((elem) => {
      if (elem === 'groupBy' || elem === 'linkedAccountId') {
        return { columnName: elem, type: 'count' };
      }
      return { columnName: elem, type: 'sum' };
    });

    const columnWidths =
      newWidths.length > 0 && newWidths.length === columns.length
        ? newWidths
        : result.map((elem) => ({
            columnName: elem,
            width: result.length > 5 ? 150 : 300,
          }));

    const tableColumnExtensions = result.map((elem) => ({
      columnName: elem,
      align: 'center',
    }));
    const indexOfGroupBy = columns.findIndex((col) => col.name === 'groupBy');
    if (indexOfGroupBy > -1) {
      const groupBy = columns.splice(indexOfGroupBy, 1)[0];
      groupBy.title = currGroupBy;
      columns.unshift(groupBy);
    }
    return { columns, columnWidths, tableColumnExtensions, totalSummaryItems };
  };

  sortColumnsByDate = (columns) =>
    columns.sort((a, b) => {
      const [aYear, aMonth, aDay] = a.name.split('-');
      const [bYear, bMonth, bDay] = b.name.split('-');
      if (!aMonth || !bMonth || !aDay || !bDay) {
        return 0;
      }
      if (aYear < bYear) {
        return 0;
      }
      if (aYear > bYear) {
        return -1;
      }
      if (aMonth > bMonth) {
        return 0;
      }
      if (aMonth < bMonth) {
        return -1;
      }
      if (aDay > bDay) {
        return 0;
      }
      return -1;
    });

  formattedDates = () => {
    const { startDate: startDateFromState, endDate: endDateFromState } = this.state;
    let { startDate, endDate } = buildStartAndEndDate(startDateFromState, endDateFromState, false);
    startDate = createTimeZoneAgnosticDateFromStr(startDate);
    endDate = createTimeZoneAgnosticDateFromStr(endDate);
    return { startDate, endDate };
  };

  buildStartAndEndDateInAgnosticDateFromStr = () => {
    let { startDate, endDate } = buildStartAndEndDate('', '');
    startDate = createTimeZoneAgnosticDateFromStr(startDate);
    endDate = createTimeZoneAgnosticDateFromStr(endDate);
    return { startDate, endDate };
  };

  handleTableColumnWidthChange = (newWidths) => {
    this.setState({ changedTableColumnWidth: newWidths });
  };
  resetFilters = () => {
    this.setState({
      filteredKeys: [],
      favourites: [],
    });
  };
  handleChangeFilterType = (field, subField) => {
    const { excludedFiltersStatusMap } = this.state;
    this.setState({
      excludedFiltersStatusMap: formatExcludeFilterMap(excludedFiltersStatusMap, field, subField),
      isApplyFiltersButtonDisabled: false,
    });
  };

  updateSelectedOptions(filterType, selectedOptions) {
    const { selectedOptionsMap } = this.state;
    const newSelectedOptionsMap = new Map(selectedOptionsMap);
    newSelectedOptionsMap.set(filterType, selectedOptions);
    this.setState({ selectedOptionsMap: newSelectedOptionsMap });
  }

  handleDateChange = ({ startDate, endDate, isDateRangeError }) => {
    const { startDate: startDateFromState, endDate: endDateFromState } = this.state;
    startDate = startDate || startDateFromState;
    endDate = endDate || endDateFromState;
    if (startDate > endDate && endDate !== null) {
      endDate = startDate;
    }

    this.setState({ startDate, endDate, isDateRangeError, isApplyFiltersButtonDisabled: false });
  };

  handleDataStatesFilterChange = (
    groupByFilter,
    selectedMetric,
    startDate,
    endDate,
    currPeriodGranLevel,
    fieldToFilterdValuesMap,
    selectedDimensions,
    excludedFiltersStatusMap,
  ) => {
    const { currDataState, metrics } = this.state;
    this.dataStates[currDataState].handleFilterChange(
      groupByFilter,
      (metrics.find((m) => m.name === selectedMetric) || {}).id,
      startDate,
      endDate,
      currPeriodGranLevel,
      fieldToFilterdValuesMap,
      selectedDimensions,
      excludedFiltersStatusMap,
    );
  };

  handleDimensionsChange = (selectedDimensions, selectedOptions) => {
    this.setState({
      selectedDimensions: selectedOptions.map((dimension) => dimension.value),
      isApplyFiltersButtonDisabled: false,
    });
  };

  handleFilterChange = (filterType, selectedOptions) => {
    let filteredValues = [];
    let selectedUsageOption = AWS_QUANTITY_TYPE_SELECT;
    if (selectedOptions) {
      if (Array.isArray(selectedOptions)) {
        filteredValues = selectedOptions.map(({ value }) => value);
      } else {
        selectedUsageOption = selectedOptions.label;
        filteredValues.push(selectedUsageOption);
      }
    }

    this.fieldToFilterdValuesMap.set(filterType, filteredValues);
    this.updateSelectedOptions(filterType, selectedOptions);
    this.setState({ isApplyFiltersButtonDisabled: false });
  };

  buildStateValue = (value, newValue, setNewVal = null) => {
    const stateNewValue = this.state[newValue];
    const obj = {};
    if (stateNewValue) {
      obj[value] = stateNewValue;
      obj[newValue] = setNewVal;
    }
    return obj;
  };
  buildStateToUpdate = () => {
    const { selectedGranLevel } = this.state;
    let state = {
      isApplyFiltersButtonDisabled: true,
      isInitialLoading: false,
    };
    const groupByFilters = this.buildStateValue('filterBarGroupBy', 'newFilterBarGroupBy');
    state.currPeriodGranLevel = selectedGranLevel;
    state = { ...state, ...groupByFilters };
    return state;
  };
  handleApplyFiltersButtonClick = () => {
    const { newFilterBarGroupBy, currDataState } = this.state;
    if (newFilterBarGroupBy) {
      this.dataStates[currDataState].handleGroupBy(newFilterBarGroupBy);
    } else {
      this.handleFetchData();
    }
    const stateToUpdate = this.buildStateToUpdate();
    this.setState(stateToUpdate);
  };

  handleGroupByChange = (groupByVal) => {
    this.setState({
      isApplyFiltersButtonDisabled: false,
      newFilterBarGroupBy: groupByVal,
    });
  };

  handleMetricChange = (metric) => {
    this.setState({
      isApplyFiltersButtonDisabled: false,
      selectedMetric: metric,
    });
  };

  handleChangeGranLevel = (granLevel) => {
    this.setState({ selectedGranLevel: granLevel, isApplyFiltersButtonDisabled: false });
  };

  handleFetchData = () => {
    const {
      currentGroupBy,
      selectedMetric,
      startDate,
      endDate,
      selectedGranLevel,
      selectedDimensions,
      excludedFiltersStatusMap,
    } = this.state;
    this.handleDataStatesFilterChange(
      currentGroupBy,
      selectedMetric,
      startDate,
      endDate,
      selectedGranLevel,
      this.fieldToFilterdValuesMap,
      selectedDimensions,
      excludedFiltersStatusMap,
    );
  };
  handleResetToInitialState = () => {
    const { selectedMetric } = this.state;
    this.dataKeyToWhereParamsMap = new Map();
    this.fieldToFilterdValuesMap = new Map();
    const newState = Object.assign({}, this.state, this.makeBaseState());
    newState.selectedMetric = selectedMetric;
    this.setState({ ...newState }, this.handleFetchData);
  };
  handleClickToTryAgain = () => {
    this.handleFetchData();
  };

  toggleChartOrTable = (barTableOrStack) => {
    this.setState({
      isDisplayTable: barTableOrStack === 'table',
      isAreaChart: barTableOrStack === 'area',
      isPieChart: barTableOrStack === 'pie',
      isLineChart: barTableOrStack === 'line',
      isCumulative: false,
    });
  };

  handleRemoveFieldFromFiltersValuesMap = (filed) => {
    this.fieldToFilterdValuesMap.delete(filed);
  };
  toggleFilters = (e) => {
    e.preventDefault();
    const { isFiltersOpen } = this.state;
    this.setState({ isFiltersOpen: !isFiltersOpen });
  };
  renderChartAndTable = (chartId) => {
    const { filteredKeys, favourites, currDataState, isDisplayTable, isInitialDataKeyFilterLoad } = this.state;
    const baseData = this.dataStates[currDataState].getData();
    const { modifiedDailyBalances, tableModifiedDailyBalance } = this.prepareMonthlyData(baseData);
    const dataKeys = this.prepareInitialDataKeys();
    const { columns, columnWidths, tableColumnExtensions, totalSummaryItems } = this.prepareTableHeaders(
      tableModifiedDailyBalance,
      this.state.changedTableColumnWidth,
      this.getFilterLabel(this.state.filterBarGroupBy),
    );
    if (!modifiedDailyBalances || !modifiedDailyBalances.length) {
      return this.renderNoDataMessage();
    }
    return !isDisplayTable
      ? this.renderChart(chartId, modifiedDailyBalances, favourites, dataKeys, filteredKeys, isInitialDataKeyFilterLoad)
      : this.renderTable(
          tableModifiedDailyBalance,
          columns,
          columnWidths,
          tableColumnExtensions,
          totalSummaryItems,
          TABLE_ID,
        );
  };

  renderTable(rows, columns, columnWidths, tableColumnExtensions, totalSummaryItems, tableId) {
    const { isTableTrendRow, currPeriodGranLevel } = this.state;
    if (rows.length > 0) {
      return (
        <CostTable
          rows={rows}
          columns={columns}
          tableId={tableId}
          renderedColumnWidths={columnWidths}
          tableColumnExtensions={tableColumnExtensions}
          displayedMetric={DisplayMetricTypes.COST}
          handleTableColumnWidthChange={this.handleTableColumnWidthChange}
          totalSummaryItems={totalSummaryItems}
          isTableTrendRow={isTableTrendRow}
          setSelectedPageSize={this.setSelectedPageSize}
          isGranLevelWeekly={currPeriodGranLevel === CostTrackingConstants.GRAN_LEVEL_WEEKLY}
          isGroupByDate
        />
      );
    }
    return null;
  }

  renderChart(chartId, data, favourites, dataKeys, filteredKeys, isInitialDataKeyFilterLoad) {
    const {
      visibleFilters,
      selectedMetric,
      SelectedUsageType,
      isTrendLine,
      isAreaChart,
      isPieChart,
      isLineChart,
      isShowOthers,
      filterBarGroupBy,
      isFiltersOpen,
      currPeriodGranLevel,
    } = this.state;
    return (
      <div id="full-cost-and-usage-chart">
        <Row>
          <CostChart
            setKeysFilterHandler={this.setKeysFilterHandler}
            addKeysFilterHandler={this.addKeysFilterHandler}
            removeKeysFilterHandler={this.removeKeysFilterHandler}
            isShowOthersChangeHandler={this.isShowOthersChangeHandler}
            data={data}
            isInitialDataKeyFilterLoad={isInitialDataKeyFilterLoad}
            mainLegendKeysFilterHandler={this.mainLegendKeysFilterHandler}
            filteredKeys={filteredKeys}
            dataKeys={dataKeys}
            favourites={favourites}
            isOthersVisible
            isRenderKeysFilter
            chartId={chartId}
            displayedMetric={selectedMetric}
            costTypeMode={CostTypeModes.USAGE}
            SelectedUsageType={SelectedUsageType}
            isTrendLine={isTrendLine}
            isZoomAvailble
            isAreaChartBtn
            isAreaChart={isAreaChart}
            isPieChart={isPieChart}
            isLineChart={isLineChart}
            isShowOthers={isShowOthers}
            groupBy={filterBarGroupBy}
            isFiltersOpen={isFiltersOpen}
            visibleFilterKeysCount={visibleFilters && visibleFilters.length}
            granLevel={currPeriodGranLevel}
          />
        </Row>
      </div>
    );
  }

  renderNoDataMessage = () => <NoDataFoundComponent callBack={this.handleClickToTryAgain} />;

  render() {
    const { invoiceStore, preferences, handleSavePreferences, usersStore, getPageFilters, getCustomTagsKeys } =
      this.props;
    const {
      isFiltersOpen,
      newFilterBarGroupBy,
      selectedMetric,
      linkedAccountsModalOpen,
      currentGroupBy,
      filterBarGroupBy,
      isDisplayTable,
      isPieChart,
      isLineChart,
      isAreaChart,
      isDateRangeError,
      selectedGranLevel,
      selectedOptionsMap,
      isApplyFiltersButtonDisabled,
      excludedFiltersStatusMap,
      selectedDimensions,
      metrics,
    } = this.state;

    if (invoiceStore.isMetricsLoading || !usersStore.currUserInitDone || !selectedMetric) {
      return <Spinner />;
    }
    const fieldToFieldDistincValuesMap = getPageFilters(
      PageNames.METRICS_EXPLORER,
      usersStore.currDispUserCloudAccountType,
    );
    const { currDispUserCloudAccountType } = usersStore;
    const preference = this.getPreference();
    const fullChartComponentsId = 'full-cost-and-usage-chart';
    const arrCustomTagsKeys = getCustomTagsKeys();
    const infoColWidth = 12 - +isFiltersOpen * 3;
    const linkedAccountsSelected = this.getLinkedAccountsForSelect();
    const isLinkedAccountsSelected = this.getIsLinkedAccountsSelected();
    const filtersCount = countFilters(this.fieldToFilterdValuesMap);
    return (
      <Container>
        <PageHeader title={PageNames.METRICS_EXPLORER} />
        <Card>
          {preference && linkedAccountsModalOpen ? (
            <ProcessedLinkedAccountsModal
              toggle={() =>
                this.setState((prev) => ({
                  linkedAccountsModalOpen: !prev.linkedAccountsModalOpen,
                }))
              }
              isOpen={linkedAccountsModalOpen}
              linkedAccounts={linkedAccountsSelected}
              invoiceStore={invoiceStore}
              handleSave={async (values) => {
                const newPreferences = updatePreferences(
                  preferences,
                  (values || []).map((v) => v.value),
                  'linkedAccountIds',
                  preference.uuid,
                );
                await handleSavePreferences.mutateAsync(newPreferences);
                this.setState({
                  linkedAccountsModalOpen: false,
                });
              }}
            />
          ) : null}
          <CardBody>
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ display: 'flex', fontSize: '0.8 rem', marginLeft: '0.7vw' }}>
                <PrimaryFilterBar
                  metricsTypes={metrics.map((m) => m.name)}
                  topLevelGroupBy={newFilterBarGroupBy || filterBarGroupBy}
                  selectedGroupByItem={currentGroupBy}
                  handleGroupByChange={this.handleGroupByChange}
                  handleMetricChange={this.handleMetricChange}
                  renderGroupByButton
                  currentCloudType={currDispUserCloudAccountType}
                  arrCustomTagsKeys={arrCustomTagsKeys}
                  isShowCustomTags
                  isGroupByValid={() => true}
                  usersStore={usersStore}
                  selectedMetric={selectedMetric}
                />
                <div className="main-filter-bar-separator" />
                <div className="d-flex">
                  <FiltersSidebar
                    startDate={this.formattedDates().startDate}
                    endDate={this.formattedDates().endDate}
                    handleDateChange={this.handleDateChange}
                    renderDatePickerFilter
                    isDateRangeError={isDateRangeError}
                    currPeriodGranLevel={selectedGranLevel}
                    handleChangeGranLevel={this.handleChangeGranLevel}
                    renderGranPeriodButton
                    currDispUserCloudAccountType={usersStore.currDispUserCloudAccountType}
                    selectedOptionsMap={selectedOptionsMap}
                    fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
                    handleFilterChange={this.handleFilterChange}
                    renderFilters
                    handleApplyFiltersButtonClick={this.handleApplyFiltersButtonClick}
                    isShowApplyFiltersButton
                    isApplyFiltersButtonDisabled={isApplyFiltersButtonDisabled}
                    excludedFiltersStatusMap={excludedFiltersStatusMap}
                    handleChangeFilterType={this.handleChangeFilterType}
                    includeExcludeFilterType="includeExclude"
                    handleToggleFilters={this.toggleFilters}
                    isFiltersOpen={isFiltersOpen}
                    isFilters={filtersCount > 0}
                    filtersCount={filtersCount}
                  />
                  <Tooltip title="Settings">
                    <IconButton
                      style={{ height: 35, color: '#646777' }}
                      className="me-2 ms-2"
                      onClick={() => this.setState({ linkedAccountsModalOpen: true })}
                      size="large"
                    >
                      <SettingsIcon color="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Col style={{ display: 'flex' }}>
                    <div>
                      <Button
                        className="form__button-toolbar cue-apply-btn"
                        type="submit"
                        color="success"
                        size="sm"
                        onClick={this.handleApplyFiltersButtonClick}
                        disabled={isApplyFiltersButtonDisabled || isEmptyArray(selectedDimensions)}
                      >
                        Apply
                      </Button>
                    </div>
                  </Col>
                </div>
              </div>
              <IconTextButton
                style={{
                  marginRight: '1.6vw',
                  marginLeft: 'auto',
                }}
                title="Reset All"
                handler={this.handleResetToInitialState}
                iconComp={RefreshIcon}
                iconStyle={{ color: '#70bbfd', width: '20px' }}
                fontSize="0.9rem"
              />
            </div>
            <div className="cue-separation-line" />
            {isLinkedAccountsSelected ? (
              <Row style={{ marginTop: '3vh' }}>
                <Col style={{ display: isFiltersOpen ? '' : 'none', paddingInline: 0 }} xs={3} md={3} lg={3} xl={3}>
                  <FiltersSidebarContainer
                    isOpen={isFiltersOpen}
                    selectedOptionsMap={selectedOptionsMap}
                    fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
                    currDispUserCloudAccountType={usersStore.currDispUserCloudAccountType}
                    fieldToFieldDistincValuesProps={{
                      [AwsCommonFields.LINKED_ACCOUNT_ID]: {
                        filter: (acc) =>
                          !acc || (preference ? preference.preferences.linkedAccountIds.includes(acc.value) : true),
                      },
                    }}
                    excludedFiltersStatusMap={excludedFiltersStatusMap}
                    handleChangeFilterType={this.handleChangeFilterType}
                    handleFilterChange={this.handleFilterChange}
                    handleApplyFiltersButtonClick={this.handleApplyFiltersButtonClick}
                    className="cue"
                    filtersStatusMap={this.fieldToFilterdValuesMap}
                    handleRemoveFieldFromFiltersValuesMap={this.handleRemoveFieldFromFiltersValuesMap}
                    handleDimensionsChange={this.handleDimensionsChange}
                    selectedDimensions={selectedDimensions}
                    isDimensionsFilter
                  />
                </Col>
                <Col xs={infoColWidth} md={infoColWidth} lg={infoColWidth} xl={infoColWidth}>
                  <div className="d-flex justify-content-end">
                    <div className="align-items-end d-flex">
                      <Col className="toggle-button-inner-button" xs="auto" md="auto" lg="auto" xl="auto">
                        <CostUsageSimpleSelectDropdown
                          items={ChartTypeDisplayTypes}
                          isDisplayTable={isDisplayTable}
                          isAreaChart={isAreaChart}
                          isPieChart={isPieChart}
                          isLineChart={isLineChart}
                          toggleChartOrTable={this.toggleChartOrTable}
                          className="cue"
                          title=""
                        />
                      </Col>
                    </div>
                  </div>
                  {this.renderChartAndTable(fullChartComponentsId)}
                </Col>
              </Row>
            ) : (
              <p>Click on settings and select linked accounts to inspect</p>
            )}
          </CardBody>
        </Card>
        <MarginAppliedReloader
          onChange={async () => {
            this.handleResetToInitialState();
          }}
          isPpApplied={null}
          accountKey={usersStore.currDispUserAccountKey}
        />
      </Container>
    );
  }
}

export default withPreferencesContextProvider(withInvoiceFiltersContextConsumer(observer(MetricsPage)));
