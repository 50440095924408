import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import ResetZoomButton from 'usage/containers/AnomalyDetectionNew/components/AnomalyChart/ResetZoomButton.jsx';
import AnomalyTooltip from 'usage/containers/AnomalyDetectionNew/components/AnomalyChart/AnomalyTooltip.jsx';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { getHchartConfig } from './timeSeriesHchartSettingsService.js';
import { generateChartSeriesMetricModel, processSeriesData } from './timeSeriesDataService.js';
import { endLoad, pushSeries } from './timeSeriesHchartService.js';
import { getChartsOptions, multiplyX } from './getChartsOption.js';
import styles from './AnomalyChart.module.scss';

highchartsMore(Highcharts);

const AnomalyChart = ({ metric }) => {
  const chartId = `chartContainer-${metric.uuid}${metric.alertUuid && `_${metric.alertUuid}`}`;
  const hchart = useRef(null);
  const tooltipEl = useRef(null);
  const [isResetZoomButtonVisible, setIsResetZoomButtonVisible] = useState(false);
  const { getCurrencyNumber } = useUserSettingsContext();

  const metricAnomalies = [
    {
      type: metric.anomalyType,
      fromDate: metric.startTime,
      toDate: metric.endTime,
      meetsCondition: true,
    },
  ];

  const positionTooltip = ({ x, y, panelWidth }) => {
    const { style } = tooltipEl.current;
    style.position = 'absolute';
    style.zIndex = 11;
    style.top = `${y}px`;
    style.left = `${x < panelWidth / 2 ? x + 6 : x - tooltipEl.current.offsetWidth - 6}px`;
  };

  const onMouseLeave = () => {
    ReactDOM.unmountComponentAtNode(tooltipEl.current);
  };

  const onMouseMove = (e) => {
    const { chartX, clientY } = hchart.current.pointer.normalize(e);
    const panelWidth = hchart.current.container.offsetWidth;
    positionTooltip({ x: chartX, y: clientY - hchart.current.container.getBoundingClientRect().top, panelWidth });
  };

  const configureHchart = (theme = 'preview') => {
    const isMobile = false;
    const isInactiveMode = false;
    const config = getHchartConfig(isMobile, theme, isInactiveMode);

    config.chart.events.selection = () => {
      setIsResetZoomButtonVisible(true);
    };

    // eslint-disable-next-line func-names
    config.tooltip.formatter = function () {
      const { x: date, y: minPoint, color } = this;
      ReactDOM.render(
        <AnomalyTooltip date={date} minPoint={getCurrencyNumber(minPoint, 1)} color={color} />,
        tooltipEl.current,
      );
    };

    // eslint-disable-next-line func-names
    config.yAxis.labels.formatter = function () {
      const { value } = this;
      return getCurrencyNumber(value, 3);
    };

    config.chart.events.load = () => {
      tooltipEl.current.parentElement.addEventListener('mousemove', onMouseMove);
      tooltipEl.current.parentElement.addEventListener('mouseleave', onMouseLeave);
    };

    return config;
  };

  const executeProcessMetric = (metrics) => {
    metrics.series.forEach((item) => {
      pushSeries(hchart.current, { byTreeExp: [] }, item.model, item.data, true);
    });

    endLoad(hchart.current, null || null, null || null);
  };

  const resetZoomClicked = () => {
    hchart.current.xAxis[0].setExtremes(null, null);
    setIsResetZoomButtonVisible(false);
  };

  useEffect(() => {
    Highcharts.setOptions(
      getChartsOptions({
        areaData: multiplyX(metric.baseline),
        lineData: multiplyX(metric.dataPoints),
        anomalies: metricAnomalies,
        chartClassNames: '',
      }),
    );
    hchart.current = Highcharts.chart(chartId, configureHchart());
    const metricsModel = generateChartSeriesMetricModel(metric);

    const anomalies =
      metricAnomalies?.map((anomaly) => ({
        ...anomaly,
        status: 'CLOSE',
        startTime: anomaly.fromDate,
        endTime: anomaly.toDate,
        direction: anomaly.direction ? anomaly.direction.toUpperCase() : '',
      })) ?? [];

    const processedSeriesData = processSeriesData(metric.dataPoints, metric.baseline, anomalies, []);

    const processedMetrics = {
      series: [
        {
          model: metricsModel,
          data: processedSeriesData,
        },
      ],
    };

    executeProcessMetric(processedMetrics);
  }, []);

  return (
    <figure className={styles.anomalyChart}>
      <div ref={tooltipEl} />
      {isResetZoomButtonVisible && <ResetZoomButton resetZoomClicked={resetZoomClicked} />}
      <div id={chartId} />
    </figure>
  );
};

AnomalyChart.propTypes = {
  metric: PropTypes.object.isRequired,
};

export default AnomalyChart;
