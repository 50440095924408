import { withRouter } from 'react-router';
import { useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Button from 'shared/components/andtComponents/Button';
import styles from './Footer.module.scss';

const Footer = withRouter(({ steps, prevStep, nextStep, screen }) => {
  const { getValues } = useFormContext();
  const [loading, setLoading] = useState(false);
  const { skip, noReturn, nextEnabled, nextText } = steps[screen];
  return (
    <div className={styles.footer}>
      <div className={styles.footerLeft}>
        {skip && (
          <>
            <GenerateIcon iconName={ICONS.handPointRight.name} className={styles.pointIcon} />
            <p>You can skip this step</p>
          </>
        )}
      </div>
      <div className={styles.footerMenu}>
        <Button
          onClick={prevStep}
          text="Back"
          isTextButton
          disabled={loading || noReturn}
          icon={() => <GenerateIcon iconName={ICONS.chevronLeft.name} />}
        />
        <Button
          onClick={async () => {
            setLoading(true);
            await nextStep();
            setLoading(false);
          }}
          disabled={(nextEnabled && !getValues(nextEnabled)) || loading}
          text={nextText || 'Next'}
          isLoading={loading}
          iconPlacement="right"
          icon={() => <GenerateIcon iconName={ICONS.chevronRight.name} />}
        />
      </div>
    </div>
  );
});

export default Footer;
