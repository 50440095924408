import HistoryTableCellClass from './historyTableCellClass';

export default class HistoryTableDateCell extends HistoryTableCellClass {
  constructor(data) {
    super(data);
    this.parsedValue = this.parseValue(this.value);
    this.parsedCompareValue = this.parseCompareValue(this.value);
  }

  get getParsedValue() {
    return this.parsedValue;
  }

  get getParsedCompareValue() {
    return this.parsedCompareValue;
  }

  changeToDateMillis = (v) => {
    const val = v;
    const result = Date.parse(val);
    return result;
  };
  parseValue = (v) => {
    const val = v;
    return val.split('').slice(0, 10).join('');
  };
  parseCompareValue = (v) => this.changeToDateMillis(v);
}
