/* eslint-disable comma-dangle */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars,arrow-parens,function-paren-newline */
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import ReportIcon from 'mdi-react/FileDocumentBoxIcon';
import PanelWithActionButton from 'shared/components/panels/PanelWithActionButton';
import { REPORT_TYPES } from 'usage/constants/usageConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import RegionActivityMap from 'shared/components/RegionActivityMap';
import DateFilter from 'shared/modules/dateFilter';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import { getMonthNameFromNumber } from 'shared/utils/dateUtil';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import ProcessingDataPanel from 'shared/components/ProcessingDataPanel';
import MonthlyYtdCostBarChart from 'shared/components/charts/SimpleBarChart';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import CustomDashboardWrapper from 'usage/containers/CustomDashboard/components/CustomDashboardWrapper';
import { withMainDashboardContextConsumer } from 'app/contexts/MainDashboardContext';
import UsageCost from './components/UsageCost';
import CurrVsPreviousMonthCost from './components/CurrVsPreviousMonthCost';
import UsageCostToggle from './components/UsageCostToggle';
import TopServicesCostChart from './components/TopServicesCostChart';
import YesterdayTopServicesCostChart from './components/YesterdayTopServicesCostChart';
import MainDbConstants from '../../constants/dbConstants';

const iconStyle = {
  color: '#727272',
  size: 16,
};
const actionButtonStyle = {
  display: 'inline-block',
  float: 'right',
};

class DashboardPage extends Component {
  static propTypes = {
    usageStore: PropTypes.object.isRequired,
    mainDbData: PropTypes.object.isRequired,
    mainDbDataLoading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleGoToCostExplorerReport = this.handleGoToCostExplorerReport.bind(this);
    this.state = {
      topServicesColumnWidth: null,
      topServicesColumns: null,
    };
  }

  async componentDidMount() {
    const { topServicesColumnWidth, topServicesColumns } = this.state;
    if (!topServicesColumnWidth || !topServicesColumns) {
      this.setTopServiceColumns();
    }
  }

  componentDidUpdate() {
    const { topServicesColumnWidth, topServicesColumns } = this.state;
    if (!topServicesColumnWidth || !topServicesColumns) {
      this.setTopServiceColumns();
    }
  }

  setTopServiceColumns = () => {
    const { mainDbData } = this.props;
    if (mainDbData && mainDbData.mainDashBoardData) {
      // TODO - return forecast data once it's ready
      const isForecastedExist = false; // mainDbData.mainDashBoardData.preparedTopServices.some((row) => row.forecasted);
      const isChangeExist = mainDbData.mainDashBoardData.preparedTopServices.some((row) => row.change);
      const topServicesColumnWidth = [
        { columnName: 'serviceName', width: 'auto' },
        { columnName: 'prevMonthCost', width: 'auto' },
        { columnName: 'mtdCost', width: 'auto' },
        { columnName: 'forecasted', width: 'auto' },
        { columnName: 'change', width: 'auto' },
      ];
      const topServicesColumns = [
        { name: 'serviceName', title: 'Service', getCellValue: (row) => row.name },
        { name: 'prevMonthCost', title: 'Prev Month Cost', getCellValue: (row) => row['Previous month'] },
        { name: 'mtdCost', title: 'MTD Cost', getCellValue: (row) => row['Current month'] },
      ];
      if (isForecastedExist) {
        topServicesColumns.push({
          name: 'forecasted',
          title: 'Forecasted',
          getCellValue: (row) => row.forecasted && +row.forecasted.toFixed(1),
        });
        topServicesColumnWidth.push({ columnName: 'forecasted', width: 'auto' });
      }
      if (isChangeExist && isForecastedExist) {
        topServicesColumns.push({
          name: 'change',
          title: 'Change',
          getCellValue: (row) => row.change && `${row.change.toFixed()}%`,
        });
        topServicesColumnWidth.push({ columnName: 'change', width: 'auto' });
      }
      this.setState({ topServicesColumnWidth, topServicesColumns });
    }
  };

  changeTopServicesColumnWidth = (topServicesColumnWidth) => {
    this.setState({ topServicesColumnWidth });
  };
  // eslint-disable-next-line class-methods-use-this
  calcNumOfCostsComponentsWithData = (mainDashBoardData) => {
    // Month to date costs will apear even though it's data === 0, so the column count start with 1,
    let count = 1;
    if (mainDashBoardData) {
      count += mainDashBoardData.forecastedMonthEndTotalCost ? 1 : 0;
      count += mainDashBoardData.expectedSavings ? 1 : 0;
      count += mainDashBoardData.prevMonthTotalCost ? 1 : 0;
    }

    return count === 0 ? 1 : 12 / count;
  };

  async handleGoToCostExplorerReport(id) {
    const { mainDbData, usageStore } = this.props;
    await usageStore.fetchReportDataOnNavigationToCostExplorer(id, mainDbData.mainDashBoardData);
  }

  renderDashBoard() {
    const { usageStore, mainDbData, mainDbDataLoading } = this.props;
    const { topServicesColumns, topServicesColumnWidth } = this.state;
    const { customDashboardModel } = usageStore.customDbSubStore;
    const { dashboards } = customDashboardModel;
    const { mainDashBoardData, toggleData } = mainDbData || {};
    const currMonth = DateFilter.getCurrMonthAndYear().month;
    const currYear = DateFilter.getCurrMonthAndYear().year;
    const prevMonth = DateFilter.getPrevMonthAndYear().month;
    const prevYear = DateFilter.getPrevMonthAndYear().year;
    const xlColSize = this.calcNumOfCostsComponentsWithData(mainDashBoardData);
    const colSize = 6;
    if (mainDbDataLoading) {
      return <Spinner />;
    }
    const defaultDashboard = dashboards.find((d) => d.isDefault);
    if (defaultDashboard) {
      return <CustomDashboardWrapper id={defaultDashboard.uuid} />;
    }
    if (!mainDashBoardData) {
      return (
        <Container className="dashboard">
          <PageHeader title={PageNames.DASHBOARD} showDate />
          <Row>
            {' '}
            <ProcessingDataPanel />
          </Row>
        </Container>
      );
    }
    const isShowCurrVsPreviousMonthCost = mainDashBoardData.preparedCostPerDay?.length > 0;
    const isShowYtdCostPerMonth = mainDashBoardData.preparedCostPerMonth?.length > 0;
    return (
      <Container className="dashboard">
        <PageHeader title={PageNames.DASHBOARD} showDate className="scale" />
        <Row className="scale">
          <UsageCost
            id="1"
            title={MainDbConstants.TITLE_USG_COST_MTD}
            value={mainDashBoardData.monthToDateCosts}
            percent={mainDashBoardData.monthToDatePercentages}
            percentArrowIsUp={mainDashBoardData.monthToDatePercentages > 0}
            showIcon
            infoValue={MainDbConstants.TOOLTIP_USG_COST}
            iconStyle={iconStyle}
            showPercent
            xlColSize={xlColSize}
            colSize={colSize}
          />
          {mainDashBoardData.forecastedMonthEndTotalCost ? (
            <UsageCost
              id="2"
              title={MainDbConstants.TITLE_USG_COST_FORECASTED}
              value={mainDashBoardData.forecastedMonthEndTotalCost}
              percent={mainDashBoardData.forecastedMonthEndPercentages}
              percentArrowIsUp={mainDashBoardData.forecastedMonthEndPercentages > 0}
              showIcon
              infoValue={MainDbConstants.TOOLTIP_FORCASTED}
              iconStyle={iconStyle}
              showPercent
              xlColSize={xlColSize}
              colSize={colSize}
            />
          ) : (
            <></>
          )}
          {mainDashBoardData.expectedSavings !== null &&
          mainDashBoardData.expectedSavings !== undefined &&
          mainDashBoardData.expectedSavings !== 0 ? (
            <UsageCostToggle
              id="3"
              title={MainDbConstants.TITLE_USG_COST_TOGGLE}
              percent={toggleData.percent}
              potSavings={toggleData.potSavings}
              infoValue={MainDbConstants.TOOLTIP_USG_COST_TOGGLE}
              iconStyle={iconStyle}
              xlColSize={xlColSize}
              colSize={colSize}
            />
          ) : (
            <></>
          )}
          {mainDashBoardData.prevMonthTotalCost !== null &&
          mainDashBoardData.prevMonthTotalCost !== undefined &&
          mainDashBoardData.prevMonthTotalCost !== 0 ? (
            <UsageCost
              id="4"
              title={MainDbConstants.TITLE_USG_COST_PREV}
              value={mainDashBoardData.prevMonthTotalCost}
              infoValue={MainDbConstants.TOOLTIP_USG_COST_PREV}
              iconStyle={iconStyle}
              xlColSize={xlColSize}
              colSize={colSize}
            />
          ) : (
            <></>
          )}
        </Row>
        <Row className="scale">
          {isShowCurrVsPreviousMonthCost && (
            <CurrVsPreviousMonthCost
              data={mainDashBoardData.preparedCostPerDay}
              height={350}
              title={`${getMonthNameFromNumber(currMonth)} ${
                currMonth === 1 ? currYear : ''
              } vs ${getMonthNameFromNumber(prevMonth)} ${prevYear}`}
              xLabel={MainDbConstants.CURR_VS_PREV_X_LABEL}
              yLabel={MainDbConstants.CURR_VS_PREV_Y_LABEL}
              handleGoToCostExplorerReport={this.handleGoToCostExplorerReport}
              actionButtonStyle={actionButtonStyle}
              xl={isShowYtdCostPerMonth ? 6 : 12}
              lg={isShowYtdCostPerMonth ? 6 : 12}
              md={12}
              sm={12}
              xs={12}
            />
          )}
          {isShowYtdCostPerMonth && (
            <PanelWithActionButton
              divider
              link
              title="Cost History (Month over Month) "
              panelClass="panel--narrow"
              ActionButtonComp={LinkIconButton}
              ButtonIcon={ReportIcon}
              handler={this.handleGoToCostExplorerReport}
              id={REPORT_TYPES.MONTHLY_COST_YTD}
              actionButtonStyle={actionButtonStyle}
              xl={isShowCurrVsPreviousMonthCost ? 6 : 12}
              lg={isShowCurrVsPreviousMonthCost ? 6 : 12}
              md={12}
              sm={12}
              xs={12}
            >
              <MonthlyYtdCostBarChart
                data={mainDashBoardData.preparedCostPerMonth}
                displayedMetric={DisplayMetricTypes.COST}
                height={350}
                dataKey="usageDate"
                yLabel="Cost"
              />
            </PanelWithActionButton>
            // </Row>
          )}
        </Row>
        <Row className="scale">
          {topServicesColumns && topServicesColumnWidth ? (
            <TopServicesCostChart
              data={mainDashBoardData.preparedTopServices}
              handleGoToCostExplorerReport={this.handleGoToCostExplorerReport}
              actionButtonStyle={actionButtonStyle}
              changeColumnWidths={this.changeTopServicesColumnWidth}
              columnWidths={topServicesColumnWidth}
              columns={topServicesColumns}
            />
          ) : null}
          <YesterdayTopServicesCostChart
            data={mainDashBoardData.yesterdayTopServices}
            handleGoToCostExplorerReport={this.handleGoToCostExplorerReport}
            actionButtonStyle={actionButtonStyle}
          />
        </Row>
        <Row>
          {mainDashBoardData.preparedCostPerRegion?.length > 1 && (
            <RegionActivityMap
              title={MainDbConstants.REGION_ACTIVITY_MAP_TITLE}
              data={mainDashBoardData.preparedCostPerRegion}
              handleGoToCostExplorerReport={this.handleGoToCostExplorerReport}
              actionButtonStyle={actionButtonStyle}
              xl={12}
            />
          )}
        </Row>
      </Container>
    );
  }

  render() {
    const { mainDbData, mainDbDataLoading, usageStore } = this.props;
    const { customDashboardModel } = usageStore.customDbSubStore;
    const { modelIsLoading } = customDashboardModel;
    const { mainDashboardData } = mainDbData || {};
    if (mainDbDataLoading || (mainDashboardData && mainDashboardData.isCustomDefaultDbSet && modelIsLoading)) {
      return <Spinner />;
    }
    return this.renderDashBoard();
  }
}

const ObserverDashboardPage = withMainDashboardContextConsumer(observer(DashboardPage));

export default ObserverDashboardPage;
