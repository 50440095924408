import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SelectMulti } from '@pileus-cloud/anodot-frontend-common/dist';

export const FilterWrapper = ({
  allFilters,
  handleSelectionChange,
  onSelectMultiButtonClick,
  selectFilterMultiMenuComponentGetter,
  filterType,
}) => {
  const [selectedFilterOptions, setSelectedFilterOptions] = useState(allFilters?.[filterType?.id] || []);

  useEffect(() => {
    if (allFilters && filterType) {
      setSelectedFilterOptions(allFilters[filterType.id] || []);
    }
  }, [allFilters, filterType]);

  return (
    <div className="d-flex flex-column z-2" automation-id={`heatmap-filter-${filterType.label}`}>
      <SelectMulti
        handleSelectionChange={(selectedOptions) => setSelectedFilterOptions(selectedOptions)}
        label={filterType?.label}
        onSelectMultiButtonClick={(isMenuOpen) => onSelectMultiButtonClick(isMenuOpen, filterType?.id)}
        selected={selectedFilterOptions}
        selectMultiMenuComponentGetter={selectFilterMultiMenuComponentGetter}
        onSelectMultiMenuClose={() => handleSelectionChange(selectedFilterOptions)}
      />
    </div>
  );
};
FilterWrapper.propTypes = {
  filterType: PropTypes.object.isRequired,
  allFilters: PropTypes.object.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  onSelectMultiButtonClick: PropTypes.func.isRequired,
  selectFilterMultiMenuComponentGetter: PropTypes.func.isRequired,
};
