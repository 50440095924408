export default class DivisionGroup {
  constructor(args) {
    this.parentAccountKey = args.accountKey;
    this.accountId = args.accountId;
    this.divisionId = args.divisionId;
    this.divisionName = args.divisionName;
    this.divisionNameDisplay = args.divisionNameDisplay;
    this.divisionCode = args.divisionCode;
    this.divisionTypeId = args.divisionTypeId;
    this.dbCreationTime = args.dbCreationTime;
    this.linkedAccounts = args.linkedAccounts; // array of linked accounts
    this.roleId = args.roleId;
  }

  getAccParentKey = () => this.accountParentKey;
  getDivisionName = () => this.divisionName;
  getdivisionCode = () => this.divisionCode;
  getDivisionId = () => this.divisionId;
  getDivisionLinkedAccounts = () => this.divisionLinkedAccounts;
  getDivisionLinkedAccountsIds = () => this.divisionLinkedAccounts.map(({ linkedAccountId }) => linkedAccountId);
  getDivisionLinkedAccountsNames = () => this.divisionLinkedAccounts.map(({ linkedAccountName }) => linkedAccountName);
  updatedDivisionTypeId = (accountKey, divisionId, divisionTypeId) => {
    if (this.parentAccountKey === accountKey && this.divisionId === divisionId) {
      this.divisionTypeId = divisionTypeId;
    }
  };
}
