import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { createApiKey } from 'shared/utils/apiUtil';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import { ONBOARDING_TYPES } from 'app/containers/Onboarding/utils/OnboardingConstants';
import { Routes } from 'shared/constants/routes';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { CLOUD_TYPE_IDS, UsersType } from 'users/constants/usersConstants';
import { AWS_ONBOARDING_FIELDS, AWS_ONBOARDING_STEP_TO_FIELDS, AWS_ONBOARDING_STEPS } from '../AwsOnboardingConstants';
import * as AwsOnboardingUtils from '../AwsOnboardingUtils';

export default function useFormNavigation({ screen, history, location, screens }) {
  const { trigger, formState, reset, setValue, getValues, setError } = useFormContext();
  const { errors } = formState || {};
  const { usersStore, divisionsStore } = useRootStore();
  const triggerErrors = async () => trigger(AWS_ONBOARDING_STEP_TO_FIELDS[screen] || [], { shouldFocus: true });
  const {
    startOnboarding,
    updateOnboardingParams,
    startOnboardingInvoice,
    cancelOnboarding,
    startOnboardingValidation,
  } = useOnboarding();
  const { mutateAsync: handleStartOnboarding } = startOnboarding({
    onError: (error) => {
      setError(AWS_ONBOARDING_FIELDS.ACCOUNT_ID, {
        type: 'custom',
        message: error?.response?.data?.clientMessage || 'Something went wrong',
      });
    },
  });
  const onboardingType = getValues(AWS_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const { mutateAsync: handleUpdateOnboardingParams } = updateOnboardingParams();
  const { mutateAsync: handleValidateOnboarding } = startOnboardingValidation();
  const { mutateAsync: handleStartInvoiceOnboarding } = startOnboardingInvoice();
  const { mutateAsync: handleCancelOnboarding } = cancelOnboarding();
  useWatch(AWS_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const nextStep = useCallback(async () => {
    const isValid = await triggerErrors();
    if (!isValid) {
      return;
    }
    const params = {
      userKey: getValues(AWS_ONBOARDING_FIELDS.USER_KEY),
      apiKey: createApiKey(usersStore),
      cloudTypeId: CLOUD_TYPE_IDS.AWS,
      accountKey: getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_KEY),
      accountId: getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_ID),
      accountName: getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_NAME),
      tenantName: getValues(AWS_ONBOARDING_FIELDS.TENANT_NAME),
      tenantScheme: getValues(AWS_ONBOARDING_FIELDS.TENANT_SCHEMA_NAME),
      resellerCustomer: getValues(AWS_ONBOARDING_FIELDS.RESELLER_CUSTOMER),
      externalId: getValues(AWS_ONBOARDING_FIELDS.EXTERNAL_ID),
      onboardingType: getValues(AWS_ONBOARDING_FIELDS.ONBOARDING_TYPE),
      validationStarted: getValues(AWS_ONBOARDING_FIELDS.VALIDATION_STARTED),
      accountType: getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_TYPE),
      autoAssignLinkedAccounts: +getValues(AWS_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS),
      roleARN: getValues(AWS_ONBOARDING_FIELDS.ROLE_ARN),
      s3BucketName: getValues(AWS_ONBOARDING_FIELDS.BUCKET_NAME),
      s3BucketRegion: getValues(AWS_ONBOARDING_FIELDS.BUCKET_REGION),
      isReseller: +getValues(AWS_ONBOARDING_FIELDS.IS_MSP),
      [AWS_ONBOARDING_FIELDS.IS_BIGQUERY]: +getValues(AWS_ONBOARDING_FIELDS.IS_BIGQUERY),
    };
    if (screen === AWS_ONBOARDING_STEPS.AWS_DETAILS) {
      const searchParams = new URLSearchParams(location.search);
      const accountIdChanged =
        searchParams.get('accountId') && getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_ID) !== searchParams.get('accountId');
      if (!getValues(AWS_ONBOARDING_FIELDS.STARTED) || accountIdChanged) {
        if (accountIdChanged) {
          await handleCancelOnboarding({ accountId: searchParams.get('accountId') });
        }
        try {
          await handleStartOnboarding(params);
        } catch (error) {
          console.info(error);
          return;
        }
        setValue(AWS_ONBOARDING_FIELDS.STARTED, true);
        const addQueryParam = (key, value) => {
          const url = new URL(window.location.href);
          url.searchParams.set(key, value);
          window.history.pushState({}, '', url.toString());
        };
        addQueryParam('accountId', params.accountId);
      } else {
        await handleUpdateOnboardingParams(params);
      }
    }

    if (screen === AWS_ONBOARDING_STEPS.GRANT_ACCESS) {
      await handleUpdateOnboardingParams(params);
      if (getValues(AWS_ONBOARDING_FIELDS.GRANT_ACCESS_REMINDER)) {
        setValue(AWS_ONBOARDING_FIELDS.GRANT_ACCESS_REMINDER, false);
      } else {
        setValue(AWS_ONBOARDING_FIELDS.GRANT_ACCESS_REMINDER, true);
        return;
      }
    }

    if (screen === AWS_ONBOARDING_STEPS.VALIDATE_ACCESS) {
      params[AWS_ONBOARDING_FIELDS.VALIDATION_STARTED] = true;
      await handleUpdateOnboardingParams(params);
      await handleValidateOnboarding(params);
      setValue(AWS_ONBOARDING_FIELDS.VALIDATION_STARTED, true);
      return;
    }

    if (screen === AWS_ONBOARDING_STEPS.LINKED_ACCOUNTS) {
      await handleStartInvoiceOnboarding(params);
    }

    if (screen === AWS_ONBOARDING_STEPS.BILLING_PROFILE) {
      const profile = getValues(AWS_ONBOARDING_FIELDS.BILLING_PROFILE);
      profile.serviceCosts = getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_TYPE) === 'shared';
      const data = Object.keys(profile).reduce(
        (acc, key) => ({
          ...acc,
          [key]: Number(profile[key]),
        }),
        {},
      );
      await divisionsStore.updateBillingProfile(data, params.accountId, true);
    }

    // go to dashboard page if it is the last screen of onboarding (invoice is already ran, so it should have data)
    if (screens && screens.findIndex((scr) => scr.id === screen) === screens.length - 1) {
      history.push(Routes.DASHBOARD);
      window.location.reload();
      return;
    }

    setValue(
      AWS_ONBOARDING_FIELDS.SCREEN,
      AwsOnboardingUtils.handleNextScreen(
        screen,
        onboardingType === ONBOARDING_TYPES.AUTOMATIC,
        usersStore.currentDisplayedUserType === UsersType.RESELLER,
      ),
    );
  }, [errors, screen, onboardingType]);

  const prevStep = useCallback(() => {
    reset({}, { keepValues: true, keepTouched: false, keepErrors: false, keepDirty: true });
    setValue(
      AWS_ONBOARDING_FIELDS.SCREEN,
      AwsOnboardingUtils.handlePrevScreen(
        screen,
        onboardingType === ONBOARDING_TYPES.AUTOMATIC,
        usersStore.currentDisplayedUserType === UsersType.RESELLER,
      ),
    );
  }, [errors, screen, onboardingType]);

  return {
    nextStep,
    prevStep,
  };
}
