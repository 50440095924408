import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import { camelCase, startCase, cloneDeep } from 'lodash';
import { mapRecommendationsTypeToDisplay } from 'recommendations/constants/recommendationsConstants';
import { Accordion } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import grey from '@mui/material/colors/grey';
import { Col } from 'reactstrap';
import Button from 'shared/components/andtComponents/Button';
import { UsersType } from 'users/constants/usersConstants';
import { updatePreferences, usePreferences } from 'users/hooks/react-query/usePreferences';
import InformationOutline from 'mdi-react/InformationOutlineIcon';
import { PREFERENCES_SELECT_VALUES, PREFERENCES_SPECIAL_FIELDS } from '../constants/preferencesConstants';
import toast from './andtComponents/Toast';
import Tooltip from './andtComponents/Tooltip';

const PreferencesTable = ({ usersStore, preferences: preferencesFromProps }) => {
  const [preferences, setPreferences] = useState(
    cloneDeep(preferencesFromProps)?.filter((p) => !p.preferences.isPreferencesHidden),
  );
  const [rowExpanded, setRowExpanded] = useState(false);
  const [searchVal, setSearchVal] = useState('');

  const { useSaveMutation } = usePreferences();
  const handleSave = useSaveMutation();

  const typeFormatter = (data) => mapRecommendationsTypeToDisplay.get(data.preferenceKey) || data.preferenceKey;

  const handleRowExpanded = (panelId) => (isExpanded) => {
    setRowExpanded(isExpanded ? panelId : null);
  };

  const handleSearchValChanged = (searchVal) => {
    setSearchVal(searchVal);
  };

  const handleSavePreferences = async () => {
    await handleSave.mutateAsync(preferences);
    toast.success('Changes saved');
  };

  // support sort preferences list by  preferences_order field in default-preferences db
  // support custom title of preferences by preferences_title in default-preferences db
  // both of them are optional fields and if not defined will display in title the key name of field in random order.
  const renderExpandedInfo = (row, disabled) => {
    const { preferences: preferencesConfig, preferencesTitles, preferencesOrder, preferencesTooltips } = row;
    const sortedPreferences = preferencesOrder
      ? Object.entries(preferencesOrder).sort((a, b) => a[1] - b[1])
      : preferencesConfig;
    const sortedKeys = preferencesOrder ? sortedPreferences.map(([key]) => key) : Object.keys(sortedPreferences);
    return (
      <div className="card p-3 flex-row flex-wrap" style={{ background: grey[50] }}>
        {sortedKeys.map((key) => {
          const renderInputInfo = (info) =>
            info ? (
              <div className="ms-1">
                <Tooltip title={info} arrow placement="top" interactive leaveDelay={1000} leaveTouchDelay={1000}>
                  <div>
                    <InformationOutline size={18} />
                  </div>
                </Tooltip>
              </div>
            ) : null;
          const renderInputNumber = (key, inputValue) => {
            const fieldSettings = PREFERENCES_SPECIAL_FIELDS[key] || {};
            const value = fieldSettings.isPercent ? inputValue * 100 : inputValue;
            return (
              <>
                <input
                  type="number"
                  min={0}
                  step={1}
                  disabled={disabled}
                  id="key"
                  value={value || ''}
                  onChange={(e) => {
                    const { value: val } = e.target;
                    const formatted = fieldSettings.isPercent
                      ? +Math.round(Math.min(fieldSettings.max, val)) / 100
                      : +val;
                    setPreferences(updatePreferences(preferences, formatted, key, row.uuid));
                  }}
                  className="form-control ms-3"
                  style={{ fontSize: 12, width: 150 }}
                  autoComplete="off"
                  {...fieldSettings}
                />
              </>
            );
          };

          const renderInput = () => {
            const selectItems = PREFERENCES_SELECT_VALUES[camelCase(key)];
            if (selectItems) {
              return (
                <div className="ms-3" style={{ minWidth: '150px' }}>
                  <Select
                    disabled={disabled}
                    value={{
                      value: preferencesConfig[key],
                      label: preferencesConfig[key],
                    }}
                    options={selectItems.map((v) => ({ label: v, value: v }))}
                    onChange={(selectedOption) => {
                      setPreferences(updatePreferences(preferences, selectedOption.value, key, row.uuid));
                    }}
                  />
                </div>
              );
            }
            if (typeof preferencesConfig[key] === 'boolean') {
              return (
                <input
                  type="checkbox"
                  checked={preferencesConfig[key] === true ? 'checked' : ''}
                  onChange={(e) => {
                    setPreferences(updatePreferences(preferences, e.target.checked, key, row.uuid));
                  }}
                  className="ms-2"
                  style={{ width: 15, height: 15 }}
                />
              );
            }
            return renderInputNumber(key, +preferencesConfig[key]);
          };

          const renderPreferenceTooltip = () => {
            let { info } = preferencesTooltips[key];
            let infoHtml = <div>{info}</div>;
            const searchString = 'MORE_DETAILS';
            if (info?.indexOf(searchString) >= 0) {
              info = info.replace(searchString, '');
              infoHtml = (
                <div className="me-1 ms-1">
                  {info}
                  <a href={preferencesTooltips[key]?.moreDetailsLink} target="_blank" rel="noreferrer">
                    more details
                  </a>
                </div>
              );
            }
            return renderInputInfo(infoHtml);
          };

          return (
            <div className="d-flex align-items-center me-3 mb-3">
              {preferencesTitles && preferencesTitles[key] ? preferencesTitles[key] : startCase(key)}:
              {preferencesTooltips && preferencesTooltips[key] ? renderPreferenceTooltip() : null}
              {renderInput()}
            </div>
          );
        })}
      </div>
    );
  };
  const actionsDisabled = ![UsersType.USER, UsersType.RESELLER].includes(
    Number(usersStore.getCurrentDisplayedUserType),
  );
  const someInfoEmpty = preferences.some((p) => Object.values(p.preferences || {}).some((k) => k === ''));
  return (
    <Col className="w-100">
      <input
        type="text"
        value={searchVal}
        placeholder="Search by type..."
        onChange={(e) => handleSearchValChanged(e.target.value)}
        className="form-control mt-2"
        style={{ fontSize: '13px', width: 250 }}
        autoComplete="off"
      />
      <AccordionSummary>
        <div
          className="font-weight-bold"
          style={{
            display: 'grid',
            gridTemplateColumns: '180px 200px auto',
            gridGap: 20,
            color: grey[700],
            fontSize: 16,
          }}
        >
          <span>Type</span>
          <span>Created By</span>
          <span>Update Time</span>
        </div>
      </AccordionSummary>
      {preferences
        .filter(
          (p) =>
            !searchVal ||
            p.preferenceKey.includes(searchVal.toLowerCase()) ||
            typeFormatter(p).toLowerCase().includes(searchVal.toLowerCase()),
        )
        .map((p) => (
          <Accordion
            className="w-100"
            expanded={p.uuid === rowExpanded}
            onChange={(e, expanded) => handleRowExpanded(p.uuid)(expanded)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '180px 200px auto',
                  gridGap: 20,
                }}
              >
                <span>{typeFormatter(p)}</span>
                <span>{p.createdBy || 'Default'}</span>
                <span>{p.createdBy ? moment(p.updateTime).format('DD MMM HH:mm') : '-'}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0">{renderExpandedInfo(p, actionsDisabled)}</AccordionDetails>
          </Accordion>
        ))}
      {someInfoEmpty && <p style={{ color: 'red' }}>Some input is empty, please fill all fields</p>}
      <div className="d-flex justify-content-end pe-3 pt-3">
        <Button
          text="Save"
          disabled={actionsDisabled || handleSave.isLoading || someInfoEmpty}
          onClick={handleSavePreferences}
        />
      </div>
    </Col>
  );
};

PreferencesTable.propTypes = {
  usersStore: PropTypes.object.isRequired,
  preferences: PropTypes.array.isRequired,
};

export default PreferencesTable;
