import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
// import moment from 'moment';
import DateFilter from '../modules/dateFilter';
import Input from './andtComponents/Input';
import styles from './PageHeader.module.scss';

const getDisplayDate = () => {
  const currDate = DateFilter.getDateFilterRangeStr();
  if (!currDate) {
    return '';
  }
  return `Updated: ${currDate}`;
};

const PageHeader = ({ title, showDate, barIcons, subTitle, smallSubTitle, searchParams, children }) => (
  <Row className="page-title-wrapper">
    <div className="page-title">
      <h3 automation-id="pageTitle">{title}</h3>
      {showDate ? <h3 className="page-subhead subhead mt-3">{getDisplayDate()}</h3> : <></>}
    </div>
    <Col xl={4} lg={4} md={4} className="d-flex justify-content-end">
      {barIcons && children}
      {searchParams ? (
        <div className={styles.searchInputContainer}>
          <Input
            value={searchParams.value}
            onChange={(e) => searchParams.onChange(e.target.value)}
            placeholder="Search"
            className={styles.searchInput}
          />
        </div>
      ) : null}
    </Col>
    {subTitle ? <h4 className="page-sub-title">{subTitle}</h4> : <></>}
    {smallSubTitle ? (
      <h3
        className="page-subhead"
        style={{
          fontSize: 14,
          maxWidth: '60%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {smallSubTitle}
      </h3>
    ) : (
      <></>
    )}
  </Row>
);

export default PageHeader;
PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  smallSubTitle: PropTypes.string,
  showDate: PropTypes.bool,
  barIcons: PropTypes.bool,
  children: PropTypes.object,
  searchParams: PropTypes.object,
};
PageHeader.defaultProps = {
  subTitle: '',
  smallSubTitle: '',
  showDate: false,
  barIcons: false,
  children: null,
  searchParams: null,
};
