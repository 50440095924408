/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './BusinessMappingTable.module.scss';

const BusinessMappingActionButton = ({ disabled, group, handleEdit, handleMakeGlobal, handleDelete, handleClone }) => (
  <div onClick={(e) => e.stopPropagation()}>
    <ButtonDropdown
      isTextButton
      disabled={disabled}
      tooltipText="Options"
      icon={() => <GenerateIcon iconName={ICONS.verticalDots.name} className={styles.arrowIcon} />}
      automationId="businessMappingTableActions"
    >
      {handleEdit && (
        <li onClick={() => handleEdit(group)} id="editMapping">
          <span>
            <GenerateIcon iconName={ICONS.edit.name} />
            Edit Mapping
          </span>
        </li>
      )}
      {handleClone && (
        <li onClick={() => handleClone(group)} id="duplicateMapping">
          <span>
            <GenerateIcon iconName={ICONS.clone.name} />
            Duplicate Mapping
          </span>
        </li>
      )}
      {handleMakeGlobal && (
        <li onClick={() => handleMakeGlobal(group)} id="convertToGlobalMapping">
          <span>
            <GenerateIcon iconName={ICONS.globe.name} />
            Convert to Global Mapping
          </span>
          <Tooltip
            title="Converting the mapping to global status will enable
           you to select the mapping in any of your viewpoints."
            arrow
          >
            <span className={styles.infoIcon}>
              <InfoIcon />
            </span>
          </Tooltip>
        </li>
      )}
      <li onClick={() => handleDelete(group)} id="deleteMapping">
        <span>
          <GenerateIcon iconName={ICONS.delete.name} />
          Delete Mapping
        </span>
      </li>
    </ButtonDropdown>
  </div>
);

BusinessMappingActionButton.propTypes = {
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func.isRequired,
  handleClone: PropTypes.func,
  handleMakeGlobal: PropTypes.func,
  group: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

BusinessMappingActionButton.defaultProps = {
  handleClone: undefined,
  handleMakeGlobal: undefined,
  handleEdit: undefined,
};

export default observer(BusinessMappingActionButton);
