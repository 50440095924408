import { useQuery, useMutation, useQueryClient } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

const root = '/api/v1/users/user-settings';

const prepareUserNotificationData = (userNotificationRawData) => {
  const {
    id,
    division_id: divisionId,
    user_key: userKey,
    is_budget: isBudget,
    is_anomaly_detection: isAnomalyDetection,
    is_alert: isAlert,
    default_account_id: defaultAccountId,
    is_email_notification: isEmailNotification,
    is_slack_notification: isSlackNotification,
    is_display_report: isShowReport,
    is_promo_notifications: isPromoNotifications,
    update_time: updateTime,
    is_alert_cost_change: isShowAlertCostChange,
    is_alert_ri_expiration: isShowAlertRiExpiration,
    is_alert_sp_expiration: isShowAlertSpExpiration,
    userHash,
  } = userNotificationRawData;
  return {
    id,
    divisionId,
    userKey,
    isBudget,
    isAnomalyDetection,
    isAlert,
    isEmailNotification,
    defaultAccountId,
    isSlackNotification,
    isPromoNotifications,
    isShowReport,
    updateTime,
    isShowAlertCostChange,
    isShowAlertRiExpiration,
    isShowAlertSpExpiration,
    userHash,
  };
};

const fetchUserNotification = async () => {
  const { userNotificationRawData } = await API.get('billings', `${root}/notifications`);
  return prepareUserNotificationData(userNotificationRawData?.[0]);
};

const updateUserNotification = async (notificationInputsState) =>
  API.put('billings', `${root}/notifications`, {
    body: notificationInputsState,
  });

export const userNotificationsProvider = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.USER_NOTIFICATIONS];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    useQuery: () => useQuery(queryKey, fetchUserNotification, { retry: false }),
    useMutation: (mutationSettings = {}) => useMutation({ mutationFn: updateUserNotification, ...mutationSettings }),
  };
};
