/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import Tooltip from '@mui/material/Tooltip';
import {
  RowDetailState,
  DataTypeProvider,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  IntegratedSelection,
  SelectionState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
  SearchPanel,
  Toolbar,
  TableColumnResizing,
  ColumnChooser,
  TableColumnVisibility,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import { alpha } from '@mui/system/colorManipulator';
import RecommendationDetailsBuilder from 'recommendations/shared/components/RecommendationDetailsBuilder';
import { withStyles } from '@mui/styles';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import { numberWithCommas, strNumToSize } from 'shared/utils/strUtil';
import TableWrapper from 'shared/components/tables/TableWrapper';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { CURRENCY_DETAILS } from 'shared/constants/appConstants';
import { mapRecommendationTypeToColumnsWidthArray } from '../../../constants/recommsTableConstants';
import RecommendationDropdown from './RecommendationDropdown';
import RecActionButton from './RecActionButton';

const TableHeaderSelectionBaseComponent = ({ classes, onToggle, allSelected, someSelected, ...restProps }) => (
  <TableSelection.HeaderCell
    onToggle={() => onToggle(allSelected)}
    allSelected={allSelected}
    someSelected={someSelected}
    {...restProps}
  />
);

TableHeaderSelectionBaseComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  allSelected: PropTypes.bool.isRequired,
  someSelected: PropTypes.bool.isRequired,
};

const TableSelectionBaseComponent = ({ classes, tableRow, onToggle, selected, ...restProps }) => (
  <TableSelection.Cell selected={selected} onToggle={() => onToggle(tableRow)} {...restProps} />
);

TableSelectionBaseComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  tableRow: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
};

const styles = () => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    fontSize: '25.5vw',
  },
  disabled: {
    ' & span': {
      color: '#00000042',
    },
    pointerEvents: 'none',
  },
});

export const TableHeaderSelectionComponent = withStyles(styles, { name: 'TableHeaderSelectionComponent' })(
  TableHeaderSelectionBaseComponent,
);
export const TableSelectionComponent = withStyles(styles, { name: 'TableSelectionComponent' })(
  TableSelectionBaseComponent,
);
class RecommendationsTable extends Component {
  static propTypes = {
    recomms: PropTypes.object.isRequired,
    recommType: PropTypes.string.isRequired,
    tableColumns: PropTypes.object.isRequired,
    sortingOptions: PropTypes.object,
    recHandlers: PropTypes.object.isRequired,
    usersStore: PropTypes.object.isRequired,
    prepareCsvModifiedRowsForRecomm: PropTypes.object.isRequired,
    currentUserReadOnly: PropTypes.bool.isRequired,
    cloudTypeId: PropTypes.number.isRequired,
    csvTitle: PropTypes.string,
    currencyCode: PropTypes.string.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  static defaultProps = {
    csvTitle: 'Recommendations',
    sortingOptions: null,
  };
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      columnWidths: this.getTableColumnsWidths(),
      pageSizes: [10, 15, 20, 0],
      filteredRecomms: [],
      searchValue: '',
      selectedRecomms: [],
      expandedRowIds: [],
    };
  }

  onToggle = (tableRow) => {
    const { selectedRows, selectedRecomms } = this.state;
    const { row, rowId } = tableRow;
    const isRowSelected = selectedRows.includes(rowId);
    const isRecommSelected = selectedRecomms.includes(row.key);
    this.setSelectedRows(isRowSelected ? selectedRows.filter((id) => id !== rowId) : [...selectedRows, rowId]);
    this.setState({
      selectedRecomms: isRecommSelected
        ? selectedRecomms.filter((key) => key !== row.key)
        : [...selectedRecomms, row.key],
    });
  };

  getTableColumnsWidths = () => {
    const { recomms, tableColumns } = this.props;
    const columnsWidths = recomms.length ? mapRecommendationTypeToColumnsWidthArray.get(recomms[0].type) || [] : [];
    // add missing columns with default width
    tableColumns.forEach((col) => {
      const { name } = col;
      if (!columnsWidths.some((c) => c.columnName === name)) {
        if (name === 'userAction') {
          columnsWidths.push({ columnName: name, width: 90 });
        }
        if (['linkedAccount', 'resource'].includes(name)) {
          columnsWidths.push({ columnName: name, width: 200 });
        } else {
          columnsWidths.push({ columnName: name, width: 150 });
        }
      }
    });
    return columnsWidths || [];
  };

  getColumnWidthByColLength = (colsLength) => {
    if (colsLength < 5) {
      return {
        linkedAccResourceColWidth: 250,
        otherColsWidth: 200,
      };
    }
    return {
      linkedAccResourceColWidth: 200,
      otherColsWidth: 150,
    };
  };

  getColWidthFromState = () => {
    const { tableColumns } = this.props;
    const { columnWidths } = this.state;
    const returnWidths = [...columnWidths];
    const { linkedAccResourceColWidth, otherColsWidth } = this.getColumnWidthByColLength(tableColumns.length);
    // add missing columns with default width
    tableColumns.forEach((col) => {
      const { name } = col;
      if (!returnWidths.some((c) => c.columnName === name)) {
        if (name === 'userAction') {
          returnWidths.push({ columnName: name, width: 90 });
        }
        if (['linkedAccount', 'resource'].includes(name)) {
          returnWidths.push({ columnName: name, width: linkedAccResourceColWidth });
        } else {
          returnWidths.push({ columnName: name, width: otherColsWidth });
        }
      }
    });
    return returnWidths || [];
  };

  setSelectedRows = (selectedRows) => {
    segmentEvent({ target: 'recommendationsTableToggleSelectRow' });
    this.setState({ selectedRows });
  };

  setExpandedRowIds = (expandedRowIds) => {
    segmentEvent({ target: 'recommendationsTableToggleExpandRow' });
    this.setState({ expandedRowIds });
  };

  setFilteredRecomms = (filteredRecomms) => {
    this.setState({ filteredRecomms });
  };

  getModifiedColumnValue = (colName, value) => {
    switch (colName) {
      case 'totalSize':
        return this.bytesSizeFormatter(value);
      case 'totalUploadSize':
        return this.bytesSizeFormatter(value);
      case 'savingAmount':
        return this.costFormatter(value);
      case 'latestVersioningSize':
        return this.bytesSizeFormatter(value);
      case 'versioningSize':
        return this.bytesSizeFormatter(value);
      case 'maxMemory':
        return this.maxMemoryFormatter(value);
      default:
        return value.value;
    }
  };

  setSearchValue = (searchValue) => {
    const { recomms, usersStore } = this.props;
    segmentEvent({ type: 'click-event', target: 'recommendation_table_search' }, usersStore);
    const filteredRecomms = recomms.filter((recomm) => {
      const { tableColumns } = this.props;
      return tableColumns.some(({ name, getCellValue }) => {
        const columnValue = getCellValue && typeof getCellValue === 'function' ? getCellValue(recomm) : recomm[name];
        const modifiedValue = this.getModifiedColumnValue(name, { value: columnValue });
        return (
          modifiedValue &&
          modifiedValue
            .toString()
            .toLowerCase()
            .includes((searchValue || '').toLowerCase())
        );
      });
    });
    this.setState({ searchValue, filteredRecomms });
  };

  selectAllFilteredRows = (isSelected) => {
    const { recomms } = this.props;
    const { filteredRecomms, searchValue } = this.state;
    const recommsToSelect = searchValue.length ? filteredRecomms : recomms;
    const selectedRecomms = recommsToSelect.map((recomm) => recomm.key);
    this.setState({ selectedRecomms: !isSelected ? [...new Set(selectedRecomms)] : [] });
  };

  isAllSelected = () => {
    const { recomms } = this.props;
    const { filteredRecomms, searchValue, selectedRecomms } = this.state;
    if (searchValue && !filteredRecomms.length) {
      return false;
    }
    if (searchValue && filteredRecomms.length) {
      return (
        selectedRecomms.length > 0 &&
        filteredRecomms.every((recomm) => filteredRecomms.find((filteredRecomm) => filteredRecomm.key === recomm.key))
      );
    }
    return selectedRecomms.length > 0 && recomms.length === selectedRecomms.length;
  };
  isSomeSelected = () => {
    const { recomms } = this.props;
    const { searchValue, filteredRecomms, selectedRecomms } = this.state;
    if ((searchValue && !filteredRecomms.length) || (recomms && !recomms.length)) {
      return false;
    }
    if (searchValue && filteredRecomms.length) {
      return selectedRecomms.some((key) => filteredRecomms.some((filteredRecomm) => filteredRecomm.key === key));
    }
    return selectedRecomms.length > 0;
  };
  isRowSelected = (tableRowKey) => {
    const { selectedRecomms } = this.state;
    const isRowSelected = selectedRecomms.includes(tableRowKey);
    return isRowSelected;
  };

  closeExpanded = () => {
    const emptyExpanded = [];
    this.setState({ expandedRowIds: emptyExpanded });
  };

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  bytesSizeFormatter = (value) => strNumToSize(value.value);
  gigaBytesSizeFormatter = (value) => `${numberWithCommas(value.value, 3)} GB`;
  costFormatter = (value) => {
    const { getCurrencyNumber } = this.props;
    return getCurrencyNumber(value?.value, 0, { roundNumber: true });
  };
  maxMemoryFormatter = (data) => {
    const modVal = +data.value || 0;
    if (modVal === 0) {
      return '-';
    }
    return `${modVal.toFixed(1)} %`;
  };
  resourceFormatter = (data) => (
    <Tooltip title={data.value}>
      <span>{data.value}</span>
    </Tooltip>
  );

  render() {
    const {
      recomms,
      recommType,
      recHandlers,
      prepareCsvModifiedRowsForRecomm,
      tableColumns,
      sortingOptions,
      cloudTypeId,
      currentUserReadOnly,
      usersStore,
      currencyCode,
    } = this.props;
    const { selectedRows, filteredRecomms, searchValue, selectedRecomms, pageSizes, expandedRowIds } = this.state;
    const accountFeatures = usersStore.getCurrentDisplayedAccountFeatures(usersStore.currDispUserAccountKey);
    const isMondayFeatureEnabled =
      accountFeatures.includes(ACCOUNT_FEATURES.MONDAY) && usersStore.isUserSubscribedToMonday;
    /* eslint-disable-next-line arrow-body-style */
    const RowDetail = ({ row }) => {
      return (
        <div>
          <RecommendationDetailsBuilder
            currentUserReadOnly={currentUserReadOnly}
            recomm={row}
            recHandlers={recHandlers}
            afterActionHandler={this.closeExpanded}
            cloudTypeId={cloudTypeId}
          />
        </div>
      );
    };

    const userActionsFormatter = (data) => {
      withStyles(data);
      return (
        <ReadOnlyDisplayWrapper isHide={false} userReadOnly={currentUserReadOnly}>
          <div style={{ width: '100%' }}>
            <RecActionButton
              rec={data.row}
              recHandlers={recHandlers}
              cloudTypeId={cloudTypeId}
              isMondayFeatureEnabled={isMondayFeatureEnabled}
            />
          </div>
        </ReadOnlyDisplayWrapper>
      );
    };

    const getColumnTooltipText = (columnName, showSavingTooltip) => {
      if (columnName === 'age') {
        return 'Days since recommendation was triggered';
      }
      if (columnName === 'savingAmount') {
        if (showSavingTooltip) {
          return "Annual savings are calculated based on last month's usage, multiplied by the hourly rate.";
        }
        return CURRENCY_DETAILS.SAVING_CALCULATE_TOOLTIP;
      }
      return '';
    };
    RowDetail.propTypes = {
      row: PropTypes.object.isRequired,
    };
    return (
      <div className="card">
        <Grid rows={searchValue ? filteredRecomms : recomms} columns={tableColumns}>
          {selectedRecomms.length ? (
            <Col
              style={{ display: 'flex', justifyContent: 'flex-start', minWidth: '300px', zIndex: 10, marginTop: -1 }}
            >
              <RecommendationDropdown
                recHandlers={recHandlers}
                recommType={recommType}
                cloudTypeId={cloudTypeId}
                prepareCsvModifiedRowsForRecomm={prepareCsvModifiedRowsForRecomm}
                tableColumns={tableColumns}
                selectedRec={recomms.filter(({ key }) => selectedRecomms.includes(key))}
              />
            </Col>
          ) : null}
          <SearchState value={searchValue} onValueChange={this.setSearchValue} />
          <SortingState />
          <SelectionState selection={selectedRows} onSelectionChange={this.setSelectedRows} />
          <IntegratedSelection />
          <IntegratedFiltering />
          <IntegratedSorting columnExtensions={sortingOptions} />
          <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[2]} />
          <IntegratedPaging />
          <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={this.setExpandedRowIds} />
          <DataTypeProvider for={['userAction']} formatterComponent={userActionsFormatter} />
          <DataTypeProvider for={['totalSize']} formatterComponent={this.bytesSizeFormatter} />
          <DataTypeProvider for={['totalUploadSize']} formatterComponent={this.bytesSizeFormatter} />
          <DataTypeProvider for={['savingAmount']} formatterComponent={this.costFormatter} />
          <DataTypeProvider for={['latestVersioningSize']} formatterComponent={this.bytesSizeFormatter} />
          <DataTypeProvider for={['versioningSize']} formatterComponent={this.bytesSizeFormatter} />
          <DataTypeProvider for={['resource']} formatterComponent={this.resourceFormatter} />
          <DataTypeProvider for={['resourceTags']} formatterComponent={this.resourceFormatter} />
          <DataTypeProvider for={['maxMemory']} formatterComponent={this.maxMemoryFormatter} />
          <DataTypeProvider for={['totalSizeGB']} formatterComponent={this.gigaBytesSizeFormatter} />
          <TableWrapper styles={styles} />
          <TableColumnResizing
            columnWidths={this.getColWidthFromState()}
            onColumnWidthsChange={this.changeColumnWidths}
          />
          <TableHeaderRow
            showSortingControls
            cellComponent={({ column, children, ...rest }) => {
              if (
                column.name === 'age' ||
                (column.name === 'savingAmount' &&
                  ((currencyCode && currencyCode !== CURRENCY_DETAILS.USD) || column.showSavingTooltip))
              ) {
                return (
                  <TableHeaderRow.Cell {...rest}>
                    <div className="flex-cell">
                      {children}
                      <Tooltip
                        title={getColumnTooltipText(column.name, column.showSavingTooltip)}
                        arrow
                        className="toolTip"
                      >
                        <span>
                          <InfoIcon />
                        </span>
                      </Tooltip>
                    </div>
                  </TableHeaderRow.Cell>
                );
              }
              return (
                <TableHeaderRow.Cell column={column} {...rest}>
                  {children}
                </TableHeaderRow.Cell>
              );
            }}
          />
          <TableColumnVisibility defaultHiddenColumnNames={['key', 'maxMemory']} />
          <Toolbar />
          <SearchPanel />
          <ColumnChooser />
          <TableSelection
            headerCellComponent={(params, style) => (
              <TableHeaderSelectionComponent
                {...params}
                styles={style}
                allSelected={this.isAllSelected()}
                someSelected={this.isSomeSelected()}
                onToggle={this.selectAllFilteredRows}
              />
            )}
            cellComponent={(params, style) => (
              <TableSelectionComponent
                {...params}
                styles={style}
                selectedRows={selectedRows}
                onToggle={this.onToggle}
                selectedRecomms={selectedRecomms}
                selected={this.isRowSelected(params.tableRow.row.key)}
              />
            )}
            showSelectAll
          />
          <PagingPanel pageSizes={pageSizes} />
          <TableRowDetail contentComponent={RowDetail} />
        </Grid>
      </div>
    );
  }
}

const ObserverRecommendationsTable = withUserSettingsConsumer(RecommendationsTable);
export default ObserverRecommendationsTable;
