/* eslint-disable jsx-a11y/anchor-is-valid, quotes */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Routes } from 'shared/constants/routes';
import { UsersType } from 'users/constants/usersConstants';
import { pages } from '../../constants/newUserConstants';

class CarouselPageNavigator extends Component {
  static propTypes = {
    previous: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    activeIndex: PropTypes.number.isRequired,
    lastPage: PropTypes.number.isRequired,
    lastPageDisp: PropTypes.number.isRequired,
    userType: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { previous, next, activeIndex, lastPage, lastPageDisp, userType } = this.props;
    return (
      <>
        <Row className="bottom-nav-bar-navigation-container">
          <Col xs={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }} xl={{ size: 12 }}>
            <hr />
          </Col>
          <Col xs="auto" md="auto" lg="auto" xl="auto">
            <Row className="nav-buttons-inner-wrapper">
              <Col xs="auto" md="auto" lg="auto" xl="auto">
                {activeIndex === 0 || activeIndex === lastPage ? null : (
                  <a
                    className="back-button visible"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                  >
                    Back
                  </a>
                )}
                {activeIndex === 0 ? (
                  <Link to={userType === UsersType.NEW_USER ? Routes.ACCOUNTS_OVERVIEW : Routes.DASHBOARD}>
                    <button className="next-button visible" type="button">
                      Cancel
                    </button>
                  </Link>
                ) : null}
              </Col>
              {activeIndex !== lastPage && (
                <Col xs="auto" md="auto" lg="auto" xl="auto">
                  <button
                    className={`next-button ${activeIndex === lastPageDisp ? 'hidden' : 'visible'}`}
                    onClick={next}
                    type="button"
                  >
                    {activeIndex === pages.page0 ? `Let's Start` : 'Next'}
                  </button>
                </Col>
              )}
              {activeIndex === lastPage && (
                <Col xs="auto" md="auto" lg="auto" xl="auto">
                  <Link to={userType === UsersType.NEW_USER ? Routes.ACCOUNTS_OVERVIEW : Routes.DASHBOARD}>
                    <button
                      className={`next-button ${activeIndex === lastPageDisp ? 'hidden' : 'visible'}`}
                      type="button"
                    >
                      Skip
                    </button>
                  </Link>
                </Col>
              )}
              {activeIndex === lastPage && (
                <Col xs="auto" md="auto" lg="auto" xl="auto">
                  <Link to={userType === UsersType.NEW_USER ? Routes.ACCOUNTS_OVERVIEW : Routes.DASHBOARD}>
                    <button
                      className={`next-button ${activeIndex === lastPageDisp ? 'none' : 'visible'}`}
                      type="button"
                    >
                      Done
                    </button>
                  </Link>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default CarouselPageNavigator;
