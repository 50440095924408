import React from 'react';
import PropTypes from 'prop-types';
import Button from './andtComponents/Button';

// Please note that this Component is deprecated
// use Button instead
const LoaderButton = ({ type, isLoading, text, disabled, onClick }) => (
  <Button onClick={onClick} isLoading={isLoading} disabled={disabled || isLoading} text={text} type={type} />
);

LoaderButton.propTypes = {
  isLoading: PropTypes.bool,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
};

LoaderButton.defaultProps = {
  isLoading: false,
  disabled: false,
  type: 'submit',
};

export default LoaderButton;
