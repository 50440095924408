import React from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import enGB from 'date-fns/locale/en-GB';
import { Col, Row } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { GranularityLevelsTypes } from 'shared/constants/appConstants';

registerLocale('en-GB', enGB);

/** *******************************
 * RENDERS
 ********************************* */

const renderWeeklyFilter = (startDate, handleChangeDateStart, maxDate, minDate) => (
  <Col xl="auto" lg="auto" md="auto" sm="auto" xs="auto">
    <div style={{ 'padding-top': '1px' }} className="date-picker date-picker--interval">
      <Row>
        <DatePicker
          locale="en-GB"
          selected={startDate}
          selectsStart
          startDate={startDate}
          onChange={handleChangeDateStart}
          placeholderText="From"
          showWeekNumbers
          formatWeekNumber={(date) => moment(date).isoWeek()}
          onWeekSelect={handleChangeDateStart}
          includeDates={[
            new Date(new Date().getFullYear() - 400, 1, 1),
            new Date(new Date().getFullYear() + 400, 1, 1),
          ]}
          maxDate={maxDate}
          minDate={minDate}
        />
      </Row>
    </div>
  </Col>
);

const renderDailyFilter = (startDate, handleChangeDateStart, maxDate, minDate) => (
  <Col xl="auto" lg="auto" md="auto" sm="auto" xs="auto">
    <div style={{ 'padding-top': '1px' }} className="date-picker date-picker--interval">
      <Row>
        <DatePicker
          selected={startDate}
          selectsStart
          startDate={startDate}
          onChange={handleChangeDateStart}
          placeholderText="From"
          maxDate={maxDate}
          minDate={minDate}
        />
      </Row>
    </div>
  </Col>
);

const renderMonthlyFilter = (startDate, handleChangeDateStart, maxDate, minDate) => (
  <Col xl="auto" lg="auto" md="auto" sm="auto" xs="auto">
    <Row>
      <DatePicker
        selected={startDate}
        selectsStart
        startDate={startDate}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        onChange={handleChangeDateStart}
        placeholderText="From"
        maxDate={maxDate}
        minDate={minDate}
      />
    </Row>
  </Col>
);

const mapGranLevelTorenderFunc = new Map([
  [GranularityLevelsTypes.GRAN_LEVEL_MONTHLY, renderMonthlyFilter],
  [GranularityLevelsTypes.GRAN_LEVEL_WEEKLY, renderWeeklyFilter],
  [GranularityLevelsTypes.GRAN_LEVEL_DAILY, renderDailyFilter],
]);

// eslint-disable-next-line no-unused-vars
const DatePickerFilter = ({ startDate, handleChangeDateStart, granLevel, title, maxDate, minDate }) => (
  <div
    style={{ display: 'flex', flexDirection: 'column' }}
    className="filter-bar-inner-col-wrapper date-picker date-picker-anodot-customization"
  >
    <h5 className="filter-bar-inner-col-title">{title}</h5>
    {/* eslint-disable-next-line max-len */}
    {mapGranLevelTorenderFunc.get(granLevel || GranularityLevelsTypes.GRAN_LEVEL_MONTHLY)(
      startDate,
      handleChangeDateStart,
      maxDate,
      minDate,
    )}
  </div>
);

DatePickerFilter.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  handleChangeDateStart: PropTypes.func.isRequired,
  title: PropTypes.string,
  granLevel: PropTypes.string.isRequired,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
};

DatePickerFilter.defaultProps = {
  maxDate: null,
  minDate: null,
};

DatePickerFilter.defaultProps = {
  title: 'Start from',
};

export default DatePickerFilter;
