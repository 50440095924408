export default class HistoryTableCellClass {
  constructor(cell) {
    this.key = this.getCellData(cell, 0);
    this.value = this.getCellData(cell, 1);
  }

  getCellData = (cell, param) => {
    let result = 'No data';
    if (cell) {
      result = cell[param];
    }
    return result;
  };

  get getValue() {
    return this.value;
  }

  get getKey() {
    return this.key;
  }

  parseValue = () => {};
  parseCompareValue = () => {};
}
