/* eslint-disable consistent-return,no-nested-ternary */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { PageNames } from 'shared/constants/appConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { AzureCommonFields } from 'shared/constants/azureConstants';
import { Card, CardBody, Container } from 'reactstrap';
import Panel from 'shared/components/Panel';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import PageHeader from 'shared/components/PageHeader';
import Spinner from 'shared/components/andtComponents/Spinner';
import moment from 'moment';
import {
  BudgetAlertGranularityTypes,
  BudgetAlertsTypes,
  BudgetAmountTypes,
  BUDGETS_TABLE_COLUMNS,
  BUDGETS_TABLE_COLUMNS_WIDTH,
  BudgetTypes,
  FilterTypes,
  TempBudgetTypes,
} from 'usage/constants/usageConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { isEmailValid } from 'shared/utils/strUtil';
import DateFilter from 'shared/modules/dateFilter';
import CustomModal from 'shared/components/andtComponents/Modal';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { withMainDashboardContextConsumer } from 'app/contexts/MainDashboardContext';
import { convertDateToUTC } from 'shared/utils/dateUtil';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import BudgetForm from './components/BudgetForm';
import BudgetsTable from './components/BudgetsTable';
import EditBudgetModal from './components/EditBudgetModal';

class BudgetUsagePage extends Component {
  static propTypes = {
    usageStore: PropTypes.object.isRequired,
    usersStore: PropTypes.object.isRequired,
    invoiceStore: PropTypes.object.isRequired,
    getPageFilters: PropTypes.func.isRequired,
    filtersValuesMap: PropTypes.object.isRequired,
    mainDbData: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { mainDbData, usersStore } = this.props;
    this.state = {
      isEditModal: false,
      isLoading: false,
      filterStatusTypeMap: this.getFilterStatusTypeMapByCloudType(usersStore.currDispUserCloudAccountType),
      selectedOptionsMap: new Map([]),
      budgetAmount: mainDbData.mainDashBoardData
        ? Math.round(mainDbData.mainDashBoardData.forecastedMonthEndTotalCost)
        : 0,
      budgetFormErrorMsgs: [],
      budgetIdSelectedForDelete: null,
    };
  }

  async componentDidMount() {
    const { usageStore } = this.props;
    if (!usageStore.budgetModel.isInitialFetchDone) {
      try {
        this.setState({ isLoading: true });
        await usageStore.budgetModel.fetchData();
        this.setState({ isLoading: false });
      } catch (error) {
        this.setState({ isLoading: false });
      }
    }
  }

  prepareBudgetColumns = () => {
    const { getCurrencyNumber } = this.props;
    return BUDGETS_TABLE_COLUMNS.map((col) => ({
      name: col.columnName,
      title: col.columnTitle,
      getCellValue: col.columnField ? (row) => getCurrencyNumber(Math.round(row[col.columnField]), 0) : null,
    }));
  };

  // function that returns the initiate filters status values by the current cloud account type
  getFilterStatusTypeMapByCloudType = (cloudType) => {
    let filterStatusTypeMapByCloudType = null;
    switch (cloudType) {
      case CLOUD_TYPE_IDS.AWS:
        filterStatusTypeMapByCloudType = new Map([
          [AwsCommonFields.LINKED_ACCOUNT_NAME, 0],
          [AwsCommonFields.SERVICE, 0],
          [AwsCommonFields.DIVISION, 0],
          [AwsCommonFields.CUSTOM_TAGS, 0],
        ]);
        break;
      case CLOUD_TYPE_IDS.AZURE:
        filterStatusTypeMapByCloudType = new Map([
          [AwsCommonFields.LINKED_ACCOUNT_NAME, 0],
          [AwsCommonFields.SERVICE, 0],
          [AwsCommonFields.DIVISION, 0],
          [AzureCommonFields.RESOURCE_GROUP, 0],
        ]);
        break;
      case CLOUD_TYPE_IDS.GCP:
        filterStatusTypeMapByCloudType = new Map([
          [AwsCommonFields.LINKED_ACCOUNT_NAME, 0],
          [AwsCommonFields.SERVICE, 0],
          [AwsCommonFields.DIVISION, 0],
          [AwsCommonFields.PROJECT, 0],
        ]);
        break;
      default:
        filterStatusTypeMapByCloudType = new Map([
          [AwsCommonFields.LINKED_ACCOUNT_NAME, 0],
          [AwsCommonFields.SERVICE, 0],
          [AwsCommonFields.PROJECT, 0],
        ]);
    }
    return filterStatusTypeMapByCloudType;
  };

  getBudget() {
    const { usageStore } = this.props;
    const budgets = usageStore.getUserBudget();
    return budgets[0];
  }

  getIsAlertsValid = (tempBudgetType) => {
    const { usageStore } = this.props;
    const alerts = usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts;
    return Object.keys(alerts).reduce(
      (acc, alertId) => {
        const alertKeys = Object.keys(alerts[alertId]);
        alertKeys.forEach((key) => {
          switch (key) {
            case 'alertEmail':
              if (!alerts[alertId][key].length) {
                acc.isRecipientsSelected = false;
              } else {
                const emailsList =
                  typeof alerts[alertId][key] === 'string' ? alerts[alertId][key] : alerts[alertId][key][0];
                const emails = emailsList.split(',').map((email) => email.trim());
                const isEmailsValid = emails.every((email) => isEmailValid(email));
                acc.isRecipientsValid = isEmailsValid;
              }
              break;
            case 'alertGranularity':
              if (!alerts[alertId][key]['0']) {
                acc.isGranularitySelected = false;
              }
              break;
            case 'alertPercent':
              if (!alerts[alertId][key]) {
                acc.isPrecentSelected = false;
              }
              break;
            case 'whenToAlert':
              if (!alerts[alertId][key]['0']) {
                acc.isForecastedActualUsageSelected = false;
              }
              break;
            default:
              break;
          }
        });
        return acc;
      },
      {
        isPrecentSelected: true,
        isForecastedActualUsageSelected: true,
        isRecipientsSelected: true,
        isRecipientsValid: true,
        isGranularitySelected: true,
      },
    );
  };

  getIsBudgetValid = (budgetAmount, budgetName, isAlerts, isAlertsValidated) => {
    if (budgetAmount > 0 && budgetName.length > 0) {
      if (isAlerts) {
        if (
          isAlertsValidated &&
          isAlertsValidated.isPrecentSelected &&
          isAlertsValidated.isForecastedActualUsageSelected &&
          isAlertsValidated.isRecipientsSelected &&
          isAlertsValidated.isRecipientsValid &&
          isAlertsValidated.isGranularitySelected
        ) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  };

  getErrorMsgs = (isAlertsValid) => {
    const msgsArr = new Array(...this.state.budgetFormErrorMsgs);
    if (this.state.budgetAmount <= 0) {
      const idx = msgsArr.findIndex((msg) => msg.type === 'amount');
      if (idx < 0) {
        msgsArr.push({ type: 'amount', msg: '* Please set budget amount' });
      }
    }
    if (this.props.usageStore.budgetModel.tempBudgets[TempBudgetTypes.CREATE].budgetName.length <= 0) {
      const idx = msgsArr.findIndex((msg) => msg.type === 'name');
      if (idx < 0) {
        msgsArr.push({ type: 'name', msg: '* Please set budget name' });
      }
    }
    if (!isAlertsValid.isPrecentSelected) {
      const idx = msgsArr.findIndex((msg) => msg.type === 'precent');
      if (idx < 0) {
        msgsArr.push({ type: 'precent', msg: '* Please set alerts precentage' });
      }
    }
    if (!isAlertsValid.isForecastedActualUsageSelected) {
      const idx = msgsArr.findIndex((msg) => msg.type === 'forecasted actual usage');
      if (idx < 0) {
        msgsArr.push({
          type: 'forecasted actual usag',
          msg: '* Please select at least one from forecasted & actual usage',
        });
      }
    }
    if (!isAlertsValid.isRecipientsSelected) {
      const idx = msgsArr.findIndex((msg) => msg.type === 'recipients');
      if (idx < 0) {
        msgsArr.push({ type: 'recipients', msg: '* Please set at least one recipient for alerts' });
      }
    }
    if (!isAlertsValid.isRecipientsValid) {
      const idx = msgsArr.findIndex((msg) => msg.type === 'recipientsValidation');
      if (idx < 0) {
        msgsArr.push({
          type: 'recipientsValidation',
          msg: '* Emails are not valid. Please seperate emails by commas (,)',
        });
      }
    }
    if (!isAlertsValid.isGranularitySelected) {
      const idx = msgsArr.findIndex((msg) => msg.type === 'granularity');
      if (idx < 0) {
        msgsArr.push({ type: 'granularity', msg: '* Please select at least one granularity for budget alerts' });
      }
    }
    return msgsArr;
  };

  handleRemoveBudget = (budget) => {
    const { budgetId } = budget;
    const { usageStore } = this.props;
    const budgetIdx = usageStore.budgetModel.budgets.findIndex((x) => x.budgetId === budgetId);
    if (budgetIdx > -1) {
      usageStore.budgetModel.budgets.splice(budgetIdx, 1);
    }
    usageStore.removeBudget(budgetId);
  };

  handleCreateNewBudget = (e) => {
    e.preventDefault();
    const isAlertsValid = this.getIsAlertsValid(TempBudgetTypes.CREATE);
    const { usageStore, usersStore, mainDbData } = this.props;
    if (
      this.getIsBudgetValid(
        this.state.budgetAmount,
        usageStore.budgetModel.tempBudgets[TempBudgetTypes.CREATE].budgetName,
        usageStore.budgetModel.tempBudgets[TempBudgetTypes.CREATE].isAlerts,
        isAlertsValid,
      )
    ) {
      usageStore.budgetModel.tempBudgets[TempBudgetTypes.CREATE].newBudgetAmount = this.state.budgetAmount;
      usageStore.createBudget(this.state.selectedOptionsMap, this.state.filterStatusTypeMap);
      this.setState({
        selectedOptionsMap: new Map([]),
        filterStatusTypeMap: this.getFilterStatusTypeMapByCloudType(usersStore.currDispUserCloudAccountType),
        budgetAmount: mainDbData.mainDashBoardData
          ? Math.round(mainDbData.mainDashBoardData.forecastedMonthEndTotalCost)
          : 0,
      });
      usageStore.budgetModel.errMsgs = [];
      return true;
    }
    usageStore.budgetModel.errMsgs = this.getErrorMsgs(isAlertsValid);
  };

  handlePercentBudgetAlert = (event, tempBudgetType) => {
    const { usageStore } = this.props;
    const val = event.target.value;
    usageStore.budgetModel.tempBudgets[tempBudgetType].percentBudgetAlert = val;
  };

  handleActivateAlert = (tempBudgetType) => {
    const { usageStore } = this.props;
    usageStore.budgetModel.tempBudgets[tempBudgetType].isAlerts =
      !usageStore.budgetModel.tempBudgets[tempBudgetType].isAlerts;
  };

  handleSetBudgetAmount = (event, tempBudgetType) => {
    const { usageStore } = this.props;
    event.preventDefault();
    const val = event.target.value;
    usageStore.budgetModel.tempBudgets[tempBudgetType].newBudgetAmount = val;
  };

  handleIsFlexibleToggle = (tempBudgetType) => {
    const { usageStore } = this.props;
    usageStore.budgetModel.tempBudgets[tempBudgetType].isFlexible =
      !usageStore.budgetModel.tempBudgets[tempBudgetType].isFlexible;
  };

  handleSelectStartDate = (tempBudgetType, startDate) => {
    const { usageStore } = this.props;
    const formatedStartDate = moment(startDate).format('YYYY-MM-DD');
    usageStore.budgetModel.tempBudgets[tempBudgetType].startDate = formatedStartDate;
    // prepare budget amounts array
    this.prepareBudgetAmounts(tempBudgetType);
  };

  handleSelectEndDate = (tempBudgetType, endDate) => {
    const { usageStore } = this.props;
    const formatedEndDate = moment(endDate).format('YYYY-MM-DD');
    usageStore.budgetModel.tempBudgets[tempBudgetType].endDate = formatedEndDate;
    // prepare budget amounts array
    this.prepareBudgetAmounts(tempBudgetType);
  };

  handleSelectPeriod = (tempBudgetType, period) => {
    const { usageStore } = this.props;
    usageStore.budgetModel.tempBudgets[tempBudgetType].period = period;
  };

  handleChangeBudgetName = (event, tempBudgetType) => {
    const { usageStore } = this.props;
    event.preventDefault();
    const val = event.target.value;
    usageStore.budgetModel.tempBudgets[tempBudgetType].budgetName = val;
  };

  handleSelectLinkedAccounts = (tempBudgetType, selectedOptions) => {
    const { usageStore } = this.props;
    usageStore.budgetModel.tempBudgets[tempBudgetType].linkedAccounts = selectedOptions;
  };

  handleUpdateBudgetAlertMail = (tempBudgetType, value, id) => {
    const { usageStore } = this.props;
    const currBudgetAlerts = usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts;
    currBudgetAlerts[id].alertEmail = value;
    usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts = currBudgetAlerts;
  };

  handleUpdateBudgetAlertPercentage = (tempBudgetType, value, id) => {
    const { usageStore } = this.props;
    const currBudgetAlerts = usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts;
    currBudgetAlerts[id].alertPercent = value;
    usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts = currBudgetAlerts;
  };

  handleAddNewBudgetAlertLine = (tempBudgetType) => {
    const { usageStore } = this.props;
    if (!usageStore.budgetModel.tempBudgets[tempBudgetType].isAlerts) {
      return;
    }
    const currBudgetLines = usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts;
    currBudgetLines.push({
      alertPercent: '',
      alertEmail: '',
      whenToAlert: [BudgetAlertsTypes.FORECASTED],
      alertGranularity: [BudgetAlertGranularityTypes.DAILY],
    });
    usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts = currBudgetLines;
  };

  handleRemoveBudgetAlertLine = (tempBudgetType, id) => {
    const { usageStore } = this.props;
    if (!usageStore.budgetModel.tempBudgets[tempBudgetType].isAlerts) {
      return;
    }
    const currBudgetAlerts = usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts.filter(
      (alert, idx) => +idx !== +id,
    );
    usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts = currBudgetAlerts;
  };

  handleAlertsCheckboxChange = (checkboxType, tempBudgetType, value, alertId) => {
    const { usageStore } = this.props;
    const index = usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts[alertId][checkboxType]
      ? usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts[alertId][checkboxType].indexOf(value)
      : -1;
    if (usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts[alertId][checkboxType]) {
      if (
        usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts[alertId][checkboxType].includes(value) &&
        usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts[alertId][checkboxType].length > 1
      ) {
        usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts[alertId][checkboxType].splice(index, 1);
      } else if (
        !usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts[alertId][checkboxType].includes(value)
      ) {
        if (usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts[alertId][checkboxType]) {
          usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts[alertId][checkboxType].push(value);
        } else {
          usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts[alertId][checkboxType] = [value];
        }
      }
    }
  };

  monthDiff = (d1, d2) => {
    const date1 = convertDateToUTC(new Date(d1));
    const date2 = convertDateToUTC(new Date(d2));
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months <= 0 ? 0 : months;
  };

  prepareBudgetAmounts = (tempBudgetType) => {
    const { usageStore, mainDbData } = this.props;
    if (usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAmountType === BudgetAmountTypes.PLANNED) {
      const monthDiff = this.monthDiff(
        usageStore.budgetModel.tempBudgets[tempBudgetType].startDate,
        new Date(usageStore.budgetModel.tempBudgets[tempBudgetType].endDate),
      );
      if (usageStore.budgetModel.tempBudgets[tempBudgetType].budgetType === BudgetTypes.RECURRING || monthDiff > 12) {
        usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAmounts = [];
        const { startDate } = usageStore.budgetModel.tempBudgets[tempBudgetType];
        for (let i = 0; i < 12; i++) {
          const date = new Date(new Date(startDate).setUTCMonth(startDate.getUTCMonth() + i));
          const year = date.getUTCFullYear();
          let month = date.getUTCMonth() + 1;
          if (month < 10) {
            month = `0${month}`;
          }
          const modifiedDate = `${year}-${month}`;
          usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAmounts.push({
            date: modifiedDate,
            amount: mainDbData.mainDashBoardData
              ? Math.round(mainDbData.mainDashBoardData.forecastedMonthEndTotalCost)
              : 0,
          });
        }
      } else if (isEmptyArray(usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAmounts)) {
        usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAmounts = [];
        const { startDate } = usageStore.budgetModel.tempBudgets[tempBudgetType];
        for (let i = 0; i < monthDiff + 1; i++) {
          const date = new Date(new Date(startDate).setUTCMonth(startDate.getUTCMonth() + i));
          const year = date.getUTCFullYear();
          let month = date.getUTCMonth() + 1;
          if (month < 10) {
            month = `0${month}`;
          }
          const modifiedDate = `${year}-${month}`;
          const { budgetAmount } = this.state;
          usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAmounts.push({
            date: modifiedDate,
            amount: budgetAmount
              ? Math.round(budgetAmount / (monthDiff + 1))
              : mainDbData.mainDashBoardData
              ? Math.round(mainDbData.mainDashBoardData.forecastedMonthEndTotalCost / (monthDiff + 1))
              : 0,
          });
        }
      }
    }
  };

  handleChangeBudgetType = (tempBudgetType) => {
    if (this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].budgetType === BudgetTypes.RECURRING) {
      this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].budgetType = BudgetTypes.EXPIRING;
      this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].endDate = DateFilter.getLastDayOfCurrMonthDate();
    } else if (this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].budgetType === BudgetTypes.EXPIRING) {
      this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].budgetType = BudgetTypes.RECURRING;
      this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].endDate =
        DateFilter.getFirstDayOfCurrMonthNextYearDate();
      const alerts = this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAlerts;
      Object.keys(alerts).forEach((alertId) => {
        const alertGranKeys = Object.keys(alerts[alertId].alertGranularity);
        if (alertGranKeys.length === 1) {
          alerts[alertId].alertGranularity[0] = BudgetAlertGranularityTypes.DAILY;
        }
      });
    }
    // prepare budget amounts array
    this.prepareBudgetAmounts(tempBudgetType);
  };

  handleChangeBudgetAmountType = (tempBudgetType) => {
    // toggle budget amount type
    if (this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAmountType === BudgetAmountTypes.FIXED) {
      this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAmountType = BudgetAmountTypes.PLANNED;
    } else if (
      this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAmountType === BudgetAmountTypes.PLANNED
    ) {
      this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAmountType = BudgetAmountTypes.FIXED;
    }

    // prepare budget amounts array
    this.prepareBudgetAmounts(tempBudgetType);
  };

  handleUpdateBudgetInBudgetAmounts = (tempBudgetType, date, value) => {
    const year = new Date(date).getUTCFullYear();
    let month = new Date(date).getUTCMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    const modifiedDate = `${year}-${month}`;
    const index = this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAmounts.findIndex(
      (budget) => budget.date === modifiedDate,
    );
    this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].budgetAmounts[index].amount = value;
  };

  handleIsRelativeAlertsToggle = (tempBudgetType) => {
    this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].isRelativeAlerts =
      !this.props.usageStore.budgetModel.tempBudgets[tempBudgetType].isRelativeAlerts;
  };

  handleChangeBudgetAmount = (value) => {
    this.setState({ budgetAmount: Number(value) });
  };

  handleUpdateBudget = (e) => {
    e.preventDefault();
    const isAlertsValid = this.getIsAlertsValid(TempBudgetTypes.UPDATED);
    const tempBudget = this.props.usageStore.budgetModel.tempBudgets[TempBudgetTypes.UPDATED];
    if (this.getIsBudgetValid(this.state.budgetAmount, tempBudget.budgetName, tempBudget.isAlerts, isAlertsValid)) {
      this.props.usageStore.updateBudget(
        this.state.budgetAmount,
        this.state.selectedOptionsMap,
        this.state.filterStatusTypeMap,
      );
      this.setState({ isEditModal: false, selectedOptionsMap: new Map([]) });
    }
    return null;
  };

  handleChangeFilterType = (field) => {
    const { filterStatusTypeMap: filterStatusTypeMap1 } = this.state;
    const filterStatusTypeMap = new Map(filterStatusTypeMap1);
    const newFilterType =
      filterStatusTypeMap.get(field) === FilterTypes.INCLUDE ? FilterTypes.EXCLUDE : FilterTypes.INCLUDE;
    filterStatusTypeMap.set(field, newFilterType);
    this.setState({ filterStatusTypeMap });
  };

  handleFilterChange = (filterType, selectedOptions) => {
    const { selectedOptionsMap: selectedOptionsMap1 } = this.state;
    const selectedOptionsMap = new Map(selectedOptionsMap1);
    selectedOptionsMap.set(filterType, selectedOptions);
    this.setState({ selectedOptionsMap });
  };
  filterOptionLabel = (type, val) => {
    if (type !== AwsCommonFields.LINKED_ACCOUNT_NAME) {
      return val;
    }
    const { filtersValuesMap } = this.props;
    const linkedAccountOptions = filtersValuesMap.get(AwsCommonFields.LINKED_ACCOUNT_NAME);
    const linkAccObj = linkedAccountOptions.find((lao) => lao.linkedAccountName === val);
    return linkAccObj ? linkAccObj.displayLabel : val;
  };
  prepareFilterOptions = (type, values) => {
    if (!values) {
      return [{ value: '', label: '' }];
    }
    return values.map((val) => ({
      value: val,
      label: this.filterOptionLabel(type, val),
    }));
  };
  prepareFilter = (rawFilters, type) => {
    const filterStatusTypeMap = new Map();
    const selectedOptionsMap = new Map();
    if (!rawFilters) {
      return;
    }
    Object.entries(rawFilters).forEach(([k, v]) => {
      filterStatusTypeMap.set(k, type === FilterTypes.INCLUDE ? 0 : 1);
      selectedOptionsMap.set(k, this.prepareFilterOptions(k, v));
    });
    return [filterStatusTypeMap, selectedOptionsMap];
  };
  prepareFiltersForDisplay = (includeFilters, excludeFilters) => {
    const { usersStore } = this.props;
    const [inclFilterStatusTypeMap, incSelectedOptionsMap] = this.prepareFilter(includeFilters, FilterTypes.INCLUDE);
    const [exclFilterStatusTypeMap, exclSelectedOptionsMap] = this.prepareFilter(excludeFilters, FilterTypes.EXCLUDE);
    const selectedOptionsMap = new Map([...incSelectedOptionsMap, ...exclSelectedOptionsMap]);
    const allFilterStatus = this.getFilterStatusTypeMapByCloudType(usersStore.currDispUserCloudAccountType);
    const filterStatusTypeMap = new Map([]);
    allFilterStatus.forEach((v, k) => {
      if (inclFilterStatusTypeMap.has(k)) {
        filterStatusTypeMap.set(k, 0);
      } else if (exclFilterStatusTypeMap.has(k)) {
        filterStatusTypeMap.set(k, 1);
      } else {
        filterStatusTypeMap.set(k, v);
      }
    });
    return { selectedOptionsMap, filterStatusTypeMap };
  };
  handleEditBudget = (budget) => {
    const { selectedOptionsMap, filterStatusTypeMap } = this.prepareFiltersForDisplay(
      budget.includeFilters,
      budget.excludeFilters,
    );
    this.setState({
      budgetAmount: budget.budgetAmount,
      selectedOptionsMap,
      filterStatusTypeMap,
    });
    const currUpdateBudget = this.props.usageStore.budgetModel.tempBudgets[TempBudgetTypes.UPDATED];
    const {
      budgetName,
      period,
      startDate,
      endDate,
      isFlexible,
      budgetAlerts,
      percentBudgetAlert,
      budgetAmount,
      budgetAmounts,
      isActive,
      budgetId,
      includeFilters,
      excludeFilters,
      budgetType,
      isAlerts,
      budgetAmountType,
    } = budget;
    currUpdateBudget.budgetName = budgetName;
    currUpdateBudget.period = period;
    currUpdateBudget.startDate = startDate;
    currUpdateBudget.endDate = endDate;
    currUpdateBudget.isFlexible = isFlexible;
    currUpdateBudget.percentBudgetAlert = percentBudgetAlert;
    currUpdateBudget.budgetAmount = budgetAmount;
    currUpdateBudget.budgetAmounts = budgetAmounts;
    currUpdateBudget.isActive = isActive;
    currUpdateBudget.isAlerts = isAlerts || budgetAlerts.length;
    currUpdateBudget.newBudgetAmount = budgetAmount;
    currUpdateBudget.budgetId = budgetId;
    currUpdateBudget.includeFilters = includeFilters;
    currUpdateBudget.excludeFilters = excludeFilters;
    currUpdateBudget.budgetType = budgetType;
    currUpdateBudget.budgetAmountType = budgetAmountType;
    if (budgetAlerts && budgetAlerts.length) {
      currUpdateBudget.budgetAlerts = JSON.parse(JSON.stringify(budgetAlerts));
    }
    this.setState({ isEditModal: true });
  };

  handleDeleteBudgetClicked = (budget) => {
    const { budgetId } = budget;
    this.setState({ budgetIdSelectedForDelete: budgetId });
  };
  handleDeleteBudget = (action) => {
    if (action === 'cancel') {
      this.setState({ budgetIdSelectedForDelete: null });
    } else if (action === 'delete') {
      try {
        const budgetId = this.state.budgetIdSelectedForDelete;
        const budgetIdx = this.props.usageStore.budgetModel.budgets.findIndex((x) => x.budgetId === budgetId);
        if (budgetIdx > -1) {
          this.props.usageStore.budgetModel.budgets.splice(budgetIdx, 1);
          this.setState({ budgetIdSelectedForDelete: null });
        }
        this.props.usageStore.removeBudget(budgetId);
      } catch (error) {
        this.setState({ budgetIdSelectedForDelete: null });
      }
    }
  };
  budgetNameById = (id) => {
    let budgetName = '';
    const index = this.props.usageStore.budgetModel.budgets.findIndex((x) => x.budgetId === id);
    if (index > -1) {
      ({ budgetName } = this.props.usageStore.budgetModel.budgets[index]);
    }
    return budgetName;
  };
  budgetActionButtonHandlers = { editBudget: this.handleEditBudget, deleteBudget: this.handleDeleteBudgetClicked };

  createBudgetTimeoutModalToggle = () => {
    this.props.usageStore.budgetModel.isCreateBudgetTimeoutModal =
      !this.props.usageStore.budgetModel.isCreateBudgetTimeoutModal;
  };

  renderBudgetsTable = (currentUserReadOnly, budgets) => {
    const { usageStore } = this.props;
    const { isLoading } = usageStore.budgetModel;
    if (isLoading) {
      return <Spinner />;
    }
    return (
      <Panel>
        <Card>
          <CardBody>
            <BudgetsTable
              key={usageStore.budgetModel.budgets.length}
              budgets={budgets}
              tableColumns={this.prepareBudgetColumns()}
              budgetHandlers={this.budgetActionButtonHandlers}
              currentUserReadOnly={currentUserReadOnly}
              columnWidths={BUDGETS_TABLE_COLUMNS_WIDTH}
              usageStore={usageStore}
            />
          </CardBody>
        </Card>
      </Panel>
    );
  };

  render() {
    const { invoiceStore, getPageFilters, usersStore, usageStore } = this.props;
    const { isLoading, selectedOptionsMap, budgetAmount, filterStatusTypeMap, isEditModal, budgetIdSelectedForDelete } =
      this.state;
    const { budgets } = usageStore.budgetModel;
    const { currentDisplayedUserType, currentUserReadOnly } = usersStore;

    const fieldToFieldDistincValuesMap = getPageFilters(PageNames.BUDGET, usersStore.currDispUserCloudAccountType);

    if (isLoading || usageStore.budgetModel.createBudgetLoading) {
      return <Spinner />;
    }

    const budgetHandlers = {
      handleRemoveBudget: this.handleRemoveBudget,
      handleCreateNewBudget: this.handleCreateNewBudget,
      handleUpdateBudget: this.handleUpdateBudget,
      handlePercentBudgetAlert: this.handlePercentBudgetAlert,
      handleActivateAlert: this.handleActivateAlert,
      handleSetBudgetAmount: this.handleSetBudgetAmount,
      handleIsFlexibleToggle: this.handleIsFlexibleToggle,
      handleSelectStartDate: this.handleSelectStartDate,
      handleSelectEndDate: this.handleSelectEndDate,
      handleSelectPeriod: this.handleSelectPeriod,
      handleChangeBudgetName: this.handleChangeBudgetName,
      handleSelectLinkedAccounts: this.handleSelectLinkedAccounts,
      handleUpdateBudgetAlertMail: this.handleUpdateBudgetAlertMail,
      handleUpdateBudgetAlertPercentage: this.handleUpdateBudgetAlertPercentage,
      handleAddNewBudgetAlertLine: this.handleAddNewBudgetAlertLine,
      handleRemoveBudgetAlertLine: this.handleRemoveBudgetAlertLine,
      handleChangeBudgetAmount: this.handleChangeBudgetAmount,
      handleChangeBudgetType: this.handleChangeBudgetType,
      handleChangeBudgetAmountType: this.handleChangeBudgetAmountType,
      handleUpdateBudgetInBudgetAmounts: this.handleUpdateBudgetInBudgetAmounts,
      handleIsRelativeAlertsToggle: this.handleIsRelativeAlertsToggle,

      handleAlertsCheckboxChange: this.handleAlertsCheckboxChange,
    };
    return (
      <Container>
        <PageHeader showDate title={PageNames.BUDGET} />
        <BudgetForm
          tempBudget={usageStore.budgetModel.tempBudgets[TempBudgetTypes.CREATE]}
          handlers={budgetHandlers}
          invoiceStore={invoiceStore}
          selectedOptionsMap={selectedOptionsMap}
          handleFilterChange={this.handleFilterChange}
          fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
          filterStatusTypeMap={filterStatusTypeMap}
          handleChangeFilterType={this.handleChangeFilterType}
          classes={[]}
          budgetAmount={budgetAmount}
          currentDisplayedUserType={currentDisplayedUserType}
          currentUserReadOnly={currentUserReadOnly}
          userMessages={usageStore.budgetModel.userMessages}
          hasBudgets={usageStore.budgetModel.budgets.length > 0}
          budgetFormErrorMsgs={usageStore.budgetModel.errMsgs}
        />
        {usageStore.budgetModel.budgets && usageStore.budgetModel.budgets.length
          ? this.renderBudgetsTable(currentUserReadOnly, budgets)
          : null}
        <EditBudgetModal
          tempBudget={usageStore.budgetModel.tempBudgets[TempBudgetTypes.UPDATED]}
          handlers={budgetHandlers}
          invoiceStore={invoiceStore}
          selectedOptionsMap={selectedOptionsMap}
          handleFilterChange={this.handleFilterChange}
          fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
          filterStatusTypeMap={filterStatusTypeMap}
          handleChangeFilterType={this.handleChangeFilterType}
          open={isEditModal}
          onClose={() => {
            this.setState({ isEditModal: false, selectedOptionsMap: new Map([]) });
          }}
          budgetAmount={budgetAmount}
          currentDisplayedUserType={currentDisplayedUserType}
          currentUserReadOnly={currentUserReadOnly}
          budgetFormErrorMsgs={usageStore.budgetModel.errMsgs}
        />
        <CustomModal
          open={usageStore.budgetModel.isCreateBudgetTimeoutModal}
          onClose={this.createBudgetTimeoutModalToggle}
          saveTitle="Got It"
          onSave={() => {}}
          title=""
        >
          Budget Creation is in progress - you will be notified once it is ready
        </CustomModal>
        <DeleteWarningModal
          deletedItemName={this.budgetNameById(budgetIdSelectedForDelete)}
          isOpen={budgetIdSelectedForDelete}
          handleDelete={this.handleDeleteBudget}
          warningMessage="Be advise you are about to delete Budget"
          modalTitle="Delete Budget"
        />
      </Container>
    );
  }
}

const ObserverBudgetUsage = withInvoiceFiltersContextConsumer(
  withUserSettingsConsumer(withMainDashboardContextConsumer(observer(BudgetUsagePage))),
);
export default ObserverBudgetUsage;
