import { COST_CONFIGURATION, getLinkedAccountName } from 'recommendationsNew/consts';
import moment from 'moment';
import { calcNumOfMonthsToBreakEven } from '../../../shared/utils/calcUtil';

export const getNumericValue = (value) => {
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string') {
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
  }
  return undefined;
};

export const getBooleanValue = (value) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (typeof value === 'number') {
    return value === 1 ? 'Yes' : 'No';
  }
  if (typeof value === 'string') {
    return value === 'true' ? 'Yes' : 'No';
  }
  return undefined;
};

/* uuid, action, suggestion_status_id: status, is_suggestion_active: isActive, is_recommendation_active: isRecommActive,
   recommendation_status_id: recommStatus, recommendation_creation_time: creationTime,
   initial_creation_date: initialCreationDate, recommendation_update_time: updateTime, alert_id: alertId,
   rejected_reason_id: rejReason, exclude_message: excludeMessage, */
export const BASE_PROPERTIES = {
  LABELS: {
    label: 'Labels',
    getterFunction: (recommendation) => recommendation.labels,
  },
  LINKED_ACCOUNT: {
    label: 'Linked Account',
    labelGetter: (cloudType) => getLinkedAccountName(cloudType),
    getterFunction: (recommendation) => `${recommendation.linkedAccountName} (${recommendation.linkedAccountId})`,
  },
  REGION: {
    label: 'Region',
    getterFunction: (recommendation) => recommendation.region,
  },
  RESOURCE_ID: {
    label: 'Resource Id',
    getterFunction: (recommendation) => recommendation.resourceId,
  },
  RESOURCE_NAME: {
    label: 'Resource Name',
    getterFunction: (recommendation) => recommendation.resourceName,
  },
  RESOURCE_TAGS: {
    label: 'Resource Tags',
    getterFunction: (recommendation) =>
      !!recommendation.resourceTags && typeof recommendation.resourceTags === 'object'
        ? Object.entries(recommendation.resourceTags).map(([k, v]) => `${k}: ${v}`)
        : null,
  },
  SIGNATURE: {
    label: 'Signature',
    getterFunction: (recommendation) => recommendation.signature,
  },
  SUBSCRIPTION_ID: {
    label: 'Subscription Id',
    getterFunction: (recommendation) => recommendation.subscriptionId, // Maybe should be part of the Linked Account?
  },
  TAGS: {
    label: 'Tags',
    getterFunction: (recommendation) => {
      if (!recommendation.customTags && !recommendation.enrichmentTags) {
        return null;
      }
      let tags = '';
      if (recommendation?.customTags) {
        tags = Object.entries(recommendation.customTags)
          ?.map(([k, v]) => `${k}: ${v}`)
          .join(', ');
      }
      if (recommendation?.enrichmentTags) {
        tags += Object.entries(recommendation.enrichmentTags)
          ?.map(([k, v]) => `${k}: ${v}`)
          .join(', ');
      }
      return tags;
    },
  },
  QUANTITY: {
    label: 'Quantity',
    getterFunction: (recommendation) =>
      recommendation?.recData?.resource_quantity ||
      recommendation?.recData?.resources_quantity ||
      recommendation?.recData?.quantity,
  },
  STARTING_TIME: {
    label: 'Starting Time',
    getterFunction: (recommendation) => {
      if (recommendation?.recData?.starting_time) {
        return typeof recommendation?.recData?.starting_time === 'string'
          ? recommendation?.recData?.starting_time
          : moment(recommendation?.recData?.starting_time * 1000).format('YYYY-MM-DD HH:mm:ss');
      }
      return undefined;
    },
  },
  MULTI_AZ: {
    label: 'Multi-AZ',
    getterFunction: (recommendation, optionIndex) => {
      let isMultiAZ = getBooleanValue(recommendation?.recData?.is_multi_az || recommendation?.recData?.multi_az);
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        isMultiAZ = getBooleanValue(recommendation.recData.alternatives[optionIndex]?.is_multi_az);
      }
      return isMultiAZ;
    },
  },
  DAYS_TO_CHECK: {
    label: 'Days to Check',
    getterFunction: (recommendation) => recommendation?.recData?.days_to_check,
  },
  CPU_UTIL: {
    label: 'Max CPU Utilization (%)',
    isPercent: true,
    getterFunction: (recommendation, optionIndex) => {
      let cpuUtil = recommendation?.recData?.cpu_util;
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        cpuUtil = recommendation.recData.alternatives[optionIndex]?.max_cpu_utilization_percentage;
      }
      return cpuUtil;
    },
  },
  OPERATION_SYSTEM: {
    label: 'Operation System',
    getterFunction: (recommendation) => recommendation.recData.operation_system || recommendation.recData.os,
  },
};

export const COST_PROPERTIES = {
  POTENTIAL_SAVINGS: {
    label: 'Potential Savings',
    isNoColorInOptions: true,
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return `${recommendation.recData?.alternatives[optionIndex]?.potential_savings}%`;
      }
      if (!recommendation.annualSavings?.[COST_CONFIGURATION.UNBLENDED]) {
        return undefined;
      }
      return `${recommendation.annualSavings[COST_CONFIGURATION.UNBLENDED].toLocaleString()}
      (${
        recommendation.annualCurrentCost?.[COST_CONFIGURATION.UNBLENDED] > 0
          ? (
              (100 * recommendation.annualSavings[COST_CONFIGURATION.UNBLENDED]) /
              recommendation.annualCurrentCost[COST_CONFIGURATION.UNBLENDED]
            ).toLocaleString(undefined, { maximumFractionDigits: 2 })
          : 0
      }% lower Annual cost)`;
    },
  },
  CURRENT_ANNUAL_COST: {
    label: 'Current Annual Cost',
    labelForOption: 'Annual Cost',
    isCurrency: true,
    isNoColorInOptions: true,
    getterFunction: (recommendation) =>
      getNumericValue(recommendation.annualCurrentCost[COST_CONFIGURATION.UNBLENDED])?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
  },
  RECOMMENDED_ANNUAL_COST: {
    label: 'New Annual Cost',
    labelForOption: 'Annual Cost',
    isCurrency: true,
    isNoColorInOptions: true,
    getterFunction: (recommendation, optionIndex) => {
      let newAnnualCost =
        recommendation.annualCurrentCost[COST_CONFIGURATION.UNBLENDED] -
        recommendation.annualSavings[COST_CONFIGURATION.UNBLENDED];
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        const option = recommendation.recData.alternatives[optionIndex];
        newAnnualCost = option?.annual_cost || option?.cost; // "cost" is to support old name
      }
      return getNumericValue(newAnnualCost)?.toLocaleString(undefined, { maximumFractionDigits: 2 });
    },
  },
  HOURLY_COST: {
    label: 'Hourly Cost',
    isCurrency: true,
    getterFunction: (recommendation, optionIndex) => {
      let hourlyCost = recommendation?.recData?.hourly_cost;
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        hourlyCost = recommendation.recData.alternatives[optionIndex]?.hourly_cost;
      }
      return getNumericValue(hourlyCost)?.toLocaleString(undefined, { maximumFractionDigits: 2 });
    },
  },
};

export const EC2_PROPERTIES = {
  ENVIRONMENT: {
    label: 'Environment',
    getterFunction: (recommendation) => recommendation.recData.environment,
  },
  INSTANCE_TYPE: {
    label: 'Instance Type',
    getterFunction: (recommendation) =>
      recommendation.recData.instance_type_model
        ? `${recommendation.recData.instance_type_model}.${recommendation.recData.instance_type_size}`
        : recommendation.recData.instance_type_size,
  },
  PROJECT: {
    label: 'Project',
    getterFunction: (recommendation) => recommendation.recData.project,
  },
};

export const DB_PROPERTIES = {
  DB_TYPE: {
    label: 'DB Type',
    getterFunction: (recommendation) => recommendation?.recData?.db_type,
  },
  DB_NAME: {
    label: 'DB Name',
    getterFunction: (recommendation) => recommendation?.recData?.db_name,
  },
  NUM_OF_CONNECTION: {
    label: 'Connections in period',
    getterFunction: (recommendation) => {
      const numOfConnections = recommendation?.recData?.num_of_connections;
      const maxNumOfConnections =
        recommendation?.recData?.max_num_of_connections || recommendation?.recData?.max_connections || 0;
      if (typeof numOfConnections === 'object') {
        return maxNumOfConnections === 0 ? '0' : maxNumOfConnections;
      }
      if (numOfConnections !== undefined) {
        return numOfConnections === 0 ? '0' : numOfConnections;
      }
      return maxNumOfConnections === 0 ? '0' : maxNumOfConnections;
    },
  },
};

export const ENGINE_PROPERTIES = {
  ENGINE: {
    label: 'Engine',
    getterFunction: (recommendation) => recommendation?.recData?.engine,
  },
  ENGINE_VERSION: {
    label: 'Engine Version',
    getterFunction: (recommendation) => recommendation?.recData?.engine_version,
  },
};

export const RDS_PROPERTIES = {
  CURRENT_INSTANCE_TYPE: {
    label: 'Current Instance Type',
    getterFunction: (recommendation) =>
      recommendation?.recData?.instance_type || recommendation?.recData?.instance_type_current,
  },
  RECOMMENDED_INSTANCE_TYPE: {
    label: 'Recommended Instance Type',
    labelForOption: 'Instance Type',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.instance_type;
      }
      return recommendation?.recData?.recommended_instance_type;
    },
  },
  CURRENT_INSTANCE_FAMILY: {
    label: 'Instance Family',
    getterFunction: (recommendation) => recommendation.recData?.instance_family,
  },
  RECOMMENDED_INSTANCE_FAMILY: {
    label: 'Recommended Instance Family',
    labelForOption: 'Instance Family',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.instance_family;
      }
      return recommendation?.recData?.recommended_instance_family;
    },
  },
  CURRENT_INSTANCE_TYPE_FAMILY: {
    label: 'Instance Type Family',
    getterFunction: (recommendation) =>
      recommendation.recData?.instance_type_family ||
      recommendation.recData?.instance_type_model ||
      recommendation.recData?.model,
  },
  RECOMMENDED_INSTANCE_TYPE_FAMILY: {
    label: 'Recommended Instance Type Family',
    labelForOption: 'Instance Type Family',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return (
          recommendation.recData.alternatives[optionIndex]?.instance_type_family ||
          recommendation.recData.alternatives[optionIndex]?.instance_type_model ||
          recommendation.recData.alternatives[optionIndex]?.model
        );
      }
      return recommendation?.recData?.recommended_instance_type_family;
    },
  },
  V_CPU: {
    label: 'vCPUs',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return getNumericValue(recommendation.recData.alternatives[optionIndex]?.cpu);
      }
      return getNumericValue(recommendation?.recData?.cpu);
    },
  },
  STORAGE: {
    label: 'Storage',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return (
          recommendation.recData.alternatives[optionIndex]?.storage_type ||
          recommendation?.recData?.alternatives[optionIndex]?.instance_storage
        );
      }
      return recommendation.recData?.storage_type || recommendation?.recData?.instance_storage;
    },
  },
  STORAGE_CLASS: {
    label: 'Storage Class',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.storage_class;
      }
      return recommendation.recData?.storage_class;
    },
  },
  MEMORY: {
    label: 'Memory',
    isGBSize: true,
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return getNumericValue(recommendation.recData.alternatives[optionIndex]?.memory);
      }
      return getNumericValue(recommendation?.recData?.memory);
    },
  },
  PHYSICAL_PROCESSOR: {
    label: 'Physical Processor',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.physical_processor;
      }
      return recommendation?.recData?.physical_processor;
    },
  },
  RI_COVERAGE: {
    label: 'RI Coverage',
    getterFunction: (recommendation, optionIndex) => {
      let riCoverage = getBooleanValue(recommendation?.recData?.ri_coverage);
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        riCoverage = getBooleanValue(recommendation.recData.alternatives[optionIndex]?.ri_coverage);
      }
      return riCoverage;
    },
  },
};

export const RESERVED_INSTANCE_PROPERTIES = {
  RI_TYPE: {
    label: 'Current RI Type',
    getterFunction: () => 'On-Demand',
  },
  RECOMMENDED_RI_TYPE: {
    label: 'Recommended RI Type',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.payment_plan;
      }
      return recommendation?.recData?.recommended_plan;
    },
  },
  EXPECTED_ROI_PERIOD: {
    label: 'Expected ROI Period',
    getterFunction: (recommendation) =>
      recommendation?.annualCurrentCost?.[COST_CONFIGURATION.UNBLENDED] &&
      recommendation?.annualSavings?.[COST_CONFIGURATION.UNBLENDED]
        ? `${calcNumOfMonthsToBreakEven(
            recommendation.annualCurrentCost[COST_CONFIGURATION.UNBLENDED] -
              recommendation.annualSavings[COST_CONFIGURATION.UNBLENDED],
            recommendation.annualCurrentCost[COST_CONFIGURATION.UNBLENDED],
          )} Months`
        : '',
  },
};

export const AZURE_PROPERTIES = {
  RESOURCE_GROUP: {
    label: 'Resource Group',
    getterFunction: (recommendation) => recommendation?.recData?.resource_group,
  },
  SKU_NAME: {
    label: 'Instance Type (SKU)',
    getterFunction: (recommendation) => recommendation?.recData?.sku_name,
  },
};

export const AZURE_RESERVED_INSTANCE_PROPERTIES = {
  RECOMMENDED_PLAN: {
    label: 'Recommended Plan',
    getterFunction: (recommendation) => recommendation?.recData?.recommended_plan,
  },
  SERVICE: {
    label: 'Service',
    getterFunction: (recommendation) => recommendation?.service,
  },
};

export const DISK_PROPERTIES = {
  DISK_NAME: {
    label: 'Disk Name',
    getterFunction: (recommendation) => recommendation?.recData?.disk_name,
  },
  DISK_TYPE: {
    label: 'Disk Type',
    getterFunction: (recommendation) => recommendation?.recData?.disk_type,
  },
  DISK_SIZE: {
    label: 'Disk Size',
    isGBSize: true,
    getterFunction: (recommendation) => recommendation?.recData?.disk_size || recommendation?.recData?.disk_size_gb,
  },
};

export const VOLUMES_PROPERTIES = {
  ATTACHED_VOLUMES: {
    label: 'Attached Volumes',
    getterFunction: (recommendation) => recommendation?.recData?.attached_volumes,
  },
  ATTACHED_VOLUMES_COST: {
    label: 'Attached Volumes Cost',
    getterFunction: (recommendation) =>
      Object.entries(
        typeof recommendation.recData.attached_volumes_costs === 'string'
          ? JSON.parse(recommendation.recData.attached_volumes_costs)
          : recommendation.recData.attached_volumes_costs,
      ).map(([id, cost]) => ({ id, cost })),
    isTable: true,
    columns: [
      {
        id: 'id',
        label: 'Volume ID',
      },
      {
        id: 'cost',
        label: 'Cost',
        isCurrency: true,
      },
    ],
  },
};

export const LOAD_BALANCER_PROPERTIES = {
  LOAD_BALANCER_NAME: {
    label: 'Load Balancer Name',
    getterFunction: (recommendation) => recommendation?.recData?.load_balancer_name,
  },
  LOAD_BALANCER_TYPE: {
    label: 'Load Balancer Type',
    getterFunction: (recommendation) => recommendation?.recData?.load_balancer_type,
  },
};
