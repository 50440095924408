import React, { useContext } from 'react';
import HeatMapLeftPanel from 'recommendationsNew/components/heatMap/heatMapLeftPanel';
import HeatMapCardList from 'recommendationsNew/components/heatMap/heatMapCards/heatMapCardList';
import HeatMapContext from 'recommendationsNew/components/heatMap/contexts/heatMapContext';

import classes from './heatMap.module.scss';

const HeatMapContent = () => {
  const { heatMapGroupByOptions: groupByOptionsContext, setHeatMapGroupByOptions } = useContext(HeatMapContext);

  const optionClicked = (option) => {
    const updatedOptions = groupByOptionsContext.map((obj) => {
      if (obj.id === option.id) {
        return { ...obj, default: !obj.default };
      }
      return obj;
    });
    setHeatMapGroupByOptions([...updatedOptions]);
  };

  return groupByOptionsContext?.length ? (
    <div className={classes.content}>
      <HeatMapLeftPanel onGroupByOptionClick={optionClicked} />
      <HeatMapCardList onCardClose={optionClicked} />
    </div>
  ) : (
    <div />
  );
};

export default HeatMapContent;
