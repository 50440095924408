import React, { useState } from 'react';
import Button from 'shared/components/andtComponents/Button';
import Modal from 'shared/components/andtComponents/Modal';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import toast from 'shared/components//andtComponents/Toast';
import useSlack from 'shared/components/slackIntegration/hooks/useSlack';
import SlackChannelSelect from 'shared/components/andtComponents/SlackCahnnelSelect/SlackChannelSelect';

const TestSlackButton = () => {
  const [isTestModal, setIsTestModal] = useState(false);
  const [selectedChannels, setSelectedChannels] = useState([]);

  const { testSlackChannel } = useSlack();
  const { mutateAsync: sendTestMessage } = testSlackChannel();

  const openTestModal = (event) => {
    event.preventDefault();
    setIsTestModal(true);
  };

  const onSelectChannel = (channels) => {
    if (!channels || !channels[0]) {
      setSelectedChannels([]);
      return;
    }
    const channel = {
      id: channels.at(-1).value,
      name: channels.at(-1).label,
    };
    setSelectedChannels([channel]);
  };

  const sendTest = async (event) => {
    event.preventDefault();
    try {
      await sendTestMessage(selectedChannels[0]);
      toast.success('Test message was sent');
    } catch (error) {
      const { response } = error;
      if (response.data.clientMessage) {
        toast.error(error.response.data.clientMessage);
      } else {
        toast.error('Something went wrong please try again later');
      }
    }
  };

  return (
    <div>
      <Button
        text="Test"
        isTextButton
        overrideStyles={{ padding: 0 }}
        onClick={openTestModal}
        icon={() => <GenerateIcon iconName={ICONS.hexagonCheck.name} />}
      />
      <Modal
        title="Send Test Message"
        saveTitle="Send test"
        onClose={(value) => setIsTestModal(value)}
        open={isTestModal}
        onSave={sendTest}
        saveDisabled={selectedChannels.length < 1}
      >
        <SlackChannelSelect
          onChangeHandler={onSelectChannel}
          savedChannels={selectedChannels}
          id="integration_tester"
        />
      </Modal>
    </div>
  );
};

export default TestSlackButton;
