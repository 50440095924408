import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import { BILLINGS_API_NAME, RECOMMENDATIONS_HEATMAP_ROOT, STATLE_TIME } from 'recommendationsNew/consts';

function fetchGroupByOptions() {
  return API.get(BILLINGS_API_NAME, `${RECOMMENDATIONS_HEATMAP_ROOT}/groupByOptions`);
}

export default function useGroupByOptions() {
  const queryKey = [apiConstants.QUERY_KEYS.RECOMMENDATIONS_GROUP_BY_OPTIONS];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchGroupByOptions: () =>
      useQuery(queryKey, () => fetchGroupByOptions(), {
        retry: false,
        staleTime: STATLE_TIME,
      }),
  };
}
