import React, { useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import PageHeader from 'shared/components/PageHeader';
import Spinner from 'shared/components/andtComponents/Spinner';
import { useAccountStatus } from './hooks/UseAccountStatus';
import styles from './AccountStatus.module.scss';

const AccountStatus = () => {
  const [tempAccId, setTempAccId] = useState('');
  const [accountId, setAccountId] = useState('');
  const { data: accountsData, isLoading } = useAccountStatus(accountId);
  const formatBool = (bool) => bool ? 'True' : 'False';
  return (
    <div className="ps-3 pe-3">
      <PageHeader title="AWS Account Status" />
      <div className={styles.menu}>
        <Input
          value={tempAccId}
          onChange={(e) => setTempAccId(e.target.value)}
          placeholder="Type AWS account id  "
          name="search-viewpoint"
          isSearch
          searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
          type="text"
        />
        <Button
          text="Search"
          isLoading={isLoading}
          onClick={() => {
            setAccountId(tempAccId);
          }}
        />
      </div>
      {isLoading && <Spinner />}
      {accountsData && (
        <div className={styles.dataTable}>
          <p>User interface table</p>
          <span>{formatBool(accountsData.userInterfaceTable)}</span>
          <p>Admin schema accounts table</p>
          <span>{formatBool(accountsData.adminSchemaTable)}</span>
          <p>Accounts table</p>
          <span>{formatBool(accountsData.accountsTable)}</span>
          <p>Rebilling tables</p>
          <span>{formatBool(accountsData.rebillingTables)}</span>
          <p>Divisions table</p>
          <span>{formatBool(accountsData.divisionsTable)}</span>
          <p>Features control table</p>
          <span>{formatBool(accountsData.featuresControlTable)}</span>
          <p>Roles table</p>
          <span>{formatBool(accountsData.rolesTable)}</span>
          <p>Schema created</p>
          <span>{formatBool(accountsData.dbSchema)}</span>
          <p>Main dashboard created</p>
          <span>{formatBool(accountsData.mainDashboard)}</span>
          <p>Last processed date</p>
          <span>{accountsData.lastProcessed || 'Not processed'}</span>
          <p>Bucket access</p>
          <span>{formatBool(accountsData.invoiceBucketAccessible)}</span>
          <p>Anodot bucket has role</p>
          <span>{formatBool(accountsData.invoiceUpdateBucketHasRole)}</span>
        </div>
      )}
    </div>
  );
};

export default AccountStatus;
