const trim = (value: string): string => value.trim();
const isString = (value) => typeof value === 'string';
const isNotEmpty = (value) => Boolean(value);
const isUnique = () => {
  const processed = new Set();

  return (value) => {
    if (processed.has(value)) {
      return false;
    }
    processed.add(value);
    return true;
  };
};

/* it allows to manipulate classNames like <MyComponent className={classes("a", false && "b", true && "c")/> */
export const classes = (...classNames: any[]): string =>
  classNames.filter(isString).filter(isNotEmpty).map(trim).filter(isUnique())
.join(' ');
