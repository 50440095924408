import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { dateToStr } from 'shared/utils/dateUtil';
import uuidv1 from 'uuid';

const root = '/api/v1/divisions/customers/report';

const getCustomersReport = () => API.get('billings', root);

const createCustomersReport = async (params) => {
  const reportParams = { ...params };
  reportParams.uuid = uuidv1();
  reportParams.creationDate = dateToStr(new Date(), 'yyyy-mm-dd hh:mm:ss');
  const body = { ...reportParams };
  await API.post('billings', root, { body });
};

const sendCustomersReport = async (params) => {
  const reportParams = { ...params };
  reportParams.uuid = uuidv1();
  await API.post('billings', `${root}/send/${reportParams.uuid}`, { body: reportParams });
};

const deleteCustomersReport = async ({ reportId }) => {
  await API.del('billings', `${root}?reportId=${reportId}`);
};

const updateCustomersReport = async (params) => {
  const reportParams = { ...params };
  reportParams.creationDate = dateToStr(new Date(), 'yyyy-mm-dd hh:mm:ss');
  const body = { ...reportParams };
  await API.put('billings', root, { body });
};

export default function useCustomerReports() {
  const queryClient = useQueryClient();

  const queryKey = [apiConstants.QUERY_KEYS.CUSTOMER_REPORTS];

  const addCustomerReportMutation = useMutation(createCustomersReport, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const updateCustomerReportMutation = useMutation(updateCustomersReport, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const deleteCustomerReportMutation = useMutation(deleteCustomersReport, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const sendCustomerReportMutation = useMutation(sendCustomersReport);

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchCustomerReports: () => useQuery(queryKey, getCustomersReport, { retry: false }),
    addCustomerReportMutation,
    deleteCustomerReportMutation,
    updateCustomerReportMutation,
    sendCustomerReportMutation,
  };
}
