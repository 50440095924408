/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Spinner from 'shared/components/andtComponents/Spinner';
import { useBusinessMapping } from 'usage/containers/BusinessMapping/hooks/react-query/useBusinessMapping';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import { Routes } from 'shared/constants/routes';
import SwitchButton from 'shared/components/andtComponents/Switch';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import { buildStartAndEndDate, standardDateFormat } from 'shared/utils/dateUtil';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import CreateViewpointModal from '../CreateBusinessMappingViewpointModal/CreateBusinessMappingViewpointModal';
import styles from './BusinessMappingViewpoints.module.scss';
import BusinessMappingPiePreview from '../BusinessMappingPiePreview/BusinessMappingPiePreview';

const BusinessMappingViewpoints = ({ history, location }) => {
  const { usersStore } = useRootStore();
  const searchParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState('');
  const isK8S = searchParams.get('isK8S') === 'true' || false;
  const setIsK8S = (value) => {
    searchParams.set('isK8S', value);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };
  const k8sSupport = checkFeatureFlag(usersStore, ACCOUNT_FEATURES.K8S);
  const [editingViewpoint, setEditingViewpoint] = useState(null);
  const [deletingViewpoint, setDeletingViewpoint] = useState(null);
  const [createViewpointModalOpen, setCreateViewpointModalOpen] = useState(false);
  const {
    deleteBusinessMappingViewpoint,
    updateBusinessMappingViewpoint,
    cloneBusinessMappingViewpoint,
    addBusinessMappingViewpoint,
    fetchBusinessMappingViewpoints,
  } = useBusinessMapping();
  const { data: viewpoints, isLoading } = fetchBusinessMappingViewpoints();
  const { mutateAsync: deleteViewpoint, isLoading: isDeleteLoading } = deleteBusinessMappingViewpoint();
  const { mutateAsync: cloneViewpoint, isLoading: isCloneLoading } = cloneBusinessMappingViewpoint();
  const { mutateAsync: editViewpoint, isLoading: isEditLoading } = updateBusinessMappingViewpoint();
  const { mutateAsync: createViewpoint, isLoading: isCreateLoading } = addBusinessMappingViewpoint();
  const { startDate, endDate } = buildStartAndEndDate(null, null, false);
  return (
    <div>
      <div className={styles.subtitle}>
        <span>
          Segment your data into Viewpoints of different business units such as projects, products, teams and more.
        </span>
      </div>
      <div className={styles.menu}>
        <div className={styles.leftMenu}>
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Viewpoint Name  "
            name="search-viewpoint"
            isSearch
            searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
            type="text"
          />
          {k8sSupport && (
            <SwitchButton
              label="Kubernetes Viewpoints"
              isChecked={isK8S}
              onChange={() => setIsK8S(!isK8S)}
              className={styles.switch}
            />
          )}
          {!isLoading && <p>Total # of Viewpoints: {viewpoints ? viewpoints.length : 0}</p>}
          <p>
            Showing for period: {standardDateFormat(startDate)} - {standardDateFormat(endDate)}
          </p>
        </div>
        <div className={styles.rightMenu}>
          <Button
            text="Manage Global Mappings"
            isGhost
            onClick={() => history.push(Routes.BUSINESS_MAPPING_GLOBAL_GROUPS)}
            automationId="manageGlobalMapping"
          />
          <div className={styles.buttonContainer}>
            <Button
              text="New Viewpoint"
              onClick={() => setCreateViewpointModalOpen(true)}
              disabled={usersStore.currentUserReadOnly}
              icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
              automationId="newViewpoint"
            />
          </div>
        </div>
      </div>
      <div>
        {isLoading && <Spinner />}
        <div className={styles.list}>
          {(viewpoints || [])
            .filter((v) => !!v.k8s === isK8S)
            .filter((v) => !search || (v.name || '').toLowerCase().includes(search.toLowerCase()))
            .map((viewpoint) => (
              <BusinessMappingPiePreview
                key={viewpoint.uuid}
                viewpoint={viewpoint}
                staticMode
                menu={
                  <ButtonDropdown
                    isTextButton
                    tooltipText="Options"
                    icon={() => <GenerateIcon iconName={ICONS.verticalDots.name} className={styles.menuIcon} />}
                    automationId="viewpointOptions"
                  >
                    <li onClick={() => history.push(`${Routes.BUSINESS_MAPPING}/${viewpoint.uuid}`)} id="editViewpoint">
                      <span>
                        <GenerateIcon iconName={ICONS.edit.name} />
                        Edit
                      </span>
                    </li>
                    <li onClick={() => setEditingViewpoint(viewpoint)} id="editNameViewpoint">
                      <span>
                        <GenerateIcon iconName={ICONS.edit.name} />
                        Edit Name
                      </span>
                    </li>
                    <li
                      onClick={async () => {
                        await cloneViewpoint({ id: viewpoint.uuid });
                        toast.success(`Viewpoint ${viewpoint.name} was duplicated.`);
                      }}
                      id="duplicateViewpoint"
                    >
                      <span>
                        <GenerateIcon iconName={ICONS.clone.name} />
                        Duplicate
                      </span>
                    </li>
                    <li onClick={() => setDeletingViewpoint(viewpoint)} id="deleteViewpoint">
                      <span>
                        <GenerateIcon iconName={ICONS.delete.name} />
                        Delete
                      </span>
                    </li>
                  </ButtonDropdown>
                }
              />
            ))}
        </div>
      </div>
      {(createViewpointModalOpen || editingViewpoint) && (
        <CreateViewpointModal
          isOpen
          isEditMode={editingViewpoint}
          takenNames={viewpoints
            .filter((v) => !editingViewpoint || v.uuid !== editingViewpoint.uuid)
            .map((v) => v.name)}
          onSave={async (...props) => {
            await (editingViewpoint ? editViewpoint : createViewpoint)(...props);
            if (editingViewpoint) {
              toast.success(`Viewpoint ${editingViewpoint.name} was saved.`);
            } else {
              toast.success('Viewpoint was created.');
            }
          }}
          usersStore={usersStore}
          viewpoint={editingViewpoint || null}
          isLoading={isEditLoading || isDeleteLoading || isCloneLoading || isCreateLoading}
          closeModal={() => {
            setCreateViewpointModalOpen(null);
            setEditingViewpoint(null);
          }}
        />
      )}
      {deletingViewpoint && (
        <DeleteWarningModal
          isOpen
          handleDelete={async (action) => {
            if (action === 'delete') {
              await deleteViewpoint({ id: deletingViewpoint.uuid });
              toast.success(`Viewpoint ${deletingViewpoint.name} was deleted.`);
            }
            setDeletingViewpoint(null);
          }}
          deletedItemName={deletingViewpoint.name}
          modalTitle="Delete Viewpoint"
          buttonDisabled={isDeleteLoading}
        />
      )}
    </div>
  );
};

BusinessMappingViewpoints.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(BusinessMappingViewpoints);
