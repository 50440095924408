/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';
import If from 'shared/components/SimpleIf';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';

const styles = {
  root: {
    // width: 500,
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
  marginRight: {
    marginRight: '5px',
  },
};

function ContentDetail({ display, value, classes, tooltip }) {
  return (
    <If cond={value !== null || value !== undefined}>
      <tr className="daily-rec__content-detail" style={{ borderTop: '2px solid #e6e6e6' }}>
        <th className="daily-rec__content-detail-display">
          {tooltip ? (
            <>
              <span className={classes.marginRight}>{display}</span>
              <Tooltip title={tooltip}>
                <span>
                  <InfoIcon />
                </span>
              </Tooltip>
            </>
          ) : (
            display
          )}
        </th>
        {value && value.length > 40 ? (
          <div>
            <Grid container>
              <Grid item>
                <Tooltip title={value} placement="right" interactive classes={{ tooltip: classes.noMaxWidth }}>
                  <td className="daily-rec__content-detail-value">
                    {/* <td> */}
                    {value.slice(0, 37)}
                    ...
                  </td>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        ) : (
          <td className="daily-rec__content-detail-value">{value}</td>
        )}
      </tr>
    </If>
  );
}

ContentDetail.propTypes = {
  display: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  tooltip: PropTypes.string,
};
ContentDetail.defaultProps = {
  tooltip: null,
};
export default withStyles(styles)(ContentDetail);
