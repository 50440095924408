/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { MoreVertical } from 'react-feather';

class AlertActionButton extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { alert, handler } = this.props;
    // const { rec, handlers } = this.props;
    // const { recCompletedHandler, recExcludeHandler } = handlers;
    return (
      <div>
        <IconButton
          className="material-table__toolbar-button"
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          size="large">
          <MoreVertical size={22} color="black" />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="material-table__filter-menu"
        >
          <MenuItem
            onClick={() => {
              handler('read', alert);
            }}
            className="material-table__filter-menu-item"
          >
            <h6>Mark as read</h6>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handler('week', alert);
            }}
            className="material-table__filter-menu-item"
          >
            <h6>Dismiss for one week</h6>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default AlertActionButton;
AlertActionButton.propTypes = {
  handler: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
};
