/* eslint-disable max-len */
import { RecommsRoutes, Routes } from 'shared/constants/routes';
import { ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { ACCOUNT_FEATURES, CLOUD_TYPE_IDS, UsersAccountTypeId, UsersType } from 'users/constants/usersConstants';

// menu options common populations
const ALL_USER_ACCOUNT_TYPES = [
  UsersAccountTypeId.REGULAR,
  UsersAccountTypeId.RESELLER,
  UsersAccountTypeId.MULTI_ACCOUNT,
  UsersAccountTypeId.RESELLER_MULTI_ACCOUNT,
  UsersAccountTypeId.MULTI_CLOUDS_ACCOUNT,
  UsersAccountTypeId.RESELLER_MULTI_CLOUDS_ACCOUNT,
];
const ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD = [
  UsersAccountTypeId.REGULAR,
  UsersAccountTypeId.RESELLER,
  UsersAccountTypeId.MULTI_ACCOUNT,
  UsersAccountTypeId.RESELLER_MULTI_ACCOUNT,
];

const ALL_CLOUD_TYPES = [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.GCP, CLOUD_TYPE_IDS.AZURE, CLOUD_TYPE_IDS.MULTI];
const ALL_CLOUD_TYPES_EXCEPT_MULTI = [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.GCP, CLOUD_TYPE_IDS.AZURE];

const ALL_VALID_USERS = [
  UsersType.SUPER_USER,
  UsersType.USER,
  UsersType.BETA_USER,
  UsersType.USER_ON_BOARDED,
  UsersType.SUB_USER,
  UsersType.RESELLER,
  UsersType.RESELLER_CUSTOMER,
  UsersType.RESELLER_CUSTOMER_EDP,
  UsersType.BUSINESS_ADMIN,
  UsersType.RESELLER_BUSINESS_ADMIN,
];

const isK8sDisabled = ({ accountTypeId, accountFeatures }) => {
  if (
    !(accountFeatures || []).includes(ACCOUNT_FEATURES.K8S) ||
    [UsersAccountTypeId.MULTI_ACCOUNT, UsersAccountTypeId.RESELLER_MULTI_ACCOUNT].includes(accountTypeId)
  ) {
    return true;
  }
  return false;
};
const isK8sPreviewDisabled = (params) => !isK8sDisabled({ ...params });

const sideBarMenuOptions = [
  {
    value: 'dashboard',
    title: 'Dashboard',
    route: Routes.DASHBOARD,
    icon: ICONS.objectsColumn.name,
    usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
    cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
    userTypes: ALL_VALID_USERS,
  },
  {
    value: 'recommendations',
    title: 'Recommendations',
    icon: ICONS.recommendations.name,
    // disableFF: ACCOUNT_FEATURES.RECOMMENDATIONS,
    children: [
      {
        value: 'dashboard',
        title: 'Dashboard',
        route: `${Routes.RECOMMENDATIONS_DASHBOARD}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'dailyRecommendations',
        title: 'Daily Recommendations',
        route: `${Routes.DAILY_RECOMMENDATIONS}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.USER_ON_BOARDED,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
        ],
      },
      {
        value: 'history',
        title: 'History',
        route: `${Routes.RECOMMENDATION_HISTORY}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'reports',
        title: 'Reports',
        route: `${Routes.RECOMMENDATIONS_REPORTS}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'exclusions',
        title: 'Exclusions',
        route: `${Routes.MANAGE_RECOMMENDATIONS}`,
        enableFF: ACCOUNT_FEATURES.EXCLUSIONS,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'preferences',
        title: 'Preferences',
        route: `${Routes.RECOMMENDATION_PREFERENCES}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
    ],
  },
  {
    value: 'recommendationsNew',
    title: 'Recommendations',
    icon: ICONS.recommendations.name,
    enableFF: ACCOUNT_FEATURES.RECOMMENDATIONS,
    children: [
      {
        value: 'recommendation360',
        title: 'Recommendation 360°',
        route: `${Routes.RECOMMENDATION_360}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'recommendationExplorer',
        title: 'Recommendation Explorer',
        route: `${Routes.RECOMMENDATION_EXPLORER}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
    ],
  },
  {
    value: 'costAndUsage',
    title: 'Cost & Usage',
    icon: ICONS.costAndUsage.name,
    children: [
      {
        value: 'costAndUsageExplorer',
        title: 'Cost & Usage Explorer',
        route: `${Routes.COST_USAGE_EXPLORER}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'reports',
        title: 'Reports',
        route: `${Routes.REPORTS}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'dashboards',
        title: 'Dashboards',
        route: `${Routes.CUSTOM_DASHBOARD}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'panels',
        title: 'Panels',
        route: `${Routes.CUSTOM_DASHBOARDS_PANELS}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'assets',
        title: 'Assets',
        route: `${Routes.ASSETS}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'resourceExplorer',
        title: 'Resource Explorer',
        route: `${Routes.RESOURCE_EXPLORER}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'metricsExplorer',
        title: 'Metrics Explorer',
        route: `${Routes.METRICS_EXPLORER}`,
        enableFF: ACCOUNT_FEATURES.METRICS_EXPLORER,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
    ],
  },
  {
    value: 'costAllocation',
    title: 'Cost Allocation',
    icon: ICONS.linkHorizontal.name,
    children: [
      {
        value: 'views',
        title: 'Views',
        route: `${Routes.VIEWS}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'virtualTags',
        title: 'Virtual Tags',
        route: `${Routes.VIRTUAL_TAGS}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'filterGroup',
        title: 'Filter Group',
        route: Routes.FILTER_GROUP,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'businessMapping',
        title: 'Business Mapping',
        route: Routes.BUSINESS_MAPPING,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'enrichmentTags',
        title: 'Enrichment Tags',
        route: Routes.ACCOUNT_TAGS,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
    ],
  },
  {
    value: 'commitment',
    title: 'Commitment',
    icon: ICONS.commitment.name,
    children: [
      {
        value: 'dashboard',
        title: 'Dashboard',
        route: `${Routes.COMMITMENT_DASHBOARD}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'spAnalyzer',
        title: 'SP Analyzer',
        route: `${Routes.SAVINGS_PLANS_ANALYZER}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'riUtilization',
        title: 'RI Utilization',
        route: `${Routes.RI_UTILIZATION}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
          UsersType.USER_ON_BOARDED,
          UsersType.RESELLER_CUSTOMER,
        ],
        isDisabled({ cloudTypeId, usersStore }) {
          if (cloudTypeId === CLOUD_TYPE_IDS.AZURE) {
            const isPAYG = usersStore.accountInfo.azure_interface_type === 'PAYG_EXPORT';
            if (!usersStore?.currUserInitDone || isPAYG) {
              return true;
            }
          }
          return false;
        },
      },
      {
        value: 'spUtilization',
        title: 'SP Utilization',
        route: `${Routes.SAVINGS_PLANS_UTILIZATION}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
          UsersType.USER_ON_BOARDED,
        ],
      },
      // For multi account - enable only in AWS
      {
        value: 'riUtilization',
        title: 'RI Utilization',
        route: `${Routes.RI_UTILIZATION}`,
        usersAccountTypes: [UsersAccountTypeId.MULTI_ACCOUNT, UsersAccountTypeId.RESELLER_MULTI_ACCOUNT],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
          UsersType.USER_ON_BOARDED,
        ],
      },
      {
        value: 'spUtilization',
        title: 'SP Utilization',
        route: `${Routes.SAVINGS_PLANS_UTILIZATION}`,
        usersAccountTypes: [UsersAccountTypeId.MULTI_ACCOUNT, UsersAccountTypeId.RESELLER_MULTI_ACCOUNT],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
          UsersType.USER_ON_BOARDED,
        ],
      },
    ],
  },
  {
    value: 'services',
    title: 'Services',
    icon: ICONS.sitemap.name,
    children: [
      {
        value: 'ec2',
        title: 'EC2',
        route: `${Routes.EC2}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'rds',
        title: 'RDS',
        route: `${Routes.RDS}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 's3',
        title: 'S3',
        route: `${Routes.S3}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'k8s',
        title: 'K8S',
        route: `${Routes.K8S_USAGE}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [UsersType.SUPER_USER],
      },
      {
        value: 'virtualMachines',
        title: 'Virtual Machines',
        route: `${Routes.AZURE_VIRTUAL_MACHINES}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'blockBlobStorage',
        title: 'Block Blob Storage',
        route: `${Routes.AZURE_STORAGE}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'dbCosts',
        title: 'DB Costs',
        route: `${Routes.AZURE_DB_COSTS}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'bigQuery',
        title: 'Big Query',
        route: `${Routes.BIG_QUERY}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.GCP],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
    ],
  },
  {
    value: 'kubernetes',
    title: 'Kubernetes',
    icon: ICONS.kubernetes.name,
    children: [
      {
        value: 'costAndUsageExplorer',
        title: 'Cost & Usage Explorer',
        route: `${Routes.K8S_USAGE}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.GCP, CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.USER_ON_BOARDED,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
        ],
        isDisabled: isK8sDisabled,
      },
      // K8s - CUE - preview
      {
        value: 'costAndUsageExplorer',
        title: 'Cost & Usage Explorer',
        route: `${Routes.K8S_PREVIEW}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
        isDisabled: isK8sPreviewDisabled,
      },
      {
        value: 'preferences',
        title: 'Preferences',
        route: `${Routes.K8S_PREFERENCES}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
        ],
        isDisabled: isK8sDisabled,
      },
      {
        value: 'assets',
        title: 'Assets',
        route: `${Routes.K8S_ASSETS}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.GCP, CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.USER_ON_BOARDED,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
        ],
        isDisabled: isK8sDisabled,
      },
    ],
  },
  {
    value: 'monitoring',
    title: 'Monitoring',
    icon: ICONS.monitor.name,
    iconSize: '24px',
    children: [
      {
        value: 'anomalyDetection',
        title: 'Anomaly Detection',
        route: `${Routes.ANOMALY_DETECTION}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'budgetNew',
        title: 'Budget',
        route: `${Routes.BUDGET_NEW}`,
        usersAccountTypes: [
          UsersAccountTypeId.REGULAR,
          UsersAccountTypeId.RESELLER,
          UsersAccountTypeId.MULTI_CLOUDS_ACCOUNT,
          UsersAccountTypeId.RESELLER_MULTI_CLOUDS_ACCOUNT,
        ],
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'idleInstances',
        title: 'Idle Instances',
        route: `${Routes.IDLE_INSTANCES}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'alerts',
        title: 'Alerts',
        route: `${Routes.CUE_ALERTS}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
    ],
  },
  {
    value: 'welcome',
    title: 'Welcome',
    route: Routes.ACCOUNTS_OVERVIEW,
    icon: ICONS.objectsColumn.name,
    usersAccountTypes: [0],
    cloudTypes: [undefined, NaN],
    userTypes: [UsersType.NEW_USER],
  },
  {
    value: 'partner',
    title: 'Partner',
    icon: ICONS.userGroup.name,
    children: [
      {
        value: 'billingRules',
        title: 'Billing Rules',
        route: Routes.AWS_BILLING_RULES,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'billingStatus',
        title: 'Billing Status',
        route: Routes.AWS_CUSTOMERS,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'billingStatus',
        title: 'Billing Status',
        route: Routes.AZURE_CUSTOMERS,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AZURE],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'billingHistory',
        title: 'Billing History',
        route: Routes.CUSTOMERS_HISTORY,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'credits',
        title: 'Credits',
        route: Routes.AWS_CREDITS_NEW,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'manageCustomers',
        title: 'Manage Customers',
        route: Routes.CUSTOMERS_MANAGE,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.GCP, CLOUD_TYPE_IDS.AZURE],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'Ppreferences',
        title: 'Preferences',
        route: `${Routes.REBILLING_PREFERENCES}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'reports',
        title: 'Reports',
        route: Routes.CUSTOMERS_REPORTS,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
    ],
  },
  {
    value: 'costGpt',
    title: 'CostGPT',
    route: Routes.COST_GPT,
    icon: ICONS.anobot.name,
    usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
    cloudTypes: ALL_CLOUD_TYPES,
    userTypes: ALL_VALID_USERS,
  },
  {
    value: 'featureFlags',
    title: 'Feature Flags',
    route: Routes.FEATURE_FLAGS,
    icon: ICONS.gear.name,
    usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
    cloudTypes: ALL_CLOUD_TYPES,
    userTypes: [UsersType.SUPER_USER],
  },
];

export const pagesIdsRoutes = {
  2: Routes.FEATURE_FLAGS,
  3: Routes.ACCOUNT,
  5: Routes.SETTINGS,
  6: Routes.ALERTS,
  7: Routes.DASHBOARD,
  8: Routes.NEW_AWS_USER,
  9: Routes.CONNECT_AZURE_EA_ACCOUNT,
  10: Routes.NEW_AZURE_USER,
  11: Routes.NEW_GCP_USER,
  12: Routes.WELCOME,
  13: Routes.EC2,
  14: Routes.RDS,
  15: Routes.S3,
  16: Routes.EBS,
  17: Routes.REPORTS,
  18: Routes.COMMITMENT_DASHBOARD,
  19: Routes.RI_UTILIZATION,
  20: Routes.SAVINGS_PLANS_UTILIZATION,
  21: Routes.SAVINGS_PLANS_ANALYZER,
  22: Routes.SAVINGS_PLANS_ANALYZER_REC,
  23: Routes.DAILY_RECOMMENDATIONS,
  25: Routes.RECOMMENDATION_HISTORY,
  26: Routes.RECOMMENDATIONS_REPORTS,
  27: Routes.RECOMMENDATIONS_DASHBOARD,
  28: Routes.MANAGE_RECOMMENDATIONS,
  29: '/recommendations/version-upgrade-recs/:id',
  30: '/recommendations/operation-system-recs/:id',
  31: '/recommendations/ip-unattached-recs/:id',
  32: '/recommendations/ec2-idle-recs/:id',
  33: '/recommendations/ebs-unattached-recs/:id',
  34: '/recommendations/idle-load-balancer-recs/:id',
  35: '/recommendations/region-migration-recs/:id',
  36: '/recommendations/ec2-low-cpu-usage-recs/:id',
  37: '/recommendations/ec2-schedule-recs/:id',
  38: '/recommendations/ec2-savings-plans-recs/:id',
  39: '/recommendations/dynamodb-idle-recs/:id',
  40: '/recommendations/rds-ri-recs/:id',
  41: '/recommendations/rds-version-upgrade-recs/:id',
  42: '/recommendations/rds-idle-recs/:id',
  43: '/recommendations/s3-idle-recs/:id',
  44: '/recommendations/s3-versioning-recs/:id',
  45: '/recommendations/s3-multipart-upload-recs/:id',
  46: '/recommendations/ebs-type-change-recs/:id',
  47: '/recommendations/ebs-type-and-size-change-recs/:id',
  48: '/recommendations/rds-type-change-recs/:id',
  49: '/recommendations/ebs-outdated-snapshot-recs/:id',
  50: '/recommendations/ebs-upgrade-recs/:id',
  51: '/recommendations/ec2-udt-recs/:id',
  52: '/recommendations/ec2-stopped-instance-recs/:id',
  53: '/recommendations/elasticache-ri-recs/:id',
  54: '/recommendations/opensearch-ri-recs/:id',
  55: '/recommendations/redshift-util-low-recs/:id',
  56: '/recommendations/es-util-low-recs/:id',
  57: '/recommendations/nat-gateway-util-low-recs/:id',
  58: '/recommendations/elasticache-util-low-recs/:id',
  59: '/recommendations/documentdb-util-low-recs/:id',
  60: '/recommendations/neptune-util-low-recs/:id',
  61: '/recommendations/kinesis-util-low-recs/:id',
  62: '/recommendations/azure-vm-ri-recs/:id',
  63: '/recommendations/azure-disk-unattached-recs/:id',
  64: '/recommendations/azure-disk-type-change-recs/:id',
  65: '/recommendations/azure-ip-unattached-recs/:id',
  66: '/recommendations/azure-maria-idle-recs/:id',
  67: '/recommendations/azure-mysql-idle-recs/:id',
  68: '/recommendations/azure-postgres-idle-recs/:id',
  69: '/recommendations/azure-cosmos-idle-recs/:id',
  70: '/recommendations/azure-sql-idle-recs/:id',
  71: '/recommendations/azure-vm-stopped-recs/:id',
  72: '/recommendations/azure-vm-idle-recs/:id',
  73: '/recommendations/azure-idle-load-balancer-recs/:id',
  74: '/recommendations/azure-db-ri-recs/:id',
  75: '/recommendations/azure-vm-rightsizing-recs/:id',
  76: '/recommendations/azure-cosmos-right-sizing-recs/:id',
  77: '/recommendations/azure-app-right-sizing-recs/:id',
  78: '/recommendations/azure-storage-recs/:id',
  79: '/recommendations/azure-cosmos-db-reserved-capacity-recs/:id',
  80: '/recommendations/azure-data-explorer-reserved-capacity-recs/:id',
  81: '/recommendations/azure-mysql-reserved-capacity-recs/:id',
  82: '/recommendations/azure-postgresql-reserved-capacity-recs/:id',
  83: '/recommendations/azure-redis-reserved-capacity-recs/:id',
  84: '/recommendations/azure-sql-data-warehouse-reserved-capacity-recs/:id',
  85: '/recommendations/azure-sql-reserved-capacity-recs/:id',
  86: '/recommendations/azure-snapshot-migration-recs/:id',
  87: '/recommendations/azure-outdated-snapshot-recs/:id',
  88: '/recommendations/azure-kusto-unused-data-recs/:id',
  89: '/recommendations/azure-app-service-reserved-capacity-recs/:id',
  90: '/recommendations/azure-db-reserved-capacity-recs/:id',
  91: '/recommendations/gcp-ip-idle-recs/:id',
  92: '/recommendations/gcp-vm-stopped-recs/:id',
  93: '/recommendations/gcp-vm-idle-recs/:id',
  94: '/recommendations/gcp-vm-rightsizing-recs/:id',
  95: '/recommendations/gcp-disk-unattached-recs/:id',
  96: Routes.BUDGET,
  97: Routes.COST_USAGE_EXPLORER,
  98: Routes.ASSETS,
  99: Routes.CUSTOM_DASHBOARD,
  100: Routes.CUSTOM_DASHBOARDS_PANELS,
  101: Routes.CUSTOM_DASHBOARD_INSTANCE,
  102: Routes.VIRTUAL_TAGS,
  103: Routes.FILTER_GROUP,
  104: Routes.VIEWS,
  105: Routes.CUE_ALERTS,
  106: Routes.ANOMALY_DETECTION,
  107: Routes.RESOURCE_EXPLORER,
  108: Routes.CPU_METRCIS,
  110: Routes.BIG_QUERY,
  111: Routes.GcpComputeEngine,
  112: Routes.GcpAppEngine,
  113: Routes.GcpCloudSQL,
  114: Routes.AzureActiveDirectory,
  115: Routes.AZURE_VIRTUAL_MACHINES,
  116: Routes.AZURE_STORAGE,
  117: Routes.AZURE_DB_COSTS,
  118: Routes.GCP_COST_SUMMARY,
  120: Routes.K8S_USAGE,
  121: Routes.K8S_PREFERENCES,
  122: Routes.AZURE_CUSTOMERS,
  123: Routes.AWS_CUSTOMERS,
  124: Routes.AWS_BILLING_RULES,
  125: Routes.AWS_CREDITS,
  126: Routes.AZURE_BILLING_RULES,
  127: Routes.CUSTOMERS_MANAGE,
  129: Routes.CUSTOMERS_HISTORY,
  130: Routes.AZURE_CUSTOMERS_HISTORY,
  132: Routes.IDLE_INSTANCES,
  133: Routes.BUSINESS_MAPPING,
  134: Routes.ACCOUNT_TAGS,
  149: Routes.K8S_ASSETS,
  151: Routes.AWS_CREDITS_NEW,
  150: Routes.CUSTOM_DASHBOARD_TEMPLATE_INSTANCE,
  152: Routes.K8S_PREVIEW,
  153: Routes.COST_GPT,
  154: Routes.ACCOUNTS_OVERVIEW,
  155: Routes.ONBOARDING_AWS,
  156: Routes.BUDGET_NEW,
  157: Routes.RECOMMENDATIONS_NEW,
  158: Routes.ONBOARDING_AZURE,
};

// TODO - Mick - replace this with menu options configuration
export const mapNotAllowedPathToUsertType = new Map([
  [
    Routes.ADMIN_CONTROL_PANEL,
    [
      UsersType.USER,
      UsersType.NEW_USER,
      UsersType.UN_AUTH_USER,
      UsersType.BETA_USER,
      UsersType.USER_ON_BOARDED,
      UsersType.USER_END_TRIAL,
      UsersType.SUB_USER,
      UsersType.RESELLER,
      UsersType.RESELLER_CUSTOMER,
      UsersType.RESELLER_CUSTOMER_EDP,
      UsersType.BUSINESS_ADMIN,
      UsersType.RESELLER_BUSINESS_ADMIN,
    ],
  ],
  [
    Routes.FEATURE_FLAGS,
    [
      UsersType.USER,
      UsersType.NEW_USER,
      UsersType.UN_AUTH_USER,
      UsersType.BETA_USER,
      UsersType.USER_ON_BOARDED,
      UsersType.USER_END_TRIAL,
      UsersType.SUB_USER,
      UsersType.RESELLER,
      UsersType.RESELLER_CUSTOMER,
      UsersType.RESELLER_CUSTOMER_EDP,
      UsersType.BUSINESS_ADMIN,
      UsersType.RESELLER_BUSINESS_ADMIN,
    ],
  ],
  [Routes.ACCOUNTS_OVERVIEW, [UsersType.USER_END_TRIAL]],
  [Routes.ONBOARDING_AWS, [UsersType.USER_END_TRIAL]],
  [Routes.ONBOARDING_AZURE, [UsersType.USER_END_TRIAL]],
  [Routes.ACCOUNT, [UsersType.USER_END_TRIAL]],
  [Routes.FEATURE_FLAGS, [UsersType.USER_END_TRIAL]],
  [Routes.SETTINGS, [UsersType.USER_END_TRIAL]],
  [Routes.ALERTS, [UsersType.USER_END_TRIAL]],
  [Routes.DASHBOARD, [UsersType.USER_END_TRIAL]],
  [Routes.NEW_AWS_USER, [UsersType.USER_END_TRIAL]],
  [Routes.CONNECT_AZURE_EA_ACCOUNT, [UsersType.USER_END_TRIAL]],
  [Routes.NEW_AZURE_USER, [UsersType.USER_END_TRIAL]],
  [Routes.NEW_GCP_USER, [UsersType.USER_END_TRIAL]],
  [
    Routes.WELCOME,
    [UsersType.USER_END_TRIAL, UsersType.SUPER_USER, UsersType.USER, UsersType.SUB_USER, UsersType.BETA_USER],
  ],
  [Routes.EC2, [UsersType.USER_END_TRIAL]],
  [Routes.RDS, [UsersType.USER_END_TRIAL]],
  [Routes.S3, [UsersType.USER_END_TRIAL]],
  [Routes.EBS, [UsersType.USER_END_TRIAL]],
  [Routes.REPORTS, [UsersType.USER_END_TRIAL]],
  [Routes.COMMITMENT_DASHBOARD, [UsersType.USER_END_TRIAL]],
  [Routes.RI_UTILIZATION, [UsersType.USER_END_TRIAL]],
  [Routes.SAVINGS_PLANS_UTILIZATION, [UsersType.USER_END_TRIAL]],
  [Routes.SAVINGS_PLANS_ANALYZER, [UsersType.USER_END_TRIAL]],
  [Routes.SAVINGS_PLANS_ANALYZER_REC, [UsersType.USER_END_TRIAL]],
  [Routes.DAILY_RECOMMENDATIONS, [UsersType.USER_END_TRIAL]],
  [Routes.RECOMMENDATION_HISTORY, [UsersType.USER_END_TRIAL]],
  [Routes.RECOMMENDATION_PREFERENCES, [UsersType.USER_END_TRIAL]],
  [Routes.RECOMMENDATIONS_REPORTS, [UsersType.USER_END_TRIAL]],
  [Routes.RECOMMENDATIONS_DASHBOARD, [UsersType.USER_END_TRIAL]],
  [Routes.MANAGE_RECOMMENDATIONS, [UsersType.USER_END_TRIAL]],
  ...RecommsRoutes.map((recommRoute) => [recommRoute, [UsersType.USER_END_TRIAL]]),
  [Routes.RECOMMENDATIONS_NEW, [UsersType.USER_END_TRIAL]],
  [Routes.RECOMMENDATION_EXPLORER, [UsersType.USER_END_TRIAL]],
  [Routes.RECOMMENDATION_360, [UsersType.USER_END_TRIAL]],
  [Routes.BUDGET, [UsersType.USER_END_TRIAL, UsersType.USER_ON_BOARDED, UsersType.NEW_USER]],
  [Routes.BUDGET_NEW, [UsersType.USER_END_TRIAL, UsersType.USER_ON_BOARDED, UsersType.NEW_USER]],
  [Routes.COST_USAGE_EXPLORER, [UsersType.USER_END_TRIAL]],
  [Routes.METRICS_EXPLORER, [UsersType.USER_END_TRIAL]],
  [Routes.BUSINESS_MAPPING, [UsersType.USER_END_TRIAL]],
  [Routes.BUSINESS_MAPPING_GROUPS, [UsersType.USER_END_TRIAL]],
  [Routes.BUSINESS_MAPPING_GLOBAL_GROUPS, [UsersType.USER_END_TRIAL]],
  [Routes.ACCOUNT_TAGS, [UsersType.USER_END_TRIAL]],
  [Routes.ASSETS, [UsersType.USER_END_TRIAL]],
  [Routes.CUSTOM_DASHBOARD, [UsersType.USER_END_TRIAL]],
  [Routes.CUSTOM_DASHBOARDS_PANELS, [UsersType.USER_END_TRIAL]],
  [Routes.CUSTOM_DASHBOARD_INSTANCE, [UsersType.USER_END_TRIAL]],
  [Routes.CUSTOM_DASHBOARD_TEMPLATE_INSTANCE, [UsersType.USER_END_TRIAL]],
  [Routes.VIRTUAL_TAGS, [UsersType.USER_END_TRIAL]],
  [Routes.FILTER_GROUP, [UsersType.USER_END_TRIAL]],
  [Routes.VIEWS, [UsersType.USER_END_TRIAL]],
  [Routes.CUE_ALERTS, [UsersType.USER_END_TRIAL]],
  [Routes.ADMIN_ACCOUNTS_STATUS, [UsersType.USER_END_TRIAL]],
  [Routes.ANOMALY_DETECTION, [UsersType.USER_END_TRIAL, UsersType.USER_ON_BOARDED, UsersType.NEW_USER]],
  [Routes.ANOMALY_DETECTION_NEW, [UsersType.USER_END_TRIAL, UsersType.USER_ON_BOARDED, UsersType.NEW_USER]],
  [Routes.RESOURCE_EXPLORER, [UsersType.USER_END_TRIAL]],
  [
    Routes.CPU_METRCIS,
    [UsersType.USER_END_TRIAL, UsersType.USER, UsersType.SUB_USER, UsersType.USER_ON_BOARDED, UsersType.NEW_USER],
  ],
  [Routes.BIG_QUERY, [UsersType.USER_END_TRIAL]],
  [Routes.GcpComputeEngine, [UsersType.USER_END_TRIAL]],
  [Routes.GcpAppEngine, [UsersType.USER_END_TRIAL]],
  [Routes.GcpCloudSQL, [UsersType.USER_END_TRIAL]],
  [Routes.AzureActiveDirectory, [UsersType.USER_END_TRIAL]],
  [Routes.AZ, [UsersType.USER_END_TRIAL]],
  [Routes.AZURE_VIRTUAL_MACHINES, [UsersType.USER_END_TRIAL]],
  [Routes.AZURE_STORAGE, [UsersType.USER_END_TRIAL]],
  [Routes.AZURE_DB_COSTS, [UsersType.USER_END_TRIAL]],
  [Routes.GCP_COST_SUMMARY, [UsersType.USER_END_TRIAL]],
  [Routes.K8S_PREVIEW, [UsersType.USER_END_TRIAL]],
  [Routes.K8S_USAGE, [UsersType.USER_END_TRIAL]],
  [Routes.K8S_PREFERENCES, [UsersType.USER_END_TRIAL]],
  [Routes.K8S_ASSETS, [UsersType.USER_END_TRIAL]],
  [
    Routes.AZURE_CUSTOMERS,
    [
      UsersType.USER_END_TRIAL,
      UsersType.SUPER_USER,
      UsersType.USER,
      UsersType.NEW_USER,
      UsersType.UN_AUTH_USER,
      // UsersType.BETA_USER,
      UsersType.USER_ON_BOARDED,
      UsersType.SUB_USER,
    ],
  ],
  [
    Routes.AWS_CUSTOMERS,
    [
      UsersType.USER_END_TRIAL,
      UsersType.SUPER_USER,
      UsersType.USER,
      UsersType.NEW_USER,
      UsersType.UN_AUTH_USER,
      // UsersType.BETA_USER,
      UsersType.USER_ON_BOARDED,
      UsersType.SUB_USER,
    ],
  ],
  [
    Routes.AWS_BILLING_RULES,
    [
      UsersType.USER_END_TRIAL,
      UsersType.SUPER_USER,
      UsersType.USER,
      UsersType.NEW_USER,
      UsersType.UN_AUTH_USER,
      // UsersType.BETA_USER,
      UsersType.USER_ON_BOARDED,
      UsersType.SUB_USER,
    ],
  ],
  [
    Routes.AWS_CREDITS_NEW,
    [
      UsersType.USER_END_TRIAL,
      UsersType.SUPER_USER,
      UsersType.USER,
      UsersType.NEW_USER,
      UsersType.UN_AUTH_USER,
      // UsersType.BETA_USER,
      UsersType.USER_ON_BOARDED,
      UsersType.SUB_USER,
    ],
  ],
  [
    Routes.REBILLING_PREFERENCES,
    [
      UsersType.USER_END_TRIAL,
      UsersType.SUPER_USER,
      UsersType.USER,
      UsersType.NEW_USER,
      UsersType.UN_AUTH_USER,
      // UsersType.BETA_USER,
      UsersType.USER_ON_BOARDED,
      UsersType.SUB_USER,
    ],
  ],
  [
    Routes.AZURE_BILLING_RULES,
    [
      UsersType.USER_END_TRIAL,
      UsersType.SUPER_USER,
      UsersType.USER,
      UsersType.NEW_USER,
      UsersType.UN_AUTH_USER,
      // UsersType.BETA_USER,
      UsersType.USER_ON_BOARDED,
      UsersType.SUB_USER,
    ],
  ],
  [
    Routes.CUSTOMERS_MANAGE,
    [
      UsersType.USER_END_TRIAL,
      UsersType.SUPER_USER,
      UsersType.USER,
      UsersType.NEW_USER,
      UsersType.UN_AUTH_USER,
      // UsersType.BETA_USER,
      UsersType.USER_ON_BOARDED,
      UsersType.SUB_USER,
    ],
  ],
  [
    Routes.CUSTOMERS_HISTORY,
    [
      UsersType.USER_END_TRIAL,
      UsersType.SUPER_USER,
      UsersType.USER,
      UsersType.NEW_USER,
      UsersType.UN_AUTH_USER,
      // UsersType.BETA_USER,
      UsersType.USER_ON_BOARDED,
      UsersType.SUB_USER,
    ],
  ],
  [
    Routes.CUSTOMERS_REPORTS,
    [
      UsersType.USER_END_TRIAL,
      UsersType.SUPER_USER,
      UsersType.USER,
      UsersType.NEW_USER,
      UsersType.UN_AUTH_USER,
      // UsersType.BETA_USER,
      UsersType.USER_ON_BOARDED,
      UsersType.SUB_USER,
    ],
  ],
  [
    Routes.AZURE_CUSTOMERS_HISTORY,
    [
      UsersType.USER_END_TRIAL,
      UsersType.SUPER_USER,
      UsersType.USER,
      UsersType.NEW_USER,
      UsersType.UN_AUTH_USER,
      // UsersType.BETA_USER,
      UsersType.USER_ON_BOARDED,
      UsersType.SUB_USER,
    ],
  ],
  [Routes.IDLE_INSTANCES, [UsersType.UN_AUTH_USER, UsersType.USER_END_TRIAL]],
  [Routes.COST_GPT, [UsersType.UN_AUTH_USER, UsersType.USER_END_TRIAL]],
  [Routes.REGISTER, []],
  [Routes.REGISTER_MSP, []],
  [Routes.LOG_IN, []],
  ['/', []],
]);

export const mapUserTypeToRoute = new Map([
  [UsersType.SUPER_USER, Routes.DASHBOARD],
  [UsersType.USER, Routes.DASHBOARD],
  [UsersType.SUB_USER, Routes.DASHBOARD],
  [UsersType.BETA_USER, Routes.DASHBOARD],
  [UsersType.USER_ON_BOARDED, Routes.WELCOME],
  [UsersType.NEW_USER, Routes.ACCOUNTS_OVERVIEW],
  [UsersType.USER_END_TRIAL, Routes.DASHBOARD],
  [UsersType.RESELLER, Routes.DASHBOARD],
  [UsersType.RESELLER_CUSTOMER, Routes.COST_USAGE_EXPLORER],
  [UsersType.RESELLER_CUSTOMER_EDP, Routes.COST_USAGE_EXPLORER],
  [UsersType.BUSINESS_ADMIN, Routes.DASHBOARD],
  [UsersType.RESELLER_BUSINESS_ADMIN, Routes.DASHBOARD],
]);

export const getSidebarItemsByAccountTypeIdAndCloudTypeId = (
  accountTypeId,
  cloudTypeId,
  userTypeId,
  accountFeatures,
  usersStore,
) => {
  function isAvailableMenuOption(menuOption) {
    // if matching all account type, cloud type and user type conditions
    // and isDisabled returns false
    // but hide disableFF records
    // or show when enableFF is turn on
    // or isDisabled function is false
    return (
      ((menuOption.usersAccountTypes || []).includes(accountTypeId) &&
        (menuOption.cloudTypes || []).includes(cloudTypeId) &&
        (menuOption.userTypes || []).includes(userTypeId) &&
        menuOption.enableFF === undefined &&
        !(menuOption.disableFF && (accountFeatures || []).includes(menuOption.disableFF)) &&
        !(
          menuOption.isDisabled &&
          menuOption.isDisabled({ accountTypeId, cloudTypeId, userTypeId, accountFeatures, usersStore })
        )) ||
      (menuOption.enableFF && (accountFeatures || []).includes(menuOption.enableFF))
    );
  }

  const visibleOptions = [];
  // go over all menu options
  sideBarMenuOptions.forEach((menuOption) => {
    if (menuOption?.disableFF && (accountFeatures || []).includes(menuOption?.disableFF)) {
      return;
    }
    if (menuOption.enableFF) {
      if (
        ((accountFeatures || []).includes(menuOption.enableFF) && !isAvailableMenuOption(menuOption)) ||
        !(accountFeatures || []).includes(menuOption.enableFF)
      ) {
        return;
      }
    }
    // Top level only
    if (!menuOption.children) {
      if (isAvailableMenuOption(menuOption)) {
        visibleOptions.push(menuOption);
      }
    } else {
      // if contains sub links, display only if there are available links
      const availableLinks = menuOption.children.filter((subOption) => isAvailableMenuOption(subOption));
      if (availableLinks.length > 0) {
        visibleOptions.push({ ...menuOption, children: availableLinks });
      }
    }
  });

  return visibleOptions;
};
