import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
// eslint-disable-next-line max-len
import NewHistoryTable from 'recommendations/containers/RecommendationsHistory/components/DevExpressTable/DevExpRecommendationHistoryTable';
import useRecommendationsHistory from 'recommendations/hooks/react-query/useRecommendationsHistory';
import { useRecommendationByType } from 'recommendations/hooks/react-query/useRecommendationByType';
import { DEFAULT_PAGE_SIZE } from 'recommendations/hooks/react-query/recommendationHelpers';
import moment from 'moment/moment';
import DateFilter from 'shared/modules/dateFilter';
import { getEndOfDay, getStartOfDay } from 'shared/utils/dateUtil';

const RecommendationsHistory = ({ location, usersStore }) => {
  const urlSearchParams = new URLSearchParams(location.search);

  const lastProcessDate = DateFilter.getDate();
  const defaultEndName = DateFilter.getDefaultDateForReq(lastProcessDate);
  const startDateForReq = urlSearchParams.get('startDate')
    ? getStartOfDay(urlSearchParams.get('startDate'))
    : getStartOfDay(moment(defaultEndName).subtract(30, 'days'));
  const endDateForReq = urlSearchParams.get('endDate')
    ? getEndOfDay(urlSearchParams.get('endDate'))
    : getEndOfDay(defaultEndName);

  const [startDate, setStartDate] = React.useState(startDateForReq);
  const [endDate, setEndDate] = React.useState(endDateForReq);

  useEffect(() => {
    window.history.replaceState(null, '', `${window.location.pathname}?startDate=${startDate}&endDate=${endDate}`);
  }, [startDate, endDate]);

  const [queryKey, setQueryKey] = React.useState({
    startDate,
    endDate,
    limit: 'all',
  });
  // Recommendations History
  const historyHook = useRecommendationsHistory(queryKey);
  const recommendationsHistoryHook = historyHook.fetchRecommendationsHistory();
  const { isLoading: isRecommendationsHistoryLoading, data: recommendationsHistoryData } = recommendationsHistoryHook;

  let recommendationsDataForGrid = {};
  if (recommendationsHistoryData) {
    recommendationsDataForGrid = {
      historyInitialStartDate: recommendationsHistoryData.startDate,
      historyInitialEndDate: recommendationsHistoryData.endDate,
      historyRecLastPageKey: recommendationsHistoryData.lastPageKey,
      historyRecommendations: recommendationsHistoryData.recommendationsHistory,
      isAllHistoryRecommFetched: true,
    };
  }

  const { currentDisplayedUserType, usersModel } = usersStore;
  const { mapLinkedAccIdToDivisionName } = usersModel;

  // Recommendation by type
  const recommendationsHook = useRecommendationByType({
    isEnabled: true,
    recommendationType: null,
    currentDisplayedUserType,
    mapLinkedAccIdToDivisionName,
  });

  if (isRecommendationsHistoryLoading) {
    return <Spinner />;
  }

  return !recommendationsHistoryData?.recommendationsHistory ? (
    <div>
      <div>
        <h6 className="display-4">whoops...</h6>
        <p className="lead">{`${PageNames.REC_HISTORY} is temporarily unavailable`}</p>
        <hr className="my-2" />
        <p>Please try again later or contact your admin</p>
      </div>
    </div>
  ) : (
    <Container>
      <>
        <Row>
          <Col md={12}>
            <PageHeader title={PageNames.REC_HISTORY} showDate={false} />
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Row>
              <Col md={12}>
                <NewHistoryTable
                  recomms={recommendationsHistoryData?.recommendationsHistory}
                  recommendationsHistoryData={recommendationsDataForGrid}
                  updateHistoryRecommendationStatus={historyHook.updateHistoryRecommendationStatus}
                  fetchHistoryRecommendation={(pageSize, startDate, endDate, limit, historyRecLastPageKey) => {
                    const startDateForReq = getStartOfDay(startDate);
                    const endDateForReq = getEndOfDay(endDate);
                    setStartDate(startDateForReq);
                    setEndDate(endDateForReq);
                    setQueryKey({
                      pageSize,
                      limit,
                      historyRecLastPageKey,
                      startDate: startDateForReq,
                      endDate: endDateForReq,
                    });
                  }}
                  recHandlers={(recKeys) => recommendationsHook.completeRecommendationByKeys(recKeys)}
                  location={location}
                  recHelpers={{ vuFetchResourcesDataFunc: () => {} }}
                  defaultPageSize={DEFAULT_PAGE_SIZE}
                  currentUserReadOnly={usersStore.currentUserReadOnly}
                  cloudTypeId={usersStore.currDispUserCloudAccountType}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    </Container>
  );
};

RecommendationsHistory.propTypes = {
  location: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
};

const ObserverRecommendationsHistory = observer(RecommendationsHistory);
export default ObserverRecommendationsHistory;
