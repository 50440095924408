import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  ContentPageTypes,
  mapRecpageTypeToDefaultDetailsDiaply,
} from 'recommendations/constants/recommendationsConstants';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import ContentDetails from 'recommendations/containers/DailyRecommendations/components/ContentDetails';
import ContentDetail from 'recommendations/shared/components/ContentDetail';

const getResourcesTable = (resources, title) => {
  const rows = resources.map((resourceId) => (
    <tr style={{ lineHeight: '16px' }}>
      <td style={{ padding: '5' }}>{resourceId}</td>
    </tr>
  ));
  return (
    <table style={{ textAlign: 'center' }}>
      <tr>
        <th style={{ padding: '5' }}>{title}</th>
      </tr>
      {rows}
    </table>
  );
};

const renderContentDetails = (excludeRule) => {
  const contentDetailsArray = [];
  contentDetailsArray.push(<ContentDetail display="Recommendation:" value={excludeRule.type} />);
  contentDetailsArray.push(<ContentDetail display="Scope:" value={excludeRule.scope} />);
  if (excludeRule.scopeKey) {
    contentDetailsArray.push(<ContentDetail display="Excluded recommendation key:" value={excludeRule.scopeKey} />);
  }
  if (excludeRule.scopeLinkedAccount) {
    contentDetailsArray.push(
      <ContentDetail display="Excluded linked account ID:" value={excludeRule.scopeLinkedAccount} />,
    );
  }
  contentDetailsArray.push(<ContentDetail display="Period (days):" value={excludeRule.period} />);
  contentDetailsArray.push(<ContentDetail display="Creation date:" value={excludeRule.date} />);
  contentDetailsArray.push(
    <ContentDetail display="Expiration date:" value={moment(excludeRule.expire).format('YYYY-MM-DD')} />,
  );
  contentDetailsArray.push(<ContentDetail display="Reason:" value={excludeRule.reason} />);
  if (!isEmptyArray(excludeRule.resources)) {
    contentDetailsArray.push(
      <ContentDetail
        display="Resources:"
        value={getResourcesTable(excludeRule.resources, excludeRule.scope || 'Resource ID ')}
      />,
    );
  }
  return contentDetailsArray;
};

const excludeRuleContent = (excludeRule) => (
  <div style={{ 'line-height': '30px', 'font-size': '13px' }}>
    <table className="project-summary__info">
      <ContentDetails contentDisplay={mapRecpageTypeToDefaultDetailsDiaply.get(ContentPageTypes.EXCLUDE_RULES_PAGE)}>
        {renderContentDetails(excludeRule)}
      </ContentDetails>
    </table>
  </div>
);

const excludeRuleDescription = (excludeRule) => {
  let description = '';
  if (excludeRule.scopeKey) {
    /* eslint-disable-next-line max-len */
    description = `Excludes only single ${excludeRule.type} recommendation, with the key ${excludeRule.scopeKey}, for ${excludeRule.period} days.`;
  } else if (excludeRule.scopeLinkedAccount) {
    /* eslint-disable-next-line max-len */
    description = `Excludes all ${excludeRule.type} recommendations that belong to linked account ${excludeRule.scopeLinkedAccount}, for ${excludeRule.period} days.`;
  } else if (excludeRule.scopeResourceId && !isEmptyArray(excludeRule.resources)) {
    /* eslint-disable-next-line max-len */
    description = `Excludes all ${excludeRule.type} recommendations that involve the resources above, for ${excludeRule.period} days.`;
  } else if (excludeRule.scopeResourceId) {
    /* eslint-disable-next-line max-len */
    description = `Excludes all ${excludeRule.type} recommendations that involve resource ${excludeRule.scopeResourceId}, for ${excludeRule.period} days.`;
  } else {
    description = `Excludes all ${excludeRule.type} recommendations for ${excludeRule.period} days.`;
  }
  return (
    <div style={{ 'line-height': '30px', 'font-size': '13px' }}>
      <p>{description}</p>
    </div>
  );
};

const ExcludeRuleBuilder = ({ excludeRule, recExcludeRemoveHandler, afterActionHandler, currentUserReadOnly }) => (
  <Container>
    <br />
    <Row style={{ 'white-space': 'pre' }}>
      <Col xl={6} lg={6} md={12} sm={12} xs={12}>
        <div className="fs-exclude">
          <p className="project-summary__statistic-title">Details</p>
          <br />
          {excludeRuleContent(excludeRule)}
          {excludeRuleDescription(excludeRule)}
        </div>
      </Col>
    </Row>
    <Row>
      <div className="project-summary fs-exclude" style={{ 'white-space': 'pre', paddingLeft: '15px' }}>
        <hr />
        {excludeRule.status && (
          <ReadOnlyDisplayWrapper isHide={false} userReadOnly={currentUserReadOnly}>
            <Row>
              <Col xs="auto" md="auto" lg="auto" xl="auto">
                <Button
                  onClick={() => {
                    recExcludeRemoveHandler(excludeRule.uuid);
                    afterActionHandler();
                  }}
                  color="primary"
                  size="sm"
                >
                  Remove Exclusion
                </Button>
              </Col>
            </Row>
          </ReadOnlyDisplayWrapper>
        )}
      </div>
    </Row>
  </Container>
);

ExcludeRuleBuilder.propTypes = {
  excludeRule: PropTypes.object.isRequired,
  recExcludeRemoveHandler: PropTypes.func.isRequired,
  afterActionHandler: PropTypes.func.isRequired,
  currentUserReadOnly: PropTypes.bool.isRequired,
};
export default ExcludeRuleBuilder;
