import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import FlexibilityTable from 'shared/components/tables/DevExpSearchAndVisibilityTable';
import CustomModal from 'shared/components/andtComponents/Modal';

const ExplainFlexibilityModal = (props) => {
  const {
    toggle,
    isOpen,
    className,
    modalType,
    tableData,
    tableColumns,
    columnWidths,
    costColumnsArray,
    currenciesColumns,
  } = props;
  const getModalTitles = (type) => {
    let modalTitle = '';
    if (type === 'ri') {
      modalTitle = 'Resources using RI';
    }
    if (type === 'sp') {
      modalTitle = 'Resources using SP';
    }
    if (type === 'pp') {
      modalTitle = 'Public Price Margins per Service';
    }
    if (type === 'credit') {
      modalTitle = 'Credit usage history';
    }
    return { modalTitle };
  };

  return (
    <CustomModal
      overrideStyles={{ width: 800 }}
      open={isOpen}
      onClose={toggle}
      className={className}
      title={getModalTitles(modalType).modalTitle}
      footerDisabled
    >
      <Row>
        <FlexibilityTable
          data={tableData}
          columns={tableColumns}
          costColumnsArray={costColumnsArray}
          currenciesColumns={currenciesColumns}
          csvTitle={`explain_flexibility_${modalType}`}
          columnWidths={columnWidths}
          numCostDecimal={2}
          isResize
          isPaging
        />
      </Row>
    </CustomModal>
  );
};

ExplainFlexibilityModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalType: PropTypes.string.isRequired,
  tableData: PropTypes.object.isRequired,
  tableColumns: PropTypes.object.isRequired,
  columnWidths: PropTypes.object.isRequired,
  currenciesColumns: PropTypes.object.isRequired,
  costColumnsArray: PropTypes.object.isRequired,
  className: PropTypes.object,
};
ExplainFlexibilityModal.defaultProps = {
  className: '',
};

export default ExplainFlexibilityModal;
