import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import {
  BASE_PROPERTIES,
  COST_PROPERTIES,
  DB_PROPERTIES,
  ENGINE_PROPERTIES,
  RDS_PROPERTIES,
} from '../../recommendationPropertyUtils';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationOptionsPanel from '../../recommendationGenericComponents/recommendationOptionsPanel';
import RecommendationOptionsContext from '../../recommendationGenericComponents/recommendationOptionsContext';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import RecommendationCommand, { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';

const RdsVersionUpgrade = ({ recommendation }) => {
  const { selectedOptionIndex } = useContext(RecommendationOptionsContext);

  const isSeparateResource =
    recommendation?.resourceId && recommendation?.resourceName && isEmptyArray(recommendation?.resources);

  const properties = { ...RDS_PROPERTIES, DB_PROPERTIES, ...COST_PROPERTIES, ...ENGINE_PROPERTIES, ...BASE_PROPERTIES };

  const RECOMMENDATION_OPTION_DATA = [
    properties.RI_COVERAGE,
    properties.ENGINE,
    properties.ENGINE_VERSION,
    properties.STORAGE,
    properties.HOURLY_COST,
    properties.DB_TYPE,
    properties.DB_NAME,
    properties.MULTI_AZ,
  ];

  const CURRENT_PROPERTIES = [
    properties.CURRENT_INSTANCE_TYPE,
    properties.CURRENT_INSTANCE_TYPE_FAMILY,
    ...RECOMMENDATION_OPTION_DATA,
    properties.CURRENT_ANNUAL_COST,
  ];

  const ALTERNATIVE_PROPERTIES = [
    properties.RECOMMENDED_INSTANCE_TYPE,
    properties.RECOMMENDED_INSTANCE_TYPE_FAMILY,
    ...RECOMMENDATION_OPTION_DATA,
    properties.RECOMMENDED_ANNUAL_COST,
    properties.POTENTIAL_SAVINGS,
  ];

  const topThreeAlternatives = recommendation?.recData?.alternatives?.slice(0, 3);

  const optionsPanelComponent =
    Array.isArray(topThreeAlternatives) && topThreeAlternatives.length ? (
      <RecommendationOptionsPanel
        alternatives={topThreeAlternatives}
        currentProperties={CURRENT_PROPERTIES}
        alternativeProperties={ALTERNATIVE_PROPERTIES}
        recommendation={recommendation}
      />
    ) : null;

  const description =
    `AWS introduces different types of instances according to application loads, memory, ` +
    `storage and networking capacity to offer better performance. We recommend you to use the ` +
    `latest generation of instances to get the best performance and pricing.`;

  const insideLookComponent = <RecommendationInsideLook description={description} />;

  const otherResourceIdRecComponent = recommendation.resourceId ? (
    <OtherResourceIdRecommendations resourceId={recommendation.resourceId} />
  ) : null;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const cliInstructions = [
    {
      actionCommand: `aws rds modify-db-instance --db-instance-identifier ${
        isSeparateResource ? recommendation.resourceId : 'RESOURCE_ID'
      } --db-instance-class ${properties.RECOMMENDED_INSTANCE_TYPE.getterFunction(
        recommendation,
        selectedOptionIndex,
      )} --no-apply-immediately`,
    },
  ];

  const commandsList = [{ type: COMMAND_TYPES.CLI, instructions: [cliInstructions] }];

  const commandComponent = <RecommendationCommand commandsList={commandsList} />;

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      otherResourceIdRecommendations={otherResourceIdRecComponent}
      optionsPanel={optionsPanelComponent}
      details={detailsComponent}
      command={commandComponent}
      preferences={preferencesComponent}
    />
  );
};

RdsVersionUpgrade.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default RdsVersionUpgrade;
