import PropTypes from 'prop-types';
import React from 'react';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import { COST_CONFIGURATION } from 'recommendationsNew/consts';
import SeriesDataChart, { LINE_NAMES } from './seriesDataChart';
import BreakEvenChart from './breakEvenChart';
import GenericChart from './genericChart';

import classes from './recommendationChartData.module.scss';

export const CHART_TYPES = {
  SERIES_DATA: 'seriesData',
  BREAK_EVEN: 'breakEven',
  GENERIC: 'generic',
};

const RecommendationChartData = ({ chartsData }) => {
  const createSeriesChartData = (data) => {
    const seriesTypes = [
      { property: LINE_NAMES.MAX, value: data.maxDataProperty },
      { property: LINE_NAMES.AVG, value: data.avgDataProperty },
      { property: LINE_NAMES.ESTIMATED, value: data.estimatedDataProperty },
      { property: LINE_NAMES.PERCENTILE, value: data.percentileDataProperty },
      { property: LINE_NAMES.PERCENTILE99, value: data.percentile99DataProperty },
    ];

    if (!data.usageDateProperty && !data.maxDataProperty) {
      return null;
    }
    let chartData = [];
    try {
      const arrOfDates = Object.keys(data.usageDateProperty || data.maxDataProperty);
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      chartData = arrOfDates.map((date) => {
        const chartDataTmp = {
          usageDate: createDateDisplayStr(date),
        };
        seriesTypes.forEach((seriesType) => {
          // Don't add property if the data for the chart doesn't exist
          if (seriesType.value) {
            chartDataTmp[seriesType.property] = seriesType.value[date] ? parseFloat(seriesType.value[date]) : null;
          }
        });
        return chartDataTmp;
      });
    } catch (error) {
      // Error
    }
    return { data: chartData };
  };

  const createGenericChartData = (data) => {
    let chartData = [];
    try {
      const arrOfDates = Object.keys(data.linesList[0].data);
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      chartData = arrOfDates.map((date) => ({
        usageDate: createDateDisplayStr(date),
        ...{
          ...data.linesList.reduce((acc, line) => {
            acc[line.id] = line.data[date] ? parseFloat(line.data[date]) : null;
            return acc;
          }, {}),
        },
      }));
    } catch (error) {
      // Error
    }
    return { data: chartData };
  };

  const createBreakEvenChartData = ({ annualCurrentCost, annualSavings }) => {
    const monthlyCurrentCost = annualCurrentCost?.[COST_CONFIGURATION.UNBLENDED] / 12;
    const riCost = annualCurrentCost?.[COST_CONFIGURATION.UNBLENDED] - annualSavings?.[COST_CONFIGURATION.UNBLENDED];
    const breakEvenData = [];
    for (let i = 1; i <= 12; ++i) {
      breakEvenData.push({ name: i, 'RI Cost': riCost, 'On-Demand Cost': (monthlyCurrentCost * i).toFixed(1) });
    }
    return breakEvenData;
  };

  return (
    <div className={classes.chartsContainer}>
      {chartsData?.map((data) => (
        <div className={classes.chartContainer}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{data.chartTitle}</div>
          </div>
          {data.chartType === CHART_TYPES.SERIES_DATA && (
            <SeriesDataChart
              data={createSeriesChartData(data)?.data}
              yAxisLabel={data?.yAxisLabel}
              yAxisUnit={data?.yAxisUnit}
            />
          )}
          {data.chartType === CHART_TYPES.GENERIC && (
            <GenericChart
              data={createGenericChartData(data)?.data}
              yAxisLabel={data?.yAxisLabel}
              yAxisUnit={data?.yAxisUnit}
              linesList={data?.linesList}
              referenceLinesList={data?.referenceLines}
            />
          )}
          {data.chartType === CHART_TYPES.BREAK_EVEN && (
            <BreakEvenChart data={createBreakEvenChartData(data?.recommendation)} />
          )}
        </div>
      ))}
    </div>
  );
};

RecommendationChartData.propTypes = {
  chartsData: PropTypes.array.isRequired,
};

RecommendationChartData.defaultProps = {};

export default RecommendationChartData;
