/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-return-await */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars,arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/system/colorManipulator';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import {
  IntegratedFiltering,
  IntegratedSorting,
  DataTypeProvider,
  FilteringState,
  SortingState,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableColumnVisibility,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import { Col } from 'reactstrap';
import { kFormatterWithDecimal } from 'shared/utils/strUtil';
import TableWrapper from 'shared/components/tables/TableWrapper';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

const Cell = (props) => <Table.Cell {...props} />;

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    fontSize: '25.5vw',
  },
});

const resourceIdPredicate = (value, filter, row) => {
  const searchValue = filter.value;
  const searchTarget = row.resourceId || '';
  return searchTarget.toLowerCase().includes(searchValue.toLowerCase());
};

class FilterTable extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    panelName: PropTypes.string.isRequired,
    usersStore: PropTypes.object.isRequired,
    selectedPageSize: PropTypes.number,
    getCurrencyNumber: PropTypes.func.isRequired,
    overrideCurrency: PropTypes.string,
    // csvTitle: PropTypes.string.isRequired,
  };
  static defaultProps = {
    selectedPageSize: 15,
    overrideCurrency: null,
  };
  constructor(props) {
    super(props);
    const { usersStore } = this.props;
    this.state = {
      columns: this.columnsByCloudType[usersStore.currDispUserCloudAccountType],
      // this.props.cloudType === CLOUD_TYPE_IDS.AZURE
      //   ? [...this.azureColumns]
      //   : // ? [...this.azureColumns, ...this.idColumn, ...this.commonColumns]
      //   [...this.idColumn, ...this.awsColumns, ...this.commonColumns],
      columnWidths: [
        { columnName: 'resourceId', width: 200 },
        { columnName: 'linkedAccountName', width: 200 },
        { columnName: 'serviceName', width: 300 },
        { columnName: 'resourceName', width: 400 },
        { columnName: 'resourceGroup', width: 400 },
        { columnName: 'totalCost', width: 100 },
        { columnName: 'totalUsage', width: 100 },
        { columnName: 'resourceDescription', width: 600 },
        { columnName: 'customTags', width: 200 },
        { columnName: 'projectName', width: 200 },
        { columnName: 'familyType', width: 200 },
      ],
      tableColumnExtensions: [
        { columnName: 'totalCost', align: 'center' },
        { columnName: 'totalUsage', align: 'center' },
      ],
      hiddenColumnNames: ['serviceName'],
      numericFilterOperations: [
        'equal',
        'notEqual',
        'greaterThan',
        'greaterThanOrEqual',
        'lessThan',
        'lessThanOrEqual',
      ],
      pageSizes: [5, 10, 15, 20, 25],
      // integratedFilteringColumnExtensions: [],
      integratedFilteringColumnExtensions: [{ columnName: 'resourceId', predicate: resourceIdPredicate }],
    };
  }
  idColumn = [
    {
      name: 'resourceId',
      title: 'Resource ID',
      getCellValue: (row) => row.resourceId,
    },
  ];
  commonColumns = [
    { name: 'serviceName', title: 'Service name', getCellValue: (row) => row.serviceName },
    { name: 'totalCost', title: 'Cost', getCellValue: (row) => row.totalCost },
    { name: 'totalUsage', title: 'Quantity', getCellValue: (row) => parseFloat(row.totalUsage) },
  ];
  awsColumns = [
    { name: 'resourceName', title: 'Resource Name', getCellValue: (row) => row.resourceName },
    { name: 'resourceDescription', title: 'Description', getCellValue: (row) => row.resourceDescription },
    { name: 'linkedAccountName', title: 'Linked Account Name', getCellValue: (row) => row.linkedAccountName },
    { name: 'customTags', title: 'Custom Tags', getCellValue: (row) => row.customTags },
    { name: 'familyType', title: 'Family Type', getCellValue: (row) => row.familyType },
  ];
  gcpColumns = [
    { name: 'resourceName', title: 'Resource Name', getCellValue: (row) => row.resourceName },
    { name: 'resourceDescription', title: 'Description', getCellValue: (row) => row.resourceDescription },
    { name: 'linkedAccountName', title: 'Linked Account Name', getCellValue: (row) => row.linkedAccountName },
    { name: 'customTags', title: 'Custom Tags', getCellValue: (row) => row.customTags },
    { name: 'projectName', title: 'Project Name', getCellValue: (row) => row.projectName },
  ];

  azureColumns = [
    { name: 'linkedAccountName', title: 'Subscription Name', getCellValue: (row) => row.linkedAccountName },
    { name: 'resourceGroup', title: 'Resource Group', getCellValue: (row) => row.resourceGroup },
    // { name: 'resourceName', title: 'Resource Name', getCellValue: row => row.resourceName }, removed for now, this field is not maintained in the DB at the moment
    { name: 'resourceName', title: 'Resource Name', getCellValue: (row) => row.resourceName },
    this.idColumn[0],
    { name: 'customTags', title: 'Tags', getCellValue: (row) => row.customTags },
    { name: 'totalCost', title: 'Cost', getCellValue: (row) => row.totalCost },
    { name: 'totalUsage', title: 'Quantity', getCellValue: (row) => parseFloat(row.totalUsage) },
  ];

  columnsByCloudType = {
    [CLOUD_TYPE_IDS.AWS]: [...this.idColumn, ...this.awsColumns, ...this.commonColumns],
    [CLOUD_TYPE_IDS.AZURE]: this.azureColumns,
    [CLOUD_TYPE_IDS.GCP]: [...this.idColumn, ...this.gcpColumns, ...this.commonColumns],
  };

  currenciesFormatter = (value) =>
    this.props.getCurrencyNumber(kFormatterWithDecimal(value.value, 1), 2, {}, this.props.overrideCurrency);

  numberFormatter = (value) => kFormatterWithDecimal(value.value, 1);

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };
  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  render() {
    const {
      columns,
      columnWidths,
      tableColumnExtensions,
      hiddenColumnNames,
      numericFilterOperations,
      integratedFilteringColumnExtensions,
      pageSizes,
    } = this.state;
    const { data, panelName, selectedPageSize } = this.props;
    return (
      <div style={{ width: '100%' }} ref={this.refCallback}>
        <Col xs={12} md={12} lg={12} xl={12}>
          <CustomCSVDownload filesNumber={1} isLoading={false} data={[{ data, filename: `${panelName}.csv` }]} />
          <Paper>
            <Grid rows={data} columns={columns}>
              <FilteringState defaultFilters={[{}]} />
              <SortingState
                defaultSorting={[
                  { columnName: 'totalCost', direction: 'desc' },
                  { columnName: 'totalUsage', direction: 'desc' },
                ]}
              />
              <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
              <IntegratedSorting />
              <PagingState defaultCurrentPage={0} defaultPageSize={selectedPageSize || 15} />
              <IntegratedPaging />
              <DataTypeProvider
                for={['totalCost']}
                formatterComponent={this.currenciesFormatter}
                availableFilterOperations={numericFilterOperations}
              />
              <DataTypeProvider
                for={['totalUsage']}
                formatterComponent={this.numberFormatter}
                availableFilterOperations={numericFilterOperations}
              />
              <TableWrapper cellComponent={Cell} columnExtensions={tableColumnExtensions} styles={styles} />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
              />
              <PagingPanel pageSizes={pageSizes} />
            </Grid>
          </Paper>
        </Col>
      </div>
    );
  }
}

const ObserverFilterTable = withUserSettingsConsumer(FilterTable);
export default ObserverFilterTable;
