import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const EBS_OUTDATED_SNAPSHOT_PROPERTIES = {
  SIZE: {
    label: 'Size',
    isGBSize: true,
    getterFunction: (recommendation) => recommendation?.recData?.snapshot_size,
  },
  SNAPSHOT_ID: {
    label: 'Snapshot ID',
    getterFunction: (recommendation) => recommendation?.resourceId,
  },
  VOLUME_ID: {
    label: 'Volume ID',
    getterFunction: (recommendation) => recommendation?.recData?.volume_id,
  },
  TIME_FROM_CREATION: {
    label: 'Time from creation',
    getterFunction: (recommendation) => recommendation?.recData?.elapsed_time_since_creation,
  },
  COST_FROM_CREATION: {
    label: 'Cost from creation',
    isCurrency: true,
    getterFunction: (recommendation) => recommendation?.recData?.cost_since_creation,
  },
};

const EbsOutdatedSnapshot = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          actionCommand: `aws ec2 delete-snapshot --snapshot-id ${recommendation?.resourceId}`,
        },
      ],
    },
  ];

  const description =
    // eslint-disable-next-line max-len
    'Ebs Outdated Snapshot, this snapshot age is 1y 1m, to avoid unnecessary costs, we recommend to delete the snapshot after 21 days. Note this recommendation does NOT include AMI snapshots.\n' +
    '\n' +
    'How to delete an Amazon EBS snapshot';
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={EBS_OUTDATED_SNAPSHOT_PROPERTIES}
    />
  );
};

EbsOutdatedSnapshot.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default EbsOutdatedSnapshot;
