import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import { BILLINGS_API_NAME, RECOMMENDATIONS_HEATMAP_ROOT, STATLE_TIME } from 'recommendationsNew/consts';
import { transformFiltersContextToApiFormat } from 'recommendationsNew/hooks/react-query/apiUtils';

function fetchHeatMapSummary(filters) {
  const transformedFilters = transformFiltersContextToApiFormat(filters);
  return API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_HEATMAP_ROOT}/summary`, {
    body: {
      filters: transformedFilters || {},
    },
  });
}

export default function useHeatMapSummary(isEnabled = true) {
  const queryKey = [apiConstants.QUERY_KEYS.RECOMMENDATIONS_HEATMAP_SUMMARY];

  return {
    invalidate: ({ filters }) => queryClient.invalidateQueries([...queryKey, filters] || queryKey),
    reset: ({ filters }) => queryClient.resetQueries([...queryKey, filters] || queryKey),
    fetchHeatMapSummary: (filters) =>
      useQuery([...queryKey, filters], () => fetchHeatMapSummary(filters), {
        retry: false,
        staleTime: STATLE_TIME,
        enabled: isEnabled,
      }),
  };
}
