import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Spinner, { SIZES } from 'shared/components/andtComponents/Spinner';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

import classes from './annualSavingsMinValueFilter.module.scss';

export function AnnualSavingsMinValueFilter({
  lowerLimit,
  minValueLimitSelected,
  upperLimit,
  onChangeLimits,
  isLoading,
}) {
  const rangeBarRef = useRef();
  const [rangeBarUnitToPixelsRatio, setRangeBarUnitToPixelsRatio] = useState(1);

  const { currencySymbol } = useUserSettingsContext();

  useEffect(() => {
    if (!isLoading && rangeBarRef.current?.clientWidth && upperLimit - lowerLimit > 0) {
      setRangeBarUnitToPixelsRatio(rangeBarRef.current?.clientWidth / (upperLimit - lowerLimit));
    } else {
      setRangeBarUnitToPixelsRatio(1);
    }
  }, [rangeBarRef.current?.clientWidth, isLoading, upperLimit, lowerLimit]);

  function pxToUnit(px) {
    return Math.round(px / rangeBarUnitToPixelsRatio);
  }

  function unitToPx(unit) {
    return Math.round(unit * rangeBarUnitToPixelsRatio);
  }

  const selectedPointStyle = {
    left: `${unitToPx(minValueLimitSelected)}px`,
  };

  const handleLowerLimitMouseDown = (e) => {
    const startX = e.clientX;
    const initialPosition = unitToPx(minValueLimitSelected);

    const handleMouseMove = (e) => {
      const deltaX = e.clientX - startX;
      const newPosition = Math.min(unitToPx(upperLimit), Math.max(0, initialPosition + Math.round(deltaX)));
      onChangeLimits({ minValueLimit: pxToUnit(newPosition) });
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className={classes.rangeFilterContainer}>
      <div className={classes.rangeLabel}>Show annual savings greater than:</div>
      {isLoading ? (
        <Spinner size={SIZES.MEDIUM_50} position="relative" />
      ) : (
        <div className={classes.rangeBar} ref={rangeBarRef}>
          <div
            className={classes.selectedPointContainer}
            style={selectedPointStyle}
            onMouseDown={handleLowerLimitMouseDown}
          >
            <div className={classes.selectedPoint} />
            <div className={classes.limitNumberContainer}>
              <div className={classes.limitNumber}>
                <div>{currencySymbol}</div>
                <div>{minValueLimitSelected?.toLocaleString()}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

AnnualSavingsMinValueFilter.propTypes = {
  lowerLimit: PropTypes.number.isRequired,
  upperLimit: PropTypes.number.isRequired,
  minValueLimitSelected: PropTypes.number.isRequired,
  onChangeLimits: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
