import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/system/colorManipulator';
import {
  FilteringState,
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  RowDetailState,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  TableRowDetail,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { CSVLink } from 'react-csv';
import Spinner from 'shared/components/andtComponents/Spinner';
import TableWrapper from 'shared/components/tables/TableWrapper';
import {
  IDLE_INSTANCES_TABLE_COLUMNS,
  IDLE_INSTANCES_COLUMN_WIDTHS,
  IDLE_INSTANCES_DEFAULT_SORTING,
} from 'usage/constants/usageConstants';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import IdleInstanceHeatmapChart from './IdleInstanceHeatmapChart';

const styles = () => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    fontSize: '25.5vw',
  },
});

class IdleInstancesTable extends Component {
  static propTypes = {
    idleInstancesData: PropTypes.object.isRequired,
    fetchInstanceData: PropTypes.func.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    isLoadingIdleInstancesRowData: PropTypes.bool.isRequired,
    idleInstancesRowData: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currInstanceId: '',
      currStartDate: '',
      currEndDate: '',
      pageSizes: [10, 15, 20, 0],
      integratedSortingColumnExtensions: [
        { columnName: 'totalWaste', compare: this.numericSort },
        { columnName: 'numOfDays', compare: this.numericSort },
        { columnName: 'dailyAvg', compare: this.numericSort },
      ],
      columnWidths: IDLE_INSTANCES_COLUMN_WIDTHS,
    };
  }

  setExpandedRowIds = (expandedRowIds) => {
    this.setState({ expandedRowIds: [expandedRowIds[expandedRowIds.length - 1]] });
  };

  numericSort = (a, b) => {
    let newA = a;
    let newB = b;
    if (typeof newA === 'string' && newA.includes('$')) {
      newA = +newA.replace('$', '');
    }
    if (typeof newB === 'string' && newB.includes('$')) {
      newB = +newB.replace('$', '');
    }
    return newA - newB;
  };

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  prepareCsvModifiedRows = (data) => JSON.parse(JSON.stringify(data));

  instanceNameColValue = (row) => `${row.resourceName} (${row.resourceId})`;
  regionColValue = (row) => row.regionTagName;
  dailyAvgColValue = (row) => row.dailyAvg.toFixed(2);
  totalWasteColValue = (row) => {
    const { getCurrencyNumber } = this.props;
    return getCurrencyNumber(row.totalWaste, 3, { roundNumber: true });
  };

  prepareColumns = () =>
    IDLE_INSTANCES_TABLE_COLUMNS.map((col) => ({
      name: col.name,
      title: col.title,
      getCellValue: this[`${col.name}ColValue`] ? this[`${col.name}ColValue`] : null,
    }));
  render() {
    const {
      idleInstancesRowData,
      idleInstancesData,
      fetchInstanceData,
      startDate,
      endDate,
      isLoadingIdleInstancesRowData,
    } = this.props;
    const RowDetail = ({ row }) => {
      let data;
      const { currInstanceId, currStartDate, currEndDate } = this.state;
      if (row.resourceId !== currInstanceId || startDate !== currStartDate || endDate !== currEndDate) {
        data = new Promise((resolve, reject) => {
          const success = fetchInstanceData(row.criteriaId, row.resourceId, startDate, endDate);
          if (success) {
            this.setState({
              currInstanceId: row.resourceId,
              currStartDate: startDate,
              currEndDate: endDate,
            });
            resolve(success);
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Could not make it');
          }
        }).then((res) => res);
      } else {
        data = idleInstancesRowData;
      }
      const dataIsEmpty = !isLoadingIdleInstancesRowData && Object.entries(data || {}).length === 0;
      if (dataIsEmpty) {
        return <div style={{ padding: '10px', width: '60vw' }}>No data found</div>;
      }
      return (
        <div style={{ padding: '10px', width: '60vw' }}>
          {isLoadingIdleInstancesRowData ? (
            <Spinner customStyle={{ width: '200px' }} />
          ) : (
            <IdleInstanceHeatmapChart isLoadingIdleInstancesRowData={isLoadingIdleInstancesRowData} series={data} />
          )}
        </div>
      );
    };

    RowDetail.propTypes = {
      row: PropTypes.object.isRequired,
    };
    const { integratedSortingColumnExtensions, expandedRowIds, pageSizes, columnWidths } = this.state;
    return (
      <div className="card">
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <CSVLink
            data={this.prepareCsvModifiedRows(idleInstancesData)}
            headers={IDLE_INSTANCES_TABLE_COLUMNS.title}
            filename="idle-instances.csv"
          >
            Download as CSV
            <span className="lnr lnr-download" style={{ fontSize: '18px', marginLeft: '8px', marginBottom: '8px' }} />
          </CSVLink>
        </div>
        <Grid rows={idleInstancesData} columns={this.prepareColumns()}>
          <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={this.setExpandedRowIds} />
          <FilteringState defaultFilters={[]} />
          <IntegratedFiltering />
          <SortingState defaultSorting={IDLE_INSTANCES_DEFAULT_SORTING} />
          <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
          <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[2]} />
          <IntegratedPaging />
          <TableWrapper styles={styles} />
          <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
          <TableHeaderRow showSortingControls />
          <TableRowDetail contentComponent={RowDetail} />
          <TableFilterRow />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
      </div>
    );
  }
}

const ObserverIdleInstancesTable = withUserSettingsConsumer(IdleInstancesTable);
export default ObserverIdleInstancesTable;
