/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import { numberWithCommas } from 'shared/utils/strUtil';
import { createDateDisplayStr, isWeekDate } from 'shared/utils/dateUtil';
import moment from 'moment';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const liStyle = (nameColor) => ({
  position: 'relative',
  display: 'inline-block',
  padding: '0.4rem 0.4rem',
  'margin-bottom': '-2px',
  'border-radius': '3px',
  border: '0.5px solid #e0e0e0',
  background: nameColor,
  maxWidth: '10px',
  maxHeight: '10px',
});

const isDecimalPointNeeded = (value, decimalPoints = 2) => {
  let valueToCheck = +(value % 1).toFixed(decimalPoints);
  if (value < 0) {
    valueToCheck *= -1;
  }
  return valueToCheck > 0;
};

const getTotalHours = (payload) => payload.reduce((acc, key) => acc + key.value, 0);
const getSavingsCost = (payload) => payload[0].payload['On-Demand Cost'] - payload[0].payload['Actual Cost'];

const getDisplayValue = ({ payload, displayedMetric, showPercent, value, decimalPoints = 2, overrideCurrency }) => {
  const { numStrAbriviaionByDisplayMetric, getCurrencyNumber } = useUserSettingsContext();
  if (displayedMetric === DisplayMetricTypes.COST) {
    return `${getCurrencyNumber(
      value,
      isDecimalPointNeeded(value, decimalPoints) ? decimalPoints : 0,
      {},
      overrideCurrency,
    )}`;
  }
  const prec = (value / getTotalHours(payload)) * 100;
  let displayValue = numStrAbriviaionByDisplayMetric(1.1 * value, value, displayedMetric, overrideCurrency);
  if (showPercent) {
    displayValue = `${displayValue} (${numStrAbriviaionByDisplayMetric(
      1.1 * value,
      prec,
      displayedMetric,
      overrideCurrency,
      {
        showPercent,
      },
    )})`;
  }
  return displayValue;
};

// Important: The tooltip assumes a usageDate field
const CustomTooltip = ({
  active,
  payload,
  data,
  currKey,
  displayedMetric,
  toInt,
  showAllItems = false,
  showPrecent,
  operation,
  hideTotal,
  decimalPoints = 2,
  overrideCurrency,
}) => {
  if (active && payload && payload.length) {
    const { usageDate: uDate, date } = payload[0].payload && payload[0].payload; // Some data arrives with usageDate property, and some with date property.
    const currDate = uDate || date;
    const currDateData = data.filter(({ usageDate }) => usageDate === currDate);
    const { usageDate, ...chartData } = currDateData && currDateData.length && currDateData[0];
    const modifyDateIfHour = (dateTemp) => {
      if (dateTemp) {
        if (isWeekDate(dateTemp)) {
          const [_, weekNum, year] = dateTemp.split('-');
          const curYear = moment().year(year);
          const weekDateStart = curYear
            .isoWeek(+weekNum)
            .startOf('isoweek')
            .format('DD MMM');
          const weekDateEnd = curYear
            .isoWeek(+weekNum + 1)
            .startOf('isoWeek')
            .add(-1, 'day')
            .format('DD MMM');
          return `${weekDateStart} - ${weekDateEnd}`;
        }
        return createDateDisplayStr(dateTemp);
      }
      return '';
    };
    const currSum = payload[0].payload.totalSum || getTotalHours(payload);
    let savings;
    if (operation === '-') {
      savings = getSavingsCost(payload);
    }
    const activePayLoad = payload.filter(({ dataKey }) => dataKey === currKey);
    const renderToolTipItems = (activePayLoadObj) => {
      const items = showAllItems ? (
        payload.map(({ value, color, dataKey }) => (
          <ul style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
            <div
              style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}
            >
              <li style={liStyle(color)} />
              <span style={{ 'font-weight': '400', marginLeft: '5px' }}>{`  ${dataKey}: ${getDisplayValue({
                payload,
                toInt,
                value,
                showPercent: showPrecent,
                displayedMetric,
                currKey: dataKey,
                decimalPoints,
                overrideCurrency,
              })}`}</span>
            </div>
          </ul>
        ))
      ) : (
        <ul>
          {currSum ? (
            <span className="d-block font-weight-normal">
              Percent of Total: {numberWithCommas(Math.abs((activePayLoadObj.value / currSum) * 100), 0)}%
            </span>
          ) : null}
          <li style={liStyle(activePayLoadObj.color)} className="me-2" />
          <span style={{ 'font-weight': '400' }}>
            {activePayLoadObj.dataKey}:{' '}
            {getDisplayValue({
              payload,
              toInt,
              value: activePayLoadObj.value,
              showPercent: showPrecent,
              displayedMetric,
              currKey,
              decimalPoints,
              overrideCurrency,
            })}
          </span>
        </ul>
      );
      return items;
    };
    if (activePayLoad.length > 0) {
      return (
        <div className="chart-tooltip fs-exclude">
          <p className="label">{`Date: ${modifyDateIfHour(currDate)}`}</p>
          {operation === '+' && !hideTotal && (
            <p>{`Total: ${getDisplayValue({
              payload,
              toInt,
              value: currSum,
              showPercent: false,
              displayedMetric,
              currKey,
              decimalPoints,
              overrideCurrency,
            })}`}</p>
          )}
          {operation === '-' && (
            <p>{`Savings: ${getDisplayValue({
              payload,
              toInt,
              value: savings,
              showPercent: false,
              displayedMetric,
              currKey,
              decimalPoints,
              overrideCurrency,
            })}`}</p>
          )}
          {renderToolTipItems(activePayLoad[0])}
        </div>
      );
    }
  }

  return null;
};

export default CustomTooltip;

CustomTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  toInt: PropTypes.bool,
  showAllItems: PropTypes.bool,
  payload: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  currKey: PropTypes.string.isRequired,
  displayedMetric: PropTypes.object.isRequired,
  showPrecent: PropTypes.bool,
  hideTotal: PropTypes.bool,
  operation: PropTypes.string,
  decimalPoints: PropTypes.number,
  overrideCurrency: PropTypes.string,
};
CustomTooltip.defaultProps = {
  toInt: false,
  showAllItems: false,
  showPrecent: false,
  hideTotal: false,
  operation: '+',
  decimalPoints: 2,
  overrideCurrency: null,
};
