/* allows to use textMaps in a i18n-like way */
export const getFakeT =
  (textMap) =>
  (key, ...args) => {
    if (!textMap[key]) {
      return '';
    }
    if (typeof textMap[key] === 'string' || Array.isArray(textMap[key])) {
      return textMap[key];
    }
    return textMap[key](...args);
  };
