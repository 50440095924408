import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { SelectMultiItem } from '@pileus-cloud/anodot-frontend-common/dist';
import { DROPDOWN_MAIN_FILTERS, FILTERS } from 'recommendationsNew/consts';
import useDynamicFilter from 'recommendationsNew/hooks/react-query/useDynamicFilter';
import RecommendationsNewContext from 'recommendationsNew/contexts/recommendationsNewContext';
import { SelectMultiItem as SelectIconsMultiItem } from './servicesFilter/selectMultiMenuItem/SelectMultiMenu';
import { AdditionalFilters } from './additionalFilters/additionalFilters';
import { FilterWrapper } from './filterWrappers';
import HeatMapSelectedFiltersDisplay from './displayedSelectedFilters/heatMapSelectedFiltersDisplay';
import { DateRangeFilter } from './dateRangeFilter/dateRangeFilter';

const HeatMapFilters = ({ usersStore }) => {
  const [currentFilter, setCurrentFilter] = useState();

  const { recommendationFilters: filtersContext, setRecommendationFilters: setFiltersContext } =
    useContext(RecommendationsNewContext);

  const dynamicFilterHook = useDynamicFilter(currentFilter);
  const { data, isLoading } = dynamicFilterHook.fetchDynamicFilter(filtersContext);

  const onSelectMultiButtonClick = (isMenuOpen, filterId) => {
    if (isMenuOpen && filterId !== currentFilter) {
      setCurrentFilter(filterId);
    } else if (!isMenuOpen) {
      setCurrentFilter(undefined);
    }
  };

  const handleSelectionChange = (currentFilterSelectedOptions) => {
    setCurrentFilter(undefined);
    const newFilters = { ...filtersContext, [currentFilter]: currentFilterSelectedOptions };
    setFiltersContext(newFilters);
  };

  const handleDateRangeChange = (dateRange) => {
    if (dateRange?.startDate) {
      const newFilters = {
        ...filtersContext,
        [FILTERS.CREATION_DATE_FROM.id]: dateRange.startDate,
        [FILTERS.CREATION_DATE_TO.id]: dateRange.endDate,
      };
      setFiltersContext(newFilters);
    }
  };

  const getDynamicFilterOptions = (filterId) => {
    if (data?.page && currentFilter === filterId) {
      return data?.page;
    }
    return undefined;
  };

  const selectCategoryMultiMenuComponentGetter = (onSelectionChange, selectedOptions, isSelectMenuOpen, onMenuBlur) => (
    <SelectMultiItem
      isOpen={isSelectMenuOpen}
      options={getDynamicFilterOptions(FILTERS.CATEGORIES.id)}
      onChange={onSelectionChange}
      selected={selectedOptions}
      onMenuBlur={onMenuBlur}
      isLoading={isLoading && currentFilter === FILTERS.CATEGORIES.id}
      width="290px"
    />
  );

  const selectTypesMultiMenuComponentGetter = (onSelectionChange, selectedOptions, isSelectMenuOpen, onMenuBlur) => (
    <SelectMultiItem
      isOpen={isSelectMenuOpen}
      options={getDynamicFilterOptions(FILTERS.TYPES.id)}
      onChange={onSelectionChange}
      selected={selectedOptions}
      onMenuBlur={onMenuBlur}
      isLoading={isLoading && currentFilter === FILTERS.TYPES.id}
      width="290px"
    />
  );

  const selectServicesMultiMenuComponentGetter = (
    onSelectionChange,
    selectedServices,
    isSelectMenuOpen,
    onMenuBlur,
    controlProps,
  ) => (
    <SelectIconsMultiItem
      cloudType={usersStore?.currDispUserCloudAccountType}
      containerProps={controlProps}
      isOpen={isSelectMenuOpen}
      isIconsViewEnabled
      onChange={onSelectionChange}
      onMenuBlur={onMenuBlur}
      options={getDynamicFilterOptions(FILTERS.SERVICES.id)}
      selected={selectedServices}
      width="295px"
      isLoading={isLoading && currentFilter === FILTERS.SERVICES.id}
    />
  );

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row gap-3 px-sm-3 py-sm-2">
        <FilterWrapper
          allFilters={filtersContext}
          handleSelectionChange={handleSelectionChange}
          onSelectMultiButtonClick={onSelectMultiButtonClick}
          selectFilterMultiMenuComponentGetter={selectCategoryMultiMenuComponentGetter}
          filterType={DROPDOWN_MAIN_FILTERS.CATEGORIES}
        />
        <FilterWrapper
          allFilters={filtersContext}
          handleSelectionChange={handleSelectionChange}
          onSelectMultiButtonClick={onSelectMultiButtonClick}
          selectFilterMultiMenuComponentGetter={selectTypesMultiMenuComponentGetter}
          filterType={DROPDOWN_MAIN_FILTERS.TYPES}
        />
        <FilterWrapper
          allFilters={filtersContext}
          handleSelectionChange={handleSelectionChange}
          onSelectMultiButtonClick={onSelectMultiButtonClick}
          selectFilterMultiMenuComponentGetter={selectServicesMultiMenuComponentGetter}
          filterType={DROPDOWN_MAIN_FILTERS.SERVICES}
        />
        <div className="d-flex flex-column" automation-id="heatmap-date-range">
          <DateRangeFilter onPeriodChange={handleDateRangeChange} />
        </div>
        <div className="d-flex flex-column">
          <AdditionalFilters />
        </div>
      </div>
      <HeatMapSelectedFiltersDisplay
        cloudType={usersStore?.currDispUserCloudAccountType}
        unsetCurrentFilter={() => setCurrentFilter(undefined)}
      />
    </div>
  );
};

HeatMapFilters.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

export default HeatMapFilters;
