import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Spinner from 'shared/components/andtComponents/Spinner';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import toast from 'shared/components/andtComponents/Toast';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import { cloneDeep } from 'lodash';
import { useTableHeaderContext } from 'shared/components/tableHeader/TableHeaderContext';
import { withLinkedAccountsTableContextProvider } from './context/costCenter.context';
import EditDivisionModal from './components/EditDivisionModal';
import DivisionsTable from './components/DivisionsTable';
import AddDivisionModal from './components/AddDivisionModal';
import Filters from './components/Filters';

const CostCenters = () => {
  const { expandedRowIds, setExpandedRowIds } = useTableHeaderContext();
  const { usersStore, divisionsStore } = useRootStore();
  const [createDivisionModalOpen, setCreateDivisionModalOpen] = useState(false);
  const [editedDivision, setEditedDivision] = useState(null);
  const [filteredDivisions, setFilteredDivisions] = useState(usersStore.usersModel.divisionGroups);
  const [filters, setFilters] = useState({
    divisionsCodes: [],
    createdBy: [],
    divisionsNames: [],
    linkedAccounts: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [itemSelectedForDelete, setItemSelectedForDelete] = useState({
    isSelected: false,
    delTitle: null,
    item: null,
  });

  useEffect(() => {
    if (isInitialLoad) {
      setIsLoading(true);
      (async () => usersStore.fetchAvailableDivisionLinkedAccounts())();
      setIsLoading(false);
      setIsInitialLoad(false);
    }
  }, []);

  const handleSetFilters = (filters) => {
    const filtersValues = Object.entries(filters).reduce((formattedFiltersValues, [key, value]) => {
      const values = value.map((val) => val.value);
      return { ...formattedFiltersValues, [key]: values };
    }, {});
    const filteredDivisions = usersStore?.usersModel?.divisionGroups?.filter((division) => {
      const { divisionsCodes, createdBy, divisionsNames, linkedAccounts } = filtersValues;
      const isDivisionCodeMatch = divisionsCodes.length === 0 || divisionsCodes.includes(division.divisionCode);
      const isCreatedByMatch = createdBy.length === 0 || createdBy.includes(division.createdBy);
      const isDivisionNameMatch = divisionsNames.length === 0 || divisionsNames.includes(division.divisionName);
      const isLinkedAccountMatch =
        linkedAccounts.length === 0 ||
        division?.linkedAccounts?.some(({ linkedAccountId }) => linkedAccounts.includes(linkedAccountId));
      return isDivisionCodeMatch && isCreatedByMatch && isDivisionNameMatch && isLinkedAccountMatch;
    });
    setFilters(filters);
    setFilteredDivisions(filteredDivisions || []);
  };

  useEffect(() => {
    if (Array.isArray(usersStore.usersModel.divisionGroups)) {
      handleSetFilters(filters);
    }
  }, [usersStore.usersModel.divisionGroups]);

  const getPayerLinkedAccount = () => {
    const { currDispUserCloudAccountType } = usersStore;
    const account = usersStore.getCurrDisplayedAccount;
    if (account && currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS) {
      const linkedAccountId = account.accountId;
      const linkedAccountName = account.accountName.split('(')[0];
      return { linkedAccountId, linkedAccountName };
    }
    return null;
  };
  const handleChangeToCustomerView = (e, divId, divisionName, accountKey) => {
    usersStore.changeCustomerUserType();
    usersStore.rootStore.appStore.updatePricingMode(true);
    usersStore.updateCurrDisplayedDivIdAndName(divId, divisionName, accountKey);
    usersStore.rootStore.invalidateStores();
  };

  const addPayerLinkedAccountToLinkedAccounts = (linkedAccounts, payerLinkedAccount, divisions) => {
    if (!payerLinkedAccount) {
      return;
    }
    const allSelectedLinkedAccounts = [];
    divisions.forEach((div) => {
      const linkedAccountIds = div.linkedAccounts.map((la) => la.linkedAccountId);
      allSelectedLinkedAccounts.push(...linkedAccountIds);
    });
    const isPayerAccountAlreadySelected = allSelectedLinkedAccounts.includes(payerLinkedAccount.linkedAccountId);
    if (!isPayerAccountAlreadySelected) {
      linkedAccounts.unshift(payerLinkedAccount);
    }
  };

  const handleCreateNewDivision = async (e, { divisionName, divisionCode, linkedAccounts = null }) => {
    e.preventDefault();
    const newDivision = {
      divisionName,
      divisionCode,
      linkedAccounts,
    };
    newDivision.linkedAccounts = linkedAccounts?.map((linkedAcc) => ({
      linkedAccountId: linkedAcc.value,
      linkedAccountName: linkedAcc.label,
    }));
    const linkedAccountsIds = linkedAccounts.map((linkedAcc) => linkedAcc.value);
    try {
      setIsLoading(true);
      await usersStore.createNewDivisionGroup(divisionName, linkedAccountsIds, newDivision);
      setIsLoading(false);
      setCreateDivisionModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.clientMessage, { position: toast.POSITION.BOTTOM_LEFT });
    }
    return null;
  };

  // update division
  const handleAddNewLinkedAccs = async (e, row, updateSelectedAccs) => {
    e.preventDefault();
    if (updateSelectedAccs && updateSelectedAccs.length > 0) {
      // update divisions
      const modifiedLinkedAccsArray = row.linkedAccounts;
      updateSelectedAccs.forEach(({ label, value }) =>
        modifiedLinkedAccsArray.push({
          linkedAccountName: label,
          linkedAccountId: value,
        }),
      );
      try {
        setIsLoading(true);
        const isSuccess = await usersStore.updateDivisionGroupLinkAccounts(
          row.divisionId,
          modifiedLinkedAccsArray,
          row.divisionName,
          row.divisionCode,
          row.divisionTypeId,
          row.roleId,
        );
        if (!isSuccess) {
          toast.error('Something went wrong, please try again later');
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error(error.response.data.clientMessage, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    }
  };

  const handleRemoveLinkedAcc = async (removedLinkedAccountIds, row) => {
    // update
    if (row.linkedAccounts.length > 1) {
      const newLinkAccounts = row.linkedAccounts.filter(
        ({ linkedAccountId }) =>
          !removedLinkedAccountIds.some(
            ({ linkedAccountId: removedLinkedAccountId }) => linkedAccountId === removedLinkedAccountId,
          ),
      );
      setIsLoading(true);
      const isSuccess = await usersStore.updateDivisionGroupLinkAccounts(
        row.divisionId,
        newLinkAccounts,
        row.divisionName,
        row.divisionCode,
        row.divisionTypeId,
        row.roleId,
      );
      if (!isSuccess) {
        toast.error('Something went wrong, please try again later');
      }
      setIsLoading(false);
    } else {
      toast.error('Cost Center must have at least one linked account');
    }
  };

  const handleDeleteDivisionClicked = (e, division) => {
    e.preventDefault();
    setItemSelectedForDelete((prevState) => ({
      ...prevState,
      item: division,
      isSelected: true,
      name: division.divisionName,
    }));
  };
  const handleDeleteDivision = async (action) => {
    if (action === 'cancel') {
      setItemSelectedForDelete({ isSelected: false, item: null, name: null });
    } else if (action === 'delete') {
      try {
        setIsLoading(true);
        const { divisionId, divisionName } = itemSelectedForDelete.item;
        await usersStore.deleteDivisionGroup(divisionId, divisionName);
      } catch (error) {
        toast.error('Something went wrong, please try again later');
      } finally {
        setItemSelectedForDelete({ isSelected: false, item: null, name: null });
        setIsLoading(false);
      }
    }
    return null;
  };
  const onEditDivisionClick = (division) => {
    setEditedDivision(division);
  };
  const closeEditDivisionModal = () => {
    setEditedDivision(null);
  };
  const getCsvData = () => {
    const divisions = usersStore.usersModel.divisionGroups;
    return divisions.map(
      ({ accountId, divisionId, divisionName, divisionNameDisplay, divisionCode, linkedAccounts }) => {
        const linkedAccountsAsString = linkedAccounts
          .map(({ linkedAccountName, linkedAccountId }) => `${linkedAccountName} (${linkedAccountId})`)
          .join(', ');
        return {
          accountId,
          divisionId,
          divisionName,
          divisionNameDisplay: divisionNameDisplay || divisionName,
          divisionCode,
          linkedAccounts: linkedAccountsAsString,
        };
      },
    );
  };
  if (isInitialLoad || isLoading || divisionsStore.isLoading) {
    return <Spinner />;
  }
  const expanded = expandedRowIds.length > 0;
  const divisions = usersStore.usersModel.divisionGroups;
  const linkedAccounts = cloneDeep(usersStore.usersModel.availbleDivisionLinkedAccounts);
  const payerLinkedAccount = getPayerLinkedAccount();
  addPayerLinkedAccountToLinkedAccounts(linkedAccounts, payerLinkedAccount, divisions);
  return (
    <div>
      <TableHeader
        includeDetails
        tableName="Cost Center"
        hideSearch
        hideTitle
        fetchCsvData={() => [{ data: getCsvData(), filename: 'Cost Centers' }]}
        expanded={expanded}
        onCreate={() => setCreateDivisionModalOpen(true)}
        isCreateLast
        isInline
        setExpanded={() =>
          setExpandedRowIds(expanded ? [] : new Array(filteredDivisions.length).fill(0).map((_, i) => i))
        }
        filters={<Filters filters={filters} setFilters={handleSetFilters} divisions={divisions} />}
      />

      {divisions && divisions.length > 0 ? (
        <DivisionsTable
          divisions={filteredDivisions}
          expandedRowIds={expandedRowIds}
          setExpandedRowIds={(ids) => setExpandedRowIds(ids)}
          handleAddNewLinkedAccs={handleAddNewLinkedAccs}
          handleRemoveLinkedAcc={handleRemoveLinkedAcc}
          handleChangeToCustomerView={handleChangeToCustomerView}
          handleDeleteDivisionClicked={handleDeleteDivisionClicked}
          openEditDivisionModal={onEditDivisionClick}
        />
      ) : null}
      {createDivisionModalOpen && (
        <AddDivisionModal
          isOpen
          onClose={() => setCreateDivisionModalOpen(false)}
          saveDivision={handleCreateNewDivision}
          divisions={divisions}
        />
      )}
      <DeleteWarningModal
        deletedItemName={itemSelectedForDelete.name || ''}
        isOpen={itemSelectedForDelete.isSelected}
        handleDelete={handleDeleteDivision}
        warningMessage={itemSelectedForDelete.item ? `Be advise you are about to delete` : ''}
        modalTitle={itemSelectedForDelete.delTitle || ''}
      />
      {!!editedDivision && (
        <EditDivisionModal
          onClose={closeEditDivisionModal}
          editedDivision={editedDivision}
          saveDivision={async ({ divisionName, divisionCode }) => {
            setIsLoading(true);
            await usersStore.updateDivision(editedDivision.divisionId, divisionName, divisionCode);
            closeEditDivisionModal();
            setIsLoading(false);
          }}
        />
      )}
    </div>
  );
};

export default withLinkedAccountsTableContextProvider(observer(CostCenters));
