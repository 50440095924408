import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import { withRouter } from 'react-router-dom';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import Button from 'shared/components/andtComponents/Button';
import styles from './ProviderPopover.module.scss';

const AwsProviderPopover = ({ onClose, history, link }) => (
  <div className={styles.container}>
    <p className={styles.title}>Before we begin, please validate:</p>
    <p className={styles.desc}>
      <GenerateIcon iconName={ICONS.fileCheck.name} />
      You have access to the CUR file.
    </p>
    <p className={styles.desc}>
      <GenerateIcon iconName={ICONS.fileCheck.name} />
      You have ability to create assigning IAM policies.
    </p>
    <p className={classNames(styles.desc, styles.link)}>
      See{' '}
      <a href="https://cloudcost.anodot.com/hc/en-us/articles/6629591463068" target="_blank" rel="noopener noreferrer">
        more details
      </a>
      .
    </p>
    <div className={styles.footer}>
      <Button text="Cancel" onClick={onClose} isSecondary />
      <Button text="Continue" onClick={() => history.push(link)} />
    </div>
  </div>
);

const AzureProviderPopover = ({ onClose, history, link }) => (
  <div className={styles.container}>
    <p className={styles.title}>In order to proceed, you must:</p>
    <p className={styles.desc}>
      <GenerateIcon iconName={ICONS.fileCheck.name} />
      Have the role of Owner, Contributor, or Global Admin on Azure.
    </p>
    <p className={styles.desc}>
      <GenerateIcon iconName={ICONS.fileCheck.name} />
      Have the ability to your billing scope data.
    </p>
    <p className={classNames(styles.desc, styles.link)}>
      See{' '}
      <a href="https://cloudcost.anodot.com/hc/en-us/articles/9425596170780" target="_blank" rel="noopener noreferrer">
        more details
      </a>
      .
    </p>
    <div className={styles.footer}>
      <Button text="Cancel" onClick={onClose} isSecondary />
      <Button text="Continue" onClick={() => history.push(link)} />
    </div>
  </div>
);

const GcpProviderPopover = ({ onClose, history, link }) => (
  <div className={styles.container}>
    <p className={styles.title}>Before we begin, please validate:</p>
    <p className={styles.desc}>
      <GenerateIcon iconName={ICONS.fileCheck.name} />
      Billing dataset name.
    </p>
    <p className={styles.desc}>
      <GenerateIcon iconName={ICONS.fileCheck.name} />
      Billing table name.
    </p>
    <p className={styles.desc}>
      <GenerateIcon iconName={ICONS.fileCheck.name} />
      Service account key json file.
    </p>
    <p className={classNames(styles.desc, styles.link)}>
      See{' '}
      <a href="https://cloudcost.anodot.com/hc/en-us/articles/6041379186972" target="_blank" rel="noopener noreferrer">
        more details
      </a>
      .
    </p>
    <div className={styles.footer}>
      <Button text="Cancel" onClick={onClose} isSecondary />
      <Button text="Continue" onClick={() => history.push(link)} />
    </div>
  </div>
);

AwsProviderPopover.propTypes = {
  onClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};

AzureProviderPopover.propTypes = {
  onClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};

GcpProviderPopover.propTypes = {
  onClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};

export default {
  [CLOUD_TYPE_IDS.AWS]: withRouter(AwsProviderPopover),
  [CLOUD_TYPE_IDS.AZURE]: withRouter(AzureProviderPopover),
  [CLOUD_TYPE_IDS.GCP]: withRouter(GcpProviderPopover),
};
