/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/system/colorManipulator';
import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableColumnResizing, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import InformationOutline from 'mdi-react/InformationOutlineIcon';
import { UncontrolledTooltip } from 'reactstrap';
import {
  mapRecommendationsTypeToDisplay,
  mapRecommendationsTypeToIconSrc,
  mapRecTypeToDisplayText,
} from 'recommendations/constants/recommendationsConstants';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import { CURRENCY_DETAILS } from 'shared/constants/appConstants';

const styles = (theme) => ({
  root: {},
  tableStriped: {
    maxHeight: '280px',
    '& tbody tr:nth-of-type(odd)': {
      // backgroundColor: '#f7f8fa',
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  tableTotalRow: {
    '& tbody tr:first-child': {
      backgroundColor: '#f3f3f3',
      '& td': {
        fontWeight: '500',
        color: '#373837',
        fontSize: '14px',
      },
    },
  },
  rowHover: {
    '& tbody tr:not(:first-child):hover': {
      backgroundColor: alpha('#dff2ff', 0.2),
    },
  },
  textAdjustment: {
    '& tbody tr td': {
      fontSize: '12px',
    },
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
});
const colStyle = {
  paddingRight: '12px',
};
const iconStyle = {
  width: '17px',
  height: '18px',
  'object-fit': 'contain',
};

const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const linkStyle = {
  display: 'inline-block',
  position: 'relative',
  zIndex: 1,
  padding: '1em',
  margin: '-1em',
};

const initialSelectionId = 0;

class RecsSummaryTable extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    columnWidths: PropTypes.object.isRequired,
    tableColumnExtensions: PropTypes.object.isRequired,
    defaultSorting: PropTypes.object.isRequired,
    currenciesColumns: PropTypes.object.isRequired,
    recommTypeSelectHandler: PropTypes.func.isRequired,
    currentRecType: PropTypes.string.isRequired,
    isSelectTypeActive: PropTypes.bool,
    usersStore: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
    currencyCode: PropTypes.string.isRequired,
    overrideCurrency: PropTypes.string,
  };

  static defaultProps = {
    isSelectTypeActive: false,
    overrideCurrency: null,
  };

  constructor(props) {
    super(props);
    this.getSummaryRowData = this.getSummaryRowData.bind(this);
    const { columns, columnWidths, tableColumnExtensions, data, currenciesColumns } = this.props;
    this.state = {
      columns,
      columnWidths,
      tableColumnExtensions,
      rows: data,
      currenciesColumns,
      selection: initialSelectionId,
      currSortColumn: null,
      sortDirection: null,
      summaryRowSaving: this.getSummaryRowData(data).summaryRowSaving,
      summaryRowRecQuantity: this.getSummaryRowData(data).summaryRowRecQuantity,
      pageSizes: [10, 15, 20, 0],
      integratedSortingColumnExtensions: [
        { columnName: 'savings', compare: this.numericSortSavings },
        { columnName: 'quantity', compare: this.numericSortQuantity },
        { columnName: 'typeDisplay', compare: this.typeSort },
      ],
      sortingStateColumnExtensions: [
        {
          columnName: 'info',
          sortingEnabled: false,
        },
        {
          columnName: 'percentOfTotal',
          sortingEnabled: false,
        },
      ],
    };
  }

  componentDidMount() {
    const { isSelectTypeActive, recommTypeSelectHandler } = this.props;
    const { rows } = this.state;
    if (rows.length) {
      const recType = null; // equals to Total
      if (isSelectTypeActive) {
        recommTypeSelectHandler(recType);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, isSelectTypeActive, recommTypeSelectHandler } = this.props;
    if (data.length > 1 && prevProps.data.length !== data.length) {
      this.setState({ rows: data }, () => {
        const recType = null; // equals to Total
        if (isSelectTypeActive) {
          recommTypeSelectHandler(recType);
        }
      });
    }
  }

  getSummaryRowData = (rows) => {
    const totalRow = rows.find((row) => row.type === 'total');
    const { savings, quantity } = totalRow || {};
    return { summaryRowSaving: savings, summaryRowRecQuantity: quantity };
  };
  setSorting = (props) => {
    const { columnName, direction } = props[0];
    this.setState({ currSortColumn: columnName, sortDirection: direction });
  };
  setSelection = (id) => {
    const { usersStore } = this.props;
    const isSelectedRecomTotal = this.state.rows[id].type === 'total';
    const recType = isSelectedRecomTotal ? null : this.state.rows[id].type;
    segmentEvent({ target: `savingOpportunitiesTableItem-${recType ?? 'total'}` });
    const recTypeDisplay = isSelectedRecomTotal ? null : mapRecommendationsTypeToDisplay.get(this.state.rows[id].type);
    this.props.recommTypeSelectHandler(recType, recTypeDisplay);
    this.setState({ selection: id });
  };
  typeSort = (a, b) => {
    let newA = a;
    let newB = b;
    let { sortDirection } = this.state;
    sortDirection = sortDirection || 'desc';
    if (sortDirection === 'desc') {
      newA = a === 'Total' ? 'A' : a;
      newB = b === 'Total' ? 'A' : b;
    }
    if (sortDirection === 'asc') {
      newA = a === 'Total' ? 'Z' : a;
      newB = b === 'Total' ? 'Z' : b;
    }
    const c = newA > newB ? 1 : -1;
    return c;
  };
  numericSortSavings = (a, b) => {
    let newA = a;
    let newB = b;
    let { sortDirection } = this.state;
    sortDirection = sortDirection || 'desc';
    if (sortDirection === 'desc') {
      newA = a === this.state.summaryRowSaving ? -1 : a;
      newB = b === this.state.summaryRowSaving ? -1 : b;
    }
    const c = newA - newB;
    return c;
  };
  numericSortQuantity = (a, b) => {
    let newA = a;
    let newB = b;
    let { sortDirection } = this.state;
    sortDirection = sortDirection || 'desc';
    if (sortDirection === 'desc') {
      newA = a === this.state.summaryRowRecQuantity ? -1 : a;
      newB = b === this.state.summaryRowRecQuantity ? -1 : b;
    }
    const c = newA - newB;
    return c;
  };
  compareriCoveragePercent = (a, b) => {
    const c = +a.replace(' %', '') - +b.replace(' %', '');
    return c;
  };
  currenciesFormatter = (value) => {
    const { getCurrencyNumber, overrideCurrency } = this.props;
    const num = +value.value;
    const str = num.toFixed(0);
    const final = `${getCurrencyNumber(str.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'), 2, {}, overrideCurrency)}`;
    return final;
  };

  typeFormatter = (data) => (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      {data.row.type !== 'total' && (
        <div style={colStyle}>
          <img src={mapRecommendationsTypeToIconSrc.get(data.row.type)} style={iconStyle} alt="icon" />
        </div>
      )}
      <div>
        <span>
          {data.row.type !== 'total' ? mapRecommendationsTypeToDisplay.get(data.row.type) || data.row.type : 'Total'}
        </span>
      </div>
    </div>
  );
  infoFormatter = (data) =>
    data.row.type === 'total' ? (
      <></>
    ) : (
      <>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
            }}
            id={data.row.type}
          />
          <InformationOutline size={18} />
        </LinkIconButton>
        <UncontrolledTooltip placement="right" target={data.row.type}>
          {mapRecTypeToDisplayText.get(data.row.type)}
        </UncontrolledTooltip>
      </>
    );

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      // onClick={() => (row.type === 'total' ? null : this.setSelection(restProps.tableRow.rowId))}
      onClick={this.props.isSelectTypeActive ? () => this.setSelection(restProps.tableRow.rowId) : null}
      style={{
        cursor: this.props.isSelectTypeActive ? 'pointer' : 'default',
        backgroundColor: restProps.tableRow.rowId === this.state.selection ? '#dff2ff' : '',
        height: '40px',
      }}
    />
  );

  render() {
    const {
      rows,
      columns,
      columnWidths,
      tableColumnExtensions,
      currenciesColumns,
      integratedSortingColumnExtensions,
      sortingStateColumnExtensions,
    } = this.state;
    const { defaultSorting, currencyCode } = this.props;
    return (
      <div
        className="chart-box-style-no-shadow"
        style={{
          width: '100%',
          fontSize: '12px',
        }}
      >
        <Grid rows={rows} columns={columns}>
          <SortingState
            defaultSorting={defaultSorting}
            columnExtensions={sortingStateColumnExtensions}
            onSortingChange={this.setSorting}
          />
          <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
          <DataTypeProvider for={currenciesColumns} formatterComponent={this.currenciesFormatter} />
          <DataTypeProvider for={['typeDisplay']} formatterComponent={this.typeFormatter} />
          <DataTypeProvider for={['info']} formatterComponent={this.infoFormatter} />
          <TableWrapper
            columnExtensions={tableColumnExtensions}
            rowComponent={this.TableRow}
            styles={styles}
            containerStyle={{ maxHeight: '280px' }}
          />
          <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
          <TableHeaderRow
            showSortingControls
            cellComponent={({ column, children, ...rest }) => {
              if (column.name === 'savings' && currencyCode && currencyCode !== CURRENCY_DETAILS.USD) {
                return (
                  <TableHeaderRow.Cell {...rest}>
                    <div className="flex-cell">
                      {children}
                      <Tooltip title={CURRENCY_DETAILS.SAVING_CALCULATE_TOOLTIP} arrow className="toolTip">
                        <span>
                          <InfoIcon />
                        </span>
                      </Tooltip>
                    </div>
                  </TableHeaderRow.Cell>
                );
              }
              return (
                <TableHeaderRow.Cell column={column} {...rest}>
                  {children}
                </TableHeaderRow.Cell>
              );
            }}
          />
        </Grid>
      </div>
    );
  }
}

export default withRouter(withUserSettingsConsumer(observer(RecsSummaryTable)));
