import { API } from 'shared/utils/apiMiddleware';
import config from 'config';

const INTEGRATION_TYPES = {
  monday: 'monday',
  slack: 'slack',
};

export function validateIntegrationType(str) {
  if (Object.values(INTEGRATION_TYPES).some((val) => val === str)) {
    return str;
  }
  return null;
}

async function completeMondayAuth(params) {
  try {
    if (
      params.get('back_to_url') &&
      params.get('redirect_uri') &&
      (params.get('back_to_url') || '').includes('.monday.com')
    ) {
      const recipeId = params.get('recipe_id') || '';
      const httpReqRoute = params.get('redirect_uri');
      const res = await API.post('billings', httpReqRoute, { body: { recipeId } });
      if (res) {
        sessionStorage.removeItem('integration');
        window.location.href = params.get('back_to_url');
        return res;
      }
    }
    sessionStorage.removeItem('integration');
    return false;
  } catch (error) {
    sessionStorage.removeItem('integration');
    return false;
  }
}

async function completeSlackAuth() {
  try {
    const slackUrl = 'https://slack.com/oauth/v2/authorize';
    const redirectUri = `${config.apiGateway.LEGACY_URL}/api/v1/integrations/slack/auth`;
    const reply = await API.get('billings', '/api/v1/integrations/slack/init');
    const { SCOPE, CLIENT_ID, token } = reply.params;
    sessionStorage.removeItem('integration');
    const redirectUrl = `${slackUrl}?scope=${SCOPE}&client_id=${CLIENT_ID}&redirect_uri=${redirectUri}&state=${token}`;
    window.location.href = redirectUrl;
    return false;
  } catch (error) {
    sessionStorage.removeItem('integration');
    window.location.href = 'slack?success=false';
    return false;
  }
}

function completeAuthForIntegrations() {
  const queryString = sessionStorage.getItem('integration');
  const params = new URLSearchParams(queryString);

  switch (validateIntegrationType(params.get('type'))) {
    case INTEGRATION_TYPES.monday:
      return completeMondayAuth(params);
    case INTEGRATION_TYPES.slack:
      return completeSlackAuth(params);
    default:
      return false;
  }
}

export default {
  completeAuthForIntegrations,
};
