/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';
import { Col, Collapse } from 'reactstrap';
import GroupBySecondLevelDropDownWithSearch from 'shared/components/GroupBySecondLevelDropDownWithSearch';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { AwsCommonFields } from '../constants/awsConstants';

class GroupByFilterButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      secondaryCollapse: {},
    };
  }

  getFilterLabel = (field) => {
    const label = LabelCoordinator.getFieldLabel(field);
    return label;
  };

  handleClick = (item, handler) => {
    const { usersStore, itemsValuesMap, isSecondary, isGroupByValid } = this.props;
    segmentEvent({ type: `cue${isSecondary ? '-secondary' : ''}-group-by`, target: `${item}` }, usersStore);
    if (!isGroupByValid(item)) {
      this.setState({ collapse: false });
      return;
    }
    handler(itemsValuesMap.get(item), isSecondary);
    this.setState({
      collapse: false,
    });
  };

  toggle = () => {
    const { usersStore } = this.props;
    const { collapse } = this.state;
    segmentEvent({ type: 'click-event', target: 'toggle-group-by' }, usersStore);
    this.setState({
      collapse: !collapse,
    });
  };
  toggleSecondary = (e, value) => {
    const { secondaryCollapse } = this.state;
    const { usersStore } = this.props;
    segmentEvent({ type: 'click-event', target: `toggle-secondary-group-by-${value}` }, usersStore);
    this.setState({ secondaryCollapse: { [value]: !secondaryCollapse[value] } });
  };

  secondLevelHendler = (value, isSecondary) => {
    const { handler, isGroupByValid, usersStore } = this.props;
    segmentEvent({ type: `cue${isSecondary ? '-secondary' : ''}-group-by`, target: `${value}` }, usersStore);
    if (!isGroupByValid(value)) {
      this.setState({ collapse: false, secondaryCollapse: {} });
      return;
    }
    handler(value, isSecondary);
    this.setState({
      collapse: false,
      secondaryCollapse: {},
    });
  };

  renderItems = (items, handler, secondLevelItems) =>
    items.map((item) => {
      const value = item.value || item;
      const label = item.label || item; // if item.label does not exist label and value will be the same
      const { currentCloudType, itemsValuesMap, isSecondary } = this.props;
      const { secondaryCollapse } = this.state;
      const accountTagsLabel = LabelCoordinator.getFieldLabelByCloudType('accounttags', currentCloudType);
      const businessMappingViewpointsLabel = LabelCoordinator.getFieldLabelByCloudType(
        AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS,
        currentCloudType,
      );
      const tagsLabel = LabelCoordinator.getFieldLabelByCloudType('tags', currentCloudType);
      const labelsLabel = LabelCoordinator.getFieldLabelByCloudType('k8scustomtags', currentCloudType);
      if (
        ['Virtual Tags', 'Views', tagsLabel, labelsLabel, accountTagsLabel, businessMappingViewpointsLabel].includes(
          value,
        )
      ) {
        return (
          <Col style={{ padding: '1px 0px' }} xs="auto" key={value}>
            <p className="collapse-item" onClick={(e) => this.toggleSecondary(e, value)}>
              {label}
              <ChevronDown size={16} style={{ cursor: 'pointer' }} />
            </p>
            {secondaryCollapse[value] && (
              <GroupBySecondLevelDropDownWithSearch
                secondLevelItems={secondLevelItems}
                handler={this.secondLevelHendler}
                itemsValuesMap={itemsValuesMap}
                secondaryCollapse
                isSecondary={isSecondary}
                item={value}
                isCue
                currentCloudType={currentCloudType}
              />
            )}
          </Col>
        );
      }
      return (
        <p className="collapse-item" onClick={() => this.handleClick(value, handler)} key={value}>
          {this.getFilterLabel(label)}
        </p>
      );
    });

  render() {
    const { title, items, handler, secondLevelItems, selectedItem, automationId } = this.props;
    const { collapse } = this.state;
    return (
      <>
        <div
          onClick={() => this.toggle()}
          className="d-flex align-items-center ps-3 pe-2 h-100"
          style={{
            marginBottom: '5px',
            cursor: 'pointer',
            width: 'max-content',
          }}
          automation-id={automationId}
        >
          <h5>
            {title} <b>{selectedItem}</b>
          </h5>
          <ChevronDown size={22} className="ms-2" />
        </div>
        {collapse ? <button type="button" className="topbar__back" onClick={this.toggle} /> : null}
        <Collapse isOpen={collapse} className="menu-wrap cue">
          <div className="drop__menu">{this.renderItems(items, handler, secondLevelItems)}</div>
        </Collapse>
      </>
    );
  }
}

GroupByFilterButton.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  automationId: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  selectedItem: PropTypes.string.isRequired,
  isSecondary: PropTypes.bool.isRequired,
  itemsValuesMap: PropTypes.object.isRequired,
  secondLevelItems: PropTypes.array.isRequired,
  isGroupByValid: PropTypes.func,
  currentCloudType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  usersStore: PropTypes.object.isRequired,
};

GroupByFilterButton.defaultProps = {
  isGroupByValid: () => true,
};

export default GroupByFilterButton;
