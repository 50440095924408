/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Progress, Row } from 'reactstrap';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { azureCommonFieldToDisplayField } from 'shared/constants/azureConstants';
import { intersperse } from 'shared/utils/strUtil';
import { awsCommonFieldToDisplayField } from 'shared/constants/awsConstants';
import { BudgetTypes } from 'usage/constants/usageConstants';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

class BudgetChart extends Component {
  static propTypes = {
    budget: PropTypes.object.isRequired,
    currMonthlyUsage: PropTypes.number.isRequired,
    currDisplayedBudgetDate: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
    overrideCurrency: PropTypes.string,
  };

  static defaultProps = {
    overrideCurrency: null,
  };

  renderBudgetProgress = () => {
    const { getCurrencyNumber, budget, currMonthlyUsage, overrideCurrency } = this.props;
    if (!budget) {
      return null;
    }
    return (
      <Row>
        <Col>
          <div>
            {budget.currTotalCost <= budget.budgetAmount ? (
              <h5 className="budget-status-text">
                <span className="budget-status-number">
                  {getCurrencyNumber(
                    Number((budget.budgetAmount - budget.currTotalCost).toFixed(0), 2, {}, overrideCurrency),
                  )}
                </span>{' '}
                Left to Spend
              </h5>
            ) : (
              <h5 className="budget-status-number red">
                {getCurrencyNumber(
                  Number((budget.budgetAmount - budget.currTotalCost).toFixed(0)),
                  2,
                  {},
                  overrideCurrency,
                )}
              </h5>
            )}
          </div>
          <div>
            {budget.currTotalCost <= budget.budgetAmount ? (
              <Progress
                value={currMonthlyUsage}
                max={budget.budgetAmount}
                color="budget-blue"
                barClassName="budget-progress-status"
              >
                <span>
                  {`${getCurrencyNumber(
                    Number(budget.currTotalCost.toFixed(0)),
                    2,
                    {},
                    overrideCurrency,
                  )} / ${getCurrencyNumber(Number(budget.budgetAmount.toFixed(0)), 2, {}, overrideCurrency)}`}
                </span>
              </Progress>
            ) : (
              <Progress
                value={currMonthlyUsage}
                max={budget.budgetAmount}
                color="budget-red"
                barClassName="budget-progress-status"
              >
                <span>
                  {`${getCurrencyNumber(
                    Number(budget.currTotalCost.toFixed(0)),
                    2,
                    {},
                    overrideCurrency,
                  )} / ${getCurrencyNumber(Number(budget.budgetAmount.toFixed(0)), 2, {}, overrideCurrency)}`}
                </span>
              </Progress>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  renderChart = () => {
    const { currDisplayedBudgetDate, budget, getCurrencyNumber, overrideCurrency } = this.props;
    if (!budget) {
      return null;
    }
    const { budgetAmounts, budgetType, budgetAmount, accumCostData } = budget;
    const dateIndex = (budgetAmounts || []).findIndex((elem) => {
      const date = new Date(elem.date);
      return (
        date.getMonth() === currDisplayedBudgetDate.getMonth() &&
        date.getFullYear() === currDisplayedBudgetDate.getFullYear()
      );
    });
    const referenceLineValue =
      dateIndex > -1 && budgetType === BudgetTypes.EXPIRING ? budgetAmounts[dateIndex].amount : budgetAmount;
    return (
      <div style={{ marginTop: '20px' }}>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            width={500}
            height={400}
            data={accumCostData}
            margin={{
              top: 10,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={(value) => {
                if (value.toLowerCase().indexOf('w') > -1) {
                  return value;
                }
                if (value.length > 0 && value.length < 9) {
                  return new Date(value).toLocaleString('en-US', { month: 'short', year: 'numeric' });
                }
                return new Date(value).toLocaleString('en-US', { month: 'short', day: 'numeric' });
              }}
            />
            <YAxis tickFormatter={(value) => getCurrencyNumber(Number(value.toFixed(0)), 2, {}, overrideCurrency)} />
            <Tooltip formatter={(value) => getCurrencyNumber(Number(value.toFixed(0)), 2, {}, overrideCurrency)} />
            <ReferenceLine
              y={referenceLineValue}
              label={{ position: 'bottom', value: 'Budget', fill: 'red', fontSize: 14 }}
              stroke="red"
              ifOverflow="extendDomain"
            />
            <Area type="monotone" dataKey="Cost" stroke="#AFD0E7" fill="#AFD0E7" fillOpacity="0.7" />
            <Area type="monotone" dataKey="Forecasted cost" stroke="#2F709D" fill="#2F709D" fillOpacity="0.7" />
            <Area type="monotone" dataKey="Overuse cost" stroke="#E02020" fill="#E02020" fillOpacity="0.7" />
            <Legend />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  };

  renderFilters = () => {
    const { budget } = this.props;
    if (!budget) {
      return null;
    }
    const { includeFilters, excludeFilters } = budget;

    const filters = [];
    if (includeFilters && Object.keys(includeFilters).length) {
      const objectKeys = Object.keys(includeFilters);
      const row = (
        <div>
          <div>
            <h5>Included Filters</h5>
          </div>
          {objectKeys.map((objectKey) => {
            const key =
              awsCommonFieldToDisplayField.get(objectKey) || azureCommonFieldToDisplayField.get(objectKey) || objectKey;
            return (
              <div>
                <h5>
                  {key}: {intersperse(includeFilters[objectKey], ', ')}
                </h5>
              </div>
            );
          })}
        </div>
      );
      filters.push(row);
    }
    if (excludeFilters && Object.keys(excludeFilters).length) {
      const objectKeys = Object.keys(excludeFilters);
      const row = (
        <div>
          <div>
            <h5 style={{ marginTop: '13px' }}>Excluded Filters</h5>
          </div>
          {objectKeys.map((objectKey) => {
            const key =
              awsCommonFieldToDisplayField.get(objectKey) || azureCommonFieldToDisplayField.get(objectKey) || objectKey;
            return (
              <div>
                <h5>
                  {key}: {intersperse(excludeFilters[objectKey], ', ')}
                </h5>
              </div>
            );
          })}
        </div>
      );
      filters.push(row);
    }
    if (filters.length > 0) {
      return filters;
    }
    return null;
  };

  render() {
    return (
      <div>
        {this.renderFilters()}
        {this.renderBudgetProgress()}
        {this.renderChart()}
      </div>
    );
  }
}

const ObserverBudgetChart = withUserSettingsConsumer(BudgetChart);
export default ObserverBudgetChart;
