import { ReportPeriodTime } from 'usage/constants/costAndUsageConstants';
import { ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import moment from 'moment';
import { CLOUD_TYPE_IDS } from '../users/constants/usersConstants';

export const BILLINGS_API_NAME = 'billings';
export const RECOMMENDATIONS_ROOT = '/api/v1/recommendationsNew';
export const RECOMMENDATIONS_HEATMAP_ROOT = `${RECOMMENDATIONS_ROOT}/heatmap`;
export const RECOMMENDATIONS_LIST_ROOT = `${RECOMMENDATIONS_ROOT}/list`;
export const RECOMMENDATION_ACTIONS_ROOT = `${RECOMMENDATIONS_ROOT}/user-action`;
export const RECOMMENDATION_BULK_ACTIONS_ROOT = `${RECOMMENDATIONS_ROOT}/bulk_user_action`;

export const STATLE_TIME = 60 * 60 * 1000;

export const SORT_BY_OPTIONS = {
  title: 'Sort by',
  items: [
    { property: 'savings', label: 'Saving Amount', symbol: '$' },
    { property: 'recs_count', label: 'Number of Recommendations', symbol: '#' },
  ],
};

export const GROUP_BY_OPTIONS = {
  title: 'Group by',
  items: {
    BUSINESS_MAP: { id: 'business_map', label: 'Business Map', icon: ICONS.pieChart.name },
    CAT_ID: { id: 'cat_id', label: 'Category', icon: ICONS.memo.name },
    COST_CENTER: { id: 'cost_center', label: 'Cost Center', icon: ICONS.listTree.name },
    CUSTOMER: { id: 'customer', label: 'Customer', icon: ICONS.user.name },
    CUSTOM_TAGS: { id: 'custom_tags', label: 'Custom Tags', icon: ICONS.tag.name },
    ENRICHMENT_TAGS: { id: 'enrichment_tags', label: 'Enrichment Tags', icon: ICONS.tags.name },
    INSTANCE_TYPE: { id: 'instance_type', label: 'Instance Type', icon: ICONS.gearsRegular.name },
    LINKED_ACCOUNT_ID: { id: 'linked_account_id', label: 'Linked Account', icon: ICONS.link.name },
    REGION: { id: 'region', label: 'Region', icon: ICONS.earth.name },
    RESOURCE_ID: { id: 'resource_id', label: 'Resource Name', icon: ICONS.server.name },
    SERVICE: { id: 'service', label: 'Service', icon: ICONS.cloud.name },
    TYPE_ID: { id: 'type_id', label: 'Type', icon: ICONS.list.name },
    VIRTUAL_TAGS: { id: 'virtual_tags', label: 'Virtual Tags', icon: ICONS.tags.name },
  },
};

export const FILTERS = {
  ASSIGNEE: { id: 'assignee', label: 'Assignee' },
  ANNUAL_SAVINGS_GREATER_THAN: { id: 'annual_savings_greater_than', label: 'Annual Savings Greater Than' },
  AGE_RANGE: { id: 'age_range', label: 'Age Range' },
  BUSINESS_MAP: { id: 'business_map', label: 'Business Map' },
  CATEGORIES: { id: 'cat_id', label: 'Categories' },
  COST_CENTER: { id: 'cost_center', label: 'Cost Center' },
  CUSTOM_TAGS: { id: 'custom_tags', label: 'Custom Tags' },
  ENRICHMENT_TAGS: { id: 'enrichment_tags', label: 'Enrichment Tags' },
  INSTANCE_TYPE: { id: 'instance_type', label: 'Instance Type' },
  IS_OPEN: { id: 'is_open', label: 'Status' },
  IS_STARRED: { id: 'is_starred', label: 'Starred' },
  LINKED_ACCOUNT_ID: { id: 'linked_account_id', label: 'Linked Account' },
  REGION: { id: 'region', label: 'Region' },
  RESOURCE_ID: { id: 'resource_id', label: 'Resource Name' },
  SERVICES: { id: 'service', label: 'Services' },
  TYPES: { id: 'type_id', label: 'Types' },
  USER_STATUS: { id: 'user_status', label: 'User Status' },
  VIRTUAL_TAGS: { id: 'virtual_tags', label: 'Virtual Tags' },
  OPEN_RECS_CREATION_DATE: { id: 'open_recs_creation_date', label: 'Open recommendation creation dates' },
  CLOSED_AND_DONE_RECS_DATES: { id: 'closed_and_done_recs_dates', label: 'Closed and Done recommendations dates' },
  RECOMMENDED_INSTANCE_TYPE: { id: 'recommended_instance_type', label: 'Recommended Instance Type' },
};

export const CLOSED_AND_DONE_RECS_DATES_FILTERS = {
  LAST_UPDATE_DATE: 'last_update_date',
  OPERATOR: 'operator',
  CREATION_DATE: 'creation_date',
};

export const CLOSED_AND_DONE_RECS_DATES_OPERATOR = {
  AND: 'And',
  OR: 'Or',
};

export const USER_STATUS_FILTERS = {
  filterLabel: FILTERS.USER_STATUS.label,
  ITEMS: {
    ALL: { id: 'all', label: 'All' },
    EXCLUDED: { id: 'excluded', label: 'Excluded' },
    DONE: { id: 'done', label: 'Done' },
  },
};

export const DROPDOWN_MAIN_FILTERS = {
  CATEGORIES: { id: 'cat_id', label: 'Categories', icon: ICONS.memo.name },
  TYPES: { id: 'type_id', label: 'Types', icon: ICONS.list.name },
  SERVICES: { id: 'service', label: 'Services', icon: ICONS.cloud.name },
  PERIOD: { id: 'period', label: 'Period' },
};

export const DROPDOWN_ADDITIONAL_FILTERS = {
  ASSIGNEE: { id: 'assignee', label: 'Assignee', icon: ICONS.clipboardUser.name, order: 10 },
  COST_CENTER: { id: 'costCenter', label: 'Cost Center', icon: ICONS.listTree.name, order: 1 },
  CUSTOM_TAGS: { id: 'custom_tags', label: 'Custom Tags', icon: ICONS.tag.name, order: 7 },
  BUSINESS_MAP: { id: 'business_map', label: 'Business Map', icon: ICONS.pieChart.name, order: 11 },
  ENRICHMENT_TAGS: { id: 'enrichment_tags', label: 'Enrichment Tags', icon: ICONS.tags.name, order: 8 },
  INSTANCE_TYPE: { id: 'instance_type', label: 'Instance Type', icon: ICONS.gearsRegular.name, order: 4 },
  LINKED_ACCOUNT_ID: { id: 'linked_account_id', label: 'Linked Account', icon: ICONS.link.name, order: 2 },
  REGION: { id: 'region', label: 'Region', icon: ICONS.earth.name, order: 3 },
  RECOMMENDED_INSTANCE_TYPE: {
    id: 'recommended_instance_type',
    label: 'Recommended Instance Type',
    icon: ICONS.gearsRegular.name,
    order: 5,
  },
  RESOURCE_ID: { id: 'resource_id', label: 'Resource Name', icon: ICONS.server.name, order: 6 },
  VIRTUAL_TAGS: { id: 'virtual_tags', label: 'Virtual Tags', icon: ICONS.tags.name, order: 9 },
};

export const SWITCH_BUTTON_STATUSES = {
  ALL: 'All',
  OPEN: 'Open',
  COMPLETED: 'Compl.✓',
  DONE: 'Done',
  UN_DONE: 'Undone',
  EXCLUDED: 'Excluded',
  NOT_EXCLUDED: 'Not Exc.',
  STARRED: 'Starred',
  NOT_STARRED: 'Not Starred',
};

export const SWITCH_BUTTON_LABELS = {
  STATUS: 'Status',
  DONE: 'Done',
  EXCLUDED: 'Excluded',
  STARRED: 'Starred',
};

export const getSwitchButtons = (isOpen, isDone, isExcluded, isStarred) => ({
  STATUS: {
    switchLabel: SWITCH_BUTTON_LABELS.STATUS,
    infoIconTooltipText: 'Show all recommendations or filter by status.',
    optionsList: {
      OPEN: { label: SWITCH_BUTTON_STATUSES.OPEN, isSelected: isOpen === true },
      ALL: { label: SWITCH_BUTTON_STATUSES.ALL, isSelected: isOpen === null },
      COMPLETED: { label: SWITCH_BUTTON_STATUSES.COMPLETED, isSelected: isOpen === false },
    },
  },
  DONE: {
    switchLabel: SWITCH_BUTTON_LABELS.DONE,
    infoIconTooltipText: 'Show recommendations marked as done, or show all',
    optionsList: {
      UN_DONE: { label: SWITCH_BUTTON_STATUSES.UN_DONE, isSelected: isDone === false },
      ALL: { label: SWITCH_BUTTON_STATUSES.ALL, isSelected: isDone === null },
      DONE: { label: SWITCH_BUTTON_STATUSES.DONE, isSelected: isDone === true },
    },
  },
  EXCLUDED: {
    switchLabel: SWITCH_BUTTON_LABELS.EXCLUDED,
    infoIconTooltipText: 'Filter out excluded recommendations',
    optionsList: {
      NOT_EXCLUDED: { label: SWITCH_BUTTON_STATUSES.NOT_EXCLUDED, isSelected: isExcluded === false },
      ALL: { label: SWITCH_BUTTON_STATUSES.ALL, isSelected: isExcluded === null },
      EXCLUDED: { label: SWITCH_BUTTON_STATUSES.EXCLUDED, isSelected: isExcluded === true },
    },
  },
  STARRED: {
    switchLabel: SWITCH_BUTTON_LABELS.STARRED,
    infoIconTooltipText: 'Filter out recommendations you have starred earlier',
    optionsList: {
      STARRED: { label: SWITCH_BUTTON_STATUSES.STARRED, iconName: ICONS.star.name, isSelected: isStarred === true },
      ALL: { label: SWITCH_BUTTON_STATUSES.ALL, isSelected: isStarred === null },
      NOT_STARRED: {
        label: SWITCH_BUTTON_STATUSES.NOT_STARRED,
        iconName: ICONS.regularStarSlash.name,
        isSelected: isStarred === false,
      },
    },
  },
});

export const DATE_RANGE_OPTIONS = {
  PREVIOUS_DAY: { value: ReportPeriodTime.PREVIOUS_DAY, label: 'Previous day' },
  SEVEN_DAYS: { value: ReportPeriodTime.SEVEN_DAYS, label: 'Last 7 Days' },
  PREVIOUS_MONTH: { value: ReportPeriodTime.PREVIOUS_MONTH, label: 'Previous month' },
  PREVIOUS_TWO_MONTH: { value: ReportPeriodTime.PREVIOUS_2_MONTH, label: 'Previous two month' },
  LAST_YEAR: { value: 'last_year', label: 'Last year' },
  CUSTOM: { value: 'custom', label: 'Custom' },
};

export const PERIOD_TYPE = {
  until: 'until',
  nextTime: 'forNextTime',
};
const DATE_FORMAT = 'YYYY-MM-DD';

export const COST_CONFIGURATION = {
  AMORTIZED: 'amortized',
  NET_AMORTIZED: 'netAmortized',
  NET_UNBLENDED: 'netUnblended',
  UNBLENDED: 'unblended',
};

export const ACTION_SUCCESS_MESSAGE = {
  star: "Recommendation marked successfully as 'Star', filter by 'Star' to view it",
  unstar: "Recommendation is no longer marked as 'Star'",
  done: "Recommendation marked successfully as 'Done’, filter by 'DONE' to view it",
  undone: "Recommendation marked successfully as 'Undone', filter by 'UNDONE' to view it",
  exclude: "Recommendation excluded successfully, filter by 'EXCLUDE' to view it",
  include: "Recommendation is no longer excluded, filter by 'NOT EXC.' to view it",
  label: 'Recommendation labeled successfully',
};
export const BULK_ACTION_SUCCESS_MESSAGE = {
  star: "Recommendations marked successfully as 'Star', filter by 'Star' to view them",
  unstar: "Recommendations is no longer marked as 'Star'",
  done: "Recommendations marked successfully as 'Done’, filter by 'DONE' to view them",
  undone: "Recommendations marked successfully as 'Undone', filter by 'UNDONE' to view them",
  exclude: "Recommendations excluded successfully, filter by 'EXCLUDE' to view them",
  include: "Recommendations is no longer excluded, filter by 'NOT EXC.' to view them",
  label: 'Recommendations labeled successfully',
};

export const ACTION_STATUS = {
  done: 'done',
  undone: 'undone',
  excluded: 'excluded',
  include: 'include',
};
export function calculateStartAndEndDates(dateRange) {
  const periodTime = dateRange?.value;

  let date;

  const today = moment();
  switch (periodTime) {
    case DATE_RANGE_OPTIONS.PREVIOUS_DAY.value:
      date = today.subtract(1, 'day');
      break;
    case DATE_RANGE_OPTIONS.SEVEN_DAYS.value:
      date = today.subtract(7, 'days');
      break;
    case DATE_RANGE_OPTIONS.PREVIOUS_MONTH.value:
      date = today.subtract(1, 'month').startOf('month');
      break;
    case DATE_RANGE_OPTIONS.PREVIOUS_TWO_MONTH.value:
      date = today.subtract(2, 'months').startOf('month');
      break;
    case DATE_RANGE_OPTIONS.LAST_YEAR.value:
      date = today.subtract(1, 'year').startOf('day');
      break;
    default:
      return 'null';
  }

  return {
    from: date.format(DATE_FORMAT),
    to: null,
  };
}

export function calculateRange(startDate) {
  const inputDate = moment(startDate.from, DATE_FORMAT);

  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  const sevenDaysAgo = moment().subtract(7, 'days');
  // const fourteenDaysAgo = moment().subtract(14, 'days');
  const startOfMonth = moment().startOf('month');
  const lastMonthStart = moment().subtract(1, 'month').startOf('month');
  const twoMonthsAgoStart = moment().subtract(2, 'months').startOf('month');
  const yearAgo = moment().subtract(1, 'year').startOf('day');

  let range;
  if (inputDate.isSame(yesterday, 'day')) {
    range = DATE_RANGE_OPTIONS.PREVIOUS_DAY.value;
  } else if (inputDate.isBetween(sevenDaysAgo, today, 'day', '[]')) {
    range = DATE_RANGE_OPTIONS.SEVEN_DAYS.value;
  } else if (inputDate.isBetween(lastMonthStart, startOfMonth, 'day', '[]')) {
    range = DATE_RANGE_OPTIONS.PREVIOUS_MONTH.value;
  } else if (inputDate.isBetween(twoMonthsAgoStart, lastMonthStart, 'day', '[]')) {
    range = DATE_RANGE_OPTIONS.PREVIOUS_TWO_MONTH.value;
  } else if (inputDate.isSameOrAfter(yearAgo, 'day')) {
    range = DATE_RANGE_OPTIONS.LAST_YEAR.value;
  } else {
    range = DATE_RANGE_OPTIONS.CUSTOM.value;
  }

  return range;
}

export const getLinkedAccountName = (cloudType) => {
  if (cloudType === CLOUD_TYPE_IDS.AWS) {
    return 'Linked Account';
  }

  if (cloudType === CLOUD_TYPE_IDS.AZURE) {
    return 'Subscription';
  }
  if (cloudType === CLOUD_TYPE_IDS.GCP) {
    return 'Project';
  }
  return '';
};
