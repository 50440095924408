/* eslint-disable camelcase */
import { makeObservable, observable } from 'mobx';

class Budget {
  constructor(
    budgetId,
    userKey,
    budget,
    budgetAmounts,
    newBudgetAmount,
    budgetType,
    budgetAmountType,
    period,
    startDate,
    endDate,
    isFlexible,
    isAlerts,
    isRelativeAlerts,
    budgetAlerts,
    creationDate,
    costData,
    accumCostData,
    isActive,
    currTotalCost,
    budgetName,
    totalForcasted,
    linkedAccounts,
    services,
    tags,
    includeFilters,
    excludeFilters,
    previousBudgets,
    monthlyStartDate,
  ) {
    this.budgetId = budgetId;
    this.userKey = userKey;
    this.budgetAmount = Number(budget);
    this.budgetAmounts = budgetAmounts;
    this.newBudgetAmount = Number(newBudgetAmount);
    this.budgetType = budgetType;
    this.budgetAmountType = budgetAmountType;
    this.period = period;
    this.startDate = new Date(startDate);
    this.endDate = new Date(endDate);
    this.isFlexible = isFlexible;
    this.isAlerts = isAlerts;
    this.isRelativeAlerts = isRelativeAlerts;
    this.budgetAlerts = this.prepareAlerts(budgetAlerts);
    this.creationDate = creationDate;
    this.costData = costData;
    this.accumCostData = accumCostData;
    this.isActive = isActive;
    this.currTotalCost = currTotalCost;
    // this.name = budgetName;
    this.budgetName = budgetName;
    this.totalForcasted = totalForcasted;
    this.linkedAccounts = linkedAccounts;
    this.services = services;
    this.tags = tags;
    this.includeFilters = includeFilters;
    this.excludeFilters = excludeFilters;
    this.previousBudgets = previousBudgets;
    this.monthlyStartDate = monthlyStartDate;
    makeObservable(this, {
      budgetId: observable,
      userKey: observable,
      budgetAmount: observable,
      budgetAmounts: observable,
      newBudgetAmount: observable,
      budgetType: observable,
      budgetAmountType: observable,
      period: observable,

      startDate: observable,
      endDate: observable,
      isFlexible: observable,
      budgetAlerts: observable,
      creationDate: observable,
      costData: observable,
      accumCostData: observable,
      isActive: observable,
      currTotalCost: observable,
      budgetName: observable,
      // name: observable,
      totalForcasted: observable,
      linkedAccounts: observable,
      services: observable,
      tags: observable,
      includeFilters: observable,
      excludeFilters: observable,
      isAlerts: observable,
      isRelativeAlerts: observable,
      previousBudgets: observable,
    });
  }

  prepareAlerts = (alerts) =>
    Object.keys(alerts).map((alertId) => ({
      alertPercent: alerts[alertId].budget_percent_to_alert_from || alerts[alertId].alertPercent,
      alertEmail: alerts[alertId].recipients || alerts[alertId].alertEmail,
      whenToAlert: alerts[alertId].when_to_alert || alerts[alertId].whenToAlert,
      alertGranularity: alerts[alertId].alert_granularity || alerts[alertId].alertGranularity,
    }));
}

export default Budget;
