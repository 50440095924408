import React, { useContext, useEffect, useState } from 'react';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ToolTipWrapper,
} from '@pileus-cloud/anodot-frontend-common/dist';
import FieldSidebarFilter from 'shared/components/FilterSidebar/FieldSidebarFilter';
import { SearchFilters } from 'recommendationsNew/components/heatMap/heatMapFilters/additionalFilters/searchFilters';
// eslint-disable-next-line max-len
import { AnnualSavingsMinValueFilter } from 'recommendationsNew/components/heatMap/heatMapFilters/additionalFilters/annualSavingsMinValueFilter';
// eslint-disable-next-line max-len
import { SwitchButtonsContainer } from 'recommendationsNew/components/heatMap/heatMapFilters/additionalFilters/switchButtonsContainer';
import { DROPDOWN_ADDITIONAL_FILTERS, FILTERS, SWITCH_BUTTON_LABELS } from 'recommendationsNew/consts';
import useDynamicFilter from 'recommendationsNew/hooks/react-query/useDynamicFilter';
import useDynamicRanges from 'recommendationsNew/hooks/react-query/useDynamicRanges';
import RecommendationsNewContext from 'recommendationsNew/contexts/recommendationsNewContext';

import classes from './additionalFilters.module.scss';

export function AdditionalFilters() {
  const [isOpen, setIsOpen] = useState(false);
  const [displayedFilters, setDisplayedFilters] = useState(
    Object.values(DROPDOWN_ADDITIONAL_FILTERS).sort((a, b) => a.order - b.order),
  );
  const [filtersSearchString, setFiltersSearchString] = useState('');
  const [currentlyOpenFilter, setCurrentlyOpenFilter] = useState();

  const ERROR_EXCLUDE_DONE = 'Choose either Done or Excluded. Both Cannot be selected.';
  const [errorMessage, setErrorMessage] = useState('');

  const { recommendationFilters: filtersContext, setRecommendationFilters: setFiltersContext } =
    useContext(RecommendationsNewContext);

  const { [FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]: annualSavingsGreaterThan, ...restFiltersContext } = filtersContext;

  const rangeFiltersContext = {
    [FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]: annualSavingsGreaterThan,
  };

  const [localFiltersCopy, setLocalFiltersCopy] = useState(restFiltersContext);
  const [rangeFiltersCopy, setRangeFiltersCopy] = useState(rangeFiltersContext);

  const dynamicRangesHook = useDynamicRanges(isOpen);
  const { data: dynamicRanges, isLoading: isDynamicRangesLoading } = dynamicRangesHook.fetchDynamicRanges({
    filters: localFiltersCopy,
  });

  const onFiltersSearchStringChange = (searchString) => {
    setFiltersSearchString(searchString);
    const searchedFilters = displayedFilters.filter((option) =>
      option.label.toLowerCase().includes(searchString.toLowerCase()),
    );
    setDisplayedFilters(searchedFilters);
  };

  const changeSwitchButtonsFilters = (event) => {
    setErrorMessage('');
    if (event.filter === SWITCH_BUTTON_LABELS.STATUS) {
      setLocalFiltersCopy({
        ...localFiltersCopy,
        [FILTERS.IS_OPEN.id]: event.filterValue,
      });
    } else if (event.filter === SWITCH_BUTTON_LABELS.DONE) {
      if (localFiltersCopy[FILTERS.USER_STATUS.id]?.excluded === true && event.filterValue === true) {
        setErrorMessage(ERROR_EXCLUDE_DONE);
        return;
      }
      setLocalFiltersCopy({
        ...localFiltersCopy,
        [FILTERS.USER_STATUS.id]: { ...localFiltersCopy[FILTERS.USER_STATUS.id], done: event.filterValue },
      });
    } else if (event.filter === SWITCH_BUTTON_LABELS.EXCLUDED) {
      if (localFiltersCopy[FILTERS.USER_STATUS.id]?.done === true && event.filterValue === true) {
        setErrorMessage(ERROR_EXCLUDE_DONE);
        return;
      }
      setLocalFiltersCopy({
        ...localFiltersCopy,
        [FILTERS.USER_STATUS.id]: { ...localFiltersCopy[FILTERS.USER_STATUS.id], excluded: event.filterValue },
      });
    } else if (event.filter === SWITCH_BUTTON_LABELS.STARRED) {
      setLocalFiltersCopy({
        ...localFiltersCopy,
        [FILTERS.IS_STARRED.id]: event.filterValue,
      });
    }
  };

  const onFilterValuesChange = (fieldName, selection) => {
    setLocalFiltersCopy({
      ...localFiltersCopy,
      [fieldName]: selection,
    });
  };

  const onFilterTypeChange = (fieldName, selection) => {
    console.log('onFilterTypeChange', fieldName, selection);
    // setLocalFiltersCopy({
    //   ...localFiltersCopy,
    //   [fieldName]: selection,
    // });
  };

  const onAnnualSavingsValueChange = (e) => {
    const newFilters = {
      ...rangeFiltersCopy,
      [FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]: e.minValueLimit,
    };
    setRangeFiltersCopy(newFilters);
  };

  useEffect(() => {
    if (dynamicRanges && !isDynamicRangesLoading) {
      let newFilters = { ...rangeFiltersCopy };
      if (!rangeFiltersCopy[FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]) {
        newFilters = {
          ...newFilters,
          [FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]: dynamicRanges.annualSavingsRange?.min,
        };
      }
      setRangeFiltersCopy(newFilters);
    }
  }, [dynamicRanges, isDynamicRangesLoading]);

  const onOpenAdditionalFiltersChange = () => {
    const newIsOpenState = !isOpen;
    setIsOpen(newIsOpenState);
    // If additional filters was opened and now closing - apply filters
    if (newIsOpenState === false) {
      if (rangeFiltersCopy) {
        // Check if default values are selected and remove them from range filters
        const tmpRangeFiltersCopy = { ...rangeFiltersCopy };
        if (rangeFiltersCopy[FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id] === dynamicRanges?.annualSavingsRange?.min) {
          delete tmpRangeFiltersCopy[FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id];
        }

        if (Object.keys(tmpRangeFiltersCopy).length > 0) {
          setFiltersContext({ ...localFiltersCopy, ...tmpRangeFiltersCopy });
        } else {
          setFiltersContext(localFiltersCopy);
        }
      }
    } else {
      setLocalFiltersCopy(restFiltersContext);
      setRangeFiltersCopy(rangeFiltersContext);
    }
  };

  return (
    <div className={classes.additionalFiltersContainer} automation-id="heatmap-additional-filters">
      <Popover open={isOpen} onOpenChange={onOpenAdditionalFiltersChange}>
        <PopoverTrigger className={classes.popoverTrigger}>
          <ToolTipWrapper content="Additional Filters">
            <div className={classes.filtersButton}>
              <GenerateIcon className={classes.filtersIcon} iconName={ICONS.barsFilter.name} />
            </div>
          </ToolTipWrapper>
        </PopoverTrigger>

        <PopoverContent align="start" sideOffset={7} className={classes.popoverContent}>
          <div className={classes.searchContainer}>
            <SearchFilters searchValue={filtersSearchString} onSearchChange={onFiltersSearchStringChange} />
          </div>
          <div className={classes.filtersContainer}>
            {displayedFilters?.map((filter) => (
              <div className={classes.fieldSidebarFilterContainer}>
                <FieldSidebarFilter
                  automation-id={`heatmap-${filter.id}-filter`}
                  handleChange={onFilterValuesChange}
                  handleChangeFilterType={onFilterTypeChange}
                  label={filter.label}
                  field={filter.id}
                  isDisabled={filter.isDisabled}
                  filterIcon={filter.icon}
                  selectedOptions={localFiltersCopy[filter.id]}
                  fetchData={() =>
                    useDynamicFilter(filter.id === currentlyOpenFilter ? filter.id : undefined).fetchDynamicFilter(
                      { ...localFiltersCopy, rangeFiltersCopy },
                      (data) => data.page,
                    )
                  }
                  options={[]}
                  onOpen={() => {
                    setCurrentlyOpenFilter(filter.id);
                  }}
                  toFormatData={false}
                />
              </div>
            ))}
            <>
              <AnnualSavingsMinValueFilter
                automation-id="heatmap-annual-savings-filter"
                lowerLimit={dynamicRanges?.annualSavingsRange?.min}
                upperLimit={dynamicRanges?.annualSavingsRange?.max}
                minValueLimitSelected={rangeFiltersCopy[FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]}
                onChangeLimits={onAnnualSavingsValueChange}
                isLoading={isDynamicRangesLoading}
              />
            </>
            <SwitchButtonsContainer
              onFiltersChange={changeSwitchButtonsFilters}
              filtersSearchString={filtersSearchString}
              isOpen={localFiltersCopy[[FILTERS.IS_OPEN.id]]}
              isStarred={localFiltersCopy[[FILTERS.IS_STARRED.id]]}
              isExcluded={localFiltersCopy[[FILTERS.USER_STATUS.id]]?.excluded}
              isDone={localFiltersCopy[[FILTERS.USER_STATUS.id]]?.done}
            />
            {errorMessage.trim().length > 0 ? (
              <div className={classes.errorMessageContainer}>
                <GenerateIcon iconName={ICONS.circleExclamation.name} />
                {errorMessage}
              </div>
            ) : (
              <div />
            )}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
