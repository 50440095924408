import { useMutation, useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import {
  BILLINGS_API_NAME,
  RECOMMENDATION_ACTIONS_ROOT,
  RECOMMENDATION_BULK_ACTIONS_ROOT,
  RECOMMENDATIONS_ROOT,
  STATLE_TIME,
} from 'recommendationsNew/consts';
import { toast } from 'react-toastify';

function userActions({ recId, action, actionParams }) {
  return API.post(BILLINGS_API_NAME, `${RECOMMENDATION_ACTIONS_ROOT}`, {
    body: {
      recId,
      action,
      actionParams,
    },
  });
}
function bulkUserAction({ recIds, action, actionParams }) {
  return API.post(BILLINGS_API_NAME, `${RECOMMENDATION_BULK_ACTIONS_ROOT}`, {
    body: {
      recIds,
      action,
      actionParams,
    },
  });
}
export default function useUserRecAction() {
  const queryKey = [apiConstants.QUERY_KEYS.RECOMMENDATION_USER_ACTION];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    recUserAction: ({ recId, action, actionParams }) =>
      useQuery([...queryKey, recId, action, actionParams], () => userActions({ recId, action, actionParams }), {
        enabled: !(!recId || !action),
        retry: false,
        staleTime: STATLE_TIME,
        onError: (error) => toast.error(error.response?.data?.message || error.message),
      }),
    bulkUserAction: ({ recIds, action, actionParams }) =>
      useQuery([...queryKey, recIds, action, actionParams], () => bulkUserAction({ recIds, action, actionParams }), {
        enabled: !(!recIds || !action),
        retry: false,
        staleTime: STATLE_TIME,
        onError: (error) => toast.error(error.response?.data?.message || error.message),
      }),
  };
}

export function recommendationCommentsProvider(recId) {
  const url = `${RECOMMENDATIONS_ROOT}/${recId}/comments`;
  return {
    createComment: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.post(BILLINGS_API_NAME, url, { body: payload }),
        onSuccess,
        onError,
      }),
    deleteComment: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.del(BILLINGS_API_NAME, url, { body: payload }),
        onSuccess,
        onError,
      }),
    updateComment: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.put(BILLINGS_API_NAME, url, { body: payload }),
        onSuccess,
        onError,
      }),
  };
}
