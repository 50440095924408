import React from 'react';

export const getOnboardingError = ({ code }) =>
  ({
    // app-server errors
    APP_REG_PARAMS_WRONG: 'Wrong parameters for app registration',
    // airflow errors (key should match airflow error)
    ERR_HANDSHAKE_AZURE_BLOBS_REQUEST: 'ERR_HANDSHAKE_AZURE_BLOBS_REQUEST',
    ERR_HANDSHAKE_AZURE_EA_API_REQUEST: 'ERR_HANDSHAKE_AZURE_EA_API_REQUEST',
    ERR_HANDSHAKE_AZURE_CSP_API_REQUEST: 'ERR_HANDSHAKE_AZURE_CSP_API_REQUEST',
    ERR_HANDSHAKE_AZURE_STORAGE_NOT_AVAILABLE:
      'The account storage is not available, please' +
      ' validate the account storage name, update it at step #3 (Billing Export), and click Next.',
    ERR_HANDSHAKE_AZURE_CONTAINER_NOT_FOUND:
      'The container was not found, please validate the' +
      ' container name, update it at step #3 (Billing Export), and click Next.',
    ERR_HANDSHAKE_AZURE_CLIENT_AUTH:
      'We are not able to receive data from Azure, ' +
      'please make sure you provided the needed permissions to the application' +
      ' (Monitoring Reader/ Storage Blob Data Reader).',
    ERR_HANDSHAKE_AZURE_EXPORT_REQUEST_ERROR:
      'We are not able to receive data from Azure, please make sure you entered the correct values in Anodot and ' +
      'you followed all the steps on the Azure side.',
    ERR_HANDSHAKE_AZURE_CRED_UNAVAILABLE:
      'Make sure the application ID, directory ID,' +
      ' and client secret are entered correctly in step #2 (App Registration).',

    ERR_HANDSHAKE_AZURE_EA_API_REQUEST_UNKNOWN:
      'Azure EA API request returns an empty response. Please check the onboarding on the Azure side.',
    ERR_HANDSHAKE_AZURE_EA_API_REQUEST_UNAUTHORIZED:
      'Authentication credentials for Azure EA API requests are invalid, or the credentials provided' +
      ' are not authorized to access the resource. ' +
      'Please check the onboarding on the Azure side and the parameters you entered in step #2 (EA Account Details).',
    ERR_HANDSHAKE_AZURE_EA_API_REQUEST_FORBIDDEN:
      'Access to the enrollment information has been denied or refused due to insufficient permissions. ' +
      'Please check the onboarding on the Azure side.',
    ERR_HANDSHAKE_AZURE_EA_API_REQUEST_NOT_FOUND:
      'The enrollment ID was not found on the Azure side, please validate the enrollment ID, update it ' +
      'in step #2 (EA Account Details), and click Next.',
    ERR_HANDSHAKE_AZURE_EA_API_REQUEST_SERVER_ERROR:
      'There was an unexpected error by the server while processing the request on the Azure side.' +
      ' We will retry the request.',
    ERR_HANDSHAKE_AZURE_EA_API_REQUEST_UNAVAILABLE:
      'The server is currently unable to' +
      ' handle the request due to temporary overloading or maintenance of' +
      ' the server on the Azure side.  We will retry the request.',
    ERR_HANDSHAKE_FLAG_NOT_RECEIVED:
      'It seems like you did not click on “Next” in last step, please click update details and then click Next.',
    ERR_HANDSHAKE_AZURE_EXPORT_PERMISSION_ERROR: (
      <>
        The validation failed due to missing permissions. Verify that the following permissions were granted:
        <ul>
          <li>Monitoring reader</li>
          <li>Storage Blob Data Reader</li>
        </ul>
      </>
    ),
  }[code]);
