import React from 'react';
import { Bar, BarChart, CartesianGrid, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { palette } from 'shared/constants/colorsConstants';
import PropTypes from 'prop-types';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import {
  BACKGROUND_GRAY_COLOR,
  FORECAST_COST_COLOR,
  getBudgetColor,
  getNumberAbbreviationFormat,
} from 'usage/containers/BudgetNew/budgetUtil';

import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import moment from 'moment';
import classes from './budgets.module.scss';

const CustomizedLabel = ({ x, y, width, fill, value, rotate }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  return (
    <text
      x={rotate ? x + width - 30 : x + width / 2}
      y={y - 10}
      dy={-4}
      fill={fill}
      textAnchor="middle"
      transform={rotate ? `rotate(45 ${x + width / 2} ${y - 10})` : null}
    >
      {getNumberAbbreviationFormat(value, getCurrencyNumber)}
    </text>
  );
};
CustomizedLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  rotate: PropTypes.bool.isRequired,
};

const CustomizedBar = (props) => {
  const { x, y, width, height, fill, percent, radius } = props;
  return (
    <g>
      {!fill && <Rectangle x={x} y={y} width={width} height={height} fill={BACKGROUND_GRAY_COLOR} />}
      <Rectangle x={x} y={y} height={height} width={width} fill={fill || getBudgetColor(percent)} radius={radius} />;
    </g>
  );
};
CustomizedBar.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  radius: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
};

const CustomTooltip = ({ active, payload }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  if (active && payload[0]) {
    const { date, cost, forecast, budget, percent, accValue } = payload[0].payload;
    return (
      <div className={classes.customTooltip}>
        <p className={classes.date}>
          <GenerateIcon iconName={ICONS.calendarDays.name} />
          <span className={classes.dateValue}>{moment(new Date(date)).format('MMM YYYY')}</span>
        </p>
        <div className={classes.details}>
          {accValue !== undefined && (
            <p className={classes.label}>
              {'Accumilated Monthly Cost: '}
              <span className={classes.value} style={{ color: getBudgetColor(NaN) }}>
                {`${getNumberAbbreviationFormat(
                  forecast && cost ? forecast + cost : accValue,
                  getCurrencyNumber,
                )} (${Math.round(((forecast && cost ? forecast + cost : accValue) * 100) / budget)}%)`}
              </span>
            </p>
          )}
          {forecast !== undefined && (
            <p className={classes.label}>
              {'Forecast Monthly Cost: '}
              <span className={classes.value} style={{ color: getBudgetColor(NaN) }}>
                {`${getNumberAbbreviationFormat(forecast, getCurrencyNumber)} (${Math.round(
                  ((forecast || 0) * 100) / budget,
                )}%)`}
              </span>
            </p>
          )}
          {cost !== undefined && (
            <p className={classes.label}>
              {'Actual Monthly Cost: '}
              <span className={classes.value} style={{ color: getBudgetColor(percent) }}>
                {`${getNumberAbbreviationFormat(cost, getCurrencyNumber)} (${Math.round(
                  ((cost || 0) * 100) / budget,
                )}%)`}
              </span>
            </p>
          )}
          <p className={classes.label}>
            Budget: <span className={classes.value}>{getNumberAbbreviationFormat(+budget, getCurrencyNumber)}</span>
          </p>
        </div>
      </div>
    );
  }

  return null;
};
CustomTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.array.isRequired,
};

const MonthlyCostBarChart = ({ data, customLegend, isPeriod }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  return (
    <div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 30,
          }}
          style={{ position: 'inherit' }}
        >
          <CartesianGrid stroke={palette.gray[200]} vertical={false} />
          <XAxis
            dataKey="date"
            tickFormatter={(value) => createDateDisplayStr(value, 'month')}
            stroke={palette.gray[300]}
            tick={{ fill: palette.gray[450], fontSize: 12 }}
          />
          <YAxis
            tickFormatter={(value) => getNumberAbbreviationFormat(value, getCurrencyNumber)}
            stroke={palette.gray[300]}
            tick={{ fill: palette.gray[450], fontSize: 12 }}
          />
          <Tooltip content={<CustomTooltip />} shared={false} />
          <Bar dataKey="cost" barSize={24} shape={<CustomizedBar />} stackId="a" radius={[4, 4, 4, 4]} />
          <Bar
            dataKey={!isPeriod ? 'forecast' : 'accForecast'}
            barSize={24}
            shape={<CustomizedBar fill={FORECAST_COST_COLOR} />}
            stackId="a"
            radius={[4, 4, 4, 4]}
          />
          <Bar
            dataKey="budgetTotal"
            label={!isPeriod ? <CustomizedLabel rotate={data.length > 20} /> : ''}
            shape={<CustomizedBar fill={BACKGROUND_GRAY_COLOR} />}
            barSize={24}
            stackId="a"
            isAnimationActive={false}
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
      {customLegend}
    </div>
  );
};

export default MonthlyCostBarChart;

MonthlyCostBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  customLegend: PropTypes.element,
  isPeriod: PropTypes.bool,
};

MonthlyCostBarChart.defaultProps = {
  customLegend: null,
  isPeriod: false,
};
