import React, { useState } from 'react';
import {
  ICONS,
  GenerateIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common/dist';
import {
  DROPDOWN_ADDITIONAL_FILTERS,
  DROPDOWN_MAIN_FILTERS,
  FILTERS,
  SWITCH_BUTTON_STATUSES,
  USER_STATUS_FILTERS,
} from 'recommendationsNew/consts';
import PropTypes from 'prop-types';
// eslint-disable-next-line max-len
import { ServicesImageTag } from 'recommendationsNew/components/heatMap/heatMapFilters/servicesFilter/selectMultiMenuItem/ServicesImageTag';
import { mapCloudTypeIdToDescription } from 'users/constants/usersConstants';
import Tooltip from 'shared/components/andtComponents/Tooltip';

import classes from './heatMapSelectedFiltersDisplay.module.scss';
import { CompletedStatus, OpenStatus } from '../../../shared/recommendationStatusSymbol';

const SelectedFilterDisplay = ({ selectedFilter, selectedFilterValue, cloudType, removeSelectedFilter }) => {
  const getSelectedServiceFilterMenuOption = (optionLabel, optionClass) => {
    const cloudTypeString = mapCloudTypeIdToDescription.get(cloudType).toLowerCase();
    return (
      <div
        className={classes.serviceFilterNameContainer}
        automation-id={`heatmap-selected-service-filter-menu-option-${optionLabel}`}
      >
        <div className={classes.serviceFilterImage}>
          <ServicesImageTag
            label={optionLabel}
            defaultIconLabel={`${cloudTypeString.toUpperCase()}Generic`}
            iconDirectoryPath={`../servicesIcons/${cloudTypeString}`}
          />
        </div>
        <div className={optionClass}>{optionLabel}</div>
      </div>
    );
  };

  const getSelectedFilterCounterWithMenu = (filter, selectedFiltersRest) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div
        className={classes.selectedFilterCounterContainer}
        automation-id={`heatmap-selected-filter-counter-with-menu-${filter.label}`}
      >
        <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
          <PopoverTrigger
            as="button"
            type="button"
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
          >
            <div className={classes.selectedFilterCounter}>+{selectedFiltersRest?.length}</div>
          </PopoverTrigger>

          <PopoverContent align="start" sideOffset={7} className={classes.popoverContent}>
            <div className={classes.popupMenuContainer}>
              <div className={classes.popupTitle}>
                <GenerateIcon iconName={filter.icon} />
                <span className={classes.filterName}>{filter.label}</span>
              </div>
              {selectedFiltersRest?.map((option) =>
                filter.id === DROPDOWN_MAIN_FILTERS.SERVICES.id ? (
                  getSelectedServiceFilterMenuOption(option.label, classes.option)
                ) : (
                  <div className={classes.option}>{option.label}</div>
                ),
              )}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    );
  };

  const getBasicSelectedFilterDisplay = (filter) => {
    const filterLabel = selectedFilterValue?.[0].label;
    const selectedFiltersRest = selectedFilterValue.slice(1);

    return (
      <div className={classes.selectedFilter} automation-id={`heatmap-selected-filter-${filter.label}`}>
        {filter.icon ? (
          <Tooltip title={filter.label} placement="bottom">
            <div className={classes.selectedFilterIconContainer}>
              <GenerateIcon iconName={filter.icon} />
            </div>
          </Tooltip>
        ) : null}
        {filterLabel ? <div className={classes.selectedFilterLabel}>{filterLabel}</div> : null}
        {selectedFiltersRest?.length > 0 ? getSelectedFilterCounterWithMenu(filter, selectedFiltersRest) : null}
        <GenerateIcon
          automation-id={`heatmap-selected-filter-${filter}-close-button`}
          iconName={ICONS.xMark.name}
          className={classes.closeButton}
          onClick={() => removeSelectedFilter({ filter: filter.id, isDropDown: true })}
        />
      </div>
    );
  };

  const getServicesSelectedFilterDisplay = (filter) => {
    const selectedFilterFirstThree = selectedFilterValue.slice(0, 3);
    const selectedFiltersRest = selectedFilterValue.slice(3);

    const filterCounter = selectedFilterValue?.length > 3 ? selectedFilterValue?.length - 3 : 0;

    return (
      <div className={classes.selectedFilter} automation-id="heatmap-selected-services-filter">
        {filter.icon ? (
          <Tooltip title={filter.label} placement="bottom">
            <div className={classes.selectedFilterIconContainer}>
              <GenerateIcon iconName={filter.icon} />
            </div>
          </Tooltip>
        ) : null}
        <>
          {selectedFilterFirstThree?.map((option) =>
            getSelectedServiceFilterMenuOption(option.label, classes.serviceFilterLabel),
          )}
        </>
        {filterCounter ? getSelectedFilterCounterWithMenu(filter, selectedFiltersRest) : null}
        <GenerateIcon
          automation-id={`heatmap-selected-filter-${filter.label}-close-button`}
          iconName={ICONS.xMark.name}
          className={classes.closeButton}
          onClick={() => removeSelectedFilter({ filter: filter.id, isDropDown: true })}
        />
      </div>
    );
  };

  const getLabelOnlySelectedComponent = (filterLabel, filter) =>
    filterLabel ? (
      <div className={classes.selectedFilter} automation-id={`heatmap-selected-filter-${filter}`}>
        <div className={classes.selectedFilterLabelOnly}>{filterLabel}</div>
        <GenerateIcon
          automation-id={`heatmap-selected-filter-${filter}-close-button`}
          iconName={ICONS.xMark.name}
          className={classes.closeButton}
          onClick={() => removeSelectedFilter({ filter })}
        />
      </div>
    ) : null;

  const getStatusSelectedFilterComponent = (filter) => {
    if (selectedFilterValue === null) {
      return null;
    }
    const filterLabel = selectedFilterValue === true ? SWITCH_BUTTON_STATUSES.OPEN : SWITCH_BUTTON_STATUSES.COMPLETED;
    return filterLabel ? (
      <div className={classes.selectedFilter} automation-id="heatmap-selected-filter-status">
        <div className={classes.selectedFilterLabelOnly}>
          {selectedFilterValue === true ? <OpenStatus /> : <CompletedStatus />}
        </div>
        <GenerateIcon
          automation-id={`heatmap-selected-filter-${filter}-close-button`}
          iconName={ICONS.xMark.name}
          className={classes.closeButton}
          onClick={() => removeSelectedFilter({ filter })}
        />
      </div>
    ) : null;
  };

  const getStarredSelectedFilterComponent = (filter) => {
    if (selectedFilterValue === null) {
      return null;
    }
    const filterIcon = selectedFilterValue === true ? ICONS.star.name : ICONS.regularStarSlash.name;
    return (
      <div className={classes.selectedFilter} automation-id="heatmap-selected-filter-starred">
        <Tooltip
          title={selectedFilterValue === true ? SWITCH_BUTTON_STATUSES.STARRED : SWITCH_BUTTON_STATUSES.NOT_STARRED}
          placement="bottom"
        >
          <div
            className={`${classes.selectedFilterIconContainer} ${
              selectedFilterValue === true ? `${classes.starred}` : `${classes.notStarred}`
            }`}
          >
            <GenerateIcon iconName={filterIcon} />
          </div>
        </Tooltip>
        <GenerateIcon
          automation-id={`heatmap-selected-filter-${filter}-close-button`}
          iconName={ICONS.xMark.name}
          className={classes.closeButton}
          onClick={() => removeSelectedFilter({ filter })}
        />
      </div>
    );
  };

  const getUserStatusSelectedFilterComponent = (filter) => {
    let excludedIcon = null;
    if (selectedFilterValue?.excluded !== null) {
      excludedIcon = selectedFilterValue?.excluded === true ? ICONS.excluded.name : ICONS.excluded.name;
    }
    let doneIcon = null;
    if (selectedFilterValue?.done !== null) {
      doneIcon = selectedFilterValue?.done === true ? ICONS.clipboardCheck.name : ICONS.clipboardCheck.name;
    }
    return (
      <div className={classes.userStatusContainer}>
        {excludedIcon ? (
          <div className={classes.selectedFilter} automation-id="heatmap-selected-filter-exclude-user-status">
            <Tooltip
              title={
                selectedFilterValue?.excluded === true
                  ? SWITCH_BUTTON_STATUSES.EXCLUDED
                  : SWITCH_BUTTON_STATUSES.NOT_EXCLUDED
              }
              placement="bottom"
            >
              <div
                className={`${classes.selectedFilterIconContainer} ${
                  selectedFilterValue?.excluded === true ? `${classes.excluded}` : `${classes.notExcluded}`
                }`}
              >
                <GenerateIcon iconName={excludedIcon} />
              </div>
            </Tooltip>
            <GenerateIcon
              automation-id={`heatmap-selected-filter-${filter}-excluded-close-button`}
              iconName={ICONS.xMark.name}
              className={classes.closeButton}
              onClick={() => removeSelectedFilter({ filter, userStatusFilter: USER_STATUS_FILTERS.ITEMS.EXCLUDED.id })}
            />
          </div>
        ) : null}
        {doneIcon ? (
          <div className={classes.selectedFilter} automation-id="heatmap-selected-filter-done-user-status">
            <Tooltip
              title={selectedFilterValue?.done === true ? SWITCH_BUTTON_STATUSES.DONE : SWITCH_BUTTON_STATUSES.UN_DONE}
              placement="bottom"
            >
              <div
                className={`${classes.selectedFilterIconContainer} ${
                  selectedFilterValue?.done === true ? `${classes.done}` : `${classes.unDone}`
                }`}
              >
                <GenerateIcon iconName={doneIcon} />
              </div>
            </Tooltip>
            <GenerateIcon
              automation-id={`heatmap-selected-filter-${filter}-done-close-button`}
              iconName={ICONS.xMark.name}
              className={classes.closeButton}
              onClick={() => removeSelectedFilter({ filter, userStatusFilter: USER_STATUS_FILTERS.ITEMS.DONE.id })}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const getSelectedFilterDisplay = () => {
    const allDropDownFilters = [...Object.values(DROPDOWN_ADDITIONAL_FILTERS), ...Object.values(DROPDOWN_MAIN_FILTERS)];
    const selectedDropDownFilter = allDropDownFilters?.find((filter) => filter.id === selectedFilter);
    if (selectedDropDownFilter) {
      if (!selectedFilterValue?.length) {
        return null;
      }
      if (selectedDropDownFilter.id === FILTERS.SERVICES.id) {
        return getServicesSelectedFilterDisplay(selectedDropDownFilter);
      }
      return getBasicSelectedFilterDisplay(selectedDropDownFilter);
    }
    if (selectedFilter === FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id) {
      return selectedFilterValue
        ? getLabelOnlySelectedComponent(`Annual Savings > ${selectedFilterValue.toLocaleString()}`, selectedFilter)
        : null;
    }
    if (selectedFilter === FILTERS.IS_OPEN.id) {
      return getStatusSelectedFilterComponent(selectedFilter);
    }
    if (selectedFilter === FILTERS.IS_STARRED.id) {
      return getStarredSelectedFilterComponent(selectedFilter);
    }
    if (selectedFilter === FILTERS.USER_STATUS.id) {
      return getUserStatusSelectedFilterComponent(selectedFilter);
    }
    return null;
  };

  return <div>{getSelectedFilterDisplay()}</div>;
};

SelectedFilterDisplay.propTypes = {
  cloudType: PropTypes.number.isRequired,
  removeSelectedFilter: PropTypes.func.isRequired,
  selectedFilter: PropTypes.string.isRequired,
  selectedFilterValue: PropTypes.any.isRequired,
};

export default SelectedFilterDisplay;
