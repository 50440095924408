import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Input, InputGroup, Row } from 'reactstrap';
import uuidv1 from 'uuid';
import FieldFilter from 'shared/components/FieldFilter';
import CustomModal from 'shared/components/andtComponents/Modal';
import { cloneDeep } from 'lodash';

const SubViewCreateEditModal = ({ subViewForEdit, saveSubView, onClose, isOpen, customTags, tagsLoading, view }) => {
  const [subView, setSubView] = useState(cloneDeep(subViewForEdit) || { name: '', values: [], uuid: uuidv1(6) });
  const [nameValidError, seNameValidError] = useState('');

  const handleValidateSubViewName = (value) => {
    const filteredSubViews = view?.subViews?.filter(({ uuid }) => uuid !== subView.uuid);
    const subViewWithSameNameOfEdit = filteredSubViews.find(({ name }) => name.toLowerCase() === value.toLowerCase());
    let nameValidError = '';
    if (subViewWithSameNameOfEdit) {
      nameValidError = 'Name already exists';
    }
    if (!value) {
      nameValidError = 'Name must have a value';
    }
    return nameValidError;
  };

  const onSaveClick = (e, subView) => {
    e.preventDefault();
    const error = handleValidateSubViewName(subView.name);
    if (error) {
      seNameValidError(error);
      return;
    }
    saveSubView(subView);
  };

  const getFilteredValues = (customTags) => {
    const formatted = customTags.map((keyVal) => {
      const value = keyVal.split(': ')[1];
      return { value, label: value };
    });
    const currSubView = subView;
    if (!currSubView) {
      return formatted;
    }
    const valuesToRemoveFromOptions = view?.subViews
      ?.filter((subView) => subView.name !== currSubView.name)
      ?.reduce((acc, currSubView) => {
        acc.push(...currSubView.values);
        return acc;
      }, []);
    const filteredValues = formatted.filter((formVal) => !valuesToRemoveFromOptions.includes(formVal.value));
    return filteredValues;
  };

  return (
    <div style={{ width: '50%' }}>
      <CustomModal
        open={isOpen}
        title="Create Sub View"
        onClose={onClose}
        saveDisabled={nameValidError || (subView && (!subView?.values?.length || !subView?.name))}
        onSave={(e) => onSaveClick(e, subView)}
      >
        <Container>
          <Row>
            <p>Sub View name:</p>
            <InputGroup>
              <Input
                type="text"
                onChange={(e) => setSubView({ ...subView, name: e.target.value })}
                value={subView?.name || ''}
                placeholder="Enter sub view name"
              />
            </InputGroup>
            {nameValidError && <p style={{ color: 'red', marginTop: '15px' }}>{nameValidError}</p>}
          </Row>
          <Row>
            <div style={{ width: '100%' }}>
              <h5 style={{ marginTop: '15px', textAlign: 'left', marginBottom: '0.5rem' }}>Values:</h5>
              {tagsLoading ? (
                'Loading...'
              ) : (
                <FieldFilter
                  type="subView"
                  name="subViews"
                  value={subView?.values || []}
                  options={getFilteredValues(customTags)}
                  handleChange={(type, selectedOptions) => setSubView({ ...subView, values: selectedOptions })}
                  placeholder="Select specific values"
                />
              )}
            </div>
          </Row>
        </Container>
      </CustomModal>
    </div>
  );
};

SubViewCreateEditModal.propTypes = {
  subViewForEdit: PropTypes.object,
  view: PropTypes.object.isRequired,
  saveSubView: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  tagsLoading: PropTypes.bool.isRequired,
  customTags: PropTypes.array,
};

SubViewCreateEditModal.defaultProps = {
  isOpen: false,
  subViewForEdit: null,
  customTags: [],
};

export default SubViewCreateEditModal;
