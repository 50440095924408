export const pages = {
  page0: 0,
  page1: 1,
  page2: 2,
  page3: 3,
  page4_getUserInfo: 4,
  page5_lastPage_displayed: 5,
  page6_lastPage: 6,
};

export class AZURE_WIZARD_FLOW_TEXT {
  static INTRO_TITLE = 'Connect your AZURE account';
  static CONFIGURE_AZURE_INTEGRATION = 'Configure Azure Integration';
  static EA_HOW_TO_TITLE = 'Configure Account EA Details';
  static APP_REGISTRATION = 'Configure Account App Registration';
  static ADD_BILLING_EXPORT_PARAMS = 'Add Billing Export Parameters';
}
export const AzureActiveIndexToTitleText = new Map([
  [pages.page0, AZURE_WIZARD_FLOW_TEXT.INTRO_TITLE],
  [pages.page1, AZURE_WIZARD_FLOW_TEXT.CONFIGURE_AZURE_INTEGRATION],
  [
    pages.page2,
    (selectedIntegration) =>
      selectedIntegration === 'ea' ? AZURE_WIZARD_FLOW_TEXT.EA_HOW_TO_TITLE : AZURE_WIZARD_FLOW_TEXT.APP_REGISTRATION,
  ],
  [pages.page3, AZURE_WIZARD_FLOW_TEXT.ADD_BILLING_EXPORT_PARAMS],
]);
