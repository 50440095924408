import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Checkbox.module.scss';

const propTypes = {
  isChecked: PropTypes.bool.isRequired,
  isHalfChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  primary: PropTypes.bool,
  children: PropTypes.object,
  automationId: PropTypes.string,
};
const defaultProps = {
  text: '',
  className: '',
  isHalfChecked: false,
  isDisabled: false,
  primary: false,
  children: null,
  automationId: 'checkbox',
};

let checkboxIndex = 0;

const Checkbox = ({
  isChecked,
  isHalfChecked,
  primary,
  isDisabled,
  children,
  onChange,
  text,
  className,
  automationId,
}) => {
  const [index, setIndex] = useState(null);
  useEffect(() => {
    setIndex(checkboxIndex);
    checkboxIndex += 1;
  }, []);
  const handleChange = (event) => {
    if (isDisabled) {
      return;
    }
    onChange(event.target.checked, event);
  };
  const getLabelText = () => {
    if (!text) {
      return '';
    }
    return <span className={styles.labelText}>{text}</span>;
  };
  return (
    <div
      className={`${styles.root} ${isDisabled ? styles.disabled : ''} ${primary ? styles.primary : ''} ${className}`}
      onClick={(e) => e.stopPropagation()}
    >
      <input
        type="checkbox"
        data-half-checked={isHalfChecked}
        checked={isChecked || false}
        disabled={isDisabled}
        id={`${index}-checkbox`}
        onChange={handleChange}
        automation-id={automationId}
      />
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/label-has-for */}
      <label htmlFor={`${index}-checkbox`}>
        {getLabelText()}
        {children}
      </label>
    </div>
  );
};

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
