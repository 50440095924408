import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import HistoryTableCellClass from './historyTableCellClass';

class HistoryTableCostCell extends HistoryTableCellClass {
  constructor(data) {
    super(data);
    this.parsedValue = this.parseValue(this.value);
    this.parsedCompareValue = this.parseCompareValue(this.value);
  }

  get getParsedValue() {
    return this.parsedValue;
  }

  get getParsedCompareValue() {
    return this.parsedCompareValue;
  }

  checkIfInt = (v) => {
    const val = v;
    const result = parseInt(val, 0);
    return result;
  };
  parseValue = (v) => {
    const { getCurrencyNumber } = this.props;
    const costValue = this.checkIfInt(v);
    return getCurrencyNumber(costValue, 0, { roundNumber: true });
  };
  parseCompareValue = (v) => this.checkIfInt(v);
}

const ObserverHistoryTableCostCell = withUserSettingsConsumer(HistoryTableCostCell);
export default ObserverHistoryTableCostCell;
