/* eslint-disable no-mixed-operators */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style,arrow-parens,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import TableWrapper from 'shared/components/tables/TableWrapper';
import {
  Grid,
  PagingPanel,
  TableColumnResizing,
  TableEditColumn,
  TableEditRow,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Undo';
import { kFormatter } from 'shared/utils/strUtil';
import Spinner from 'shared/components/andtComponents/Spinner';
import EditPencil from 'shared/img/icons/PencilIcon';
import { observer } from 'mobx-react';

const EditButton = ({ onExecute }) => <EditPencil onClick={onExecute} />;
EditButton.propTypes = {
  onExecute: PropTypes.func.isRequired,
};

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes" size="large">
    <SaveIcon />
  </IconButton>
);
CommitButton.propTypes = {
  onExecute: PropTypes.func.isRequired,
};
const CancelButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Cancel changes" size="large">
    <CancelIcon />
  </IconButton>
);
CancelButton.propTypes = {
  onExecute: PropTypes.func.isRequired,
};

const commandComponents = {
  edit: EditButton,
  commit: CommitButton,
  cancel: CancelButton,
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return <CommandButton onExecute={onExecute} />;
};
Command.propTypes = {
  id: PropTypes.number.isRequired,
  onExecute: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/prop-types
const FilterIcon = ({ type, ...restProps }) => <TableFilterRow.Icon type={type} {...restProps} />;

class AccountTagsTable extends Component {
  static propTypes = {
    tableData: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    rowUpdateHandler: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { columns, tableData } = this.props;
    this.state = {
      rows: tableData,
      columns,
      defaultColumnWidths: this.calcDefaultColumnWidths(),
      hiddenColumnNames: [],
      rowChanges: {},
      addedRows: [],
      //   editingRowIds: [],
      tableColumnExtensions: [{ columnName: 'id', width: 60 }],
      editingStateColumnExtensions: [{ columnName: 'linked_account_id', editingEnabled: false }],
      pageSizes: [10, 15, 20, 0],
      filteringStateColumnExtensions: [],
      tableIsUpdated: false,
      numericFilterOperations: [
        'equal',
        'notEqual',
        'greaterThan',
        'greaterThanOrEqual',
        'lessThan',
        'lessThanOrEqual',
      ],
    };
    this.calcDefaultColumnWidths = this.calcDefaultColumnWidths.bind(this);
  }

  setAddedRows = (addedRows) => {
    this.setState({ addedRows });
  };
  setEditingRowIds = (editingRowIds) => {
    this.setState({ editingRowIds });
  };
  calcDefaultColumnWidths = () => {
    const letterSizeInPx = 7;
    const sortIconLengthInPx = 40;
    const defaultColumnWidths = this.props.columns.map((col) => ({
      columnName: col.name,
      width: col.name.length * letterSizeInPx + sortIconLengthInPx,
    }));
    return defaultColumnWidths;
  };
  changeAddedRows = (value) => {
    const initialized = value.map((row) => (Object.keys(row).length ? row : { city: 'Tokio' }));
    this.setAddedRows(initialized);
  };

  currenciesFormatter = (value) => this.props.getCurrencyNumber(kFormatter(value.value || 0));
  percentagesFormatter = (value) => (value.value ? `${value.value}%` : null);

  commitChanges = async ({ added, changed, deleted }) => {
    console.log('here');
    let changedRows = [];
    // if (added) {
    //   const startingAddedId = this.state.rows.length > 0 ? this.state.rows[this.state.rows.length - 1].id + 1 : 0;
    //   changedRows = [
    //     ...this.state.rows,
    //     ...added.map((row, index) => ({
    //       id: startingAddedId + index,
    //       ...row,
    //     })),
    //   ];
    // }
    const { rows } = this.state;
    if (changed) {
      const changedRowId = +Object.keys(changed)[0];
      const changedRow = rows[changedRowId];
      if (changedRow) {
        try {
          this.setState({ tableIsUpdated: true });
          const newRow = { ...changedRow, ...changed[changedRowId] };
          changedRows = rows.map((row, index) => (changedRowId === index ? { ...row, ...changed[index] } : row));
          await this.props.rowUpdateHandler(newRow);
          this.setState({ rows: changedRows, tableIsUpdated: false });
        } catch (error) {
          this.setState({ tableIsUpdated: false });
        }
      }
    }
  };

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  render() {
    const { rows, columns } = this.state;
    const { defaultColumnWidths, pageSizes, tableIsUpdated, editingStateColumnExtensions } = this.state;
    return (
      <div className="card">
        {tableIsUpdated && <Spinner />}
        <Paper>
          <Grid rows={rows} columns={columns}>
            <EditingState onCommitChanges={this.commitChanges} columnExtensions={editingStateColumnExtensions} />
            <FilteringState defaultFilters={[]} />
            <SortingState />
            <PagingState defaultCurrentPage={0} />
            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedPaging />
            <TableWrapper columnExtensions={this.tableColumnExtensions} />
            <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
            <TableHeaderRow showSortingControls />
            <PagingPanel pageSizes={pageSizes} />
            <TableFilterRow showFilterSelector iconComponent={FilterIcon} />
            <Toolbar />
            <TableEditColumn showEditCommand commandComponent={Command} />
            <TableEditRow />
          </Grid>
        </Paper>
      </div>
    );
  }
}

const ObserverAccountTagsTable = observer(AccountTagsTable);
export default ObserverAccountTagsTable;
