/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import VideoPlayer from './VideoPlayer';

const text = 'Watch this tutorial to set up your Cost & Usage report:';
const src = 'https://www.youtube.com/embed/VTa1kL0nddM?modestbranding=1&rel=0';

export default class WizardPlayerForm extends Component {
  constructor() {
    super();
    this.state = {
      index: 1,
    };
  }

  render() {
    return (
      <Row>
        <Col xs="auto" md="auto" lg="auto" xl="auto">
          <h4 className="inner-wizard-items-header">{`${text} `}</h4>
          <br />
          <VideoPlayer src={src} title="YouTubePlayerCurTutorial" />
        </Col>
      </Row>
    );
  }
}
