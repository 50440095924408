/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars,arrow-parens */
import { API } from 'shared/utils/apiMiddleware';
import config from '../../config';

export default class DivisionsApi {
  // Customers

  getAzureCustomersHistoryData = async (startDate, endDate, periodGranLevel) => {
    const getUrl = `/api/v1/divisions/azure-billing-history?startDate=${startDate}&endDate=${endDate}&periodGranLevel=${periodGranLevel}`;
    return API.get('billings', getUrl);
  };
  getCustomersData = async (startDate, endDate, periodGranLevel) => {
    const getUrl = `/api/v1/divisions/customers?startDate=${startDate}&endDate=${endDate}&periodGranLevel=${periodGranLevel}`;
    return API.get('billings', getUrl);
  };

  getResellerCustomerInvoiceData = async (rows, type) => {
    const getUrl = `${config.apiGateway.URL}/api/v1/divisions/customers/aws/customer-invoice/${type}`;
    const response = await fetch(getUrl, {
      method: 'POST',
      body: JSON.stringify({ rows }),
      headers: {
        ...config.apiReqHeaders.createHeaders(),
        'Content-Type': 'application/json',
      },
    });
    return response.text();
  };
  // Division Group

  getDivisionGroups = async () => {
    const getUrl = `/api/v1/divisions/i/`;
    return API.get('billings', getUrl);
  };

  updateDivisionGroupName = async (divId, body) => {
    const updateUrl = `/api/v1/divisions/name?divId=${divId}`;
    return API.put('billings', updateUrl, { body });
  };
  updateDivisionGroup = async (divId, body) => {
    const updateUrl = `/api/v1/divisions?divId=${divId}`;
    return API.put('billings', updateUrl, { body });
  };

  updateDivisionDisounctAndType = async (divId, accountKey, body) => {
    const updateUrl = `/api/v1/divisions/customer?divId=${divId}&accountKey=${accountKey}`;
    return API.put('billings', updateUrl, { body });
  };
  createCustomersMapping = async () => {
    const getUrl = `/api/v1/divisions/create-customers-mapping`;
    return API.get('billings', getUrl);
  };

  updateDivisionGroupLinkAccounts = async (divId, linkedAccIds, divisionName, divisionCode, divisionTypeId, roleId) => {
    const body = { divId, linkedAccIds, divisionName, divisionCode, divisionTypeId, roleId };
    const updateUrl = '/api/v1/divisions/link-acc-new';
    return API.put('billings', updateUrl, { body });
  };

  deleteDivisionGroup = async (divisionId, divisionName) => {
    const deleteUrl = `/api/v1/divisions?divId=${divisionId}&divisionName=${divisionName}`;
    return API.del('billings', deleteUrl);
  };
  createDivisionGroup = async (body) => {
    const creareUrl = `/api/v1/divisions`;
    return API.post('billings', creareUrl, { body });
  };

  // AZURE Reseller *****************************
  getAzureCustomersTableData = async (startDate, endDate) => {
    // awsResellerRatesData
    const getUrl = `/api/v1/divisions/customers/azure?startDate=${startDate}&endDate=${endDate}`;
    return API.get('billings', getUrl);
  };

  // AWS Reseller *****************************

  getAwsCustomersTableData = async (startDate, endDate) => {
    // awsResellerRatesData
    const getUrl = `/api/v1/divisions/customers/aws?startDate=${startDate}&endDate=${endDate}`;
    return API.get('billings', getUrl);
  };

  getAwsCustomersTable = async (startDate, endDate) => {
    const getUrl = `/api/v1/divisions/customers/aws/costs?startDate=${startDate}&endDate=${endDate}`;
    return API.get('billings', getUrl);
  };
  fetchExplainCustomerFlexibilityMarginData = async (
    divisionId,
    divisionName,
    accountKey,
    flexibilityType,
    startDate,
    endDate,
  ) => {
    const url = `/api/v1/divisions/customers/aws/flexibility/${flexibilityType}?divisionId=${divisionId}&divisionName=${divisionName}&accountKey=${accountKey}&startDate=${startDate}&endDate=${endDate}`;
    return API.get('billings', url);
  };

  // CREDITS
  deleteCreditsData = async (id, divisionName) => {
    const updateUrl = `/api/v1/divisions/customers/aws/credit/${id}`;
    return API.del('billings', updateUrl);
  };
  addCreditsData = async (creditParams = {}) => {
    const body = { ...creditParams };
    const addUrl = `/api/v1/divisions/customers/aws/credit`;
    return API.post('billings', addUrl, { body });
  };
  getAwsCreditsData = async (startDate, endDate) => {
    const getUrl = `/api/v1/divisions/customers/aws/credit?startDate=${startDate}&endDate=${endDate}`;
    return API.get('billings', getUrl);
  };
  updateCreditsData = async (currEditedId, newCReditarams) => {
    const body = { id: currEditedId, ...newCReditarams };
    const addUrl = `/api/v1/divisions/customers/aws/credit`;
    return API.put('billings', addUrl, { body });
  };
  fetchExplainCreditHistoryData = async (creditId, startDate, endDate) => {
    const getUrl = `/api/v1/divisions/customers/aws/credit/history?id=${creditId}&startDate=${startDate}&endDate=${endDate}`;
    return API.get('billings', getUrl);
  };

  getBillingProfile = (payerAccount) =>
    API.get('billings', `/api/v1/divisions/customers/billing-profile?accountId=${payerAccount}`);

  updateBillingProfile = (data, payerAccount, isOnboarding) =>
    API.put('billings', `/api/v1/divisions/customers/billing-profile?accountId=${payerAccount}&isOnboarding=${isOnboarding}`, { body: data });
}
