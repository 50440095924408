/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/system/colorManipulator';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  DataTypeProvider,
  SortingState,
  RowDetailState,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  ColumnChooser,
  TableColumnVisibility,
  TableFilterRow,
  TableColumnResizing,
  TableRowDetail,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { RDS_TABLE_CSV_RI_DATA_COL, RdsIsActiveValues } from 'usage/constants/usageConstants';
import { Col } from 'reactstrap';
import { CSVLink } from 'react-csv';
import { percentStr, kFormatter } from 'shared/utils/strUtil';
import CancelIcon from 'shared/img/icons/RoundCancelIcon';
import CheckIcon from 'shared/img/icons/RoundCheckIcon';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

const styles = () => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    fontSize: '25.5vw',
  },
});

class DevExpSearchAndVisibilityTable extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    columnWidths: PropTypes.object.isRequired,
    tableColumnExtensions: PropTypes.object.isRequired,
    defaultHiddenColumnNames: PropTypes.object.isRequired,
    defaultSorting: PropTypes.object.isRequired,
    currenciesColumns: PropTypes.object.isRequired,
    csvColumns: PropTypes.object,
    csvTitle: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };
  static defaultProps = {
    csvColumns: null,
  };
  constructor(props) {
    super(props);
    const { columns, columnWidths, tableColumnExtensions, defaultHiddenColumnNames, data, currenciesColumns } =
      this.props;
    this.state = {
      columns,
      columnWidths,
      tableColumnExtensions,
      hiddenColumnNames: defaultHiddenColumnNames,
      rows: data,
      currenciesColumns,
      pageSizes: [10, 15, 20, 0],
      integratedSortingColumnExtensions: [{ columnName: 'riCoveragePercent', compare: this.compareriCoveragePercent }],
    };
  }
  getIcon = ({ row }) => {
    if (row.isActive) {
      return <CheckIcon style={{ margin: 'auto', height: '25px', width: '25px' }} />;
    }
    return <CancelIcon style={{ margin: 'auto', height: '25px', width: '25px' }} />;
  };

  csvHeaders = () => {
    const { csvColumns } = this.props;
    const csvColumnsLocal = csvColumns.map((col) => ({
      label: col.title,
      key: col.name,
    }));
    return csvColumnsLocal;
  };

  getCsvModifiedData = (data) => {
    if (!data) return [];
    return data.map((row) => {
      if (row.riData) {
        const riDataValuesAsString = row.riData.reduce((acc, riDatum, idx) => {
          Object.keys(riDatum).forEach((key, index) => {
            if (index > 0) acc += ', ';
            if (key.toLowerCase() === 'quantity') acc += `${riDatum[key]} Hours`;
            else if (key.toLowerCase() === 'percent') acc += `${riDatum[key] * 100}%`;
            else acc += riDatum[key];
          });
          if (idx < row.riData.length - 1) {
            acc += ' | ';
          }
          return acc;
        }, '');
        row[RDS_TABLE_CSV_RI_DATA_COL] = riDataValuesAsString;
        delete row.riData;
      }
      return row;
    });
  };

  compareriCoveragePercent = (a, b) => {
    const c = +a.replace(' %', '') - +b.replace(' %', '');
    return c;
  };

  tableColumns = [
    { name: 'title', title: 'RI (ARN) / On-Demand' },
    { name: 'quantity', title: 'Quantity', getCellValue: (row) => `${Number(row.quantity).toFixed(0)} Hours` },
    { name: 'percent', title: 'Percent', getCellValue: (row) => percentStr(row.percent * 100) },
  ];

  columnWidths = [
    { columnName: 'riPercent', width: 450 },
    { columnName: 'onDemandPercent', width: 450 },
  ];
  RowDetail = ({ row }) => (
    <div className="card">
      <Grid rows={row.riData} columns={this.tableColumns}>
        <TableWrapper columnExtensions={this.columnWidths} />
        <TableHeaderRow />
      </Grid>
    </div>
  );

  currenciesFormatter = (value) => this.props.getCurrencyNumber(kFormatter(value.value));

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  render() {
    const {
      rows,
      columns,
      columnWidths,
      tableColumnExtensions,
      hiddenColumnNames,
      currenciesColumns,
      integratedSortingColumnExtensions,
      pageSizes,
    } = this.state;
    const { csvTitle, defaultSorting } = this.props;
    const rowsCopy = JSON.parse(JSON.stringify(rows));
    const rowsForCsv = this.getCsvModifiedData(rowsCopy);
    rowsForCsv.map((row) => {
      if (row.isActive === RdsIsActiveValues.NOT_ACTIVE) {
        row.isActive = 'No';
        return row;
      }
      row.isActive = 'Yes';
      return row;
    });

    return (
      <>
        <Col xs={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CSVLink data={rowsForCsv} headers={this.csvHeaders()} filename={`${csvTitle}.csv`}>
            Export as CSV
            <span className="lnr lnr-download" style={{ fontSize: '18px', marginLeft: '8px', marginBottom: '8px' }} />
          </CSVLink>
        </Col>
        <Col xs={12} md={12} lg={12} xl={12}>
          <Paper>
            <Grid rows={rows} columns={columns}>
              <RowDetailState />
              <FilteringState defaultFilters={[]} />
              <IntegratedFiltering />
              <SortingState defaultSorting={defaultSorting} />
              <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
              <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[2]} />
              <IntegratedPaging />
              <DataTypeProvider for={currenciesColumns} formatterComponent={this.currenciesFormatter} />
              <DataTypeProvider for={['isActive']} formatterComponent={this.getIcon} />
              <TableWrapper columnExtensions={tableColumnExtensions} styles={styles} />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableRowDetail contentComponent={this.RowDetail} />
              <TableFilterRow />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
              />
              <PagingPanel pageSizes={pageSizes} />
              <Toolbar />
              <ColumnChooser />
            </Grid>
          </Paper>
        </Col>
      </>
    );
  }
}

const ObserverDevExpSearchAndVisibilityTable = withUserSettingsConsumer(DevExpSearchAndVisibilityTable);
export default ObserverDevExpSearchAndVisibilityTable;
