/* eslint-disable no-plusplus */
/* eslint-disable no-empty */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { action, makeObservable, observable, runInAction } from 'mobx';
import DateFilter from 'shared/modules/dateFilter';
import { buildTimeDiffDateFromBaseDate } from 'shared/utils/dateUtil';
import { CostTrackingConstants } from 'usage/constants/costAndUsageConstants';
import { CLOUD_TYPE_IDS, DIVISION_TYPE_ID } from 'users/constants/usersConstants';

export default class AzureCustomersModel {
  constructor(apiGateway) {
    this.apiGateway = apiGateway;
    this.customersData = [];
    this.customersDataByDivisionType = [];
    this.customersTableData = [];
    this.customerHistoryTableData = [];
    this.resellerRatesData = [];
    this.isLoading = false;
    this.savedLogoUrl = null;
    makeObservable(this, {
      fetchData: action,
      getCustomersTableData: action,
      updateCustomerDivisionTypeLocally: action,
      customersData: observable,
      customersDataByDivisionType: observable,
      customersTableData: observable,
      customerHistoryTableData: observable,
      resellerRatesData: observable,
      isLoading: observable,
    });
  }

  invalidateData = () => {
    this.customersData = [];
    this.customersTableData = [];
    this.customerHistoryTableData = [];
    this.resellerRatesData = [];
    this.customersDataByDivisionType = [];
    this.isLoading = false;
    this.savedLogoUrl = null;
  };

  updateLocalModel = (divisionId, discount, customerType, accountKey) => {
    this.customersData.forEach((item, index) => {
      if (item.divisionId === parseInt(divisionId, 0) && item.accountKey === parseInt(accountKey, 0)) {
        this.customersData[index].customerType = customerType;
        this.customersData[index].divisionMarginPercent = discount;
      }
    });
    const updated = [...this.customersData];
    return updated;
  };

  updateDivisionDisounctAndType = async (divisionId, discount, customerType, accountKey) => {
    const body = { discount, customerType };
    try {
      await this.apiGateway.updateDivisionDisounctAndType(divisionId, accountKey, body);
      runInAction(() => {
        this.customersData = [...this.updateLocalModel(divisionId, discount, customerType, accountKey)];
        this.customersDataByDivisionType = [...this.prepareCustomersDataByDivisionType(this.customersData)];
        this.customersTableData = [...this.prepareCustomerTableData(this.customersData)];
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
      });
    }
  };
  updateCustomerDivisionTypeLocally = (accountKey, accountId, divisionId, newDivisionTypeId) => {
    const customerIndex = this.customersData.findIndex(
      (customer) =>
        customer.accountKey === accountKey && customer.accountId === accountId && customer.divisionId === divisionId,
    );
    if (customerIndex > -1) {
      this.customersData[customerIndex].divisionTypeId = newDivisionTypeId;
    }
    this.customersData.forEach((item, index) => {
      if (
        item.divisionId === parseInt(divisionId, 0) &&
        item.accountKey === parseInt(accountKey, 0) &&
        item.accountId === accountId
      ) {
        this.customersData[index].divisionTypeId = newDivisionTypeId;
      }
    });
    const updated = [...this.customersData];
    return updated;
  };

  createCustomersMapping = async () => {
    try {
      // this.isInitialLoading = true;
      await this.apiGateway.createCustomersMapping();
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
      });
    }
  };

  prepareCustomersDataByDivisionType = (data) => {
    const copiedData = JSON.parse(JSON.stringify(data));
    copiedData.map((item) => ({
      ...item,
      groupBy: item.customerType || 'Unassigned',
    }));

    return copiedData;
  };
  prepareCustomerTableData = (customersData) => {
    const preparedCustomersData = [];
    const currMonth = DateFilter.getCurrMonthDateFormat();
    const prevMonth = DateFilter.getPrevMonthDateFormat();
    const filteredCustomerData = customersData.filter(
      (customerData) =>
        customerData.divisionTypeId in
        {
          [DIVISION_TYPE_ID.CUSTOMER]: '',
          [DIVISION_TYPE_ID.CUSTOMER_WITH_USERS]: '',
        },
    );
    filteredCustomerData.forEach((customerData) => {
      const idx = preparedCustomersData.map((currCustomer) => currCustomer.groupBy).indexOf(customerData.groupBy);
      if (idx > -1) {
        if (customerData.usageDate === prevMonth) {
          preparedCustomersData[idx].lastMonthCharges = preparedCustomersData[idx].lastMonthCharges
            ? preparedCustomersData[idx].lastMonthCharges + customerData.totalCost
            : customerData.totalCost;
        } else if (customerData.usageDate === currMonth) {
          preparedCustomersData[idx].monthToDateCharges = preparedCustomersData[idx].monthToDateCharges
            ? preparedCustomersData[idx].monthToDateCharges + customerData.totalCost
            : customerData.totalCost;
        }
      } else if (customerData.usageDate === currMonth || customerData.usageDate === prevMonth) {
        const currData = {};
        if (customerData.usageDate === currMonth) {
          currData.monthToDateCharges = customerData.totalCost;
        } else if (customerData.usageDate === prevMonth) {
          currData.lastMonthCharges = customerData.totalCost;
        }
        currData.accountName = customerData.accountName;
        currData.id = customerData.divisionId;
        currData.divisionName = customerData.divisionName;
        currData.customerType = customerData.customerType;
        currData.divisionTypeId = customerData.divisionTypeId;
        currData.divisionMarginPercent = customerData.divisionMarginPercent;
        currData.accountKey = customerData.accountKey;
        currData.accountId = customerData.accountId;
        currData.groupBy = customerData.groupBy;
        preparedCustomersData.push(currData);
      }
    });
    return preparedCustomersData;
  };

  fetchData = async (startDate, endDate, periodGranLevel) => {
    try {
      this.isLoading = true;
      const defaultStartDate = startDate || buildTimeDiffDateFromBaseDate(new Date(), -180, 'd').startDate;
      const defaultEndDate = endDate || buildTimeDiffDateFromBaseDate(new Date(), -1, 'd').endDate;
      const defaultPeriodGranLevel = periodGranLevel || CostTrackingConstants.GRAN_LEVEL_MONTHLY;
      const customersData = await this.apiGateway.getCustomersData(
        defaultStartDate,
        defaultEndDate,
        defaultPeriodGranLevel,
      );

      runInAction(() => {
        this.customersData = customersData;
        this.customersTableData = this.prepareCustomerTableData(customersData);
        this.customersDataByDivisionType = this.prepareCustomersDataByDivisionType(customersData);
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        this.state = 'error';
      });
    }
    return this.customersData;
  };
  getCustomersTableData = async () => {
    try {
      const startDate = DateFilter.getFirstDayOfCurrMonthDate();
      const endDate = DateFilter.getDateStr();
      const defaultPeriodGranLevel = CostTrackingConstants.GRAN_LEVEL_MONTHLY;
      const customersData = await this.apiGateway.getCustomersData(startDate, endDate, defaultPeriodGranLevel);
      runInAction(() => {
        this.customersTableData = customersData;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        this.state = 'error';
      });
    }
  };
  getCustomersHistoryData = async (startDate, endDate) => {
    try {
      const customersData = await this.apiGateway.getAzureCustomersHistoryData(
        startDate,
        endDate,
        CostTrackingConstants.GRAN_LEVEL_MONTHLY,
      );
      runInAction(() => {
        this.customerHistoryTableData = customersData;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
      });
    }
  };
  resellerIndexPPByEntryId = (id) => this.resellerRatesData.findIndex((resellerData) => resellerData.id === id);
  resellerObjectById = (id) => {
    const indx = this.resellerIndexPPByEntryId(id);
    if (indx > -1) {
      return this.resellerRatesData[indx];
    }
    return {};
  };
  deleteOrReplacePrivPricingLocally = (id, ruleName, newPP) => {
    const indx = this.resellerIndexPPByEntryId(id);
    if (indx > -1) {
      if (newPP) {
        if (Array.isArray(newPP)) {
          const newPpLength = newPP.length;
          for (let index = 0; index < newPpLength; index++) {
            this.resellerRatesData.splice(indx + index, !index ? 1 : 0, newPP[index]);
          }
        } else {
          this.resellerRatesData.splice(indx, 1, newPP);
        }
      } else {
        this.resellerRatesData.splice(indx, 1);
      }
    }
  };
}
