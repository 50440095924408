/* eslint-disable no-unused-vars */
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export default class IdleInstancesModel {
  constructor(apiGateway) {
    this.apiGateway = apiGateway;
    this.data = [];
    this.isLoadingCriterias = false;
    this.idleInstancesCriterias = [];
    // this.isLoadingIdleInstancesCriteria = false;
    this.isLoadingIdleInstancesTableData = false;
    this.isLoadingIdleInstancesRowData = false;
    this.idleInstancesTableData = null;
    this.idleInstancesRowData = null;
    makeObservable(this, {
      fetchData: action,
      createIdleCriteriaRule: action,
      deleteIdleCriteria: action,
      // handleUpdateIdleInstancesCriteria: action,
      getIdleInstancesTableData: action,
      data: observable,
      idleInstancesCriterias: observable,
      isLoadingCriterias: observable,
      // isLoadingIdleInstancesCriteria: observable,
      isLoadingIdleInstancesTableData: observable,
      idleInstancesTableData: observable,
      isLoadingIdleInstancesRowData: observable,
      idleInstancesRowData: observable,
      getIdleInstancesCriteriaAvailableLinkedAccounts: computed,
    });
  }

  fetchData = async () => {
    this.isLoadingCriterias = true;
    try {
      const idleInstancesCriterias = await this.apiGateway.getIdleInstancesCriterias();
      runInAction(() => {
        // eslint-disable-next-line prefer-destructuring
        this.idleInstancesCriterias = [...idleInstancesCriterias];
        this.isLoadingCriterias = false;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
        this.isLoadingCriterias = false;
      });
    }
  };

  createIdleCriteriaRule = async (bodyParams) => {
    const body = bodyParams;
    try {
      this.isLoadingCriterias = true;
      const res = await this.apiGateway.createIdleInstancesCriteria(body);
      runInAction(() => {
        this.isLoadingCriterias = false;
        if (res.is_valid) {
          body.criteriaId = res.criteria_id;
          this.idleInstancesCriterias = [...this.idleInstancesCriterias, body];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isLoadingCriterias = false;
      });
    }
  };
  deleteIdleCriteria = async (id) => {
    try {
      this.isLoadingCriterias = true;
      await this.apiGateway.deleteIdleCriteria(id);
      const criteriaIndex = this.idleInstancesCriterias.findIndex((crt) => crt.criteriaId === id);
      const tempList = [...this.idleInstancesCriterias];
      runInAction(() => {
        this.isLoadingCriterias = false;
        if (criteriaIndex > -1) {
          tempList.splice(criteriaIndex, 1);
          this.idleInstancesCriterias = [...tempList];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isLoadingCriterias = false;
      });
    }
  };
  updateIdleCriteriaRule = async (bodyParams) => {
    this.isLoadingCriterias = true;
    const body = bodyParams;
    try {
      this.isLoadingCriterias = true;
      const res = await this.apiGateway.updateIdleCriteriaRule(body);
      const criteriaIndex = this.idleInstancesCriterias.findIndex((crt) => crt.criteriaId === bodyParams.criteriaId);
      const tempList = [...this.idleInstancesCriterias];
      runInAction(() => {
        this.isLoadingCriterias = false;
        if (res.is_valid && criteriaIndex > -1) {
          tempList.splice(criteriaIndex, 1, bodyParams);
          this.idleInstancesCriterias = [...tempList];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isLoadingCriterias = false;
      });
    }
  };

  getIdleInstancesTableData = async (bodyParams) => {
    this.isLoadingIdleInstancesTableData = true;
    try {
      const res = await this.apiGateway.getIdleInstancesTableData(bodyParams);
      runInAction(() => {
        this.isLoadingIdleInstancesTableData = false;
        if (res) {
          this.idleInstancesTableData = res;
        }
      });
    } catch (error) {
      this.isLoadingIdleInstancesTableData = false;
    }
  };

  getIdleInstanceRowData = async (criteriaId, instanceId, startDate, endDate) => {
    this.isLoadingIdleInstancesRowData = true;
    try {
      const res = await this.apiGateway.getIdleInstanceRowData(criteriaId, instanceId, startDate, endDate);
      // eslint-disable-next-line consistent-return
      runInAction(() => {
        this.isLoadingIdleInstancesRowData = false;
        if (res) {
          this.idleInstancesRowData = res;
        }
      });
      return res;
    } catch (error) {
      this.isLoadingIdleInstancesRowData = false;
      return false;
    }
  };

  invalidateData = () => {
    this.data = [];
    this.isLoadingCriterias = false;
    this.idleInstancesCriterias = [];
    this.isLoadingIdleInstancesTableData = false;
    this.isLoadingIdleInstancesRowData = false;
    this.idleInstancesTableData = null;
    this.idleInstancesRowData = null;
  };

  get getIdleInstancesCriteriaAvailableLinkedAccounts() {
    if (!this.idleInstancesCriterias || this.idleInstancesCriterias.length === 0) {
      return [];
    }
    const linkedAccountsSet = new Set();
    this.idleInstancesCriterias.forEach((criteria) => {
      if (Array.isArray(criteria.linkedAccounts)) {
        criteria.linkedAccounts.forEach((la) => linkedAccountsSet.add(la));
      }
    });
    return [...linkedAccountsSet];
  }

  get getIdleInstancesAvailableCriteriaNames() {
    if (!this.idleInstancesCriterias || this.idleInstancesCriterias.length === 0) {
      return [];
    }
    const crtNamesSet = this.idleInstancesCriterias.map((criteria) => ({
      label: criteria.criteriaName,
      value: criteria.criteriaId,
    }));
    // this.idleInstancesCriterias.forEach((criteria) => {
    //   criteria.linkedAccounts.forEach(la => crtNamesSet.add(la));
    // });
    return [...crtNamesSet];
  }
}
