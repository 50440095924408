/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { S3CostRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class S3CostRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new S3CostRecommendation();
  }

  build() {
    super.build();
    const { zone_tag: region, bucket_name: bucketName, total_size: totalSize } = this._rawRecommendation;
    this._recommendation.region = region;
    this._recommendation.bucketName = bucketName;
    this._recommendation.totalSize = totalSize;
  }
}
