import PropTypes from 'prop-types';
import React from 'react';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';

import classes from './threeStateSwitchButton.module.scss';

export const ThreeStateSwitchButton = ({ infoIconTooltipText, isDisabled, switchLabel, optionsList, changeState }) => {
  const onOptionClick = (event) => {
    changeState(event);
  };

  return (
    optionsList?.length === 3 && (
      <div className={classes.container}>
        <div className={classes.labelContainer}>
          {switchLabel}
          <InfoPopover isSimple>{infoIconTooltipText}</InfoPopover>
        </div>
        <div className={`${classes.switchOptionsContainer} ${isDisabled ? classes.disabled : ''}`}>
          {optionsList.map((option) => (
            <div
              className={`${classes.option} ${option.isSelected ? classes.selected : classes.notSelected}`}
              onClick={() => onOptionClick({ option: option.label })}
            >
              {option.iconName ? <GenerateIcon iconName={`${option.iconName}`} /> : option.label}
            </div>
          ))}
        </div>
      </div>
    )
  );
};

ThreeStateSwitchButton.propTypes = {
  infoIconTooltipText: PropTypes.string,
  isDisabled: PropTypes.bool,
  switchLabel: PropTypes.string,
  optionsList: PropTypes.array.isRequired,
  changeState: PropTypes.func.isRequired,
};

ThreeStateSwitchButton.defaultProps = {
  isDisabled: false,
  switchLabel: '',
  infoIconTooltipText: '',
};

export default ThreeStateSwitchButton;
