/* eslint-disable no-unused-vars */
export const copyStrToClipboard = (copyText) => {
  const el = document.createElement('textarea');
  el.value = copyText;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el);
  // Check if there is any content selected previously
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false;
  el.select(); // Select the <textarea> content
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
};

export const downloadFile = (filename, text) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  element.target = '_blank';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const downloadFileSafari = (wasClicked, filename, id, text) => {
  const link = document.getElementById(`${id}`);
  link.href = `data:application/octet-stream,${encodeURIComponent(text)}`;
  link.download = `${filename}`;
  link.target = '_blank';
  if (!wasClicked) {
    link.click();
  }
};
