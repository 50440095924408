import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatTime } from 'shared/utils/dateUtil';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { getOnboardingError } from 'app/containers/Onboarding/AwsOnboarding/utils/errors';
import { getOnboardingError as getAzureOnboardingError } from 'app/containers/Onboarding/AzureOnboarding/utils/errors';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { useFormContext } from 'react-hook-form';
import styles from './WarningsList.module.scss';
import { AWS_ONBOARDING_FIELDS } from '../../AwsOnboarding/AwsOnboardingConstants';

const WarningsList = ({ warnings }) => {
  const { getValues } = useFormContext();
  const [open, setOpen] = useState(false);
  if (!open) {
    return (
      <div className={styles.closedContainer}>
        <div className={styles.title}>
          Errors
          <span> ({warnings.length})</span>
        </div>
        <div onClick={() => setOpen(true)} className={styles.openButton}>
          <GenerateIcon iconName={ICONS.chevronDown.name} />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Errors ({warnings.length})</div>
        <div onClick={() => setOpen(false)} className={styles.openButton}>
          <GenerateIcon iconName={ICONS.xMark.name} />
        </div>
      </div>
      <div className={styles.list}>
        {warnings.map((w) => (
          <div key={w.code} className={styles.warning}>
            <div className={styles.warningTitle}>
              <span>
                <GenerateIcon iconName={ICONS.triangleExclamation.name} />
                Action Required
              </span>
              <span className={styles.date}>{formatTime(w.created)}</span>
            </div>
            <div className={styles.warningDesc}>
              {getValues(AWS_ONBOARDING_FIELDS.CLOUD_TYPE_ID) === CLOUD_TYPE_IDS.AZURE ?
                getAzureOnboardingError({ code: w.code }) : getOnboardingError({ code: w.code })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

WarningsList.propTypes = {
  warnings: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default WarningsList;
