import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { querystring } from 'shared/utils/apiUtil';
import { Routes } from 'shared/constants/routes';
import { validateIntegrationType } from 'shared/utils/integrationsUtils';
import { mapUserTypeToRoute } from 'shared/constants/routesMapping';
import { UsersType } from 'users/constants/usersConstants';

const UnauthenticadedRoute = ({ component: C, props: cProps, location, ...rest }) => {
  // eslint-disable-next-line no-use-before-define
  useEffect(() => {
    const integrationType = new URLSearchParams(location.search).get('type');
    if (validateIntegrationType(integrationType)) {
      sessionStorage.setItem('integration', location.search);
    }
  }, []);
  const { usersStore } = cProps;
  // Setting the next routh according to user type and redirection
  const currPath = querystring('redirect') || String(window.location.pathname);
  const mainUser = usersStore.getMainUser();
  let nextRoutePath = mapUserTypeToRoute.get(mainUser?.userType);
  if (
    currPath !== '/' &&
    currPath !== `${Routes.LOG_IN}` &&
    ![UsersType.NEW_USER, UsersType.USER_ON_BOARDED].includes(mainUser?.userType)
  ) {
    const isAllowed = !usersStore.isPathNotAllowedToUser(currPath, mainUser?.userType);
    if (isAllowed) {
      nextRoutePath = currPath;
      if (window.location.search && !querystring('redirect')) {
        nextRoutePath += window.location.search;
      }
    }
  }
  return (
    <Route
      {...rest}
      // eslint-disable-next-line
      render={(props) =>
        !cProps.isSessionStorageAuth() ? <C {...props} {...cProps} /> : <Redirect to={nextRoutePath} />
      }
    />
  );
};

UnauthenticadedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  props: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default UnauthenticadedRoute;
