/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import Delete from 'mdi-react/TrashCanOutlineIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import SendIcon from 'mdi-react/SendIcon';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import toast from 'shared/components/andtComponents/Toast';
import { SuccessNotificationsLabels } from 'shared/constants/notificationsConstants';

const linkStyle = {
  display: 'inline-block',
  position: 'relative',
  zIndex: 1,
  padding: '1em',
  margin: '-1em',
};
const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const UserActions = (props) => {
  const { row, currentUserReadOnly, updateReportHandler, sendReportHandler, deleteHandler } = props;
  return (
    <i>
      <Row>
        <Col>
          <ReadOnlyDisplayWrapper isHide={false} userReadOnly={currentUserReadOnly}>
            <LinkIconButton containerStyle={buttonContainerStyle}>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  deleteHandler(row.uuid);
                }}
              />
              <Delete />
            </LinkIconButton>
          </ReadOnlyDisplayWrapper>
        </Col>
        <Col>
          <LinkIconButton containerStyle={buttonContainerStyle}>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                updateReportHandler(row.uuid);
              }}
            />

            <PencilIcon />
          </LinkIconButton>
        </Col>
        <Col>
          <LinkIconButton containerStyle={buttonContainerStyle}>
            <button
              style={{
                ...linkStyle,
                color: '#70bbfd',
              }}
              type="button"
              className="btn-no-style"
              onClick={() => {
                sendReportHandler(row.uuid);
                toast.success(SuccessNotificationsLabels.REPORT_SENT);
              }}
            />
            <SendIcon />
          </LinkIconButton>
        </Col>
      </Row>
    </i>
  );
};

UserActions.propTypes = {
  row: PropTypes.object.isRequired,
  currentUserReadOnly: PropTypes.bool.isRequired,
  updateReportHandler: PropTypes.func.isRequired,
  sendReportHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
};

export default UserActions;
