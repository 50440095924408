import { PageNames } from 'shared/constants/appConstants';
import { mapPageNameToFiltersList } from 'invoices/constants/FiltersOptionsData';
import { AwsAutoCompleteFields } from 'shared/constants/awsConstants';
import { modifiedPurchaseOptionToDisplay } from 'shared/utils/awsUtils';

export const createFiltersDistinctValuesDisplayMap = (valKey, values) => {
  const createDivisionDisplayMap = (valArr) => {
    const map = new Map();
    if (valArr) {
      valArr.forEach((val) => {
        map.set(val.divisionName, val.divisionNameDisplay);
      });
    }
    return map;
  };
  const createBusinessMappingDisplayMap = (valArr) => {
    const map = new Map();
    if (valArr) {
      valArr.forEach((val) => {
        map.set(val.id, val.name);
      });
    }
    return map;
  };
  const createPurchaseOptionDisplayMap = (valArr) => {
    const map = new Map();
    if (valArr) {
      valArr.forEach((val) => {
        map.set(val.purchaseOptionName, modifiedPurchaseOptionToDisplay(val.purchaseOptionDisplayName));
      });
    }
    return map;
  };
  const keyToDisplayFuncCreator = {
    division: createDivisionDisplayMap,
    businessmappingviewpoints: createBusinessMappingDisplayMap,
    businessmapping: createBusinessMappingDisplayMap,
    purchaseoption: createPurchaseOptionDisplayMap,
  };
  return keyToDisplayFuncCreator[valKey](values);
};

export const prepareFilterMap = (mergedFilters, pageName, cloudType, definedArrFilters, k8sFeatureEnabled) => {
  const mapFilters = new Map();
  const arrFilters = definedArrFilters || mapPageNameToFiltersList.get(pageName)(cloudType, k8sFeatureEnabled);
  if (!arrFilters) {
    return mapFilters;
  }
  arrFilters.forEach((filterName) => {
    if (mergedFilters.has(filterName) || AwsAutoCompleteFields.includes(filterName)) {
      mapFilters.set(filterName, mergedFilters.get(filterName) || []);
    }
  });
  return mapFilters;
  // if (pageName === PageNames.SAVINGS_PLANS_ANALYZER) {
  // TODO - uncomment once we will suport service and os filter
  //   this.updateSpAnalyzerSpecificFilters(mapFilters);
  // }
};

export const getPageFiltersRaw = (mergedFilters, pageName, cloudType, filterOptions, k8sFeatureEnabled) => {
  if (mergedFilters.size === 0) {
    return new Map();
  }
  const mapFilters = prepareFilterMap(mergedFilters, pageName, cloudType, undefined, k8sFeatureEnabled);
  if (pageName === PageNames.FILTER_GROUP && filterOptions) {
    filterOptions.forEach(({ name, filter }) => {
      if (mapFilters.get(name)) {
        mapFilters.set(name, mapFilters.get(name).filter(filter));
      }
    });
  }
  return mapFilters;
};
