import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  TableColumnResizing,
  TableRowDetail,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedSelection,
  RowDetailState,
  SelectionState,
  SortingState,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid';
import { ReactComponent as NotContain } from 'shared/img/icons/not-contain.svg';
import { ReactComponent as Contains } from 'shared/img/icons/contains.svg';
import { ReactComponent as Equal } from 'shared/img/icons/equal.svg';
import { ReactComponent as NotEqual } from 'shared/img/icons/not-equal.svg';
import { ReactComponent as GreaterThan } from 'shared/img/icons/greater-than.svg';
import { ReactComponent as LessThan } from 'shared/img/icons/less-than.svg';
import { ReactComponent as GreaterThanOrEqual } from 'shared/img/icons/greater-than-or-equal.svg';
import { ReactComponent as Assign } from 'shared/img/icons/assign.svg';
import TableWrapper from 'shared/components/tables/TableWrapper';
import useTable from 'shared/hooks/customHooks/useTable';
import useRecsList from 'recommendationsNew/hooks/react-query/useRecsList';
import Spinner from 'shared/components/andtComponents/Spinner';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import RecommendationsNewContext from 'recommendationsNew/contexts/recommendationsNewContext';
import { debounce } from 'lodash';
import { getTagsColumnsForCsv } from 'shared/utils/exportTagUtil';
import { TagColumn } from 'shared/components/tagColumn/TagColumn';
import { withStyles } from '@mui/styles';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import { palette } from 'shared/constants/colorsConstants';
import useUserRecAction from 'recommendationsNew/hooks/react-query/useUserRecAction';
import toast from 'shared/components/andtComponents/Toast';
import {
  ACTION_SUCCESS_MESSAGE,
  BULK_ACTION_SUCCESS_MESSAGE,
  ACTION_STATUS,
  getLinkedAccountName,
} from 'recommendationsNew/consts';
import { useRootStore } from 'app/contexts/RootStoreContext';
import RecommendationDataContainer from '../detailedRecommendation/recommendationDataContainer';
import { ReactComponent as NoResults } from '../../img/no-results.svg';
import classes from './recommendationsList.module.scss';
import RecommendationsTableHeader from './recommendationsTableHeader';
import RecommendationExcluded from './recommendationExcluded';
import RecommendationActions from './recommendationActions';
import RecommendationLabel from './recommendationLabel';
import BulkOperationsRow from './bulkOperationsRow';
import RecommendationComments from './recommendationComments';
import RecommendationUserStatus from './recommendationUserStatus';
import RecommendationDetailsWidthContext from '../../contexts/recommendationDetailsWidthContext';

const containOperator = ['contains'];
const numericFilterOperations = ['greaterThan', 'lessThan'];
const defaultFilterOperations = ['contains', 'notContains', 'equal', 'notEqual'];
const fixedColumns = ['comment', 'star', 'status', 'userStatus', 'userActions'];
const recommendationsColumns = (cloudType) => [
  {
    name: 'savings',
    title: 'Annual Potential Savings',
    getCellValue: (row) => row.annualSavings?.unblended,
  },
  {
    name: 'monthlySavings',
    title: 'Monthly Potential Savings',
    getCellValue: (row) => row.monthlySavings?.unblended,
  },
  { name: 'typeName', title: 'Recommendation Type' },
  {
    name: 'linkedAccount',
    title: getLinkedAccountName(cloudType),
    getCellValue: (row) =>
      row.linkedAccountId ? `${row.linkedAccountName || ''} (${row.linkedAccountId})` : row.linkedAccountName,
  },
  {
    name: 'resource',
    title: 'Resource',
    getCellValue: (row) =>
      row.resourceId && row.resourceName !== row.resourceId
        ? `${row.resourceName || ''} (${row.resourceId})`
        : row.resourceName,
  },
  { name: 'age', title: 'Age' },
  { name: 'region', title: 'Region' },
  { name: 'customTags', title: 'Tags', getCellValue: (row) => row.customTags },
  { name: 'enrichmentTags', title: 'Enrichment Tags', getCellValue: (row) => row.customTags },
  { name: 'labels', title: 'Labels' },
];

const pageSize = 50;

const actionsColumns = [
  // { name: 'assignee', title: 'Assignee' },

  { name: 'userStatus', title: '' },
  { name: 'star', title: '' },
  { name: 'comment', title: '' },
  { name: 'status', title: '' },
  { name: 'userActions', title: '' },
];

const recommendationColumnsWidths = [
  { columnName: 'savings', width: 190 },
  { columnName: 'typeName', width: 190 },
  { columnName: 'linkedAccount', width: 225 },
  { columnName: 'resource', width: 290 },
  { columnName: 'age', width: 60 },
  { columnName: 'region', width: 125 },
  { columnName: 'customTags', width: 125 },
  { columnName: 'enrichmentTags', width: 145 },
  // { columnName: 'assignee', width: 60 },
  { columnName: 'star', width: 50 },
  { columnName: 'comment', width: 50 },
  { columnName: 'status', width: 60 },
  { columnName: 'userStatus', width: 40 },
  { columnName: 'userActions', width: 40 },
  { columnName: 'monthlySavings', width: 120 },
  { columnName: 'labels', width: 120 },
];

const exportColumn = [
  { label: 'Potential Savings', key: 'savings' },
  { label: 'Recommendation Type', key: 'typeName' },
  { label: 'Linked Account Name', key: 'linkedAccountName' },
  { label: 'Linked Account ID', key: 'linkedAccountId' },
  { label: 'resource Name', key: 'resourceName' },
  { label: 'resource ID', key: 'resourceId' },
  { label: 'Age', key: 'age' },
  { label: 'Region', key: 'region' },
];

const CustomFilterIcon = ({ type }) => {
  switch (type) {
    case 'contains':
      return <Contains />;
    case 'notContains':
      return <NotContain />;
    case 'equal':
      return <Equal />;
    case 'notEqual':
      return <NotEqual />;
    case 'greaterThan':
      return <GreaterThan />;
    case 'lessThan':
      return <LessThan />;
    case 'greaterThanOrEqual':
      return <GreaterThanOrEqual />;
    default:
      return <Contains />;
  }
};

const CustomFormatterWithTooltip = ({ value }) => (
  <Tooltip title={value}>
    <span>{value}</span>
  </Tooltip>
);

const CustomHeaderCell = ({ column, ...restProps }) => {
  const { children } = restProps;

  if (!column.title) {
    return (
      <TableHeaderRow.Cell {...restProps} column={column}>
        <Tooltip title={column.title}>
          <span className={classes.headerList} />
        </Tooltip>
      </TableHeaderRow.Cell>
    );
  }
  return (
    <TableHeaderRow.Cell {...restProps} column={column}>
      <Tooltip title={column.title}>
        <span className={classes.headerList}>{children}</span>
      </Tooltip>
    </TableHeaderRow.Cell>
  );
};

const CustomFilterRow = ({ children, ...restProps }) => (
  <TableFilterRow.Row {...restProps} className={`filterRow ${classes.filterRow}`}>
    {children}
  </TableFilterRow.Row>
);

const CustomFilterCell = withStyles({
  root: {
    '& input::placeholder': {
      color: palette.gray[400],
      fontSize: '12px',
      fontStyle: 'medium',
      fontFamily: 'Roboto',
    },
    border: 'none',
  },
})(({ column, classes, ...restProps }) => {
  if (['assignee', 'star', 'comment', 'status', 'userStatus', 'userActions'].includes(column.name)) {
    return <TableFilterRow.Cell {...restProps}> </TableFilterRow.Cell>;
  }
  return <TableFilterRow.Cell {...restProps} className={classes.root} />;
});

const getFilterColumnValue = (operator, value) => {
  switch (operator) {
    case 'contains':
      return { like: [value] };
    case 'notContains':
      return { like: [value], negate: true };
    case 'equal':
      return { eq: [value] };
    case 'notEqual':
      return { eq: [value], negate: true };
    default:
      return {};
  }
};

const statusFormatter = ({ row }) => (
  <div className={classes.statusWrapper}>
    {row.open ? (
      <span className={classes.open}>OPEN</span>
    ) : (
      <Tooltip title="Completed" arrow placement="left">
        <span className={classes.complete}>
          COMP. <GenerateIcon iconName={ICONS.check.name} />
        </span>
      </Tooltip>
    )}
  </div>
);

const RecommendationsList = () => {
  const { recommendationFilters: filtersContext, heatMapGroupByOptions } = useContext(RecommendationsNewContext);
  const { usersStore } = useRootStore();
  const { getCurrencyNumber } = useUserSettingsContext();
  const { NewTableWrapper, NewTableRow, NewTableSelectionBar } = useTable();
  const recsListHook = useRecsList();
  const recActionHook = useUserRecAction();
  const [sorting, setSorting] = useState([{ columnName: 'savings', direction: 'desc' }]);
  const [searchText, setSearchText] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExcludeModal, setShowExcludeModal] = useState(false);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [recommendationsData, setRecommendationsData] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [userActionParams, setUserActionParams] = useState({});
  const [recColumnsParams, setRecColumnsParams] = useState({ filters: filtersContext });
  const [isPrevPage, setIsPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [localFilters, setLocalFilters] = useState(null);
  const [columnNames, setColumnNames] = useState([]);
  const [columns, setColumns] = useState(...[recommendationsColumns(usersStore.currDispUserCloudAccountType)]);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [showDoneId, setShowDoneId] = useState(null);
  const [columnsWidth, setColumnsWidth] = useState(recommendationColumnsWidths);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(['monthlySavings', 'labels']);

  const { recommendationDetailsWidthRef } = useContext(RecommendationDetailsWidthContext);

  const {
    data: recsData = {},
    refetch: refetchRecs,
    isLoading: isRecsDataLoading,
  } = recsListHook.fetchRecommendations(queryParams, () => {
    setSelectedRows([]);
  });

  const { data: recColumns, isLoading: isGetColumnsLoading } =
    recsListHook.fetchRecommendationColumns(recColumnsParams);

  const { refetch: refetchFullRecs } = recsListHook.fetchFullRecommendations(queryParams);

  const { data: useActionStatus } = recActionHook.recUserAction(userActionParams, {
    enabled: !!userActionParams.recId,
  });

  const getFirstRowForPagingToken = () => {
    const firstRow = {};
    if (!recsData.paginationToken) {
      return recommendationsData[0];
    }
    Object.keys(recsData.paginationToken).forEach((key) => {
      firstRow[key] = recommendationsData[0][key];
    });
    return firstRow;
  };

  const updateSearchQuery = () => {
    setQueryParams({
      filters: filtersContext,
      tableFilters: localFilters,
      groupBy: heatMapGroupByOptions || queryParams.groupBy,
      sortBy: sorting || queryParams.sortBy,
      lastItem: currentPage > 0 && !isPrevPage && recommendationsData.length ? recsData.paginationToken : null,
      firstItem: isPrevPage && recommendationsData.length ? getFirstRowForPagingToken() : null,
      pageSize,
      pageNumber: currentPage,
      searchText,
    });
  };

  useEffect(() => {
    setRecColumnsParams({ filters: filtersContext });
  }, [filtersContext]);

  useEffect(() => {
    updateSearchQuery();
  }, [filtersContext, heatMapGroupByOptions, isPrevPage, currentPage]);

  useEffect(() => {
    if (recColumns && recColumns.length) {
      const customColumns = recColumns.map((r) => ({
        name: r.columnName,
        recTypes: r.recTypes,
        title: r.columnName
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' '),
        getCellValue: (row) => (row?.recData && row?.recData[r.columnName]) || '',
      }));
      if (!recColumnsParams.filters.type_id?.length || recColumnsParams.filters.type_id?.length > 1) {
        setHiddenColumnNames([...hiddenColumnNames, ...customColumns.map((c) => c.name)]);
      } else {
        setHiddenColumnNames(['monthlySavings', 'labels']);
      }
      setColumnNames(customColumns.map((c) => c.title));
      setDynamicColumns([...customColumns]);
      setColumns([
        ...recommendationsColumns(usersStore.currDispUserCloudAccountType),
        ...customColumns,
        ...actionsColumns,
      ]);
      setColumnsWidth([...columnsWidth, ...recColumns.map((r) => ({ columnName: r.columnName, width: 70 }))]);
    }
  }, [recColumns]);

  useEffect(() => {
    if (!isPrevPage && currentPage === 0) {
      updateSearchQuery();
    } else {
      setIsPrevPage(false);
      setCurrentPage(0);
    }
  }, [searchText, sorting, localFilters]);

  useEffect(() => {
    setRecommendationsData(recsData?.page || recommendationsData);
    setExpandedRowIds([]);
    setTotalPages(recsData?.total ? Math.round((recsData.tableTotal || recsData.total) / pageSize) : totalPages);
  }, [recsData]);

  useEffect(() => {
    if (!isExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(recommendationsData.map((row, index) => index));
    }
  }, [isExpanded]);

  useEffect(() => {
    if (useActionStatus) {
      if (userActionParams.action === ACTION_STATUS.done) {
        setShowDoneId(userActionParams.recId);
      }
      refetchRecs().then(() => {
        toast.success(ACTION_SUCCESS_MESSAGE[userActionParams.action]);
        setUserActionParams({});
        setShowDoneId(null);
      });
    }
  }, [useActionStatus]);

  const onCurrentPageChange = (newPageIndex) => {
    setIsPrevPage(newPageIndex < currentPage);
    setCurrentPage(newPageIndex);
  };

  const updateFilters = (filters) => {
    const tempFilters = {};
    filters.forEach((f) => {
      switch (f.columnName) {
        case 'savings':
          tempFilters.annual_savings_greater_than = Number(f.value);
          break;
        case 'age':
          tempFilters.age_range = f.operation === 'greaterThan' ? [Number(f.value), null] : [null, Number(f.value)];
          break;
        case 'customTags':
          tempFilters.custom_tags_search_expression = f.value;
          break;
        case 'enrichmentTags':
          tempFilters.enrichment_tags_search_expression = f.value;
          break;
        default: {
          const columnName = f.columnName?.replace(/([A-Z])/g, '_$1').toLowerCase();
          tempFilters[columnName] = getFilterColumnValue(f.operation, f.value);
        }
      }
    });
    setLocalFilters(tempFilters);
  };

  const debouncedSetFilters = debounce(updateFilters, 500);

  const onFiltersChange = (newFilters) => {
    debouncedSetFilters(newFilters);
  };

  useEffect(
    () => () => {
      debouncedSetFilters.cancel();
    },
    [],
  );

  const fetchDataForExport = async (isFromBulk = false) => {
    let exportData;
    if (isFromBulk) {
      exportData = recommendationsData.filter((r, index) => selectedRows.includes(index));
    } else {
      const { data } = await refetchFullRecs();
      exportData = data.page;
    }
    const extraColumn = dynamicColumns
      .filter((c) => !exportColumn.find((ec) => ec.key === c.name) && !hiddenColumnNames.includes(c.name))
      .map((c) => ({ label: c.title, key: c.name }));
    const dataToExport = (exportData || []).map((rec) => {
      const { savings, recData, ...rest } = rec;
      const tagsColumn = getTagsColumnsForCsv(rec, 'customTags', 'enrichmentTags');
      tagsColumn.exportTagColumns.forEach((column) => {
        if (!exportColumn.find((c) => c.key === column.key && c.label === column.label)) {
          exportColumn.push(column);
        }
      });
      const extractData = {};
      extraColumn.forEach((ec) => {
        extractData[ec.key] = recData[ec.key];
      });
      return {
        savings: savings?.unblended,
        ...rest,
        ...tagsColumn.data,
        ...extractData,
      };
    });

    return [
      {
        data: dataToExport || [],
        filename: 'Recommendations Export.csv',
        headers: [...exportColumn, ...extraColumn],
      },
    ];
  };

  const getLabels = () => {
    if (!currentRow?.labels) {
      return [];
    }
    return currentRow.labels?.split(',').map((label) => ({ label, recIds: [currentRow.recId] }));
  };

  const labelsOptions = useMemo(() => getLabels(), [currentRow]);

  const setExcluded = (props) => {
    if (props.row.userStatus?.status === ACTION_STATUS.excluded) {
      setUserActionParams({
        action: ACTION_STATUS.include,
        recId: props.row.recId,
      });
    } else {
      setCurrentRow(props.row);
      setShowExcludeModal(true);
    }
  };

  const setDone = (props) => {
    setUserActionParams({
      action: props.row.userStatus?.status === ACTION_STATUS.done ? ACTION_STATUS.undone : ACTION_STATUS.done,
      recId: props.row.recId,
    });
  };
  const CustomNoDataComponent = () => {
    if (isRecsDataLoading || isGetColumnsLoading) {
      return null;
    }
    return (
      <div className={classes.noResultsWrapper}>
        <NoResults />
        <span className={classes.mainText}>No Recommendation was found</span>
        <span className={classes.subText}>Try different filters</span>
      </div>
    );
  };
  return (
    <div className="recommendation-list">
      {(isRecsDataLoading || isGetColumnsLoading) && (
        <div>
          <Spinner top="90%" />
        </div>
      )}
      <NewTableWrapper className={selectedRows?.length > 0 && 'custom-pager'}>
        <RecommendationsTableHeader
          rowsCount={recsData?.total}
          displayCount={recsData?.tableTotal}
          searchText={searchText}
          setSearchText={setSearchText}
          showFilters={showFilters}
          setShowFilters={(showFilters) => {
            setShowFilters(showFilters);
            if (!showFilters) {
              setLocalFilters(null);
            }
          }}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          fetchExportData={fetchDataForExport}
          hiddenColumnNames={hiddenColumnNames}
          setHiddenColumnNames={(columns) => {
            setHiddenColumnNames(columns);
          }}
          pageChange={onCurrentPageChange}
          currentPage={currentPage}
          totalPages={totalPages}
          columns={columns.filter((c) => !actionsColumns.find((ac) => ac.name === c.name))}
        />
        <div ref={recommendationDetailsWidthRef}>
          <Grid rows={recommendationsData} columns={columns} automationId="recommendations-list">
            <SortingState
              sorting={sorting}
              columnExtensions={[
                { columnName: 'customTags', sortingEnabled: false },
                { columnName: 'enrichmentTags', sortingEnabled: false },
                { columnName: 'assignee', sortingEnabled: false },
                { columnName: 'star', sortingEnabled: false },
                { columnName: 'comment', sortingEnabled: false },
                { columnName: 'status', sortingEnabled: false },
                { columnName: 'userStatus', sortingEnabled: false },
                { columnName: 'userActions', sortingEnabled: false },
              ]}
              onSortingChange={(sortingColumAndDirection) => setSorting(sortingColumAndDirection)}
            />
            {showFilters && <FilteringState onFiltersChange={onFiltersChange} />}
            <SelectionState selection={selectedRows} onSelectionChange={setSelectedRows} />
            <IntegratedSelection />
            <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
            <DataTypeProvider
              for={['savings']}
              availableFilterOperations={['greaterThanOrEqual']}
              formatterComponent={(data) => getCurrencyNumber(data.value)}
            />
            <DataTypeProvider
              for={['monthlySavings']}
              availableFilterOperations={['greaterThanOrEqual']}
              formatterComponent={(data) => getCurrencyNumber(data.value)}
            />
            <DataTypeProvider for={['typeName']} availableFilterOperations={defaultFilterOperations} />
            <DataTypeProvider for={['linkedAccount']} availableFilterOperations={defaultFilterOperations} />
            <DataTypeProvider
              for={['resource']}
              availableFilterOperations={defaultFilterOperations}
              formatterComponent={CustomFormatterWithTooltip}
            />
            <DataTypeProvider for={['age']} availableFilterOperations={numericFilterOperations} />
            <DataTypeProvider for={['region']} availableFilterOperations={defaultFilterOperations} />
            <DataTypeProvider
              for={['customTags']}
              formatterComponent={TagColumn}
              availableFilterOperations={containOperator}
            />
            <DataTypeProvider
              for={['enrichmentTags']}
              formatterComponent={TagColumn}
              availableFilterOperations={containOperator}
            />
            <DataTypeProvider for={['assignee']} formatterComponent={() => <Assign />} />
            <DataTypeProvider
              for={['star']}
              formatterComponent={({ row }) => (
                <Tooltip title="Add Star" arrow placement="bottom">
                  <span>
                    <GenerateIcon
                      className={`${classes.starIcon} ${usersStore.currentUserReadOnly ? classes.disableIcon : ''}`}
                      iconName={row.starred ? ICONS.star.name : ICONS.starHollow.name}
                      color={row.starred ? palette.mango[500] : palette.gray[400]}
                      size="lg"
                      disabled={usersStore.currentUserReadOnly}
                      onClick={() => {
                        if (!usersStore.currentUserReadOnly) {
                          setUserActionParams({ action: row.starred ? 'unstar' : 'star', recId: row.recId });
                        }
                      }}
                    />
                  </span>
                </Tooltip>
              )}
            />
            <DataTypeProvider for={['comment']} formatterComponent={(props) => <RecommendationComments {...props} />} />
            <DataTypeProvider for={['status']} formatterComponent={statusFormatter} />
            <DataTypeProvider
              for={['userStatus']}
              formatterComponent={(props) => (
                <RecommendationUserStatus
                  isExcluded={props.row.userStatus?.status === ACTION_STATUS.excluded}
                  isDone={props.row.userStatus?.status === ACTION_STATUS.done}
                  setExcluded={() => setExcluded(props)}
                  setDone={() => setDone(props)}
                  showDoneId={showDoneId}
                  {...props}
                />
              )}
            />
            <DataTypeProvider
              for={['userActions']}
              formatterComponent={(props) => (
                <RecommendationActions
                  {...props}
                  addLabel={() => {
                    setCurrentRow(props.row);
                    setShowLabelModal(true);
                  }}
                />
              )}
            />
            <DataTypeProvider
              for={dynamicColumns.map((column) => column.name)}
              availableFilterOperations={defaultFilterOperations}
              formatterComponent={CustomFormatterWithTooltip}
            />
            <DataTypeProvider for={columnNames} formatterComponent={CustomFormatterWithTooltip} />
            <TableWrapper
              virtual
              noDataCellComponent={CustomNoDataComponent}
              rowComponent={(props) => <NewTableRow expandedRowIds={expandedRowIds} {...props} />}
              height="750px"
            />
            <TableSelection showSelectAll />
            <TableColumnResizing resizingMode="nextColumn" columnWidths={columnsWidth} />
            <TableRowDetail contentComponent={RecommendationDataContainer} />
            <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
            {showFilters && (
              <TableFilterRow
                showFilterSelector
                iconComponent={CustomFilterIcon}
                rowComponent={CustomFilterRow}
                cellComponent={CustomFilterCell}
              />
            )}
            <NewTableSelectionBar selectedRows={selectedRows}>
              <div className={classes.bulkRow}>
                <BulkOperationsRow
                  exportCsv={() => fetchDataForExport(true)}
                  reloadData={(actionType) => {
                    refetchRecs().then(() => {
                      toast.success(BULK_ACTION_SUCCESS_MESSAGE[actionType]);
                    });
                  }}
                  selectedRecs={recommendationsData.filter((r, index) => selectedRows.includes(index))}
                />
              </div>
            </NewTableSelectionBar>
            <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} />
            <TableFixedColumns rightColumns={fixedColumns} />
          </Grid>
        </div>
      </NewTableWrapper>
      {showExcludeModal && (
        <RecommendationExcluded
          recs={[currentRow]}
          closeModal={(obj) => {
            if (obj) {
              setUserActionParams({
                action:
                  currentRow.userStatus?.status === ACTION_STATUS.excluded
                    ? ACTION_STATUS.include
                    : ACTION_STATUS.excluded,
                recId: currentRow.recId,
                actionParams: obj,
              });
            }
            setShowExcludeModal(false);
          }}
        />
      )}
      {showLabelModal && (
        <RecommendationLabel
          closeModal={() => setShowLabelModal(false)}
          recLabels={labelsOptions}
          recIds={[currentRow.recId]}
          saveLabels={(addLabels, removeLabels) => {
            if (!addLabels.length && !removeLabels.length) {
              return;
            }
            setUserActionParams({
              action: 'label',
              recId: currentRow.recId,
              actionParams: { data: { label_add: addLabels.join(','), label_delete: removeLabels.join(',') } },
            });
          }}
        />
      )}
    </div>
  );
};

export default RecommendationsList;

RecommendationsList.propTypes = {};
