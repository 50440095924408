import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import { Chip, FormHelperText, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import Switch from 'shared/components/andtComponents/Switch';
import Input from 'shared/components/andtComponents/Input';
import RadioButtonsList from 'shared/components/andtComponents/RadioButtonList';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import {
  isCustomDivisionNotFinished,
  SPLIT_TYPES,
  SPLIT_TYPES_DESCRIPTIONS,
  SPLIT_TYPES_NAMES,
  sumPercentages,
} from '../../constants/businessMappingConstants';
import styles from './BusinessMappingSplitBlock.module.scss';

const BusinessMappingSplitBlock = ({
  groups,
  isEditMode,
  group,
  isSplit,
  setIsSplit,
  mappings,
  setMappings,
  splitType,
  setSplitType,
  customDivision,
  setCustomDivision,
  disabled,
}) => {
  const targetGroups = groups.filter((g) => !g.split && (!isEditMode || g.uuid !== group.uuid));
  const sharingDisabled = disabled || isEmptyArray(targetGroups);
  const equalPercent = Number(100 / mappings.length).toFixed(0);
  useEffect(() => {
    if (!customDivision) {
      return;
    }
    const newCustomDivision = {};
    mappings.forEach((m) => {
      newCustomDivision[m] = customDivision[m] || '';
    });
    setCustomDivision(newCustomDivision);
  }, [mappings, equalPercent]);
  const renderTypeDescription = () => {
    if (splitType !== SPLIT_TYPES.CUSTOM) {
      return <p>{SPLIT_TYPES_DESCRIPTIONS[splitType]}</p>;
    }
    if (isEmptyArray(mappings)) {
      return <p>Select mappings to specify the percentage of each of them</p>;
    }
    const percentagesMismatch = isCustomDivisionNotFinished(customDivision);
    if (!percentagesMismatch) {
      return <p>Total sum of percentages should be 100</p>;
    }
    return (
      <FormHelperText error>
        Total sum of percentages should be 100 (current: {sumPercentages(customDivision)})
      </FormHelperText>
    );
  };
  return (
    <div className={styles.container}>
      <div className="d-flex align-items-center">
        <Tooltip
          title="You should create usual maps first to be able to divide costs between them"
          disableFocusListener={!sharingDisabled}
          disableHoverListener={!sharingDisabled}
        >
          <Switch
            label="Split cost"
            isChecked={isSplit}
            onChange={() => setIsSplit(!isSplit)}
            isDisabled={sharingDisabled}
          />
        </Tooltip>
        <Tooltip
          title={
            disabled
              ? 'Allocate shared costs across different Business Mappings in the same Viewpoint.\n' +
                'To turn this mapping to split type copy it locally to your Viewpoint'
              : 'Allocate shared costs across different Business Mappings in the same Viewpoint.'
          }
          arrow
        >
          <span className={styles.infoIcon}>
            <InfoIcon />
          </span>
        </Tooltip>
      </div>
      {isSplit && (
        <>
          <div className={styles.selectLabel}>
            <span>Business Mapping</span>
          </div>
          <div className={styles.selectWrapper}>
            <Autocomplete
              fullWidth
              openOnFocus
              disableCloseOnSelect
              isOptionEqualToValue={(a, b) => a.value === b.value}
              size="small"
              getOptionLabel={(option) => option.label}
              disableClearable
              multiple
              renderTags={() => null}
              popupIcon={<GenerateIcon iconName={ICONS.caretDown.name} />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={styles.autocompleteInput}
                  variant="filled"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {mappings.length > 0 && (
                          <Chip
                            className={styles.chipComponent}
                            label={`${mappings.length} selected`}
                            onDelete={() => {
                              setMappings([]);
                            }}
                          />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              classes={{
                option: styles.menuOption,
              }}
              SelectProps={{
                MenuProps: {
                  style: {
                    maxHeight: 400,
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                },
              }}
              value={mappings.map((m) => ({
                value: m,
                label: targetGroups.find((tg) => tg.uuid === m).name,
              }))}
              onChange={(e, newVal) => {
                e.stopPropagation();
                setMappings((newVal || []).map((item) => item.value));
              }}
              renderOption={(props, option) => (
                <MenuItem key={option.value} {...props}>
                  <Checkbox
                    className={styles.checkbox}
                    isChecked={mappings.some((val) => val === option.value)}
                    primary
                    text={option.label}
                  />
                </MenuItem>
              )}
              options={targetGroups.map((g) => ({
                label: g.name,
                value: g.uuid,
              }))}
            />
            <span>Select</span>
          </div>
          <div className="mt-4">
            <RadioButtonsList
              value={splitType}
              optionClassName={styles.radioOption}
              options={Object.values(SPLIT_TYPES).map((val) => ({
                label: SPLIT_TYPES_NAMES[val],
                value: val,
                primary: true,
              }))}
              onChange={(value) => setSplitType(value)}
              renderDescription={(value) => {
                if (value === splitType) {
                  return renderTypeDescription();
                }
                return null;
              }}
            />
          </div>
          {splitType === SPLIT_TYPES.CUSTOM && (
            <div>
              <div className={styles.ratioBlockList}>
                {targetGroups
                  .filter((g) => mappings.includes(g.uuid))
                  .map((m) => (
                    <div key={m.uuid} className={styles.ratioInputBlock}>
                      <div className={styles.inputLabel}>{m.name}</div>
                      <div className={styles.input}>
                        <Input
                          value={customDivision[m.uuid]}
                          placeholder="0"
                          onChange={(e) => {
                            e.stopPropagation();
                            const { value } = e.target;
                            setCustomDivision({
                              ...customDivision,
                              [m.uuid]:
                                +value || +value === 0 ? Math.min(Number(Math.abs(value)).toFixed(0) || 0, 100) : '',
                            });
                          }}
                        />
                        <span>%</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

BusinessMappingSplitBlock.propTypes = {
  groups: PropTypes.array.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  group: PropTypes.object,
  disabled: PropTypes.bool,
  isSplit: PropTypes.bool.isRequired,
  setIsSplit: PropTypes.func.isRequired,
  mappings: PropTypes.array.isRequired,
  setMappings: PropTypes.func.isRequired,
  splitType: PropTypes.string.isRequired,
  setSplitType: PropTypes.func.isRequired,
  customDivision: PropTypes.object.isRequired,
  setCustomDivision: PropTypes.func.isRequired,
};

BusinessMappingSplitBlock.defaultProps = {
  group: null,
  disabled: false,
};

export default BusinessMappingSplitBlock;
