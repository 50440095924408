import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CostTrackingConstants } from 'usage/constants/costAndUsageConstants';
import { useEC2Usage } from 'usage/hooks/react-query/useEC2Usage';
import { GranularityLevelsTypes } from 'shared/constants/appConstants';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';

export const ec2UsageWrapper = (Component) => {
  const Wrapper = (props) => {
    const { initialEndDate, initialStartDate } = props;
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [currPeriodGranLevel, setCurrPeriodGranLevel] = useState(CostTrackingConstants.GRAN_LEVEL_DAILY);
    const [selectedOptionsMap, setSelectedOptionsMap] = useState(new Map());
    const { fetchEC2TotalInstancesAndCost } = useEC2Usage();
    const {
      data: ec2Cost,
      isLoading,
      isFetching,
    } = fetchEC2TotalInstancesAndCost(startDate, endDate, currPeriodGranLevel, selectedOptionsMap);
    return (
      <Component
        {...props}
        isEc2CostLoading={isLoading || isFetching}
        ec2Cost={ec2Cost}
        startDate={startDate}
        endDate={endDate}
        setDate={({ startDate: newStartDate, endDate: newEndDate }) => {
          const start = newStartDate || startDate;
          let end = newEndDate || endDate;
          if (start > end && end !== null) {
            end = start;
          }
          const modifiedStart = buildStartAndEndDate(start, end).startDate;
          const modifiedEnd = buildStartAndEndDate(start, end).endDate;
          setStartDate(modifiedStart);
          setEndDate(modifiedEnd);
        }}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        currPeriodGranLevel={currPeriodGranLevel}
        setCurrPeriodGranLevel={(granLevel) => {
          setCurrPeriodGranLevel(granLevel);
          if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_HOURLY) {
            setEndDate(startDate);
          }
        }}
        selectedOptionsMap={selectedOptionsMap}
        setFilters={(filterType, selectedOptions) => {
          const selectedOptionsMapLocal = selectedOptionsMap.set(filterType, selectedOptions);
          console.log(selectedOptionsMapLocal, new Map(selectedOptionsMapLocal));
          setSelectedOptionsMap(new Map(selectedOptionsMapLocal));
        }}
      />
    );
  };
  Wrapper.propTypes = {
    initialStartDate: PropTypes.object.isRequired,
    initialEndDate: PropTypes.object.isRequired,
  };
  return Wrapper;
};
