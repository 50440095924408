import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import { BASE_PROPERTIES, COST_PROPERTIES, getNumericValue } from '../../recommendationPropertyUtils';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import RecommendationCommand, { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import RecommendationChartData, {
  CHART_TYPES,
} from '../../recommendationGenericComponents/recommendationChart/recommendationChartData';
import { Y_AXIS_UNIT } from '../../recommendationGenericComponents/recommendationChart/chartConsts';
import RecommendationOptionsPanel from '../../recommendationGenericComponents/recommendationOptionsPanel';
import RecommendationOptionsContext from '../../recommendationGenericComponents/recommendationOptionsContext';

const EC2_LOW_CPU_USAGE_PROPERTIES = {
  EC2_RIGHT_SIZING_INSTANCE_TYPE: {
    label: 'Instance Type',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.instance_type;
      }
      // eslint-disable-next-line dot-notation
      return recommendation.recData?.model?.['Instance type'];
    },
  },
  EC2_RIGHT_SIZING_MEMORY: {
    label: 'Memory',
    isBSize: true,
    // eslint-disable-next-line dot-notation
    getterFunction: (recommendation) => recommendation.recData?.model?.['Memory'],
  },
  EC2_RIGHT_SIZING_NETWORK: {
    label: 'Network',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.network_performance;
      }
      // eslint-disable-next-line dot-notation
      return recommendation.recData?.model?.['Network'];
    },
  },
  EC2_RIGHT_SIZING_STORAGE: {
    label: 'Storage',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.storage;
      }
      // eslint-disable-next-line dot-notation
      return recommendation.recData?.model?.['Storage'];
    },
  },
  EC2_RIGHT_SIZING_vCPUS: {
    label: 'vCPUs',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return getNumericValue(recommendation.recData.alternatives[optionIndex]?.vcpu);
      }
      // eslint-disable-next-line dot-notation
      return recommendation.recData?.model?.['vCPUs'];
    },
  },
  NETWORK_IN: {
    label: 'Network In',
    isBSize: true,
    getterFunction: (recommendation) => recommendation.recData?.network_in,
  },
  NETWORK_OUT: {
    label: 'Network Out',
    isBSize: true,
    getterFunction: (recommendation) => recommendation.recData?.network_out,
  },
};

const Ec2LowCpuUsage = ({ recommendation }) => {
  const properties = {
    ...EC2_LOW_CPU_USAGE_PROPERTIES,
    ...COST_PROPERTIES,
    ...BASE_PROPERTIES,
  };

  const description = `This Ec2 instance has a low CPU utilization, 
            we recommend to switch to a more suitable instance type which will cost less.`;
  const insideLookComponent = <RecommendationInsideLook description={description} recData={recommendation?.recData} />;

  const otherResourceIdRecommendations = <OtherResourceIdRecommendations resourceId={recommendation?.resourceId} />;

  const RECOMMENDATION_OPTION_DATA = [
    properties.EC2_RIGHT_SIZING_INSTANCE_TYPE,
    properties.EC2_RIGHT_SIZING_MEMORY,
    properties.EC2_RIGHT_SIZING_STORAGE,
    properties.EC2_RIGHT_SIZING_vCPUS,
    properties.CPU_UTIL,
    properties.EC2_RIGHT_SIZING_NETWORK,
  ];

  const CURRENT_PROPERTIES = [...RECOMMENDATION_OPTION_DATA, properties.CURRENT_ANNUAL_COST];

  const ALTERNATIVE_PROPERTIES = [
    ...RECOMMENDATION_OPTION_DATA,
    properties.RECOMMENDED_ANNUAL_COST,
    properties.POTENTIAL_SAVINGS,
  ];

  const topThreeAlternatives = recommendation?.recData?.alternatives?.slice(0, 3);

  const { selectedOptionIndex } = useContext(RecommendationOptionsContext);

  const optionsPanelComponent =
    Array.isArray(topThreeAlternatives) && topThreeAlternatives.length ? (
      <RecommendationOptionsPanel
        alternatives={topThreeAlternatives}
        currentProperties={CURRENT_PROPERTIES}
        alternativeProperties={ALTERNATIVE_PROPERTIES}
        recommendation={recommendation}
      />
    ) : null;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: 'Stop instance',
          actionCommand: `aws ec2 stop-instances --instance-ids ${recommendation?.resourceId}`,
        },
        {
          actionText: 'Validate if the instance is stopped',
          // eslint-disable-next-line max-len
          actionCommand: `aws ec2 describe-instances --instance-ids ${recommendation?.resourceId} --query "Reservations[*].Instances[*].{PublicIP:PublicIpAddress,Name:Tags[?Key='Name']|[0].Value,Status:State.Name,InstanceID:InstanceId,Instancetype:InstanceType}" --output table`,
        },
        {
          actionText: 'Change the AWS instance type with AWS CLI command',
          actionCommand: `aws ec2 modify-instance-attribute --instance-id ${
            recommendation?.resourceId
            // eslint-disable-next-line max-len
          } --instance-type "{\\"Value\\": \\"{${`${recommendation?.recData?.model_recommended['Instance type']}`}\\"}"`,
        },
        {
          // eslint-disable-next-line max-len
          actionText: `Validate if the instance type is changed to ${recommendation?.recData?.model_recommended['Instance type']}`,
          // eslint-disable-next-line max-len
          actionCommand: `aws ec2 describe-instances --instance-ids ${recommendation?.resourceId} --query "Reservations[*].Instances[*].{PublicIP:PublicIpAddress,Name:Tags[?Key=='Name']|[0].Value,Status:State.Name,InstanceID:InstanceId,Instancetype:InstanceType}" --output table`,
        },
        {
          actionText: 'Start the instance',
          actionCommand: `aws ec2 start-instances --instance-id ${recommendation?.resourceId}`,
        },
      ],
    },
    {
      type: COMMAND_TYPES.CONSOLE,
      instructions: [
        {
          actionText: 'Changing EC2 Instance Type using the AWS Console',
          actionLink: 'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-instance-resize.html',
        },
      ],
    },
  ];

  const recommendationCommandComponent = <RecommendationCommand commandsList={commandsList} />;

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  let chartsData = [
    {
      chartType: CHART_TYPES.GENERIC,
      chartTitle: 'Network Performance',
      yAxisLabel: 'Network I/O (MB)',
      yAxisUnit: Y_AXIS_UNIT.BYTE,
      linesList: [
        {
          id: 'networkIn',
          label: 'Network In',
          data: recommendation.recData?.network_in_statistics_usage,
        },
        {
          id: 'networkOut',
          label: 'Network Out',
          data: recommendation.recData?.network_out_statistics_usage,
        },
      ],
    },
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'CPU Performance',
      yAxisLabel: 'CPU',
      yAxisUnit: Y_AXIS_UNIT.PERCENT,
      maxDataProperty: recommendation?.recData?.cpu_util_statistics_usage,
      avgDataProperty: recommendation?.recData?.cpu_util_statistics_avg_usage,
      estimatedDataProperty:
        selectedOptionIndex >= 0
          ? recommendation.recData?.alternatives[selectedOptionIndex]?.cpu_max_estimated
          : recommendation.recData?.cpu_util_recommended_usage,
    },
  ];
  if (
    recommendation?.recData?.mem_used_percent_statistics_usage ||
    recommendation?.recData?.mem_used_percent_statistics_avg_usage
  ) {
    chartsData = [
      ...chartsData,
      {
        chartType: CHART_TYPES.SERIES_DATA,
        chartTitle: 'Memory Performance',
        yAxisLabel: 'Memory',
        yAxisUnit: Y_AXIS_UNIT.PERCENT,
        maxDataProperty: recommendation?.recData?.mem_used_percent_statistics_usage,
        avgDataProperty: recommendation?.recData?.mem_used_percent_statistics_avg_usage,
      },
    ];
  }

  const recommendationChartsComponent = <RecommendationChartData chartsData={chartsData} />;

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      otherResourceIdRecommendations={otherResourceIdRecommendations}
      details={detailsComponent}
      command={recommendationCommandComponent}
      preferences={preferencesComponent}
      charts={recommendationChartsComponent}
      optionsPanel={optionsPanelComponent}
    />
  );
};

Ec2LowCpuUsage.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default Ec2LowCpuUsage;
