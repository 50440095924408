import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'shared/components/andtComponents/Spinner';
import { UsersType } from 'users/constants/usersConstants';
import Button from 'shared/components/andtComponents/Button';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import toast from 'shared/components/andtComponents/Toast';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import { getDivisionUsers } from 'divisions/utils';
import { getPayerLinkedAccount } from 'shared/utils/sharedUtils';
import styles from './BillingProfile.module.scss';
import BillingProfileForm from './BillingProfileForm';

const BillingProfile = ({
  divisionsStore,
  usersStore,
  isAllAccounts,
  payerAccountOptions,
  billingProfileTitle,
  isOnBoarding,
}) => {
  const actionsDisabled = ![UsersType.USER_ON_BOARDED, UsersType.NEW_USER, UsersType.USER, UsersType.RESELLER].includes(
    +usersStore.getCurrentDisplayedUserType,
  );
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anyFieldChanged, setAnyFieldChanged] = useState(false);
  const [payerAccount, setPayerAccount] = useState(payerAccountOptions ? payerAccountOptions[0].value : null);
  const [profile, setProfile] = useState({});
  const loadData = async (account) => {
    setLoading(true);
    setProfile(await divisionsStore.getBillingProfile(account));
    setLoading(false);
  };

  useEffect(() => {
    setPayerAccount(payerAccountOptions ? payerAccountOptions[0].value : null);
  }, [payerAccountOptions]);
  useEffect(() => {
    if (!payerAccount) {
      return;
    }
    loadData(payerAccount);
  }, [payerAccount]);
  // const { usersStore } = useRootStore();
  useEffect(() => {
    (async () => {
      await usersStore.fetchSubUsers();
    })();
  }, []);

  const payerAccountLinkedAccount = getPayerLinkedAccount({
    account: usersStore.getCurrDisplayedAccount,
    currDispUserCloudAccountType: usersStore.currDispUserCloudAccountType,
  });
  const subUsers = usersStore.getSubUsersListByDisplayedType();
  const customers = usersStore.usersModel.divisionGroups;
  const isSignleCustomerOnly = !!(
    !isOnBoarding &&
    customers.length === 1 &&
    customers[0].linkedAccounts[0].linkedAccountId === payerAccountLinkedAccount.linkedAccountId &&
    getDivisionUsers(subUsers, customers[0].divisionName).length
  );
  const isMultiCustomersOnly = !isOnBoarding && customers.length > 1;
  if (!payerAccount) {
    return null;
  }
  const handleSave = async () => {
    setSaveLoading(true);
    const data = Object.keys(profile).reduce(
      (acc, key) => ({
        ...acc,
        [key]: Number(profile[key]),
      }),
      {},
    );
    await divisionsStore.updateBillingProfile(data, payerAccount);
    await loadData(payerAccount);
    setSaveLoading(false);
    toast.success('Changes saved');
  };

  const renderBody = () => (
    <>
      <BillingProfileForm
        profile={profile}
        setProfile={(profile) => {
          setAnyFieldChanged(true);
          setProfile(profile);
        }}
        isSignleCustomerOnly={isSignleCustomerOnly}
        isMultiCustomersOnly={isMultiCustomersOnly}
      />
      <div className={styles.buttons}>
        <Button
          disabled={actionsDisabled || saveLoading || !anyFieldChanged}
          onClick={handleSave}
          isLoading={saveLoading}
          overrideStyles={{ width: 85 }}
          text="Save"
        />
      </div>
    </>
  );
  return (
    <div className="w-100">
      {billingProfileTitle && (
        <div className="mb-4">
          <TableHeaderProvider>
            <TableHeader tableName="Billing Profile" hideSearch isCreatable={false} />
          </TableHeaderProvider>
        </div>
      )}
      {isAllAccounts ? (
        <div className="mb-4">
          <h5 className="">Payer Account</h5>
          <OneChoiceFieldFilter
            type="payerAccount"
            value={payerAccountOptions.find((opt) => opt.value === payerAccount)}
            options={payerAccountOptions}
            handleChange={(type, value) => setPayerAccount(value.value)}
            isClearable
          />
        </div>
      ) : null}
      {loading ? <Spinner /> : renderBody()}
    </div>
  );
};

BillingProfile.propTypes = {
  divisionsStore: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  isAllAccounts: PropTypes.bool.isRequired,
  payerAccountOptions: PropTypes.array.isRequired,
  billingProfileTitle: PropTypes.bool,
  isOnBoarding: PropTypes.bool,
};

BillingProfile.defaultProps = {
  billingProfileTitle: true,
  isOnBoarding: false,
};

export default BillingProfile;
