/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class VideoPlayer extends Component {
  static propTypes = {
    video: PropTypes.string.isRequired,
    autoplay: PropTypes.string.isRequired,
    rel: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    modest: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.ratio = 500 / 315;
    this.width = 600;
    this.state = {
      index: 1,
    };
  }

  render() {
    const { src, title } = this.props;
    return (
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          title={title}
          src={src}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </div>
    );
  }
}
