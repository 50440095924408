import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import {
  transformFiltersContextToApiFormat,
  transformSelectDataToClientFormat,
} from 'recommendationsNew/hooks/react-query/apiUtils';
import {
  BILLINGS_API_NAME,
  GROUP_BY_OPTIONS,
  RECOMMENDATIONS_HEATMAP_ROOT,
  SORT_BY_OPTIONS,
  STATLE_TIME,
} from 'recommendationsNew/consts';

async function fetchHeatMap({ filters, groupBy, sortBy, topX = 4 }) {
  const transformedFilters = transformFiltersContextToApiFormat(filters);
  const result = await API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_HEATMAP_ROOT}`, {
    body: {
      filters: transformedFilters || {},
      group_by: groupBy || [GROUP_BY_OPTIONS.items.TYPE_ID.id],
      sort_by: sortBy || SORT_BY_OPTIONS.items[0].property,
      top_x: topX,
    },
  });
  if (result && Object.keys(result).length > 0) {
    Object.keys(result).forEach((key) => {
      result[key] = { ...result[key], page: transformSelectDataToClientFormat(result[key]?.page, key) };
    });
  }
  return result;
}

export default function useHeatMap(isEnabled = true) {
  const queryKey = [apiConstants.QUERY_KEYS.RECOMMENDATIONS_HEATMAP];

  return {
    invalidate: ({ filters, groupBy, sortBy }) =>
      queryClient.invalidateQueries([...queryKey, filters, groupBy, sortBy] || queryKey),
    reset: ({ filters, groupBy, sortBy }) =>
      queryClient.resetQueries([...queryKey, filters, groupBy, sortBy] || queryKey),
    fetchRecommendationsHeatMap: ({ filters, groupBy, sortBy }) =>
      useQuery([...queryKey, filters, groupBy, sortBy], () => fetchHeatMap({ filters, groupBy, sortBy }), {
        retry: false,
        staleTime: STATLE_TIME,
        enabled: isEnabled,
      }),
  };
}
