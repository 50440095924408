import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'reactstrap';
import IconInputField from 'shared/components/IconInputField';
import ARN_IMG from 'shared/img/images/find_ARN.png';

const ExecuteCliCommands = React.memo(({ cmdsToExecute, disableEnterKeySub, arnRoleNum, handleArnRoleNumChange }) => (
  <Row>
    <Col xs={12} md={12} lg={12} xl={12}>
      <h4 className="inner-wizard-items-header" style={{ 'padding-bottom': '20px' }}>
        <span>
          Copy below AWS Cli Commands, and execute them from the directory you have placed the downloaded JSON files
        </span>
      </h4>
      <br />
    </Col>
    {cmdsToExecute.map((cmdToExecute) => (
      <Col className="cli-command-copy-wraper" xs={10} md={10} lg={10} xl={10}>
        <code>{cmdToExecute}</code>
      </Col>
    ))}
    <Col xs={12} md={12} lg={12} xl={12}>
      <hr />
      <h4 className="inner-wizard-items-header" style={{ 'padding-bottom': '20px' }}>
        <span>
          Enter the the Role ARN from the output of aws iam create-role cli command and click the Next button bellow
        </span>
      </h4>
      <br />
    </Col>
    <Col xs={6} md={6} lg={6} xl={6}>
      <Form onKeyPress={disableEnterKeySub} className="form">
        <div className="form__form-group-trim">
          <IconInputField
            icon="lnr lnr-cloud"
            handler={handleArnRoleNumChange}
            label="Role ARN"
            name="arnRoleNum"
            value={arnRoleNum}
            type="text"
            placeHolder="Enter your ARN"
          />
        </div>
      </Form>
    </Col>
    <Col sm={6} md={6} lg={6} xl={6}>
      <Row style={{ padding: '5px 5px', margin: '2px 0px 0px 2px', width: '70%' }} className="chart-box-style-light">
        <div style={{ width: '100%', textAlign: 'center' }}>How to find my ARN example</div>
        <img src={ARN_IMG} alt="arn" style={{ padding: '2px' }} />
      </Row>
    </Col>
  </Row>
));
ExecuteCliCommands.propTypes = {
  cmdsToExecute: PropTypes.arrayOf(PropTypes.shape).isRequired,
  disableEnterKeySub: PropTypes.func.isRequired,
  arnRoleNum: PropTypes.string.isRequired,
  handleArnRoleNumChange: PropTypes.func.isRequired,
};
export default ExecuteCliCommands;
