/* eslint-disable object-curly-newline */
/* eslint-disable react/sort-comp */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
import If from 'shared/components/SimpleIf';
import DownloadAsPngLink from 'shared/components/DownloadAsPngLink';
import PropTypes from 'prop-types';
import { random5DigitNumber } from 'shared/utils/mathUtil';
import CustomTooltip from 'shared/components/CustomTooltip';
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { StringToColor } from 'shared/utils/graphicUtil';
import { CostTrackingConstants, DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

class CostChart extends PureComponent {
  static propTypes = {
    chartId: PropTypes.string,
    data: PropTypes.object.isRequired,
    yAxisLabelTitle: PropTypes.string,
    xAxisLabelTitle: PropTypes.string,
    barClickHandler: PropTypes.func.isRequired,
    barClickable: PropTypes.bool.isRequired,
    showAllItems: PropTypes.bool.isRequired,
    displayedMetric: PropTypes.object.isRequired,
    isRenderDownloadAsPng: PropTypes.bool,
    dataKeys: PropTypes.object.isRequired,
    // requiered for trend line button
    // isRenderTrendLineBtn: PropTypes.bool,
    isTrendLine: PropTypes.bool,
    separateStackKeyName: PropTypes.string,
    getCurrencyNumber: PropTypes.func.isRequired,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
  };

  static defaultProps = {
    chartId: null,
    yAxisLabelTitle: null,
    xAxisLabelTitle: null,
    isTrendLine: false,
    isRenderDownloadAsPng: false,
    separateStackKeyName: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      currKey: '',
    };
  }

  localCostStrFromString = (strValue) => {
    const { getCurrencyNumber, displayedMetric } = this.props;
    if (displayedMetric === DisplayMetricTypes.COST) {
      return `${getCurrencyNumber(strValue)}`;
    }
    return strValue;
  };

  handleBarClick = (data, index, dataKey) => {
    if (this.props.barClickable && dataKey !== CostTrackingConstants.OTHERS) {
      const { usageDate } = data;
      this.props.barClickHandler(usageDate, dataKey);
    }
  };

  // handleShowTrendToggle = () => {
  //   this.setState({ isTrendLine: !this.state.isTrendLine });
  // };

  liStyle = (nameColor) => ({
    position: 'relative',
    display: 'inline-block',
    padding: '0.4rem 0.4rem',
    'margin-bottom': '-2px',
    'border-radius': '3px',
    border: '0.5px solid #e0e0e0',
    background: nameColor,
  });

  getStackId = (dataKeyName) => (dataKeyName === this.props.separateStackKeyName ? 'b' : 'a');

  // eslint-disable-next-line class-methods-use-this
  renderArea(dataKeys) {
    const { currKey } = this.state;
    const { barClickable } = this.props;
    return dataKeys.map((dataKey, index) => {
      const customMouseOver = (e) => {
        if (dataKey.name !== currKey) {
          this.setState({ currKey: dataKey.name });
        }
      };
      return (
        <Area
          cursor={barClickable ? 'pointer' : 'default'}
          dataKey={dataKey.name}
          stackId={this.getStackId(dataKey.name)}
          stroke={
            dataKey.name === CostTrackingConstants.OTHERS
              ? CostTrackingConstants.OTHERS_NAME_COLOR
              : StringToColor.next(dataKey.name)
          }
          fill={
            dataKey.name === CostTrackingConstants.OTHERS
              ? CostTrackingConstants.OTHERS_NAME_COLOR
              : StringToColor.next(dataKey.name)
          }
          onClick={(data, idx) => this.handleBarClick(data, idx, dataKey.name)}
          onMouseOver={customMouseOver}
          style={barClickable ? { cursor: 'pointer' } : null}
          type="monotone"
        />
      );
    });
  }

  renderBar(dataKeys) {
    const { currKey } = this.state;
    const { barClickable } = this.props;
    // eslint-disable-next-line arrow-body-style
    return dataKeys.map((dataKey, index) => {
      const customMouseOver = (e) => {
        if (dataKey.name !== currKey) {
          this.setState({ currKey: dataKey.name });
        }
      };
      return (
        <Bar
          cursor={barClickable ? 'pointer' : 'default'}
          barSize={28}
          dataKey={dataKey.name}
          stackId={this.getStackId(dataKey.name)}
          fill={
            dataKey.name === CostTrackingConstants.OTHERS
              ? CostTrackingConstants.OTHERS_NAME_COLOR
              : StringToColor.next(dataKey.name)
          }
          onClick={dataKey.name === 'others' ? null : (data, idx) => this.handleBarClick(data, idx, dataKey.name)}
          onMouseOver={customMouseOver}
          style={barClickable ? { cursor: 'pointer' } : null}
        />
      );
    });
  }

  renderLine(dataKeys) {
    const { currKey } = this.state;
    return dataKeys.map((dataKey, index) => {
      const customMouseOver = (e) => {
        if (dataKey.name !== currKey) {
          this.setState({ currKey: dataKey.name });
        }
      };
      return <Line type="monotone" dataKey={dataKey.name} stroke="#ff7300" />;
    });
  }

  render() {
    const {
      data,
      displayedMetric,
      isRenderDownloadAsPng,
      chartId,
      yAxisLabelTitle,
      dataKeys,
      xAxisLabelTitle,
      isTrendLine,
      showAllItems,
      numStrAbriviaionByDisplayMetric,
    } = this.props;
    const { currKey } = this.state;
    const finalChartId = chartId || `cost-chart-${random5DigitNumber()}`;

    return (
      <Container>
        <If cond={isRenderDownloadAsPng}>
          <DownloadAsPngLink chartId={finalChartId} />
        </If>
        <Row id={finalChartId} className="cost-chart-container">
          <ResponsiveContainer height={400}>
            <ComposedChart
              stackOffset="sign"
              width={1000}
              height={400}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis label={{ value: xAxisLabelTitle || '' }} dataKey="usageDate" />
              <YAxis
                tickLine
                label={{
                  value: yAxisLabelTitle || `${displayedMetric}`,
                  offset: 0,
                  angle: -90,
                  position: 'left',
                }}
                tickFormatter={(value) =>
                  numStrAbriviaionByDisplayMetric(value, value, displayedMetric, false, { decimal: 2 })
                }
              />
              <Tooltip
                cursor={false}
                content={
                  <CustomTooltip
                    showAllItems={showAllItems}
                    data={data}
                    currKey={currKey}
                    displayedMetric={displayedMetric}
                  />
                }
              />
              <Legend />
              <ReferenceLine y={0} stroke="#000" />
              {showAllItems ? this.renderArea(dataKeys) : this.renderBar(dataKeys)}
              {isTrendLine ? <Line type="linear" dataKey="totalSum" stroke="#ff7300" /> : null}
            </ComposedChart>
          </ResponsiveContainer>
        </Row>
      </Container>
    );
  }
}

const ObserverCostChart = withUserSettingsConsumer(CostChart);
export default ObserverCostChart;
