/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable comma-spacing, no-param-reassign, no-bitwise, no-mixed-operators, no-plusplus, no-restricted-globals */
import {
  containsLowerCase,
  containsNumber,
  containsSpecialChar,
  containsUpperCase,
  isEmailValid,
} from 'shared/utils/strUtil';
import {
  FULLNAME_VALIDATION_RESULT_STRINGS,
  INVALID_LINKED_ACCS,
  MINIMUM_PASSWORD_LENGTH,
  PASSWORD_VALIDATION_RESULT_STRINGS,
  USERNAME_VALIDATION_RESULT_STRINGS,
  UsersType,
} from 'users/constants/usersConstants';
import { mapNotAllowedPathToUsertType } from 'shared/constants/routesMapping';

export const isPathNotAllowedToUser = (path, userType) => {
  let result = true;
  const arrUnAllowedUser = mapNotAllowedPathToUsertType.get(path);
  if (!arrUnAllowedUser) {
    return result;
  }
  result = arrUnAllowedUser.includes(userType);
  return result;
};

export const isFullNameValid = (fullName) => {
  let validResult = true;
  const resultStrings = [];
  if (!fullName) {
    validResult = false;
    resultStrings.push(FULLNAME_VALIDATION_RESULT_STRINGS.EMPTY_FULLNAME);
  } else if (fullName.length === 0) {
    validResult = false;
    resultStrings.push(FULLNAME_VALIDATION_RESULT_STRINGS.EMPTY_FULLNAME);
  } else {
    const regExp = new RegExp(/^[A-Za-z- ]+$/);
    // const regExp = new RegExp(/^[a-z]([-']?[a-z]+)*( [a-z]([-']?[a-z]+)*)+$/i);
    validResult = regExp.test(fullName);
    if (!validResult) {
      resultStrings.push(FULLNAME_VALIDATION_RESULT_STRINGS.VERIFY_YOUR_FULLNAME);
    }
  }
  return {
    validResult,
    resultStrings,
  };
};

export const isUsernameValid = (username) => {
  let validResult = true;
  const resultStrings = [];

  if (!isEmailValid(username)) {
    validResult = false;
    resultStrings.push(USERNAME_VALIDATION_RESULT_STRINGS.INVALID_USERNAME);
  }

  return {
    validResult,
    resultStrings,
  };
};

export const isCvcLengthValid = (cardCvc) => {
  let validResult = true;
  let resultString = '';
  if (cardCvc.length < 3 || cardCvc.length > 4) {
    validResult = false;
    resultString = 'Invalid card CVC length';
  }

  return {
    validResult,
    resultString,
  };
};

export const isLinkedAccSelectsionValid = (linkedAccs) => {
  let validResult = true;
  let resultString = '';
  if (linkedAccs.length < 1) {
    validResult = false;
    resultString = INVALID_LINKED_ACCS;
  }

  return {
    validResult,
    resultString,
  };
};

export const isPasswordValid = (password) => {
  let validResult = true;
  const resultStrings = [];

  if (!containsSpecialChar(password)) {
    validResult = false;
    resultStrings.push(PASSWORD_VALIDATION_RESULT_STRINGS.INVALID_NO_SPECIAL_CHAR);
  }

  if (!containsLowerCase(password)) {
    validResult = false;
    resultStrings.push(PASSWORD_VALIDATION_RESULT_STRINGS.INVALID_NO_LOWERCASE_CHAR);
  }

  if (!containsUpperCase(password)) {
    validResult = false;
    resultStrings.push(PASSWORD_VALIDATION_RESULT_STRINGS.INVALID_NO_UPPERCASE_CHAR);
  }

  if (password.length <= MINIMUM_PASSWORD_LENGTH) {
    validResult = false;
    resultStrings.push(PASSWORD_VALIDATION_RESULT_STRINGS.INVALID_LENGTH);
  }

  if (!containsNumber(password)) {
    validResult = false;
    resultStrings.push(PASSWORD_VALIDATION_RESULT_STRINGS.INVALID_NO_NUMBER);
  }

  return {
    validResult,
    resultStrings,
  };
};

const hashCode = (s) =>
  s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
export const getStringHashKeyAndMap = (authToken) => {
  const hashKey = hashCode(authToken);
  const hashMap = new Map([[hashKey, authToken]]);
  return { hashKey, hashMap };
};

export const isUserCustomer = (userType) => [UsersType.RESELLER_CUSTOMER].includes(+userType);
export const isUserCustomerEDP = (userType) =>
  [UsersType.RESELLER_CUSTOMER, UsersType.RESELLER_CUSTOMER_EDP].includes(+userType);
