/* eslint-disable react/no-did-update-set-state */
/* eslint-disable arrow-parens */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     maxWidth: 360,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

// const classes = useStyles();

export default class CheckBoxItem extends PureComponent {
  static propTypes = {
    ContainerProps: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      checked: props.ContainerProps.checked,
      id: props.ContainerProps.id,
      name: props.ContainerProps.name,
    };
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.ContainerProps.checked !== this.props.ContainerProps.checked) {

    if (prevProps.ContainerProps !== this.props.ContainerProps) {
      const { checked, id, name } = this.props.ContainerProps;
      this.setState({ checked, id, name });
    }
  }

  onBoxCheck = (id) => () => {
    let { checked } = this.state;
    checked = !checked;
    this.setState({ checked }, () => {
      this.props.ContainerProps.handler(id);
    });
  };

  render() {
    // const { id, name, style } = this.props.ContainerProps;
    const { checked, id, name } = this.state;
    const labelId = `checkbox-list-label-${id}`;
    return (
      <ListItem key={id} role={undefined} dense button style={this.props.style} onClick={this.onBoxCheck(id)}>
        <ListItemIcon>
          <Checkbox
            id={id}
            edge="start"
            value="dense"
            color="primary"
            checked={checked}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={name} />
      </ListItem>
    );
  }
}
