/* eslint-disable max-len */
// S3 data for table
const prepareTableData = (rawData) => {
  const arrTableData = Object.values(rawData);
  const rows = arrTableData.map(
    ({
      linked_account_name: linkedAccountName,
      linked_account_id: linkedAccountId,
      bucket_name: bucketName,
      total_cost: cost,
      region,
      bucket_size_by_storage_type: bucketSizeByStorage,
      total_size: size,
      versioning,
      lifecycle,
      month,
      year,
    }) => ({
      linkedAccountName,
      linkedAccountId,
      bucketName,
      cost,
      region,
      bucketSizeByStorage,
      size,
      versioning,
      lifecycle,
      month,
      year,
    }),
  );
  return rows;
};

export default prepareTableData;
