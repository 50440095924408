import React from 'react';
import AccountStorageImage from './assets/AccountStorage.png';
import ContainerNameImage from './assets/ContainerName.png';
import DirectoryInfoImage from './assets/FilePartitioning.png';
import DirectoryPrefixImage from './assets/Directory.png';
import CurrencyTypeImage from '../AppRegPage/assets/Currency.jpg';
import CostTypeImage from './assets/Metric.png';
import commonStyles from '../Pages.module.scss';

const BillingExportPageHelp = {};

BillingExportPageHelp.accountStorage = {
  component: () => (
    <p className={commonStyles.helpText}>
      From the <span>Storage accounts</span> page, enter the <span>Name</span> of the storage account you created.
    </p>
  ),
  image: AccountStorageImage,
  imageHeight: 246,
};

BillingExportPageHelp.containerName = {
  component: () => (
    <p className={commonStyles.helpText}>
      Go to <span>Data storage &gt; Containers</span> and copy the <span>name of the container</span> that holds your
      export.
    </p>
  ),
  image: ContainerNameImage,
  imageHeight: 246,
};

BillingExportPageHelp.directoryInfo = {
  component: () => (
    <div className={commonStyles.helpText}>
      <p>
        <span>Choose Shared Container or File Partitioning:</span>
      </p>
      <br />
      <ol>
        <li>
          Shared container: Applicable when multiple exports have been configured on the same container (either using
          different profiles, scopes, or export configurations).
        </li>
        <li>
          File Partitioning: While creating the export for Anodot you enabled the toggle that will organize your file
          into multiple smaller file partitions
        </li>
      </ol>
    </div>
  ),
  image: DirectoryInfoImage,
  imageHeight: 120,
};

BillingExportPageHelp.directoryPrefix = {
  component: () => (
    <p className={commonStyles.helpText}>
      Enter the <span>directory field</span> you defined for the export.
    </p>
  ),
  image: DirectoryPrefixImage,
  imageHeight: 267,
};

BillingExportPageHelp.costType = {
  component: () => (
    <p className={commonStyles.helpText}>
      Choose the export <span>cost type</span> you selected while creating the export for Anodot.
    </p>
  ),
  image: CostTypeImage,
  imageHeight: 246,
};

BillingExportPageHelp.currencyType = {
  component: () => (
    <div className={commonStyles.helpText}>
      <p>
        From Azure, go to <span>Cost Management + Billing &gt; Billing profiles</span> to see <span>currency type</span>
        .
      </p>
      <p className={commonStyles.notice}>Notice:</p>
      <ol>
        <li>Once selected the currency can’t be updated by the user</li>
        <li>If currency is not USD, `All accounts` won’t be supported for this account.</li>
        <li>If currency is not USD, ‘Multi-cloud’ won’t be supported for this account.</li>
      </ol>
    </div>
  ),
  image: CurrencyTypeImage,
  imageHeight: 120,
};

export default BillingExportPageHelp;
