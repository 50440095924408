import { palette } from 'shared/constants/colorsConstants';

export const Y_AXIS_UNIT = {
  PERCENT: 'percent',
  BYTE: 'byte',
  MB: 'mb',
  OTHER: 'other',
};

export const CHART_LINE_COLORS = {
  LILAC: { color: palette.lilach[500] },
  TEAL: { color: palette.teal[500] },
  YELLOW: { color: palette.yellow[500] },
  BLUE: { color: palette.blue[450] },
  ROSE: { color: palette.rose[400] },
  PURPLE: { color: palette.purple[500] },
  LILAC_LIGHT: { color: palette.lilach[400] },
  LIME: { color: palette.lime[500] },
};
