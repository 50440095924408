import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
// eslint-disable-next-line max-len
import { ThreeStateSwitchButton } from 'recommendationsNew/components/heatMap/heatMapFilters/additionalFilters/threeStateSwitchButton';
import { getSwitchButtons, SWITCH_BUTTON_STATUSES } from 'recommendationsNew/consts';

import classes from './additionalFilters.module.scss';

export const SwitchButtonsContainer = ({
  onFiltersChange,
  filtersSearchString,
  isOpen,
  isStarred,
  isExcluded,
  isDone,
}) => {
  const threeStateSwitchButtons = getSwitchButtons(isOpen, isDone, isExcluded, isStarred);

  const displayedSwitchButtons = useMemo(
    () =>
      Object.values(threeStateSwitchButtons)?.filter((option) =>
        option.switchLabel?.toLowerCase().includes(filtersSearchString?.toLowerCase()),
      ),
    [filtersSearchString, threeStateSwitchButtons],
  );

  const onChangeState = (event) => {
    // eslint-disable-next-line no-nested-ternary
    const filterValue = [
      SWITCH_BUTTON_STATUSES.OPEN,
      SWITCH_BUTTON_STATUSES.DONE,
      SWITCH_BUTTON_STATUSES.EXCLUDED,
      SWITCH_BUTTON_STATUSES.STARRED,
    ].includes(event.option)
      ? true
      : [
          SWITCH_BUTTON_STATUSES.COMPLETED,
          SWITCH_BUTTON_STATUSES.UN_DONE,
          SWITCH_BUTTON_STATUSES.NOT_EXCLUDED,
          SWITCH_BUTTON_STATUSES.NOT_STARRED,
        ].includes(event.option)
      ? false
      : null;
    onFiltersChange({ ...event, filterValue });
  };

  return (
    <div className={classes.switchButtonsContainer}>
      {Object.values(displayedSwitchButtons).map((switchButton) => (
        <ThreeStateSwitchButton
          automation-id={`heatmap-${switchButton.switchLabel}-switch-button-filter`}
          switchLabel={switchButton.switchLabel}
          infoIconTooltipText={switchButton.infoIconTooltipText}
          changeState={(event) => {
            const eventData = { ...event, filter: switchButton.switchLabel };
            return onChangeState(eventData);
          }}
          optionsList={Object.values(switchButton.optionsList)}
        />
      ))}
    </div>
  );
};

SwitchButtonsContainer.propTypes = {
  filtersSearchString: PropTypes.string,
  onFiltersChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isStarred: PropTypes.bool,
  isExcluded: PropTypes.bool,
  isDone: PropTypes.bool,
};

SwitchButtonsContainer.defaultProps = {
  filtersSearchString: '',
  isOpen: true,
  isStarred: null,
  isExcluded: false,
  isDone: false,
};
