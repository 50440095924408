/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-empty */
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Collapse, Container, Form, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import IconInputTextArea from 'shared/components/IconInputTextArea';
import IconInputField from 'shared/components/IconInputField';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import LoaderButton from 'shared/components/LoaderButton';
import Spinner from 'shared/components/andtComponents/Spinner';

class ApiAccessKey extends Component {
  static propTypes = {
    usersStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      apiAccessKey: '',
      apiAccessKeyEffectiveDate: '',
      collapse: false,
      isLoading: false,
      isInitialLoad: true,
      apiAccessKeyInputType: 'password',
    };
  }

  async componentDidMount() {
    // this.setState({ isLoading: true });
    if (this.state.isInitialLoad) {
      try {
        await this.props.usersStore.getAzureEaApiAccessKeyData();
        this.setState({ isInitialLoad: false });
      } catch (error) {}
    }
  }

  disableEnterKeySub = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  handleEaConfigInputsChange = (newInput, e) => {
    if (!e) {
      return;
    }
    const currState = { ...this.state };
    this.setState({ [e.target.name]: newInput, isSaveDataError: false });
    currState[e.target.name] = newInput;
    if (currState.apiAccessKey.length > 0 && currState.apiAccessKeyEffectiveDate.length > 0) {
      this.setState({ showNoInputValueWarning: false, showGoToNextAlert: true });
    }
  };

  handleRenewApiAccessKey = async () => {
    if (this.state.apiAccessKey.length && this.state.apiAccessKeyEffectiveDate.length) {
      this.setState({ isLoading: true });
      const result = await this.props.usersStore.usersModel.updateAzureEaApiAccessKeyData(
        this.state.apiAccessKey,
        this.state.apiAccessKeyEffectiveDate,
      );
      if (result) {
        this.props.usersStore.usersModel.azureEaApiAccessKeyData = {
          apiAccessKey: this.state.apiAccessKey.slice(this.state.apiAccessKey.length - 4),
          effectiveDate: this.state.apiAccessKeyEffectiveDate,
        };
      }
      setTimeout(() => this.setState({ isLoading: false, apiAccessKey: '', apiAccessKeyEffectiveDate: '' }), 3000);
    }
  };

  handleChangeClientSecretInputType = (e) => {
    e.preventDefault();
    const { apiAccessKeyInputType } = this.state;
    const newApiAccessKeyInputType = apiAccessKeyInputType === 'password' ? 'text' : 'password';
    this.setState({ apiAccessKeyInputType: newApiAccessKeyInputType });
  };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { apiAccessKeyInputType } = this.state;
    if (this.state.isInitialLoad) {
      return <Spinner />;
    }
    return (
      <Container className="api-access-key">
        <table>
          <tr>
            <td>API Access Key:</td>
            <td>
              xxxx-xxxx-xxxx-xxxx-xxxx-
              {this.props.usersStore.usersModel.azureEaApiAccessKeyData.apiAccessKey}
            </td>
          </tr>
          <tr>
            <td>Effective Date:</td>
            <td>{this.props.usersStore.usersModel.azureEaApiAccessKeyData.effectiveDate}</td>
          </tr>
        </table>
        <div className="d-flex align-items-center">
          {this.state.collapse ? (
            <MinusIcon onClick={this.toggle} className="collapse-icon" />
          ) : (
            <PlusIcon onClick={this.toggle} className="collapse-icon" />
          )}
          <h5 onClick={this.toggle} className="collapse-text">
            Renew API Access Key
          </h5>
        </div>
        <Collapse isOpen={this.state.collapse} className="sidebar__submenu-wrap">
          <Form onKeyPress={this.disableEnterKeySub} className="form">
            <div className="form__form-group">
              <div className="form__form-group">
                <IconInputField
                  icon="lnr lnr-cloud"
                  handler={this.handleEaConfigInputsChange}
                  label="API Access Key"
                  name="apiAccessKey"
                  value={this.state.apiAccessKey}
                  type={apiAccessKeyInputType}
                  handleChangeInputType={this.handleChangeClientSecretInputType}
                  isPassword
                  maxLength={999}
                  placeHolder="Enter your API Access Key"
                />
              </div>
              <div className="form__form-group">
                <IconInputField
                  icon="lnr lnr-cloud"
                  handler={this.handleEaConfigInputsChange}
                  label="API Access Key Effective Date"
                  name="apiAccessKeyEffectiveDate"
                  value={this.state.apiAccessKeyEffectiveDate}
                  type="date"
                  placeHolder="Enter last effective date"
                />
              </div>
            </div>
            <div className="renew-submit-container">
              <div className="renew-submit-btn">
                <LoaderButton
                  onClick={this.handleRenewApiAccessKey}
                  block
                  size="md"
                  isLoading={this.state.isLoading}
                  text="Renew"
                  loadingText="Renewing..."
                />
              </div>
            </div>
          </Form>
        </Collapse>
      </Container>
    );
  }
}

const ObserverApiAccessKey = observer(ApiAccessKey);
export default ObserverApiAccessKey;
