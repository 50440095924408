/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style,arrow-parens,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  RowDetailState,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import { alpha } from '@mui/system/colorManipulator';
import {
  Grid,
  TableHeaderRow,
  TableRowDetail,
  SearchPanel,
  Toolbar,
  TableColumnResizing,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@mui/styles';
import { strNumToSize } from 'shared/utils/strUtil';
import { Progress } from 'reactstrap';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import TableWrapper from 'shared/components/tables/TableWrapper';
import BudgetActionButton from './BudgetActionButton';
import BudgetDisplay from './BudgetDisplay';

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    fontSize: '25.5vw',
  },
});

class BudgetsTable extends Component {
  static propTypes = {
    budgets: PropTypes.object.isRequired,
    tableColumns: PropTypes.object.isRequired,
    columnWidths: PropTypes.object.isRequired,
    budgetHandlers: PropTypes.object.isRequired,
    currentUserReadOnly: PropTypes.bool.isRequired,
    usageStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentDisplayedPeriods: {},
      columnWidths: this.props.columnWidths,
      pageSizes: [10, 20, 50],
    };
  }

  setExpandedRowIds = (expandedRowIds) => {
    this.setState({ expandedRowIds });
  };

  closeExpanded = () => {
    const emptyExpanded = [];
    this.setState({ expandedRowIds: emptyExpanded });
  };

  bytesSizeFormatter = (value) => strNumToSize(value.value);

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  render() {
    const { budgets, tableColumns, budgetHandlers, usageStore } = this.props;
    const { columnWidths } = this.state;
    const RowDetail = ({ row }) => (
      <div style={{ padding: '10px', width: '60vw' }}>
        <BudgetDisplay budget={row} currMonthlyUsage={row.currTotalCost} usageStore={usageStore} />
      </div>
    );
    const userActionsFormatter = (data) => {
      withStyles(data);
      return (
        <ReadOnlyDisplayWrapper userReadOnly={this.props.currentUserReadOnly}>
          <BudgetActionButton budget={data.row} budgetHandlers={budgetHandlers} />
        </ReadOnlyDisplayWrapper>
      );
    };

    const budgetStatusFormatter = (data) => {
      withStyles(data);
      return (
        <div>
          <div className="text-center">{`${Math.round((data.row.currTotalCost / data.row.budgetAmount) * 100)}%`}</div>
          <Progress
            value={(data.row.currTotalCost / data.row.budgetAmount) * 100}
            color={(data.row.currTotalCost / data.row.budgetAmount) * 100 > 100 ? 'budget-red' : 'budget-blue'}
          />
        </div>
      );
    };

    const budgetForcastedFormatter = (data) => {
      withStyles(data);
      return (
        <div>
          <div className="text-center">{`${Math.round((data.row.totalForcasted / data.row.budgetAmount) * 100)}%`}</div>
          <Progress
            value={(data.row.totalForcasted / data.row.budgetAmount) * 100}
            color={(data.row.totalForcasted / data.row.budgetAmount) * 100 > 100 ? 'budget-red' : 'budget-blue'}
          />
        </div>
      );
    };

    RowDetail.propTypes = {
      row: PropTypes.object.isRequired,
    };
    return (
      <div className="card">
        <Grid rows={budgets} columns={tableColumns}>
          <SearchState />
          <IntegratedFiltering />
          <PagingState defaultCurrentPage={0} defaultPageSize={this.state.pageSizes[1]} />
          <IntegratedPaging />
          <RowDetailState expandedRowIds={this.state.expandedRowIds} onExpandedRowIdsChange={this.setExpandedRowIds} />
          <DataTypeProvider for={['budgetStatus']} formatterComponent={budgetStatusFormatter} />
          <DataTypeProvider for={['forcastedStatus']} formatterComponent={budgetForcastedFormatter} />
          <DataTypeProvider for={['userAction']} formatterComponent={userActionsFormatter} />
          <TableWrapper styles={styles} />
          <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
          <TableHeaderRow />
          <Toolbar />
          <SearchPanel />
          <TableRowDetail contentComponent={RowDetail} />
          <PagingPanel pageSizes={this.state.pageSizes} />
        </Grid>
      </div>
    );
  }
}

const ObserverBudgetsTable = observer(BudgetsTable);
export default ObserverBudgetsTable;
