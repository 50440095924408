/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const handleSelect = (event, handler, placeHolder, mapValuesDescToValues) => {
  const getValue = event.target.value;
  const validatedValue = mapValuesDescToValues && mapValuesDescToValues.get(getValue) ? getValue : '';
  handler(validatedValue);
};

const renderList = (selectedValue, arrValues, placeHolder, mapRegionNamesToDescription) =>
  arrValues.map((val, indx) => {
    const value = val === placeHolder ? '' : val;
    const displayedVal = val === placeHolder ? val : mapRegionNamesToDescription.get(val);
    return (
      <option selected={val === selectedValue} value={value}>
        {displayedVal}
      </option>
    );
  });

const IconSelectField = memo(
  ({
    handler,
    label,
    name,
    value,
    type = 'text',
    placeHolder,
    icon,
    arrValues,
    mapRegionNamesToDescription,
    list,
    ...rest
  }) => {
    arrValues.unshift(placeHolder);
    return (
      <div>
        <span className="inner-wizard-input-legend">{label}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <span className={icon} />
          </div>
          <select
            style={{ border: '1px solid #f2f4f7', color: '#646777', width: '100%', backgroundColor: 'transparent' }}
            name={`${name}`}
            onChange={(event) => handleSelect(event, handler, placeHolder, mapRegionNamesToDescription)}
          >
            {renderList(value, arrValues, placeHolder, mapRegionNamesToDescription)}
          </select>
        </div>
      </div>
    );
  },
);

export default IconSelectField;

IconSelectField.propTypes = {
  handler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  list: PropTypes.string.isRequired,
  mapRegionNamesToDescription: PropTypes.object.isRequired,
  arrValues: PropTypes.array.isRequired,
};
