import React from 'react';
import { ReactComponent as NoAlert } from 'shared/img/icons/no-alert.svg';
import classes from './budgetModal.module.scss';

const ComingSoonAlert = ({ customHeader }) => (
  <div className={classes.noAlertWrapper}>
    <div className={classes.content}>
      <NoAlert />
      <span>{customHeader}</span>
      <h4 className={classes.header}>Coming Soon!</h4>
    </div>
  </div>
);

export default ComingSoonAlert;
