import Select from 'react-select';
import React from 'react';
import PropTypes from 'prop-types';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import { filterOptions, getCurrenciesSymbol } from './currencySymbolsHelper';

const menuWidth = '100%';
const style = {
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? 'rgba(38, 113, 255, 0.3)' : 'transparent',
    backgroundColor: '#E9E9F0FF',
    borderWidth: '2px',
    boxShadow: 'unset',
  }),
  container: (baseStyle) => ({
    ...baseStyle,
    flexGrow: 1,
  }),

  menu: (baseStyle) => ({
    ...baseStyle,
    width: menuWidth,
    'max-width': menuWidth,
  }),
  option: (baseStyle) => ({
    ...baseStyle,
    width: menuWidth,
    maxWidth: menuWidth,
  }),
  multiValue: (baseStyle) => ({
    ...baseStyle,
    backgroundColor: '#B5BBC3',
    alignItems: 'center',
    borderRadius: '6px',
  }),
};

const tooltipText =
  'Please note the following:\n1. Once selected the currency can’t be updated by the user.\n ' +
  "2.'All accounts' won’t be supported for this account.\n 3. ‘Multi-cloud’ won’t be supported for this account.\n ";
export const CurrenciesSelect = ({ usersStore, currencySymbol, handleSelectCurrency }) =>
  checkFeatureFlag(usersStore, ACCOUNT_FEATURES.ENABLE_MULTI_CURRENCY) && (
    <div className="form__form-group">
      <span className="inner-wizard-input-legend">Currency Type (optional)</span>
      <Tooltip title={tooltipText} arrow className="toolTip">
        <span>
          <InfoIcon />
        </span>
      </Tooltip>
      <Select
        style={style}
        value={currencySymbol}
        options={getCurrenciesSymbol()}
        menuPortalTarget={document.querySelector('body')}
        placeholder="USD ($)- Default"
        filterOption={filterOptions}
        isClearable
        onChange={(selectedOptions) => {
          handleSelectCurrency(selectedOptions);
        }}
        getOptionLabel={(option) => `${option.key} (${option.value})`}
      />
      <span>
        {'You can find some more information in the following link: '}
        <a href="https://cloudcost.anodot.com/hc/en-us/articles/9719238564124" target="_blank" rel="noreferrer">
          Setting Non-USD Currencies for Accounts
        </a>
      </span>
    </div>
  );

CurrenciesSelect.propTypes = {
  usersStore: PropTypes.object.isRequired,
  currencySymbol: PropTypes.object,
  handleSelectCurrency: PropTypes.func.isRequired,
};

CurrenciesSelect.defaultProps = {
  currencySymbol: null,
};
