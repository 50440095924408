/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable  no-param-reassign */
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  CostTrackingConstants,
  CostUsageStates,
  K8S_GROUP_BY_LOV,
  K8sGroupByLovToAttributes,
  mapCostUsageStateToAttributes,
  numOfCUStates,
} from 'usage/constants/costAndUsageConstants';
import { FilterTypes } from 'usage/constants/usageConstants';
import { buildFilterParams, buildWhereParams, convertObjToMap, getIncludeExcludeMaps } from 'shared/utils/apiUtil';
import {
  alignedStartEndDateByGranLevel,
  buildStartAndEndDate,
  createTimeZoneAgnosticDateFromStr,
  replaceDateByGranularity,
} from 'shared/utils/dateUtil';
import { K8S_QUANTITY_TYPE_SELECT, K8sDisplayCostTypes } from 'shared/constants/k8sConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';

export default class KubernetesModel {
  constructor(apiGateway) {
    this.apiGateway = apiGateway;
    this.costBreakDownData = [];
    this.weightsData = [];
    this.counters = null;
    this.dataStates = [...new Array(numOfCUStates)].map(() => ({}));
    this.isLoading = false;
    this.initialFetchCostBreakDownDataDone = false;
    this.isCostAndUsageLoading = false;
    this.isCostBreakLoading = false;
    this.currDataState = CostUsageStates.CLUSTER;
    this.currentGroupBy = K8sGroupByLovToAttributes.get(K8S_GROUP_BY_LOV.BY_CLUSTER);
    this.podGeneralData = null;
    this.dataTooBigErrorMessage = null;
    makeObservable(this, {
      fetchK8ScostAndUsage: action,
      costBreakDownData: observable,
      counters: observable,
      dataStates: observable,
      isLoading: observable,
      // isWeightsLoading: observable,
      // initialFetchDone: observable,
      initialFetchCostBreakDownDataDone: observable,
      weightsData: observable,
      isCostAndUsageLoading: observable,
      isCostBreakLoading: observable,
      currDataState: observable,
      currentGroupBy: observable,
      dataTooBigErrorMessage: observable,
    });
  }

  defaultDates = () => {
    const { startDate, endDate } = buildStartAndEndDate(null, null);
    return { startDate, endDate };
  };

  buildStartAndEndDateInAgnosticDateFromStr = () => {
    let { startDate, endDate } = buildStartAndEndDate('', '');
    startDate = createTimeZoneAgnosticDateFromStr(startDate);
    endDate = createTimeZoneAgnosticDateFromStr(endDate);
    return { startDate, endDate };
  };

  invalidateData = () => {
    this.costBreakDownData = [];
    this.weightsData = [];
    this.counters = null;
    this.dataStates = [...new Array(numOfCUStates)].map(() => ({}));
    this.isLoading = false;
    this.isWeightsLoading = false;
    this.initialFetchDone = false;
    this.initialFetchCostBreakDownDataDone = false;
    this.isCostAndUsageLoading = false;
    this.isCostBreakLoading = false;
    this.currDataState = CostUsageStates.CLUSTER;
    this.currentGroupBy = K8sGroupByLovToAttributes.get(K8S_GROUP_BY_LOV.BY_CLUSTER);
    this.podGeneralData = null;
    this.dataTooBigErrorMessage = null;
  };

  fetchCostBreakDownData = async (startDate, endDate) => {
    this.isLoading = true;
    this.isCostBreakLoading = true;
    let costBreakDownData = null;
    try {
      const defaultGroupBy = CostUsageStates.CLUSTER;
      costBreakDownData = await this.apiGateway.getCostBreakDownData(startDate, endDate, defaultGroupBy);
      this.getWeightsData();
      runInAction(() => {
        this.costBreakDownData = costBreakDownData;
        this.isLoading = false;
        this.isCostBreakLoading = false;
        this.initialFetchCostBreakDownDataDone = true;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
        this.initialFetchCostBreakDownDataDone = false;
        this.costBreakDownData = [];
        this.isLoading = false;
        this.isCostBreakLoading = false;
      });
    }
  };

  fetchK8ScostAndUsage = async (
    secondaryGroupByLevel = 'none',
    groupByValue,
    whereParamsMap,
    start,
    end,
    periodGranLevel,
    filtersMap,
    currCostType = [K8sDisplayCostTypes.COMPUTE, K8sDisplayCostTypes.DATA_TRANSFER, K8sDisplayCostTypes.STORAGE],
    currDataState,
    selectedUsageType = K8S_QUANTITY_TYPE_SELECT,
    selectedUsageTypeMetric = null,
    excludedFiltersStatusMap = new Map(),
    likedFiltersStatus,
    isNetUnblended,
    isShowAmortizeCost,
    isNetAmortize,
    lastProcessTime = '',
    panelId,
    dashboardId,
    wasteCostAllocationChecked,
    k8SGranularity,
  ) => {
    start = replaceDateByGranularity(start, periodGranLevel, true, lastProcessTime);
    end = replaceDateByGranularity(end, periodGranLevel, false, lastProcessTime);
    const { startDate, endDate } = buildStartAndEndDate(start, end);
    const { includeFiltersMap, excludeFiltersMap, likeFiltersStatus } = getIncludeExcludeMaps(
      filtersMap,
      convertObjToMap(excludedFiltersStatusMap),
      likedFiltersStatus,
    );
    const filterParams = buildFilterParams(includeFiltersMap);
    const excludeFilterParams = buildFilterParams(excludeFiltersMap, FilterTypes.EXCLUDE);
    const likeFilterParams = buildFilterParams(convertObjToMap(likeFiltersStatus), FilterTypes.LIKE);
    const whereParams = buildWhereParams(whereParamsMap);
    const arrCostType = Array.isArray(currCostType) ? [...currCostType] : [currCostType];
    const secondaryGroupBy = secondaryGroupByLevel !== 'none' ? secondaryGroupByLevel : false;
    this.currDataState = currDataState;
    this.dataTooBigErrorMessage = null;
    this.currentGroupBy = mapCostUsageStateToAttributes.get(currDataState);
    const { start: startAligned, end: endAligned } = alignedStartEndDateByGranLevel(
      startDate,
      endDate,
      periodGranLevel,
    );
    try {
      this.isCostAndUsageLoading = true;
      let k8sCurrGroupByBalance = await this.apiGateway.getK8sCostAndUsage(
        groupByValue,
        secondaryGroupBy,
        whereParams,
        startAligned,
        endAligned,
        periodGranLevel,
        filterParams,
        arrCostType,
        selectedUsageType,
        selectedUsageTypeMetric,
        excludeFilterParams,
        likeFilterParams,
        isNetUnblended,
        isShowAmortizeCost,
        isNetAmortize,
        panelId,
        dashboardId,
        wasteCostAllocationChecked,
        k8SGranularity,
      );
      if (groupByValue === AwsCommonFields.LINKED_ACCOUNT_ID || groupByValue === AwsCommonFields.LINKED_ACCOUNT_NAME) {
        k8sCurrGroupByBalance = k8sCurrGroupByBalance.map((dailyBalance) => {
          const groupBy =
            dailyBalance.linkedAccountName && dailyBalance.linkedAccountId
              ? `${dailyBalance.linkedAccountName} (${dailyBalance.linkedAccountId})`
              : dailyBalance.groupBy;
          return {
            ...dailyBalance,
            groupBy,
          };
        });
      } else if (
        secondaryGroupBy === AwsCommonFields.LINKED_ACCOUNT_ID ||
        secondaryGroupBy === AwsCommonFields.LINKED_ACCOUNT_NAME
      ) {
        k8sCurrGroupByBalance = k8sCurrGroupByBalance.map((dailyBalance) => {
          const groupBySecondary =
            dailyBalance.linkedAccountName && dailyBalance.linkedAccountId
              ? `${dailyBalance.linkedAccountName} (${dailyBalance.linkedAccountId})`
              : dailyBalance.group_by_secondary;
          return {
            ...dailyBalance,
            groupBySecondary,
          };
        });
      }
      runInAction(() => {
        this.dataStates[currDataState] = k8sCurrGroupByBalance;
        this.isCostAndUsageLoading = false;
      });
      return k8sCurrGroupByBalance;
    } catch (error) {
      console.log(error);
      runInAction(() => {
        const { response } = error;
        if (response && response.status === 403 && response.data.errorCode === 'DATA_TOO_BIG') {
          this.dataTooBigErrorMessage = response.data.clientMessage;
        }
        this.isCostAndUsageLoading = false;
        this.dataStates[currDataState] = [];
        this.state = 'error';
        return [];
      });
    }
  };
  getK8sPodData = async (start, end, clusterName = null, nodeName = null, podName = null, ...other) => {
    try {
      this.isCostAndUsageLoading = true;
      const { startDate, endDate } = buildStartAndEndDate(start, end);
      this.currDataState = CostUsageStates.POD_DETAILS;
      const { podUsage, podGeneralData } = await this.apiGateway.getK8sPodData(
        startDate,
        endDate,
        clusterName,
        nodeName,
        podName,
        ...other,
      );
      runInAction(() => {
        this.dataStates[CostUsageStates.POD_DETAILS] = podUsage;
        this.podGeneralData = podGeneralData;
        this.isCostAndUsageLoading = false;
      });
      return podUsage;
    } catch (error) {
      console.log(error);
      this.isCostAndUsageLoading = false;
      this.dataStates[CostUsageStates.POD_DETAILS] = [];
      this.state = 'error';
      return [];
    }
  };
  weightIndexByEntryId = (id) => this.weightsData.findIndex((weightData) => weightData.id === id);
  getCostBreakDownData = async (startDate, endDate, groupBy) => {
    if (this.isCostBreakLoading) {
      return;
    }
    try {
      this.isCostBreakLoading = true;
      const costBreakDownData = await this.apiGateway.getCostBreakDownData(startDate, endDate, groupBy);
      runInAction(() => {
        this.costBreakDownData = costBreakDownData;
        this.isCostBreakLoading = false;
      });
      return this.costBreakDownData;
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
        this.isCostBreakLoading = false;
      });
    }
    return this.costBreakDownData;
  };
  getWeightsData = async () => {
    if (this.isWeightsLoading) {
      return;
    }
    if (this.weightsData.length) {
      return this.weightsData;
    }
    try {
      this.isWeightsLoading = true;
      const weightsData = await this.apiGateway.getWeightsData();
      runInAction(() => {
        this.weightsData = weightsData;
        this.isWeightsLoading = false;
      });
      return this.weightsData;
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
        this.isWeightsLoading = false;
      });
    }
    return this.weightsData;
  };
  addWeightsData = async (weights) => {
    try {
      const result = await this.apiGateway.addWeightsData(weights);
      runInAction(() => {
        this.weightsData = [...this.weightsData, result];
      });
    } catch (error) {
      this.state = 'error';
      this.weightsData = [...this.weightsData];
    }
  };
  deleteOrReplaceWeightsLocally = (id, newWeight) => {
    const indx = this.weightIndexByEntryId(id);
    if (indx > -1) {
      if (newWeight) {
        if (Array.isArray(newWeight)) {
          const newWeightsLength = newWeight.length;
          for (let index = 0; index < newWeightsLength; index++) {
            this.weightsData.splice(indx + index, !index ? 1 : 0, newWeight[index]);
          }
        } else {
          this.weightsData.splice(indx, 1, newWeight);
        }
      } else {
        this.weightsData.splice(indx, 1);
      }
    }
  };
  deleteWeightsData = async (id) => {
    try {
      await this.apiGateway.deleteWeightsData(id);
      this.deleteOrReplaceWeightsLocally(id);
    } catch (error) {
      this.state = 'error';
    }
  };
  updateWeightsData = async (currEditedId, newCreditsParams) => {
    try {
      const { accountId } = this.weightsData.find((data) => data.id === currEditedId);
      const newWeightsObject = { accountId, ...newCreditsParams };
      const newWeightsObjectResult = await this.apiGateway.updateWeightsData(currEditedId, newWeightsObject);
      runInAction(() => {
        this.deleteOrReplaceWeightsLocally(currEditedId, newWeightsObjectResult);
      });
    } catch (error) {
      this.state = 'error';
    }
  };
}
