import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';

export class K8sCommonFields {
  static REGION = 'region';
  static LINKED_ACCOUNT_NAME = 'linkedaccname';
  static LINKED_ACCOUNT_ID = 'linkedaccid';
  static ENVIRONMENT = 'environment';
  static PROJECT = 'project';
  static DEVELOPMENT = 'development';
  static PRODUCTION = 'production';
  static STAGING = 'staging';
  static TESTING = 'testing';
  static CLUSTER = 'cluster';
  static POD = 'pod';
  static INSTANCE_TYPE = 'instancetype';
  static USAGE_DATE = 'usagedate';
  static GROUP_BY = 'groupby';
  static TOTAL_COST = 'totalcost';
  static TOTAL_USAGE = 'totalhours';
  static NODE = 'node';
  static QUANTITY_TYPE = 'quantitytype';
  static OS = 'os';
  static K8S_CUSTOM_TAGS = 'k8scustomtags';
  static K8S_CUSTOM_TAGS_KEYS = 'k8scustomtags_keys';
  static PURCHASE_OPTION = 'purchaseoption';
  static NAMESPACE = 'namespace';
  static NODE_GROUP = 'nodegroup';
  static DEPLOYMENT = 'deployment';
  static NONE = 'none';
  static PAYER_ACCOUNT = 'payeraccount';
  static PAYER_ACCOUNT_NAME = 'accountName';
  static LABELS = 'labels';
}

export const k8sGranLevel = {
  NODES: 'NODES',
  PODS: 'PODS',
};

export const k8sCommonFieldToDisplayField = new Map([
  [K8sCommonFields.NONE, '- None -'],
  [K8sCommonFields.REGION, 'Region'],
  [K8sCommonFields.LINKED_ACCOUNT_NAME, 'Linked Account'],
  [K8sCommonFields.LINKED_ACCOUNT_ID, 'Linked Account'],
  [K8sCommonFields.ENVIRONMENT, 'Environments'],
  [K8sCommonFields.PROJECT, 'Projects'],
  [K8sCommonFields.DEVELOPMENT, 'Development'],
  [K8sCommonFields.PRODUCTION, 'Production'],
  [K8sCommonFields.STAGING, 'Staging'],
  [K8sCommonFields.TESTING, 'Testing'],
  [K8sCommonFields.CLUSTER, 'Cluster'],
  [K8sCommonFields.POD, 'Pod'],
  [K8sCommonFields.INSTANCE_TYPE, 'Instance Type'],
  [K8sCommonFields.NODE, 'Node'],
  [K8sCommonFields.QUANTITY_TYPE, 'Quantity Type'],
  [K8sCommonFields.OS, 'Operation System'],
  [K8sCommonFields.PURCHASE_OPTION, 'Purchase Option'],
  [K8sCommonFields.USAGE_DATE, 'Date'],
  [K8sCommonFields.NAMESPACE, 'Namespace'],
  [K8sCommonFields.NODE_GROUP, 'Node Group'],
  [K8sCommonFields.DEPLOYMENT, 'Deployment'],
  [K8sCommonFields.K8S_CUSTOM_TAGS, 'Labels'],
  [K8sCommonFields.PAYER_ACCOUNT, 'Payer Account'],
  [K8sCommonFields.PAYER_ACCOUNT_NAME, 'Payer Account Name'],
]);

export const k8sFieldToDisplayByCloudType = (cloudType) =>
  ({
    [CLOUD_TYPE_IDS.AZURE]: new Map([
      ...k8sCommonFieldToDisplayField,
      [K8sCommonFields.LINKED_ACCOUNT_ID, 'Subscription'],
    ]),
    [CLOUD_TYPE_IDS.AWS]: k8sCommonFieldToDisplayField,
    [CLOUD_TYPE_IDS.GCP]: k8sCommonFieldToDisplayField,
  }[cloudType]);

export const K8S_QUANTITY_TYPE_SELECT = 'Usage';

export class K8sCostTypes {
  static COMPUTE = 'compute';
  static DATA_TRANSFER = 'dataTransfer';
  static STORAGE = 'storage';
  static NETWORK = 'network';
}

export class K8sDisplayCostTypes {
  static COMPUTE = 'Compute';
  static DATA_TRANSFER = 'Data Transfer';
  static STORAGE = 'Storage';
  static NETWORK = 'Network';
}

export const mapK8sCostTypesToDisplay = new Map([
  [K8sCostTypes.COMPUTE, K8sDisplayCostTypes.COMPUTE],
  [K8sCostTypes.DATA_TRANSFER, K8sDisplayCostTypes.DATA_TRANSFER],
  [K8sCostTypes.STORAGE, K8sDisplayCostTypes.STORAGE],
  [K8sCostTypes.NETWORK, K8sDisplayCostTypes.NETWORK],
]);

export const mapK8sDisplayCostTypesToTypes = new Map([
  [K8sDisplayCostTypes.COMPUTE, K8sCostTypes.COMPUTE],
  [K8sDisplayCostTypes.DATA_TRANSFER, K8sCostTypes.DATA_TRANSFER],
  [K8sDisplayCostTypes.STORAGE, K8sCostTypes.STORAGE],
  [K8sDisplayCostTypes.NETWORK, K8sCostTypes.NETWORK],
]);

export const mapCloudTypeIdToK8sCostTypes = new Map([
  [CLOUD_TYPE_IDS.AWS, [K8sCostTypes.COMPUTE, K8sCostTypes.DATA_TRANSFER, K8sCostTypes.STORAGE]],
  [CLOUD_TYPE_IDS.AZURE, [K8sCostTypes.COMPUTE, K8sCostTypes.NETWORK, K8sCostTypes.STORAGE]],
  [CLOUD_TYPE_IDS.GCP, [K8sCostTypes.COMPUTE, K8sCostTypes.NETWORK, K8sCostTypes.STORAGE]],
]);

export const MAP_K8S_GCP_DISALLOWED_PROPERTIES_BY_KEYS = new Map([
  ['nodegroup', [K8sCommonFields.K8S_CUSTOM_TAGS, K8sCommonFields.NAMESPACE]],
  ['node', [K8sCommonFields.K8S_CUSTOM_TAGS, K8sCommonFields.NAMESPACE]],
  ['namespace', [K8sCommonFields.NODE, K8sCommonFields.NODE_GROUP]],
  ['customtags', [K8sCommonFields.NODE, K8sCommonFields.NODE_GROUP]],
]);
