/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { S3VersioningRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class S3VersioningRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new S3VersioningRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.S3_VERSIONING;
  }

  buildRecommTitle() {}

  build() {
    super.build();
    const { storage_class: currentStorageClass, total_size_gb: totalSizeGB } = this._rawRecommendation;

    this._recommendation.currentStorageClass = currentStorageClass;
    this._recommendation.totalSizeGB = totalSizeGB;

    const { zone_tag: region } = this._rawRecommendation;
    if (region && !this._recommendation.region) {
      this._recommendation.region = region;
    }
  }
}
