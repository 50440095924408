/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'shared/components/Alert';

export default function AlertError({ toShow, text, withCloseIcon, updateAlertClosed }) {
  return toShow ? (
    <Alert
      color="danger"
      icon={withCloseIcon ? 'lnr lnr-cross-circle' : ''}
      className="alert--bordered"
      handleClose={updateAlertClosed || null}
    >
      {text}
    </Alert>
  ) : null;
}

AlertError.propTypes = {
  toShow: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  withCloseIcon: PropTypes.bool.isRequired,
  updateAlertClosed: PropTypes.func.isRequired,
};
