import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import MultiSelect from 'shared/components/andtComponents/MultiSelect';
import Spinner, { SIZES } from 'shared/components/andtComponents/Spinner';
import useSlack from 'shared/components/slackIntegration/hooks/useSlack';
import classes from './SlackChannelSelect.module.scss';

const SlackChannelSelect = ({ savedChannels, onChangeHandler, id, children }) => {
  const [options, setOptions] = useState([]);
  const [savedOptions, setSavedOptions] = useState([]);

  const { getSlackChannels } = useSlack();
  const { data: channels, isLoading } = getSlackChannels();

  const processOptions = (channelsFromSlack) => {
    if (!channelsFromSlack || channelsFromSlack.length === 0) {
      return;
    }
    setOptions(channelsFromSlack.map((channel) => ({ value: channel.id, label: channel.name })));
    if (!savedChannels) {
      return;
    }
    const companyChannelsMap = new Map();
    channelsFromSlack.forEach((channel) => {
      companyChannelsMap.set(channel.id, channel.name);
    });
    const tempOptionsArr = [];
    savedChannels.forEach((channel) => {
      const label = companyChannelsMap.get(channel.id);
      if (label) {
        tempOptionsArr.push({ value: channel.id, label });
      }
    });
    setSavedOptions(tempOptionsArr);
  };

  useEffect(() => {
    processOptions(channels);
  }, [channels, savedChannels]);

  return (
    <>
      {children}
      {isLoading ? (
        <div className={classes.spinnerEnv}>
          <Spinner size={SIZES.SMALL_30} />
        </div>
      ) : (
        <MultiSelect
          options={options}
          id={`slack_select_${id}`}
          value={savedOptions}
          onChange={onChangeHandler}
          isMulti
        />
      )}
    </>
  );
};
SlackChannelSelect.propTypes = {
  savedChannels: PropTypes.array.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.any,
};

SlackChannelSelect.defaultProps = {
  children: null,
};

const SlackChannelSelectObserver = observer(SlackChannelSelect);
export default SlackChannelSelectObserver;
