import Button from 'shared/components/andtComponents/Button';
import React, { useMemo } from 'react';
import { SelectMulti, GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { FlatObject } from 'shared/types/commonTypes.ts';
import styles from '../featureflags.module.scss';
import useFetchCompaniesList from '../hooks/useFetchCompaniesList.ts';
import useFetchAccountsList from '../hooks/useFetchAccountsList.ts';

export type SelectOption = {
  label: string;
  value: string;
};
const makeOptions = (arr: FlatObject<string>[], labelKey = 'name', valueKey = 'id'): SelectOption[] =>
  arr.map((item) => ({ label: item[labelKey] || '', value: item[valueKey] || '' }));

const Filters = ({ filters, setFilters, features, onAddFeature, onRefresh, onSave }) => {
  const { data: companies = [] } = useFetchCompaniesList();
  const { data: accounts = [] } = useFetchAccountsList();

  const companiesOptions = useMemo(() => makeOptions(companies, 'companyName', 'companyId'), [companies]);
  const accountsOptions = useMemo(
    () =>
      accounts.map(({ accountName, accountId }) => ({
        label: accountName ? `${accountName} (${accountId})` : accountId || '',
        value: accountId,
      })),
    [accounts],
  );
  const featureFlagsOptions = useMemo(() => makeOptions(features, 'featureName', 'featureName'), [features]);
  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filtersInputs}>
        <div>
          <SelectMulti
            label="Accounts"
            options={accountsOptions}
            handleSelectionChange={(vals) => {
              setFilters({ ...filters, accounts: vals });
            }}
            selected={filters.accounts}
          />
        </div>
        <div>
          <SelectMulti
            label="Companies"
            minWidth={250}
            options={companiesOptions}
            handleSelectionChange={(vals) => {
              setFilters({ ...filters, companies: vals });
            }}
            selected={filters.companies}
          />
        </div>
        <div>
          <SelectMulti
            label="Features"
            minWidth={250}
            options={featureFlagsOptions}
            handleSelectionChange={(vals) => {
              setFilters({ ...filters, features: vals });
            }}
            selected={filters.features}
          />
        </div>
      </div>
      <div className={styles.filtersButtons}>
        <Button
          text="Add Feature"
          onClick={() => onAddFeature(prompt('New Feature?'))}
          icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
        />
        <Button text="" onClick={onRefresh} icon={() => <GenerateIcon iconName={ICONS.refresh.name} />} iconSize={20} />
        <Button
          text="Save"
          onClick={onSave}
          icon={() => <GenerateIcon iconName={ICONS.floppyDisk.name} />}
          iconSize={20}
        />
      </div>
    </div>
  );
};

export default Filters;
