/* eslint-disable no-unused-vars, arrow-parens */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/system/colorManipulator';
import { CSVLink } from 'react-csv';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  SortingState,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow, TableFilterRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@mui/styles';
import {
  SP_DISCOUNT_TABLE_COLUMNS,
  SP_DISCOUNT_DETAIL_NAMES,
  SP_DISCOUNT_COLUMN_EXTENSIONS,
  SP_DISCOUNT_DEFAULT_SORTING,
} from 'usage/constants/usageConstants';
import { numberWithCommas, percentStr } from 'shared/utils/strUtil';
import { mapRegionNamesToDescription } from 'shared/constants/awsConstants';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const camelToStrings = (camelStr) => camelStr.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    fontSize: '25.5vw',
  },
});

// const RiPurchasedTable = ({ data }) => {

const RowDetail = ({ row }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const arrRow = row ? Object.entries(row) : [];
  return (
    arrRow
      .filter(([key, value]) => SP_DISCOUNT_DETAIL_NAMES.indexOf(key) > -1)
      // ['startTime', 'offeringClass', 'paymentOption', 'recurringCharges', 'scope', 'term'].indexOf(key) > -1)
      .map(([key, value]) => (
        <Col xs={12} md={12} lg={12} xl={12}>
          <Row>
            <p style={{ padding: '3px', margin: '2px 0px' }}>
              <b>{`${camelToStrings(key)} : `}</b>
              <span>{key === 'recurringCharges' ? `${getCurrencyNumber(value)} hourly` : `${value}`}</span>
            </p>
          </Row>
        </Col>
      ))
  );
};
// hiddenColumnNamesChange = (hiddenColumnNames) => {
//   this.setState({ hiddenColumnNames });
// };
RowDetail.propTypes = {
  row: PropTypes.object.isRequired,
};
class SpDiscountTable extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      pageSizes: [5, 10],
    };
    this.months = [];
  }

  formatTotalUsage = (row) => {
    const formatted = numberWithCommas(row.value);
    return formatted;
  };

  formatPercent = (row) => {
    const formatted = percentStr(row.value * 100);
    return formatted;
  };

  formantRegionName = (row) => {
    const formatted = mapRegionNamesToDescription.get(row.value);
    return formatted || row.value;
  };

  render() {
    const { data } = this.props;
    const { pageSizes } = this.state;
    return (
      <Paper>
        <Col xs={12} md={12} lg={12} xl={12} style={{ padding: '15px' }}>
          <CSVLink data={data} headers={SP_DISCOUNT_TABLE_COLUMNS.title} filename="sp_table.csv">
            Export as CSV
            <span className="lnr lnr-download" style={{ fontSize: '18px', marginLeft: '8px', marginBottom: '8px' }} />
          </CSVLink>
        </Col>
        <Grid rows={data} columns={SP_DISCOUNT_TABLE_COLUMNS}>
          <SortingState defaultSorting={SP_DISCOUNT_DEFAULT_SORTING} />
          <FilteringState defaultFilters={[]} />
          <IntegratedSorting />
          <IntegratedFiltering />
          <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[2]} />
          <IntegratedPaging />
          <DataTypeProvider for={['region']} formatterComponent={this.formantRegionName} />
          <DataTypeProvider for={['total_usage']} formatterComponent={this.formatTotalUsage} />
          <DataTypeProvider for={['discount']} formatterComponent={this.formatPercent} />
          <TableWrapper styles={styles} columnExtensions={SP_DISCOUNT_COLUMN_EXTENSIONS} />
          <TableHeaderRow showSortingControls />
          <TableFilterRow showFilterSelector />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
      </Paper>
    );
  }
}

export default SpDiscountTable;
