/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Container } from 'reactstrap';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import Panel from 'shared/components/Panel';

export default function AzureSQL() {
  return (
    <Container>
      <PageHeader title={PageNames.AzureSQL} showDate />
      <Panel divider title="The best destination for fully managed SQL in the cloud">
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>Frictionless migration</span>
          </h5>
        </p>
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>Built-in machine learning</span>
          </h5>
        </p>
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>Unmatched scale and high availability</span>
          </h5>
        </p>
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>Advanced data security</span>
          </h5>
        </p>
      </Panel>
    </Container>
  );
}
