import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line max-len
import RecommendationOptionButton from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationOptionButton';

import classes from './recommendationOption.module.scss';

const RecommendationOptionsPanel = ({ recommendation, alternatives, currentProperties, alternativeProperties }) => (
  <div className={classes.optionsContainer}>
    <RecommendationOptionButton recommendation={recommendation} properties={currentProperties} isCurrent />
    {alternatives.map((_, index) => (
      <RecommendationOptionButton
        recommendation={recommendation}
        optionIndex={index}
        properties={alternativeProperties}
      />
    ))}
  </div>
);

RecommendationOptionsPanel.propTypes = {
  alternatives: PropTypes.array.isRequired,
  currentProperties: PropTypes.object.isRequired,
  alternativeProperties: PropTypes.object.isRequired,
};

export default RecommendationOptionsPanel;
