import { useQuery, useMutation, useQueryClient } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { getAnomaliesQueryParams } from 'usage/containers/AnomalyDetectionNew/anomalyDetectionHelpers';

const anomaliesRoot = '/api/v1/anomaly-detection';
const alertRulesURL = `${anomaliesRoot}/rules`;
const alertRuleURL = `${anomaliesRoot}/rule`;
const fetchAnomalies = (queryParams, isPpApplied, isFull) =>
  API.get('billings', `${anomaliesRoot}?${isFull ? 'isFull=true&' : ''}${queryParams}&isPpApplied=${isPpApplied}`);

export function anomalyDetectionProvider(filterObject, isPpApplied) {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.ANOMALY_DETECTION, { ...filterObject, isPpApplied }];
  const queryParams = getAnomaliesQueryParams(filterObject);

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchAllAnomalies: () =>
      queryClient.fetchQuery(queryKey, () => fetchAnomalies(queryParams, isPpApplied, true), {
        retry: false,
      }),
    fetchAnomalies: () =>
      useQuery(queryKey, () => fetchAnomalies(queryParams, isPpApplied, false), {
        retry: false,
      }),
  };
}

const fetchAlertRules = async () => API.get('billings', alertRulesURL);
export function anomaliesAlertRulesProvider(isPpApplied) {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.ANOMALIES_ALERT_RULES, { isPpApplied }];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchAlertRules: () =>
      useQuery(queryKey, () => fetchAlertRules(isPpApplied), {
        retry: false,
      }),
    createAlert: ({ onSuccess }) =>
      useMutation({
        mutationFn: (alertRule) => API.post('billings', alertRuleURL, { body: { alertRule } }),
        onSuccess,
      }),
    updateAlert: ({ onSuccess }) =>
      useMutation({
        mutationFn: (alertRule) => API.put('billings', alertRuleURL, { body: { alertRule } }),
        onSuccess,
      }),
    deleteAlert: ({ onSuccess = () => {} } = {}) =>
      useMutation({
        mutationFn: (alertRuleId) => API.del('billings', `${alertRuleURL}/${alertRuleId}`),
        onSuccess,
      }),
  };
}

export function anomalyCommentsProvider(uuid) {
  const url = `${anomaliesRoot}/${uuid}/comment`;
  return {
    createComment: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.post('billings', url, { body: payload }),
        onSuccess,
        onError,
      }),
    deleteComment: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.del('billings', url, { body: payload }),
        onSuccess,
        onError,
      }),
    updateComment: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.put('billings', url, { body: payload }),
        onSuccess,
        onError,
      }),
  };
}

export function anomalyUserStatusProvider(uuid) {
  const url = `${anomaliesRoot}/${uuid}/userStatus`;
  return {
    addUserStatus: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.post('billings', url, { body: payload }),
        onSuccess,
        onError,
      }),
  };
}

export function anomalyFeedbackProvider(uuid) {
  const url = `${anomaliesRoot}/${uuid}/feedback`;
  return {
    addFeedback: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.post('billings', url, { body: payload }),
        onSuccess,
        onError,
      }),
    deleteFeedback: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.del('billings', url, { body: payload }),
        onSuccess,
        onError,
      }),
  };
}
