import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationChartData, {
  CHART_TYPES,
} from '../../recommendationGenericComponents/recommendationChart/recommendationChartData';
import {
  AZURE_PROPERTIES,
  BASE_PROPERTIES,
  COST_PROPERTIES,
  RESERVED_INSTANCE_PROPERTIES,
} from '../../recommendationPropertyUtils';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import RecommendationOptionsPanel from '../../recommendationGenericComponents/recommendationOptionsPanel';

const RdsReservedInstance = ({ recommendation }) => {
  const properties = {
    ...RESERVED_INSTANCE_PROPERTIES,
    ...AZURE_PROPERTIES,
    ...COST_PROPERTIES,
    ...BASE_PROPERTIES,
  };

  const CURRENT_PROPERTIES = [properties.RI_TYPE, properties.CURRENT_ANNUAL_COST];

  const ALTERNATIVE_PROPERTIES = [
    properties.RECOMMENDED_RI_TYPE,
    properties.RECOMMENDED_ANNUAL_COST,
    properties.POTENTIAL_SAVINGS,
  ];

  const topThreeAlternatives = useMemo(() => {
    let alternatives = [];
    if (Array.isArray(recommendation?.recData?.alternatives)) {
      alternatives = recommendation?.recData?.alternatives?.slice(0, 3);
    }
    return alternatives;
  }, [recommendation]);

  const optionsPanelComponent =
    Array.isArray(topThreeAlternatives) && topThreeAlternatives.length ? (
      <RecommendationOptionsPanel
        alternatives={topThreeAlternatives}
        currentProperties={CURRENT_PROPERTIES}
        alternativeProperties={ALTERNATIVE_PROPERTIES}
        recommendation={recommendation}
      />
    ) : null;

  const description = `RI purchase recommendations are based on your historical usage.
                        These recommendations are calculated by analyzing all incurred usage eligible to be covered
                        by an RI (e.g. Pay as you go usage) and using our unique algorithms to simulate possible
                        combinations of RI’s that would cover that usage.`;
  const descriptionComponent = <RecommendationInsideLook description={description} />;

  const otherResourceIdRecommendations = <OtherResourceIdRecommendations resourceId={recommendation?.resourceId} />;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  const breakEvenChartComponent = (
    <RecommendationChartData
      chartsData={[{ chartTitle: 'Break Even point', recommendation, chartType: CHART_TYPES.BREAK_EVEN }]}
    />
  );

  return (
    <RecommendationDetailsLayout
      insideLook={descriptionComponent}
      otherResourceIdRecommendations={otherResourceIdRecommendations}
      optionsPanel={optionsPanelComponent}
      details={detailsComponent}
      charts={breakEvenChartComponent}
      preferences={preferencesComponent}
    />
  );
};

RdsReservedInstance.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default RdsReservedInstance;
