import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';
import EditBudgetForm from './BudgetForm';

class EditBudgetModal extends React.Component {
  handleClose = () => {
    const { onClose, selectedValue } = this.props;
    onClose(selectedValue);
  };

  handleListItemClick = (value) => {
    this.props.onClose(value);
  };

  render() {
    const {
      invoiceStore,
      budgetAmount,
      currentDisplayedUserType,
      currentUserReadOnly,
      open,
      handleFilterChange,
      handlers,
      fieldToFieldDistincValuesMap,
      budgetFormErrorMsgs,
      budgetName,
      selectedOptionsMap,
      filterStatusTypeMap,
      handleChangeFilterType,
      tempBudget,
    } = this.props;
    return (
      <CustomModal
        onClose={this.handleClose}
        open={open}
        saveDisabled={currentUserReadOnly}
        title="Update Budget"
        overrideStyles={{ width: 800 }}
        closeOnSave={false}
        onSave={handlers.handleUpdateBudget}
      >
        <EditBudgetForm
          tempBudget={tempBudget}
          handlers={handlers}
          budgetName={budgetName}
          budgetAmount={budgetAmount}
          isUpdate
          invoiceStore={invoiceStore}
          currentDisplayedUserType={currentDisplayedUserType}
          currentUserReadOnly={currentUserReadOnly}
          budgetFormErrorMsgs={budgetFormErrorMsgs}
          selectedOptionsMap={selectedOptionsMap}
          handleFilterChange={handleFilterChange}
          fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
          filterStatusTypeMap={filterStatusTypeMap}
          handleChangeFilterType={handleChangeFilterType}
        />
      </CustomModal>
    );
  }
}

EditBudgetModal.propTypes = {
  handlers: PropTypes.object.isRequired,
  tempBudget: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  budgetName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  budgetAmount: PropTypes.number.isRequired,
  currentDisplayedUserType: PropTypes.number.isRequired,
  budgetFormErrorMsgs: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  currentUserReadOnly: PropTypes.bool.isRequired,
  selectedOptionsMap: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  fieldToFieldDistincValuesMap: PropTypes.object.isRequired,
  filterStatusTypeMap: PropTypes.object.isRequired,
  handleChangeFilterType: PropTypes.object.isRequired,
};

export default EditBudgetModal;
