import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';

import classes from './SearchMultiIcon.module.scss';

export function SearchMultiIcon({ searchValue, onChangeSearch, onBlur, containerProps }) {
  const onChange = (searchInput) => {
    onChangeSearch(searchInput);
  };

  const onBlurSearch = (e) => {
    const relatedTargetChanged = { id: containerProps.id };
    onBlur({ ...e, relatedTarget: relatedTargetChanged });
  };

  const onClearSearchClick = (e) => {
    e.stopPropagation();
    onChange('');
  };

  return (
    /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
    <div className={classes.searchInputContainer} onBlur={onBlurSearch} tabIndex="0" id={containerProps.id}>
      <div className={classes.searchInputWithIcon}>
        <GenerateIcon iconName={ICONS.magnifyingGlass.name} className={classes.searchIcon} />
        <input
          value={searchValue.toString()}
          className={classes.searchInput}
          placeholder=" Search"
          onChange={(event) => onChange(event?.target?.value)}
        />
      </div>
      {searchValue ? (
        <GenerateIcon className={classes.clearIcon} iconName={ICONS.circleXmark.name} onClick={onClearSearchClick} />
      ) : null}
    </div>
  );
}

SearchMultiIcon.propTypes = {
  containerProps: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChangeSearch: PropTypes.object.isRequired,
  searchValue: PropTypes.string,
};

SearchMultiIcon.defaultProps = {
  searchValue: '',
};
