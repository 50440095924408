import React from 'react';
import PropTypes from 'prop-types';
import {
  CostTypes,
  Dimensions,
  mapCostTypesToDisplay,
  mapDimensionsToDisplay,
} from 'usage/constants/costAndUsageConstants';
// eslint-disable-next-line max-len
import { formatFilterOptionValueLabel } from 'usage/containers/CostAndUsageExplorer/helpers/customDashboardHelperMethods';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import {
  createSelectedDimensionsMap,
  getDefaultFiltersConfig,
  getLabel,
} from 'shared/components/FilterSidebar/filterUtil';
import FieldSidebarFilter from 'shared/components/FilterSidebar/FieldSidebarFilter';

const createOptions = (fieldValues, filterType) =>
  fieldValues.map((fieldValue) => formatFilterOptionValueLabel(fieldValue, filterType));

const createSelectedCostTypesMap = (selectedCostTypes) => {
  const selectedMap = new Map([]);
  const selectedArr = selectedCostTypes
    ? selectedCostTypes.map((selectedCostType) => ({
        value: selectedCostType,
        label: mapCostTypesToDisplay.get(selectedCostType),
      }))
    : [];
  selectedMap.set('Cost Type', selectedArr);
  return selectedMap;
};

const getFieldTooltip = (field, label, filterTooltipsFields) => {
  if (filterTooltipsFields?.recommendations?.includes(field)) {
    return `The selected filter “${label}” isn’t applicable for Recommendation panels`;
  }
  if (filterTooltipsFields?.K8SAndRecommendation?.includes(field)) {
    return `The selected filter “${label}” isn’t applicable for K8S/Recommendation panels`;
  }
  return null;
};

const renderFilterByType = (selectedOptionsMap, props, generalOptions) => {
  const {
    handleFilterChange,
    likeFiltersStatus,
    handleChangeFilterType,
    excludedFiltersStatusMap,
    likeOperator,
    fieldsWidth,
  } = props;
  const {
    field,
    options,
    isOneChoiceFieldFilter = false,
    isAutoComplete,
    isAsyncLoad,
    isAsyncLoadClick,
    subMenu,
    filter,
    label,
    tooltip,
    disableLike,
    isDisabled = false,
    keys,
    fetchData,
  } = generalOptions;
  return (
    <div key={field}>
      <FieldSidebarFilter
        label={label}
        field={field}
        keys={keys}
        options={options}
        likeOperator={likeOperator && !disableLike}
        isAutoComplete={isAutoComplete}
        isAsyncLoad={isAsyncLoad}
        isAsyncLoadClick={isAsyncLoadClick}
        subMenu={subMenu}
        fetchData={fetchData || (() => ({ data: [] }))}
        filter={filter}
        tooltip={tooltip}
        fieldsWidth={fieldsWidth}
        handleChange={handleFilterChange}
        handleChangeFilterType={handleChangeFilterType}
        selectedOptions={selectedOptionsMap.get(field) || []}
        excludeMode={excludedFiltersStatusMap.get(field)}
        likeMode={!!likeFiltersStatus && likeFiltersStatus[field]}
        isOneChoiceFieldFilter={isOneChoiceFieldFilter}
        isDisabled={isDisabled || props.isDisabled}
      />
    </div>
  );
};

const FilterContentList = ({ fieldToFieldDistincValuesMap, selectedOptionsMap, isForK8S, generalProps }) => {
  const {
    fieldToFieldDistincValuesProps,
    isCostTypeFilter,
    handleCostTypeChange,
    excludedFiltersStatusMap,
    handleChangeFilterType,
    currDispUserCloudAccountType,
    disallowedFilters,
    isDimensionsFilter,
    filterConfig,
    selectedCostTypes,
    handleDimensionsChange,
    selectedDimensions,
  } = generalProps;

  // TODO - add sub title "Kubernetes" before namespace and labels filters in CUE
  const filters = [];
  const selectedCostTypesMap = createSelectedCostTypesMap(selectedCostTypes);
  if (!fieldToFieldDistincValuesMap) {
    return null;
  }
  if (isDimensionsFilter) {
    const selectedDimensionsMap = createSelectedDimensionsMap(selectedDimensions);
    filters.push(
      <div>
        <FieldSidebarFilter
          field="Measure"
          options={[
            {
              value: Dimensions.AVERAGE,
              label: mapDimensionsToDisplay.get(Dimensions.AVERAGE),
            },
            {
              value: Dimensions.MAX,
              label: mapDimensionsToDisplay.get(Dimensions.MAX),
            },
            {
              value: Dimensions.MIN,
              label: mapDimensionsToDisplay.get(Dimensions.MIN),
            },
          ]}
          handleChange={handleDimensionsChange}
          selectedOptions={selectedDimensionsMap.get('Measure') || []}
          isExcludeMode={!excludedFiltersStatusMap.has('Measure')}
          handleChangeFilterType={handleChangeFilterType}
          isOneChoiceFieldFilter={false}
          disableExclude
        />
      </div>,
    );
  }
  if (isCostTypeFilter) {
    filters.push(
      <div key="cost-type-filter">
        <FieldSidebarFilter
          field="Cost Type"
          options={[
            {
              value: CostTypes.COST,
              label: mapCostTypesToDisplay.get(CostTypes.COST),
            },
            {
              value: CostTypes.REFUND,
              label: mapCostTypesToDisplay.get(CostTypes.REFUND),
            },
            {
              value: CostTypes.DISCOUNT,
              label: mapCostTypesToDisplay.get(CostTypes.DISCOUNT),
            },
            {
              value: CostTypes.CREDIT,
              label: mapCostTypesToDisplay.get(CostTypes.CREDIT),
            },
          ]}
          handleChange={handleCostTypeChange}
          selectedOptions={selectedCostTypesMap.get('Cost Type') || []}
          isExcludeMode={!excludedFiltersStatusMap.has('Cost Type')}
          handleChangeFilterType={handleChangeFilterType}
          fetchData={() => ({ data: [] })}
          disableExclude
        />
      </div>,
    );
  }
  if (fieldToFieldDistincValuesMap.size > 0) {
    fieldToFieldDistincValuesMap.forEach((fieldValues, field) => {
      const passedProps = (fieldToFieldDistincValuesProps && fieldToFieldDistincValuesProps[field]) || {};
      const { filter, isAutoComplete, values, subMenu, isAsyncLoad, isAsyncLoadClick, keys, disableLike, fetchData } = {
        ...(filterConfig
          ? filterConfig(field, passedProps, generalProps)
          : getDefaultFiltersConfig(field, passedProps, generalProps) || {}),
        ...passedProps,
      };
      const options = createOptions(
        isAutoComplete || isAsyncLoad || isAsyncLoadClick ? values || [] : fieldValues,
        field,
      );
      const label = getLabel(field, currDispUserCloudAccountType);
      const generalOptions = {
        keys,
        isAutoComplete,
        subMenu,
        isAsyncLoad,
        isAsyncLoadClick,
        field,
        disableLike,
        options,
        filter,
        label,
        tooltip: getFieldTooltip(field, label, generalProps.filterTooltipsFields),
        isOneChoiceFieldFilter: AwsCommonFields.QUANTITY_TYPE === field,
        isDisabled: currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP ? disallowedFilters?.includes(field) : null,
        fetchData,
      };
      if (generalOptions.isOneChoiceFieldFilter && isForK8S) {
        return;
      }
      filters.push(renderFilterByType(selectedOptionsMap, generalProps, generalOptions));
    });
  }
  return filters;
};

export default FilterContentList;

FilterContentList.propTypes = {
  fieldToFieldDistincValuesMap: PropTypes.object.isRequired,
  selectedOptionsMap: PropTypes.object.isRequired,
  isForK8S: PropTypes.bool,
  generalProps: PropTypes.object.isRequired,
};
FilterContentList.defaultProps = {
  isForK8S: false,
};
