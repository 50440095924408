import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import useDebouncedInput from 'shared/hooks/customHooks/useDebouncedInput';
import {
  useFilterRecDBCustomTagsBySearchStr,
  useFiltersAccountTagsBySearchStr,
  useFiltersCustomTagsBySearchStr,
  useFiltersDistinctValues,
  useFiltersK8SLabelsBySearchStr,
  useFiltersNamespaces,
  useFiltersPods,
  useFiltersResources,
  useFiltersUsageTypes,
  useFiltersViewpointsMappings,
  useFiltersVirtualTagsBySearchStr,
} from '../hooks/react-query/useInvoiceFilters';

const InvoiceFiltersContext = React.createContext();

export function withInvoiceFiltersContextProvider(Component, rootStore) {
  function InvoiceFiltersContextProvider({ ...props }) {
    const { currDispUserCloudAccountType, currDispUserAccountKey } = rootStore?.usersStore;
    const { customTagsFetcher } = useFiltersCustomTagsBySearchStr();
    const { viewpointMappingsFetcher } = useFiltersViewpointsMappings();
    const { accountTagsFetcher } = useFiltersAccountTagsBySearchStr();
    const { virtualTagsFetcher } = useFiltersVirtualTagsBySearchStr();
    const { k8sLabelsFetcher } = useFiltersK8SLabelsBySearchStr();
    const { resourcesFetcher } = useFiltersResources();
    const { usageTypesFetcher } = useFiltersUsageTypes();
    const { namespacesFetcher } = useFiltersNamespaces();
    const { podsFetcher } = useFiltersPods();
    const { recDbCustomTagsFetcher } = useFilterRecDBCustomTagsBySearchStr();
    const accountFeatures = rootStore?.usersStore.getCurrentDisplayedAccountFeatures(currDispUserAccountKey);
    const [lastFetchedAccountKey] = useDebouncedInput(currDispUserAccountKey, 500);

    const { filtersValuesMap, filtersLoading, ...getters } = useFiltersDistinctValues({
      accountType: currDispUserCloudAccountType,
      accountKey: currDispUserAccountKey,
      fetchK8S: (accountFeatures || []).includes(ACCOUNT_FEATURES.K8S),
      enabled: !!currDispUserAccountKey && currDispUserAccountKey === lastFetchedAccountKey,
    });
    filtersValuesMap.set(AwsCommonFields.USAGE_TYPE, usageTypesFetcher.values);
    filtersValuesMap.set(AwsCommonFields.NAMESPACE, namespacesFetcher.values);
    return (
      <InvoiceFiltersContext.Provider
        value={{
          viewpointMappingsFetcher,
          customTagsFetcher,
          k8sLabelsFetcher,
          accountTagsFetcher,
          virtualTagsFetcher,
          resourcesFetcher,
          usageTypesFetcher,
          namespacesFetcher,
          podsFetcher,
          recDbCustomTagsFetcher,
          filtersValuesMap,
          filtersLoading,
          ...getters,
        }}
      >
        <Component {...props} />
      </InvoiceFiltersContext.Provider>
    );
  }
  InvoiceFiltersContextProvider.propTypes = {
    queryClient: PropTypes.object.isRequired,
  };
  return observer(InvoiceFiltersContextProvider);
}

export function withInvoiceFiltersContextConsumer(Component) {
  return function InvoiceFiltersContextConsumer({ ...props }) {
    return (
      <InvoiceFiltersContext.Consumer>{(data) => <Component {...data} {...props} />}</InvoiceFiltersContext.Consumer>
    );
  };
}

export const useInvoiceFilters = () => useContext(InvoiceFiltersContext);
