import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'reactstrap';
import IconInputField from 'shared/components/IconInputField';
import Spinner from 'shared/components/andtComponents/Spinner';
import CheckBox from 'shared/components/CheckBox';
import { CurrenciesSelect } from 'users/containers/Onboarding/shared/components/UserCurrency/CurrencySelect';
import OnboardingLinks from 'app/containers/Onboarding/utils/OnboardingLinks';

class AzureAppRegistration extends PureComponent {
  static propTypes = {
    applicationId: PropTypes.string.isRequired,
    directoryId: PropTypes.string.isRequired,
    clientSecret: PropTypes.string.isRequired,
    applicationName: PropTypes.string.isRequired,
    handleChangeStateValuesByName: PropTypes.func.isRequired,
    isTestAppCreds: PropTypes.bool,
    isCSP: PropTypes.bool.isRequired,
    handleSetIsCSP: PropTypes.func.isRequired,
    usersStore: PropTypes.object.isRequired,
    handleSelectCurrency: PropTypes.func.isRequired,
    currencySymbol: PropTypes.object,
  };

  static defaultProps = {
    isTestAppCreds: false,
    currencySymbol: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      clientSecretInputType: 'password',
    };
  }

  handleAppRegistrationInputsChange = (newInput, e) => {
    if (!e) {
      return;
    }
    const { handleChangeStateValuesByName } = this.props;
    const validInput = newInput.replace(/["\s]+/g, '');
    const inputName = e.target.name;
    handleChangeStateValuesByName(inputName, validInput);
  };

  handleChangeClientSecretInputType = (e) => {
    e.preventDefault();
    const { clientSecretInputType } = this.state;
    const newClientSecretInputType = clientSecretInputType === 'password' ? 'text' : 'password';
    this.setState({ clientSecretInputType: newClientSecretInputType });
  };

  disableEnterKeySub = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  render() {
    const {
      applicationId,
      applicationName,
      clientSecret,
      directoryId,
      isTestAppCreds,
      isCSP,
      handleSetIsCSP,
      usersStore,
      currencySymbol,
      handleSelectCurrency,
    } = this.props;
    const { clientSecretInputType } = this.state;
    if (isTestAppCreds) {
      return (
        <div style={{ height: '300px' }}>
          <Spinner />
        </div>
      );
    }
    return (
      <Row>
        <Col xs={12} md={12} lg={12} xl={12}>
          <p>
            You can find some more information in the link below <br />
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={OnboardingLinks.AZURE.application}
            >
              Grant access to Azure application
            </a>
          </p>
          <br />
        </Col>
        <Col xs={12} md={12} lg={8} xl={8}>
          <Form onKeyPress={this.disableEnterKeySub} className="form">
            <div className="form__form-group">
              <CheckBox label="Reseller (CSP)" onChangeFunc={() => handleSetIsCSP(!isCSP)} value={isCSP} />
            </div>
            <div className="form__form-group">
              <IconInputField
                icon="lnr lnr-database"
                handler={this.handleAppRegistrationInputsChange}
                label="Directory ID"
                name="directoryId"
                value={directoryId}
                type="text"
                placeHolder="Enter your Directory Id"
              />
            </div>
            <div className="form__form-group">
              <IconInputField
                icon="lnr lnr-cloud"
                handler={this.handleAppRegistrationInputsChange}
                label="Application ID"
                name="applicationId"
                value={applicationId}
                type="text"
                placeHolder="Enter your Application ID"
              />
            </div>
            <div className="form__form-group">
              <IconInputField
                icon="lnr lnr-cloud"
                handler={this.handleAppRegistrationInputsChange}
                label="Client Secret"
                name="clientSecret"
                value={clientSecret}
                type={clientSecretInputType}
                placeHolder="Enter your Client Secret"
                handleChangeInputType={this.handleChangeClientSecretInputType}
                isPassword
              />
            </div>
            <div className="form__form-group">
              <IconInputField
                icon="lnr lnr-cloud"
                handler={this.handleAppRegistrationInputsChange}
                label="Application Name"
                name="applicationName"
                value={applicationName}
                type="text"
                placeHolder="You can choose a name for this APP"
              />
            </div>
            <CurrenciesSelect
              usersStore={usersStore}
              currencySymbol={currencySymbol}
              handleSelectCurrency={handleSelectCurrency}
            />
          </Form>
        </Col>
      </Row>
    );
  }
}

export default AzureAppRegistration;
