import { ReactComponent as GoalIcon } from 'shared/img/icons/goal.svg';
import costTypeIcon from './assets/cost-type.svg';
import menuIcon from './assets/menu.svg';
import optionsIcon from './assets/options.svg';
import downloadIcon from './assets/download.svg';
import { ReactComponent as CopyIcon } from './assets/copy.svg';
import { ReactComponent as ResetIcon } from './assets/reset.svg';
import { ReactComponent as pieChartIcon } from './assets/charts/pie-chart.svg';
import { ReactComponent as areaChartIcon } from './assets/charts/area-chart.svg';
import { ReactComponent as barChartIcon } from './assets/charts/bar-chart.svg';
import { ReactComponent as tableChartIcon } from './assets/charts/table-chart.svg';
import { ReactComponent as lineChartIcon } from './assets/charts/line-chart.svg';
import { ReactComponent as SaveReportIcon } from './assets/save/save-report.svg';
import { ReactComponent as OverwriteIcon } from './assets/save/overwrite.svg';
import { ReactComponent as SavePanelIcon } from './assets/save/save-panel.svg';
import { ReactComponent as ExportPngIcon } from './assets/save/export-png.svg';
import { ReactComponent as ExportCsvIcon } from './assets/save/export-csv.svg';
import { ReactComponent as CostIcon } from './assets/cost-usage/cost.svg';
import { ReactComponent as UsageIcon } from './assets/cost-usage/usage.svg';
import { ReactComponent as RateIcon } from './assets/cost-usage/rate.svg';

export default {
  CopyIcon,
  ResetIcon,
  menu: {
    menuIcon,
    costTypeIcon,
    optionsIcon,
    downloadIcon,
  },
  chartTypes: {
    pieChartIcon,
    areaChartIcon,
    barChartIcon,
    tableChartIcon,
    lineChartIcon,
  },
  download: {
    OverwriteIcon,
    SaveReportIcon,
    SavePanelIcon,
    ExportCsvIcon,
    ExportPngIcon,
    GoalIcon,
  },
  costUsage: {
    CostIcon,
    UsageIcon,
    RateIcon,
  },
};
