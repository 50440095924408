import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import HolidayBottomLeftIcon from 'shared/components/HolidayThemedIcons/HolidayBottomLeftIcon';
import { DEFAULT_THEME } from 'app/containers/App/components/CustomThemeApplier';
import SidebarContent from './SidebarContent';

import classes from './Sidebar.module.scss';

const Sidebar = ({ childProps }) => {
  const currUserTheme = childProps.usersStore.getCurrDisplayedUserTheme();
  return (
    <div className={classes.root}>
      <SidebarContent
        userType={childProps.usersStore.currentDisplayedUserType}
        cloudType={childProps.usersStore.currDispUserCloudAccountType}
        accountFeatures={childProps.usersStore.getCurrentDisplayedAccountFeatures(
          childProps.usersStore.currDispUserAccountKey,
        )}
        accountType={childProps.usersStore.getCurrAccountTypeId()}
        usersStore={childProps.usersStore}
      />
      {currUserTheme === DEFAULT_THEME ? (
        <HolidayBottomLeftIcon holiday={childProps.usersStore.currentHoliday} />
      ) : null}
    </div>
  );
};

Sidebar.propTypes = {
  childProps: PropTypes.object.isRequired,
};

const ObserverSidebar = observer(Sidebar);
export default ObserverSidebar;
