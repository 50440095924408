import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  SortingState,
  DataTypeProvider,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableFilterRow,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { kIntFormmater, percentStrNoCommas } from 'shared/utils/strUtil';
import Input from '@mui/material/Input';
import { withStyles } from '@mui/styles';
import { Progress } from 'reactstrap';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

const styles = () => ({
  numericInput: {
    fontSize: '14px',
    textAlign: 'right',
    width: '100%',
  },
});

const CovarageEditorBase = ({ value, onValueChange, classes }) => {
  const handleChange = (event) => {
    const { value: targetValue } = event.target;
    if (targetValue.trim() === '') {
      onValueChange();
      return;
    }
    onValueChange(parseInt(targetValue, 10));
  };
  return (
    <Input
      type="number"
      classes={{
        root: classes.root,
      }}
      fullWidth
      value={value === undefined ? '' : value}
      inputProps={{
        min: 0,
        placeholder: 'Filter...',
      }}
      onChange={handleChange}
    />
  );
};

CovarageEditorBase.propTypes = {
  value: PropTypes.number,
  onValueChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

CovarageEditorBase.defaultProps = {
  value: '',
};

const CovarageEditor = withStyles(styles)(CovarageEditorBase);

// eslint-disable-next-line react/prop-types
const FilterIcon = ({ type, event, dataField, ...restProps }) => <TableFilterRow.Icon type={type} {...restProps} />;

class DevExpFilterColumnTable extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    defaultHiddenColumnNames: PropTypes.object.isRequired,
    defaultSorting: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { columns, data, defaultSorting, defaultHiddenColumnNames } = this.props;
    this.state = {
      columns,
      rows: data || [],
      defaultSorting,
      hiddenColumnNames: defaultHiddenColumnNames,
      covarageFilterOperations: [
        'equal',
        'notEqual',
        'greaterThan',
        'greaterThanOrEqual',
        'lessThan',
        'lessThanOrEqual',
      ],
      pageSizes: [5, 10, 20, 0],
    };
  }

  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  kFormmater = (value) => kIntFormmater(value.value);
  currenciesFormatter = (value) => this.props.getCurrencyNumber(kIntFormmater(value.value));
  percentageFormatter = (value) => percentStrNoCommas(Number(value));

  riCovarageFormatter = (data) => {
    withStyles(data);
    return (
      <div>
        <div className="text-center">{this.percentageFormatter(data.row.coverage)}</div>
        <Progress value={data.row.coverage} color="budget-blue" />
      </div>
    );
  };

  render() {
    const { rows, columns, defaultSorting, hiddenColumnNames, pageSizes, covarageFilterOperations } = this.state;
    return (
      <Paper>
        <Grid rows={rows} columns={columns}>
          <FilteringState defaultFilters={[]} />
          <IntegratedFiltering />

          <SortingState defaultSorting={defaultSorting} />
          <IntegratedSorting />

          <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[2]} />
          <IntegratedPaging />
          <DataTypeProvider
            for={['coverage']}
            availableFilterOperations={covarageFilterOperations}
            editorComponent={CovarageEditor}
          />
          <DataTypeProvider for={['coverage']} formatterComponent={this.riCovarageFormatter} />
          <DataTypeProvider for={['totalUsageQuantity']} formatterComponent={this.kFormmater} />
          <DataTypeProvider for={['riTotalUsageQuantity']} formatterComponent={this.kFormmater} />
          <DataTypeProvider for={['onDemandTotalUsageQuantity']} formatterComponent={this.kFormmater} />
          <DataTypeProvider for={['onDemand']} formatterComponent={this.currenciesFormatter} />
          <TableWrapper />
          <TableHeaderRow showSortingControls />
          <TableFilterRow showFilterSelector iconComponent={FilterIcon} />
          <PagingPanel pageSizes={pageSizes} />
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
          />
          <Toolbar />
          <ColumnChooser />
        </Grid>
      </Paper>
    );
  }
}

const ObserverDevExpFilterColumnTable = withUserSettingsConsumer(DevExpFilterColumnTable);
export default ObserverDevExpFilterColumnTable;
