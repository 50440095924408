import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { SelectMulti, ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import CustomModal from 'shared/components/andtComponents/Modal';
import styles from './AddLinkedAccountsModal.module.scss';

const AddLinkedAccountsModal = ({ isOpen, onClose, linkedAccounts, addLinkedAccounts }) => {
  const [selectedLinkedAccounts, setSelectedLinkedAccounts] = useState([]);
  const [saveClicked, setSaveClicked] = useState(false);
  const [errors, setErrors] = useState({
    linkedAccounts: '',
  });
  const renderErrorMsg = (fieldName) =>
    saveClicked && errors && errors[fieldName] ? <span className={styles.errorMsg}>{errors[fieldName]}</span> : null;
  const onSaveClick = async (e) => {
    setSaveClicked(true);
    if (!selectedLinkedAccounts?.length) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        linkedAccounts: 'You must select at least one linekd account before saving',
      }));
      return;
    }
    addLinkedAccounts(e, selectedLinkedAccounts);
  };
  return (
    <CustomModal
      closeOnSave={false}
      onSave={onSaveClick}
      open={isOpen}
      onClose={onClose}
      title=""
      automationId="addLinkedAccountsToDivisionModal"
    >
      <div>
        <div className={styles.selectContainer}>
          <SelectMulti
            showCounter
            label="Linked Accounts"
            options={linkedAccounts.map(({ linkedAccountId, linkedAccountName }) => ({
              value: linkedAccountId,
              label: linkedAccountName ? `${linkedAccountName} (${linkedAccountId})` : linkedAccountId,
            }))}
            selected={selectedLinkedAccounts}
            handleSelectionChange={(selected) => setSelectedLinkedAccounts(selected)}
          />
          {renderErrorMsg('linkedAccounts')}
        </div>
        {selectedLinkedAccounts.length > 0 && (
          <div className={styles.chipList}>
            {selectedLinkedAccounts.map((ln) => (
              <Chip
                key={ln.value}
                label={ln.label}
                deleteIcon={<GenerateIcon iconName={ICONS.xMark.name} className="chip-delete-icon" />}
                onDelete={() =>
                  setSelectedLinkedAccounts(
                    selectedLinkedAccounts.filter(({ linkedAccountId }) => ln.linkedAccountId !== linkedAccountId),
                  )
                }
              />
            ))}
          </div>
        )}
      </div>
    </CustomModal>
  );
};

AddLinkedAccountsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  addLinkedAccounts: PropTypes.func.isRequired,
  linkedAccounts: PropTypes.array,
};
AddLinkedAccountsModal.defaultProps = {
  isOpen: false,
  linkedAccounts: [],
};
export default AddLinkedAccountsModal;
