import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataTypeProvider, IntegratedSorting, RowDetailState, SortingState } from '@devexpress/dx-react-grid';
import { Grid, TableRowDetail, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { mapRegionNamesToDescription } from 'shared/constants/awsConstants';
import { ReactComponent as EmptyComponent } from 'shared/img/icons/empty-component.svg';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import useTable from 'shared/hooks/customHooks/useTable';
import AnomalyDetectionTableHeaderColumn from './AnomalyDetectionTableHeaderColumn';
import { getTableColumns } from '../anomalyDetectionHelpers';
import PrecentChange from './PercentChange';
import AlertStatus from './AlertStatus';
import AlertedColumn from './AlertedColumn';
import ExploreAnomaly from './ExploreAnomaly';
import AnomalyFeedback from './AnomalyFeedback';
import AnomalyComments from './AnomalyComments';
import AnomalyUserStatus from './AnomalyUserStatus';
import AnomalyDetectionTableCell from './AnomalyDetectionTableCell';
import NoAlertRules from './NoAlertRules';
import AnomalyChart from './AnomalyChart/AnomalyChart';
import Cubelet from './Cubelet';
import styles from './anomalyDetectionTable.module.scss';

const propTypes = {
  rows: PropTypes.array.isRequired,
  mapLinkedAccIdToDivisionName: PropTypes.object.isRequired,
  userAccounts: PropTypes.array,
  alertRuleIds: PropTypes.array,
  columns: PropTypes.array.isRequired,
  columnsExtensions: PropTypes.array.isRequired,
  openAddAlertRuleModal: PropTypes.func.isRequired,
  exploreRoute: PropTypes.string.isRequired,
  showAlerts: PropTypes.bool,
  userType: PropTypes.number.isRequired,
};

const defaultProps = {
  userAccounts: [],
  alertRuleIds: [],
  showAlerts: false,
};

const AnomalyDetectionTable = ({
  rows,
  mapLinkedAccIdToDivisionName,
  userAccounts,
  exploreRoute,
  alertRuleIds,
  showAlerts,
  userType,
  openAddAlertRuleModal,
}) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const [sorting, setSorting] = useState([]);
  const { NewTableWrapper, NewTableRow, NewTableRowDetail } = useTable();
  const [expandedRowIds, setExpandedRowIds] = useState([]);

  useEffect(() => {
    setSorting(
      showAlerts
        ? [{ columnName: 'alertStart', direction: 'desc' }]
        : [{ columnName: 'anomalyStart', direction: 'desc' }],
    );
  }, [showAlerts]);
  const columns = getTableColumns(showAlerts, mapLinkedAccIdToDivisionName, userType);
  const columnsExtensions = [
    {
      name: 'alertStart',
      width: 'auto',
    },
    {
      columnName: 'anomalyStart',
      width: '150px',
    },
    { columnName: 'division', width: 'auto' },
    {
      columnName: 'accountName',
      width: 'auto',
    },
    { columnName: 'serviceName', width: 'auto' },
    { columnName: 'totalCostImpact', width: '120px' },
    { columnName: 'currentCost', width: 'auto' },
    { columnName: 'regionTagName', width: 'auto' },
    { columnName: 'purchaseOption', width: '150px' },
    { columnName: 'usageQuantityType', width: '120px' },
    { columnName: 'percentChange', width: '145px' },
    {
      columnName: 'status',
      width: '70px',
      align: 'center',
    },
    {
      columnName: 'alerts',
      width: '34px',
      align: 'center',
    },
    {
      columnName: 'feedbacks',
      width: '72px',
      align: 'center',
    },
    {
      columnName: 'comments',
      width: '34px',
      align: 'center',
    },
    {
      columnName: 'userStatuses',
      width: '34px',
      align: 'center',
    },
    {
      columnName: 'explore',
      width: '34px',
      align: 'center',
    },
  ];

  if (showAlerts && !alertRuleIds?.length && !rows?.length) {
    return <NoAlertRules openAddAlertRuleModal={openAddAlertRuleModal} />;
  }

  if (!rows || !rows.length) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        <EmptyComponent className="mb-1 emptyComponentIcon" />
        <span className={styles.noAnomalies}>No {showAlerts ? 'alerts ' : 'anomalies '} to show,</span>
        <span className={styles.noAnomalies}>Try to change the timeframe</span>
      </div>
    );
  }

  const RowDetail = ({ row }) => (
    <NewTableRowDetail className={styles.rowDetail} row={row}>
      <AnomalyChart metric={row} />
      <Cubelet anomaly={row} path={exploreRoute} />
    </NewTableRowDetail>
  );

  RowDetail.propTypes = {
    row: PropTypes.shape({
      cubeletLastSplit: PropTypes.object,
      cubeletSplits: PropTypes.array,
    }).isRequired,
  };

  return (
    <NewTableWrapper>
      <Grid rows={rows} columns={columns}>
        <SortingState
          sorting={sorting}
          onSortingChange={(sortingcolumAndDirection) => setSorting(sortingcolumAndDirection)}
        />
        <IntegratedSorting />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
        <TableWrapper
          cellComponent={AnomalyDetectionTableCell}
          columnExtensions={columnsExtensions}
          rowComponent={(props) => <NewTableRow expandedRowIds={expandedRowIds} {...props} />}
          height="750px"
        />
        <DataTypeProvider for={['percentChange']} formatterComponent={PrecentChange} />
        <DataTypeProvider
          for={['totalCostImpact']}
          formatterComponent={({ value }) => (
            <div>
              <span>{getCurrencyNumber(value, { roundNumber: true }, 0)}</span>
            </div>
          )}
        />
        <DataTypeProvider
          for={['regionTagName']}
          formatterComponent={({ value }) => mapRegionNamesToDescription.get(value) || value}
        />
        <DataTypeProvider
          for={['accountName']}
          formatterComponent={({ value }) => {
            const account = userAccounts.find(({ accountId }) => accountId === value);
            return (account && account.accountName) || value;
          }}
        />
        <DataTypeProvider
          for={['status']}
          formatterComponent={(props) => <AlertStatus {...props} showAlerts={showAlerts} />}
        />
        <DataTypeProvider
          for={['alerts']}
          formatterComponent={(props) => <AlertedColumn {...props} alertRuleIds={alertRuleIds} />}
        />
        <DataTypeProvider
          for={['explore']}
          formatterComponent={(props) => <ExploreAnomaly {...props} path={exploreRoute} />}
        />
        <DataTypeProvider for={['feedbacks']} formatterComponent={(props) => <AnomalyFeedback {...props} />} />
        <DataTypeProvider for={['comments']} formatterComponent={(props) => <AnomalyComments {...props} />} />
        <DataTypeProvider for={['userStatuses']} formatterComponent={(props) => <AnomalyUserStatus {...props} />} />
        <TableRowDetail contentComponent={RowDetail} />
        <TableHeaderRow
          messages={{ sortingHint: '' }}
          contentComponent={AnomalyDetectionTableHeaderColumn}
          showSortingControls
        />
      </Grid>
    </NewTableWrapper>
  );
};

AnomalyDetectionTable.propTypes = propTypes;
AnomalyDetectionTable.defaultProps = defaultProps;
export default AnomalyDetectionTable;
