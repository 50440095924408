import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import ColoredTabs from 'shared/components/tabs/ColoredTabs';
import RolesAndUsers from 'users/containers/RolesAndUsers';
import DivisionsPage from 'users/containers/Divisions/index';
import ApiAccessKeyPage from 'users/containers/ApiAccessKey/index';
import Settings from 'users/containers/Settings/index';
import Channels from 'users/containers/Channels/index';
import LinkedAccounts from 'users/containers/LinkedAccounts';
import {
  CLOUD_TYPE_IDS,
  cloudTypeToAccountTabsMap,
  customerCloudTypeToAccountTabsMap,
  edpCustomerCloudTypeToAccountTabsMap,
  TABS_IDS,
  UsersType,
  userTypeToAccountTabsMap,
} from 'users/constants/usersConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import CloudAccounts from 'users/containers/CloudAccounts/CloudAccounts';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { withRolesProvider } from 'users/contexts/RolesProvider';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import { isUserCustomer, isUserCustomerEDP } from 'users/utils/userUtil';
import AppRegistrationConfiguration from '../Onboarding/azure/components/AppRegistrationConfiguration';

const Account = ({ usersStore, location, ...props }) => {
  useEffect(() => {
    usersStore.fetchUserLinkedAccounts();
    usersStore.fetchSubUsers();
  }, []);

  const getTabsOrder = () => {
    const { currentDisplayedUserType: userType, currDispUserCloudAccountType: cloudAccountType } = usersStore;
    const userTabs = userTypeToAccountTabsMap.get(userType);
    const isCustomer = isUserCustomer(userType);
    const isEDPCustomer = isUserCustomerEDP(userType) && !isCustomer;
    const getCloudTabs = () => {
      if (isEDPCustomer) {
        return edpCustomerCloudTypeToAccountTabsMap.get(cloudAccountType);
      }
      if (isCustomer) {
        return customerCloudTypeToAccountTabsMap.get(cloudAccountType);
      }
      return cloudTypeToAccountTabsMap.get(cloudAccountType);
    };
    const tabs = [...userTabs, ...(getCloudTabs() || [])];
    const settingsTabIndex = tabs.findIndex((tab) => tab === TABS_IDS.SETTINGS);
    if (settingsTabIndex > -1) {
      // Move settings tab to the end of the tab list
      tabs.push(tabs[settingsTabIndex]);
      tabs.splice(settingsTabIndex, 1);
    }
    const costCenterTabIndex = tabs.findIndex((tab) => tab === TABS_IDS.COST_CENTER);
    if (costCenterTabIndex > -1 && cloudAccountType === CLOUD_TYPE_IDS.MULTI) {
      tabs.splice(costCenterTabIndex, 1);
    }
    const channelsTabIndex = tabs.findIndex((tab) => tab === TABS_IDS.CHANNELS);
    if (
      channelsTabIndex > -1 &&
      !usersStore.getCurrDisplayedUserIsAdmin() &&
      ![UsersType.SUPER_USER, UsersType.RESELLER_CUSTOMER, UsersType.RESELLER_CUSTOMER_EDP].includes(userType)
    ) {
      tabs.splice(channelsTabIndex, 1);
    }
    return tabs || [];
  };

  const getSubscriptionTabName = () => {
    const { currDispUserCloudAccountType: cloudAccountType } = usersStore;
    return cloudAccountType === CLOUD_TYPE_IDS.AZURE ? 'subscriptions' : 'projects';
  };

  const navObjectsMap = new Map([
    [TABS_IDS.CLOUD_ACCOUNTS, { id: '', name: 'Cloud Accounts', title: '', icon: 'cloud' }],
    [
      TABS_IDS.COST_CENTER,
      { id: '', name: `${LabelCoordinator.getFieldLabel('Cost Center')}`, title: '', icon: 'listTree' },
    ],
    [TABS_IDS.USERS, { id: '', name: 'Roles & Users', title: '', icon: 'mainUserRegular' }],
    [TABS_IDS.LINKED_ACCOUNTS, { id: '', name: 'Linked Accounts', title: '', icon: 'linkHorizontal' }],
    [
      TABS_IDS.SUBSCRIPTIONS,
      {
        id: '',
        name: capitalize(getSubscriptionTabName()),
        title: '',
        icon: 'linkHorizontal',
      },
    ],
    [
      TABS_IDS.AZURE_APP_REG,
      {
        id: '',
        name: 'App Registration',
        title: 'Grant permissions to analyze specific resources needed for our savings recommendations tool',
        icon: 'pencilMechanical',
      },
    ],
    [TABS_IDS.AZURE_API_ACCESS_KEY, { id: '', name: 'API Access Key', title: '', icon: 'key' }],
    [TABS_IDS.SETTINGS, { id: '', name: 'Settings', title: '', icon: 'gearRegular' }],
    [TABS_IDS.CHANNELS, { id: '', name: 'Channels', title: '', icon: 'objectIntersect' }],
  ]);
  const componentsMap = new Map([
    [TABS_IDS.CLOUD_ACCOUNTS, CloudAccounts],
    [TABS_IDS.COST_CENTER, DivisionsPage],
    [TABS_IDS.USERS, RolesAndUsers],
    [TABS_IDS.LINKED_ACCOUNTS, LinkedAccounts],
    [TABS_IDS.AZURE_APP_REG, AppRegistrationConfiguration],
    [TABS_IDS.SUBSCRIPTIONS, LinkedAccounts],
    [TABS_IDS.AZURE_API_ACCESS_KEY, ApiAccessKeyPage],
    [TABS_IDS.SETTINGS, Settings],
    [TABS_IDS.CHANNELS, Channels],
  ]);

  const getArrOfNavItems = (tabOrderArray) =>
    tabOrderArray.map((tabId, index) => {
      const navObj = navObjectsMap.get(tabId);
      navObj.id = index;
      return navObj;
    });
  const getArrOfComponents = (tabOrderArray) =>
    tabOrderArray.map((tabId) => {
      const C = componentsMap.get(tabId);
      return (
        <TableHeaderProvider>
          <C key={tabId} {...props} usersStore={usersStore} location={location} />
        </TableHeaderProvider>
      );
    });

  if (usersStore.isLoading) {
    return <Spinner />;
  }

  const tabsOrder = getTabsOrder();
  const arrOfNavItems = getArrOfNavItems(tabsOrder);
  const arrOfComponents = getArrOfComponents(tabsOrder);
  return (
    <Container>
      <PageHeader title={PageNames.ACCOUNT} />
      <Row>
        <ColoredTabs
          arrOfNavItems={arrOfNavItems}
          defaultAccountIndex={
            location.state?.defaultTabId
              ? tabsOrder.indexOf(location.state?.defaultTabId)
              : location.defaultAccountIndex || location.state?.defaultAccountIndex
          }
        >
          {arrOfComponents}
        </ColoredTabs>
      </Row>
    </Container>
  );
};

Account.propTypes = {
  history: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  divisionsStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
};

const ObserverAccount = withRolesProvider(observer(Account));
export default ObserverAccount;
