import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FILTERS, USER_STATUS_FILTERS } from 'recommendationsNew/consts';
import useRecsList from 'recommendationsNew/hooks/react-query/useRecsList';
import Button from 'shared/components/andtComponents/Button';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import RecommendationsNewContext from 'recommendationsNew/contexts/recommendationsNewContext';

import classes from './otherResourceIdRecommendations.module.scss';

const OtherResourceIdRecommendations = ({ resourceId }) => {
  const { setRecommendationFilters: setFiltersContext } = useContext(RecommendationsNewContext);

  const [recommendationsCounter, setRecommendationsCounter] = useState(0);
  const [text, setText] = useState('There are no additional recommendations found on this Resource');

  const currentFilters = {
    [FILTERS.IS_OPEN.id]: true,
    [FILTERS.USER_STATUS.id]: {
      [USER_STATUS_FILTERS.ITEMS.DONE.id]: false,
      [USER_STATUS_FILTERS.ITEMS.EXCLUDED.id]: false,
    },
    [FILTERS.RESOURCE_ID.id]: [{ value: resourceId, label: resourceId }],
  };

  const recommendationsListHook = useRecsList();
  const { data: recommendationsTotal = -1 } = recommendationsListHook.fetchRecommendationsTotal({
    filters: currentFilters,
  });

  useEffect(() => {
    if (recommendationsTotal >= 0) {
      const otherRecommendationsCounter = recommendationsTotal - 1;
      setRecommendationsCounter(otherRecommendationsCounter);
      if (otherRecommendationsCounter > 0) {
        setText(
          'There are additional recommendations found on this Resource. Filter to view all related recommendations',
        );
      }
    }
  }, [recommendationsTotal]);

  return recommendationsTotal < 0 ? null : (
    <div className={classes.additionalRecContainer}>
      <div className={classes.text}>{text}</div>
      <Button
        disabled={recommendationsCounter === 0}
        icon={() => <GenerateIcon iconName={ICONS.barsFilter.name} />}
        onClick={() => setFiltersContext({ ...currentFilters })}
        text={`Other Recommendations (${recommendationsCounter})`}
      />
    </div>
  );
};

OtherResourceIdRecommendations.propTypes = {
  resourceId: PropTypes.string.isRequired,
};

export default OtherResourceIdRecommendations;
