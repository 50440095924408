import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import {
  Grid,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { observer } from 'mobx-react';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { alpha } from '@mui/system/colorManipulator';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { IconButton } from '@mui/material';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { PreferencesKeys, PreferencesTypes } from 'users/constants/usersConstants';
import { addPreference, usePreferences } from 'users/hooks/react-query/usePreferences';
import Spinner from 'shared/components/andtComponents/Spinner';
import {
  CALC_METHODS,
  POD_PREFERENCES_TABLE_COLUMNS,
  POD_PREFERENCES_TABLE_COLUMNS_EXTENSIONS,
  POD_PREFERENCES_TABLE_COLUMNS_WIDTHS,
} from '../constants/podPreferences.constants';
import AddOrUpdatePodPreferenceModal from './AddOrUpdatePodPreferenceModal';

const tableStyles = () => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    fontSize: '25.5vw',
  },
});

const PodCalculationPage = ({ usersStore, filtersValuesMap, namespacesFetcher }) => {
  const [addPreferenceModelOpen, setAddPreferenceModalOpen] = useState(false);
  const [updatePreferenceModelOpen, setUpdatePreferenceModalOpen] = useState(null);
  const { fetchPreferences, useSaveMutation } = usePreferences();
  const { data: preferences, isLoading } = fetchPreferences();
  const handleSave = useSaveMutation();
  const filteredPreferences = (preferences || []).filter(
    (p) => p.preferenceType === PreferencesTypes.K8S && p.preferenceKey === PreferencesKeys.POD_COST_CALCULATION,
  );
  const allNamespacesObj = filteredPreferences[0];
  useEffect(() => {
    namespacesFetcher.onLoad();
  }, []);
  const getNamespacePodCalcPreferences = () => {
    if (!allNamespacesObj) {
      return [];
    }
    const { namespacePreferences } = allNamespacesObj.preferences;
    return Object.keys(namespacePreferences).map((key) => ({
      namespace: key,
      calcMethod: namespacePreferences[key].calcMethod,
    }));
  };
  const {
    preferences: { namespacePreferences },
    uuid,
  } = allNamespacesObj || { preferences: {} };
  const namespaces = filtersValuesMap.get('namespace') || [];
  const preferencesOptions = getNamespacePodCalcPreferences()
    .filter((p) => p.namespace !== 'default' || namespaces.includes('default'))
    .map((p) => {
      const method = p.calcMethod;
      const item = CALC_METHODS.find((m) => m.value === method) || {};
      return {
        ...p,
        calcMethodLabel: item.label || null,
      };
    });
  preferencesOptions.sort((a, b) => {
    if (a.namespace === 'default') {
      return -1;
    }
    if (b.namespace === 'default') {
      return 1;
    }
    return a.namespace.localeCompare(b.namespace);
  });
  const handleDeletePreference = async (namespace) => {
    const newPreferences = lodash.cloneDeep(allNamespacesObj.preferences.namespacePreferences);
    delete newPreferences[namespace];
    await handleSave.mutateAsync(
      addPreference(preferences, {
        ...allNamespacesObj,
        preferences: {
          ...allNamespacesObj.preferences,
          namespacePreferences: newPreferences,
        },
      }),
    );
  };
  const userActionsFormatter = (data) => {
    const { currentUserReadOnly } = usersStore;
    const actionsDisabled = data.row.namespace === 'default';
    return (
      <div style={{ display: 'flex' }}>
        <ReadOnlyDisplayWrapper isHide={false} userReadOnly={currentUserReadOnly}>
          <Tooltip title="Delete">
            <IconButton
              disabled={actionsDisabled}
              onClick={() => handleDeletePreference(data.row.namespace)}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ReadOnlyDisplayWrapper>
        <ReadOnlyDisplayWrapper isHide={false} userReadOnly={currentUserReadOnly}>
          <Tooltip title="Edit">
            <IconButton
              disabled={actionsDisabled}
              onClick={() => setUpdatePreferenceModalOpen(data.row)}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </ReadOnlyDisplayWrapper>
      </div>
    );
  };
  if (isLoading || isEmptyArray(namespaces)) {
    return (
      <Container>
        <Card>
          <CardBody>
            <Spinner />
          </CardBody>
        </Card>
      </Container>
    );
  }
  return (
    <Container>
      {addPreferenceModelOpen && (
        <AddOrUpdatePodPreferenceModal
          isOpen={addPreferenceModelOpen}
          toggle={() => setAddPreferenceModalOpen(false)}
          namespacePreferences={preferencesOptions}
          usersStore={usersStore}
          filtersValuesMap={filtersValuesMap}
          allNamespacesPreferences={namespacePreferences}
          uuid={uuid}
        />
      )}
      {updatePreferenceModelOpen && (
        <AddOrUpdatePodPreferenceModal
          updateMode
          isOpen={!!updatePreferenceModelOpen}
          namespacePreferences={preferencesOptions}
          item={updatePreferenceModelOpen}
          toggle={() => setUpdatePreferenceModalOpen(null)}
          filtersValuesMap={filtersValuesMap}
          usersStore={usersStore}
          allNamespacesPreferences={namespacePreferences}
          uuid={uuid}
        />
      )}
      <Row className="d-flex justify-content-start align-items-center" style={{ margin: '0 0 10px 0' }}>
        <div style={{ padding: '5px', marginRight: '10px' }}>
          <LinkIconButton>
            <button className="btn-no-style" type="button" onClick={() => setAddPreferenceModalOpen(true)} />
            <IconFromPng size={30} icon={ICONS.plus} />
          </LinkIconButton>
        </div>
        <div style={{ fontSize: '1.2vw', padding: '5px' }}>
          <span>Add Pod Calculation Preferences</span>
        </div>
      </Row>
      <Col xs={12}>
        <Paper>
          <Grid rows={preferencesOptions} columns={POD_PREFERENCES_TABLE_COLUMNS}>
            <FilteringState
              defaultFilters={[]}
              columnExtensions={[{ columnName: 'userAction', filteringEnabled: false }]}
            />
            <IntegratedFiltering />
            <DataTypeProvider for={['userAction']} formatterComponent={userActionsFormatter} />
            <TableWrapper columnExtensions={POD_PREFERENCES_TABLE_COLUMNS_EXTENSIONS} styles={tableStyles} />
            <TableColumnResizing defaultColumnWidths={POD_PREFERENCES_TABLE_COLUMNS_WIDTHS} />
            <TableHeaderRow />
            <TableFilterRow />
            <Toolbar />
          </Grid>
        </Paper>
      </Col>
    </Container>
  );
};

PodCalculationPage.propTypes = {
  usersStore: PropTypes.object.isRequired,
  namespacesFetcher: PropTypes.object.isRequired,
  filtersValuesMap: PropTypes.object.isRequired,
};

export default withInvoiceFiltersContextConsumer(observer(PodCalculationPage));
