/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import { debounce } from 'lodash';
import { GenerateIcon, ICONS, EmptyDdIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import SelectIconsMultiMenu from 'recommendationsNew/components/heatMap/heatMapFilters/servicesFilter/selectMultiMenuItem/SelectIconsMultiMenu';
import { ViewByPanel } from 'recommendationsNew/components/heatMap/heatMapFilters/servicesFilter/selectMultiMenuItem/ViewByPanel';

import classes from './SelectMulti.module.scss';

export const MENU_HEIGHT = 300;

const customStyles = (menuWidth) => ({
  option: (base) => ({
    ...base,
    width: '100%',
    height: '100%',
    borderRadius: 0,
    borderWidth: 0,
    margin: 0,
    padding: 0,
    display: 'flex',
    backgroundColor: 'unset',
    '&:active': {
      backgroundColor: 'unset',
    },
    '&:hover': {
      backgroundColor: 'unset',
    },
    '&:focus': {
      backgroundColor: 'unset',
    },
  }),
  container: (base) => ({
    ...base,
    width: menuWidth,
    margin: 0,
    position: 'absolute',
    backgroundColor: 'white',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    boxShadow: 'rgba(0, 0, 36, 0.25) 0px 4px 12px -2px',
  }),
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    padding: '0 15px',
    borderRadius: 'unset',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottom: '1px solid #C9DBFF',
    backgroundColor: 'rgba(38, 113, 255, 0.1)',
    '> div': {
      display: 'flex',
      padding: 'unset',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
    },
    '&:hover': {
      borderBottom: '1px solid #C9DBFF',
    },
  }),
  indicatorSeparator: () => ({}),
  menu: (base) => ({
    ...base,
    // isIconsViewEnabled ?
    margin: 0,
    border: 0,
    borderRadius: 6,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: '0 4px 12px -2px rgba(0, 0, 36, 0.25)',
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: MENU_HEIGHT,
    minHeight: MENU_HEIGHT,
    overflowY: 'hidden',
    position: 'relative',
  }),
  input: () => ({
    width: '100%',
  }),
});

function EmptyComponent() {
  // eslint-disable-next-line react/jsx-filename-extension
  return <span />;
}

function OptionMulti(props) {
  const { isSelected, label, isFocused } = props;
  return (
    <components.Option {...props}>
      <div className={`${classes.option} ${isFocused ? classes.focused : null}`}>
        <input className={classes.Checkbox} type="checkbox" checked={isSelected} onChange={() => null} />
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label className={`${classes.Label} ${isSelected ? classes.activeOption : ''}`}>{label}</label>
      </div>
    </components.Option>
  );
}
OptionMulti.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  isFocused: PropTypes.bool.isRequired,
};

// eslint-disable-next-line react/prop-types
function FooterComponent({ selectedCount, onClick, optionsCount, children }) {
  const buttonText = selectedCount > 0 ? 'Clear All' : 'Select All';
  return (
    <div className={classes.ActionBar}>
      <div>{children}</div>
      <div className={classes.ActionBtn} onClick={onClick}>
        <span role="presentation" className={classes.clear}>
          {buttonText}
        </span>
        <span>{`(${selectedCount > 0 ? selectedCount : optionsCount})`}</span>
      </div>
    </div>
  );
}

function NothingToShow() {
  return (
    <div className={classes.EmptyIconContainer}>
      <EmptyDdIcon />
      <span>Nothing Found</span>
    </div>
  );
}

function MenuList(props) {
  // eslint-disable-next-line react/prop-types,react/destructuring-assignment
  const { MenuListFooter = null } = props.selectProps.components;
  // eslint-disable-next-line react/prop-types
  const { children } = props;
  const isEmpty = !Array.isArray(children);

  const rowRenderer = ({ index, style }) => <div style={style}>{children[index]}</div>;

  return (
    <>
      {isEmpty ? (
        <>{children}</>
      ) : (
        // eslint-disable-next-line react/prop-types
        <List style={{ margin: '4px' }} height={MENU_HEIGHT} itemSize={37} itemCount={children.length}>
          {rowRenderer}
        </List>
      )}
      {MenuListFooter}
    </>
  );
}

export function SelectMultiItem({
  hideFooter,
  options,
  selected,
  onChange,
  isOpen,
  onMenuBlur,
  width,
  children,
  isIconsViewEnabled,
  cloudType,
  containerProps,
  ...props
}) {
  const selectRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState(selected || []);
  const [formattedOptions, setFormattedOptions] = useState(options);

  const [isIconsViewSelected, setIsIconsViewSelected] = useState(true);

  const resetFocus = () =>
    setTimeout(() => {
      selectRef.current?.focus();
    }, 1);

  useEffect(() => {
    if (isOpen) {
      resetFocus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const tempOptions = [];
      const isValid = (val) => val !== undefined && val !== null && val !== '';
      let i = 0;
      for (i; i < options.length; i++) {
        const { value, label } = options[i];
        if (!isValid(value) && !isValid(label)) {
          // eslint-disable-next-line no-continue
          continue;
        }
        if (!isValid(value)) {
          if (!selectedItems.some((item) => item.value === label)) {
            tempOptions.push({ value: label, label });
          }
        } else if (!isValid(label)) {
          if (!selectedItems.some((item) => item.value === value)) {
            tempOptions.push({ value, label: value });
          }
        } else if (!selectedItems.some((item) => item.value === value)) {
          tempOptions.push(options[i]);
        }
      }
      const tempSelectedItems = [...selectedItems].sort((a, b) => a.label.localeCompare(b.label));
      const formatted = [...tempSelectedItems, ...tempOptions];
      setFormattedOptions(formatted);
      setFilteredOptions(formatted);
    }
  }, [options, isOpen]);

  useEffect(() => {
    setSelectedItems(selected);
  }, [selected]);

  const onBlur = (e) => {
    onMenuBlur(e);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'blurLayer') {
      onMenuBlur(e);
    }
  };

  function SearchInput(props) {
    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
    if (props.value === '') {
      resetFocus();
    }
    // eslint-disable-next-line react/prop-types,react/destructuring-assignment
    if (!props.selectProps.menuIsOpen) {
      return <components.Input {...props} />;
    }
    return (
      <div className={classes.InputSearch}>
        <GenerateIcon iconName={ICONS.magnifyingGlass.name} className={classes.IconGray} />
        {/* eslint-disable-next-line react/prop-types,react/destructuring-assignment */}
        <span className={classes.Placeholder}>{!props.selectProps.inputValue ? 'Search' : null}</span>
        <div className={classes.inputEnv}>
          <components.Input {...props} />
        </div>
        {/* eslint-disable-next-line react/prop-types,react/destructuring-assignment */}
        {props.selectProps?.inputValue ? (
          <div
            className={classes.IconClear}
            onClick={(e) => {
              e.currentTarget.name = 'clearBtn';
            }}
          >
            <GenerateIcon className={classes.IconGray} iconName={ICONS.circleXmark.name} />
          </div>
        ) : null}
      </div>
    );
  }

  const selectedOptionsCount = () => {
    if (!filteredOptions.length) {
      return selectedItems.length;
    }
    const selectedFilterOption = filteredOptions.filter((fo) => selectedItems.find((s) => s.value === fo.value));
    return selectedFilterOption.length;
  };

  const onFooterClick = () => {
    let tempItems = [];
    const selectedFilteredOptions = selectedItems.filter((fo) => filteredOptions.find((s) => s.value === fo.value));
    const selectedUnfilteredOptions = selectedItems.filter((fo) => !filteredOptions.find((s) => s.value === fo.value));
    if (selectedFilteredOptions.length > 0) {
      tempItems = selectedUnfilteredOptions;
    } else {
      tempItems = [...selectedUnfilteredOptions, ...filteredOptions.filter((opt) => !opt.isDisabled)];
    }
    setSelectedItems(tempItems);
    onChange(tempItems);
    resetFocus();
  };

  const customComponents = {
    DropdownIndicator: EmptyComponent,
    MultiValue: EmptyComponent,
    SingleValue: EmptyComponent,
    MultiValueRemove: EmptyComponent,
    Placeholder: EmptyComponent,
    ClearIndicator: EmptyComponent,
    Option: OptionMulti,
    NoOptionsMessage: NothingToShow,
    MenuList,
    Input: (props) => SearchInput(props),
    MenuListFooter: hideFooter ? (
      EmptyComponent
    ) : (
      <FooterComponent
        selectedCount={selectedOptionsCount()}
        onClick={onFooterClick}
        optionsCount={filteredOptions.filter((opt) => !opt.isDisabled).length}
      >
        {children}
      </FooterComponent>
    ),
  };

  const selectItem = (items) => {
    setSelectedItems(items);
    onChange(items);
  };

  const onChangeViewClick = (e) => {
    setIsIconsViewSelected(e.isIconsViewSelected);
  };

  const searchInputChanged = (val, reason) => {
    if (reason.action === 'set-value') {
      resetFocus();
      return;
    }
    setInputValue(val);
    resetFocus();
    const searchOptions = () => {
      if (reason.action === 'menu-close' && reason.prevInputValue !== '') {
        setFilteredOptions(formattedOptions);
        resetFocus();
        return;
      }
      if (reason.action === 'input-change') {
        const o = options.filter((op) => !val || op.label.toLowerCase().indexOf(val.toLowerCase()) > -1);
        setFilteredOptions(o);
      } else {
        setFilteredOptions(formattedOptions);
      }
      resetFocus();
    };
    debounce(searchOptions, 200)();
  };

  return (
    <div className={classes.selectEnv}>
      {isOpen && (
        <>
          <div
            id="blurLayer"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
              background: 'rgba(0, 0, 0, 0)',
            }}
            onClick={handleOutsideClick}
          />
          <div style={{ zIndex: 2, position: 'absolute' }}>
            {isIconsViewEnabled && (
              <ViewByPanel
                isIconsViewSelected={isIconsViewSelected}
                onChangeViewClick={onChangeViewClick}
                viewByPanelWidth={width}
                onBlur={onBlur}
                containerProps={containerProps}
              />
            )}
            {isIconsViewEnabled && isIconsViewSelected ? (
              <SelectIconsMultiMenu
                menuIsOpen={isOpen}
                options={formattedOptions}
                onBlur={onBlur}
                selectedOptions={selectedItems}
                onSelectionChanged={selectItem}
                cloudType={cloudType}
                menuWidth={width}
                searchValue={inputValue}
                onChangeSearchInputValue={(value) => setInputValue(value)}
                containerProps={containerProps}
                isLoading={props.isLoading}
              />
            ) : (
              <Select
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...props}
                menuIsOpen={isOpen}
                ref={selectRef}
                onChange={selectItem}
                onInputChange={searchInputChanged}
                options={formattedOptions}
                components={customComponents}
                value={selectedItems}
                styles={customStyles(width)}
                isMulti
                autoFocus
                closeMenuOnSelect={false}
                escapeClearsValue
                hideSelectedOptions={false}
                onBlur={onBlur}
                defaultMenuIsOpen
                inputValue={inputValue}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

SelectMultiItem.propTypes = {
  isOpen: PropTypes.bool,
  hideFooter: PropTypes.bool,
  isIconsViewEnabled: PropTypes.bool,
  onMenuBlur: PropTypes.func.isRequired,
  options: PropTypes.array,
  width: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func,
  children: PropTypes.func,
  cloudType: PropTypes.number,
  containerProps: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

SelectMultiItem.defaultProps = {
  isOpen: false,
  options: [],
  width: '100%',
  selected: [],
  hideFooter: false,
  children: null,
  isIconsViewEnabled: false,
  onChange: () => {},
  cloudType: 0,
  isLoading: false,
};
