/* eslint-disable no-unused-vars, quotes */
import React from 'react';
import Alert from 'shared/components/Alert';

export default function AlertGoToNext({ toShow, updateAlertClosed, steps }) {
  return (
    false &&
    toShow && ( // hiding the alert for now
      <Alert color="success" icon="lnr lnr-cross-circle" className="alert--bordered" handleClose={updateAlertClosed}>
        {`Just a few more steps to go! Click on the “Next” button`}
      </Alert>
    )
  );
}
