/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-parens */
/* eslint-disable max-len */
/* eslint-disable comma-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable function-paren-newline */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/system/colorManipulator';
import { withStyles } from '@mui/styles';
import { IntegratedSorting, DataTypeProvider, RowDetailState, SortingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableRowDetail,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import { Col, Row } from 'reactstrap';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import { kFormatter } from 'shared/utils/strUtil';
import { mapTableFieldToDisplay, TABS_IDS } from 'users/constants/usersConstants';
import { Routes } from 'shared/constants/routes';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import AlertActionButton from './AlertActionButton';

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    fontSize: '25.5vw',
  },
});

const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const linkStyle = {
  display: 'inline-block',
  position: 'relative',
  zIndex: 1,
  padding: '1em',
  margin: '-1em',
};

class AlertsTable extends React.Component {
  static propTypes = {
    rows: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    columnWidths: PropTypes.object.isRequired,
    tableColumnExtensions: PropTypes.object.isRequired,
    defaultSorting: PropTypes.object.isRequired,
    handler: PropTypes.func.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { columns, columnWidths, tableColumnExtensions } = this.props;
    this.state = {
      columns,
      columnWidths,
      tableColumnExtensions,
      hiddenColumnNames: [],
      // hiddenColumnNames: this.props.defaultHiddenColumnNames,
      searchValue: '',
    };
  }

  userActionsFormatter = (data) => {
    withStyles(data);
    return (
      <div>
        <AlertActionButton alert={data.row} handler={this.props.handler} />
      </div>
    );
  };

  currenciesFormatter = (value) => this.props.getCurrencyNumber(kFormatter(value.value));

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  changeSearchValue = (value) => this.setState({ searchValue: value });

  createRowDetails = (row) => {
    const arrOfKeys = ['accountId', 'linkedAccountId'];
    return (
      <div>
        {arrOfKeys.map((key) => (
          <Col xs={12} md={12} lg={12} xl={12}>
            <div style={{ padding: '5px', margin: '0 5px' }}>
              <b>{`${mapTableFieldToDisplay.get(key)}:  `}</b>
              <span>{`${row[key]}`}</span>
            </div>
          </Col>
        ))}
        <Col
          style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}
          xs="auto"
          md="auto"
          lg="auto"
          xl="auto"
        >
          <LinkIconButton>
            <Link
              to={{
                pathname: Routes.ACCOUNT,
                state: {
                  defaultTabId: TABS_IDS.LINKED_ACCOUNTS,
                },
              }}
            />
            <span>Check Linked account</span>
            <span className="lnr lnr-chevron-right-circle" />
            {/* <NavigateNextIcon /> */}
          </LinkIconButton>
        </Col>
      </div>
    );
  };

  RowDetail = ({ row }) => (
    <Row>
      <Col xs={12} md={12} lg={12} xl={12}>
        <h5 className="inner-wizard-items-header" style={{ padding: '10px', margin: '0 10px' }}>
          <span>{row.description}</span>
        </h5>
      </Col>
      <Col xs={12} md={12} lg={12} xl={12}>
        {this.createRowDetails(row)}
        <br />
        <hr />
      </Col>
    </Row>
  );

  render() {
    const { columns, columnWidths, tableColumnExtensions, hiddenColumnNames, searchValue } = this.state;
    const { rows, handler, defaultSorting } = this.props;

    return (
      <div style={{ width: '100%' }} ref={this.refCallback}>
        <Col xs={12} md={12} lg={12} xl={12}>
          <Paper>
            <Grid rows={rows} columns={columns}>
              {/* <FilteringState defaultFilters={[]} />
              <IntegratedFiltering /> */}
              <SortingState defaultSorting={defaultSorting} />
              <IntegratedSorting />
              <RowDetailState />
              <DataTypeProvider for={['userAction']} formatterComponent={this.userActionsFormatter} />
              <TableWrapper columnExtensions={tableColumnExtensions} styles={styles} />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableRowDetail contentComponent={this.RowDetail} />
              {/* <TableFilterRow /> */}
              <TableColumnVisibility
                // hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
              />
              <Toolbar />
              <ColumnChooser />
            </Grid>
          </Paper>
        </Col>
      </div>
    );
  }
}

const ObserverAlertsTable = withUserSettingsConsumer(AlertsTable);
export default ObserverAlertsTable;
