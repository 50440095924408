import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import classes from './SelectIconsMultiMenu.module.scss';

export const ServicesImageTag = ({ label, defaultIconLabel }) => {
  const iconImageRef = useRef(null);
  const cloudTypeString = 'aws';

  useEffect(() => {
    // Access the current property of the ref to get the DOM element
    const iconImage = iconImageRef.current;

    let iconSrc;
    try {
      /* eslint-disable-next-line global-require,import/no-dynamic-require */
      iconSrc = require(`../servicesIcons/${cloudTypeString}/${label}.svg`);
      // Do something with the icon
    } catch (error) {
      /* eslint-disable-next-line global-require,import/no-dynamic-require,max-len */
      iconSrc = require(`../servicesIcons/${cloudTypeString}/${defaultIconLabel}.svg`);
    }
    iconImage.src = iconSrc;
  }, []);

  return <img ref={iconImageRef} alt="iconService" className={classes.serviceIcon} />;
};

ServicesImageTag.propTypes = {
  label: PropTypes.string,
  defaultIconLabel: PropTypes.string,
};

ServicesImageTag.defaultProps = {
  label: '',
  defaultIconLabel: '',
};
