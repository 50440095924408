import React from 'react';
import PropTypes from 'prop-types';
import {
  generateCreateRoleCmd,
  generatePutRolePolicyCmd,
  generateTopicNotificationCmd,
} from '../helpers/cliCommandsStringGenerators';
import CliExecuteCmd from './CliExecuteCmd';
import ExecuteCliCommands from './ExecuteCliCommands';

const createCliExecuteCmdArray = (strCmds) =>
  strCmds.map((strCmd, idx) => <CliExecuteCmd cmdString={strCmd} id={`strCmd_${idx}`} />);

const ExecuteCliCommandsContainer = React.memo(
  ({ invoiceBucketName, arnRoleNum, handleArnRoleNumChange, bucketRegionName, cmdToExecute, disableEnterKeySub }) => {
    const mapCmdsGenerator = new Map([
      ['topicConfigCmd', generateTopicNotificationCmd(invoiceBucketName)],
      ['roleCmd', generateCreateRoleCmd()],
      ['polictyCmd', generatePutRolePolicyCmd()],
    ]);
    const strCmds = [];
    Object.keys(cmdToExecute).forEach((key) => (cmdToExecute[key] ? strCmds.push(mapCmdsGenerator.get(key)) : null));
    return (
      <ExecuteCliCommands
        handleArnRoleNumChange={handleArnRoleNumChange}
        cmdsToExecute={createCliExecuteCmdArray(strCmds)}
        bucketRegionName={bucketRegionName}
        arnRoleNum={arnRoleNum}
        disableEnterKeySub={disableEnterKeySub}
      />
    );
  },
);
ExecuteCliCommandsContainer.propTypes = {
  invoiceBucketName: PropTypes.string.isRequired,
  arnRoleNum: PropTypes.string.isRequired,
  handleArnRoleNumChange: PropTypes.func.isRequired,
  // onSubmit: PropTypes.func.isRequired,
  disableEnterKeySub: PropTypes.func.isRequired,
  cmdToExecute: PropTypes.object,
  bucketRegionName: PropTypes.string.isRequired,
};

ExecuteCliCommandsContainer.defaultProps = {
  cmdToExecute: {
    topicConfigCmd: true,
    roleCmd: true,
    polictyCmd: true,
  },
};

export default ExecuteCliCommandsContainer;
