/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len, no-unused-vars */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable consistent-return */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Collapse, Container, Row } from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import FieldFilter from 'shared/components/FieldFilter';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import { buildStartAndEndDate, datePickerSyntexDates, getDatePlusDays } from 'shared/utils/dateUtil';
import IdleCriteriaRulesPanel from './components/IdleCriteriaRulesPanel';
import IdleInstancesTable from './components/IdleInstancesTable';

class IdleInstancesPage extends Component {
  static propTypes = {
    usageStore: PropTypes.object.isRequired,
    usersStore: PropTypes.object.isRequired,
    invoiceStore: PropTypes.object.isRequired,
    filtersValuesMap: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      filterSelectedOptionsMap: new Map([['criterianame', [{ label: 'All', value: 'all' }]]]),
      // filterSelectedOptionsMap: new Map([['linkedaccid', []]]),
      startDate: buildStartAndEndDate(null, null).startDate,
      endDate: buildStartAndEndDate(null, null).endDate,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleGetIdleInstancesTableData = this.handleGetIdleInstancesTableData.bind(this);
  }

  componentDidMount() {
    const { usageStore } = this.props;
    usageStore.idleInstancesModel.fetchData();
  }

  toggleCollapse = () => {
    const { collapse } = this.state;
    this.setState({ collapse: !collapse });
  };

  handleGetIdleInstancesTableData = async () => {
    this.setState({ filterFormMsg: '' });
    try {
      let linkedAccounts = this.state.filterSelectedOptionsMap.get('linkedaccid');
      let criteriaIds = this.state.filterSelectedOptionsMap.get('criterianame');
      if (!linkedAccounts || !linkedAccounts.length) {
        this.setState({ filterFormMsg: '* Please select at least one linked account' });
        return null;
      }
      linkedAccounts = linkedAccounts.map((linkedAcc) => linkedAcc.value);
      criteriaIds = criteriaIds.find((cn) => cn.value === 'all') ? null : criteriaIds.map((cn) => cn.value);
      const body = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        linkedAccounts,
        criteriaIds,
      };
      this.props.usageStore.idleInstancesModel.isLoadingIdleInstancesTableData = true;
      await this.props.usageStore.getIdleInstancesTableData(body);
    } catch (error) {
      this.setState({ filterFormMsg: 'Something went wrong, please try again later' });
    }
  };

  fetchInstanceData = async (criteriaId, instanceId, startDate, endDate) => {
    const data = await this.props.usageStore.getIdleInstanceRowData(criteriaId, instanceId, startDate, endDate);
    return data;
  };

  handleDateChange = ({ startDate, endDate }) => {
    const start = startDate || this.state.startDate;
    let end = endDate || this.state.endDate;
    if (start > end && end !== null) {
      end = start;
    }
    const modStartDate = buildStartAndEndDate(start, end).startDate;
    const modEndDate = buildStartAndEndDate(start, end).endDate;
    this.setState({
      startDate: modStartDate,
      endDate: modEndDate,
    });
  };

  selecLinkAccFilterValueHandler = (filterType, selectedOptions) => {
    const { filterSelectedOptionsMap } = this.state;
    const selectedOptionsMap = new Map(filterSelectedOptionsMap);
    selectedOptionsMap.set(filterType, selectedOptions);
    this.setState({ filterSelectedOptionsMap: selectedOptionsMap, filterFormMsg: '' });
  };

  selecCriteriNameFilterValueHandler = (filterType, selectedOptions) => {
    const { filterSelectedOptionsMap } = this.state;
    const allIndex = selectedOptions.findIndex((option) => option.value === 'all');
    let validSelcetedOption = [...selectedOptions];
    if (allIndex > -1) {
      if (allIndex === selectedOptions.length - 1) {
        // just added all
        validSelcetedOption = [{ label: 'All', value: 'all' }];
      } else {
        validSelcetedOption.splice(allIndex, 1);
      }
    }
    const selectedOptionsMap = new Map(filterSelectedOptionsMap);
    selectedOptionsMap.set(filterType, validSelcetedOption);
    this.setState({ filterSelectedOptionsMap: selectedOptionsMap, filterFormMsg: '' });
  };

  render() {
    const { usageStore, filtersValuesMap, invoiceStore, usersStore } = this.props;
    const { startDate, endDate, maxCpuUtil, collapse, filterSelectedOptionsMap, filterFormMsg } = this.state;
    if (usageStore.isLoading) {
      return <Spinner />;
    }
    const { currentUserReadOnly } = usersStore;

    const { isLoadingIdleInstancesTableData, idleInstancesTableData, isLoadingCriterias, idleInstancesCriterias } =
      usageStore.idleInstancesModel;
    const availableLinkedAccountsForFilter = usageStore.getIdleInstancesCriteriaAvailableLinkedAccounts();
    const preparedLinkedAccountsOptions = usageStore.getPreparedLinkedAccountsOptions(
      availableLinkedAccountsForFilter,
      filtersValuesMap.get('linkedaccid'),
    );
    const preparedCriteriaNamesForFilter = usageStore.getIdleInstancesAvailableCriteriaNames();
    const preparedIdleInstancesTableData = idleInstancesTableData
      ? idleInstancesTableData.map((datum) => {
          const currLinkedAccItem =
            preparedLinkedAccountsOptions.find((item) => item.value === datum.linkedAccountId) || {};
          return {
            ...datum,
            linkedAccount: currLinkedAccItem.label || datum.linkedAccountId,
          };
        })
      : null;
    return (
      <Container>
        <PageHeader title={PageNames.IDLE_INSTANCES} />
        <Card>
          <CardBody className="idle-instances-body">
            {/* {this.renderFileTypeErrorMessage()} */}
            <div className="collapse-title">
              {collapse ? (
                <MinusIcon onClick={this.toggleCollapse} className="collapse-icon" />
              ) : (
                <PlusIcon onClick={this.toggleCollapse} className="collapse-icon" />
              )}
              <h5 onClick={this.toggleCollapse} style={{ cursor: 'pointer', fontSize: '18px' }}>
                Idle Instances Criteria
              </h5>
            </div>
            <Collapse isOpen={collapse || idleInstancesCriterias.length === 0}>
              <ReadOnlyDisplayWrapper isHide={false} userReadOnly={currentUserReadOnly}>
                <IdleCriteriaRulesPanel
                  invoiceStore={invoiceStore}
                  usageStore={usageStore}
                  currentUserReadOnly={usersStore.currentUserReadOnly}
                  existingCriteriasNames={preparedCriteriaNamesForFilter}
                />
              </ReadOnlyDisplayWrapper>
            </Collapse>
            {!idleInstancesCriterias || !idleInstancesCriterias.length ? null : (
              <div className="filters-collapse-container">
                <Card>
                  <CardBody className="filters-card-body">
                    <DatePickerFilter
                      onDateChange={this.handleDateChange}
                      startDate={datePickerSyntexDates(startDate, null).startDate}
                      endDate={datePickerSyntexDates(null, endDate).endDate}
                      maxDate={getDatePlusDays(startDate, 90)}
                      currPeriodGranLevel="day"
                      className="idle-instance-datepicker"
                    />
                    <Row className="linked-acc-filter">
                      <Col>
                        <h5 className="filter-bar-inner-col-title">Criteria Name</h5>
                        <FieldFilter
                          type="criterianame"
                          value={filterSelectedOptionsMap.get('criterianame')}
                          options={preparedCriteriaNamesForFilter}
                          handleChange={this.selecCriteriNameFilterValueHandler}
                        />
                      </Col>
                      <Col>
                        <h5 className="filter-bar-inner-col-title">Linked Account</h5>
                        <FieldFilter
                          type="linkedaccid"
                          value={filterSelectedOptionsMap.get('linkedaccid')}
                          options={preparedLinkedAccountsOptions}
                          handleChange={this.selecLinkAccFilterValueHandler}
                        />
                      </Col>
                    </Row>
                    <div style={{ marginLeft: -15 }}>
                      <Button
                        className="form__button-toolbar"
                        // type="submit"
                        color="success"
                        size="sm"
                        onClick={this.handleGetIdleInstancesTableData}
                      >
                        Filter
                      </Button>
                      <h5 className="filter-bar-inner-col-title filter-msg">{filterFormMsg}</h5>
                    </div>
                  </CardBody>
                </Card>
              </div>
            )}
            <div>
              {idleInstancesTableData || isLoadingIdleInstancesTableData ? (
                isLoadingIdleInstancesTableData ? (
                  <Spinner customStyle={{ width: '200px' }} />
                ) : (
                  <IdleInstancesTable
                    idleInstancesData={preparedIdleInstancesTableData}
                    fetchInstanceData={this.fetchInstanceData}
                    startDate={startDate}
                    endDate={endDate}
                    isLoadingIdleInstancesRowData={usageStore.idleInstancesModel.isLoadingIdleInstancesRowData}
                    idleInstancesRowData={usageStore.idleInstancesModel.idleInstancesRowData}
                  />
                )
              ) : null}
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const ObserverIdleInstances = withInvoiceFiltersContextConsumer(observer(IdleInstancesPage));
export default ObserverIdleInstances;
