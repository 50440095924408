import React from 'react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';

import Button from 'shared/components/andtComponents/Button';
import OnboardingLinks from 'app/containers/Onboarding/utils/OnboardingLinks';

import { ReactComponent as RocketIcon } from './assets/rocket.svg';
import AccountTypeImage from '../../assets/accountType.jpg';
import styles from './AccountDetailsModal.module.scss';

const AccountDetailsHelp = {};

AccountDetailsHelp.OnboardingType = {
  component: () => (
    <>
      <div className={styles.onboardingTypeLabel}>
        <p>
          <RocketIcon className={styles.rocketIcon} />
          Automatically
        </p>
        <span>Run scripts automatically in order to grant access.</span>
        <Button
          onClick={() => window.open(OnboardingLinks.AZURE.onboardingType, '_blank')}
          text="Learn more"
          isTextButton
          overrideStyles={{ height: 20 }}
        />
      </div>
      <div className={styles.onboardingTypeLabel}>
        <p>
          <GenerateIcon iconName={ICONS.gear.name} />
          Manually
        </p>
        <span>Grant Anodot access to your Cloud accounts and update each script manually.</span>
        <Button
          text="Learn more"
          onClick={() => window.open(OnboardingLinks.AZURE.onboardingType, '_blank')}
          isTextButton
          overrideStyles={{ height: 20 }}
        />
      </div>
    </>
  ),
};

AccountDetailsHelp.AzureAccountType = {
  component: () => (
    <div className={styles.azureAccountTypeHelp}>
      <p>
        On Azure portal navigate to Cost Management + Billing page, select the relevant billing scope and click on
        Properties.
        <br />
        <p>
          <span>
            <span className={styles.noteTitle}>Notice:</span>
            <br />
            1. You can onboard Amortized export only for CSP, EA and MCA types.
            <br />
            2. All the accounts will be created using billing export.
          </span>
        </p>
      </p>
    </div>
  ),
  image: AccountTypeImage,
  imageHeight: 110,
};

AccountDetailsHelp.MSPAccountType = {
  component: () => (
    <div className={styles.mspAccountTypeHelp}>
      <p className={styles.mspAccountTypeHelpTitle}>
        <GenerateIcon iconName={ICONS.mainUser.name} />
        Dedicated Account
      </p>
      <p>
        This is for a single customer where <span>all</span> RIs and SPs belong to the customer <span>only</span>. If
        this is not the case, please select <span>Shared Account</span>.
      </p>
      <Button
        onClick={() => window.open(OnboardingLinks.AZURE.customerType, '_blank')}
        text="Learn more"
        isTextButton
        overrideStyles={{ paddingLeft: 0, height: 20 }}
      />
      <p>
        <span className={styles.noteTitle}>Notice:</span>
        <br />
        The customer will be able to see the Commitments section including ALL SP and RI purchases under this account.
        If there are purchases that do not belong to the customer, they will see all purchases.
      </p>
      <p className={styles.mspAccountTypeHelpTitle}>
        <GenerateIcon iconName={ICONS.users.name} />
        Shared Account
      </p>
      <p>This is for multiple customers, every subscription must be manually assigned to the relevant customer.</p>
      <Button
        onClick={() => window.open(OnboardingLinks.AZURE.customerType, '_blank')}
        text="Learn more"
        isTextButton
        overrideStyles={{ paddingLeft: 0, height: 20 }}
      />
    </div>
  ),
};

export default AccountDetailsHelp;
