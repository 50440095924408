import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import App from './app/containers/App/App';
import { queryClient } from './queryClient';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Router>
    <QueryClientProvider client={queryClient}>
      <App queryClient={queryClient} />
    </QueryClientProvider>
  </Router>,
);
