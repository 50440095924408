import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import LabelCoordinator from '../modules/labelCoordinator';

export const getCustomTagFilterLabel = (field, cloudType = CLOUD_TYPE_IDS.AWS) => {
  let label = `${LabelCoordinator.getFieldLabelByCloudType('tag', cloudType)} - ${field.split('customtags:')[1]}`;
  label = field.includes('virtual') ? `Virtual Tag - ${field.split(':')[1]}` : label;
  label = field.includes('views') ? `View - ${field.split(':')[1]}` : label;
  return label;
};

export const getPayerLinkedAccount = ({ account, currDispUserCloudAccountType }) => {
  if (account && currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS) {
    const linkedAccountId = account.accountId;
    const linkedAccountName = account.accountName.split('(')[0];
    return { linkedAccountId, linkedAccountName };
  }
  return null;
};
