import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line max-len
import RecommendationDetails from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationDetails';
import {
  BASE_PROPERTIES,
  COST_PROPERTIES,
} from 'recommendationsNew/components/detailedRecommendation/recommendationPropertyUtils';
// eslint-disable-next-line max-len
import RecommendationDetailsLayout from 'recommendationsNew/components/detailedRecommendation/recommendationDetailsLayout';
// eslint-disable-next-line max-len
import RecommendationPreferences from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationPreferences';
// eslint-disable-next-line max-len
import RecommendedHourlyCommitment from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendedHourlyCommitment';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';

const SAVINGS_PLAN_PROPERTIES = {
  SAVINGS_PLAN_TYPE: {
    label: 'Savings Plan Type',
    getterFunction: (recommendation) => recommendation?.recData?.savings_plan_type,
  },
  RECOMMENDED_HOURLY_COMMITMENT: {
    label: 'Recommended Hourly Commitment',
    isCurrency: true,
    getterFunction: (recommendation) => recommendation?.recData?.recommended_hourly_commitment,
  },
  UPFRONT_COST: {
    label: 'Upfront Cost',
    isCurrency: true,
    getterFunction: (recommendation) =>
      recommendation?.recData?.recommended_upfront_payment !== undefined
        ? parseFloat(recommendation?.recData?.recommended_upfront_payment)
        : parseFloat(recommendation?.recData?.recommended_partial_upfront_payment),
  },
  PAYMENT_OPTION: {
    label: 'Payment Option',
    getterFunction: (recommendation) => recommendation?.recData?.payment_option,
  },
  TERM: {
    label: 'Term',
    getterFunction: (recommendation) => recommendation?.recData?.term,
  },
  MONTHLY_RECURRING_CHARGES: {
    label: 'Monthly Recurring Charges',
    isCurrency: true,
    getterFunction: (recommendation) => recommendation?.recData?.monthly_recurring_charges,
  },
  TOTAL_COMMITMENT: {
    label: 'Total Commitment',
    isCurrency: true,
    getterFunction: (recommendation) => recommendation?.recData?.total_year_commitment,
  },
};

const Ec2SavingPlan = ({ recommendation }) => {
  const properties = { ...BASE_PROPERTIES, ...COST_PROPERTIES, ...SAVINGS_PLAN_PROPERTIES };

  const description =
    'We recommend you to commit to this Savings plan, since your daily hourly usage is above the stated commitment.';
  const descriptionComponent = <RecommendationInsideLook description={description} />;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  const hourlyCommitmentComponent = (
    <RecommendedHourlyCommitment hourlyCommitment={recommendation?.recData?.recommended_hourly_commitment} />
  );

  return (
    <RecommendationDetailsLayout
      details={detailsComponent}
      preferences={preferencesComponent}
      insideLook={descriptionComponent}
      hourlyCommitment={hourlyCommitmentComponent}
    />
  );
};

Ec2SavingPlan.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default Ec2SavingPlan;
