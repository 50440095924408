/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Card, CardBody, Carousel, CarouselControl, CarouselItem, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { PageNames } from 'shared/constants/appConstants';
import { Routes } from 'shared/constants/routes';
import { UsersType, CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import AlertError from '../shared/components/Alerts/AlertError';
import AlertGoToNext from '../shared/components/Alerts/AlertGoToNext';
import { GcpActiveIndexToTitleText, pages } from './constants/newUserConstants';
import WizardIntro from './components/Intro/WizardIntro';
import CarouselPageNavigator from '../shared/components/LayoutComponents/CarouselPageNavigator';
import GcpDetailsConfiguration from './components/GcpDetailsConfiguration';
import SideBarNavigationTimeline from '../shared/components/LayoutComponents/SideBarNavigationTimeline';

const items = [
  {
    id: pages.page0,
    withProps: false,
    component: <WizardIntro />,
  },
  {
    id: pages.page1,
    withProps: true,
    component: GcpDetailsConfiguration,
  },
];

const WithProps =
  (C) =>
  ({ ...props }) =>
    <C {...props} />;

class NewGcpUserWizard extends Component {
  static propTypes = {
    usersStore: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: pages.page0,
      modal: false,
      projectId: '',
      datasetName: '',
      tableName: '',
      serviceAccountKeyPath: '',
      serviceAccountKeyName: '',
      currencySymbol: null,
      serviceAccountKey: null,
      accountName: '',
      accountId: '',
      clientSecret: '',
      isSavingData: false,
      isDataSaved: false,
      isSaveDataError: false,
      showNoInputValueWarning: false,
      showGoToNextAlert: false,
    };
    // this.next = this.next.bind(this);
  }

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  getSteps = () => [GcpActiveIndexToTitleText.get(pages.page0), GcpActiveIndexToTitleText.get(pages.page1)];

  closeModal = () => {
    this.setState({ modal: false });
  };

  handleGcpConfigInputsChange = (newInput, e) => {
    if (!e) {
      return;
    }
    const currState = { ...this.state };
    this.setState({ [e.target.name]: newInput, isSaveDataError: false });
    currState[e.target.name] = newInput;
    if (
      currState.projectId.length > 0 &&
      currState.datasetName.length > 0 &&
      currState.tableName.length > 0 &&
      currState.serviceAccountKeyPath.length > 0 &&
      currState.accountName.length > 0
    ) {
      this.setState({ showNoInputValueWarning: false, showGoToNextAlert: true });
    }
  };

  handleSelectServiceAccountKey = (name, key) => {
    this.setState({ serviceAccountKeyName: name, serviceAccountKey: key });
  };

  handleSelectCurrency = (value) => {
    this.setState({ currencySymbol: value });
  };

  disableEnterKeySub = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  updateAlertClosed = () => {
    this.setState({ showNoInputValueWarning: false, showGoToNextAlert: false });
    // this.setState({ showGoToNextAlert: false });
  };
  updateDataErrorClosed = () => {
    this.setState({ isSaveDataError: false });
  };

  validateForm = (page) => {
    let result = true;
    if (page === pages.page1) {
      if (
        this.state.projectId.length === 0 ||
        this.state.datasetName.length === 0 ||
        this.state.tableName.length === 0 ||
        this.state.serviceAccountKeyPath.length === 0 ||
        this.state.accountName.length === 0
      ) {
        result = false;
      }
    }
    return result;
  };

  saveGcpDetailsConfiguration = async () => {
    const { projectId, datasetName, tableName, serviceAccountKey, serviceAccountKeyName, accountName, currencySymbol } =
      this.state;
    const gcpDetailsConfiguration = {
      projectId,
      datasetName,
      tableName,
      serviceAccountKey,
      serviceAccountKeyName,
      accountName,
      accountProperties: { currencyCode: currencySymbol?.key },
    };
    try {
      this.setState({ isSavingData: true });
      const connectResult = await this.props.usersStore.connectGcpAccount(gcpDetailsConfiguration);
      if (connectResult.isConnectSuccess) {
        const { accountId } = connectResult;
        this.setState({ isSavingData: false, isDataSaved: connectResult.isConnectSuccess, accountId });
        const user = this.props.usersStore.getUserByKey(this.props.usersStore.currentDisplayedUserKey);
        const { userType } = user;
        if (userType === UsersType.NEW_USER) {
          const newType = UsersType.USER_ON_BOARDED;
          user.userType = newType;
          await this.props.usersStore.updateUserTypeAndAwsAccId(newType, connectResult.accountId, true);
        }
        this.props.history.push(userType === UsersType.NEW_USER ? Routes.WELCOME : Routes.DASHBOARD);
      } else {
        this.setState({ isSavingData: false, isSaveDataError: true });
      }
    } catch (error) {
      this.setState({ isSavingData: false, isSaveDataError: true });
    }
  };

  updateUserConnectionResult = (isConnectionSuccess, awsAccountID) => {
    if (!isConnectionSuccess) {
      return;
    }
    // const userKey = this.props.usersStore.getAuthUserKey();
    const currDispUserKey = this.props.usersStore.currentDisplayedUserKey;
    const currDispUser = this.props.usersStore.getUserByKey(currDispUserKey);
    const awsAccountIdNum = typeof awsAccountID === 'number' ? awsAccountID : parseInt(awsAccountID, 0);
    if (currDispUser.userType === UsersType.NEW_USER) {
      const newType = UsersType.USER_ON_BOARDED;
      this.props.usersStore.updateUserTypeAndAwsAccId(newType, awsAccountIdNum, isConnectionSuccess);
    }
  };

  navigationDisabled = () => this.animating || document.activeElement.nodeName === 'INPUT';

  next = () => {
    const { activeIndex, isSavingData } = this.state;
    const { usersStore } = this.props;
    if (this.navigationDisabled()) {
      return;
    }
    if (activeIndex === pages.page0) {
      usersStore.usersModel.sendOnBoardingEvent(CLOUD_TYPE_IDS.GCP);
    }
    if (isSavingData) {
      return;
    }
    if (!this.validateForm(activeIndex)) {
      this.setState({ showNoInputValueWarning: true, showGoToNextAlert: false });
      return;
    }
    const nextIndex = activeIndex === pages.page1 ? pages.page1 : activeIndex + 1;
    if (activeIndex === pages.page1) {
      this.saveGcpDetailsConfiguration();
      return;
    }
    this.setState({ showNoInputValueWarning: false, showGoToNextAlert: false, activeIndex: nextIndex });
  };

  previous = () => {
    const { activeIndex } = this.state;
    if (this.navigationDisabled()) {
      return;
    }
    if (activeIndex === 0) {
      return;
    }
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    this.setState({ showNoInputValueWarning: false, activeIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    if (this.animating) {
      return;
    }
    this.setState({ activeIndex: newIndex });
  };

  goOnCondition = () => {
    this.setState({ showGoToNextAlert: true });
    return null;
  };

  handleConnectLinkedAcct = async () => {
    this.setState({ linkedAccounts: this.props.usersStore.usersModel.userLinkedAccounts });
  };

  render() {
    const {
      activeIndex,
      userConnectionStage,
      modal,
      connectingUser,
      projectId,
      isSaveDataError,
      showGoToNextAlert,
      datasetName,
      tableName,
      serviceAccountKeyPath,
      currencySymbol,
      showNoInputValueWarning,
      isSavingData,
      linkedAccounts,
    } = this.state;
    const { usersStore } = this.props;
    const { userType } = usersStore.getUserByKey(usersStore.currentDisplayedUserKey) || {};
    const isModalOpen = modal || connectingUser;
    const slides = items.map((item) => (
      <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.id}>
        {item.withProps
          ? WithProps(item.component)({
              projectId,
              datasetName,
              tableName,
              serviceAccountKeyPath,
              currencySymbol,
              accountName: this.accountName,
              disableEnterKeySub: this.disableEnterKeySub,
              handleGcpConfigInputsChange: this.handleGcpConfigInputsChange,
              handleSelectServiceAccountKey: this.handleSelectServiceAccountKey,
              handleSelectCurrency: this.handleSelectCurrency,
              usersStore,
            })
          : item.component}
      </CarouselItem>
    ));
    return (
      <>
        <Container className="new-user-container" style={{ width: 'calc(100% - 220px)' }}>
          <Row className="page-title-wrapper">
            <Col className="page-title">
              <h3>{PageNames.GCP_ON_BOARDING}</h3>
            </Col>
          </Row>
          <SideBarNavigationTimeline
            activeIndex={activeIndex}
            length={items.length}
            cloudProviderName="GCP"
            steps={this.getSteps()}
          />
          <Card className="wizrd-carousel-item-container">
            <CardBody>
              <Row>
                <Col xs={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }} xl={{ size: 12 }}>
                  <AlertError
                    text="Please fill in the below fields to proceed"
                    withCloseIcon
                    toShow={showNoInputValueWarning}
                    updateAlertClosed={this.updateAlertClosed}
                  />
                  <AlertError
                    text="There was an error, please try again later"
                    withCloseIcon
                    toShow={isSaveDataError}
                    updateAlertClosed={this.updateDataErrorClosed}
                  />
                  <AlertGoToNext
                    toShow={
                      showGoToNextAlert && (activeIndex === pages.page2 || activeIndex === pages.page4_getUserInfo)
                    }
                    steps={items.length - 2 - activeIndex}
                    updateAlertClosed={this.updateAlertClosed}
                  />
                  {isSavingData && <Spinner />}
                  <Carousel
                    interval={0}
                    autoPlay={false}
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                  >
                    {slides}
                    <CarouselControl className="control-hidden" direction="prev" directionText="Previous" />
                    <CarouselControl className="control-hidden" direction="next" directionText="Next" />
                  </Carousel>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <CarouselPageNavigator
            previous={this.previous}
            next={this.next}
            activeIndex={activeIndex}
            lastPage={pages.page2}
            closeModal={this.closeModal}
            linkedAccounts={linkedAccounts}
            userType={userType}
          />
        </Container>
      </>
    );
  }
}

const ObserverNewGcpUserWizard = observer(NewGcpUserWizard);
export default ObserverNewGcpUserWizard;
