import { percentStr } from 'shared/utils/strUtil';
import HistoryTableCellClass from './historyTableCellClass';

export default class HistoryTablePercentCell extends HistoryTableCellClass {
  constructor(data) {
    super(data);
    this.parsedValue = this.parseValue(this.value);
    this.parsedCompareValue = this.parseCompareValue(this.value);
  }

  get getParsedValue() {
    return this.parsedValue;
  }

  get getParsedCompareValue() {
    return this.parsedCompareValue;
  }

  checkIfInt = (v) => {
    const val = v;
    const result = parseInt(val, 0) ? val : -1;
    return result;
  };
  parseValue = (v) => percentStr(this.checkIfInt(v));
  parseCompareValue = (v) => this.checkIfInt(v);
}
