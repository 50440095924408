import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { palette } from 'shared/constants/colorsConstants';
import { ReactComponent as EmptyGroupByCardData } from 'recommendationsNew/img/noData.svg';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { kIntFormmater, strNumToSize } from 'shared/utils/strUtil';
import { CustomizedLegend } from './customizedLegend';
import { CHART_LINE_COLORS, Y_AXIS_UNIT } from './chartConsts';
import { CustomizedTooltip } from './customizedTooltip';
import { CustomizedActiveDot } from './customizedActiveDot';

import classes from './genericChart.module.scss';

const GenericChart = ({ data, yAxisLabel, yAxisUnit, linesList, referenceLinesList }) => {
  if (!data?.length) {
    return (
      <div className="mb-4">
        <EmptyGroupByCardData className="m-auto d-flex" />
      </div>
    );
  }
  const formatYValue = (value) => {
    let formattedValue = value;
    if (yAxisUnit === Y_AXIS_UNIT.BYTE) {
      formattedValue = value === 0 ? value : strNumToSize(value, 0);
    } else if (yAxisUnit === Y_AXIS_UNIT.PERCENT) {
      formattedValue = `${kIntFormmater(value, 2, false)}%`;
    } else if (yAxisUnit === Y_AXIS_UNIT.MB) {
      formattedValue = `${kIntFormmater(value, 2, false)}MB`;
    }
    return formattedValue;
  };

  const legendWrapperStyle = {
    bottom: '0',
    left: '0',
    position: 'relative',
    width: '99%',
  };

  const [turnedOffLines, setTurnedOffLines] = useState([]);

  const linesToDisplay = useMemo(
    () => linesList.filter((line) => !turnedOffLines.includes(line.id)),
    [linesList, turnedOffLines],
  );

  const maxYAxisValue = useMemo(() => {
    const linesMax = Math.max(...data.map((item) => Math.max(...linesToDisplay.map((line) => item[line.id]))));
    const referenceMax = Math.max(...referenceLinesList.map((line) => line.yAxisValue));
    return Math.max(linesMax, referenceMax);
  }, [data, linesToDisplay, referenceLinesList]);

  const onClickLegendPair = (entry) => {
    if (turnedOffLines.includes(entry.currentTarget.id)) {
      setTurnedOffLines(turnedOffLines.filter((lineId) => lineId !== entry.currentTarget.id));
    } else {
      setTurnedOffLines([...turnedOffLines, entry.currentTarget.id]);
    }
  };

  const [clickedLineId, setClickedLineId] = useState(null);

  const handleLineClick = (entry) => {
    if (clickedLineId === entry.id) {
      setClickedLineId(null);
    } else {
      setClickedLineId(entry.id);
    }
  };

  const colorsKeys = Object.keys(CHART_LINE_COLORS);

  return (
    <ResponsiveContainer className={classes.customChartContainer} width="100%" height={284}>
      <LineChart width={550} height={284} data={data} margin={{ top: 20, right: 20, bottom: 0, left: 20 }}>
        <CartesianGrid horizontal vertical={false} />
        <XAxis
          axisLine={{ stroke: palette.gray[300] }}
          dataKey="usageDate"
          tickLine={false}
          tick={{ style: { fontSize: 11, color: palette.gray[400] } }}
          type="category"
        />
        <YAxis
          axisLine={{ stroke: palette.gray[300] }}
          domain={[0, maxYAxisValue]}
          label={{
            value: yAxisLabel,
            dy: -15,
            dx: 10,
            angle: -90,
            position: 'left',
          }}
          tickLine={false}
          tick={{ style: { fontSize: 11, color: palette.gray[400] } }}
          tickFormatter={(value) => formatYValue(value)}
          type="number"
        />
        <Tooltip
          content={
            <CustomizedTooltip turnedOffLines={turnedOffLines} clickedLineId={clickedLineId} yAxisUnit={yAxisUnit} />
          }
        />
        {linesToDisplay?.map((line, index) => (
          <Line
            className={`${classes.customLine} ${turnedOffLines.includes(`${line.id}Legend`) && classes.hiddenLine}`}
            dataKey={line.id}
            dot={false}
            activeDot={<CustomizedActiveDot />}
            id={line.id}
            name={line.label}
            onClick={handleLineClick}
            stroke={
              clickedLineId && clickedLineId !== line.id
                ? palette.gray[300]
                : CHART_LINE_COLORS[colorsKeys[index]].color
            }
            strokeWidth={1}
            type="linear"
          />
        ))}
        {referenceLinesList?.map((line, index) => (
          <ReferenceLine
            y={line.yAxisValue}
            label={{
              value: line.label,
              offset: 5,
              position: 'top',
              fill: CHART_LINE_COLORS[colorsKeys[index + linesToDisplay.length]].color,
              fontSize: 12,
            }}
            stroke={CHART_LINE_COLORS[colorsKeys[index + linesToDisplay.length]].color}
            strokeWidth={1}
            strokeDasharray="6 6"
          />
        ))}
        <Legend
          content={<CustomizedLegend turnedOffLines={turnedOffLines} onClickLegendPair={onClickLegendPair} />}
          wrapperStyle={legendWrapperStyle}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

GenericChart.propTypes = {
  data: PropTypes.object.isRequired,
  linesList: PropTypes.array.isRequired,
  referenceLinesList: PropTypes.array,
  yAxisLabel: PropTypes.string.isRequired,
  yAxisUnit: PropTypes.string,
};

GenericChart.defaultProps = {
  yAxisUnit: Y_AXIS_UNIT.OTHER,
  referenceLinesList: [],
};

export default GenericChart;
