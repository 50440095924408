/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'reactstrap';
import IconInputField from 'shared/components/IconInputField';
import { CurrenciesSelect } from 'users/containers/Onboarding/shared/components/UserCurrency/CurrencySelect';

const nameValidation = {
  marginTop: '5px',
  color: 'red',
  fontSize: '10px',
};

class GcpDetailsConfiguration extends PureComponent {
  static propTypes = {
    projectId: PropTypes.string.isRequired,
    datasetName: PropTypes.string.isRequired,
    tableName: PropTypes.string.isRequired,
    serviceAccountKeyPath: PropTypes.string.isRequired,
    currencySymbol: PropTypes.object,
    accountName: PropTypes.string.isRequired,
    handleGcpConfigInputsChange: PropTypes.func.isRequired,
    handleSelectServiceAccountKey: PropTypes.func.isRequired,
    disableEnterKeySub: PropTypes.func.isRequired,
    handleSelectCurrency: PropTypes.func.isRequired,
    usersStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      nameIsValid: true,
    };
  }

  serviceAccountKeyHandler = (name, e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    try {
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        const content = reader.result;
        await this.props.handleSelectServiceAccountKey(file.name, content);
      };
      reader.readAsText(file);
    } catch (error) {
      console.log('error reading the file: ', error);
      return;
    }
    this.props.handleGcpConfigInputsChange(name, e);
  };

  accountNameValidation = (name, e) => {
    const reg = /[^A-Za-z0-9 ]/;
    if (reg.test(name) || name.length > 15) {
      this.setState({ nameIsValid: false });
    } else {
      this.setState({ nameIsValid: true });
      this.props.handleGcpConfigInputsChange(name, e);
    }
  };

  render() {
    const {
      projectId,
      datasetName,
      tableName,
      serviceAccountKeyPath,
      accountName,
      handleGcpConfigInputsChange,
      disableEnterKeySub,
      currencySymbol,
      handleSelectCurrency,
      usersStore,
      // validateForm,
      // handleAwsAccountNameChange,
    } = this.props;
    const { nameIsValid } = this.state;

    return (
      <>
        <Row>
          <Col xs={12} md={12} lg={8} xl={8}>
            <h4 className="inner-wizard-items-header" style={{ 'padding-bottom': '20px' }}>
              <span>Complete the following fields:</span>
            </h4>
            <Form onKeyPress={disableEnterKeySub} className="form">
              <div className="form__form-group">
                <IconInputField
                  icon="lnr lnr-cloud"
                  handler={this.accountNameValidation}
                  label="Display Account Name"
                  name="accountName"
                  value={accountName}
                  type="text"
                  placeHolder="Enter a display name for your account, length of 2 - 15 and alphanumeric characters"
                />
                {!nameIsValid && (
                  <div style={nameValidation}>Please use alphanumeric values only and length between 2-15</div>
                )}
              </div>
              <div className="form__form-group">
                <IconInputField
                  icon="lnr lnr-cloud"
                  handler={handleGcpConfigInputsChange}
                  label="Project Id"
                  name="projectId"
                  value={projectId}
                  type="text"
                  placeHolder="Enter your Project Id"
                />
              </div>
              <div className="form__form-group">
                <IconInputField
                  icon="lnr lnr-database"
                  handler={handleGcpConfigInputsChange}
                  label="Billing dataset name"
                  name="datasetName"
                  value={datasetName}
                  type="text"
                  placeHolder="Enter your Billing dataset name"
                />
              </div>
              <div className="form__form-group">
                <IconInputField
                  icon="lnr lnr-database"
                  handler={handleGcpConfigInputsChange}
                  label="Billing table name"
                  name="tableName"
                  value={tableName}
                  type="text"
                  placeHolder="Enter your billing table name"
                />
              </div>
              <div className="form__form-group">
                <IconInputField
                  icon="lnr lnr-upload"
                  handler={this.serviceAccountKeyHandler}
                  label="Service account key file (.json)"
                  name="serviceAccountKeyPath"
                  value={serviceAccountKeyPath}
                  type="file"
                  accept=".json"
                />
              </div>
              <CurrenciesSelect
                usersStore={usersStore}
                currencySymbol={currencySymbol}
                handleSelectCurrency={handleSelectCurrency}
              />
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

GcpDetailsConfiguration.defaultProps = {
  currencySymbol: null,
};

export default GcpDetailsConfiguration;
