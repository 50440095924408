import React from 'react';
import PropTypes from 'prop-types';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import { useAssets } from 'usage/hooks/react-query/useAssets';
import * as tableHelpers from 'usage/containers/Assets/helpers/assetsTableHelper';
import { AMORTIZE_COST_TYPES } from 'usage/constants/costAndUsageConstants';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const AssetCsvDownload = ({
  isNeedFetch,
  usersStore,
  tableData,
  disabled,
  kubernetesStore,
  filterParams: {
    startDate,
    endDate,
    selectedOptionsMap,
    excludedFiltersStatusMap,
    selectedColumns,
    selectedMeasure,
    currPeriodGranLevel,
    currCostType,
    isK8S,
  },
}) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const { fetchAssetDataAsync, assetData, assetDataLoading } = useAssets();

  const formatCSVData = (data = []) => {
    const columns = tableHelpers.prepareTableColumns(
      selectedColumns,
      isK8S,
      currCostType,
      usersStore.currDispUserCloudAccountType,
      getCurrencyNumber,
    );
    const resourceInd = columns.findIndex((c) => c.name === 'resourceid');
    if (resourceInd >= 0) {
      columns.splice(resourceInd, 0, { title: 'Resource Name', name: 'resource' });
    }
    return data.map((row) => {
      const d = {};
      columns.forEach((column) => {
        d[column.title] =
          column.getCellValue &&
          !['computeCost', 'dataTransferCost', 'networkCost', 'storageCost'].includes(column.name)
            ? column.getCellValue(row)
            : row[column.name];
      });
      return d;
    });
  };
  const handleLoadCsvData = async () => {
    const selectedMeasuresMap = new Map([
      [AMORTIZE_COST_TYPES.UNBLENDED, false],
      [AMORTIZE_COST_TYPES.NET_UNBLENDED, false],
      [AMORTIZE_COST_TYPES.AMORTIZED, false],
      [AMORTIZE_COST_TYPES.NET_AMORTIZED, false],
    ]);
    if (selectedMeasure && selectedMeasure.value) {
      selectedMeasuresMap.set(selectedMeasure.value, true);
    }
    const { k8SGranularity } = kubernetesStore;
    const assets = await fetchAssetDataAsync({
      startDate,
      endDate,
      granLevel: currPeriodGranLevel,
      columns: selectedColumns,
      filtersMap: selectedOptionsMap,
      excludeFiltersMap: excludedFiltersStatusMap,
      measuresMap: selectedMeasuresMap,
      costTypes: currCostType,
      isK8S,
      k8SGranularity,
      isFull: true,
    });
    return [{ data: assets.data, filename: 'Report.csv' }];
  };
  return (
    <CustomCSVDownload
      filesNumber={1}
      showDownloadIcon
      hideText
      disabled={disabled}
      fetchData={isNeedFetch ? handleLoadCsvData : undefined}
      data={[{ data: isNeedFetch ? assetData : tableData, filename: 'Report.csv' }]}
      formatData={formatCSVData}
      isLoading={assetDataLoading}
    >
      CSV
    </CustomCSVDownload>
  );
};

AssetCsvDownload.propTypes = {
  isNeedFetch: PropTypes.bool.isRequired,
  usersStore: PropTypes.object.isRequired,
  tableData: PropTypes.array,
  kubernetesStore: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  filterParams: PropTypes.object.isRequired,
};

AssetCsvDownload.defaultProps = {
  tableData: [],
};

export default AssetCsvDownload;
