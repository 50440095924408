import { action, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import { CLOUD_TYPE_IDS, isUsersAccountTypeTenant } from 'users/constants/usersConstants';
import {
  buildStartAndEndDate,
  buildTimeDiffDateFromBaseDate,
  getLstDayOfMonthDate,
  getStartEndDatesFromRelativeString,
} from 'shared/utils/dateUtil';
import uuidv1 from 'uuid';
import DateFilter from 'shared/modules/dateFilter';
import MainDashboardReportHelper from 'usage/models/helperClasses/mainDashboardReportsHelper';
import { ReportPeriodTime } from 'usage/constants/costAndUsageConstants';
import { cuaAlertsTypeToNotificationParam, REPORT_TYPES } from 'usage/constants/usageConstants';
import CustomDashboardSubStore from './subStores/customDashBoard/customDashboardSubStore';

class UsageStore {
  mainDashboardReportHelper = new MainDashboardReportHelper();
  // eslint-disable-next-line no-use-before-define
  mapMainDashboardReports = new Map([...UsageStore.createMainDashboardReports(this.mainDashboardReportHelper)]);

  ebsData = Array.from([]);
  rdsData = Array.from([]);
  s3Data = Array.from([]);
  ec2PricingMethodDataday = Array.from([]);
  ec2PricingMethodDatamonth = Array.from([]);
  azureRiCoverageData = [];
  azureVirtualMachineUsageData = {
    dataFetched: false,
    data: Array.from([]),
  };
  azureStorageUsageData = {
    dataFetched: false,
    data: Array.from([]),
  };
  azureDbCostData = {
    dataFetched: false,
    data: Array.from([]),
  };
  newBudget = null;
  isLoading = true;
  budgetModel = null;
  anomalyDetectionModel = null;
  arrMainDashboardReports = [
    REPORT_TYPES.CURR_VS_PREV_MONTH,
    REPORT_TYPES.REGION,
    REPORT_TYPES.TOP_5_SERVICES,
    REPORT_TYPES.YESTERDAY_TOP_5_SERVICES,
    REPORT_TYPES.MONTHLY_COST_YTD,
  ];

  constructor(
    rootStore,
    apiGateway,
    budgetModel,
    anomalyDetectionModel,
    bigQueryModel,
    commitmentModel,
    idleInstancesModel,
    costAndUsageAlertsModel,
    k8sUsageModel,
    customDashboardModel,
  ) {
    this.rootStore = rootStore;
    this.apiGateway = apiGateway;
    this.budgetModel = budgetModel;
    this.anomalyDetectionModel = anomalyDetectionModel;
    this.bigQueryModel = bigQueryModel;
    this.k8sUsageModel = k8sUsageModel;
    this.commitmentModel = commitmentModel;
    this.resourceOperationsData = [];
    this.resourceDetails = null;
    this.idleInstancesModel = idleInstancesModel;
    this.costAndUsageAlertsModel = costAndUsageAlertsModel;
    this.customDbSubStore = new CustomDashboardSubStore(this, customDashboardModel, apiGateway);
    this.mapCloudTypeToFetchFuncArray = (accountType) =>
      new Map([
        [CLOUD_TYPE_IDS.AWS, []],
        [CLOUD_TYPE_IDS.AZURE, []],
        [
          CLOUD_TYPE_IDS.GCP,
          [
            ...(isUsersAccountTypeTenant(accountType)
              ? []
              : [this.bigQueryModel.fetchData, this.bigQueryModel.fetchBigQueryDistinctValues]),
          ],
        ],
      ]);
    makeObservable(this, {
      isLoading: observable,
      rdsData: observable,
      s3Data: observable,
      azureVirtualMachineUsageData: observable,
      azureStorageUsageData: observable,
      azureDbCostData: observable,
      // riCoverage: observable,
      azureRiCoverageData: observable,
      ec2PricingMethodDataday: observable,
      ec2PricingMethodDatamonth: observable,
      // envUsage: observable,
      // envInstTypesUsage: observable,
      budgetModel: observable,
      anomalyDetectionModel: observable,
      bigQueryModel: observable,
      k8sUsageModel: observable,
      idleInstancesModel: observable,
      resourceDetails: observable,
      costAndUsageAlertsModel: observable,
      getUserBudget: action,
      fetchData: action,
      // fetchReports: action,
      fetchAnomalyDetectionData: action,
      updateIsDismissedAnomaly: action,
      updateBudget: action,
      // updateSelectedEnvTagValues: action,
      fetchRdsData: action,
      // handleCreateIdleInstancesParams: action,
      // addCueAlert: action,
      // removeCueAlert: action,
      saveAllCauAlertsOfType: action,
      updateCuaAlertsSendStatus: action,
      getIdleInstancesCriteriaAvailableLinkedAccounts: action,
    });
  }

  static createMainDashboardReports(builder) {
    const mapDashboardReports = new Map();
    const savedRegionParams = builder.reportsParamsBuilder(REPORT_TYPES.REGION);
    const savedCurrVsPrevParams = builder.reportsParamsBuilder(REPORT_TYPES.CURR_VS_PREV_MONTH);
    const topFiveServReportParams = builder.reportsParamsBuilder(REPORT_TYPES.TOP_5_SERVICES);
    const yesterdayTopFiveServReportParams = builder.reportsParamsBuilder(REPORT_TYPES.YESTERDAY_TOP_5_SERVICES);
    const ytdMonthlyCost = builder.reportsParamsBuilder(REPORT_TYPES.MONTHLY_COST_YTD);
    mapDashboardReports.set(REPORT_TYPES.REGION, savedRegionParams);
    mapDashboardReports.set(REPORT_TYPES.CURR_VS_PREV_MONTH, savedCurrVsPrevParams);
    mapDashboardReports.set(REPORT_TYPES.TOP_5_SERVICES, topFiveServReportParams);
    mapDashboardReports.set(REPORT_TYPES.YESTERDAY_TOP_5_SERVICES, yesterdayTopFiveServReportParams);
    mapDashboardReports.set(REPORT_TYPES.MONTHLY_COST_YTD, ytdMonthlyCost);
    return mapDashboardReports;
  }

  updateMainDbReportsDates = (year, currMonth, currDay, prevMonth) => {
    const savedRegionParams = this.mapMainDashboardReports.get(REPORT_TYPES.REGION);
    const savedCurrVsPrevParams = this.mapMainDashboardReports.get(REPORT_TYPES.CURR_VS_PREV_MONTH);
    const topFiveServReportParams = this.mapMainDashboardReports.get(REPORT_TYPES.TOP_5_SERVICES);
    const yesterdayTopFiveServReportParams = this.mapMainDashboardReports.get(REPORT_TYPES.YESTERDAY_TOP_5_SERVICES);
    const ytdMonthlyCost = this.mapMainDashboardReports.get(REPORT_TYPES.MONTHLY_COST_YTD);
    savedRegionParams.updateDates(year, currMonth, currDay, prevMonth);
    savedCurrVsPrevParams.updateDates(year, currMonth, currDay, prevMonth);
    topFiveServReportParams.updateDates(year, currMonth, currDay, prevMonth);
    yesterdayTopFiveServReportParams.updateDates(year, currMonth, currDay, prevMonth);
    // prevMonth = January, the report is from 1st January - current date
    ytdMonthlyCost.updateDates(year, currMonth, currDay, 1);
  };

  fetchData = async () => {
    const { usersStore } = this.rootStore;
    this.isLoading = true;
    const currDispUserCloudAccType = usersStore.currDispUserCloudAccountType;
    const accountType = usersStore.getCurrAccountTypeId();
    try {
      const fetchArr = this.mapCloudTypeToFetchFuncArray(+accountType).get(currDispUserCloudAccType);
      if (fetchArr && Array.isArray(fetchArr)) {
        await Promise.all(fetchArr.map((func) => func()));
      }
      const lastProcessDate = new Date(DateFilter.getDate());
      this.updateMainDbReportsDates(
        lastProcessDate.getFullYear(),
        lastProcessDate.getMonth() + 1,
        lastProcessDate.getDate(),
        lastProcessDate.getMonth(),
      );

      runInAction(() => {
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
      });
    }
  };

  clearAzureDbCostData = () => {
    const azureDbCostData = {
      dataFetched: false,
      data: Array.from([]),
    };
    this.azureDbCostData = { ...azureDbCostData };
  };

  getRdsData = async (selectedStartDate = null, selectedEndDate = null) => {
    const { startDate, endDate } = buildStartAndEndDate(selectedStartDate, selectedEndDate);
    this.rdsData = await this.apiGateway.getRdsInstanceCosts(startDate, endDate);
    return this.rdsData;
  };
  getS3Data = async (selectedStartDate = null, selectedEndDate = null) => {
    const { startDate, endDate } = buildStartAndEndDate(selectedStartDate, selectedEndDate);
    this.s3Data = await this.apiGateway.getS3BucketCosts(startDate, endDate);
    return this.s3Data;
  };

  invalidateCommitmentRiAndChargeBackModel = () => {
    this.commitmentModel.invalidateChargeBackAndRiFlexabilityData();
  };

  // / New Azure by cost and usage

  getAzureRiCoverage = async (startDate, endDate) => {
    const { startDate: start, endDate: end } = buildStartAndEndDate(startDate, endDate);
    try {
      const data = await this.apiGateway.getAzureRiCoverage(start, end);
      runInAction(() => {
        this.azureRiCoverageData = [...data];
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
        this.azureRiCoverageData = [];
      });
      throw error;
    }
  };

  // end new azure methods

  getAzureStorageUsageData = async (startDat, endDat) => {
    const { startDate, endDate } = buildStartAndEndDate(startDat, endDat);
    const data = await this.apiGateway.getAzureStorageUsageData(startDate, endDate);
    if (data && Array.isArray(data)) {
      this.azureStorageUsageData.data = [...data];
      this.azureStorageUsageData.dataFetched = true;
    }
    return this.azureStorageUsageData;
  };

  getAzureDbCostData = async (rawStartDate, rawEndDate) => {
    try {
      const { startDate, endDate } = buildStartAndEndDate(rawStartDate, rawEndDate);
      const data = await this.apiGateway.getAzureDbCostData(startDate, endDate);
      if (data && Array.isArray(data)) {
        this.azureDbCostData.data = [...data];
        this.azureDbCostData.dataFetched = true;
      }
    } catch (error) {
      // console.log(error);
    }
    return this.azureDbCostData;
  };

  invalidateAzureData = () => {
    this.azureVirtualMachineUsageData.data.splice(0);
    this.azureVirtualMachineUsageData.dataFetched = false;
    this.azureStorageUsageData.data.splice(0);
    this.azureStorageUsageData.dataFetched = false;
    this.azureDbCostData.data.splice(0);
    this.azureDbCostData.dataFetched = false;
    this.azureRiCoverageData = [];
  };

  invalidateStore = () => {
    this.rdsData.splice(0);
    this.ebsData.splice(0);
    this.s3Data.splice(0);
    this.ec2PricingMethodDataday.splice(0);
    this.ec2PricingMethodDatamonth.splice(0);
    this.budgetModel.invalidateData();
    this.invalidateAzureData();
    this.anomalyDetectionModel.invalidateData();
    this.bigQueryModel.invalidateData();
    this.commitmentModel.invalidateData();
    this.costAndUsageAlertsModel.invalidateData();
    this.idleInstancesModel.invalidateData();
    this.k8sUsageModel.invalidateData();
    this.isLoading = true;
  };

  getUserBudget = () => this.budgetModel.monthlyBudget;

  // eslint-disable-next-line class-methods-use-this
  buildDefaultDatesBasedOnReportParams = (start, end, saveModalRelativeDates, periodType) => {
    let { startDate, endDate } = buildStartAndEndDate(start, end);
    if (periodType === 'relativeDate') {
      // eslint-disable-next-line prefer-destructuring,radix
      startDate = buildTimeDiffDateFromBaseDate(endDate, -parseInt(saveModalRelativeDates, 0), 'd').startDate;
    } else if (periodType === ReportPeriodTime.PREVIOUS_MONTH) {
      const { month, year } = DateFilter.getPrevMonthAndYear();
      const monthInDateFormat = month - 1; // I get a calendar month not a Date format month
      const lastDayOfMonth = getLstDayOfMonthDate(new Date(year, monthInDateFormat, 1));
      // eslint-disable-next-line prefer-destructuring
      startDate = buildStartAndEndDate(new Date(year, monthInDateFormat, 1), lastDayOfMonth).startDate;
      // eslint-disable-next-line prefer-destructuring
      endDate = buildStartAndEndDate(new Date(year, monthInDateFormat, 1), lastDayOfMonth).endDate;
    } else if (
      periodType === ReportPeriodTime.PREVIOUS_2_MONTH ||
      periodType === ReportPeriodTime.PREVIOUS_3_MONTH ||
      periodType === ReportPeriodTime.PREVIOUS_6_MONTH ||
      periodType === ReportPeriodTime.PREVIOUS_DAY ||
      periodType === ReportPeriodTime.PREVIOUS_WEEK ||
      periodType === ReportPeriodTime.PREVIOUS_2_WEEK ||
      periodType === ReportPeriodTime.PREVIOUS_3_WEEK ||
      periodType === ReportPeriodTime.PREVIOUS_4_WEEK ||
      periodType === ReportPeriodTime.PREVIOUS_5_WEEK ||
      periodType === ReportPeriodTime.PREVIOUS_6_WEEK ||
      periodType === ReportPeriodTime.PREVIOUS_7_WEEK ||
      periodType === ReportPeriodTime.PREVIOUS_8_WEEK ||
      periodType === ReportPeriodTime.YTD
    ) {
      startDate = getStartEndDatesFromRelativeString(periodType, DateFilter.getDate()).start;
      endDate = getStartEndDatesFromRelativeString(periodType, DateFilter.getDate()).end;
    }
    return { startDate, endDate };
  };
  // this function is called from Reports - with report ID, or from Main DB with params
  fetchReportDataOnNavigationToCostExplorer = async (reportId, mainDashboardData) => {
    const reportParams = this.getReportLocalyById([], reportId, mainDashboardData);
    const {
      state,
      saveModalKeepDates,
      dataKeyToWhereParamsMap,
      fieldToFilterdValuesMap,
      excludedFiltersStatusMap,
      likeFiltersStatus,
      saveModalRelativeDates,
      periodType,
    } = reportParams;
    const {
      currDataState,
      currentGroupBy,
      filterBarGroupBySecondary,
      startDate,
      endDate,
      currPeriodGranLevel,
      currCostType,
      isShowAmortizeCost,
      isApplyMargin,
      isNetAmortize,
      isNetUnblended,
      isPublicCost,
    } = state;
    const { startDate: startBuilt, endDate: endBuilt } = this.buildDefaultDatesBasedOnReportParams(
      '',
      '',
      saveModalRelativeDates,
      periodType,
    );
    const startD = saveModalKeepDates ? startDate : startBuilt;
    const endD = saveModalKeepDates ? endDate : endBuilt;
    try {
      await this.rootStore.invoiceStore.fetchDataByState(
        currDataState,
        currentGroupBy,
        filterBarGroupBySecondary,
        currentGroupBy,
        dataKeyToWhereParamsMap,
        startD,
        endD,
        currPeriodGranLevel,
        fieldToFilterdValuesMap,
        currCostType,
        isShowAmortizeCost,
        isApplyMargin,
        new Map(excludedFiltersStatusMap),
        likeFiltersStatus,
        isNetAmortize,
        isNetUnblended,
        isPublicCost,
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  getReportLocalyById = (reports, reportId, mainDashBoardData) => {
    let reportParams = {};
    if (this.arrMainDashboardReports.includes(reportId)) {
      const reportParamsObj = this.mapMainDashboardReports.get(reportId);
      if (reportId === REPORT_TYPES.TOP_5_SERVICES) {
        const currTopServices = mainDashBoardData.preparedTopServices.map(({ name }) => name);
        reportParamsObj.createTopFiveMap(currTopServices);
      }

      if (reportId === REPORT_TYPES.YESTERDAY_TOP_5_SERVICES) {
        const currYesterdayTopServices = mainDashBoardData.yesterdayTopServices.map((currService) => currService.name);
        reportParamsObj.createYesterdayTopFiveMap(currYesterdayTopServices);
      }

      reportParams = reportParamsObj.getReportParams;
    } else {
      const reportData = reports.find((r) => r.id === reportId);
      reportParams = {
        ...reportData.reportParams,
        isPpApplied: reportData.isPpApplied,
      };
    }
    return reportParams;
  };

  createBudget = (selectedOptionsMap, filterStatusTypeMap) => {
    this.budgetModel.createBudget(selectedOptionsMap, filterStatusTypeMap);
  };

  updateBudget = (budget, selectedOptionsMap, filterStatusTypeMap) => {
    this.budgetModel.updateBudget(budget, selectedOptionsMap, filterStatusTypeMap);
  };

  removeBudget = (budgetId) => {
    this.budgetModel.removeBudget(budgetId);
  };

  deleteBudget = (id) => {
    this.budgetModel.deleteBudget(id);
  };

  fetchAnomalyDetectionData = async () => {
    await this.anomalyDetectionModel.fetchAnomalyDetectionData();
  };

  updateIsDismissedAnomaly = async (anomalyKey) => {
    await this.anomalyDetectionModel.updateIsDismissedAnomaly(anomalyKey);
  };

  fetchRdsData = async (rawStartDate, rawEndDate) => {
    const { startDate, endDate } = buildStartAndEndDate(rawStartDate, rawEndDate);
    this.isLoading = true;
    try {
      const data = await this.apiGateway.getRdsInstanceCosts(startDate, endDate);
      runInAction(() => {
        this.rdsData = data;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
      });
    }
    return this.rdsData;
  };

  fetchBigQueryData = async (startDate = null, endDate = null, fieldToFilterdValuesMap, pageSize = 5) => {
    await this.bigQueryModel.fetchBigQueryData(startDate, endDate, fieldToFilterdValuesMap, pageSize);
  };

  // Idle Instances Model

  createIdleCriteriaRule = async (params) => {
    await this.idleInstancesModel.createIdleCriteriaRule(params);
  };
  deleteIdleCriteria = async (id) => {
    await this.idleInstancesModel.deleteIdleCriteria(id);
  };

  getIdleInstancesCriteriaAvailableLinkedAccounts = () =>
    this.idleInstancesModel.getIdleInstancesCriteriaAvailableLinkedAccounts;
  getIdleInstancesAvailableCriteriaNames = () => {
    const preparedOptionsNamesList = this.idleInstancesModel.getIdleInstancesAvailableCriteriaNames;
    preparedOptionsNamesList.unshift({ label: 'All', value: 'all' });
    return preparedOptionsNamesList;
  };

  // eslint-disable-next-line class-methods-use-this
  getPreparedLinkedAccountsOptions = (availableLinkedAccountsForFilter, allLinkedAccountOptions) => {
    if (!availableLinkedAccountsForFilter || !availableLinkedAccountsForFilter.length) {
      return [];
    }
    const values =
      allLinkedAccountOptions &&
      allLinkedAccountOptions
        .filter((laOption) => availableLinkedAccountsForFilter.includes(laOption.linkedAccountId))
        .map((laOption) => ({ label: laOption.displayLabel, value: laOption.linkedAccountId }));
    return values;
  };

  updateIdleCriteriaRule = async (bodyParams) => {
    await this.idleInstancesModel.updateIdleCriteriaRule(bodyParams);
  };

  getIdleInstancesTableData = async (bodyParams) => {
    await this.idleInstancesModel.getIdleInstancesTableData(bodyParams);
  };

  getIdleInstanceRowData = async (criteriaId, instanceId, startDate, endDate) => {
    const data = await this.idleInstancesModel.getIdleInstanceRowData(criteriaId, instanceId, startDate, endDate);
    return data;
  };

  // Alerts ***********************
  getCauAlerts = () => {
    const result = this.costAndUsageAlertsModel.getAlerts();
    return result;
  };
  fetchCauAlerts = async () => {
    await this.costAndUsageAlertsModel.fetchData();
  };
  getCauAlert = (id) => this.costAndUsageAlertsModel.getAlert(id);
  addCauAlert = (alertType, alertParams, alertId) => {
    const newId = alertId || uuidv1();
    // eslint-disable-next-line no-param-reassign
    alertParams.id = newId;
    this.costAndUsageAlertsModel.addAlert(alertType, alertParams);
  };

  updateEditedCauAlertParam = (alertParam, alertId) => {
    this.costAndUsageAlertsModel.updateEditedAlertParam(alertParam, alertId);
  };
  editCauAlertStatus = (alertId, newStatus) => {
    this.costAndUsageAlertsModel.editAlertStatus(alertId, newStatus);
  };
  removeCuaAlert = (alertId) => {
    this.costAndUsageAlertsModel.removeAlert(alertId);
  };
  cancelCuaAlertEdit = (alertId) => {
    this.costAndUsageAlertsModel.cancelEditAlert(null, alertId);
  };
  deleteCauAlert = (alertId) => this.costAndUsageAlertsModel.deleteAlert(alertId);
  getCueAlertLinkedAccountsValues = (alertId) => this.costAndUsageAlertsModel.getAlertLinkedAccountsValues(alertId);
  getCueAlertServicesValues = (alertId) => this.costAndUsageAlertsModel.getAlertServicesValues(alertId);

  saveAllCauAlertsOfType = async (alertType) => {
    const result = await this.costAndUsageAlertsModel.saveAllAlertsOfType(alertType);
    return result || false;
  };
  saveCauAlert = async (alertType, alertParams, alertId = null, isSaveAfterEdit = false) => {
    const newId = alertId || uuidv1();
    if (!alertId) {
      // eslint-disable-next-line no-param-reassign
      alertParams.db_creation_time = moment().format('YYYY-MM-DD HH:mm:ss');
    }
    // eslint-disable-next-line no-param-reassign
    alertParams.id = newId;
    const alertBeforeEdit = this.costAndUsageAlertsModel.alerts.find((alert) => alert.id === alertId);
    // eslint-disable-next-line no-unused-expressions
    isSaveAfterEdit && this.removeCuaAlert(alertId);
    const alert = this.costAndUsageAlertsModel.addAlert(alertType, alertParams);
    if (alert) {
      const result = await this.costAndUsageAlertsModel.saveAllAlerts([alert]); // alert must be passes as array
      if (!result) {
        if (alertBeforeEdit) {
          this.costAndUsageAlertsModel.replaceAlert(alert.id, alertBeforeEdit);
        } else {
          this.removeCuaAlert(alert.id);
        }
      }
      return result || false;
    }
    return false;
  };
  cancelAllUnsavedCauAlerts = async (alertType) => {
    const result = await this.costAndUsageAlertsModel.cancelAllUnsavedCauAlerts(alertType);
    return result || false;
  };
  updateCuaAlertsSendStatus = async (alertType, newStatus) => {
    const newStatusAsNum = newStatus ? 1 : 0;
    const newSendStatus = { [cuaAlertsTypeToNotificationParam[alertType]]: newStatusAsNum };
    await this.rootStore.usersStore.updateCuaAlertsSendStatus(newSendStatus);
  };

  // Kpis:
  getKpiBusinessUnitCoverageData = async (service, granularity, startDate, endDate) => {
    const businessUnitCoverageData = await this.apiGateway.getKpiBusinessUnitCoverageData(
      service,
      granularity,
      startDate,
      endDate,
    );
    return businessUnitCoverageData;
  };
  getKpiCoverageByPricingMethod = async (service, granularity, startDate, endDate) => {
    const businessUnitCoverageData = await this.apiGateway.getKpiCoverageByPricingMethod(
      service,
      granularity,
      startDate,
      endDate,
    );
    return businessUnitCoverageData;
  };
  getKpieBusinessUnitReservationUtilization = async (service, granularity, startDate, endDate) => {
    const businessUnitReservationUtilizationData = await this.apiGateway.getKpieBusinessUnitReservationUtilization(
      service,
      granularity,
      startDate,
      endDate,
    );
    return businessUnitReservationUtilizationData;
  };
  getKpiCashBasis = async (granularity, startDate, endDate) => {
    const cashBasisData = await this.apiGateway.getKpiCashBasis(granularity, startDate, endDate);
    return cashBasisData;
  };
  getKpiCostPerHour = async (service, granularity, startDate, endDate) => {
    const cashBasisData = await this.apiGateway.getKpiCostPerHour(service, granularity, startDate, endDate);
    return cashBasisData;
  };
  getKpiMonthlyCostPerHour = async (service, granularity, startDate, endDate) => {
    const cashBasisData = await this.apiGateway.getKpiMonthlyCostPerHour(service, granularity, startDate, endDate);
    return cashBasisData;
  };
  getKpiQtdCoverageByService = async (service, granularity, startDate, endDate) => {
    const qtdCoverageData = await this.apiGateway.getKpiQtdCoverageByService(service, granularity, startDate, endDate);
    return qtdCoverageData;
  };
  getKpiQtdUtilizationByService = async (service, year, startMonth, endMonth) => {
    const qtdCoverageData = await this.apiGateway.getKpiQtdUtilizationByService(service, year, startMonth, endMonth);
    return qtdCoverageData;
  };
  getKpiSavingsOnDemand = async (yearQuarter) => this.apiGateway.getKpiSavingsOnDemand(yearQuarter);
  qtdPileusSavings = async () => this.apiGateway.qtdPileusSavings();
}

export default UsageStore;
