import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'shared/components/andtComponents/Toast';
import CommentColumn from 'shared/components/commentColumn/commentColumn';
import { recommendationCommentsProvider } from 'recommendationsNew/hooks/react-query/useUserRecAction';
import classes from './recommendationsList.module.scss';

const propTypes = {
  row: PropTypes.object.isRequired,
};

const RecommendationComments = ({ row }) => {
  const [deletingComments, setDeletingComments] = useState([]);

  const recommendationCommentsHook = recommendationCommentsProvider(row.recId);
  const { mutateAsync: mutationAddComment, isLoading: isAddingComment } = recommendationCommentsHook.createComment({
    onError: () => {
      toast.error('Failed to add comment');
    },
  });
  const { mutateAsync: mutationDeleteComment, isLoading: isDeletingComment } = recommendationCommentsHook.deleteComment(
    {
      onError: () => {
        toast.error('Failed to delete comment');
        setDeletingComments([]);
      },
    },
  );
  const { mutateAsync: mutationUpdateComment, isLoading: isUpdatingComment } = recommendationCommentsHook.updateComment(
    {
      onError: () => {
        toast.error('Failed to update comment');
      },
    },
  );
  return (
    <CommentColumn
      row={row}
      idKey="commentId"
      mutationAddComment={mutationAddComment}
      mutationDeleteComment={mutationDeleteComment}
      mutationUpdateComment={mutationUpdateComment}
      isAddingComment={isAddingComment}
      isDeletingComment={isDeletingComment}
      isUpdatingComment={isUpdatingComment}
      deletingComments={deletingComments}
      setDeletingComments={setDeletingComments}
      className={classes.commentWrapper}
    />
  );
};

RecommendationComments.propTypes = propTypes;
export default RecommendationComments;
