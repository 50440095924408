import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import IconFromPng from 'shared/components/IconFromPng';
import Spinner from 'shared/components/andtComponents/Spinner';
import Panel from 'shared/components/Panel';
import PageHeader from 'shared/components/PageHeader';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import { ReportSendFrequency, USER_SAVED_REPORT_TYPES } from 'usage/constants/costAndUsageConstants';
import { UsersType as usersType } from 'users/constants/usersConstants';
import ICONS from 'shared/constants/assetsConstants';
import { PageNames } from 'shared/constants/appConstants';
import DateFilter from 'shared/modules/dateFilter';
import {
  REPORTS_COLUMN_WIDTHS,
  REPORTS_DEFAULT_HIDDEN_COLUMN_NAMES,
  REPORTS_DEFAULT_SORTING,
  REPORTS_TABLE_COLUMN_EXTENSIONS,
  REPORTS_TABLE_COLUMNS,
} from 'usage/constants/usageConstants';
import AssetsReportContent from 'shared/components/reportModal/AssetsReportContent';
import ReportModal from 'shared/components/reportModal/ReportModal';
import { withReportsContextProvider } from 'usage/contexts/reportsContext';
import CueReportContent from 'shared/components/reportModal/CueReportContent';
import ReportsHelperMethods, {
  prepareFrequencyStartDate,
} from 'usage/containers/CostAndUsageExplorer/helpers/reportsHelperMethods';
import ReportsTable from './components/ReoprtsDevExpTable';

const CreateReportButton = (props) => {
  const { handler } = props;
  return (
    <Row className="dotted_box">
      <div className="dotted_box-title">
        <span>Create Report</span>
      </div>
      <br />
      <div>
        <LinkIconButton>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a href="#" onClick={handler} />
          <IconFromPng size="auto" icon={ICONS.plus} />
        </LinkIconButton>
      </div>
    </Row>
  );
};

CreateReportButton.propTypes = {
  handler: PropTypes.func.isRequired,
};

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportIdSelectedForDelete: null,
      editedReport: {},
    };
  }

  getTableColumnsAndWidth = () => {
    const { usersStore } = this.props;
    if (usersStore.currentDisplayedUserType !== usersType.RESELLER) {
      return {
        tableColumns: REPORTS_TABLE_COLUMNS.filter((col) => col.name !== 'pricingMode'),
        tableColumnsWidth: REPORTS_COLUMN_WIDTHS.filter((col) => col.columnName !== 'pricingMode'),
      };
    }
    return {
      tableColumns: REPORTS_TABLE_COLUMNS,
      tableColumnsWidth: REPORTS_COLUMN_WIDTHS,
    };
  };

  // preparers ////////////////

  prepareTableData = (data) => {
    if (!data) {
      return [];
    }
    const prepareSlackChannels = (str) => str.split(',').map((id) => ({ id, name: '' }));
    return data.map(
      ({
        id,
        name,
        creationDate,
        mailParams,
        frequency,
        period,
        isSnapShot,
        reportType,
        sourceKey,
        updateTime,
        reportParams = {},
        isPpApplied,
      }) => ({
        id,
        name,
        email: mailParams.email,
        slackChannels: mailParams.slack ? prepareSlackChannels(mailParams.slack) : mailParams.slackChannels,
        frequency,
        period,
        isSnapShot,
        reportType,
        sourceKey,
        isK8S: (reportParams.state || {}).isK8S,
        creationDate,
        updateTime,
        isPpApplied,
      }),
    );
  };

  // handlers ////////////////

  handleDeleteReportClicked = (e, reportId) => {
    e.preventDefault();
    this.setState({ reportIdSelectedForDelete: reportId });
  };
  handleDeleteReport = (action) => {
    const { deleteReport } = this.props;
    if (action === 'cancel') {
      this.setState({ reportIdSelectedForDelete: null });
    } else if (action === 'delete') {
      try {
        deleteReport({ id: this.state.reportIdSelectedForDelete });
        this.setState({ reportIdSelectedForDelete: null });
      } catch (error) {
        this.setState({ reportIdSelectedForDelete: null });
      }
    }
    return null;
  };
  handleSelectReportForEdit = (e, editedReport) => {
    e.preventDefault();
    this.setState({ editedReport });
  };
  handleSendReport = (report) => {
    const { sendReport } = this.props;
    if (!report) {
      return;
    }
    sendReport(report);
  };
  handleCloseEditReport = () => {
    this.setState({ editedReport: {} });
  };
  handleSaveEditReport = (params) => {
    const { saveCueReport } = this.props;
    const { editedReport } = this.state;
    const data = ReportsHelperMethods.getModalSaveReportHandler(
      this,
      editedReport.id,
    )({
      ...params,
      isEdit: true,
      id: editedReport.id,
      baseReportParams: this.reportById(editedReport.id)?.baseReportParams,
    });
    saveCueReport(data);
    this.handleCloseEditReport();
  };
  handleSaveAssetEditReport = (params) => {
    const { saveAssetReport } = this.props;
    const { editedReport } = this.state;
    const data = ReportsHelperMethods.modalSaveEditAssetReportHandler(this, editedReport.id, {
      ...params,
      baseReportParams: this.reportById(editedReport.id)?.baseReportParams,
    });
    saveAssetReport(data);
    this.handleCloseEditReport();
  };

  handleSaveScheduleDashboardReport = (params) => {
    const { saveCustomDashboardReport } = this.props;
    const { editedReport } = this.state;
    const { reportName, emails, slackChannels, delivery } = params;
    const { time, frequency, customFrequency, customStartDate } = delivery;
    const preparedFrequencyStartDate = prepareFrequencyStartDate(
      frequency,
      +frequency === ReportSendFrequency.CUSTOM ? customStartDate : null,
      time,
    );
    saveCustomDashboardReport({
      id: editedReport.id,
      type: 'saved',
      name: reportName.value,
      email: emails.value,
      slackChannels,
      reportFreq: frequency,
      mailDeliveryFrequency: customFrequency,
      mailFrequencyStartDate: preparedFrequencyStartDate,
      frequencyDeliveryTime: time,
      sourceKey: editedReport.sourceKey,
    });
    this.setState({
      editedReport: {},
    });
  };
  reportById = (id) => {
    const { reports } = this.props;
    const report = reports.find((report) => report.id === id);
    return report || null;
  };
  // Renders
  renderEditReportModalByType = (id) => {
    const { usersStore, sendReport } = this.props;
    const { editedReport } = this.state;
    const report = this.reportById(id);
    if (!report) {
      return null;
    }
    const { reportType, reportParams, sourceKey } = report;
    const reportContext = {
      props: this.props,
      state: { ...reportParams.state, sourceKey },
      fieldToFilterdValuesMap: reportParams.fieldToFilterdValuesMap,
      dataKeyToWhereParamsMap: reportParams.dataKeyToWhereParamsMap,
    };
    let onSave = () => {};
    let title;
    let reportData = {};
    let children = null;
    if (reportType === USER_SAVED_REPORT_TYPES.CUSTOM_DASHBOARD) {
      onSave = this.handleSaveScheduleDashboardReport;
      title = 'Dashboard Report';
      reportData = report.getReportMetaDataParams();
    } else if (reportType === USER_SAVED_REPORT_TYPES.ASSET) {
      onSave = this.handleSaveAssetEditReport;
      title = 'Assets Report';
      children = <AssetsReportContent />;
      reportData = report.getReportMetaDataParams();
    } else if (reportType === USER_SAVED_REPORT_TYPES.CUE) {
      onSave = this.handleSaveEditReport;
      title = 'Cost And Usage Report';
      children = <CueReportContent />;
      reportData = report.getReportMetaDataParams();
    }
    return (
      <ReportModal
        onClose={this.handleCloseEditReport}
        modalType={reportType}
        isOpen={!!editedReport.id}
        onSave={onSave}
        onSend={(params) => {
          const data = ReportsHelperMethods.getModalSendReportHandler(reportContext, reportType)(params);
          sendReport(data);
        }}
        usersStore={usersStore}
        title={title}
        data={{ ...reportData, id }}
      >
        {children}
      </ReportModal>
    );
  };

  render() {
    const { reportsLoading, reports, usersStore } = this.props;
    const { reportIdSelectedForDelete, editedReport } = this.state;
    if (reportsLoading) {
      return <Spinner />;
    }
    const { tableColumns, tableColumnsWidth } = this.getTableColumnsAndWidth();
    const preapredData = this.prepareTableData(reports);
    const { currentUserReadOnly } = usersStore;
    return (
      <Container>
        <PageHeader title={PageNames.REPORTS} showDate />
        <Row>
          <Panel divider title="MY REPORTS - COST & USAGE">
            <ReportsTable
              rows={preapredData}
              columns={tableColumns}
              columnWidths={tableColumnsWidth}
              tableColumnExtensions={REPORTS_TABLE_COLUMN_EXTENSIONS}
              defaultHiddenColumnNames={REPORTS_DEFAULT_HIDDEN_COLUMN_NAMES}
              defaultSorting={REPORTS_DEFAULT_SORTING}
              csvTitle={`REPORTS_data_${DateFilter.month}_${DateFilter.year}`}
              deleteHandler={this.handleDeleteReportClicked}
              editHandler={this.handleSelectReportForEdit}
              sendHandler={this.handleSendReport}
              currentUserReadOnly={currentUserReadOnly}
              usersStore={usersStore}
            />
          </Panel>
        </Row>
        <DeleteWarningModal
          deletedItemName={this.reportById(reportIdSelectedForDelete)?.name || ''}
          isOpen={reportIdSelectedForDelete}
          handleDelete={this.handleDeleteReport}
          warningMessage="Be advise you are about to delete Report"
          modalTitle="Delete Report"
        />
        {this.renderEditReportModalByType(editedReport.id)}
      </Container>
    );
  }
}

Reports.propTypes = {
  usersStore: PropTypes.object.isRequired,
  reports: PropTypes.array.isRequired,
  reportsLoading: PropTypes.bool.isRequired,
  saveCueReport: PropTypes.func.isRequired,
  saveAssetReport: PropTypes.func.isRequired,
  sendReport: PropTypes.func.isRequired,
  saveCustomDashboardReport: PropTypes.func.isRequired,
  deleteReport: PropTypes.func.isRequired,
};

const ObserverReports = withReportsContextProvider(observer(Reports));
export default ObserverReports;
