/* eslint-disable no-param-reassign,class-methods-use-this */
/* eslint-disable no-empty */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable arrow-parens */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { capitalize } from 'shared/utils/strUtil';
import {
  ACCOUNT_FEATURES,
  CLOUD_TYPE_IDS,
  DIVISION_TYPE_ID,
  INVALID_RESULT_STRING,
  mapParentUserTypeToDivisionUserTypeAndId,
  UsersType,
} from 'users/constants/usersConstants';
import DateFilter from 'shared/modules/dateFilter';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { initAprinsic, initSegment } from 'shared/modules/segmentAndAptrinsicHandler';
import apiConstants from 'shared/api/apiConstants';
import { parseJwt, setLocalStorage } from 'shared/utils/tokenUtil';
import { USER_TYPES_VALS } from '../constants/usersConstants';
import User from '../models/user';

class UsersStore {
  currentHoliday = null;

  usersModel = null;
  authUserKey = null;
  authUserType = null;
  welcomeScreenAnimationDone = false;
  currentDisplayedUserKey = null;
  currentDisplayedUserType = UsersType.UN_AUTH_USER;
  currentDisplayedUserName = '';
  currDispUserTrialDaysLeft = null;
  currDispUserPricingPlanId = null;
  currDispUserAccountKey = null;
  currDispUserDivisionId = null;
  currDispUserDivisionName = null;
  currDispUserCloudAccountType = null;
  isUserStoreDataReq = true;
  isLoading = false;
  isUserSubscribedToMonday = false;
  currUserInitDone = false;
  // Target Goals **********************************
  mapPanelRouteToType = new Map([
    ['business-unit-reservation-utilization', 'utilization'],
    ['business-unit-coverage', 'coverage'],
    ['pricing-method-coverage', 'coverage'],
  ]);

  constructor(rootStore, usersModel, customersModel) {
    this.rootStore = rootStore;
    this.usersModel = usersModel;
    LabelCoordinator.setUsersStore(this);
    makeObservable(this, {
      isLoading: observable,
      isUserStoreDataReq: observable,
      authUserKey: observable,
      authUserType: observable,
      welcomeScreenAnimationDone: observable,
      currentDisplayedUserKey: observable,
      currentDisplayedUserType: observable,
      currentDisplayedUserName: observable,
      currDispUserTrialDaysLeft: observable,
      currDispUserPricingPlanId: observable,
      currDispUserCloudAccountType: observable,
      currDispUserAccountKey: observable,
      currDispUserDivisionId: observable,
      currDispUserDivisionName: observable,
      currUserInitDone: observable,
      updateAutoAssignAccountProperty: action,
      setWelcomeScreenAnimationDone: action,
      updateCurrentDisplayedUserKey: action,
      updateCurrentDisplayedUser: action,
      updateCurrentDisplayedUserAccountKeyAndCloudId: action,
      updateCurrentAuthUser: action,
      initMainUser: action,
      fetchData: action,
      registerUser: action,
      updateLinkedAccounts: action,
      fetchUserLinkedAccounts: action,
      // divisions
      updateCurrDisplayedDivIdAndName: action,
      deleteSubUser: action,
      updateCuaAlertsSendStatus: action,
      getCurrDisplayedAccount: computed,
      getCurrUserName: computed,
      currentDisplaydUser: computed,
      cauAlertsShowStatus: computed,
    });
  }

  get getCurrentUser() {
    const user = this.getUserByKey(this.currentDisplayedUserKey);
    if (user) {
      return user;
    }
    return null;
  }

  get accountInfo() {
    const user = this.getUserByKey(this.currentDisplayedUserKey);
    const changedUserAccount = user.getAccountByAccKey(this.currDispUserAccountKey);
    return changedUserAccount.accountInfo;
  }

  get getCurrUserKey() {
    return this.currentDisplayedUserKey;
  }

  get getCurrUserName() {
    return capitalize(this.currentDisplayedUserName);
  }

  get getCurrentDisplayedUserType() {
    return this.currentDisplayedUserType;
  }

  get getCurrentDisplayedUserCloudAccountType() {
    return this.currDispUserCloudAccountType;
  }

  get currentUserReadOnly() {
    const user = this.getUserByKey(this.currentDisplayedUserKey);
    return (user || {}).isReadOnly;
  }

  get isCurrentAuthUserParent() {
    let result = false;
    const user = this.getUserByKey(this.authUserKey);
    result = user ? user.getIsParent() : false;
    return result;
  }

  get isCurrentAuthUserOnTrialPlan() {
    let result = false;
    const user = this.getUserByKey(this.authUserKey);
    result = user && user.pricingPlanId === 2;
    return result;
  }

  get currentAuthUserDaysLeftIfOnTrialPlan() {
    let result = false;
    const user = this.getUserByKey(this.authUserKey);
    result = user && user.pricingPlanId === 2 ? user.remainingTrialDays : '';
    return result;
  }

  get cauAlertsShowStatus() {
    return this.usersModel.cauAlertsShowStatus;
  }

  get currentDisplaydUser() {
    // return this.usersModel.users.find(user => user.userKey === this.currentDisplayedUserKey);
    return this.getUserByKey(this.getCurrUserKey);
  }

  updateUserSlackData = async (code, userKey) => {
    const userSlackData = {
      code,
      userKey,
    };
    return this.usersModel.updateUserSlackData(userSlackData);
  };

  connectUserToCloudAccount = async (connectData) => {
    const result = await this.usersModel.connectUserToCloudAccount(connectData, this.currentDisplayedUserKey);
    return result;
  };

  processUserPayment = async (userPaymentData) => {
    const result = await this.usersModel.processUserPayment(userPaymentData);
    return result;
  };

  insertUserCloudAccountInterface = async (connectData) => {
    const result = await this.usersModel.insertUserCloudAccountInterface(connectData);
    return result;
  };

  initCurrUserAttributes = (user) => {
    runInAction(() => {
      this.currUserInitDone = false;
    });
    if (!user) {
      return null;
    }
    runInAction(() => {
      this.currentDisplayedUserType = user.userType;
      this.currentDisplayedUserKey = user.userKey;
      this.currDispUserTrialDaysLeft = user.remainingTrialDays;
      this.currDispUserPricingPlanId = user.pricingPlanId;
      this.currUserName = user.userName;
      const sessionAccounKey = window.localStorage.getItem('currDispUserAccountKey');
      const currDispUserAccount = user.getDefaultUserAccount(sessionAccounKey, user.settings);
      if (currDispUserAccount) {
        const lastProcessTime = currDispUserAccount.getLastProcessTime();
        DateFilter.setDate(lastProcessTime);
        this.currDispUserAccountKey = currDispUserAccount.getAccKey();
        this.currDispUserDivisionId = currDispUserAccount.getDivisionId();
        this.currDispUserDivisionName = currDispUserAccount.getMainDivisionName();
        this.updateCurrentCloudAccountType(currDispUserAccount.getAccTypeId());
      }
      this.currUserInitDone = true;
    });
  };

  initMainUser = async () => {
    const mainAuthUser = await this.fetchMainAuthUser();
    await this.usersModel.processMainUserAndUpdateIfNeeded(); // specific checks
    this.initCurrUserAttributes(mainAuthUser);
  };

  reInitMainUser = () => {
    const mainUser = this.getMainUser();
    this.initCurrUserAttributes(mainUser);
    initAprinsic(this.getUserDataForSegment(), mainUser.userHash);
    initSegment(this.getUserDataForSegment());
  };

  translateUserTypeToName = (tabObj, value) => {
    const keys = Object.keys(tabObj);
    const vals = Object.values(tabObj);
    const index = vals.indexOf(value);
    return keys[index];
  };

  findDomainEmail = (email) => {
    if (!email || email.length === 0) {
      return '';
    }
    const [, domain] = email.split('@');
    return domain ? domain.split('.')[0] : '';
  };

  getUserDataForSegment = () => ({
    userKey: this.currentDisplayedUserKey,
    userType: this.translateUserTypeToName(USER_TYPES_VALS, this.currentDisplayedUserType),
    email: this.currUserName,
    userName: this.currentDisplayedUserName,
    account: this.getCurrDisplayedAccount.accountName,
    isMSP: [
      USER_TYPES_VALS.RESELLER_CUSTOMER_EDP,
      USER_TYPES_VALS.RESELLER_CUSTOMER,
      USER_TYPES_VALS.RESELLER,
      USER_TYPES_VALS.RESELLER_BUSINESS_ADMIN,
    ].some((val) => val === this.currentDisplayedUserType),
    accountType: this.translateUserTypeToName(CLOUD_TYPE_IDS, this.currDispUserCloudAccountType),
    accountId: this.getCurrDisplayedAccount.accountId,
    companyName: (this.currentDisplaydUser && this.currentDisplaydUser.companyName) || null,
  });

  getMainUser() {
    return this.usersModel.mainUser;
  }

  getAuthUserKey = () => this.authUserKey;

  getCurrDispUserInfo(key) {
    const user = this.getUserByKey(key);
    if (!user) {
      return null;
    }
    const account = user.getDefaultUserAccount(null, user.settings);
    const userInfo = {
      name: user.getUserDisplayName(),
      type: user.getUserType(),
      theme: user.getCustomTheme(),
      isResellerMode: user.getIsResellerMode(),
      remainingTrialDays: user.remainingTrialDays,
      roleId: user.roleId,
      isAdmin: user.getIsAdmin(),
    };
    if (!account) {
      return userInfo;
    }
    return {
      ...userInfo,
      accountKey: account.getAccKey(),
      divisionId: account.getDivisionId(),
      divisionName: account.getMainDivisionName(),
      accountProvider: account.getAccTypeId(),
      currencyCode: account.getUserCurrencyCode(),
    };
  }

  getCurrDisplayedUserTheme = () => {
    const user = this.getCurrDispUserInfo(this.currentDisplayedUserKey);
    if (!user) {
      return null;
    }
    return user.theme;
  };

  getCurrDisplayedUserRole = () => {
    const user = this.getCurrDispUserInfo(this.currentDisplayedUserKey);
    if (!user) {
      return null;
    }
    return user.roleId;
  };

  getCurrDisplayedUserIsAdmin = () => {
    const user = this.getCurrDispUserInfo(this.currentDisplayedUserKey);
    if (!user) {
      return null;
    }
    return user.isAdmin;
  };

  getCurrDisplayedUserIsResellerMode = () => {
    const user = this.getCurrDispUserInfo(this.currentDisplayedUserKey);
    if (!user) {
      return null;
    }
    return user.isResellerMode;
  };
  getCurrDisplayedUserCompanyIsResellerMode = () => {
    const user = this.getCurrDispUserInfo(this.currentDisplayedUserKey);
    if (!user) {
      return null;
    }
    return user.isResellerModeCompany;
  };
  getCurrDisplayedUserCurrencySymbolCode = () => {
    const user = this.getCurrDispUserInfo(this.currentDisplayedUserKey);
    if (!user) {
      return null;
    }
    return user.currencyCode;
  };
  getCurrDisplayedUserTypeAndCloudId = () => ({
    userType: this.currentDisplayedUserType,
    cloudTypeId: this.currDispUserCloudAccountType,
  });
  getUserCurrencyByAccountDetails = (acckey) => {
    const user = this.getUserByKey(this.currentDisplayedUserKey);
    const account = user.getAccountByAccKey(acckey);
    return account?.currencyCode || '';
  };

  getAllCurrDisplayedUserAccounts = () => {
    try {
      const currUser = this.getUserByKey(this.currentDisplayedUserKey);
      return currUser ? currUser.getAllAccounts() : [];
    } catch (error) {
      return [];
    }
  };

  getDefaultFeaturesForNewUser = () => {
    try {
      const currUser = this.getUserByKey(this.currentDisplayedUserKey);
      return currUser?.defaultAccountFeatures || [];
    } catch (error) {
      return [];
    }
  };

  getIsAzureAccountMigrationRequired = (accountId) => {
    const accountFeatures = this.getCurrentDisplayedAccountFeatures(this.currDispUserAccountKey);
    const anyAccountIsEA = this.getAllCurrDisplayedUserAccounts()
      .filter((acc) => !accountId || acc.accountId === accountId)
      .some((account) => account.accountInfo.azure_interface_type === 'EA');
    const isMigrationEnabled = accountFeatures.includes(ACCOUNT_FEATURES.AZURE_EA_MCA_ENABLE_MIGRATION);
    return anyAccountIsEA && isMigrationEnabled;
  };

  getIsAzureAccountMCAOnboardingEnabled = () => {
    const accountFeatures = this.getCurrentDisplayedAccountFeatures(this.currDispUserAccountKey);
    return accountFeatures.includes(ACCOUNT_FEATURES.AZURE_MCA_ONBOARDING);
  };

  deleteAccountFromDisplayedUserAccounts = (account) => {
    const currUser = this.getUserByKey(this.currentDisplayedUserKey);
    if (currUser && account) {
      currUser.deleteAcc(account);
    }
  };

  updateAutoAssignAccountProperty = async (autoAssignLinkedAccounts) => {
    runInAction(() => {
      this.usersModel.users = this.usersModel.users.map((u) => {
        if (u.userKey !== this.currentDisplayedUserKey) {
          return u;
        }
        return {
          ...u,
          accounts: u.accounts.map((a) => {
            if (a.getAccKey() !== this.currDispUserAccountKey) {
              return a;
            }
            a.autoAssignLinkedAccounts = autoAssignLinkedAccounts;
            return a;
          }),
        };
      });
    });
    await this.usersModel.apiGateway.usersApi.updateAutoAssignAccountProperty(autoAssignLinkedAccounts);
  };

  get getCurrDisplayedAccount() {
    const currUser = this.getUserByKey(this.currentDisplayedUserKey);
    return currUser ? currUser.getAccountByAccKey(this.currDispUserAccountKey) : null;
  }

  getCurrDisplayedAccountId = () => {
    const currDisplayedAccount = this.getCurrDisplayedAccount;
    if (currDisplayedAccount) {
      return currDisplayedAccount.accountId;
    }

    return '-1';
  };

  getCurrAccountDivisionId = () => {
    const currUser = this.getUserByKey(this.currentDisplayedUserKey);
    const currAccount = currUser ? currUser.getAccountByAccKey(this.currDispUserAccountKey) : '';
    return currAccount ? currAccount.getDivisionId() : this.currDispUserDivisionId;
  };

  getCurrAccountTypeId = () => {
    const currUser = this.getUserByKey(this.currentDisplayedUserKey);
    return currUser ? currUser.getAccTypeIdByAcckey(this.currDispUserAccountKey) : '';
  };

  getCurrentDisplayedAccountId = (currDispUserAccountKey) => {
    const allUserAccounts = this.getAllCurrDisplayedUserAccounts();
    const currentAccount = allUserAccounts.find((acc) => acc.accountKey === currDispUserAccountKey) || null;
    if (currentAccount) {
      return currentAccount.accountId;
    }
    return '';
  };

  getCurrentDisplayedAccountFeatures = (currDispUserAccountKey) => {
    const allUserAccounts = this.getAllCurrDisplayedUserAccounts();
    if (!allUserAccounts.length) {
      return this.getDefaultFeaturesForNewUser();
    }
    const currentAccount =
      allUserAccounts.length > 0 ? allUserAccounts.filter((acc) => acc.accountKey === currDispUserAccountKey) : [];
    if (currentAccount.length) {
      return currentAccount[0].features;
    }
    return [];
  };

  getCurrentDisplayedDivisionId = (currDispUserAccountKey) => {
    const allUserAccounts = this.getAllCurrDisplayedUserAccounts();
    const currentAccount =
      allUserAccounts.length > 0 ? allUserAccounts.filter((acc) => acc.accountKey === currDispUserAccountKey) : [];
    if (currentAccount.length) {
      return currentAccount[0].divisionId;
    }
    return '';
  };

  getCurrentDisplayedAccountName = (currDispUserAccountKey) => {
    const allUserAccounts = this.getAllCurrDisplayedUserAccounts();
    const currentAccount =
      allUserAccounts.length > 0 ? allUserAccounts.filter((acc) => acc.accountKey === currDispUserAccountKey) : [];
    if (currentAccount.length) {
      return currentAccount[0].accountName;
    }
    return '';
  };

  getUserByKey(key) {
    const userAsArr = this.usersModel.users.filter((user) => user.userKey === key);
    return userAsArr[0];
  }

  getCloudAccountData = async (accountId, cloudTypeId) => {
    try {
      const data = await this.usersModel.apiGateway.getCloudAccountData(accountId, cloudTypeId);
      return data;
    } catch (error) {
      return {};
    }
  };

  getUserDataByAccount = (accountId) => this.usersModel.apiGateway.getUserDataByAccount(accountId);

  getIsUserSubscribedToMondayWebhook = async () => {
    const isUserSubscribed = await this.usersModel.getIsUserSubscribedToMondayWebhook();
    runInAction(() => {
      this.isUserSubscribedToMonday = isUserSubscribed;
    });
  };

  deleteAccount = async (accountSelectedForDelete) => {
    try {
      this.deleteAccountFromDisplayedUserAccounts(accountSelectedForDelete);
      const { accountId, cloudTypeId } = accountSelectedForDelete;
      await this.usersModel.apiGateway.deleteAccount(accountId, cloudTypeId);
      await this.usersModel.fetchUser();
    } catch (error) {}
  };

  updateCurrentDisplayedUserKey = (userKey) => {
    this.currentDisplayedUserKey = userKey;
    window.localStorage.setItem('dispUserKey', userKey);
    window.sessionStorage.setItem('dispUserKey', userKey);
  };

  updateCurrentDisplayedUserName = (name) => {
    this.currentDisplayedUserName = name;
  };

  updateCurrentCloudAccountType = (cloudAccType) => {
    this.currDispUserCloudAccountType = cloudAccType;
    LabelCoordinator.setCloudType(this.currDispUserCloudAccountType);
  };

  updateCurrentDisplayedUserAccount = async (accountId, accountKey, newAccountName, newRoleARN) => {
    const currUser = this.getUserByKey(this.currentDisplayedUserKey);
    if (currUser) {
      currUser.updateAccountName(accountKey, newAccountName);
      await this.usersModel.apiGateway.updateUserAccount(accountId, accountKey, newAccountName, newRoleARN);
      await this.usersModel.fetchUser();
    }
  };

  updateCurrDisplayedDivIdAndName = (divisionId, divisionName, accountKey, forceFetch = true) => {
    this.currDispUserDivisionId = divisionId;
    this.currDispUserDivisionName = divisionName;
    this.currDispUserAccountKey = accountKey;
    if (forceFetch) {
      this.rootStore.fetchData(this.currentDisplayedUserKey, false);
    }
  };

  changeCustomerUserType = () => {
    if (this.currentDisplayedUserType === UsersType.RESELLER) {
      this.setUserType(UsersType.RESELLER_CUSTOMER);
    }
  };

  getNewUserType = (newUserKey) => {
    const authUserType = this.getUserByKey(this.authUserKey).userType;
    const newUser = this.getUserByKey(newUserKey);
    const { userType, isReadOnly } = newUser;
    if (authUserType === UsersType.USER && isReadOnly) {
      return authUserType;
    }
    return userType;
  };

  updateCurrentDisplayedUser = async (userKey) => {
    this.updateCurrentDisplayedUserKey(userKey);
    const account = this.getCurrDispUserInfo(userKey);
    this.currentDisplayedUserName = account.name;
    this.currDispUserTrialDaysLeft = this.getUserByKey(userKey).remainingTrialDays;
    this.currDispUserPricingPlanId = this.getUserByKey(userKey).pricingPlanId;
    this.currentDisplayedUserType = this.getNewUserType(userKey);
    this.currDispUserAccountKey = account.accountKey;
    this.currDispUserDivisionId = account.divisionId;
    this.currDispUserDivisionName = account.divisionName;
    this.updateCurrentCloudAccountType(account.accountProvider);
  };

  updateCurrentDisplayedUserAccountKeyAndCloudId = (numericNewAccKey, cloudId) => {
    this.currDispUserAccountKey = numericNewAccKey;
    window.localStorage.setItem('currDispUserAccountKey', numericNewAccKey);
    if (cloudId !== undefined) {
      this.updateCurrentCloudAccountType(cloudId);
    }
    const currUser = this.getUserByKey(this.currentDisplayedUserKey);
    this.currDispUserDivisionId = currUser.getAccountByAccKey(numericNewAccKey).getDivisionId();
    this.currDispUserDivisionName = currUser.getAccountByAccKey(numericNewAccKey).getMainDivisionName();
    this.currentDisplayedUserType = currUser.getUserType();
    this.usersModel.userLinkedAccounts = undefined;
  };

  updateCurrentDisplayedUserLogIn = (userKey) => {
    this.updateCurrentDisplayedUserKey(userKey);
    this.currentDisplayedUserName = this.getUserByKey(userKey).userDisplayName;
    this.currentDisplayedUserType = this.getUserByKey(userKey).userType;
    this.currDispUserTrialDaysLeft = this.getUserByKey(userKey).remainingTrialDays;
    this.currDispUserPricingPlanId = this.getUserByKey(userKey).pricingPlanId;
    this.currDispUserAccountKey = this.getCurrDispUserInfo(userKey).accountKey;
    this.currDispUserDivisionId = this.getCurrDispUserInfo(userKey).divisionId;
    this.currDispUserDivisionName = this.getCurrDispUserInfo(userKey).divisionName;
    this.updateCurrentCloudAccountType(this.getCurrDispUserInfo(userKey).accountProvider);
  };

  updateCurrentAuthUser = (userKey) => {
    this.authUserKey = userKey;
    window.localStorage.setItem('authUserKey', userKey);
  };

  setUserType = (userType) => {
    this.currentDisplayedUserType = userType;
  };

  updateUserTypeAndAwsAccId = async (newType, awsAccountID, isConnectionSuccess, cloudType, accountKey) => {
    // user key is needed in the model for local data update
    const result = await this.usersModel.updateUserTypeAndAwsAccId(newType, awsAccountID, isConnectionSuccess);
    this.currentDisplayedUserType = newType;
    this.authUserType = newType;
    this.currDispUserAccountKey = accountKey;
    this.currDispUserDivisionId = 0;
    this.currDispUserCloudAccountType = cloudType || this.currDispUserCloudAccountType;
    return result;
  };

  getAllCustomerDivisionUserAccounts = () => {
    try {
      const currUser = this.getUserByKey(this.currentDisplayedUserKey);
      return currUser?.getAllCustomerDivisions();
    } catch (error) {
      return [];
    }
  };

  changeOnBoardUserTypeIfHasProcessTime = (userKey) => {
    let updated = false;
    if (this.getUserByKey(userKey)) {
      const newType = UsersType.USER;
      this.usersModel.updateUserData(userKey, 'userType', newType); // user key is needed for local data update
      updated = true;
    }
    return updated;
  };

  handleDisplayedUserChange = async (userKey) => {
    try {
      this.rootStore.appStore.setIsAccountLoading(true);
      await this.fetchSubUserDetails(userKey);
      const user = this.getUserByKey(userKey);
      const currDispUserAccount = user.getDefaultUserAccount(null, user.settings);
      const { lastProcessTime = new Date(null) } = currDispUserAccount || {};
      DateFilter.setDate(lastProcessTime);
      this.updateCurrentDisplayedUser(userKey);
      this.usersModel.userLinkedAccounts = undefined;
      this.rootStore.invalidateStores();
      this.rootStore.fetchData(this.currentDisplayedUserKey);
      runInAction(() => {
        this.rootStore.appStore.setIsAccountLoading(false);
      });
    } catch (error) {
      runInAction(() => {
        this.rootStore.appStore.setIsAccountLoading(false);
      });
    }
  };

  handleDisplayedAccountChange = async (newKey, cloudId) => {
    if (newKey !== this.currDispUserAccountKey) {
      const user = this.getUserByKey(this.currentDisplayedUserKey);
      const numericNewAccKey = Object.prototype.toString.call(newKey) === '[object Number]' ? newKey : +newKey;
      if (!user || !user.getAccountByAccKey(numericNewAccKey)) {
        return;
      }
      const changedUserAccount = user.getAccountByAccKey(numericNewAccKey);
      const { lastProcessTime = new Date(null) } = changedUserAccount || {};
      DateFilter.setDate(lastProcessTime);
      this.updateCurrentDisplayedUserAccountKeyAndCloudId(numericNewAccKey, cloudId);
      this.rootStore.invalidateStores(false);
      // In order not to delay the page load, we fetchData after a timeout
      setTimeout(() => {
        this.rootStore.fetchData(this.currentDisplayedUserKey);
      }, 500);
    }
  };

  handleBackToCurrDisplayedAccount = () => {
    const user = this.getUserByKey(this.currentDisplayedUserKey);
    const { divisionId, mainDivisionName, cloudTypeId } = user.getAccountByAccKey(this.currDispUserAccountKey);
    this.setUserType(user.userType);
    this.rootStore.invalidateStores(false);
    this.updateCurrDisplayedDivIdAndName(divisionId, mainDivisionName, this.currDispUserAccountKey);
    this.fetchDivisionGroups();
  };

  setMainAuthUserAsPayingUser = (pricingPlan, billingUrl, isPayingUser = true) => {
    const mainUser = this.getMainUser();
    if (mainUser) {
      mainUser.isPayingUser = isPayingUser;
      mainUser.pricingPlan = pricingPlan;
      mainUser.paymentBillingUrl = billingUrl;
    }
  };

  isCurrUserIsPayingUser = () => {
    const currDispUser = this.getUserByKey(this.currentDisplayedUserKey);
    if (currDispUser) {
      return currDispUser.isPayingUser;
    }

    return false;
  };

  registerUser = async (username, displayName, password) => {
    this.isLoading = true;
    try {
      const result = await this.usersModel.registerUser(username, displayName, password);
      runInAction(() => {
        this.isLoading = false;
      });
      return result;
    } catch (error) {
      this.state = `error: ${error}`;
    }

    return null;
  };

  confirmUser = async (
    firstName,
    lastName,
    jobTitle,
    companyName,
    username,
    displayName,
    password,
    codeConfirmation,
    providerId,
    campaignId,
    messageId,
    isMSP,
    theme,
  ) => {
    const confirmResult = { result: true, msg: '' };
    try {
      let userKey = '';
      // make server API call instead of direct calling AWS
      const user = await this.usersModel.signIn(username, password);
      const { jwtToken: authToken, username: externalUserName } = user || {};
      userKey = externalUserName || '';
      // setting the initial displayed Account user to logged in user
      const decoded = parseJwt(authToken);
      setLocalStorage('authToken', authToken, decoded['custom:useSessionStorage'] === '1');
      this.updateCurrentAuthUser(userKey);
      this.updateCurrentDisplayedUserKey(userKey);
      const createUserResult = await this.usersModel.createPileusUser(
        firstName,
        lastName,
        jobTitle,
        companyName,
        username,
        displayName,
        userKey,
        providerId,
        campaignId,
        messageId,
        isMSP,
        theme,
      );
    } catch (error) {
      confirmResult.result = false;
      confirmResult.msg = INVALID_RESULT_STRING;
      this.state = `error: ${error}`;
    }
    return confirmResult;
  };

  isFetchRequired = () => {
    const mainAuthUser = this.getMainUser();
    let isRequired = true;
    if (mainAuthUser && mainAuthUser.userType === UsersType.NEW_USER) {
      isRequired = false;
    }
    return isRequired;
  };

  createPileusUser = async (username) => {
    this.isLoading = true;
    try {
      const result = await this.usersModel.createPileusUser(username);
      runInAction(() => {
        this.isLoading = false;
      });
      return result;
    } catch (error) {
      this.state = `error: ${error}`;
    }

    return null;
  };

  fetchUserLinkedAccounts = async () => {
    const mainUser = this.getMainUser();
    if (mainUser) {
      const result = await this.usersModel.fetchUserLinkedAccounts(this.currDispUserCloudAccountType);
      return result;
    }
    return false;
  };

  signinWithToken = async () => {
    const userInfo = await this.usersModel.signinWithToken();
    return userInfo;
  };

  setExternalUserNameToUserKey = async (externalUserName, userKey) => {
    const result = await this.usersModel.setExternalUserNameToUserKey(externalUserName, userKey);
    return result;
  };

  getUserKeyFromUserName = async (userName) => {
    const userKey = await this.usersModel.getUserKeyFromUserName(userName);
    return userKey;
  };

  fetchAvailableLinkedAccounts = async () => {
    const mainUser = this.getMainUser();
    if (mainUser) {
      const data = await this.usersModel.fetchAvailableLinkedAccounts();
      if (data) {
        return data;
      }
    }
    return false;
  };

  fetchAvailableDivisionLinkedAccounts = async () => {
    const mainUser = this.getMainUser();
    if (mainUser) {
      const data = await this.usersModel.fetchAvailableDivisionLinkedAccounts();
      if (data) {
        return data;
      }
    }
    return false;
  };

  updateLinkedAccounts = async (linkedAccounts) => {
    const mainUser = this.getMainUser();
    if (mainUser) {
      const result = await this.usersModel.updateLinkedAccounts(linkedAccounts);
      return result;
    }
    return {};
  };

  getUserAlerts = async () => {
    const mainUser = this.getMainUser();
    if (mainUser) {
      const data = await this.usersModel.getUserAlerts(this.currentDisplayedUserKey); // user key is needed for the model locally
      if (data) {
        return data;
      }
    }
    return false;
  };

  updateAlertDismiss = async (dueDate, alertId) => {
    const mainUser = this.getMainUser();
    if (mainUser) {
      const result = await this.usersModel.updateAlertDismiss(dueDate, alertId);
      return result;
    }
    return false;
  };

  fetchMainAuthUser = async () => {
    let mainAuthuser = null;
    try {
      mainAuthuser = await this.usersModel.fetchUser();
    } catch (error) {
      this.state = 'error';
    }
    return mainAuthuser;
  };

  resendSignUp = async (username) => {
    const result = await this.usersModel.resendSignUp(username);
    return result;
  };

  fetchUserNotifications = async () => {
    try {
      await this.usersModel.fetchUserNotifications();
    } catch (error) {
      this.state = 'error';
    }
    return this.usersModel.usersNotifications;
  };

  // user general details form

  updateUserNotificationSettings = async (notificationInputsState, isShowCreateReport) => {
    try {
      await this.usersModel.updateUserNotificationSettings(notificationInputsState, isShowCreateReport);
      return true;
    } catch (error) {
      this.state = 'error';
    }
    return false;
  };

  updateCuaAlertsSendStatus = async (newSendStatus) => {
    const result = await this.usersModel.updateCuaAlertsSendStatus(newSendStatus);
    return result;
  };

  updateUserGeneralData = (firstName, lastName, jobTitle) =>
    this.usersModel.updateUserGeneralData(this.currentDisplayedUserKey, firstName, lastName, jobTitle);

  fetchData = async (currDispUserKey, isForceFetch = false) => {
    if (this.isUserStoreDataReq) {
      this.isLoading = true;
      try {
        await this.usersModel.fetchData(currDispUserKey, isForceFetch);
        runInAction(() => {
          this.updateCurrentDisplayedUserLogIn(currDispUserKey);
          this.reInitMainUser();
          this.isUserStoreDataReq = false;
          this.isLoading = false;
          this.currUserInitDone = true;
        });
      } catch (error) {
        this.state = 'error';
      }
    }
    await this.fetchCurrentDisplayedUserData();
    runInAction(() => {
      this.isLoading = false;
      this.currUserInitDone = true;
    });
  };

  fetchCurrentDisplayedUserData = async () => {
    this.isLoading = true;
    try {
      // this is not necessary to wait
      this.usersModel.fetchAccountTagsData();
    } catch (error) {
      this.state = 'error';
    }
    this.isLoading = false;
  };

  getTargetGoal = (panelService, panelRoute) => {
    const targetGoals = this.usersModel.targetGoals;
    const selectedTargetGoal =
      targetGoals &&
      targetGoals.find((targetGoal) => {
        const { service, type } = targetGoal;
        return service === panelService && type === this.mapPanelRouteToType.get(panelRoute);
      });
    return selectedTargetGoal && selectedTargetGoal.target;
  };
  getNewUserUserType = (isAdminUser, currentDisplayedUserType) => {
    if (!isAdminUser && +currentDisplayedUserType !== UsersType.RESELLER) {
      return null;
    }
    return currentDisplayedUserType;
  };
  fetchAllTargetGoals = async () => {
    const goals = await this.usersModel.fetchAllTargetGoals();
    return goals || [];
  };
  createNewTargetGoal = async (service, type, target) => {
    await this.usersModel.createNewTargetGoal(service, type, target, this.currentDisplayedUserName);
  };
  deleteTargetGoal = async (uuid) => {
    await this.usersModel.deleteTargetGoal(uuid);
  };
  updateTargetGoal = async (uuid, service, type, target) => {
    await this.usersModel.updateTargetGoal(uuid, service, type, target, this.currentDisplayedUserName);
  };

  // Sub Users **************************************

  fetchSubUserDetails = async (userKey) => {
    const user = this.getUserByKey(userKey);
    if (user.accounts && user.accounts.length > 0) {
      // means that this user was fetched already
      return;
    }
    const data = await this.usersModel.fetchSubUserDetails(userKey);
    const newUser = new User(data);
    this.usersModel.replaceUser(newUser);
  };

  fetchSubUsers = async () => {
    const mainUser = this.getMainUser();
    if (mainUser) {
      const data = await this.usersModel.fetchSubUsers();
      if (data) {
        return data;
      }
    }
    return false;
  };

  prepareSubUser = (userKey, divisionUserTableId, divisionId, email, name, accountKey) => {
    const currDisplayedAccount = this.getCurrDisplayedAccount;
    this.usersModel.prepareSubUser(
      userKey,
      divisionUserTableId,
      divisionId,
      email,
      name,
      accountKey,
      currDisplayedAccount,
    );
  };
  updateUserRole = async (users) => {
    const result = await this.usersModel.updateUserRole(users);
    await this.fetchSubUsers();
    return result;
  };
  isRoleHasUsersAttached = (roleId) => {
    let result = true;
    result = this.usersModel.isRoleInSubUsers(roleId);
    return result;
  };
  createNewUsersInRole = async (emails, userRole, isAdminUser, overrideReadOnly) => {
    const divisionTypeId = DIVISION_TYPE_ID.USER;
    const userType = this.getNewUserUserType(isAdminUser, this.currentDisplayedUserType);
    const result = await this.usersModel.createNewUsersInRole(
      emails,
      userRole,
      divisionTypeId,
      userType,
      overrideReadOnly,
    );
    return result;
  };
  createNewCustomerSubUser = async (email, customerName, divisionId, accountKey, roleId) => {
    const { userType, divisionTypeId } = mapParentUserTypeToDivisionUserTypeAndId.get(this.currentDisplayedUserType);
    const currDisplayedAccount = this.getCurrDisplayedAccount;
    try {
      const result = await this.usersModel.createNewCustomerSubUser(
        email,
        customerName,
        divisionId,
        divisionTypeId,
        userType,
        currDisplayedAccount,
        roleId,
      );
      return result;
    } catch (error) {
      return false;
    }
  };

  deleteSubUser = async (divUserKey, divisionId, userName, divisionTypeId, accountKey) => {
    const result = await this.usersModel.deleteSubUser(divUserKey, accountKey, userName, divisionTypeId);
    return result;
  };

  getSubUsersListByDisplayedType = () => {
    const type = this.usersModel.mapUserTypeToSubUsers.get(this.currentDisplayedUserType);
    let list = [];
    if (type === 'costCenter') {
      list = this.usersModel.getCostCentersSubUsers;
    } else if (type === 'customer') {
      list = (this.usersModel.getCustomersSubUsers || []).filter(
        (cus) => cus.awsAccountId === this.getCurrDisplayedAccountId(),
      );
    }
    return list || [];
  };

  // Division Groups **************************************
  isAccountConnectedToDivisions = (accountId) => {
    const result = this.usersModel.isAccountConnectedToDivisions(accountId);
    return result;
  };
  fetchDivisionGroups = async (mapUpdate) => {
    const data = await this.usersModel.fetchDivisionGroups(mapUpdate);
    if (data) {
      return data;
    }
    return false;
  };

  createNewDivisionGroup = async (divGroupName, linkedAccounts, newDivision) => {
    const { divisionTypeId } = mapParentUserTypeToDivisionUserTypeAndId.get(this.currentDisplayedUserType);
    await this.usersModel.createNewDivisionGroup(
      divGroupName,
      linkedAccounts,
      newDivision,
      this.currDispUserAccountKey,
      divisionTypeId,
    );
    await this.fetchDivisionGroups();
  };

  deleteDivisionGroup = async (divisionId, divisionName) => {
    const result = await this.usersModel.deleteDivisionGroup(divisionId, divisionName);
    await this.fetchAvailableDivisionLinkedAccounts();
    await this.fetchDivisionGroups();
    return result;
  };
  updateLocalModelDivisionName = (divId, newName) => {
    const { divisionGroups } = this.usersModel;
    const editedDivisionIndex = divisionGroups.findIndex((x) => x.divisionId === divId);
    if (editedDivisionIndex > -1) {
      divisionGroups[editedDivisionIndex].divisionNameDisplay = newName;
      return divisionGroups[editedDivisionIndex].divisionName;
    }
    return null;
  };
  updateLocalModelDivision = (divId, newName, newCode) => {
    const { divisionGroups } = this.usersModel;
    const editedDivisionIndex = divisionGroups.findIndex((x) => x.divisionId === divId);
    if (editedDivisionIndex > -1) {
      divisionGroups[editedDivisionIndex].divisionNameDisplay = newName;
      divisionGroups[editedDivisionIndex].divisionCode = newCode;
      return divisionGroups[editedDivisionIndex].divisionName;
    }
    return null;
  };
  updateDivision = async (divisionId, newDivDisplayName, newDivCode) => {
    const divisionName = this.updateLocalModelDivision(divisionId, newDivDisplayName, newDivCode);
    if (divisionName) {
      const divNameToDisplay = new Map([[divisionName, newDivDisplayName]]);
      LabelCoordinator.addNewKeyToDisplayAndReverseMapValues('cueDisplayCoordinator', divNameToDisplay);
    }
    const result = await this.usersModel.updateDivisionGroup(divisionId, newDivDisplayName, newDivCode);
    return result;
  };
  updateDivisionGroupName = async (divisionId, newDivDisplayName) => {
    const divisionName = this.updateLocalModelDivisionName(divisionId, newDivDisplayName);
    if (divisionName) {
      // add the new display value to the display map
      const divNameToDisplay = new Map([[divisionName, newDivDisplayName]]);
      LabelCoordinator.addNewKeyToDisplayAndReverseMapValues('cueDisplayCoordinator', divNameToDisplay);
    }
    const result = await this.usersModel.updateDivisionGroupName(divisionId, newDivDisplayName);
    return result;
  };

  updateDivisionGroupLinkAccounts = async (divId, linkedAccs, divisionName, divisionCode, divisionTypeId, roleId) => {
    const result = await this.usersModel.updateDivisionGroupLinkAccounts(
      divId,
      linkedAccs,
      divisionName,
      divisionCode,
      divisionTypeId,
      roleId,
    );
    return result;
  };
  updateAvailableDivisionLinkAccountLocally = (linkedAccountIds) => {
    this.usersModel.updateAvailableDivisionLinkAccountLocally(linkedAccountIds);
  };
  getDivisionValues = () => [...this.usersModel.getDivisionValues()];
  getDivisionValuesByPayerAccount = (accountId) => [...this.usersModel.getDivisionValuesByPayerAccount(accountId)];
  getDivisionIdByDivName = (name) => this.usersModel.getDivisionIdByDivName(name);
  getDivisionNameByDivId = (id) => this.usersModel.getDivisionNameByDivId(id);
  // ********* User Margin
  updateUserMarginPercentage = async (marginPercentageNumber) => {
    const result = await this.usersModel.apiGateway.updateUserMarginPercentage(marginPercentageNumber);
    return result;
  };
  getUserMarginPercent = async () => {
    const result = await this.usersModel.apiGateway.getUserMarginPercent();
    return result;
  };

  // AZURE On Boarding

  connectAzureEaAccount = async (eaDetailsConfiguration) => {
    const result = await this.usersModel.connectAzureEaAccount(eaDetailsConfiguration, this.currentDisplayedUserKey);
    return result;
  };
  connectAzureBillingExportAccount = async (billingExportConfiguration) => {
    const result = await this.usersModel.connectAzureBillingExportAccount(
      billingExportConfiguration,
      this.currentDisplayedUserKey,
    );
    return result;
  };
  saveAzureOnBoardingAppRegistrationConfiguration = async (appConfiguration) => {
    const result = await this.usersModel.apiGateway.saveAzureOnBoardingAppRegistrationConfiguration(appConfiguration);
    return result;
  };
  testAppRegistraionConfiguration = async (appConfiguration) => {
    const result = await this.usersModel.apiGateway.testAppRegistraionConfiguration(appConfiguration);
    return result;
  };
  deleteAppRegistrationConfiguration = async (data) => {
    const result = await this.usersModel.apiGateway.deleteAppRegistrationConfiguration(data);
    return result;
  };
  updateAppName = async (data) => {
    const result = await this.usersModel.apiGateway.updateAppName(data);
    return result;
  };
  getAzureRegisteredApps = async () => {
    const result = await this.usersModel.apiGateway.getAzureRegisteredApps();
    return result;
  };

  // Azure EA Api Access Code
  getAzureEaApiAccessKeyData = async () => {
    const result = await this.usersModel.getAzureEaApiAccessKeyData();
    return result;
  };

  updateAzureEaApiAccessKeyData = async (apiAccessKey, effectiveDate) => {
    const result = await this.usersModel.updateAzureEaApiAccessKeyData(apiAccessKey, effectiveDate);
    return result;
  };

  // GCP On Boarding

  connectGcpAccount = async (gcpDetailsConfiguration) => {
    const result = await this.usersModel.connectGcpAccount(gcpDetailsConfiguration, this.currentDisplayedUserKey);
    return result;
  };

  updateAccountTagsInfo = async (newData) => {
    await this.usersModel.updateAccountTagsInfo(newData);
  };
  getAccountTagsMetaData = async () => {
    const result = await this.usersModel.getAccountTagsMetaData();
    return result;
  };
  uploadAccountTagsFile = async (fileData) => {
    const result = await this.usersModel.uploadAccountTagsFile(fileData);
    return result;
  };
  deleteAccountTagsFile = async (fileId) => {
    const result = await this.usersModel.deleteAccountTagsFile(fileId);
    return result;
  };
  attachAccountTagsToCSVReport = (rows) => {
    const newRows = this.usersModel.attachAccountTagsToCSVReport(rows);
    return newRows;
  };

  // PREFERENCES

  invalidateDivisionGroups = () => {
    this.usersModel.invalidateDivisionGroups();
  };
  invalidateSubUsers = () => {
    this.usersModel.invalidateSubUsers();
  };
  invalidateTargetGoals = () => {
    this.usersModel.invalidateTargetGoals();
  };
  getMapNotAllowedPathToUsertType = async () => {
    await this.usersModel.getMapNotAllowedPathToUsertType();
  };
  isPathNotAllowedToUser = (path, userType) => this.usersModel.isPathNotAllowedToUser(path, userType);

  setWelcomeScreenAnimationDone = () => {
    this.welcomeScreenAnimationDone = true;
  };

  invalidateStore = () => {
    this.usersModel.invalidateData();
    this.authUserKey = null;
    this.authUserType = null;
    this.updateCurrentDisplayedUserKey(null);
    this.currDispUserAccountKey = null;
    this.currDispUserDivisionId = null;
    this.currDispUserDivisionName = null;
    this.currDispUserCloudAccountType = null;
    this.currentDisplayedUserType = UsersType.UN_AUTH_USER;
    this.currentDisplayedUserName = '';
    this.isUserStoreDataReq = true;
    this.currDispUserTrialDaysLeft = null;
    this.currDispUserPricingPlan = null;
    this.isLoading = true;
    this.currUserInitDone = false;
    this.welcomeScreenAnimationDone = false;
  };
}

export default UsersStore;
