/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

class NumberField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
    this.toggleEditing = this.toggleEditing.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  toCurrency(number) {
    const { getCurrencyNumber } = this.props;
    const num = typeof number === 'string' ? Number(number) : number;
    return getCurrencyNumber(Number(num.toFixed(0)));
  }

  toggleEditing() {
    const { isEditing } = this.state;
    this.setState({ isEditing: !isEditing });
  }

  render() {
    const { label, name, value, onChange } = this.props;
    const { isEditing } = this.state;
    return (
      <div>
        <label htmlFor={name}>{label}</label>
        {isEditing ? (
          <input
            className="insert-budget-input"
            type="number"
            name={name}
            value={value}
            onChange={onChange}
            onBlur={this.toggleEditing}
            autoComplete="off"
          />
        ) : (
          <input
            className="insert-budget-input"
            type="text"
            name={name}
            value={this.toCurrency(value)}
            onFocus={this.toggleEditing}
            readOnly
            autoComplete="off"
          />
        )}
      </div>
    );
  }
}

NumberField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  getCurrencyNumber: PropTypes.func.isRequired,
};

const ObserverNumberField = withUserSettingsConsumer(NumberField);
export default ObserverNumberField;
