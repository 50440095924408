import React from 'react';
import AddSlackButton from 'shared/components/slackIntegration/AddSlackButton';
import { ReactComponent as SlackLogo } from 'shared/img/icons/slack.svg';
import PropTypes from 'prop-types';
import { Grid, Table, TableColumnResizing, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import moment from 'moment/moment';
import TestSlackButton from 'shared/components/slackIntegration/testSlackButton';
import UninstallSlackButton from 'shared/components/slackIntegration/uninstallSlackButton';
import styles from './integrations.module.scss';

const detailsRowColumns = [
  { name: 'teamName', title: 'Workspace name' },
  { name: 'userName', title: 'Created by' },
  {
    name: 'dbUpdateTime',
    title: 'Last updated',
    getCellValue: (row) => moment(row.dbUpdateTime).format('MMMM DD YYYY'),
  },
  { name: 'test', title: `Test`, getCellValue: () => <TestSlackButton /> },
  { name: 'delete', title: 'Delete', getCellValue: () => <UninstallSlackButton /> },
];
const detailsRowColumnsWidth = [
  { columnName: 'teamName', width: 'auto' },
  { columnName: 'userName', width: 'auto' },
  { columnName: 'dbUpdateTime', width: 'auto' },
  { columnName: 'test', width: 80 },
  { columnName: 'delete', width: 80 },
];

export const SlackIntegrationDetails = ({ integrations }) => (
  <div className={`sub-table ${styles.integrationsTable}`}>
    <Grid rows={integrations || []} columns={detailsRowColumns}>
      <Table />
      <TableColumnResizing defaultColumnWidths={detailsRowColumnsWidth} resizingMode="nextColumn" />
      <TableHeaderRow />
    </Grid>
  </div>
);
SlackIntegrationDetails.propTypes = {
  integrations: PropTypes.array,
};

SlackIntegrationDetails.defaultProps = {
  integrations: [],
};

const SlackIntegration = ({ integrations }) => (
  <div className={styles.content}>
    <div className={styles.type}>
      <SlackLogo className={styles.slackLogo} />
      <div className={styles.typeName}>
        Slack App <span className={styles.typeCount}>({integrations?.length || 0})</span>
      </div>
    </div>
    <AddSlackButton isDisabled={integrations?.length > 0} />
  </div>
);

export default SlackIntegration;

SlackIntegration.propTypes = {
  integrations: PropTypes.array,
};

SlackIntegration.defaultProps = {
  integrations: [],
};
