import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { SORT_BY_OPTIONS } from 'recommendationsNew/consts';

const HeatMapContext = createContext();

export const HeatMapFiltersProvider = ({ children }) => {
  const [heatMapGroupByOptions, setHeatMapGroupByOptions] = useState([]);
  const [heatMapSortByOption, setHeatMapSortByOption] = useState(SORT_BY_OPTIONS.items[0].property);

  return (
    <HeatMapContext.Provider
      value={{
        heatMapSortByOption,
        setHeatMapSortByOption,
        heatMapGroupByOptions,
        setHeatMapGroupByOptions,
      }}
    >
      {children}
    </HeatMapContext.Provider>
  );
};

HeatMapFiltersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeatMapContext;
