/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
// import Panel from 'shared/components/Panel';
import If from 'shared/components/SimpleIf';
import { roundNumber } from 'shared/utils/mathUtil';
import RecommendationLink from 'recommendations/shared/components/RecommendationLink';
import DismissRecButton from 'recommendations/shared/components/DismissRecButton';
import { ContentPageTypes, RecommFieldsAndFiltersValues } from 'recommendations/constants/recommendationsConstants';
import RecContentFactory from 'recommendations/helpers/factories/RecContentFactory';
import RecommStatusBadge from 'shared/components/RecommStatusBadge';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import Tooltip from '@mui/material/Tooltip';
import { CURRENCY_DETAILS } from 'shared/constants/appConstants';
import Panel from './Panel';

const quickRecPanel = ({ Content, RecLink }) => {
  const { getCurrencyNumber, currencyCode } = useUserSettingsContext();
  const QuickRecPanel = ({
    rec,
    recCompletedHandler,
    recExcludeHandler,
    recHelpers,
    currentUserReadOnly,
    cloudTypeId,
  }) => (
    <div className="daily-rec">
      <Panel
        // <PanelWithStatusBadge
        label={rec.status}
        title={rec.title}
        panelClass="dashboard__booking-reservations-panel"
        className="dashboard__booking-reservations-panel"
        addLeftMargin
        StatusBdgComp={RecommStatusBadge}
      >
        <div className="dashboard__booking-reservations daily-recs__potential-savings">
          <p>
            {currencyCode && currencyCode !== CURRENCY_DETAILS.USD ? (
              <Tooltip title={CURRENCY_DETAILS.SAVING_CALCULATE_TOOLTIP}>
                <span>Potential Savings</span>
              </Tooltip>
            ) : (
              'Potential Savings'
            )}
          </p>
          <p>
            <h1 className="daily-rec__header-amount">
              {`${getCurrencyNumber(
                rec.costPotentialSavings.toFixed(1)[rec.costPotentialSavings.toFixed(1).length - 1] === '0'
                  ? rec.costPotentialSavings.toFixed(0)
                  : rec.costPotentialSavings,
                0,
              )}`}
            </h1>
            <span>{`(${roundNumber(rec.potentialSavings)}% lower Annual Costs)`}</span>
            {/* <h1 className="daily-rec__header-amount">{`${costStr(rec.costPotentialSavings)} (${roundNumber(rec.potentialSavings)}%)`}</h1> */}
            {/* <h1 style={{ color: '#65B3FD' }}>{roundNumber(rec.potentialSavings)}%</h1> */}
          </p>
        </div>
        <div className="project-summary recommendations-card-body" style={{ 'white-space': 'pre' }}>
          <br />
          <Content rec={rec} contentPageType={ContentPageTypes.DAILY_RECS} />
          <hr />
          {/* <div> */}
          <ReadOnlyDisplayWrapper isHide={false} userReadOnly={currentUserReadOnly}>
            <ButtonToolbar>
              {/* <Button color="primary" size="sm">
                <RecLink rec={rec} recHandler={recCompletedHandler} recHelpers={recHelpers} title="Details" />
              </Button> */}
              <button className="daily-recs__details-btn" type="button">
                <RecLink rec={rec} recHandler={recCompletedHandler} recHelpers={recHelpers} title="Details" />
              </button>
              <If cond={rec.status === RecommFieldsAndFiltersValues.OPEN}>
                <DismissRecButton rec={rec} recExcludeHandler={recExcludeHandler} cloudTypeId={cloudTypeId} />
              </If>
            </ButtonToolbar>
          </ReadOnlyDisplayWrapper>
          {/* </div> */}
        </div>
        {/* </PanelWithStatusBadge> */}
      </Panel>
    </div>
  );
  QuickRecPanel.propTypes = {
    rec: PropTypes.object.isRequired,
    recCompletedHandler: PropTypes.func.isRequired,
    recExcludeHandler: PropTypes.func.isRequired,
    recHelpers: PropTypes.object.isRequired,
    currentUserReadOnly: PropTypes.bool.isRequired,
    cloudTypeId: PropTypes.number.isRequired,
  };

  return QuickRecPanel;
};

const QuickRecommendationPanelBuilder = ({
  rec,
  recCompletedHandler,
  recExcludeHandler,
  recHelpers,
  currentUserReadOnly,
  cloudTypeId,
}) => {
  const Content = RecContentFactory.create(rec);
  const RecLink = RecommendationLink;
  const QuickRecPanel = quickRecPanel({ Content, RecLink });

  return (
    <div style={{ marginRight: '15px' }} className="costum-xl-layout">
      {/* <TransitionGroup>
        <CSSTransition key={rec.key} timeout={800} classNames="slide"> */}
      <QuickRecPanel
        rec={rec}
        recCompletedHandler={recCompletedHandler}
        recExcludeHandler={recExcludeHandler}
        recHelpers={recHelpers}
        currentUserReadOnly={currentUserReadOnly}
        cloudTypeId={cloudTypeId}
      />
      {/* </CSSTransition>
      </TransitionGroup> */}
    </div>
  );
};

QuickRecommendationPanelBuilder.propTypes = {
  rec: PropTypes.object.isRequired,
  recCompletedHandler: PropTypes.func.isRequired,
  recExcludeHandler: PropTypes.func.isRequired,
  recHelpers: PropTypes.object.isRequired,
  currentUserReadOnly: PropTypes.bool.isRequired,
  cloudTypeId: PropTypes.number.isRequired,
};

export default QuickRecommendationPanelBuilder;
