import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { AccountT } from '../types.ts';

const root = '/api/v1/admin/accounts';
const queryKey = [apiConstants.QUERY_KEYS.ACCOUNTS];
const fetchAccountsList = (): Promise<AccountT[]> => API.get('billings', root);

const useFetchAccountsList = () => useQuery(queryKey, fetchAccountsList);

export default useFetchAccountsList;
