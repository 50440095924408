import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import ColoredTabs from 'shared/components/tabs/ColoredTabs';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import InvoicesPreferences from './components/InvoicesPreferences';
import BillingProfile from './components/BillingProfile';
import PreferencesTableDraggable from './components/PreferencesTableDraggable';

const RebillingPreferences = ({ usersStore, divisionsStore, filtersValuesMap }) => {
  const arrOfNavItems = [
    { id: 0, name: 'Billing Profile', disabled: usersStore.currDispUserCloudAccountType !== CLOUD_TYPE_IDS.AWS },
    { id: 1, name: 'Billing Rules Prioritization' },
    { id: 2, name: 'Invoice' },
  ].filter((a) => !a.disabled);
  const payerAccounts = filtersValuesMap.get(AwsCommonFields.PAYER_ACCOUNT);
  const payerOptions = !isEmptyArray(payerAccounts)
    ? payerAccounts.map((value) => ({ label: value, value: value.match(/\s\(([^()]+)\)/)[1] }))
    : [{ value: usersStore.getCurrDisplayedAccountId() }];
  return (
    <Container>
      <PageHeader title={PageNames.REBILLING_PREFERENCES} />
      <Card>
        <CardBody>
          <Row style={{ maxWidth: 1300 }}>
            <ColoredTabs arrOfNavItems={arrOfNavItems} defaultAccountIndex={arrOfNavItems[0].id} vertical>
              <BillingProfile
                divisionsStore={divisionsStore}
                usersStore={usersStore}
                payerAccountOptions={payerOptions}
                isAllAccounts={!isEmptyArray(payerAccounts)}
              />
              <PreferencesTableDraggable
                usersStore={usersStore}
                payerAccountOptions={payerOptions}
                isAllAccounts={!isEmptyArray(payerAccounts)}
              />
              <InvoicesPreferences
                usersStore={usersStore}
                payerAccountOptions={payerOptions}
                isAllAccounts={!isEmptyArray(payerAccounts)}
              />
            </ColoredTabs>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

RebillingPreferences.propTypes = {
  usersStore: PropTypes.object.isRequired,
  divisionsStore: PropTypes.object.isRequired,
  filtersValuesMap: PropTypes.object.isRequired,
};

const ObserverRebillingPreferences = withInvoiceFiltersContextConsumer(observer(RebillingPreferences));
export default ObserverRebillingPreferences;
