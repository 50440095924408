import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'shared/components/andtComponents/SimpleSelect';
import CustomModal from 'shared/components/andtComponents/Modal';
import RadioButton from 'shared/components/andtComponents/RadioButton';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import Input from 'shared/components/andtComponents/Input';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import moment from 'moment/moment';
import { PERIOD_TYPE } from 'recommendationsNew/consts';
import classes from './recommendationModal.module.scss';

const reasonOptions = [
  {
    value: 'Postponed due to workload',
    label: 'Postponed due to workload',
    primary: true,
    labelClassName: classes.radioReasonLabel,
  },
  {
    value: 'Postponed due to low business impact',
    label: 'Postponed due to low business impact',
    primary: true,
    labelClassName: classes.radioReasonLabel,
  },
  {
    value: 'The recommendation cannot be applied',
    label: 'The recommendation cannot be applied',
    primary: true,
    labelClassName: classes.radioReasonLabel,
  },
  {
    value: 'The recommendation is wrong',
    label: 'The recommendation is wrong',
    primary: true,
    labelClassName: classes.radioReasonLabel,
  },
  { value: 'other', label: 'Other', primary: true, labelClassName: classes.radioReasonLabel },
];

const RecommendationExcluded = ({ recs, closeModal }) => {
  const [excludeInfo, setExcludeInfo] = useState({ until: PERIOD_TYPE.nextTime, untilType: '6' });
  const [dateFormat, setDateFormat] = useState('');
  const [showReqError, setShowReqError] = useState(false);

  const saveExclude = () => {
    if (!excludeInfo?.reason) {
      setShowReqError(true);
    } else {
      closeModal({
        data: {
          comment: excludeInfo?.reason === 'other' ? excludeInfo.other : excludeInfo.reason,
          until:
            excludeInfo?.until === PERIOD_TYPE.until
              ? excludeInfo.date
              : moment().add(excludeInfo.untilType, 'months').format(),
        },
      });
    }
  };

  return (
    <CustomModal
      open
      onClose={closeModal}
      onSave={saveExclude}
      closeOnSave={false}
      className={classes}
      title="Exclusion Details"
    >
      <div className={classes.section}>
        <span>Resources</span>
        <div className={classes.readOnlyInfo}>
          {recs.map((rec) => (
            <>
              <span>{`${rec.resourceName || ''} (${rec.resourceId || ''})`}</span>
              <br />
            </>
          ))}
        </div>
      </div>
      <div className={classes.section}>
        <span className={classes.subHeader}>Exclusion Period</span>
        <div className={classes.periodWrapper}>
          <div>
            <div className={classes.firstRadio}>
              <RadioButton
                checked={excludeInfo?.until === PERIOD_TYPE.nextTime}
                className={classes.radioPeriodLabel}
                label="For the next"
                primary
                onClick={() => setExcludeInfo({ ...excludeInfo, until: PERIOD_TYPE.nextTime })}
              />
            </div>
            <div className={classes.secondRadio}>
              <RadioButton
                checked={excludeInfo?.until === PERIOD_TYPE.until}
                className={classes.radioPeriodLabel}
                label="Until date"
                primary
                onClick={() => setExcludeInfo({ ...excludeInfo, until: PERIOD_TYPE.until })}
              />
            </div>
          </div>
          <div className={classes.fieldsWrapper}>
            <div className={classes.margin}>
              <Select
                placeholder=""
                className={classes.selectFixed}
                value={excludeInfo?.untilType}
                disabled={excludeInfo?.until !== PERIOD_TYPE.nextTime}
                options={[
                  { label: '1 Month', value: '1' },
                  { label: '3 Months', value: '3' },
                  { label: '6 Months', value: '6' },
                  { label: '12 Months', value: '12' },
                ]}
                name="onboarding-currency-type"
                onChange={(value) => setExcludeInfo({ ...excludeInfo, untilType: value })}
              />
            </div>
            <div className={classes.margin}>
              <div className="andt-date-picker date-picker-anodot-customization">
                <DatePicker
                  selected={excludeInfo.date}
                  selectsStart
                  minDate={new Date()}
                  disabled={excludeInfo?.until !== PERIOD_TYPE.until}
                  onChange={(date) => {
                    setExcludeInfo({ ...excludeInfo, date });
                    setDateFormat(moment(date).format('DD/MM/YYYY'));
                  }}
                  customInput={
                    <div className={classes.dateInputWrapper}>
                      <input readOnly value={dateFormat} className={classes.input} />
                      <GenerateIcon iconName={ICONS.calendar.name} style={{ paddingRight: 8, paddingTop: 10 }} />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.section}>
        <span className={classes.subHeader}>Exclusion reason</span>
        <div className={classes.marginTB}>
          <span className={classes.text}>Please specify the reason to exclude this recommendation:</span>
        </div>
        <RadioButtonList
          options={reasonOptions}
          value={excludeInfo?.reason}
          onChange={(val) => {
            setExcludeInfo({ ...excludeInfo, reason: val, other: val === 'other' ? excludeInfo.other : '' });
          }}
        />
        <Input
          disabled={excludeInfo?.reason !== 'other'}
          className={classes.otherInput}
          value={excludeInfo.other}
          onChange={(event) => setExcludeInfo({ ...excludeInfo, other: event.target.value })}
        />
        {showReqError && !excludeInfo?.reason && <span className={classes.error}>Exclusion reason is required</span>}
      </div>
    </CustomModal>
  );
};

export default RecommendationExcluded;
