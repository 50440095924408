/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/system/colorManipulator';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  DataTypeProvider,
  SortingState,
  RowDetailState,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  ColumnChooser,
  TableColumnVisibility,
  TableFilterRow,
  TableColumnResizing,
  TableRowDetail,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Col } from 'reactstrap';
import { CSVLink } from 'react-csv';
import { strNumToSize } from 'shared/utils/strUtil';
import { S3IsVersioningValues } from 'usage/constants/usageConstants';
import CancelIcon from 'shared/img/icons/RoundCancelIcon';
import CheckIcon from 'shared/img/icons/RoundCheckIcon';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';

const styles = () => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    fontSize: '25.5vw',
  },
});

class DevExpSearchAndVisibilityTable extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    columnWidths: PropTypes.object.isRequired,
    tableColumnExtensions: PropTypes.object.isRequired,
    defaultHiddenColumnNames: PropTypes.object.isRequired,
    defaultSorting: PropTypes.object.isRequired,
    currenciesColumns: PropTypes.object.isRequired,
    csvColumns: PropTypes.object,
    csvTitle: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  static defaultProps = {
    csvColumns: null,
  };
  constructor(props) {
    super(props);
    const { columns, columnWidths, tableColumnExtensions, defaultHiddenColumnNames, data, currenciesColumns } =
      this.props;
    this.state = {
      columns,
      columnWidths,
      tableColumnExtensions,
      hiddenColumnNames: defaultHiddenColumnNames,
      rows: data,
      currenciesColumns,
      pageSizes: [10, 15, 20, 0],
    };
  }

  getIcon = ({ row, column }) => {
    if (column.name === 'versioning' && row.versioning === -1) {
      return <span>-</span>;
    }
    if ((row.versioning && column.name === 'versioning') || (row.lifecycle && column.name === 'lifecycle')) {
      return <CheckIcon style={{ margin: 'auto', height: '25px', width: '25px' }} />;
    }
    return <CancelIcon style={{ margin: 'auto', height: '25px', width: '25px' }} />;
  };

  tableColumns = [
    { name: 'type', title: 'Type', getCellValue: (row) => row.type },
    { name: 'size', title: 'Size', getCellValue: (row) => strNumToSize(row.value, 2, CLOUD_TYPE_IDS.AWS) },
  ];

  columnWidths = [
    { columnName: 'type', width: 450 },
    { columnName: 'size', width: 450 },
  ];

  RowDetail = ({ row }) => (
    <div className="card">
      <Grid rows={row.bucketSizeByStorage} columns={this.tableColumns}>
        <TableWrapper columnExtensions={this.columnWidths} />
        <TableHeaderRow />
      </Grid>
    </div>
  );

  currenciesFormatter = (value) => this.props.getCurrencyNumber(value.value, 0, { roundNumber: true });

  sizeFormatter = (value) => strNumToSize(value.value, 2, CLOUD_TYPE_IDS.AWS);

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  csvHeaders = () => {
    const { csvColumns } = this.props;
    const csvColumnsLocal = csvColumns.map((col) => ({
      label: col.title,
      key: col.name,
    }));
    csvColumnsLocal.push({ key: 'typeSizeDistribution', label: 'Type/Size distribution' });
    return csvColumnsLocal;
  };

  csvRows = (rows) => {
    const csvModifiedRows = JSON.parse(JSON.stringify(rows));
    csvModifiedRows.map((row) => {
      if (row.versioning === S3IsVersioningValues.NO) {
        row.versioning = 'No';
      } else {
        row.versioning = 'Yes';
      }
      row.size = strNumToSize(row.size, 2, CLOUD_TYPE_IDS.AWS);
      const typeSizeDistribution = row.bucketSizeByStorage.reduce((acc, sizeType) => {
        acc = acc
          ? acc.concat(` ${sizeType.type} (${strNumToSize(sizeType.value, 2, CLOUD_TYPE_IDS.AWS)})`)
          : ` ${sizeType.type} (${strNumToSize(sizeType.value, 2, CLOUD_TYPE_IDS.AWS)})`;
        return acc;
      }, '');
      row.typeSizeDistribution = typeSizeDistribution;
      delete row.bucketSizeByStorage;
      return row;
    });
    return csvModifiedRows;
  };

  render() {
    const { csvTitle, defaultSorting } = this.props;
    const { rows, columns, columnWidths, tableColumnExtensions, hiddenColumnNames, currenciesColumns, pageSizes } =
      this.state;
    const csvModifiedRows = this.csvRows(rows);

    return (
      <>
        <Col xs={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CSVLink data={csvModifiedRows} headers={this.csvHeaders()} filename={`${csvTitle}.csv`}>
            Export as CSV
            <span className="lnr lnr-download" style={{ fontSize: '18px', marginLeft: '8px', marginBottom: '8px' }} />
          </CSVLink>
        </Col>
        <Col xs={12} md={12} lg={12} xl={12}>
          <Paper>
            <Grid rows={rows} columns={columns}>
              <RowDetailState />
              <FilteringState defaultFilters={[]} />
              <IntegratedFiltering />
              <SortingState defaultSorting={defaultSorting} />
              <IntegratedSorting />
              <PagingState defaultCurrentPage={0} />
              <IntegratedPaging />
              <DataTypeProvider for={['versioning']} formatterComponent={this.getIcon} />
              <DataTypeProvider for={['lifecycle']} formatterComponent={this.getIcon} />
              <DataTypeProvider for={currenciesColumns} formatterComponent={this.currenciesFormatter} />
              <DataTypeProvider for={['size']} formatterComponent={this.sizeFormatter} />
              <TableWrapper columnExtensions={tableColumnExtensions} styles={styles} />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableRowDetail contentComponent={this.RowDetail} />
              <TableFilterRow />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
              />
              <PagingPanel pageSizes={pageSizes} />
              <Toolbar />
              <ColumnChooser />
            </Grid>
          </Paper>
        </Col>
      </>
    );
  }
}

const ObserverDevExpSearchAndVisibilityTable = withUserSettingsConsumer(DevExpSearchAndVisibilityTable);
export default ObserverDevExpSearchAndVisibilityTable;
