import React, { useContext, useState } from 'react';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ToolTipWrapper,
} from '@pileus-cloud/anodot-frontend-common/dist';
import RecommendationsNewContext from 'recommendationsNew/contexts/recommendationsNewContext';
import {
  CLOSED_AND_DONE_RECS_DATES_FILTERS,
  CLOSED_AND_DONE_RECS_DATES_OPERATOR,
  DATE_RANGE_OPTIONS,
  FILTERS,
  USER_STATUS_FILTERS,
} from 'recommendationsNew/consts';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import { palette } from 'shared/constants/colorsConstants';
import {
  CompletedStatus,
  DoneStatus,
  OpenStatus,
} from 'recommendationsNew/components/shared/recommendationStatusSymbol';
import { isEqual } from 'lodash';
import { DateRangeDropDown } from './dateRangeDropDown';

import classes from './dateRangeFilter.module.scss';

export function DateRangeFilter() {
  const [isOpen, setIsOpen] = useState(false);

  const {
    recommendationFilters: filtersContext,
    setRecommendationFilters: setFiltersContext,
    initialFiltersState,
  } = useContext(RecommendationsNewContext);

  const [localFiltersCopy, setLocalFiltersCopy] = useState({
    [FILTERS.OPEN_RECS_CREATION_DATE.id]: filtersContext?.[FILTERS.OPEN_RECS_CREATION_DATE.id],
    [FILTERS.CLOSED_AND_DONE_RECS_DATES.id]: filtersContext?.[FILTERS.CLOSED_AND_DONE_RECS_DATES.id],
  });

  const checkIfDateRangeFilterChanged = () =>
    !isEqual(
      localFiltersCopy[FILTERS.OPEN_RECS_CREATION_DATE.id],
      initialFiltersState[FILTERS.OPEN_RECS_CREATION_DATE.id],
    ) ||
    !isEqual(
      localFiltersCopy[FILTERS.CLOSED_AND_DONE_RECS_DATES.id],
      initialFiltersState[FILTERS.CLOSED_AND_DONE_RECS_DATES.id],
    );

  const dateRangeFilterTitle = () => {
    if (!checkIfDateRangeFilterChanged()) {
      return DATE_RANGE_OPTIONS.LAST_YEAR.label;
    }
    return 'Custom Range';
  };

  const onFilterValuesChange = (selection, filter, subFilter = null) => {
    if (filter === FILTERS.OPEN_RECS_CREATION_DATE.id) {
      setLocalFiltersCopy({
        ...localFiltersCopy,
        [FILTERS.OPEN_RECS_CREATION_DATE.id]: selection,
      });
    } else if (filter === FILTERS.CLOSED_AND_DONE_RECS_DATES.id) {
      const tmp = { ...localFiltersCopy[FILTERS.CLOSED_AND_DONE_RECS_DATES.id], [subFilter]: selection };
      setLocalFiltersCopy({
        ...localFiltersCopy,
        [FILTERS.CLOSED_AND_DONE_RECS_DATES.id]: tmp,
      });
    }
  };

  const onOpenDateRangeFilterChange = () => {
    const newIsOpenState = !isOpen;
    setIsOpen(newIsOpenState);

    if (newIsOpenState === false) {
      setFiltersContext({
        ...filtersContext,
        [FILTERS.OPEN_RECS_CREATION_DATE.id]: localFiltersCopy[FILTERS.OPEN_RECS_CREATION_DATE.id],
        [FILTERS.CLOSED_AND_DONE_RECS_DATES.id]: localFiltersCopy[FILTERS.CLOSED_AND_DONE_RECS_DATES.id],
      });
    }
  };

  const operatorButtonStyle = {
    background: palette.blue[100],
    fontSize: 16,
    fontWeight: 400,
    height: '24px',
    padding: '0 4px',
  };

  const openRecsDateRange = (
    <div className={classes.dateRangeContainer}>
      <div className={classes.recStatusContainer}>
        <div className={classes.recStatus}>
          <OpenStatus />
        </div>
      </div>
      <div className={classes.dateFilter}>
        <div className={classes.title}>Creation Date</div>
        <DateRangeDropDown
          onPeriodChange={(selectedOption) => onFilterValuesChange(selectedOption, FILTERS.OPEN_RECS_CREATION_DATE.id)}
          selected={filtersContext[FILTERS.OPEN_RECS_CREATION_DATE.id]}
        />
      </div>
    </div>
  );

  const completedAndDoneRecsDateRange = (
    <div className={classes.dateRangeContainer}>
      <div className={classes.recStatusContainer}>
        {filtersContext[FILTERS.USER_STATUS.id]?.[USER_STATUS_FILTERS.ITEMS.DONE.id] === true ||
        filtersContext[FILTERS.USER_STATUS.id]?.[USER_STATUS_FILTERS.ITEMS.DONE.id] == null ? (
          <div className={classes.recStatus}>
            <DoneStatus />
          </div>
        ) : null}
        {!filtersContext[FILTERS.IS_OPEN.id] ? (
          <div className={classes.recStatus}>
            <CompletedStatus />
          </div>
        ) : null}
      </div>
      <div className={classes.dateFilter}>
        <div className={classes.title}>Completed Date</div>
        <DateRangeDropDown
          onPeriodChange={(selectedOption) =>
            onFilterValuesChange(
              selectedOption,
              FILTERS.CLOSED_AND_DONE_RECS_DATES.id,
              CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE,
            )
          }
          selected={
            filtersContext[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE]
          }
        />
      </div>
      <div className={classes.optionalDateFilter}>
        <div className={classes.operatorDropdown}>
          <ButtonDropdown
            text={
              localFiltersCopy[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR] ||
              CLOSED_AND_DONE_RECS_DATES_OPERATOR.AND
            }
            overrideMenuStyles={{}}
            iconPlacement="right"
            icon={() => <GenerateIcon iconName={ICONS.caretDown.name} className={classes.caretDown} />}
            overrideButtonStyles={operatorButtonStyle}
          >
            <div
              className={classes.operatorItem}
              onClick={() =>
                onFilterValuesChange(
                  CLOSED_AND_DONE_RECS_DATES_OPERATOR.AND,
                  FILTERS.CLOSED_AND_DONE_RECS_DATES.id,
                  CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR,
                )
              }
            >
              <span>{CLOSED_AND_DONE_RECS_DATES_OPERATOR.AND}</span>
            </div>
            <div
              className={classes.operatorItem}
              onClick={() =>
                onFilterValuesChange(
                  CLOSED_AND_DONE_RECS_DATES_OPERATOR.OR,
                  FILTERS.CLOSED_AND_DONE_RECS_DATES.id,
                  CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR,
                )
              }
            >
              <span>{CLOSED_AND_DONE_RECS_DATES_OPERATOR.OR}</span>
            </div>
          </ButtonDropdown>
        </div>
        <div className={`${classes.dateRangeContainer} ${classes.noFrame}`}>
          <div className={classes.dateFilter}>
            <div className={classes.title}>Creation Date</div>
            <DateRangeDropDown
              onPeriodChange={(selectedOption) =>
                onFilterValuesChange(
                  selectedOption,
                  FILTERS.CLOSED_AND_DONE_RECS_DATES.id,
                  CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE,
                )
              }
              selected={
                filtersContext[FILTERS.CLOSED_AND_DONE_RECS_DATES.id][CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE]
              }
            />
          </div>
        </div>
      </div>
    </div>
  );

  const dateRangeComponent = () => {
    if (
      filtersContext[FILTERS.IS_OPEN.id] === true &&
      filtersContext[FILTERS.USER_STATUS.id]?.[USER_STATUS_FILTERS.ITEMS.DONE.id] === false
    ) {
      return openRecsDateRange;
    }
    if (
      filtersContext[FILTERS.IS_OPEN.id] === false &&
      filtersContext[FILTERS.USER_STATUS.id]?.[USER_STATUS_FILTERS.ITEMS.DONE.id] === true
    ) {
      return completedAndDoneRecsDateRange;
    }
    return (
      <>
        {openRecsDateRange}
        {completedAndDoneRecsDateRange}
      </>
    );
  };

  return (
    <div className={classes.dateRangeFilterContainer} automation-id="date-range-filter">
      <Popover open={isOpen} onOpenChange={onOpenDateRangeFilterChange}>
        <PopoverTrigger className={classes.popoverTrigger}>
          <ToolTipWrapper content="DateRange">
            <div className={classes.dateRangeTitleContainer}>
              <span>{dateRangeFilterTitle()}</span>
              <GenerateIcon iconName={ICONS.caretDown.name} className={classes.arrowDown} />
            </div>
          </ToolTipWrapper>
        </PopoverTrigger>

        <PopoverContent align="start" sideOffset={7} className={classes.popoverContent}>
          <div className={classes.filtersContainer}>{dateRangeComponent()}</div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
