import React from 'react';
import PropTypes from 'prop-types';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import { CUSTOM_TAGS_NOT_TAGGED } from 'shared/constants/awsConstants';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { OPERATORS_KEYS } from 'shared/constants/appConstants';
import { ALL_TAGS_FIELD } from '../../constants/businessMappingConstants';
import styles from './FilterSelector.module.scss';

const FieldSelect = ({
  value: { field, prefix, ruleId, index },
  filterProps,
  handleChange,
  filtersList,
  currDispUserCloudAccountType,
}) => {
  const { subMenu, keys, selectAll, showNotAllocated } = filterProps[field] || {};
  const formattedOptions = (keys || []).sort((a, b) => a.localeCompare(b)).map((a) => ({ label: a, value: a }));
  return (
    <div className="d-flex w-100">
      <SimpleSelect
        value={field}
        isClearable={false}
        className={styles.fieldSelect}
        label="Condition"
        onChange={(value) => {
          handleChange({
            values: [],
            operator: OPERATORS_KEYS.IS,
            field: value,
            ruleId,
            index,
          });
        }}
        options={[...filtersList.keys()]
          .map((value) => ({
            label: LabelCoordinator.getFieldLabelByCloudType(value, currDispUserCloudAccountType),
            value,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))}
      />
      {subMenu && (
        <SimpleSelect
          label="Key"
          isClearable={false}
          className={styles.tagKeySelect}
          value={prefix || null}
          onChange={(value) => {
            const newPrefix = value || null;
            handleChange({
              values: [],
              operator: newPrefix === ALL_TAGS_FIELD ? OPERATORS_KEYS.LIKE : OPERATORS_KEYS.IS,
              field,
              prefix: newPrefix,
              ruleId,
              index,
            });
          }}
          renderLabel={(label) =>
            label === 'Not tagged' ? (
              <>
                Not tagged <InfoPopover isSimple>Group all not tagged cost cross all tag keys</InfoPopover>
              </>
            ) : (
              label
            )
          }
          options={[
            ...(selectAll ? [{ label: ALL_TAGS_FIELD, value: ALL_TAGS_FIELD }] : []),
            ...(showNotAllocated ? [{ label: 'Not tagged', value: CUSTOM_TAGS_NOT_TAGGED }] : []),
            ...formattedOptions,
          ]}
        />
      )}
    </div>
  );
};

FieldSelect.propTypes = {
  currDispUserCloudAccountType: PropTypes.number.isRequired,
  value: PropTypes.shape({
    values: PropTypes.array.isRequired,
    field: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    ruleId: PropTypes.number,
    index: PropTypes.number,
  }).isRequired,
  filtersList: PropTypes.object.isRequired,
  filterProps: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default FieldSelect;
