import React from 'react';

import classes from './genericChart.module.scss';

export const CustomizedLegend = ({ payload, turnedOffLines, onClickLegendPair }) => (
  <div className={classes.customizedLegend}>
    {payload.map((entry) => {
      const entryId = `${entry.payload.id}Legend`;
      return (
        <div className={classes.legendPair} onClick={onClickLegendPair} id={entryId}>
          <div
            className={classes.legendSymbol}
            style={
              turnedOffLines?.includes(entryId)
                ? { border: `1px solid ${entry.color}` }
                : { backgroundColor: entry.color }
            }
          />
          <div className={classes.legendLabel}>{entry.value}</div>
        </div>
      );
    })}
  </div>
);
