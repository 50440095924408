import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Spinner from 'shared/components/andtComponents/Spinner';
import { Routes } from 'shared/constants/routes';
import { isPathNotAllowedToUser } from 'users/utils/userUtil';
import { observer } from 'mobx-react';

const AuthenticatedRoute = ({
  component: C,
  props: cProps,
  observeResources = [],
  blockResources = [],
  remountKey,
  ...rest
}) => {
  useEffect(() => {}, [cProps.usersStore.currUserInitDone, ...observeResources.map((resFunc) => !!resFunc(cProps))]);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!cProps || !cProps.isSessionStorageAuth()) {
          return (
            <Redirect
              to={`${Routes.LOG_IN}?redirect=${props.location.pathname}${encodeURIComponent(props.location.search)}`}
            />
          );
        }
        if (
          !cProps.usersStore.currUserInitDone ||
          cProps.newTabLoading ||
          blockResources.some((resFunc) => !!resFunc(cProps))
        ) {
          return <Spinner />;
        }
        if (isPathNotAllowedToUser(props.match.path, cProps.usersStore.currentDisplayedUserType)) {
          return <Redirect to="/page-not-found" />;
        }
        return (
          <C
            key={remountKey ? remountKey(cProps) : props.location.key}
            {...props}
            {...cProps}
            isPpApplied={cProps.appStore.isPpApplied}
          />
        );
      }}
    />
  );
};

export default observer(AuthenticatedRoute);
