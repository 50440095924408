import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';

const S3_IDLE_PROPERTIES = {
  CUSTOMER: {
    label: 'Customer',
    getterFunction: (recommendation) => recommendation?.recData?.customer,
  },
  BUCKET_NAME: {
    label: 'Bucket Name',
    getterFunction: (recommendation) => recommendation?.recData?.bucket_name,
  },
  IDLE_STARTING_TIME: {
    label: 'Idle Starting time',
    getterFunction: (recommendation) => recommendation?.recData?.starting_time,
  },
};

const S3Idle = ({ recommendation }) => {
  const description = 'This S3 Bucket was inactive in the last 60 days. We recommended that you terminate it.';
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      dynamicProps={{ ...S3_IDLE_PROPERTIES }}
    />
  );
};

S3Idle.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default S3Idle;
