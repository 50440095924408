/* eslint-disable jsx-a11y/anchor-is-valid, quotes */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { UsersType } from 'users/constants/usersConstants';
import { Routes } from 'shared/constants/routes';

export default class CarouselPageNavigator extends Component {
  static propTypes = {
    previous: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    activeIndex: PropTypes.number.isRequired,
    lastPage: PropTypes.number.isRequired,
    lastPageDisp: PropTypes.number.isRequired,
    isTestButton: PropTypes.bool,
    hideBackBtn: PropTypes.bool,
    userType: PropTypes.number.isRequired,
  };
  static defaultProps = {
    isTestButton: false,
    hideBackBtn: false,
  };

  getNextButtonText = () => {
    const { activeIndex, isTestButton } = this.props;
    if (activeIndex === 0) {
      return "Let's Start";
    }
    if (isTestButton) {
      return 'Test';
    }
    return 'Next';
  };

  render() {
    const { previous, next, hideBackBtn, activeIndex, lastPage, lastPageDisp, userType } = this.props;
    const saveButtonClassNames = classNames({
      'next-button': true,
      hidden: activeIndex === lastPageDisp,
      visible: activeIndex !== lastPageDisp,
    });
    return (
      <>
        <Row className="bottom-nav-bar-navigation-container">
          <Col xs={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }} xl={{ size: 12 }}>
            <hr />
          </Col>
          <Col xs="auto" md="auto" lg="auto" xl="auto">
            <Row className="nav-buttons-inner-wrapper">
              <Col xs="auto" md="auto" lg="auto" xl="auto">
                {activeIndex === 0 || activeIndex === lastPage || hideBackBtn ? null : (
                  <a
                    className="back-button visible"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                  >
                    Back
                  </a>
                )}
                {activeIndex === 0 ? (
                  <Link to={userType === UsersType.NEW_USER ? Routes.ACCOUNTS_OVERVIEW : Routes.DASHBOARD}>
                    <button className="next-button visible" type="button">
                      Cancel
                    </button>
                  </Link>
                ) : null}
              </Col>
              {activeIndex !== lastPage && (
                <Col xs="auto" md="auto" lg="auto" xl="auto">
                  <button className={saveButtonClassNames} onClick={next} type="button">
                    {this.getNextButtonText()}
                  </button>
                </Col>
              )}
              {activeIndex === lastPage && (
                <Col xs="auto" md="auto" lg="auto" xl="auto">
                  <button className={saveButtonClassNames} onClick={next} type="button">
                    Save
                  </button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}
