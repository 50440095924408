/* eslint-disable class-methods-use-this, arrow-parens */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import Spinner from 'shared/components/andtComponents/Spinner';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import Panel from 'shared/components/Panel';
import {
  ALERTS_COLUMN_WIDTHS,
  ALERTS_DEFAULT_SORTING,
  ALERTS_TABLE_COLUMN_EXTENSIONS,
  ALERTS_TABLE_COLUMNS,
} from 'users/constants/usersConstants';
import AlertsTable from './components/AlertsTable';

class Notifications extends Component {
  static propTypes = {
    usersStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      dataIsLoading: false,
    };
  }

  async componentDidMount() {
    const alerts = this.props.usersStore.usersModel.userAlerts;
    if (alerts && alerts.length === 0) {
      this.setState({ dataIsLoading: true });
      try {
        await this.props.usersStore.getUserAlerts();
        this.setState({ dataIsLoading: false });
      } catch (error) {
        this.setState({ dataIsLoading: false });
      }
    }
  }

  handleDismissAlert = (type, alert) => {
    alert.setShowAlert(false);
    alert.setHideDueDate('0');
    if (type === 'week') {
      const dueDate = moment(moment(), 'YYYY MM DD').add(7, 'days');
      alert.setHideDueDate(dueDate);
      this.props.usersStore.updateAlertDismiss(dueDate.format('YYYY-MM-DD').toString(), alert.id);
      return;
    }
    this.props.usersStore.updateAlertDismiss('', alert.id);
  };

  render() {
    if (this.state.dataIsLoading || this.props.usersStore.isLoading) {
      return <Spinner />;
    }
    const currentDate = moment();
    const preapredData = this.props.usersStore.usersModel.userAlerts.filter((alert) => {
      if (alert.hideDueDate && alert.hideDueDate !== '0') {
        const dd = moment(alert.hideDueDate);
        return !alert.showAlert && currentDate > dd;
      }
      return alert.showAlert;
    });

    return (
      <Container>
        <PageHeader title={PageNames.NOTIFICATIONS} showDate />
        <Panel divider title="Alerts ">
          <AlertsTable
            rows={preapredData}
            columns={ALERTS_TABLE_COLUMNS}
            columnWidths={ALERTS_COLUMN_WIDTHS}
            tableColumnExtensions={ALERTS_TABLE_COLUMN_EXTENSIONS}
            // defaultHiddenColumnNames={ALERTS_DEFAULT_HIDDEN_COLUMN_NAMES}
            defaultSorting={ALERTS_DEFAULT_SORTING}
            handler={this.handleDismissAlert}
            // currenciesColumns={ALERTS_CURRENCIES_COLUMNS}
          />
        </Panel>
      </Container>
    );
  }
}

const ObserverAlerts = observer(Notifications);
export default ObserverAlerts;
