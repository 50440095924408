const dev = {
  env: 'dev',
  connect: {
    TOPIC: 'dev-new-invoice-upload',
    PILEUS_BUCKET: 'dev-invoice-update',
  },
};

const prod = {
  env: 'prod',
  connect: {
    TOPIC: 'prod-new-invoice-upload',
    PILEUS_BUCKET: 'prod-invoice-update',
  },
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default {
  // common config values here
  ...config,
};
