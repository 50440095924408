import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  RowDetailState,
  DataTypeProvider,
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import { alpha } from '@mui/system/colorManipulator';
import {
  Grid,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
  Toolbar,
  SearchPanel,
  ColumnChooser,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@mui/styles';
import { strNumToSize } from 'shared/utils/strUtil';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { mapRecommendationTypeToColumnsWidthArray } from '../../../constants/recommsTableConstants';
import ExcludeRuleBuilder from './ExcludeRuleBuilder';
import ExcludeRuleActionButton from './ExcludeRuleActionButton';

const styles = () => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    fontSize: '25.5vw',
  },
});
const propTypes = {
  excludeRules: PropTypes.object.isRequired,
  tableColumns: PropTypes.object.isRequired,
  recExcludeRemoveHandler: PropTypes.func.isRequired,
  currentUserReadOnly: PropTypes.bool.isRequired,
  cloudTypeId: PropTypes.number.isRequired,
};

const ExcludeRulesTable = ({
  excludeRules,
  tableColumns,
  recExcludeRemoveHandler,
  currentUserReadOnly,
  cloudTypeId,
}) => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(['key']);
  const pageSizes = [10, 20, 50, 100, 0];

  const getTableColumnsWidths = (recomms) => {
    const columnsWidths = recomms.length ? mapRecommendationTypeToColumnsWidthArray.get(recomms[0].type) : null;
    return columnsWidths || [];
  };

  const closeExpanded = () => {
    setExpandedRowIds([]);
  };

  const bytesSizeFormatter = (value) => strNumToSize(value.value);
  const RowDetail = ({ row }) => (
    <div>
      <ExcludeRuleBuilder
        currentUserReadOnly={currentUserReadOnly}
        excludeRule={row}
        recExcludeRemoveHandler={recExcludeRemoveHandler}
        afterActionHandler={closeExpanded}
        cloudTypeId={cloudTypeId}
      />
    </div>
  );
  RowDetail.propTypes = {
    row: PropTypes.object.isRequired,
  };

  const userActionsFormatter = (data) => {
    withStyles(data);
    return (
      <ReadOnlyDisplayWrapper isHide={false} userReadOnly={currentUserReadOnly}>
        <div style={{ width: '100%' }}>
          <ExcludeRuleActionButton
            excludeRule={data.row}
            recExcludeRemoveHandler={recExcludeRemoveHandler}
            cloudTypeId={cloudTypeId}
          />
        </div>
      </ReadOnlyDisplayWrapper>
    );
  };
  return (
    <div className="card">
      <Grid rows={excludeRules} columns={tableColumns}>
        <SearchState />
        <SortingState
          defaultSorting={[
            { columnName: 'updateTime', direction: 'desc' },
            { columnName: 'costPotentialSavings', direction: 'desc' },
          ]}
        />
        <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[2]} />
        <IntegratedFiltering />
        <IntegratedSorting />
        <IntegratedPaging />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
        <DataTypeProvider for={['userAction']} formatterComponent={userActionsFormatter} />
        <DataTypeProvider for={['totalSize']} formatterComponent={bytesSizeFormatter} />
        <DataTypeProvider for={['latestVersioningSize']} formatterComponent={bytesSizeFormatter} />
        <DataTypeProvider for={['versioningSize']} formatterComponent={bytesSizeFormatter} />
        <TableWrapper columnExtensions={getTableColumnsWidths(excludeRules)} styles={styles} />
        <TableHeaderRow showSortingControls />
        <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} onHiddenColumnNamesChange={setHiddenColumnNames} />
        <Toolbar />
        <ColumnChooser />
        <SearchPanel />
        <PagingPanel pageSizes={pageSizes} />
        <TableRowDetail contentComponent={RowDetail} />
      </Grid>
    </div>
  );
};
ExcludeRulesTable.propTypes = propTypes;
const ObserverExcludeRulesTable = observer(ExcludeRulesTable);
export default ObserverExcludeRulesTable;
