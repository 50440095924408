/* eslint-disable max-len */
/* eslint-disable new-parens */
import { AwsCommonFields, AwsQuantityTypes } from 'shared/constants/awsConstants';
import { K8sCommonFields } from 'shared/constants/k8sConstants';
import { AzureCommonFields } from 'shared/constants/azureConstants';
import { AppCommonFields } from 'shared/constants/appConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { GcpCommonFields } from '../../shared/constants/gcpConstants';

export class CostTrackingConstants {
  static DATA_LEVEL_TYPE_SERVICE = 'service';
  static DATA_LEVEL_FAMILY_TYPE = 'familyType';
  static DATA_LEVEL_TYPE_TYPE = 'type';
  static DATA_LEVEL_TYPE_OPERATION = 'operation';
  static DATA_LEVEL_TYPE_RESOURCE = 'resource';
  static DATA_LEVEL_TYPE_GROUP_BY = 'groupBy';
  static GRAN_LEVEL_YEARLY = 'year';
  static GRAN_LEVEL_QUARTERLY = 'quarter';
  static GRAN_LEVEL_MONTHLY = 'month';
  static GRAN_LEVEL_DAILY = 'day';
  static GRAN_LEVEL_HOURLY = 'hour';
  static GRAN_LEVEL_WEEKLY = 'week';
  static OTHERS = 'Others';
  static OTHERS_NAME_COLOR = '#AFAFAF';
  static NO_DIVISION = 'No Cost Center';
}

export const mapGranLevelToDisplayValue = new Map([
  [CostTrackingConstants.GRAN_LEVEL_YEARLY, 'Yearly'],
  [CostTrackingConstants.GRAN_LEVEL_QUARTERLY, 'Quarterly'],
  [CostTrackingConstants.GRAN_LEVEL_MONTHLY, 'Monthly'],
  [CostTrackingConstants.GRAN_LEVEL_DAILY, 'Daily'],
  [CostTrackingConstants.GRAN_LEVEL_WEEKLY, 'Weekly'],
  [CostTrackingConstants.GRAN_LEVEL_HOURLY, 'Hourly'],
]);

export class GRANULARITY_LEVEL_TYPES {
  static GRAN_LEVEL_MONTHLY = 0;
  static GRAN_LEVEL_DAILY = 1;
  static GRAN_LEVEL_WEEKLY = 2;
}

export const GRAN_LEVEL_TYPE_TO_START_DATE_DAYS_DELTA = {
  [CostTrackingConstants.GRAN_LEVEL_MONTHLY]: 120,
  [`${CostTrackingConstants.GRAN_LEVEL_MONTHLY}View`]: 60,
  [`${CostTrackingConstants.GRAN_LEVEL_MONTHLY}CustomTag`]: 60,
  [CostTrackingConstants.GRAN_LEVEL_WEEKLY]: 56,
  [CostTrackingConstants.GRAN_LEVEL_DAILY]: 30,
};

export const SEVEN_DAYS_MILLIS = 86400000 * 6;

export class CostUsageStates {
  static SERVICE = 0;
  static TYPE = 1;
  static OPERATION = 2;
  static RESOURCE = 3;
  static REGION = 4;
  static LINKED_ACC_ID = 5;
  static PROJECT = 6;
  static ENVIRONMENT = 7;
  static FAMILY_TYPE = 8;
  static CLUSTER = 9;
  static NODE = 10;
  static POD = 11;
  static NAMESPACE = 12;
  static SECOND_GROUP_BY = 13;
  static RESOURCE_GROUP = 14;
  static SUBSCRIPTION_ID = 15;
  static DIVISION = 16;
  static CUSTOM_TAGS = 17;
  static CHARGE_TYPE = 18;
  static PURCHASE_OPTION = 19;
  static POD_DETAILS = 20;
  static INSTANCE_TYPE = 21;
  static VIRTUAL_CUSTOM_TAGS = 22;
  static VIEWS_CUSTOM_TAGS = 23;
  static PAYER_ACCOUNT = 24;
  static NODE_GROUP = 25;
  static NONE = 26;
  static COST_TYPE_DESCRIPTION = 27;
  static CATEGORY = 28;
  static LABELS = 29;
  static USAGE_TYPE = 30;
  static AVAILABILITY_ZONE = 31;
  static CLOUD_PROVIDER = 32;
  static SUB_METER_CATEGORY = 33;
  static DEPLOYMENT = 34;
  static BUSINESS_MAPPING = 35;
  static COST_TYPE = 36;
  static LINE_TYPE = 37;
  static ACCOUNT_TAGS = 38;
}

export const numOfCUStates = Math.max(...Object.values(CostUsageStates));

export class DisplayCostUsageStates {
  static SERVICE = 'Services';
  static TYPE = 'Types';
  static OPERATION = 'Operations';
  static RESOURCE = 'Resources';
  static REGION = 'Regions';
  static LINKED_ACC_ID = 'Linked Accounts';
  static PROJECT = 'Projects';
  static ENVIRONMENT = 'Environment';
  static FAMILY_TYPE = 'Family Type';
  static CLUSTER = 'Cluster';
  static POD = 'Pod';
  static NODE = 'Node';
  static NODE_GROUP = 'Node Group';
  static RESOURCE_GROUP = 'Resource Group';
  static AVAILABILITY_ZONE = 'Availability Zone';
  static SUBSCRIPTION_ID = 'Subscription name';
  static METER_NAME = 'Meter Name';
  static METER_CATEGORY = 'Meter Category';
  static SUB_METER_CATEGORY = 'Sub Meter Category';
  static LINE_TYPE = 'Line Type';
  static COST_TYPE = 'Cost Type';
  static DIVISION = 'Cost Center';
  static CUSTOM_TAGS = 'Custom Tags';
  static ACCOUNT_TAGS = 'Enrichment Tags';
  static VIRTUAL_CUSTOM_TAGS = 'Virtual Custom Tags';
  static VIEWS_CUSTOM_TAGS = 'Views Tags';
  static CHARGE_TYPE = 'Charge Type';
  static PURCHASE_OPTION = 'Purchase Option';
  static NAMESPACE = 'Namespace';
  static INSTANCE_TYPE = 'Instance Type';
  static PAYER_ACCOUNT = 'Payer Account';
  static CLOUD_PROVIDER = 'Cloud Provider';
  static NONE = '- None -';
  static COST_TYPE_DESCRIPTION = 'Cost Type Description';
  static CATEGORY = 'Category';
  static BUSINESS_MAPPING = 'Business Mapping';
  static USAGE_TYPE = 'Usage Type';
  static LABELS = 'Labels';
  static LABEL_TAGS = 'Labels';
}

export const USER_SAVED_REPORT_TYPES = {
  CUE: 'cueReport',
  CUSTOM_DASHBOARD: 'customDashboardReport',
  ASSET: 'assetReport',
};
export const mapReportTypeToDisplay = new Map([
  [USER_SAVED_REPORT_TYPES.CUE, 'Cost & Usage'],
  [USER_SAVED_REPORT_TYPES.CUSTOM_DASHBOARD, 'Dashboard'],
  [USER_SAVED_REPORT_TYPES.ASSET, 'Asset'],
]);

export class ReportSendFrequency {
  static WEEKLY = 0;
  static MONTHLY = 1;
  static QUARTERLY = 2;
  static NONE = 3;
  static CUSTOM = 4;
}

export class DaysOfWeek {
  static SUNDAY = 6;
  static MONDAY = 0;
  static TUESDAY = 1;
  static WEDNESDAY = 2;
  static THURSDAY = 3;
  static FRIDAY = 4;
  static SATURDAY = 5;
}

export class PeriodTimeMonth {
  static FIRST_OF = 8;
  static LAST_OF = 9;
}

export class ReportPeriodTime {
  static SEVEN_DAYS = 7;
  static FOURTEEN_DAYS = 14;
  static THIRTY_DAYS = 30;
  static SIXTY_DAYS = 60;
  static NINETY_DAYS = 90;
  static CURRENT_MONTH = 'currentMonth';
  static PREVIOUS_MONTH = 'previousMonth';
  static PREVIOUS_2_MONTH = 'previous2Month';
  static PREVIOUS_3_MONTH = 'previous3Month';
  static PREVIOUS_6_MONTH = 'previous6Month';
  static YTD = 'yearToDate';
  static PREVIOUS_DAY = 'previousDay';
  static PREVIOUS_WEEK = 'previousWeek';
  static PREVIOUS_2_WEEK = 'previous2Week';
  static PREVIOUS_3_WEEK = 'previous3Week';
  static PREVIOUS_4_WEEK = 'previous4Week';
  static PREVIOUS_5_WEEK = 'previous5Week';
  static PREVIOUS_6_WEEK = 'previous6Week';
  static PREVIOUS_7_WEEK = 'previous7Week';
  static PREVIOUS_8_WEEK = 'previous8Week';
}

export class ItemsOptionsValues {
  static ALL = 'all';
}

export const mapFrequencyToDeliveryTime = new Map([
  [ReportSendFrequency.WEEKLY, DaysOfWeek],
  [ReportSendFrequency.MONTHLY, PeriodTimeMonth],
  [ReportSendFrequency.QUARTERLY, {}],
  [ReportSendFrequency.CUSTOM, {}],
  [ReportSendFrequency.NONE, {}],
]);
export const mapWeeklyFrequencyToDisplayName = new Map([
  [DaysOfWeek.SUNDAY, 'Sunday'],
  [DaysOfWeek.MONDAY, 'Monday'],
  [DaysOfWeek.TUESDAY, 'Tuesday'],
  [DaysOfWeek.WEDNESDAY, 'Wednesday'],
  [DaysOfWeek.THURSDAY, 'Thursday'],
  [DaysOfWeek.FRIDAY, 'Friday'],
  [DaysOfWeek.SATURDAY, 'Saturday'],
]);
export const mapMonthlyFrequencyToDisplayName = new Map([
  [PeriodTimeMonth.FIRST_OF, 'First day of month'],
  [PeriodTimeMonth.LAST_OF, 'Last day of month'],
]);

export const mapFrequencyToPeriodTimeDisplayNameMap = new Map([
  [ReportSendFrequency.WEEKLY, mapWeeklyFrequencyToDisplayName],
  [ReportSendFrequency.MONTHLY, mapMonthlyFrequencyToDisplayName],
  [ReportSendFrequency.QUARTERLY, new Map()],
  [ReportSendFrequency.CUSTOM, new Map()],
  [ReportSendFrequency.NONE, new Map()],
]);
export const mapReportFrequencyToDisplayName = new Map([
  [ReportSendFrequency.WEEKLY, 'Weekly'],
  [ReportSendFrequency.MONTHLY, 'Monthly'],
  [ReportSendFrequency.QUARTERLY, 'Quarterly'],
  [ReportSendFrequency.NONE, '-'],
  [ReportSendFrequency.CUSTOM, 'Custom'],
]);

export const mapDeliveryFrequencyToDefaultDeliveryTime = new Map([
  [ReportSendFrequency.WEEKLY, DaysOfWeek.SUNDAY],
  [ReportSendFrequency.MONTHLY, 1],
  [ReportSendFrequency.QUARTERLY, null],
  [ReportSendFrequency.NONE, null],
  [ReportSendFrequency.CUSTOM, null],
]);
export const mapReportDatePeriodToDisplayName = new Map([
  [ReportPeriodTime.NINETY_DAYS, 'last 90 Days'],
  [ReportPeriodTime.SIXTY_DAYS, 'last 60 Days'],
  [ReportPeriodTime.THIRTY_DAYS, 'last 30 Days'],
  [ReportPeriodTime.FOURTEEN_DAYS, 'last 14 Days'],
  [ReportPeriodTime.SEVEN_DAYS, 'last 7 Days'],
  [ReportPeriodTime.CURRENT_MONTH, 'Current month'],
  [ReportPeriodTime.PREVIOUS_MONTH, 'Previous month'],
  [ReportPeriodTime.PREVIOUS_2_MONTH, 'Previous 2 months'],
  [ReportPeriodTime.PREVIOUS_3_MONTH, 'Previous 3 months'],
  [ReportPeriodTime.PREVIOUS_6_MONTH, 'Previous 6 months'],
  [ReportPeriodTime.PREVIOUS_DAY, 'Previous day'],
  [ReportPeriodTime.PREVIOUS_WEEK, 'Previous week'],
  [ReportPeriodTime.PREVIOUS_2_WEEK, 'Previous 2 weeks'],
  [ReportPeriodTime.PREVIOUS_3_WEEK, 'Previous 3 weeks'],
  [ReportPeriodTime.PREVIOUS_4_WEEK, 'Previous 4 weeks'],
  [ReportPeriodTime.PREVIOUS_5_WEEK, 'Previous 5 weeks'],
  [ReportPeriodTime.PREVIOUS_6_WEEK, 'Previous 6 weeks'],
  [ReportPeriodTime.PREVIOUS_7_WEEK, 'Previous 7 weeks'],
  [ReportPeriodTime.PREVIOUS_8_WEEK, 'Previous 8 weeks'],
  [ReportPeriodTime.YTD, 'Year to date'],
]);

export const mapItemsOptionsToDisplayName = new Map([[ItemsOptionsValues.ALL, 'All']]);
export const mapGranularityToDisplayName = new Map([
  ['day', 'Daily'],
  ['week', 'Weekly'],
  ['month', 'Monthly'],
  ['quarter', 'Quarterly'],
]);
export const mapPeriodDisplayNameToReportDate = new Map([
  ['last 90 Days', ReportPeriodTime.NINETY_DAYS],
  ['last 60 Days', ReportPeriodTime.SIXTY_DAYS],
  ['last 30 Days', ReportPeriodTime.THIRTY_DAYS],
  ['last 14 Days', ReportPeriodTime.FOURTEEN_DAYS],
  ['last 7 Days', ReportPeriodTime.SEVEN_DAYS],
  ['Current month', ReportPeriodTime.CURRENT_MONTH],
  ['Previous month', ReportPeriodTime.PREVIOUS_MONTH],
  ['Previous 6 month', ReportPeriodTime.PREVIOUS_6_MONTH],
  ['Previous day', ReportPeriodTime.PREVIOUS_DAY],
  ['Previous week', ReportPeriodTime.PREVIOUS_WEEK],
  ['Previous 2 weeks', ReportPeriodTime.PREVIOUS_2_WEEK],
  ['Previous 3 weeks', ReportPeriodTime.PREVIOUS_3_WEEK],
  ['Previous 4 weeks', ReportPeriodTime.PREVIOUS_4_WEEK],
  ['Previous 5 weeks', ReportPeriodTime.PREVIOUS_5_WEEK],
  ['Previous 6 weeks', ReportPeriodTime.PREVIOUS_6_WEEK],
  ['Previous 7 weeks', ReportPeriodTime.PREVIOUS_7_WEEK],
  ['Previous 8 weeks', ReportPeriodTime.PREVIOUS_8_WEEK],
  ['Year to date', ReportPeriodTime.YTD],
]);
export const mapReportDatePeriodToType = new Map([
  [ReportPeriodTime.NINETY_DAYS, 'relativeDate'],
  [ReportPeriodTime.SIXTY_DAYS, 'relativeDate'],
  [ReportPeriodTime.THIRTY_DAYS, 'relativeDate'],
  [ReportPeriodTime.FOURTEEN_DAYS, 'relativeDate'],
  [ReportPeriodTime.SEVEN_DAYS, 'relativeDate'],
  [ReportPeriodTime.CURRENT_MONTH, ReportPeriodTime.CURRENT_MONTH],
  [ReportPeriodTime.PREVIOUS_DAY, ReportPeriodTime.PREVIOUS_DAY],
  [ReportPeriodTime.PREVIOUS_WEEK, ReportPeriodTime.PREVIOUS_WEEK],
  [ReportPeriodTime.PREVIOUS_2_WEEK, ReportPeriodTime.PREVIOUS_2_WEEK],
  [ReportPeriodTime.PREVIOUS_3_WEEK, ReportPeriodTime.PREVIOUS_3_WEEK],
  [ReportPeriodTime.PREVIOUS_4_WEEK, ReportPeriodTime.PREVIOUS_4_WEEK],
  [ReportPeriodTime.PREVIOUS_5_WEEK, ReportPeriodTime.PREVIOUS_5_WEEK],
  [ReportPeriodTime.PREVIOUS_6_WEEK, ReportPeriodTime.PREVIOUS_6_WEEK],
  [ReportPeriodTime.PREVIOUS_7_WEEK, ReportPeriodTime.PREVIOUS_7_WEEK],
  [ReportPeriodTime.PREVIOUS_8_WEEK, ReportPeriodTime.PREVIOUS_8_WEEK],
  [ReportPeriodTime.PREVIOUS_MONTH, ReportPeriodTime.PREVIOUS_MONTH],
  [ReportPeriodTime.PREVIOUS_2_MONTH, ReportPeriodTime.PREVIOUS_2_MONTH],
  [ReportPeriodTime.PREVIOUS_3_MONTH, ReportPeriodTime.PREVIOUS_3_MONTH],
  [ReportPeriodTime.PREVIOUS_6_MONTH, ReportPeriodTime.PREVIOUS_6_MONTH],
  [ReportPeriodTime.YTD, ReportPeriodTime.YTD],
]);

export const azureMapCostUsageStateToDisplay = new Map([
  [CostUsageStates.OPERATION, DisplayCostUsageStates.METER_NAME],
  [CostUsageStates.FAMILY_TYPE, DisplayCostUsageStates.METER_CATEGORY],
]);

export const mapCostUsageStateToDisplay = new Map([
  [CostUsageStates.SERVICE, DisplayCostUsageStates.SERVICE],
  [CostUsageStates.REGION, DisplayCostUsageStates.REGION],
  [CostUsageStates.LINKED_ACC_ID, DisplayCostUsageStates.LINKED_ACC_ID],
  [CostUsageStates.OPERATION, DisplayCostUsageStates.OPERATION],
  [CostUsageStates.TYPE, DisplayCostUsageStates.TYPE],
  [CostUsageStates.RESOURCE, DisplayCostUsageStates.RESOURCE],
  [CostUsageStates.PROJECT, DisplayCostUsageStates.PROJECT],
  [CostUsageStates.ENVIRONMENT, DisplayCostUsageStates.ENVIRONMENT],
  [CostUsageStates.FAMILY_TYPE, DisplayCostUsageStates.FAMILY_TYPE],
  [CostUsageStates.CLUSTER, DisplayCostUsageStates.CLUSTER],
  [CostUsageStates.NODE, DisplayCostUsageStates.NODE],
  [CostUsageStates.NODE_GROUP, DisplayCostUsageStates.NODE_GROUP],
  [CostUsageStates.POD, DisplayCostUsageStates.POD],
  [CostUsageStates.NAMESPACE, DisplayCostUsageStates.NAMESPACE],
  [CostUsageStates.AVAILABILITY_ZONE, DisplayCostUsageStates.AVAILABILITY_ZONE],
  [CostUsageStates.RESOURCE_GROUP, DisplayCostUsageStates.RESOURCE_GROUP],
  [CostUsageStates.SUBSCRIPTION_ID, DisplayCostUsageStates.SUBSCRIPTION_ID],
  [CostUsageStates.DIVISION, DisplayCostUsageStates.DIVISION],
  [CostUsageStates.CUSTOM_TAGS, DisplayCostUsageStates.CUSTOM_TAGS],
  [CostUsageStates.ACCOUNT_TAGS, DisplayCostUsageStates.ACCOUNT_TAGS],
  [CostUsageStates.VIRTUAL_CUSTOM_TAGS, DisplayCostUsageStates.VIRTUAL_CUSTOM_TAGS],
  [CostUsageStates.VIEWS_CUSTOM_TAGS, DisplayCostUsageStates.VIEWS_CUSTOM_TAGS],
  [CostUsageStates.CHARGE_TYPE, DisplayCostUsageStates.CHARGE_TYPE],
  [CostUsageStates.PURCHASE_OPTION, DisplayCostUsageStates.PURCHASE_OPTION],
  [CostUsageStates.PAYER_ACCOUNT, DisplayCostUsageStates.PAYER_ACCOUNT],
  [CostUsageStates.CLOUD_PROVIDER, DisplayCostUsageStates.CLOUD_PROVIDER],
  [CostUsageStates.NONE, DisplayCostUsageStates.NONE],
  [CostUsageStates.COST_TYPE_DESCRIPTION, DisplayCostUsageStates.COST_TYPE_DESCRIPTION],
  [CostUsageStates.CATEGORY, DisplayCostUsageStates.CATEGORY],
  [CostUsageStates.BUSINESS_MAPPING, DisplayCostUsageStates.BUSINESS_MAPPING],
  [CostUsageStates.USAGE_TYPE, DisplayCostUsageStates.USAGE_TYPE],
  [CostUsageStates.LABELS, DisplayCostUsageStates.LABEL_TAGS],
  [CostUsageStates.SUB_METER_CATEGORY, DisplayCostUsageStates.SUB_METER_CATEGORY],
  [CostUsageStates.LINE_TYPE, DisplayCostUsageStates.LINE_TYPE],
  [CostUsageStates.COST_TYPE, DisplayCostUsageStates.COST_TYPE],
]);

export const mapCostUsageStateToDisplayByCloudType = (cloudTypeId, value) => {
  if (cloudTypeId === CLOUD_TYPE_IDS.AZURE && azureMapCostUsageStateToDisplay.has(value)) {
    return azureMapCostUsageStateToDisplay.get(value);
  }
  return mapCostUsageStateToDisplay.get(value);
};

export const mapGroupByAttributeToCostUsageState = new Map([
  [AwsCommonFields.SERVICE, CostUsageStates.SERVICE],
  [AwsCommonFields.REGION, CostUsageStates.REGION],
  [AwsCommonFields.AVAILABILITY_ZONE, CostUsageStates.AVAILABILITY_ZONE],
  [AwsCommonFields.LINKED_ACCOUNT_ID, CostUsageStates.LINKED_ACC_ID],
  [AwsCommonFields.OPERATION, CostUsageStates.OPERATION],
  [AwsCommonFields.TYPE, CostUsageStates.TYPE],
  [AwsCommonFields.RESOURCE, CostUsageStates.RESOURCE],
  [AwsCommonFields.PROJECT, CostUsageStates.PROJECT],
  [AwsCommonFields.ENVIRONMENT, CostUsageStates.ENVIRONMENT],
  [K8sCommonFields.CLUSTER, CostUsageStates.CLUSTER],
  [K8sCommonFields.NAMESPACE, CostUsageStates.NAMESPACE],
  [K8sCommonFields.NODE, CostUsageStates.NODE],
  [AwsCommonFields.NODE_GROUP, CostUsageStates.NODE_GROUP],
  [K8sCommonFields.POD, CostUsageStates.POD],
  [AzureCommonFields.RESOURCE_GROUP, CostUsageStates.RESOURCE_GROUP],
  [AwsCommonFields.DIVISION, CostUsageStates.DIVISION],
  [AwsCommonFields.CUSTOM_TAGS, CostUsageStates.CUSTOM_TAGS],
  [AwsCommonFields.ACCOUNT_TAGS, CostUsageStates.ACCOUNT_TAGS],
  [AwsCommonFields.CHARGE_TYPE, CostUsageStates.CHARGE_TYPE],
  [AwsCommonFields.PURCHASE_OPTION, CostUsageStates.PURCHASE_OPTION],
  [AwsCommonFields.VIRTUAL_CUSTOM_TAGS, CostUsageStates.VIRTUAL_CUSTOM_TAGS],
  [AwsCommonFields.VIEWS_CUSTOM_TAGS, CostUsageStates.VIEWS_CUSTOM_TAGS],
  [AwsCommonFields.FAMILY_TYPE, CostUsageStates.FAMILY_TYPE],
  [AwsCommonFields.PAYER_ACCOUNT, CostUsageStates.PAYER_ACCOUNT],
  [AwsCommonFields.CLOUD_PROVIDER, CostUsageStates.CLOUD_PROVIDER],
  [AwsCommonFields.NONE, CostUsageStates.NONE],
  [AwsCommonFields.COST_TYPE, CostUsageStates.COST_TYPE],
  [GcpCommonFields.COST_TYPE_DESCRIPTION, CostUsageStates.COST_TYPE_DESCRIPTION],
  [GcpCommonFields.CATEGORY, CostUsageStates.CATEGORY],
  [GcpCommonFields.BUSINESS_MAPPING, CostUsageStates.BUSINESS_MAPPING],
  [AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS, CostUsageStates.BUSINESS_MAPPING],
  [GcpCommonFields.USAGE_TYPE, CostUsageStates.USAGE_TYPE],
  [AwsCommonFields.DEPLOYMENT, CostUsageStates.DEPLOYMENT],
  [K8sCommonFields.LABELS, CostUsageStates.LABELS],
]);
export const mapAzureGroupByAttributeToCostUsageState = new Map([
  [AwsCommonFields.SERVICE, CostUsageStates.SERVICE],
  [AwsCommonFields.REGION, CostUsageStates.REGION],
  [AwsCommonFields.OPERATION, CostUsageStates.OPERATION],
  [AzureCommonFields.METER_CATEGORY, CostUsageStates.FAMILY_TYPE],
  [AzureCommonFields.SUB_METER_CATEGORY, CostUsageStates.SUB_METER_CATEGORY],
  [AzureCommonFields.LINE_TYPE, CostUsageStates.LINE_TYPE],
  [AzureCommonFields.COST_TYPE, CostUsageStates.COST_TYPE],
  [AwsCommonFields.TYPE, CostUsageStates.TYPE],
  [AwsCommonFields.RESOURCE, CostUsageStates.RESOURCE],
  [AwsCommonFields.PROJECT, CostUsageStates.PROJECT],
  [AwsCommonFields.ENVIRONMENT, CostUsageStates.ENVIRONMENT],
  [K8sCommonFields.CLUSTER, CostUsageStates.CLUSTER],
  [K8sCommonFields.NODE, CostUsageStates.NODE],
  [K8sCommonFields.POD, CostUsageStates.POD],
  [AzureCommonFields.RESOURCE_GROUP, CostUsageStates.RESOURCE_GROUP],
  [AzureCommonFields.SUBSCRIPTION_ID, CostUsageStates.SUBSCRIPTION_ID],
  [AwsCommonFields.DIVISION, CostUsageStates.DIVISION],
  [AwsCommonFields.CUSTOM_TAGS, CostUsageStates.CUSTOM_TAGS],
  [AwsCommonFields.ACCOUNT_TAGS, CostUsageStates.ACCOUNT_TAGS],
  [AwsCommonFields.PURCHASE_OPTION, CostUsageStates.PURCHASE_OPTION],
  [AwsCommonFields.PAYER_ACCOUNT, CostUsageStates.PAYER_ACCOUNT],
  [AwsCommonFields.CLOUD_PROVIDER, CostUsageStates.CLOUD_PROVIDER],
  [AwsCommonFields.NONE, CostUsageStates.NONE],
  [AwsCommonFields.CATEGORY, CostUsageStates.CATEGORY],
  [AwsCommonFields.BUSINESS_MAPPING, CostUsageStates.BUSINESS_MAPPING],
  [AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS, CostUsageStates.BUSINESS_MAPPING],
  [AwsCommonFields.USAGE_TYPE, CostUsageStates.USAGE_TYPE],
  [AwsCommonFields.NAMESPACE, CostUsageStates.NAMESPACE],
  [AwsCommonFields.NODE_GROUP, CostUsageStates.NODE_GROUP],
  [AwsCommonFields.VIRTUAL_CUSTOM_TAGS, CostUsageStates.VIRTUAL_CUSTOM_TAGS],
  [AwsCommonFields.VIEWS_CUSTOM_TAGS, CostUsageStates.VIEWS_CUSTOM_TAGS],
  [K8sCommonFields.LABELS, CostUsageStates.LABELS],
  [AwsCommonFields.DEPLOYMENT, CostUsageStates.DEPLOYMENT],
]);

export const mapCloudBaseGroupByToCostUsageStateMap = new Map([
  [CLOUD_TYPE_IDS.AWS, mapGroupByAttributeToCostUsageState],
  [CLOUD_TYPE_IDS.AZURE, mapAzureGroupByAttributeToCostUsageState],
  [CLOUD_TYPE_IDS.GCP, mapGroupByAttributeToCostUsageState],
  [CLOUD_TYPE_IDS.MULTI, mapGroupByAttributeToCostUsageState],
]);

export const mapCostUsageStateToAttributes = new Map([
  [CostUsageStates.SERVICE, AwsCommonFields.SERVICE],
  [CostUsageStates.REGION, AwsCommonFields.REGION],
  [CostUsageStates.LINKED_ACC_ID, AwsCommonFields.LINKED_ACCOUNT_ID],
  [CostUsageStates.SUBSCRIPTION_ID, AwsCommonFields.LINKED_ACCOUNT_ID],
  [CostUsageStates.OPERATION, AwsCommonFields.OPERATION],
  [CostUsageStates.TYPE, AwsCommonFields.TYPE],
  [CostUsageStates.FAMILY_TYPE, AwsCommonFields.FAMILY_TYPE],
  [CostUsageStates.RESOURCE, AwsCommonFields.RESOURCE],
  [CostUsageStates.PROJECT, AwsCommonFields.PROJECT],
  [CostUsageStates.ENVIRONMENT, AwsCommonFields.ENVIRONMENT],
  [CostUsageStates.CLUSTER, K8sCommonFields.CLUSTER],
  [CostUsageStates.NAMESPACE, K8sCommonFields.NAMESPACE],
  [CostUsageStates.NODE, K8sCommonFields.NODE],
  [CostUsageStates.NODE_GROUP, AwsCommonFields.NODE_GROUP],
  [CostUsageStates.POD, K8sCommonFields.POD],
  [CostUsageStates.RESOURCE_GROUP, AzureCommonFields.RESOURCE_GROUP],
  [CostUsageStates.DIVISION, AwsCommonFields.DIVISION],
  [CostUsageStates.CUSTOM_TAGS, AwsCommonFields.CUSTOM_TAGS],
  [CostUsageStates.ACCOUNT_TAGS, AwsCommonFields.ACCOUNT_TAGS],
  [CostUsageStates.CHARGE_TYPE, AwsCommonFields.CHARGE_TYPE],
  [CostUsageStates.PURCHASE_OPTION, AwsCommonFields.PURCHASE_OPTION],
  [CostUsageStates.POD_DETAILS, AwsCommonFields.POD],
  [CostUsageStates.INSTANCE_TYPE, AwsCommonFields.INSTANCE_TYPE],
  [CostUsageStates.VIRTUAL_CUSTOM_TAGS, AwsCommonFields.VIRTUAL_CUSTOM_TAGS],
  [CostUsageStates.VIEWS_CUSTOM_TAGS, AwsCommonFields.VIEWS_CUSTOM_TAGS],
  [CostUsageStates.PAYER_ACCOUNT, AwsCommonFields.PAYER_ACCOUNT],
  [CostUsageStates.CLOUD_PROVIDER, AwsCommonFields.CLOUD_PROVIDER],
  [CostUsageStates.NONE, AwsCommonFields.NONE],
  [CostUsageStates.SUB_METER_CATEGORY, AzureCommonFields.SUB_METER_CATEGORY],
  [CostUsageStates.LINE_TYPE, AzureCommonFields.LINE_TYPE],
  [CostUsageStates.COST_TYPE, AzureCommonFields.COST_TYPE],
]);
export const mapAzureCostUsageStateToAttributes = new Map([
  [CostUsageStates.SERVICE, AwsCommonFields.SERVICE],
  [CostUsageStates.REGION, AwsCommonFields.REGION],
  [CostUsageStates.OPERATION, AwsCommonFields.OPERATION],
  [CostUsageStates.TYPE, AwsCommonFields.TYPE],
  [CostUsageStates.FAMILY_TYPE, AwsCommonFields.FAMILY_TYPE],
  [CostUsageStates.SUB_METER_CATEGORY, AzureCommonFields.SUB_METER_CATEGORY],
  [CostUsageStates.LINE_TYPE, AzureCommonFields.LINE_TYPE],
  [CostUsageStates.COST_TYPE, AzureCommonFields.COST_TYPE],
  [CostUsageStates.RESOURCE, AwsCommonFields.RESOURCE],
  [CostUsageStates.PROJECT, AwsCommonFields.PROJECT],
  [CostUsageStates.ENVIRONMENT, AwsCommonFields.ENVIRONMENT],
  [CostUsageStates.RESOURCE_GROUP, AzureCommonFields.RESOURCE_GROUP],
  [CostUsageStates.SUBSCRIPTION_ID, AzureCommonFields.SUBSCRIPTION_ID],
  [CostUsageStates.DIVISION, AwsCommonFields.DIVISION],
  [CostUsageStates.PURCHASE_OPTION, AwsCommonFields.PURCHASE_OPTION],
  [CostUsageStates.NONE, AwsCommonFields.NONE],
  [CostUsageStates.CATEGORY, AwsCommonFields.CATEGORY],
  [CostUsageStates.BUSINESS_MAPPING, AwsCommonFields.BUSINESS_MAPPING],
  [CostUsageStates.VIRTUAL_CUSTOM_TAGS, AwsCommonFields.VIRTUAL_CUSTOM_TAGS],
  [CostUsageStates.VIEWS_CUSTOM_TAGS, AwsCommonFields.VIEWS_CUSTOM_TAGS],
]);

export const mapCloudBaseCostUsageStateToAttributesMap = new Map([
  [CLOUD_TYPE_IDS.AWS, mapCostUsageStateToAttributes],
  [CLOUD_TYPE_IDS.AZURE, mapAzureCostUsageStateToAttributes],
  [CLOUD_TYPE_IDS.GCP, mapCostUsageStateToAttributes],
  [CLOUD_TYPE_IDS.MULTI, mapCostUsageStateToAttributes],
]);

export class GROUP_BY_LOV {
  // static NONE = 'None';
  static BY_NONE = '- None -';
  static BY_SERVICE = 'Service';
  static BY_REGION = 'Region';
  static BY_AZ = 'Availability Zone';
  static BY_LINKED_ACC_NAME = 'Linked Account';
  static BY_LINKED_ACC_ID = 'Linked Account';
  static BY_SUBSCRIPTION = 'Subscription';
  static BY_PROJECT = 'Project';
  static BY_OPERATION = 'Operation';
  static BY_TYPE = 'Type';
  static BY_RESOURCE = 'Resource';
  static BY_ENV = 'Environment';
  static BY_DATE = 'Date';
  static BY_CLUSTER = 'Cluster';
  static BY_NAMESPACE = 'Namespace';
  static BY_NODE = 'Node';
  static BY_COST_TYPE = 'Cost Type';
  static BY_NODE_GROUP = 'Node Group';
  static BY_DEPLOYMENT = 'Deployment';
  static BY_POD = 'Pod';
  static BY_CATEGORY = 'Category';
  static BY_BUSINESS_MAPPING = 'Business Mapping';
  static BY_USAGE_TYPE = 'Usage Type';
  static BY_DIVISION = 'Cost Center';
  static BY_CUSTOM_TAGS = 'Custom Tags';
  static BY_CHARGE_TYPE = 'Charge Type';
  static BY_PURCHASE_OPTION = 'Purchase Option';
  static BY_INSTANCE_TYPE = 'Instance Type';
  static BY_FAMILY_TYPE = 'Family Type';
  static PAYER_ACCOUNT = 'Payer Account';
  static PAYER_ACCOUNT_NAME = 'Payer Account Name';
  static BY_CLOUD_PROVIDER = 'Cloud Provider';
  static BY_USAGE_QUANTITY_TYPE = 'Quantity Type';
  static BY_ITEM_DESCRIPTION = 'Item Description';
}

export class AWS_GROUP_BY_METRICS_LOV {
  static BY_LINKED_ACC_ID = 'Linked Account';
  static BY_INSTANCE_TYPE = 'Instance Type';
  static BY_RESOURCE = 'Resource';
}

export class AWS_GROUP_BY_LOV {
  static BY_NONE = '- None -';
  static BY_SERVICE = 'Service';
  static BY_REGION = 'Region';
  static BY_AZ = 'Availability Zone';
  static BY_LINKED_ACC_ID = 'Linked Account';
  static BY_OPERATION = 'Operation';
  static BY_DIVISION = 'Cost Center';
  static BY_CHARGE_TYPE = 'Charge Type';
  static BY_PURCHASE_OPTION = 'Purchase Option';
  static BY_RESOURCE = 'Resource';
  static BY_INSTANCE_TYPE = 'Instance Type';
  static BY_FAMILY_TYPE = 'Family Type';
  static BY_CATEGORY = 'Category';
  static BY_USAGE_TYPE = 'Usage Type';
  static BY_COST_TYPE = 'Cost Type';
}

export class K8S_GROUP_BY_LOV {
  static BY_NONE = '- None -';
  static BY_REGION = 'Region';
  static BY_LINKED_ACC_ID = 'Linked Account';
  static BY_CLUSTER = 'Cluster';
  static BY_NAMESPACE = 'Namespace';
  static BY_NODE = 'Node';
  static BY_POD = 'Pod';
  static BY_INSTANCE_TYPE = 'Instance Type';
  static BY_NODE_GROUP = 'Node Group';
  static BY_DEPLOYMENT = 'Deployment';
}
export class GCP_K8S_GROUP_BY_LOV {
  static BY_NONE = '- None -';
  static BY_REGION = 'Region';
  static BY_LINKED_ACC_ID = 'Linked Account';
  static BY_CLUSTER = 'Cluster';
  static BY_NAMESPACE = 'Namespace';
  static BY_NODE = 'Node';
  static BY_POD = 'Pod';
  static BY_INSTANCE_TYPE = 'Instance Type';
  static BY_NODE_GROUP = 'Node Group';
}

export class AZURE_K8S_GROUP_BY_LOV {
  static BY_NONE = '- None -';
  static BY_CLUSTER = 'Cluster';
  static BY_REGION = 'Region';
  static BY_SUBSCRIPTION = 'Subscription';
  static BY_NAMESPACE = 'Namespace';
  static BY_NODE = 'Node';
  static BY_POD = 'Pod';
  static BY_INSTANCE_TYPE = 'Instance Type';
  static BY_NODE_GROUP = 'Node Group';
  static BY_DEPLOYMENT = 'Deployment';
}

export const mapK8SGroupByCloudBasedLov = new Map([
  [CLOUD_TYPE_IDS.AWS, K8S_GROUP_BY_LOV],
  [CLOUD_TYPE_IDS.GCP, GCP_K8S_GROUP_BY_LOV],
  [CLOUD_TYPE_IDS.AZURE, AZURE_K8S_GROUP_BY_LOV],
]);

export class MULTI_GROUP_BY_LOV {
  static BY_NONE = '- None -';
  static BY_CLOUD = 'Cloud Provider';
  static BY_DIVISION = 'Cost Center';
  static BY_LINKED_ACC_ID = 'Linked Account';
  static BY_SERVICE = 'Service';
  static BY_OPERATION = 'Operation';
  static BY_REGION = 'Region';
  static BY_FAMILY_TYPE = 'Family Type';
  static BY_CATEGORY = 'Category';
  static BY_COST_TYPE = 'Cost Type';
}

// FIXME GCP-AZURE
export class GCP_GROUP_BY_LOV {
  static BY_NONE = '- None -';
  static BY_SERVICE = 'Service';
  static BY_REGION = 'Region';
  static BY_OPERATION = 'Operation';
  static BY_LINKED_ACC_ID = 'Linked Account';
  static BY_DIVISION = 'Cost Center';
  static BY_RESOURCE = 'Resource';
  static BY_FAMILY_TYPE = 'Family Type';
  static BY_COST_TYPE_DESCRIPTION = 'Cost Type Description';
  static BY_CATEGORY = 'Category';
  static BY_COST_TYPE = 'Cost Type';
}

export class AZURE_GROUP_BY_LOV {
  static BY_NONE = '- None -';
  static BY_SERVICE = 'Service';
  static BY_REGION = 'Region';
  static BY_METER_NAME = 'Meter Name';
  static BY_METER_CATEGORY = 'Meter Category';
  static BY_SUB_METER_CATEGORY = 'Sub Meter Category';
  static BY_LINE_TYPE = 'Line Type';
  static BY_RESOURCE_GROUP = 'Resource Group';
  static BY_SUBSCRIPTION_ID = 'Subscription';
  static BY_DIVISION = 'Cost Center';
  static BY_RESOURCE = 'Resource';
  static BY_PURCHASE_OPTION = 'Purchase Option';
  static BY_CATEGORY = 'Category';
  static BY_USAGE_TYPE = 'Usage Type';
  static BY_COST_TYPE = 'Cost Type';
}

// AWS Maps

export const mapGroupByCloudBasedLov = new Map([
  [CLOUD_TYPE_IDS.AWS, AWS_GROUP_BY_LOV],
  [CLOUD_TYPE_IDS.AZURE, AZURE_GROUP_BY_LOV],
  [CLOUD_TYPE_IDS.GCP, GCP_GROUP_BY_LOV],
  [CLOUD_TYPE_IDS.MULTI, MULTI_GROUP_BY_LOV],
]);

export const GroupByLovToAttributes = new Map([
  [GROUP_BY_LOV.BY_NONE, AwsCommonFields.NONE],
  [GROUP_BY_LOV.BY_COST_TYPE, AwsCommonFields.COST_TYPE],
  [GROUP_BY_LOV.BY_DATE, AwsCommonFields.USAGE_DATE],
  [GROUP_BY_LOV.BY_SERVICE, AwsCommonFields.SERVICE],
  [GROUP_BY_LOV.BY_REGION, AwsCommonFields.REGION],
  [GROUP_BY_LOV.BY_AZ, AwsCommonFields.AVAILABILITY_ZONE],
  [GROUP_BY_LOV.BY_LINKED_ACC_ID, AwsCommonFields.LINKED_ACCOUNT_ID],
  [GROUP_BY_LOV.BY_OPERATION, AwsCommonFields.OPERATION],
  [GROUP_BY_LOV.BY_TYPE, AwsCommonFields.TYPE],
  [GROUP_BY_LOV.BY_RESOURCE, AwsCommonFields.RESOURCE],
  [GROUP_BY_LOV.BY_PROJECT, AwsCommonFields.PROJECT],
  [GROUP_BY_LOV.BY_ENV, AwsCommonFields.ENVIRONMENT],
  [AZURE_GROUP_BY_LOV.BY_RESOURCE_GROUP, AzureCommonFields.RESOURCE_GROUP],
  [AZURE_GROUP_BY_LOV.BY_SUBSCRIPTION_ID, AzureCommonFields.SUBSCRIPTION_ID],
  [AZURE_GROUP_BY_LOV.BY_METER_NAME, AwsCommonFields.OPERATION],
  [AZURE_GROUP_BY_LOV.BY_METER_CATEGORY, AzureCommonFields.METER_CATEGORY],
  [AZURE_GROUP_BY_LOV.BY_SUB_METER_CATEGORY, AzureCommonFields.SUB_METER_CATEGORY],
  [AZURE_GROUP_BY_LOV.BY_LINE_TYPE, AzureCommonFields.LINE_TYPE],
  [GROUP_BY_LOV.BY_NODE_GROUP, AwsCommonFields.NODE_GROUP],
  [GROUP_BY_LOV.BY_DEPLOYMENT, AwsCommonFields.DEPLOYMENT],
  [GROUP_BY_LOV.BY_CLUSTER, K8sCommonFields.CLUSTER],
  [GROUP_BY_LOV.BY_POD, K8sCommonFields.POD],
  [GROUP_BY_LOV.BY_NAMESPACE, K8sCommonFields.NAMESPACE],
  [GROUP_BY_LOV.BY_DIVISION, AwsCommonFields.DIVISION],
  [GROUP_BY_LOV.BY_CHARGE_TYPE, AwsCommonFields.CHARGE_TYPE],
  [GROUP_BY_LOV.BY_PURCHASE_OPTION, AwsCommonFields.PURCHASE_OPTION],
  [GROUP_BY_LOV.BY_INSTANCE_TYPE, AwsCommonFields.INSTANCE_TYPE],
  [GROUP_BY_LOV.BY_FAMILY_TYPE, AwsCommonFields.FAMILY_TYPE],
  [MULTI_GROUP_BY_LOV.BY_COST_TYPE, AwsCommonFields.COST_TYPE],
  [GROUP_BY_LOV.PAYER_ACCOUNT, AwsCommonFields.PAYER_ACCOUNT],
  [GROUP_BY_LOV.PAYER_ACCOUNT_NAME, AwsCommonFields.PAYER_ACCOUNT_NAME],
  [GROUP_BY_LOV.BY_CLOUD_PROVIDER, AwsCommonFields.CLOUD_PROVIDER],
  [GROUP_BY_LOV.BY_USAGE_QUANTITY_TYPE, AwsCommonFields.QUANTITY_TYPE],
  [GROUP_BY_LOV.BY_ITEM_DESCRIPTION, AwsCommonFields.ITEM_DESCRIPTION],
  [GROUP_BY_LOV.BY_CATEGORY, AwsCommonFields.CATEGORY],
  [GROUP_BY_LOV.BY_BUSINESS_MAPPING, AwsCommonFields.BUSINESS_MAPPING],
  [GROUP_BY_LOV.BY_USAGE_TYPE, AwsCommonFields.USAGE_TYPE],
  [GCP_GROUP_BY_LOV.BY_COST_TYPE_DESCRIPTION, GcpCommonFields.COST_TYPE_DESCRIPTION],
]);

export const K8sGroupByLovToAttributes = new Map([
  [GROUP_BY_LOV.BY_NONE, AwsCommonFields.NONE],
  [K8S_GROUP_BY_LOV.BY_CLUSTER, K8sCommonFields.CLUSTER],
  [GROUP_BY_LOV.BY_REGION, K8sCommonFields.REGION],
  [GROUP_BY_LOV.BY_LINKED_ACC_ID, K8sCommonFields.LINKED_ACCOUNT_ID],
  [GROUP_BY_LOV.BY_SUBSCRIPTION, K8sCommonFields.LINKED_ACCOUNT_ID],
  [GROUP_BY_LOV.BY_POD, K8sCommonFields.POD],
  [K8S_GROUP_BY_LOV.BY_NODE, K8sCommonFields.NODE],
  [GROUP_BY_LOV.BY_NODE_GROUP, AwsCommonFields.NODE_GROUP],
  [GROUP_BY_LOV.BY_DEPLOYMENT, AwsCommonFields.DEPLOYMENT],
  [K8S_GROUP_BY_LOV.BY_NAMESPACE, K8sCommonFields.NAMESPACE],
  [GROUP_BY_LOV.BY_PROJECT, K8sCommonFields.PROJECT],
  [GROUP_BY_LOV.BY_ENV, K8sCommonFields.ENVIRONMENT],
  [GROUP_BY_LOV.BY_INSTANCE_TYPE, AwsCommonFields.INSTANCE_TYPE],
  [GROUP_BY_LOV.BY_DATE, AwsCommonFields.USAGE_DATE],
]);

export class DisplayMetricTypes {
  static COST = 'Cost';
  static USAGE = 'Usage';
  static QUANTITY = 'Quantity';
  static RATE = 'Rate';
  static BYTES = '';
}

export const mapDisplayUsageQuantityTypeToMetricType = new Map([
  [AwsQuantityTypes.HOURS, DisplayMetricTypes.USAGE],
  [AwsQuantityTypes.BYTES, DisplayMetricTypes.BYTES],
  [AwsQuantityTypes.REQUESTS, DisplayMetricTypes.USAGE],
  [AwsQuantityTypes.OTHER, DisplayMetricTypes.USAGE],
  [AwsQuantityTypes.QUANTITY, DisplayMetricTypes.QUANTITY],
  [AwsQuantityTypes.MEMORY, DisplayMetricTypes.BYTES],
  [AwsQuantityTypes.CPU, DisplayMetricTypes.QUANTITY],
]);

export const mapToggleDisplayMetric = new Map([
  [DisplayMetricTypes.COST, 'Usage'],
  [DisplayMetricTypes.USAGE, 'Cost'],
]);

export const mapDataAttributesToDisplayMetricTypes = new Map([
  [AppCommonFields.TOTAL_COST, DisplayMetricTypes.COST],
  [AppCommonFields.TOTAL_USAGE, DisplayMetricTypes.USAGE],
  [AppCommonFields.TOTAL_QUANTITY, DisplayMetricTypes.QUANTITY],
]);

export const mapDisplayMetricTypesToDataAttributes = new Map([
  [DisplayMetricTypes.COST, AppCommonFields.TOTAL_COST],
  [DisplayMetricTypes.USAGE, AppCommonFields.TOTAL_USAGE],
  [DisplayMetricTypes.QUANTITY, AppCommonFields.TOTAL_QUANTITY],
  [DisplayMetricTypes.BYTES, AppCommonFields.TOTAL_USAGE],
  // this map is used to sort data keys: rate sorting by cost field for now
  [DisplayMetricTypes.RATE, AppCommonFields.TOTAL_COST],
]);

export const calcRateValue = (costValue, usageValue, usageSelected) => {
  let rateValue = usageValue ? costValue / usageValue : 0;
  if (usageSelected === AwsQuantityTypes.BYTES) {
    // rate should be per GB
    rateValue *= 1000000000;
  }
  return rateValue;
};

export const getValueFuncByMetricType = (metricType, selectedUsageType) => {
  const map = new Map([
    [DisplayMetricTypes.COST, (row) => row[AppCommonFields.TOTAL_COST]],
    [DisplayMetricTypes.USAGE, (row) => row[AppCommonFields.TOTAL_USAGE]],
    [DisplayMetricTypes.QUANTITY, (row) => row[AppCommonFields.TOTAL_QUANTITY]],
    [DisplayMetricTypes.BYTES, (row) => row[AppCommonFields.TOTAL_USAGE]],
    [
      DisplayMetricTypes.RATE,
      (row) => {
        const field =
          selectedUsageType === AwsQuantityTypes.QUANTITY
            ? AppCommonFields.TOTAL_QUANTITY
            : AppCommonFields.TOTAL_USAGE;
        const usageField = row[field];
        const costField = row[AppCommonFields.TOTAL_COST];
        return calcRateValue(costField, usageField, selectedUsageType);
      },
    ],
  ]);
  return map.get(metricType);
};

export class CostTypes {
  static COST = 'cost';
  static REFUND = 'refund';
  static DISCOUNT = 'discount';
  static CREDIT = 'credit';
  // static TAX = 'tax';
}

export class DisplayCostTypes {
  static COST = 'Cost';
  static REFUND = 'Refund';
  static DISCOUNT = 'Discount';
  static CREDIT = 'Credit';
  // static TAX = 'Tax';
}

export const mapCostTypesToDisplay = new Map([
  [CostTypes.COST, DisplayCostTypes.COST],
  [CostTypes.REFUND, DisplayCostTypes.REFUND],
  [CostTypes.DISCOUNT, DisplayCostTypes.DISCOUNT],
  [CostTypes.CREDIT, DisplayCostTypes.CREDIT],
]);

export const mapDisplayCostTypesToTypes = new Map([
  [DisplayCostTypes.COST, CostTypes.COST],
  [DisplayCostTypes.REFUND, CostTypes.REFUND],
  [DisplayCostTypes.DISCOUNT, CostTypes.DISCOUNT],
  [DisplayCostTypes.CREDIT, CostTypes.CREDIT],
]);

// / EC2 USAGE

export class EC2_USAGE_CHARTS_TITLES {
  static EC2_QUANTITY_BY_TYPE = 'EC2 Quantity By Type';
  static EC2_CURR_MONTH_RI_COVERAGE = 'RI Coverage';
}

// Commitment

export const CommitmentServices = {
  EC2_COMPUTE_RI: 'ec2',
  RDS_RI: 'rds',
  ELASTIC_CACHE_RI: 'elasticache',
  REDSHIFT_RI: 'redshift',
  ELASTIC_SEARCH_RI: 'es',
  OPEN_SEARCH_RI: 'os',
};

export const AZURE_SERVICE_TYPES = {
  virtualMachines: 'virtualMachines',
  storage: 'storage',
  sqlDatabase: 'sqlDatabase',
};

export const ChartTypeValues = {
  BAR: 'bar',
  AREA: 'area',
  LINE: 'line',
  TABLE: 'table',
  PIE: 'pie',
};

export const CostTypeModes = {
  COST: 'COST',
  USAGE: 'USAGE',
  RATE: 'RATE',
  GOAL: 'GOAL',
};

export const ChartTypeDisplayTypes = [
  { name: 'Stacked Bar', value: ChartTypeValues.BAR },
  { name: 'Stacked Area', value: ChartTypeValues.AREA },
  { name: 'Line Chart', value: ChartTypeValues.LINE },
  { name: 'Table', value: ChartTypeValues.TABLE },
  { name: 'Pie Chart', value: ChartTypeValues.PIE },
];

export const convertModeToUsageRateFlags = (costTypeMode) => ({
  isUsageChecked: costTypeMode === CostTypeModes.USAGE,
  isRateChecked: costTypeMode === CostTypeModes.RATE,
});

export const convertUsageRateFlagsToMode = ({ isUsageChecked, isRateChecked }) => {
  if (isRateChecked) {
    return CostTypeModes.RATE;
  }
  if (isUsageChecked) {
    return CostTypeModes.USAGE;
  }
  return CostTypeModes.COST;
};

export const convertFlagToChartTypes = (chartType) => ({
  isDisplayTable: chartType === ChartTypeValues.TABLE,
  isAreaChart: chartType === ChartTypeValues.AREA,
  isPieChart: chartType === ChartTypeValues.PIE,
  isLineChart: chartType === ChartTypeValues.LINE,
});

export const convertChartTypesFlag = ({ isDisplayTable, isPieChart, isAreaChart, isLineChart }) => {
  if (isDisplayTable) {
    return ChartTypeValues.TABLE;
  }
  if (isPieChart) {
    return ChartTypeValues.PIE;
  }
  if (isAreaChart) {
    return ChartTypeValues.AREA;
  }
  if (isLineChart) {
    return ChartTypeValues.LINE;
  }
  return ChartTypeValues.BAR;
};

// Amortize
export const AMORTIZE_COST_TYPES = {
  AMORTIZED: 'amortized',
  NET_AMORTIZED: 'netAmortized',
  UNBLENDED: 'unblended',
  NET_UNBLENDED: 'netUnblended',
  PUBLIC_COST: 'publicCost',
};

export const AMORTIZE_COST_TYPE_TO_NAME = {
  [AMORTIZE_COST_TYPES.AMORTIZED]: 'isShowAmortizeCost',
  [AMORTIZE_COST_TYPES.NET_AMORTIZED]: 'isNetAmortize',
  [AMORTIZE_COST_TYPES.UNBLENDED]: '',
  [AMORTIZE_COST_TYPES.NET_UNBLENDED]: 'isNetUnblended',
  [AMORTIZE_COST_TYPES.PUBLIC_COST]: 'isPublicCost',
};

export const AMORTIZE_COST_DEFAULT_STATE = {
  isShowAmortizeCost: false,
  isNetAmortize: false,
  isNetUnblended: false,
  isPublicCost: false,
};

export const costTypeToFlags = (costType) => {
  const updatedState = { ...AMORTIZE_COST_DEFAULT_STATE };
  if (AMORTIZE_COST_TYPE_TO_NAME[costType]) {
    updatedState[AMORTIZE_COST_TYPE_TO_NAME[costType]] = true;
  }
  return updatedState;
};

export const AMORTIZE_COST_TYPES_ITEMS = {
  AMORTIZED: { label: 'Amortized', name: 'Amortized', value: AMORTIZE_COST_TYPES.AMORTIZED },
  NET_AMORTIZED: { label: 'Net Amortized', name: 'Net Amortized', value: AMORTIZE_COST_TYPES.NET_AMORTIZED },
  NET_UNBLENDED: { label: 'Net Unblended', name: 'Net Unblended', value: AMORTIZE_COST_TYPES.NET_UNBLENDED },
  PUBLIC_COST: { label: 'Public Cost', name: 'Public Cost', value: AMORTIZE_COST_TYPES.PUBLIC_COST },
  UNBLENDED: { label: 'Unblended', name: 'Unblended', value: AMORTIZE_COST_TYPES.UNBLENDED },
};

export const AmortizeCostTypesCustomerEDPItems = Object.values(AMORTIZE_COST_TYPES_ITEMS).filter(
  (v) =>
    v.value.toLowerCase() !== AMORTIZE_COST_TYPES.NET_UNBLENDED.toLowerCase() &&
    v.value.toLowerCase() !== AMORTIZE_COST_TYPES.NET_AMORTIZED.toLowerCase(),
);

export const ResourceExplorerTypes = {
  Operations: 'operations',
  CostCoverage: 'cost-coverage',
  ResourceID: 'resource-id',
  InstanceType: 'instance-type',
};

export class Dimensions {
  static AVERAGE = 'average';
  static MAX = 'maximum';
  static MIN = 'minimum';
}

export class DisplayDimensions {
  static AVERAGE = 'Average';
  static MAX = 'Maximum';
  static MIN = 'Minimum';
}

export const mapDimensionsToDisplay = new Map([
  [Dimensions.AVERAGE, DisplayDimensions.AVERAGE],
  [Dimensions.MAX, DisplayDimensions.MAX],
  [Dimensions.MIN, DisplayDimensions.MIN],
]);

export const mapDisplayToDimensions = new Map([
  [DisplayDimensions.AVERAGE, Dimensions.AVERAGE],
  [DisplayDimensions.MAX, Dimensions.MAX],
  [DisplayDimensions.MIN, Dimensions.MIN],
]);

// / ReportModal///
export const TEST_MODAL_TYPE = 'test_modal';

export const PeriodOptions = [
  { value: ReportPeriodTime.SEVEN_DAYS, label: 'last 7 Days' },
  { value: ReportPeriodTime.FOURTEEN_DAYS, label: 'last 14 Days' },
  { value: ReportPeriodTime.THIRTY_DAYS, label: 'last 30 Days' },
  { value: ReportPeriodTime.SIXTY_DAYS, label: 'last 60 Days' },
  { value: ReportPeriodTime.NINETY_DAYS, label: 'last 90 Days' },
  { value: ReportPeriodTime.CURRENT_MONTH, label: 'Current month' },
  { value: ReportPeriodTime.PREVIOUS_MONTH, label: 'Previous month' },
  { value: ReportPeriodTime.PREVIOUS_2_MONTH, label: 'Previous 2 months' },
  { value: ReportPeriodTime.PREVIOUS_3_MONTH, label: 'Previous 3 months' },
  { value: ReportPeriodTime.PREVIOUS_6_MONTH, label: 'Previous 6 months' },
  { value: ReportPeriodTime.PREVIOUS_DAY, label: 'Previous day' },
  { value: ReportPeriodTime.PREVIOUS_WEEK, label: 'Previous week' },
  { value: ReportPeriodTime.PREVIOUS_2_WEEK, label: 'Previous 2 weeks' },
  { value: ReportPeriodTime.PREVIOUS_3_WEEK, label: 'Previous 3 weeks' },
  { value: ReportPeriodTime.PREVIOUS_4_WEEK, label: 'Previous 4 weeks' },
  { value: ReportPeriodTime.PREVIOUS_5_WEEK, label: 'Previous 5 weeks' },
  { value: ReportPeriodTime.PREVIOUS_6_WEEK, label: 'Previous 6 weeks' },
  { value: ReportPeriodTime.PREVIOUS_7_WEEK, label: 'Previous 7 weeks' },
  { value: ReportPeriodTime.PREVIOUS_8_WEEK, label: 'Previous 8 weeks' },
  { value: ReportPeriodTime.YTD, label: 'Year to date' },
];

export const FrequencyOptions = [
  { value: ReportSendFrequency.NONE, label: '-' },
  { value: ReportSendFrequency.WEEKLY, label: 'Weekly' },
  { value: ReportSendFrequency.MONTHLY, label: 'Monthly' },
  { value: ReportSendFrequency.QUARTERLY, label: 'Quarterly' },
  { value: ReportSendFrequency.CUSTOM, label: 'Custom' },
];

export const WeekDays = [
  { value: DaysOfWeek.SUNDAY, label: 'Every Sunday' },
  { value: DaysOfWeek.MONDAY, label: 'Every Monday' },
  { value: DaysOfWeek.TUESDAY, label: 'Every Tuesday' },
  { value: DaysOfWeek.WEDNESDAY, label: 'Every Wednesday' },
  { value: DaysOfWeek.THURSDAY, label: 'Every Thursday' },
  { value: DaysOfWeek.FRIDAY, label: 'Every Friday' },
  { value: DaysOfWeek.SATURDAY, label: 'Every Saturday' },
];
