import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import Spinner from 'shared/components/andtComponents/Spinner';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import IconFromPng from 'shared/components/IconFromPng';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import ICONS from 'shared/constants/assetsConstants';
import WeightsTable from './WeightsTable';
import AddOrUpdateWeightsModal from './AddOrUpdateWeightsModal';

export const AWS_K8S_WEIGHTS_TABLE_COLUMNS = [
  { name: 'instance', title: 'Instance Family' },
  { name: 'cpu', title: 'CPU' },
  { name: 'memory', title: 'Memory' },
  { name: 'network', title: 'Network' },
  { name: 'userAction', title: ' ' },
];
export const AWS_K8S_WEIGHTS_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'instance', width: 200 },
  { columnName: 'cpu', width: 150 },
  { columnName: 'memory', width: 150 },
  { columnName: 'network', width: 150 },
  { columnName: 'userAction', width: 150 },
];

export const AWS_K8S_WEIGHTS_TABLE_COLUMNS_EXTENSIONS = [
  { columnName: 'cpu', align: 'center' },
  { columnName: 'network', align: 'center' },
  {
    columnName: 'memory',
    align: 'center',
  },
];

export const AddWeightsButton = ({ handler }) => (
  <Row style={{ display: 'flex', justifyContent: 'flex-start', margin: '0 0 10px 0', alignItems: 'center' }}>
    <div style={{ padding: '5px', marginRight: '10px' }}>
      <LinkIconButton>
        <button type="button" className="btn-no-style" onClick={handler} />
        <IconFromPng size={30} icon={ICONS.plus} />
      </LinkIconButton>
    </div>
    <div style={{ fontSize: '1.2vw', padding: '5px' }}>
      <span>Add Cost Weights to Instance Type</span>
    </div>
  </Row>
);
AddWeightsButton.propTypes = {
  handler: PropTypes.func.isRequired,
};

class KubernetesWeightsPage extends Component {
  static propTypes = {
    usersStore: PropTypes.object.isRequired,
    invoiceStore: PropTypes.object.isRequired,
    kubernetesStore: PropTypes.object.isRequired,
    filtersValuesMap: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalState: '',
      isUpdating: false,
      currEditedId: null,
      weightsParams: {
        instance: '',
        cpu: '',
        memory: '',
        network: '',
        totalScore: '',
      },
      inputsErrorMessage: {
        instance: '',
        cpu: '',
        memory: '',
        network: '',
        totalScore: '',
      },
    };
    this.defaultWeightsParams = {
      instance: '',
      cpu: '',
      memory: '',
      network: '',
      totalScore: '',
    };
    this.defaultInputsErrorMessage = {
      instance: '',
      cpu: '',
      memory: '',
      network: '',
      totalScore: '',
    };
  }

  async componentDidMount() {
    const { kubernetesStore } = this.props;
    kubernetesStore.getWeightsData();
  }

  toggleModal = () => {
    // eslint-disable-next-line max-len
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      currEditedId: null,
      weightsParams: { ...this.defaultWeightsParams },
      inputsErrorMessage: { ...this.defaultInputsErrorMessage },
    }));
  };
  resetToBaseStateParams = () => {
    // eslint-disable-next-line max-len
    this.setState({
      currEditedId: null,
      isUpdating: false,
      weightsParams: { ...this.defaultWeightsParams },
      inputsErrorMessage: { ...this.defaultInputsErrorMessage },
    });
  };
  handleOpenAddWeightsModalClick = () => {
    this.setState({ isModalOpen: true, modalState: 'add' });
  };
  newWeightsDataIsValid = (weightsParams) => {
    const { instance, cpu, memory, network } = weightsParams;
    const { inputsErrorMessage } = this.state;
    inputsErrorMessage.instance = !instance ? 'Please select an instance' : '';
    inputsErrorMessage.totalScore = +cpu + +memory + +network !== 10 ? 'Total weights must be equal to 10' : '';
    if (Object.values(inputsErrorMessage).some((val) => !!val)) {
      this.setState({ inputsErrorMessage });
      return false;
    }
    return true;
  };
  handleAddWeightsData = async () => {
    if (!this.newWeightsDataIsValid(this.state.weightsParams)) {
      return;
    }
    try {
      const { weightsParams } = this.state;
      this.setState({ isUpdating: true });
      this.toggleModal();
      await this.props.kubernetesStore.addWeightsData(weightsParams);
      this.setState({ isUpdating: false });
    } catch (error) {
      this.toggleModal();
      this.setState({ isUpdating: false });
    }
  };
  handleDeleteWeightClick = async (id) => {
    try {
      this.setState({ isUpdating: true });
      await this.props.kubernetesStore.deleteWeightsData(id);
      this.resetToBaseStateParams();
    } catch (error) {
      this.resetToBaseStateParams();
    }
  };
  handleEditWeightsData = async () => {
    if (!this.newWeightsDataIsValid(this.state.weightsParams)) {
      return;
    }
    try {
      const { weightsParams, currEditedId } = this.state;
      this.setState({ isUpdating: true });
      this.toggleModal();
      await this.props.kubernetesStore.updateWeightsData(currEditedId, weightsParams);
      this.setState({ isUpdating: false });
    } catch (error) {
      this.toggleModal();
      this.setState({ isUpdating: false });
    }
  };
  handleEditWeightsClick = (row) => {
    const { id, ...rest } = row;
    const instance = { value: row.instance, label: row.instance };
    const selectedweightsParams = { ...rest, instance };
    const inputsErrorMessage = this.defaultInputsErrorMessage;
    this.setState({
      isModalOpen: true,
      modalState: 'edit',
      weightsParams: selectedweightsParams,
      inputsErrorMessage,
      currEditedId: id,
    });
  };
  handleInstanceChange = (selectedOption, selectedValue) => {
    const { weightsParams, inputsErrorMessage } = this.state;
    weightsParams[selectedOption] = selectedValue ? { ...selectedValue } : selectedValue;
    inputsErrorMessage[selectedOption] = selectedValue ? '' : 'Please select an instance';
    this.setState({ weightsParams, inputsErrorMessage });
  };
  handleNumericFilterChange = (e) => {
    const { weightsParams, inputsErrorMessage } = this.state;
    const attr = e.target.id;
    const val = e.target.value;
    // eslint-disable-next-line no-nested-ternary
    weightsParams[attr] = String(Math.min(Math.max(+val, 0), 10));
    const { cpu, memory, network } = weightsParams;
    inputsErrorMessage.totalScore = +cpu + +memory + +network !== 10 ? 'Total weights must be equal to 10' : '';
    this.setState({ weightsParams, inputsErrorMessage });
  };

  render() {
    const { kubernetesStore, invoiceStore, usersStore, filtersValuesMap } = this.props;
    const { inputsErrorMessage, isModalOpen, modalState, currEditedId, weightsParams, isUpdating } = this.state;
    const { isLoading } = invoiceStore;
    if (isUpdating || kubernetesStore.kubernetesModel.isWeightsLoading || isLoading) {
      return (
        <Container>
          <Card>
            <CardBody>
              <Spinner />
            </CardBody>
          </Card>
        </Container>
      );
    }

    const { weightsData } = kubernetesStore.kubernetesModel;
    const inputListOfValues = filtersValuesMap.get('instancefamily') || [];
    const { currentUserReadOnly } = usersStore;
    const instanceNotToShow =
      weightsData && weightsData.filter((wd) => wd.id !== currEditedId).map(({ instance }) => instance);
    return (
      <Container>
        <AddWeightsButton handler={this.handleOpenAddWeightsModalClick} />
        {weightsData.length > 0 && (
          <WeightsTable
            rows={weightsData}
            columns={AWS_K8S_WEIGHTS_TABLE_COLUMNS}
            columnWidths={AWS_K8S_WEIGHTS_TABLE_COLUMNS_WIDTHS}
            tableColumnExtensions={AWS_K8S_WEIGHTS_TABLE_COLUMNS_EXTENSIONS}
            defaultHiddenColumnNames={[]}
            defaultSorting={[]}
            deleteHandler={this.handleDeleteWeightClick}
            editHandler={this.handleEditWeightsClick}
            explainHandler={this.handleExplainCreditHistory}
            csvTitle="Kubernetes insatnce weights"
            currentUserReadOnly={currentUserReadOnly}
          />
        )}
        <AddOrUpdateWeightsModal
          modalState={modalState}
          toggle={this.toggleModal}
          isOpen={isModalOpen}
          inputListOfValues={inputListOfValues}
          inputSelectedOption={weightsParams}
          selectedInputsErrorMessage={inputsErrorMessage}
          handleAddWeightsData={this.handleAddWeightsData}
          handleEditWeightsData={this.handleEditWeightsData}
          handleInstanceChange={this.handleInstanceChange}
          handleTextInputChange={this.handleTextInputChange}
          invoiceStore={invoiceStore}
          handleNumericFilterChange={this.handleNumericFilterChange}
          instanceNotToShow={instanceNotToShow}
        />
      </Container>
    );
  }
}

const ObserverKubernetesWeightsPage = withInvoiceFiltersContextConsumer(observer(KubernetesWeightsPage));
export default ObserverKubernetesWeightsPage;
