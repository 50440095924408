import { RecommTableFieldTypes } from 'recommendations/constants/recommendationsConstants';
import { RecommTypesToStoreNames, RecommTypeValuesToFilterText } from '../constants/recommHistoryConstants';
import HistoryTableCellClass from './historyTableCellClass';

export default class HistoryTableStringCell extends HistoryTableCellClass {
  constructor(cell) {
    super(cell);
    this.parsedValue = this.parseValue(this.key, this.value);
    this.parsedCompareValue = this.parseValue(this.key, this.value);
  }

  get getParsedValue() {
    return this.parsedValue;
  }

  get getParsedCompareValue() {
    return this.parsedCompareValue;
  }

  parseValue = (k, v) => {
    const statusfilter = RecommTypesToStoreNames.get(RecommTableFieldTypes.REC_STATUS);
    const typefilter = RecommTypesToStoreNames.get(RecommTableFieldTypes.TYPE);
    const isTypeOrStatus = k === statusfilter || k === typefilter;
    if (isTypeOrStatus) {
      return RecommTypeValuesToFilterText.get(v, 0);
    }
    return v;
  };
}
