import React from 'react';
import { ReactComponent as SlackLogo } from 'shared/img/icons/slack.svg';
import { ReactComponent as InfoComponent } from 'shared/img/icons/info.svg';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import PropTypes from 'prop-types';
import Button from 'shared/components/andtComponents/Button';
import { userNotificationsProvider } from 'shared/hooks/react-query/useUserSettings';
import styles from './integrations.module.scss';

const LegacySlackIntegration = ({ setIsLegacySlack }) => {
  const userNotificationsHook = userNotificationsProvider();
  const { mutateAsync: updateUserNotifications } = userNotificationsHook.useMutation({
    onSettled: () => {
      userNotificationsHook.invalidate();
      setIsLegacySlack(false);
    },
  });
  const removeLegacySlack = async (e) => {
    e.preventDefault();
    await updateUserNotifications({ isSlackNotification: false });
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.type}>
          <SlackLogo className={styles.slackLogo} />
          <div className={styles.typeName}>Slack Legacy</div>
          <div className={styles.infoBar}>
            <InfoComponent className="me-1" />
            <div className={styles.infoText}>
              Legacy Slack application is enabled. In order to install the new Slack application disable the legacy
              application.
            </div>
          </div>
        </div>
        <Button text="Delete" onClick={removeLegacySlack} icon={() => <GenerateIcon iconName={ICONS.delete.name} />} />
      </div>
    </>
  );
};

export default LegacySlackIntegration;

LegacySlackIntegration.propTypes = {
  setIsLegacySlack: PropTypes.func.isRequired,
};

LegacySlackIntegration.defaultProps = {};
