import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Routes } from 'shared/constants/routes';
import Spinner from 'shared/components/andtComponents/Spinner';
import CustomModal from 'shared/components/andtComponents/Modal';
import { withRouter } from 'react-router';
import {
  mapModalBodyByCurrStage,
  mapModalHeaderByCurrStage,
  UserConnectionStages,
} from '../constants/newUserConstants';
import { ReactComponent as ZoomIllustration } from './assets/zoomIllustration.svg';
import styles from './WizardSubmitModal.module.scss';

const goToLinkedAccountClicked = (goToLinkedAccountsHandler, closeModal) => {
  closeModal();
  goToLinkedAccountsHandler();
};

const WizardSubmitModal = memo(
  ({ history, closeModal, isOpen, userConnectionStage, handleGoBack, handleTryConnect, goToLinkedAccountsHandler }) => {
    const renderBody = () => {
      switch (userConnectionStage) {
        case UserConnectionStages.CONNECTING:
          return (
            <div>
              Please do not close this window until the process is finished
              <Spinner />
            </div>
          );
        case UserConnectionStages.CHECK_FAILED:
          return (
            <div className="d-flex align-items-center">
              <ZoomIllustration />
              Please review your input <br /> and re-try.
            </div>
          );
        default:
          return mapModalBodyByCurrStage.get(userConnectionStage);
      }
    };
    const footerParams = () => {
      switch (userConnectionStage) {
        case UserConnectionStages.CONNECTING:
          return { footerDisabled: true };
        case UserConnectionStages.RESULT_SUCCESS:
          return { saveTitle: 'Done', onSave: () => history.push(Routes.WELCOME) };
        case UserConnectionStages.RESULT_SUCCESS_LINKED_ACC:
          return {
            saveTitle: 'Connect Linked Accounts',
            onSave: () => goToLinkedAccountClicked(goToLinkedAccountsHandler, closeModal),
          };
        case UserConnectionStages.CHECK_FAILED:
          return {
            saveTitle: 'Go to Prev Steps',
            cancelTitle: 'Try again',
            onSave: () => {
              handleGoBack();
            },
            onCloseClick: () => {
              handleTryConnect();
            },
          };
        case UserConnectionStages.RESULT_FAIL:
          return { saveHidden: true, cancelTitle: 'Back' };
        default:
          return { footerDisabled: true };
      }
    };
    return (
      <CustomModal
        open={isOpen}
        onClose={closeModal}
        className={styles.modal}
        title={mapModalHeaderByCurrStage.get(userConnectionStage)}
        {...footerParams()}
      >
        <div style={{ minHeight: 150 }} className="d-flex flex-column">
          {renderBody()}
        </div>
      </CustomModal>
    );
  },
);

WizardSubmitModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleTryConnect: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  goToLinkedAccountsHandler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userConnectionStage: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(WizardSubmitModal);
