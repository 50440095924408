import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IntegratedSorting,
  DataTypeProvider,
  SortingState,
  PagingState,
  IntegratedPaging,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import {
  Grid,
  TableHeaderRow,
  PagingPanel,
  TableColumnResizing,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { useRootStore } from 'app/contexts/RootStoreContext';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import TableWrapper from 'shared/components/tables/TableWrapper';
import useTable from 'shared/hooks/customHooks/useTable';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import Button from 'shared/components/andtComponents/Button';
import { withRolesContextConsumer } from 'users/contexts/RolesProvider';
import { useLinkedAccountsTableState } from 'users/containers/Divisions/context/costCenter.context';
import MoveLinkedAccountsModal from './MoveLinkedAccountsModal';
import { LINKED_ACCOUNTS_TABLE_COLUMNS, LINKED_ACCOUNTS_COLUMN_EXTENSIONS } from '../divisionsConstants';
import styles from './LinkedAccountsTable.module.scss';

const LinkedAccountsTable = ({ division, removeLinkedAccounts }) => {
  const [columnWidths, setColumnWidths] = useState(LINKED_ACCOUNTS_COLUMN_EXTENSIONS);
  const [deletedLinkedAccounts, setDeletedLinkedAccounts] = useState([]);
  const [moveLinkedAccounts, setMoveLinkedAccounts] = useState([]);
  const { NewTableSelectionBar } = useTable();
  const { usersStore, divisionsStore } = useRootStore();
  const { selectedRows, setSelectedRows } = useLinkedAccountsTableState(division.divisionId);
  const pageSizes = [10, 20, 30];

  const actionsFormatter = (data) => (
    <ButtonDropdown
      text=""
      isTextButton
      icon={() => <GenerateIcon iconName={ICONS.verticalDots.name} className={styles.menuIcon} />}
      automationId="useractions"
    >
      <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
        <Button
          onClick={() => setDeletedLinkedAccounts([data.row])}
          id="delete"
          automationId="delete-linked-account-button"
          text="Remove Linked Account"
          isTextButton
          className={{ buttonText: styles.buttonText, icon: styles.buttonIcon }}
          icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
        />
      </ReadOnlyDisplayWrapper>
      {division?.linkedAccounts?.length > 1 && (
        <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
          <Button
            onClick={() => setMoveLinkedAccounts([data.row])}
            id="move"
            automationId="move-linked-account-button"
            text="Move Linked Account"
            isTextButton
            className={{
              buttonText: styles.buttonText,
              icon: styles.buttonIcon,
              button: styles.button,
              buttonContent: styles.buttonContent,
            }}
            icon={() => <GenerateIcon iconName={ICONS.turnDownRight.name} />}
          />
        </ReadOnlyDisplayWrapper>
      )}
    </ButtonDropdown>
  );

  const userDeleteWarningHandler = async (action) => {
    if (action === 'delete') {
      await removeLinkedAccounts(deletedLinkedAccounts, division);
      setSelectedRows([]);
    }
    setDeletedLinkedAccounts([]);
  };
  return (
    <div className={`sub-table ${styles.tableWrapper}`}>
      <Grid rows={division?.linkedAccounts || []} columns={LINKED_ACCOUNTS_TABLE_COLUMNS}>
        <SortingState />
        <IntegratedSorting />
        <SelectionState selection={selectedRows} onSelectionChange={setSelectedRows} />
        <IntegratedSelection />
        <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[1]} />
        <IntegratedPaging />
        <DataTypeProvider for={['actions']} formatterComponent={actionsFormatter} />
        <TableWrapper columnExtensions={LINKED_ACCOUNTS_COLUMN_EXTENSIONS} />
        <TableColumnResizing
          columnWidths={columnWidths}
          onColumnWidthsChange={setColumnWidths}
          resizingMode="nextColumn"
        />
        <TableHeaderRow showSortingControls />
        <PagingPanel pageSizes={pageSizes} />
        <TableSelection showSelectAll />
        <NewTableSelectionBar selectedRows={selectedRows}>
          <div>
            <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
              <Button
                automationId="delete-linked-accounts-button"
                isTextButton
                text="Delete"
                icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
                onClick={() => {
                  setDeletedLinkedAccounts(division.linkedAccounts.filter((_, idx) => selectedRows.includes(idx)));
                }}
                disabled={selectedRows?.length >= division?.linkedAccounts?.length}
              />
            </ReadOnlyDisplayWrapper>
            <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
              <Button
                automationId="move-linked-accounts-button"
                isTextButton
                text="Move"
                icon={() => <GenerateIcon iconName={ICONS.turnDownRight.name} />}
                onClick={() => {
                  setMoveLinkedAccounts(division.linkedAccounts.filter((_, idx) => selectedRows.includes(idx)));
                }}
                disabled={selectedRows?.length >= division?.linkedAccounts?.length}
              />
            </ReadOnlyDisplayWrapper>
          </div>
        </NewTableSelectionBar>
      </Grid>
      <DeleteWarningModal
        deletedItemName={`${deletedLinkedAccounts.length} Linked accounts`}
        isOpen={deletedLinkedAccounts.length > 0}
        handleDelete={userDeleteWarningHandler}
        warningMessage="Be advise you are about to delete "
        modalTitle="Delete Linked Accounts"
      />
      {!!moveLinkedAccounts?.length && (
        <MoveLinkedAccountsModal
          onClose={() => setMoveLinkedAccounts([])}
          isOpen
          toDivision={null}
          fromDivision={division}
          linkedAccountIds={moveLinkedAccounts.map(({ linkedAccountId }) => linkedAccountId)}
          divisions={usersStore.usersModel.divisionGroups}
          fetchDivisions={divisionsStore.fetchData}
        />
      )}
    </div>
  );
};

LinkedAccountsTable.propTypes = {
  division: PropTypes.object.isRequired,
  removeLinkedAccounts: PropTypes.func.isRequired,
};

export default withRolesContextConsumer(LinkedAccountsTable);
