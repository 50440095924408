import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import InformationOutline from 'mdi-react/InformationOutlineIcon';
// eslint-disable-next-line max-len
import RecommendationOptionsContext from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationOptionsContext';

import classes from './recommendationOption.module.scss';

const POTENTIAL_SAVING_LABEL = 'Potential Savings';

const RecommendationOptionButton = ({ recommendation, properties, isCurrent, optionIndex }) => {
  const isRecommended = optionIndex === 0;

  const { selectedOptionIndex, setSelectedOptionIndex } = useContext(RecommendationOptionsContext);

  const { getCurrencyNumber } = useUserSettingsContext();
  const [title, setTitle] = useState('');
  const isSelected = selectedOptionIndex === optionIndex;

  const option = recommendation?.recData?.alternatives?.[optionIndex];

  useEffect(() => {
    let tmpTitle = `OPTION ${optionIndex + 1}`;
    if (isCurrent) {
      tmpTitle = 'CURRENT';
    } else if (isRecommended) {
      tmpTitle = `${tmpTitle} (RECOMMENDED)`;
    }
    setTitle(tmpTitle);
  }, []);

  const optionSelectionChanged = () => {
    if (!isCurrent && setSelectedOptionIndex) {
      return setSelectedOptionIndex(optionIndex);
    }
    return undefined;
  };

  const getPropertyValue = (property) => {
    let propertyValue = '';

    const value = property?.getterFunction(recommendation, optionIndex);
    if (typeof value === 'string' && property.isCurrency) {
      propertyValue = getCurrencyNumber(value);
    } else if (typeof value === 'number') {
      const decimal = value % 1 > 0 ? 2 : 0;
      propertyValue = property.isCurrency
        ? getCurrencyNumber(value)
        : value.toLocaleString(undefined, {
            maximumFractionDigits: decimal,
          });
    } else {
      propertyValue = value;
    }
    return propertyValue;
  };

  const isPropertyDifferentFromCurrent = (property) => {
    if (isCurrent || !property || property?.isNoColorInOptions) {
      return false;
    }
    return property?.getterFunction(recommendation, optionIndex) !== property?.getterFunction(recommendation);
  };

  return (
    <div className={classes.optionContainer}>
      <div
        className={`${classes.option}${isCurrent ? '' : ` ${classes.notCurrent}`}${
          isSelected ? ` ${classes.selected}` : ''
        }`}
        onClick={optionSelectionChanged}
      >
        <div className={`${classes.titleContainer}${isRecommended ? ` ${classes.recommended}` : ''}`}>
          <div className={classes.title}>{title}</div>
          {isCurrent ? (
            <div />
          ) : (
            <Tooltip placement="top" title={POTENTIAL_SAVING_LABEL} arrow>
              <div className={classes.savingAmount}>
                {option?.saving_amount || option?.savings_amount
                  ? getCurrencyNumber(option?.saving_amount || option?.savings_amount)
                  : ''}
              </div>
            </Tooltip>
          )}
        </div>
        <div className={classes.dataContainer}>
          {properties?.map((property) => (
            <>
              <div className={classes.dataTitle}>
                {property?.labelForOption || property?.label}:
                {property?.info ? (
                  <div>
                    <Tooltip title={property.info} arrow placement="top">
                      <div>
                        <InformationOutline size={18} />
                      </div>
                    </Tooltip>
                  </div>
                ) : null}
              </div>
              <div
                className={`${classes.dataValue} ${
                  isPropertyDifferentFromCurrent(property) ? classes.differentFromCurrent : ''
                }`}
              >
                {getPropertyValue(property)}
              </div>
            </>
          ))}
        </div>
      </div>
      {isSelected && <div className={classes.arrowDown} />}
    </div>
  );
};

RecommendationOptionButton.propTypes = {
  recommendation: PropTypes.object.isRequired,
  isCurrent: PropTypes.bool,
  optionIndex: PropTypes.number,
  properties: PropTypes.object,
};

RecommendationOptionButton.defaultProps = {
  isCurrent: false,
  optionIndex: undefined,
  properties: undefined,
};

export default RecommendationOptionButton;
