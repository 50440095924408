import React, { useState } from 'react';
import Button from 'shared/components/andtComponents/Button';
import Modal from 'shared/components/andtComponents/Modal';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import toast from 'shared/components//andtComponents/Toast';
import useSlack from 'shared/components/slackIntegration/hooks/useSlack';
import styles from './slackIntegration.module.scss';

const uninstallText =
  'Are you sure you would like to remove integration for Slack? \n' +
  'Note this action could only be reverted by re running the integration. ';

const UninstallSlackButton = () => {
  const [isUninstallModal, setIsUninstallModal] = useState(false);

  const { uninstallSlackIntegration } = useSlack();
  const { mutateAsync: uninstallSlack } = uninstallSlackIntegration();

  const openUninstallModal = (event) => {
    event.preventDefault();
    setIsUninstallModal(true);
  };

  const uninstallHandler = async () => {
    try {
      await uninstallSlack();
      toast.success('Integration for Slack removed');
    } catch (error) {
      const { response } = error;
      if (response.data.clientMessage) {
        toast.error(error.response.data.clientMessage);
      } else {
        toast.error('Something went wrong please try again later');
      }
    }
  };

  return (
    <div>
      <Button
        text=""
        isTextButton
        overrideStyles={{ padding: 0 }}
        onClick={openUninstallModal}
        icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
      />
      <Modal
        title="Remove Integration for Slack"
        saveTitle="Remove Integration"
        onClose={(value) => setIsUninstallModal(value)}
        open={isUninstallModal}
        onSave={uninstallHandler}
        saveWarning
      >
        <div className={styles.uninstallText}>{uninstallText}</div>
      </Modal>
    </div>
  );
};

export default UninstallSlackButton;
