import React from 'react';
import PropTypes from 'prop-types';

const JsonDownlad = ({ jsonString }) => (
  <>
    <div className="account__code-window">
      <pre>
        <code>{jsonString}</code>
      </pre>
    </div>
  </>
);
JsonDownlad.propTypes = {
  jsonString: PropTypes.func.isRequired,
  downloadFilename: PropTypes.string.isRequired,
};
export default JsonDownlad;
