import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, ClickAwayListener, Paper, Tooltip } from '@mui/material';
import FiltersSidebarContainer from 'shared/components/FilterSidebar/FiltersSidebarContainer';
import { PageNames, OPERATORS_KEYS } from 'shared/constants/appConstants';
import { random5DigitNumber } from 'shared/utils/mathUtil';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { useRootStore } from 'app/contexts/RootStoreContext';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  transformCustomFilterFromDB,
  transformCustomFilterFromUI,
  filterFieldsTooltip,
} from '../helpers/customFiltersHelper';

const CustomDashboardFilters = ({ dashboardId, onReload, filters: filtersData, getPageFilters }) => {
  const { usersStore, usageStore } = useRootStore();
  const { uuid: idFromServer, name: nameFromServer, filters: filtersFromServer } = (filtersData || [])[0] || {};
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [deleteFilterModelOpen, setDeleteFilterModelOpen] = useState(false);
  const [filters, setFilters] = useState(
    !isEmptyArray(filtersFromServer) ? transformCustomFilterFromDB(filtersFromServer) : [],
  );
  const [saveLoading, setSaveLoading] = useState(false);
  const fieldToFieldDistincValuesMap = getPageFilters(
    PageNames.CUSTOM_DASHBOARD,
    usersStore.currDispUserCloudAccountType,
  );
  const isEdit = !!nameFromServer;
  const { customDashboardModel } = usageStore.customDbSubStore;
  const handleSaveFilter = async () => {
    setSaveLoading(true);
    const data = {
      filters: transformCustomFilterFromUI(filters),
      name: isEdit ? nameFromServer : random5DigitNumber(),
      uuid: idFromServer,
    };
    if (isEdit) {
      await customDashboardModel.updateCustomFilter(dashboardId, data);
    } else {
      await customDashboardModel.createCustomFilter(dashboardId, data);
    }
    setFilterMenuOpen(false);
    setSaveLoading(false);
    onReload();
  };

  return (
    <div className="position-relative d-flex align-items-center">
      <DeleteWarningModal
        deletedItemName=""
        buttonDisabled={saveLoading}
        isOpen={deleteFilterModelOpen}
        handleDelete={async (action) => {
          setSaveLoading(true);
          if (action === 'delete') {
            await customDashboardModel.deleteCustomFilter(dashboardId, idFromServer);
            setFilters([]);
            onReload();
          }
          setSaveLoading(false);
          setDeleteFilterModelOpen(false);
        }}
        warningMessage="Filter will be deleted."
        executeButtonTitle="OK"
        modalTitle="Delete Filter"
      />
      {filterMenuOpen ? (
        <Button onClick={handleSaveFilter} disabled={saveLoading}>
          Save
        </Button>
      ) : (
        <Button onClick={() => setFilterMenuOpen(true)} disabled={saveLoading} color="secondary">
          {isEdit ? 'Edit' : 'Add'} Filter
        </Button>
      )}
      {isEdit ? (
        <>
          <Button onClick={() => setDeleteFilterModelOpen(true)} className="ms-2" disabled={saveLoading}>
            Delete
          </Button>
        </>
      ) : null}
      <Tooltip
        className="align-self-center"
        title="Custom filter applies for all panels in the dashboard that were created in Cost And Usage Explorer"
      >
        <InfoOutlinedIcon className="ms-2" />
      </Tooltip>
      {filterMenuOpen && (
        <ClickAwayListener onClickAway={() => setFilterMenuOpen(false)}>
          <Paper className="position-absolute p-3" style={{ right: 0, width: 400, zIndex: 5, top: '100%' }}>
            <FiltersSidebarContainer
              isOpen
              selectedOptionsMap={new Map(filters.map((f) => [f.field, f.values]))}
              excludedFiltersStatusMap={
                new Map(
                  filters.map((f) => {
                    const { operator, field } = f;
                    if (typeof operator !== 'object') {
                      return [field, operator === OPERATORS_KEYS.IS_NOT];
                    }
                    return [
                      field,
                      Object.keys(operator).reduce(
                        (acc, key) => ({
                          ...acc,
                          [key]: operator[key] === OPERATORS_KEYS.IS_NOT,
                        }),
                        {},
                      ),
                    ];
                  }),
                )
              }
              fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
              filterTooltipsFields={filterFieldsTooltip()[usersStore.currDispUserCloudAccountType]}
              currDispUserCloudAccountType={usersStore.currDispUserCloudAccountType}
              handleChangeFilterType={(field, subField) => {
                setFilters(() => {
                  const getOperator = (op) =>
                    op === OPERATORS_KEYS.IS_NOT ? OPERATORS_KEYS.IS : OPERATORS_KEYS.IS_NOT;
                  let changed = false;
                  const newFilters = filters.map((f) => {
                    if (field !== f.field) {
                      return f;
                    }
                    changed = true;
                    return {
                      ...f,
                      operator: subField
                        ? {
                            ...(f.operator || {}),
                            [subField]: getOperator((f.operator || {})[subField]),
                          }
                        : getOperator(f.operator),
                    };
                  });
                  if (!changed) {
                    newFilters.push({ field: '', operator: OPERATORS_KEYS.IS_NOT });
                  }
                  return newFilters;
                });
              }}
              handleFilterChange={(field, value) => {
                let changed = false;
                const newFilters = filters.map((f) => {
                  if (f.field !== field) {
                    return f;
                  }
                  changed = true;
                  return {
                    ...f,
                    values: value,
                  };
                });
                if (!changed) {
                  newFilters.push({ field, values: value, operator: OPERATORS_KEYS.IS });
                }
                setFilters(newFilters);
              }}
              handleRemoveFieldFromFiltersValuesMap={(field) => {
                setFilters(filters.filter((f) => f.field !== field));
              }}
              showTitle={false}
              className="dashboard"
            />
          </Paper>
        </ClickAwayListener>
      )}
    </div>
  );
};

CustomDashboardFilters.propTypes = {
  getPageFilters: PropTypes.func.isRequired,
  dashboardId: PropTypes.string.isRequired,
  onReload: PropTypes.func.isRequired,
  filters: PropTypes.array,
};

CustomDashboardFilters.defaultProps = {
  filters: null,
};

export default withInvoiceFiltersContextConsumer(observer(CustomDashboardFilters));
