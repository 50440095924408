/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Row } from 'reactstrap';
import ArrowLeftDropCircleOutline from 'mdi-react/ArrowLeftDropCircleOutlineIcon';
import ArrowRightDropCircleOutline from 'mdi-react/ArrowRightDropCircleOutlineIcon';
import { BudgetMonthChange } from 'usage/constants/usageConstants';
import { getMonthNameFromNumber } from 'shared/utils/dateUtil';
import ICONS from 'shared/constants/assetsConstants';
import IconFromPng from 'shared/components/IconFromPng';
import NewCustomDashboardPanelModal from 'shared/components/NewCustomDashboardPanelModal';
import BudgetChart from './BudgetChart';
import * as custDabrdHelpers from '../../CostAndUsageExplorer/helpers/customDashboardHelperMethods';

class BudgetDisplay extends Component {
  static propTypes = {
    budget: PropTypes.object.isRequired,
    usageStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currDisplayedBudgetDate: new Date(
        props.budget.monthlyStartDate.getFullYear(),
        props.budget.monthlyStartDate.getMonth(),
        1,
      ),
    };
  }

  getParamsForCustomDashboard = () => {
    const { budgetId } = this.props.budget;
    return { id: budgetId };
  };

  dateFormat = (date) => `${date.getFullYear()}-${date.getMonth()}`;

  handleChangeMonth = (direction) => {
    const prevDisaplyedBudgetDate = new Date(this.state.currDisplayedBudgetDate);
    if (direction === BudgetMonthChange.PREVIOUS) {
      const updatedDisaplyedBudgetDate = new Date(
        prevDisaplyedBudgetDate.setMonth(prevDisaplyedBudgetDate.getMonth() - 1),
      );
      const index = this.props.budget.previousBudgets
        ? this.props.budget.previousBudgets.findIndex(
            (budget) => this.dateFormat(budget.monthlyStartDate) === this.dateFormat(updatedDisaplyedBudgetDate),
          )
        : -1;
      if (index > -1) {
        this.setState({ currDisplayedBudgetDate: updatedDisaplyedBudgetDate });
      }
    } else if (direction === BudgetMonthChange.NEXT) {
      const updatedDisaplyedBudgetDate = new Date(
        prevDisaplyedBudgetDate.setMonth(prevDisaplyedBudgetDate.getMonth() + 1),
      );
      const index = this.props.budget.previousBudgets
        ? this.props.budget.previousBudgets.findIndex(
            (budget) => this.dateFormat(budget.monthlyStartDate) === this.dateFormat(updatedDisaplyedBudgetDate),
          )
        : -1;
      if (
        index > -1 ||
        this.dateFormat(this.props.budget.monthlyStartDate) === this.dateFormat(updatedDisaplyedBudgetDate)
      ) {
        this.setState({ currDisplayedBudgetDate: updatedDisaplyedBudgetDate });
      }
    }
  };
  handleIsDiaplayArrow = (budget) => {
    if (!budget.previousBudgets) {
      return false;
    }
    return true;
  };

  handlePresentedBudget = () => {
    if (this.dateFormat(this.state.currDisplayedBudgetDate) === this.dateFormat(this.props.budget.monthlyStartDate)) {
      return this.props.budget;
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.props.budget.previousBudgets.length; i++) {
      if (
        this.dateFormat(this.props.budget.previousBudgets[i].monthlyStartDate) ===
        this.dateFormat(this.state.currDisplayedBudgetDate)
      ) {
        return this.props.budget.previousBudgets[i];
      }
    }
    return null;
  };

  handleSaveDashboardPanelButtonClick = (e, type) => {
    e.preventDefault();
    this.setState({ newCustomDashboardPanelModalIsOpen: true, currCustomPanelType: type });
  };
  handleCloseNewCustomDashboardPanelModal = () => {
    this.setState({ newCustomDashboardPanelModalIsOpen: false, currCustomPanelType: null });
  };

  render() {
    const { usageStore } = this.props;
    const { currDisplayedBudgetDate, newCustomDashboardPanelModalIsOpen } = this.state;
    const { existingDashboardsNamesAndIds, modelIsLoading } = usageStore.customDbSubStore.customDashboardModel;

    return (
      <div>
        <div className="float-right">
          <a
            style={{ cursor: 'pointer' }}
            role="button"
            tabIndex={0}
            onClick={(e) => this.handleSaveDashboardPanelButtonClick(e, 'budget-chart')}
          >
            <IconFromPng size="20px" matchHeight icon={ICONS.save} />
          </a>
        </div>
        <Row className="budgets-nevigate-row">
          <ArrowLeftDropCircleOutline
            color="#646777"
            className="arrow-btn"
            onClick={() => this.handleChangeMonth(BudgetMonthChange.PREVIOUS)}
          />
          <h5 className="month-name">
            {getMonthNameFromNumber(this.state.currDisplayedBudgetDate.getMonth() + 1)},{' '}
            {this.state.currDisplayedBudgetDate.getFullYear()}
          </h5>
          <ArrowRightDropCircleOutline
            color="#646777"
            className="arrow-btn"
            onClick={() => this.handleChangeMonth(BudgetMonthChange.NEXT)}
          />
        </Row>
        <BudgetChart
          budget={this.handlePresentedBudget()}
          currMonthlyUsage={this.handlePresentedBudget().currTotalCost}
          currDisplayedBudgetDate={currDisplayedBudgetDate}
        />
        <NewCustomDashboardPanelModal
          modalIsOpen={newCustomDashboardPanelModalIsOpen}
          getCurrentCauParams={this.getParamsForCustomDashboard}
          onClose={this.handleCloseNewCustomDashboardPanelModal}
          customDashboardStore={usageStore.customDbSubStore}
          existingDashboardsNamesAndIds={existingDashboardsNamesAndIds}
          helpers={custDabrdHelpers}
          type={this.state.currCustomPanelType}
          isCustomDbModelLoading={modelIsLoading}
          state={this.state}
          isSelectTimePeriodEnabled={false}
          usageStore={usageStore}
        />
      </div>
    );
  }
}

const ObserverBudgetDisplay = observer(BudgetDisplay);
export default ObserverBudgetDisplay;
