/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import ShowPasswordIcon from 'mdi-react/EyeOutlineIcon';
import HidePasswordIcon from 'mdi-react/EyeOffOutlineIcon';
import PropTypes from 'prop-types';
import Input from 'shared/components/andtComponents/Input';
import styles from './RegisterForm.module.scss';

const RegisterForm = ({
  handleSubmit,
  handleChange,
  jobTitle,
  companyName,
  companyExampleName,
  username,
  password,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleKeyPress = (target) => {
    if (target.charCode === 13) {
      handleSubmit();
    }
  };
  return (
    <form onSubmit={handleSubmit} className="w-100">
      <div>
        <label htmlFor="companyName" className={styles.input}>
          Company *
          <Input
            value={companyName}
            onChange={handleChange}
            name="companyName"
            type="text"
            placeholder={companyExampleName}
          />
        </label>
      </div>
      <div>
        <label htmlFor="jobTitle" className={styles.input}>
          Job Title
          <Input value={jobTitle} onChange={handleChange} name="jobTitle" type="text" placeholder="Manager" />
        </label>
      </div>
      <div>
        <label htmlFor="username" className={styles.input}>
          Email *
          <Input
            value={username}
            onChange={handleChange}
            name="username"
            type="email"
            placeholder={`user@${companyExampleName.toLowerCase()}.com`}
          />
        </label>
      </div>
      <div>
        <label htmlFor="username" className={styles.input}>
          Password *
          <Input
            onChange={handleChange}
            name="password"
            component="input"
            value={password}
            onKeyPress={handleKeyPress}
            type={showPassword ? 'text' : 'password'}
            placeholder="****"
          />
          <button className="btn-no-style" onClick={handleShowPassword}>
            {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
          </button>
        </label>
      </div>
    </form>
  );
};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  jobTitle: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  companyExampleName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

export default RegisterForm;
